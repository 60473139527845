import React from "react";
import InvoiceOverview from './InvoiceOverview';
import NewInvoice from "./NewInvoice";

const Invoices = ({
  invoiceId,
  activeIndex,
  currentPage
}) => {
  return (
    <>
      {invoiceId ?
        invoiceId === "create" ? <NewInvoice invoiceId={null} /> :
          <NewInvoice invoiceId={invoiceId} />
        :
        <InvoiceOverview
          activeIndex={activeIndex}
          currentPage={currentPage}
        />
      }
    </>

  )
}

export default Invoices;
