import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignUpExternal.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Entry from "./Entry";
import Takeoff from "./Takeoff";
import Image from "../../components/Image";
import withUserAuth from "../../utils/withUserAuth";

const SignUpExternal = ({ istestmode }) => {
  const [visible, setVisible] = useState(true);
  const heightWindow = use100vh();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const authtype = params.get("authtype") || "googleex";
  const signUpToken = params.get("signUpToken") || "";
  const email = params.get("email");
  const name = params.get("name");

  return (
    <div className={styles.row}>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        <div className={styles.head}>
          <Link
            className={styles.logo}
            to={{ pathname: "https://greatweek.com" }}
            target='_blank'
          >
            <Image
              className={styles.pic}
              src='/images/logo-dark.png'
              srcDark='/images/logo-light.png'
              alt='logo'
            />
          </Link>
          <div className={styles.info}>
            Already a member?{" "}
            <Link className={styles.link} to='/'>
              Sign in
            </Link>
          </div>
        </div>
        <div className={styles.wrap}>
          <div className={cn("h3", styles.title)}>No Account Found</div>
          <div className='h5'>
            We've searched our records but can't find an account belonging to{" "}
            <strong>{email}</strong>.
          </div>
          <div className='h5'>&nbsp;</div>
          <div className='h5'>&nbsp;</div>
          <div className='h5'>
            Do you want to create a new GreatWeek account with this email?
          </div>
          <div className='h5'>&nbsp;</div>

          {visible ? (
            <Entry
              onConfirm={() => setVisible(false)}
              istestmode={istestmode || false}
              email={email}
              authtype={authtype}
              signUpToken={signUpToken}
              name={name}
            />
          ) : (
            <Takeoff />
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserAuth(SignUpExternal);
