import React, { useState, useEffect } from "react";
import styles from "./CustomerOverview.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Filters from "../../../components/Filters";
import Settings from "./Settings";
import Table from "./Table";
import DeleteModal from "../../../components/DeleteModal";
import Icon from "../../../components/Icon";
import EditNewCustomer from "../EditNewCustomer";
import MainCustomerContainer from "../index";
import { Redirect, useHistory, useParams } from "react-router";
import Spinner from "../../../utils/spinner";
import {
  deleteBulkCustomer,
  deleteBulkRecipients,
  getContactsData,
  getRecipientsAndContacts,
} from "../../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../utils/utils";
import Modal from "../../../components/Modal";
import Checkbox from "../../../components/Checkbox";
import Pipeline from "./Pipeline";
import AdjustPipeline from "./Pipeline/AdjustPipeline";

// import { customers } from "../../../mocks/customers";

let idsArray = [];
let page = 1;

const CustomerList = ({ visibility, activeTab, isCustomer, setIsCustomer }) => {
  const [visible, setVisible] = useState(true);
  const [customerId, setCustomerId] = useState(undefined);
  const [showMainContainer, setShowMainContainer] = useState(false);
  const [actionCustomer, setActionCustomer] = useState(true);
  const [show, setShow] = useState(false);
  const [checkCount, setCheckCount] = useState(0);
  const [isAdd, setIsAdd] = useState(false);
  const [deleteIdArray, setDeleteIdArray] = useState([]);

  const [activeIndex, setActiveIndex] = useState(parseInt(activeTab) || 1);
  const [deleteBulkCustomerLoading, setDeleteBulkCustomerLoading] =
    useState(false);

  const [customerData, setCustomerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const [nextPageInfo, setNextPageInfo] = useState(null);

  const [isCustomerSelected, setIsCustomerSelected] = useState(true);
  const [isRecipientSelected, setIsRecipientSelected] = useState(false);

  const [showAdjustPipeline, setShowAdjustPipeline] = useState(false);
  const [showAddDeal, setShowAddDeal] = useState(false);
  const [customersType, setCustomersType] = useState("Active");

  const [currentSelected, setCurrentSelected] = useState(0);

  const [selectedTypeForAddNewContact, setSelectedTypeForAddNewContact] = useState("Customer");


  const history = useHistory();
  const { activeCustomerTab } = useParams();

  // const customerLength = Object.keys(customers).length;

  // const handleChangeCustomerAll = (e) => {
  //   var ariaStatus = e.target.getAttribute("aria-Checked");
  //   if (ariaStatus === "true") {
  //     setActionCustomer(true);
  //     setCheckCount(0);
  //   }
  //   if (ariaStatus === "false") {
  //     setActionCustomer(false);
  //     setCheckCount(customerLength);
  //   }
  //   if (ariaStatus === "mixed") {
  //     setActionCustomer(false);
  //     setCheckCount(customerLength);
  //   }
  // };

  // const handleChangeCustomer = (e) => {
  //   if (e.target.checked) {
  //     setCheckCount(checkCount + 1);
  //   }
  //   if (!e.target.checked) {
  //     setCheckCount(checkCount - 1);
  //   }
  // };

  const handleEditCustomer = (id) => {
    setCustomerId(id);
    setVisible(false);
  };

  // useEffect(() => {
  //   if (checkCount === 0) {
  //     setActionCustomer(true);
  //   } else {
  //     setActionCustomer(false);
  //   }
  // });

  useEffect(async () => {
    if (visibility) {
      const type = isCustomer ? 'Customer' : 'Recipient';
      await getCustomerData(false, type);
    }
  }, [activeIndex]);

  const getCustomerData = async (doConcat, type, sort, sortOrder) => {
    page = doConcat ? page + 1 : 1;
    setDeleteIdArray([]);
    setCheckCount(0);
    setIsLoading(true);
    const customersData = await getRecipientsAndContacts({
      pageSize: 30,
      page: page,
      type: type || "Customer",
      sort: sort || "name",
      sortOrder: sortOrder || 'asc'
    });
    const { data } = customersData;
    if (data) {
      setHasMore(data.isNextPageExist);
      setNextPageInfo(data?.nextPageInfo);
      if (doConcat) {
        setCustomerData(customerData.concat(data.contacts));
      } else {
        setCustomerData(data.contacts);
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      {showAdjustPipeline ?
        <AdjustPipeline
          setShowAdjustPipeline={setShowAdjustPipeline}
        />
        :
        showMainContainer ? (
          <MainCustomerContainer />
        ) : visible ? (
          <>
            {/* {< div className={cn("h3", styles.pageTitle)}>Customers</div>} */}
            <Card
              className={styles.card}
              title='Contacts'
              border
              /* classTitle={cn("title-blue", styles.title)} */
              classCardHead={cn(styles.head, { [styles.hidden]: show })}
              head={
                <>
                  {/* <Filters
                    className={styles.filters}
                    title="Showing 9 of 32 customer"
                    iconHead={
                      <Icon
                        className={styles.filterBtn}
                        name="adjustments"
                        size="24"
                      />
                    }
                  >
                    <Settings
                    //isClick={() => setVisible(false)}
                    />
                  </Filters>
                  <button className={styles.downloadBtn}>
                    <Icon name="download" size="24" />
                  </button> */}
                  {customersType === "Pipeline" ?
                    <div className={styles.buttonContainer}>
                      <button className='button-stroke-red' onClick={() => setShowAdjustPipeline(true)}>
                        Adjust Pipeline
                      </button>
                      <button className='button' onClick={() => {
                        setShowAddDeal(true)
                      }}>
                        Add Deal
                      </button>
                    </div>
                    :
                    checkCount < 1 ? (
                      activeIndex === 1 ? (
                        <button
                          onClick={() => {
                            if (customerData?.length > 0) {
                              if (customerData[0]?.type === "Customer") {
                                setSelectedTypeForAddNewContact('Customer');
                              } else {
                                setSelectedTypeForAddNewContact('Recipient');
                              }
                            }
                            setVisible(false);
                            setIsAdd(true);
                          }}
                          className={cn("button", styles.addCustomerBtn)}
                        >
                          {`Add ${customerData?.length > 0 ? customerData[0]?.type === "Customer" ?
                            'Customer' : 'Recipient' : currentSelected === 0 ? 'Customer' : 'Recipient'}`}
                        </button>
                      ) : (
                        activeIndex === 2 && (
                          <button
                            onClick={() => {
                              history.push({
                                pathname: "/contacts/add",
                                state: { isComingFromCustomer: true },
                              });
                            }}
                            className={cn("button", styles.addCustomerBtn)}
                          >
                            Add recipient
                          </button>
                        )
                      )
                    ) : (
                      <button
                        onClick={async () => {
                          setDeleteBulkCustomerLoading(true);
                          const { data } =
                            activeIndex === 1
                              ? await deleteBulkCustomer({
                                Ids: deleteIdArray,
                              })
                              : await deleteBulkRecipients({
                                Ids: deleteIdArray,
                              });
                          if (data) {
                            setCustomerData([]);
                            setDeleteBulkCustomerLoading(false);
                            await getCustomerData(false);
                          } else {
                            setDeleteBulkCustomerLoading(false);
                            toast.error(
                              "Error in deleting bulk customers",
                              toastConfiguration
                            );
                          }
                        }}
                        className={cn("button-stroke-red", styles.deleteMarkedBtn)}
                        style={{ width: 150 }}
                      >
                        {deleteBulkCustomerLoading ? (
                          <Spinner size='14' color='gray' />
                        ) : (
                          "Delete Marked"
                        )}
                      </button>
                    )}
                </>
              }
            >
              <Modal
                visible={showModal}
                onClose={() => setShowModal(false)}
                outerClassName={styles.outer}
                children={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 16,
                      marginTop: 24,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 16,
                      }}
                    >
                      <Checkbox
                        onChange={() => {
                          setIsCustomerSelected(!isCustomerSelected);
                          setIsRecipientSelected(false);
                        }}
                        value={isCustomerSelected}
                      />
                      <span>Customer</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 16,
                      }}
                    >
                      <Checkbox
                        onChange={() => {
                          setIsRecipientSelected(!isRecipientSelected);
                          setIsCustomerSelected(false);
                        }}
                        value={isRecipientSelected}
                      />
                      <span>Recipient</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        onClick={() => {
                          if (isCustomerSelected) {
                            setVisible(false);
                            setIsAdd(true);
                          } else {
                            history.push({
                              pathname: "/contacts/add",
                              state: { isComingFromCustomer: true },
                            });
                          }
                        }}
                        className='button'
                      >
                        Done
                      </button>
                    </div>
                  </div>
                }
              />
              <div className={cn(styles.row, { [styles.flex]: show })}>
                <Table
                  setShowAddDeal={setShowAddDeal}
                  setCurrentSelected={setCurrentSelected}
                  showAddDeal={showAddDeal}
                  setShowAdjustPipeline={setShowAdjustPipeline}
                  showAdjustPipeline={showAdjustPipeline}
                  className={styles.table}
                  activeTable={show}
                  setActiveTable={setShow}
                  setShowMainContainer={setShowMainContainer}
                  setVisible={setVisible}
                  setCustomerId={setCustomerId}
                  editCustomer={handleEditCustomer}
                  visibility={visibility}
                  activeIndex={activeIndex}
                  setCustomersType={setCustomersType}
                  customersType={customersType}
                  setActiveIndex={setActiveIndex}
                  setVisibility={setVisible}
                  setActionCustomer={setActionCustomer}
                  setCheckCount={setCheckCount}
                  checkCount={checkCount}
                  customerData={customerData}
                  setCustomerData={setCustomerData}
                  setNextPageInfo={setNextPageInfo}
                  isLoading={isLoading}
                  hasMore={hasMore}
                  isCustomer={isCustomer}
                  setIsCustomer={setIsCustomer}
                  getCustomerData={getCustomerData}
                  deleteIdArray={deleteIdArray}
                  noCustomerButtonClickHandler={() => {
                    if (activeIndex === 2) {
                      setVisible(false);
                      setIsAdd(true);
                    } else {
                      history.push({
                        pathname: "/transactions/add",
                        state: { isComingFromCustomer: true },
                      });
                    }
                  }}
                  setDeleteIdArray={setDeleteIdArray}
                />
              </div>
            </Card>
          </>
        ) : isAdd ? (
          <EditNewCustomer
            activeCustomerTab={activeCustomerTab}
            isAdd={isAdd}
            setIsCustomer={setIsCustomer}
            selectedTypeForAddNewContact={selectedTypeForAddNewContact}
          />
        ) : (
          <Redirect to={`/contacts/${customerId}`} />
        )}
    </>
  );
};

export default CustomerList;
