import React, { useState } from "react";
import styles from "./SaveSuccess.module.sass";
import Modal from "../../../../../../components/Modal";
import Icon from "../../../../../../components/Icon";
import Image from "../../../../../../components/Image";
import Spinner from "../../../../../../utils/spinner";

const SaveSuccess = ({
  showSuccessModal,
  setShowSuccessModal,
  onConfirm,
  downloadPdfHandler,
  subscriptionResponse
}) => {
  const [loadingDownloadPdf, setLoadingDownloadPdf] = useState(false);

  return (
    <>
      <Modal
        visible={showSuccessModal}
        onClose={onConfirm}
        outerClassName={styles.outer}
      >
        <div className={styles.wrap}>
          <img
            className={styles.pic}
            src="/success.gif"
            style={{ width: 440, height: 287 }}
            srcDark="/images/content/takeoff.png"
            alt="delete"
          />
          <div className={styles.email}>
            Fantastic. Your quote is now ready!
          </div>
          <div className={styles.email}>
            Download to sent it to customer: <span style={{ color: "black" }}>{subscriptionResponse?.customerEmail}</span>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 14
          }}>
            <button  
            onClick={async () => 
              {
                setLoadingDownloadPdf(true);
                await downloadPdfHandler(true)
                setLoadingDownloadPdf(false);

              }}
            className="button">

            {loadingDownloadPdf ? (<Spinner size="20" color="gray" />) : ( <><Icon name={"download"} size="20" /><span>Download Pdf</span></>)} 
   
            </button>
            <button onClick={onConfirm} className="button">
              <Icon name="coffee" size="20" />
              Done
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SaveSuccess;
