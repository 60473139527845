import React from 'react';

const HoverComp = ({ content, imgEle , hoverClass }) => {
    return (
        <div style={{ height: "100%" }} className={hoverClass}>
            {imgEle}
            <div style={{
                width: 300,
                border: "2px solid gray",
                borderRadius: "5px",
                padding: 10,
                backgroundColor: "#FFFDF9",
                fontFamily: "tomatoGrotesk",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "15px",
                color: "#6F767E",
                position: 'absolute',
                zIndex: 99999,
                display: 'none'
            }}>
                <blockquote className={"quote"}>
                    {" " + content + " "}
                </blockquote>
            </div>
        </div>

    )
}

export default HoverComp;