import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import rrulelugin from "@fullcalendar/rrule";
import timegrid from "@fullcalendar/timegrid";
import googlecalendar from "@fullcalendar/google-calendar";
import interaction from "@fullcalendar/interaction";
import styles from "./Events.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Table from "./Table";
import AddEditEvent from "./AddEditEvent";
import {
  connectWithGoogleExternalInt,
  disconnectGoogleEmails,
  getAllEvents,
  isGoogleConnected,
  syncGoogleCalendarEvents,
} from "../../../utils/apiCallHanlder";
import {
  convertUTCToLocalTime,
  getRRuleStartDateString,
  dateFormatter,
  getReturnUrl,
  toastConfiguration,
} from "../../../utils/utils";
import Image from "../../../components/Image";
import Icon from "../../../components/Icon";

import Spinner from "../../../utils/spinner";
import { toast, ToastContainer } from "react-toastify";

const Events = (props) => {
  const [showNewEventModal, setShowNewEventModal] = useState(false);
  const [unsyncLoading, setUnSyncLoading] = useState(false);
  const [initialDate, setInitialDate] = useState(new Date()); // set initial date to current date
  const [events, setEvents] = useState([]);
  const [viewType, setviewType] = useState("day");
  const [Arguments, setArguments] = useState("");
  const [googleSync, setGoogleSync] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [startLoading, setStartLoading] = useState(true);
  const [eventSyncLoading, setEventSyncLoading] = useState(false);
  const [lastSyncOn, setLastSyncOn] = useState("Sync Now");
  let currentDate = new Date();
  const [prefilStartDate, setPrefilStartDate] = useState(new Date());
  const [prefilEndDate, setPrefilEndDate] = useState(
    new Date(new Date().getTime() + 60 * 60 * 1000)
  );
  const calendarRef = useRef(null);

  const [editEvent, setEditEvent] = useState(null);

  const getCurrentMonthDate = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentMonthDate = `${currentYear}-${currentMonth}-01`;
    let getdays = getDaysInMonth(currentYear, currentDate.getMonth());
    let currentDaysInMonth = `${currentYear}-${currentMonth}-${getdays}`;
    return { currentMonthDate, currentDaysInMonth };
  };

  function getDaysInMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
  }

  useEffect(() => {
    let { currentMonthDate, currentDaysInMonth } = getCurrentMonthDate();
    setstartDate(currentMonthDate);
    setendDate(currentDaysInMonth);
  }, []);

  const dateClick = (arg) => {
    setArguments(arg);
    setEditEvent(undefined);
    setPrefilStartDate(arg.date);
    setShowNewEventModal(true);
  };

  const eventClick = (info) => {
    let event = {
      title: info.event.title,
      description: info.event.extendedProps.description,
      start: info.event.start,
      end: info.event.end || info.event.start,
      color: info.event.backgroundColor,
      calendarEventId: info.event.extendedProps.calendarEventId,
      googleCaledarEventId: info?.event?.extendedProps?.googleEventId,
      counterPartyId: info?.event?.extendedProps?.counterPartyId,
      counterPartyName: info?.event?.extendedProps?.counterPartyName,
      counterPartyImageUrl: info?.event?.extendedProps?.counterPartyImageUrl,
      email: info?.event?.extendedProps?.counterPartyEmail,
      phone: info?.event?.extendedProps?.counterPartyPhone,
    };

    // const end = new Date(event.start.getTime() + 60 * 60 * 1000);
    // event.end = end;
    setEditEvent(event);
    setShowNewEventModal(true);
  };

  const fetchEvents = async () => {
    // setStartLoading(true);
    const data = await getAllEvents({
      startDate: startDate,
      endDate: endDate,
    });
    if (data && data.data) {
      var eventsForPreparation = data.data.events;
      eventsForPreparation.map((x, index) => {
        x.start = convertUTCToLocalTime(x.start);
        x.end = convertUTCToLocalTime(x.end);
        if (x.rule !== undefined && x.rule != null && x.rule != '') {
          x.rrule = 'RRULE:DTSTART=' + getRRuleStartDateString(x.start) + ';' + x.rule;
        }
      });

      setEvents(eventsForPreparation);
    }
    setStartLoading(false);
  };

  useEffect(async () => {
    if (endDate && !endDate.includes("NaN")) {
      await fetchEvents();
    }
  }, [endDate]);

  useEffect(async () => {
    const { data } = await isGoogleConnected({ syncEvent: true });
    if (data) {
      if (data?.details?.lastEventSyncOn) {
        setLastSyncOn(
          "Last sync on: " + dateFormatter(data?.details?.lastEventSyncOn)
        );
      }

      if (data?.isGoogleConnected) {
        setGoogleSync(true);
      } else {
        setGoogleSync(false);
      }
    }
    // await fetchEvents();
  }, []);

  const ConnectWithGmailHandler = async () => {
    const ReturnUrl = getReturnUrl("studio/1" + "");

    const signInResponse = await connectWithGoogleExternalInt({ ReturnUrl });
    const { data } = signInResponse;
    if (data) {
      window.location = data.redirectURL;
    }
  };

  const syncGoogleCalendarEventsHandler = async () => {
    setEventSyncLoading(true);
    const signInResponse = await syncGoogleCalendarEvents({});
    const { data } = signInResponse;
    setEventSyncLoading(false);

    if (data) {
      await fetchEvents();
    }
  };

  // useEffect(() => {
  //   fetchEvents();
  // }, [startDate]);
  // const options = {
  //   timeFormat: (time) => {
  //     // define a custom time format
  //     let hours = time.hour < 10 ? "0" + time.hour : time.hour;
  //     let minutes = time.minute < 10 ? "0" + time.minute : time.minute;
  //     let meridiem = time.hour < 12 ? "am" : "pm";
  //     return hours + ":" + minutes + meridiem;
  //   },
  //   // other options
  // };

  const getPrevNextMonthDates = (date) => {
    const prevMonthDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    const nextMonthDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return { prevMonthDate, nextMonthDate };
  };

  const handlePrevMonthClick = () => {
    if (
      calendarRef.current.calendar.currentData.currentViewType ===
      "timeGridWeek"
    ) {
      calendarRef.current.getApi().prev();
      let month = calendarRef.current.calendar.currentData.viewTitle;
      weekHandle(month);
    } else if (
      calendarRef.current.calendar.currentData.currentViewType === "timeGridDay"
    ) {
      calendarRef.current.getApi().prev();
      let month = calendarRef.current.calendar.currentData.viewTitle;
      let month1 = new Date(month);
      let setmonth1 = `${month1.getFullYear()}-${month1.getMonth() + 1
        }-${month1.getDate()}`;
      setstartDate(setmonth1);
      setendDate(setmonth1);
    } else if (
      calendarRef.current.calendar.currentData.currentViewType ===
      "dayGridMonth"
    ) {
      calendarRef.current.getApi().prev();
      let month = calendarRef.current.calendar.currentData.viewTitle;
      addDate(month);
    }
  };

  const addDate = (month) => {
    if (month) {
      const currentMonthDate = new Date(month);
      const currentYear = currentMonthDate.getFullYear();
      const currentMonth = currentMonthDate.getMonth() + 1;
      const currentMonthDate1 = `${currentYear}-${currentMonth}-01`;
      let getdays = getDaysInMonth(currentYear, currentMonthDate.getMonth());
      let currentDaysInMonth1 = `${currentYear}-${currentMonth}-${getdays}`;
      setstartDate(currentMonthDate1);
      setendDate(currentDaysInMonth1);
      setInitialDate(new Date(currentMonthDate1));
    }
  };

  const weekHandle = (month) => {
    let monthsplit = month.split(" ");
    // let monthsplit1 = month.split(" - ");

    if (monthsplit.length === 5) {
      let startdate1 = new Date(
        `${monthsplit[monthsplit.length - 1]}-${monthsplit[0]}-${monthsplit[1]}`
      );
      const currentMonth = startdate1.getMonth() + 1;
      let startdate2 = `${monthsplit[monthsplit.length - 1]}-${currentMonth}-${monthsplit[1]
        }`;
      let enddate1 = `${monthsplit[monthsplit.length - 1]}-${currentMonth}-${monthsplit[3]
        }`;
      setstartDate(startdate2);
      setendDate(enddate1);
    } else if (monthsplit.length === 6) {
      let startdate1 = new Date(
        `${monthsplit[monthsplit.length - 1]}-${monthsplit[0]}-${monthsplit[1]}`
      );
      let enddate1 = new Date(
        `${monthsplit[monthsplit.length - 1]}-${monthsplit[3]}-${monthsplit[4]}`
      );
      let startdate2 = `${monthsplit[monthsplit.length - 1]}-${startdate1.getMonth() + 1
        }-${monthsplit[1]}`;
      let enddate2 = `${monthsplit[monthsplit.length - 1]}-${enddate1.getMonth() + 1
        }-${monthsplit[4]}`;
      setstartDate(startdate2);
      setendDate(enddate2);
    }
  };

  const handleNextMonthClick = () => {
    if (
      calendarRef.current.calendar.currentData.currentViewType ===
      "timeGridWeek"
    ) {
      calendarRef.current.getApi().next();
      let month = calendarRef.current.calendar.currentData.viewTitle;
      weekHandle(month);
      // setstartDate(startDate);
      // setendDate(endDate);
    } else if (
      calendarRef.current.calendar.currentData.currentViewType === "timeGridDay"
    ) {
      calendarRef.current.getApi().next();
      let month = calendarRef.current.calendar.currentData.viewTitle;
      let month1 = new Date(month);
      let setmonth1 = `${month1.getFullYear()}-${month1.getMonth() + 1
        }-${month1.getDate()}`;
      setstartDate(setmonth1);
      setendDate(setmonth1);
    } else if (
      calendarRef.current.calendar.currentData.currentViewType ===
      "dayGridMonth"
    ) {
      calendarRef.current.getApi().next();
      let month = calendarRef.current.calendar.currentData.viewTitle;
      addDate(month);
    }
  };

  const handleViewChange = (view) => {
    let month = calendarRef?.current?.calendar?.currentData?.viewTitle;
    addDate(month);
  };

  const customButtons = {
    prevMonthButton: {
      text: "⬅",
      click: handlePrevMonthClick,
    },
    nextMonthButton: {
      text: "➡",
      click: handleNextMonthClick,
    },
  };

  const handleCalendarReady = (calendar) => { };

  return (
    <>
      {props?.value !== "dashboard" && !startLoading && (
        <div className={styles.rightTopBtnWrap}>
          <button
            onClick={() => {
              setEditEvent(undefined);
              setShowNewEventModal(true)
            }}
            className='button'
          >
            Add new event
          </button>
        </div>
      )}

      <Card
        className={styles.card}
      /* title="Events"
  classTitle={cn("title-blue", styles.title)}
  head={
  googleSync ? (
    <>
      <button
        className={cn("button", styles.addProductBtn, styles.synced)}
      >
        <Image
          src="/images/icons/google.svg"
          srcDark="/images/icons/google.svg"
        />
        Your google calendar synced
      </button>
      <button
        onClick={() => {
          setGoogleSync(false);
        }}
        className={cn("button button-stroke", styles.unlink)}
      >
        Unlink
      </button>
    </>
  ) : (
    <button
      onClick={() => {
        setGoogleSync(true);
      }}
      className={cn("button", styles.addProductBtn)}
    >
      <Image
        src="/images/icons/google.svg"
        srcDark="/images/icons/google.svg"
      />
      Sync your google calendar
    </button>
  )
  } */
      //classCardHead={cn(styles.head, { [styles.hidden]: show })}
      //head={
      // <button
      //     onClick={() => {
      //         //setVisible(false);
      //         //setIsAdd(true);
      //     }
      //     }
      //     className={cn("button", styles.addProductBtn)}
      // >
      //     New event
      // </button>
      //}
      >
        <ToastContainer />

        {startLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: window.outerHeight - 450,
            }}
          >
            <Spinner size='50' color='gray' />
          </div>
        ) : true ? (
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, googlecalendar, timegrid, interaction, rrulelugin]}
            initialView='timeGridWeek'
            viewDidMount={handleViewChange}
            // initialDate={initialDate}
            selectable={true}
            // onReady={handleCalendarReady}
            customButtons={customButtons}
            events={events}
            // options={options}

            headerToolbar={{
              left: "timeGridDay,timeGridWeek,dayGridMonth",
              center: "prevMonthButton title nextMonthButton",
              right: "today",
            }}
            weekends={true}
            dateClick={dateClick}
            eventClick={eventClick}
            eventTimeFormat={{
              hour: "numeric",
              minute: "2-digit",
              meridiem: "lowercase",
            }}
            onReady={handleCalendarReady}
          />
        ) : (
          <Table events={events} fetchEvents={fetchEvents} />
        )}

        <AddEditEvent
          forceRefreshEvents={fetchEvents}
          editEvent={editEvent}
          Arguments={Arguments}
          prefilStartDate={prefilStartDate}
          prefilEndDate={prefilEndDate}
          showNewEventModal={showNewEventModal}
          googleSync={googleSync}
          setShowNewEventModal={setShowNewEventModal}
        />
      </Card>
    </>
  );
};

export default Events;