import React, { useEffect, useState } from "react";
import styles from "./AddNewTransactions.module.sass";
import Icon from "../../../components/Icon";
import Card from "../../../components/Card";
import cn from "classnames";
import Income from "./Income";
import Expense from "./Expense";
import {
  GetFileNameFromPath,
  toastConfiguration,
  transactionDateFormatter,
} from "../../../utils/utils";
import Spinner from "../../../utils/spinner";
import { useDropzone } from "react-dropzone";
import {
  addNewTransaction,
  getAccountDetails,
  getTaxRate,
  searchCOAList,
  uploadFile,
} from "../../../utils/apiCallHanlder";
import Image from "../../../components/Image";
import AlternativeAvatar from "../../../components/AlternativeAvatar";
import SearchCounterParty from "../../Accounting/Reconcile/SearchContacts";
import { toast } from "react-toastify";

const transactionToggle = [
  {
    title: "Income",
  },
  {
    title: "Expense",
  },
];

const imageWRTExtension = {
  common: "/images/content/image.png",
  xlsx: "/images/content/xlsx.png",
  xls: "/images/content/xlsx.png",
  pdf: "/images/content/pdf.png",
};

const categories = {
  Software: [
    {
      text: "IT",
      value: "IT",
      display: "IT",
    },
    {
      text: "Software",
      value: "Software",
      display: "Software",
    },
    {
      text: "Saas",
      value: "Saas",
      display: "Saas",
    },
  ],
  Travel: [
    {
      text: "Hotel",
      value: "Hotel",
      display: "Hotel",
    },
    {
      text: "Taxi",
      value: "Taxi",
      display: "Taxi",
    },
    {
      text: "Train",
      value: "Train",
      display: "Train",
    },
  ],
  Entertainment: [
    {
      text: "Resturant",
      value: "Resturant",
      display: "Resturant",
    },
    {
      text: "Gift",
      value: "Gift",
      display: "Gift",
    },
  ],
  Others: [
    {
      text: "Other",
      value: "Other",
      display: "Other",
    },
  ],
};

const AddNewTransactions = ({
  className,
  setShowAddNewTransactions,
  getAccountSwitchTransactions,
}) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [transactionAttachments, setTransactionAttachments] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [blobUrlToDisplay, setBlobUrl] = useState("");
  const [files, setFiles] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const [counterPartyName, setCounterPartyName] = useState("");
  const [counterPartyId, setCounterPartyId] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isNew, setIsNew] = useState(false);

  const [coaId, setCoaId] = useState("");
  const [categoryName, setCategoryName] = useState(
    categories.Software[0].value
  );
  const [selectedTaxRate, setSelectedTaxRate] = useState("");
  const [taxRates, setTaxRates] = useState([]);

  const [taxRateLoading, setTaxRateLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [cashAccountLoading, setCashAccountLoading] = useState(false);
  const [cashAccounts, setCashAccounts] = useState([]);
  const [selectedCashAccount, setSelectedCashAccount] = useState("");

  const [amount, setAmount] = useState(0);
  const [saveLoading, setSaveLoading] = useState(false);
  const [bookLoading, setBookLoading] = useState(false);

  const [options, setOptions] = useState([]);
  const [coaOptions, setCoaOptions] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [subTypeName, setSubTypeName] = useState('');

  useEffect(async () => {
    setTaxRateLoading(true);
    setCashAccountLoading(true);
    await getCOAOptions();
    const { data } = await getTaxRate();
    if (data) {
      setTaxRates(data?.taxRates);
      setSelectedTaxRate(data?.taxRates[0]?.companyTaxId);
    }
    setTaxRateLoading(false);
    const cashAccountsRes = await getAccountDetails(
      {
        onlyAccount: true,
      },
      false
    );
    if (cashAccountsRes.data && cashAccountsRes.data.cashAccounts.length > 0) {
      setCashAccounts(cashAccountsRes.data.cashAccounts);
      setSelectedCashAccount(cashAccountsRes.data.cashAccounts[0].id);
    }
    setCashAccountLoading(false);
  }, []);

  const getCOAOptions = async () => {
    const { data } = await searchCOAList('');
    if (data) {
      const minifiedTypes = data?.list?.map(item => item.name);
      const expensesOptions = data?.list?.filter(item => item.name === "Expenses") || [];

      setOptions(data?.list);
      setCoaOptions(minifiedTypes);
      setSubTypes(expensesOptions);
      setCoaId(expensesOptions[0]?.name);
      setSubTypeName(expensesOptions[0]?.children[0]?.id);
    }
  }

  const deleteImageHandler = async (e, attachmentLink) => {
    setDeleteLoading(true);
    e.stopPropagation();
    setTransactionAttachments(
      transactionAttachments.filter(function (item) {
        return item !== attachmentLink;
      })
    );
    setDeleteLoading(false);
  };

  const { getRootProps } = useDropzone({
    noDragEventsBubbling: true,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        imageUploadHandler(acceptedFiles[0]);
      }
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const imageUploadHandler = async (file) => {
    setImageUploadLoading(true);
    setSelectedImage(file);
    const formData = new FormData();
    formData.append("", file);
    const { data, error } = await uploadFile(formData);

    if (data) {
      const imageUrl = data?.file?.url;

      if (imageUrl) {
        transactionAttachments.push(imageUrl);
        setTransactionAttachments(transactionAttachments);
      }
    }
    else {
      toast.error(error || "Error in uploading file", toastConfiguration);
    }
    setImageUploadLoading(false);
  };

  const saveTransactionDataHandler = async (isBooked) => {
    if (!amount || !description) {
      const label = !amount ? "amount" : "description";
      toast.error(
        `Please add ${label} before proceeding`,
        toastConfiguration
      );
      return;
    }
    const { data, error } = await addNewTransaction({
      bookTransaction: isBooked,
      transactionAccountId: selectedCashAccount,
      type: activeIndex === 1 ? "income" : "expense",
      contactId: counterPartyId,
      contactName: counterPartyName,
      contactEmail: email,
      categoryName: categoryName,
      doCreateCounterParty: isNew,
      categoryId: 0,
      companyTaxId: selectedTaxRate,
      coaId: subTypeName,
      amountValue: amount * 100,
      transactionDate: transactionDateFormatter(startDate),
      Description: description,
      attachments: transactionAttachments,
    });
    if (data) {
      toast.success("Transaction is saved successfully", toastConfiguration);
      setShowAddNewTransactions(false);
      await getAccountSwitchTransactions(isBooked);
    } else {
      toast.error(
        error || "Error in saving transaction data",
        toastConfiguration
      );
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.col} style={{ width: 400 }}>
          <Card
            className={cn(styles.card, className)}
            title='Add new transaction'
          >
            <div>
              {counterPartyId || email || counterPartyName ? (
                <div className={styles.infoWrapper}>
                  <div className={styles.left}>
                    {imageUrl ? (
                      <Image
                        src={imageUrl || "/images/content/avatar.jpg"}
                        srcDark={imageUrl || "/images/content/avatar.jpg"}
                        className={styles.pic}
                      />
                    ) : (
                      <AlternativeAvatar
                        name={counterPartyName}
                        className={"customer-info"}
                      />
                    )}
                    <button
                      onClick={() => {
                        setCounterPartyId(null);
                        setCounterPartyName(null);
                        setEmail(null);
                        setMobile(null);
                        setIsNew(false);
                      }}
                      className={styles.deleteBtn}
                    >
                      <Icon name='trash' size='20' fill='#FF4900' />
                    </button>
                  </div>
                  <div className={styles.infoWrap}>
                    <div className={styles.info}>
                      <p className={styles.label}>Name</p>
                      <p className={styles.txt}>{counterPartyName || ""}</p>
                    </div>
                    <div className={styles.info}>
                      <p className={styles.label}>Email</p>
                      <p className={styles.txt}>{email || ""}</p>
                    </div>
                    <div className={styles.info}>
                      <p className={styles.label}>Mobile</p>
                      <p className={styles.txt}>{mobile || ""}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.field}>
                  <div className={styles.fieldLabel}>Contact</div>
                  <SearchCounterParty
                    className={styles.search}
                    counterPartyName={counterPartyName}
                    inputPlaceholder='Name of contact'
                    setIsNew={setIsNew}
                    setCounterPartyName={setCounterPartyName}
                    isNew={isNew}
                    showDetails={(x) => {
                      setImageUrl(x?.imageUrl);
                      setEmail(x?.email);
                      setMobile(x?.mobile);
                      setCounterPartyId(x?.id);
                      setCounterPartyName(x?.name);
                    }}
                  />
                </div>
              )}
            </div>

            <div
              className={styles.typeNav}
              style={{
                marginTop: counterPartyId || counterPartyName ? 20 : 0,
              }}
            >
              {transactionToggle.map((x, index) => (
                <div
                  className={cn(styles.item, {
                    [styles.active]: index + 1 === activeIndex,
                  })}
                  key={index}
                  onClick={() => setActiveIndex(index + 1)}
                >
                  {x.title}
                </div>
              ))}
            </div>
            <Income
              setStartDate={setStartDate}
              startDate={startDate}
              setCOAId={setCoaId}
              coaId={coaId}
              setCategoryName={setCategoryName}
              categoryName={categoryName}
              categories={categories}
              setSelectedTaxRate={setSelectedTaxRate}
              selectedTaxRate={selectedTaxRate}
              taxRates={taxRates}
              taxRateLoading={taxRateLoading}
              setDescription={setDescription}
              cashAccounts={cashAccounts}
              cashAccountLoading={cashAccountLoading}
              setSelectedCashAccount={setSelectedCashAccount}
              selectedCashAccount={selectedCashAccount}
              setAmount={setAmount}
              options={options}
              coaOptions={coaOptions}
              setSubTypes={setSubTypes}
              subTypeName={subTypeName}
              setSubTypeName={setSubTypeName}
              subTypes={subTypes}
            />
          </Card>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16,
              paddingLeft: 20,
            }}
          >
            <button
              onClick={async () => {
                if (saveLoading) {
                  return;
                }
                if (amount > 999999) {
                  toast.error(
                    "The total amount must be no more than $999,999.99 usd.",
                    toastConfiguration
                  );
                  return;
                }
                setSaveLoading(true);
                await saveTransactionDataHandler(false);
                setSaveLoading(false);
              }}
              className={cn("button", styles.addNew)}
              style={{
                width: 75,
              }}
            >
              {saveLoading ? (
                <Spinner size={24} color={"white"} />
              ) : (
                <span>Save</span>
              )}
            </button>

            <button
              onClick={async () => {
                if (bookLoading) {
                  return;
                }
                if (amount > 999999) {
                  toast.error(
                    "The total amount must be no more than $999,999.99 usd.",
                    toastConfiguration
                  );
                  return;
                }
                setBookLoading(true);
                await saveTransactionDataHandler(true);
                setBookLoading(false);
              }}
              className={cn("button", styles.addNew)}
              style={{
                backgroundColor: "rgb(52, 168, 83)",
                width: 261,
              }}
            >
              {bookLoading ? (
                <Spinner size={24} color={"white"} />
              ) : (
                <span>Book Transaction</span>
              )}
            </button>
          </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.col12} style={{ width: 400 }}>
          <Card title='Attachment' className={styles.card}>
            {imageUploadLoading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ width: "70%" }}>{selectedImage?.name}</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "30%",
                  }}
                >
                  <Spinner
                    size='24px'
                    color='gray'
                    loading={imageUploadLoading}
                  />
                </div>
              </div>
            ) : transactionAttachments < 1 ? (
              <div {...getRootProps({ className: "dropzone" })}>
                <div className={styles.dropzoneWrap}>
                  <p className={styles.dropzone}>
                    <Icon name='file-add' size='72' />
                    <span>
                      Drag and drop here or click to upload (PDF/JPG/PNG)
                    </span>
                  </p>
                </div>
              </div>
            ) : null}

            <div className={styles.imgInput}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {transactionAttachments?.map((x, index) => {
                  const fileNameObj = GetFileNameFromPath(x, true);
                  return (
                    <>
                      <div className={styles.selectedImageWrap}>
                        {fileNameObj.extension === "pdf" ? (
                          <iframe
                            src={
                              [].attachmentCount > 0
                                ? blobUrlToDisplay
                                : files[0]?.preview + "#toolbar=0"
                            }
                            height={300}
                            width={250}
                            style={{ pointerEvents: "auto" }}
                          />
                        ) : (
                          <img
                            className={styles.selectedImage}
                            src={
                              fileNameObj.extension === "png" ||
                                fileNameObj.extension === "gif" ||
                                fileNameObj.extension === "jpg" ||
                                fileNameObj.extension === "jpeg"
                                ? x
                                : imageWRTExtension[fileNameObj.extension] ||
                                imageWRTExtension["common"]
                            }
                            hideZoom
                          />
                        )}
                        {activeIndex !== 2 && (
                          <div className={styles.action}>
                            {deleteLoading ? (
                              <>
                                <div className={styles.modal}>
                                  <div
                                    style={{
                                      position: "fixed",
                                      left: "50%",
                                      top: "45%",
                                    }}
                                  >
                                    <Spinner size='48' color='gray' />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <a
                                  href={x}
                                  download={
                                    fileNameObj?.fileName +
                                    fileNameObj?.extension
                                  }
                                  style={{
                                    cursor: "pointer",
                                    marginRight: 8,
                                  }}
                                >
                                  <Icon name='download' size='24' />
                                </a>
                                <button
                                  className={styles.dropImgDelete}
                                  onClick={async (e) => {
                                    deleteImageHandler(e, x);
                                  }}
                                >
                                  <Icon name='trash' size='24' />
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddNewTransactions;
