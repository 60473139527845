import React, {useState} from "react";
import cn from "classnames";
import styles from "./Code.module.sass";
import Loader from "../../../components/Loader";
import Icon from "../../../components/Icon";
import Spinner from "../../../utils/spinner";
import OTPInput from "react-otp-input";

const Code = ({ValidateEmailPinCodeHandler, loadingPin, loadingResendPin, ResendEmailPinCodeHandler}) => {
  const [OTP, setOTP] = useState("");
  function handleChange(OTP) {
    setOTP(OTP);
  }
  return (
    <div className={styles.code}>
      <div className={styles.body}>
        <OTPInput
          onChange={handleChange}
          value={OTP}
          containerStyle={styles.fieldset}
          inputStyle={styles.input}
          numInputs={4}
          separator={<span></span>}
        />
        {/* <div className={styles.fieldset}>
          <div className={styles.field}>
            <input
              className={styles.input}
              name="number1"
              type="tel"
              autocomplete="off"
              required
            />
          </div>
          <div className={styles.field}>
            <input
              className={styles.input}
              name="number2"
              type="tel"
              autocomplete="off"
              required
            />
          </div>
          <div className={styles.field}>
            <input
              className={styles.input}
              name="number3"
              type="tel"
              autocomplete="off"
              required
            />
          </div>
          <div className={styles.field}>
            <input
              className={styles.input}
              name="number4"
              type="tel"
              autocomplete="off"
              required
            />
          </div>
        </div> */}
        <div className={styles.errorNote}>
          The code you entered is incorrect.
        </div>
        <div className={styles.resendWrap}>
          <p>Didn't receive the code?</p>
          <button onClick={() => ResendEmailPinCodeHandler()}>
          {loadingResendPin ?
                  (<><Spinner loading={loadingResendPin} size={30} /></>)
                  : ("Resend")}

          </button>
        </div>
        <button onClick={() => ValidateEmailPinCodeHandler({pin:OTP})} className={cn("button", styles.button)}>
         
         
          {loadingPin ?
                  (<><Spinner loading={loadingPin} size={30} /></>)
                  : (<>
                   <span>Continue</span></>)}

        </button>
      </div>
    </div>
  );
};

export default Code;
