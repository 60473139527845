import React, { useEffect, useState } from "react";
import styles from "./Integration.module.sass";
import { useHistory } from "react-router";
import cn from "classnames";
import Spinner from "../../../../utils/spinner";
import {
    getIntegrationSettings,
    updateZapierIntegrationSettings,
} from "../../../../utils/apiCallHanlder";
import Tooltip from "../../../../components/Tooltip";
import Switch from "../../../../components/Switch";
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import { toast, ToastContainer } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";


const Integration = () => {
    const [loading, setLoading] = useState(true);
    const [isZapierIntegrationOn, setIsZapierIntegrationOn] = useState(false);
    const [zapierIntegrationEmail, setZapierIntegrationEmail] = useState('');
    const [zapierIntegrationSecret, setZapierIntegrationSecret] = useState('');

    useEffect(async () => {
        const { data } = await getIntegrationSettings();
        if (data) {
            setIsZapierIntegrationOn(data.isZapierOn);
            setZapierIntegrationEmail(data.zapierEmail);
            setZapierIntegrationSecret(data.zapierSecret);
        }
        setLoading(false);
    }, []);

    const switchIntegrationState = async (newState) => {
        const { data, error } = await updateZapierIntegrationSettings(newState);
        if (data) {
            setIsZapierIntegrationOn(data.isZapierOn);
            setZapierIntegrationEmail(data.zapierEmail);
            setZapierIntegrationSecret(data.zapierSecret);
        }
    }

    return (
        <>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: 120
                    }}
                >
                    <Spinner size='48px' color='gray' />
                </div>
            ) : (
                <Card
                    className={cn(styles.card)}
                    classCardHead={styles.head}
                    title={
                        <>
                            <Switch
                                className={styles.switch}
                                value={isZapierIntegrationOn}
                            />
                            <span>Zapier integration</span>
                        </>
                    }>
                    <ToastContainer />
                    <div style={{
                        display: "flex",
                        alignItems: "left",
                        cursor: "default"
                    }}>
                            <button
                                className={cn("button-stroke button-small", styles.button)}
                                onClick={() => {
                                    switchIntegrationState(!isZapierIntegrationOn);
                                }}>
                                {isZapierIntegrationOn ? 'Turn Off' : 'Turn On'}
                            </button>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.wrapper}>
                            <div className={styles.table}>
                                <div className={styles.row}>
                                    <div className={styles.col}>Username</div>
                                    <div className={styles.col}>Secret</div>
                                    <div className={styles.col}></div>
                                    <div className={styles.col}></div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.col}>
                                        <p>Username</p>
                                        <p>{zapierIntegrationEmail}</p>
                                    </div>
                                    <div className={styles.col}>
                                        <p>Secret</p>
                                        <p>
                                            {zapierIntegrationSecret ? zapierIntegrationSecret : "******"}
                                        </p>
                                    </div>
                                    <div className={styles.col}>
                                        {zapierIntegrationSecret && zapierIntegrationSecret.length > 10 ?
                                                <div
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigator?.clipboard?.writeText(zapierIntegrationSecret);
                                                        toast.success("Copied successfully.", toastConfiguration);
                                                    }}>

                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#9b9b9b"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                                    </svg>

                                                </div>
                                                : null }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            )}
        </>
    );
};

export default Integration;
