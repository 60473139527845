import React, { useEffect, useState } from "react";
import Icon from "../../../../components/Icon";
import Image from "../../../../components/Image";
import {
  getContactEmails,
  getCustomer,
} from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import cn from "classnames";
import styles from "./Emails.module.sass";
import Modal from "./Modal/modal";
import { dateFormatterWithTime, dateFormatterWithTimeZone, toTitleCase } from "../../../../utils/utils";
import AlternativeAvatar from "../../../../components/AlternativeAvatar";

const emailsInfo = [
  {
    subject: "Quote 9878",
    created: "jul 22, 2022",
    status: "Opened",
    type: "Manual",
  },
  {
    subject: "Gotham Limited LLC",
    created: "jul 22, 2022",
    status: "Recipient",
    type: "Automated",
  },
];

const Emails = ({
  id,
  name,
  email,
  mobileCode,
  mobile,
  imageUrl,
  setSyncWithGoogleVisible,
  emails,
  setEmails,
  nextPageInfo,
  setNextPageInfo,
  isNextPageExists,
  setIsNextPageExists,
  startLoading,
  setStartLoading,
  getEmails
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedEmailContent, setSelectedEmailContent] = useState(null);

  console.log(startLoading)

  useEffect(async () => {
    setEmails([]);
    setNextPageInfo(null);
    setIsNextPageExists(false);
    await getEmails(false);
  }, []);

  const onClose = () => {
    setOpenModal(false);
  };
  return (
    <>
      <div className={styles.infoWrapper}>
        <Modal
          customerId={id}
          visible={openModal}
          onClose={onClose}
          replyToEmail={email}
          selectedEmailContent={selectedEmailContent}
        />

        {imageUrl ?
          <Image
            src={imageUrl || "/images/content/avatar.jpg"}
            srcDark={imageUrl || "/images/content/avatar.jpg"}
            className={styles.pic}
          />
          :
          <AlternativeAvatar name={name} className={'small-icon'} />
        }
        <div className={styles.infoWrap}>
          <div className={styles.info}>
            <p className={styles.label}>Name</p>
            <p className={styles.txt}>{name}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.label}>Email</p>
            <p className={styles.txt}>{email}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.label}>Mobile</p>
            <p className={styles.txt}>
              {mobileCode} {mobile}
            </p>
          </div>
        </div>
      </div>
      {startLoading && emails?.length < 1 ? (
        <div className={styles.spinner}>
          <Spinner loading={startLoading} size={50} color={"gray"} />
        </div>
      ) : (
        <>
          <div className={styles.table}>
            <div className={styles.head}>
              <div className={styles.col}>Subject</div>
              <div className={styles.col}>Created</div>
              <div className={styles.col}>Status</div>
              <div className={styles.col}>Type</div>
            </div>
            {emails.map((x, index) => (
              <div
                className={styles.row}
                key={index}
                onClick={() => {
                  setSelectedEmailContent(x);
                  setOpenModal(true);
                }}
              >
                <div className={styles.col}>
                  <p className={styles.mblLabel}>Subject</p>
                  <span>{x.subject || "(No Subject)"}</span>
                </div>
                <div className={styles.col} style={{ width: 200 }}>
                  <p className={styles.mblLabel}>Created</p>
                  <span>{dateFormatterWithTime(x.date, true)}</span>
                </div>
                <div className={styles.col} style={{ textAlign: "center" }}>
                  {x?.attachements?.length > 0 && (
                    <Icon size="24" name="attachment" viewBox="0 0 24 24" />
                  )}
                </div>
                <div className={styles.col}>
                  <p className={styles.mblLabel}>Type</p>
                  <span>{toTitleCase(x.boundType || "")}</span>
                </div>
              </div>
            ))}
          </div>
          {isNextPageExists ? (
            <div className={styles.foot}>
              <button
                className={cn("button-stroke-red button-small", styles.button)}
                onClick={() => getEmails(true)}
                style={{ width: 200 }}
              >
                {startLoading ? (
                  <Spinner loading={startLoading} size={20} color={"gray"} />
                ) : (
                  <>
                    <span>See More Emails</span>
                    <Icon name="arrow-next" size="20"></Icon>
                  </>
                )}
              </button>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default Emails;
