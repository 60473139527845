import React, { useState } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Row from "./Row";
import DeleteModal from "../../../../components/DeleteModal";
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import {
  Checkbox,
  TriStateCheckbox,
  TriStateContext,
} from "react-tri-state-checkbox";
import "react-tri-state-checkbox/styles/checkbox.css";
import Spinner from "../../../../utils/spinner";

import { documentsDetails } from "../../../../mocks/documentsDetails";

const Table = ({
  className,
  editAction,
  editDocument,
  deleteItem,
  documents,
  isNextPageExist,
  fetchNextPage,
  nextPageLoading,
  attachmentClickHandler
}) => {

  const handleChange = (e) => { }

  const ledgerId = documentsDetails
    .map((x) => x.id);

  return (
    <>
      <Card className={styles.card}>
        <TriStateContext controls={ledgerId}>
          <div className={cn(styles.wrapper, className)}>
            <div className={cn(styles.table)}>
              <div className={cn(styles.row)}>
                <div className={styles.editOverlayHead}></div>
                {/* <div className={styles.col}>
                  <TriStateCheckbox
                    className={styles.triHead}
                  ></TriStateCheckbox>
                </div> */}
                <div className={styles.col}>ID</div>
                {/* <div className={styles.col}>DOC</div> */}
                <div className={styles.col}>Document Name</div>
                <div className={styles.col}>Uploaded</div>
                <div className={styles.col}>Status</div>
                <div className={styles.col}>Type</div>
                <div className={styles.col}></div>
              </div>
              {documents.map((x, index) => (
                <Row
                  item={x}
                  attachmentClickHandler={attachmentClickHandler}
                  key={index}
                  singleCheck={
                    <Checkbox
                      className={cn(
                        x.image ? styles.triCheckboxImg : styles.triCheckbox
                      )}
                      id={x.documentFileId}
                      onChange={handleChange}
                    />
                  }
                  editDocument={editDocument}
                  deleteItem={deleteItem}
                />
              ))}

            </div>
          </div>
          {isNextPageExist ?
            <div className={styles.foot}>
              <button
                className={cn("button-stroke button-small", styles.button)}
                onClick={() => { fetchNextPage(); }}
                style={{ width: 150 }}
              >
                {nextPageLoading ?
                  <Spinner loading={nextPageLoading} size="15px" color="gray" />
                  :
                  <>
                    <span>Load More</span>
                    <Icon name="arrow-next" size="20"></Icon>
                  </>
                }
              </button>
            </div>
            : null}
        </TriStateContext>
      </Card>
    </>
  );
};

export default Table;
