import React, { useEffect, useState } from "react";
import styles from "./Spaces.module.sass";
import cn from "classnames";
import Row from "./Row";
import Spinner from "../../../utils/spinner";
import TextInput from "../../../components/TextInput";
import Card from "../../../components/Card";
import Modal from "../../../components/Modal";
import { addToDOSpaces, deleteToDoSpace, getToDOSpaces, updateToDOSpaces } from "../../../utils/apiCallHanlder";
import { ToastContainer, toast } from "react-toastify";
import { toastConfiguration } from "../../../utils/utils";
import DeleteModal from "../../../components/DeleteModal";
import Icon from "../../../components/Icon";

const Spaces = ({ spaceAddNew, setSpaceAddNew }) => {
    const [loading, setLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [addLoading, setAddLoading] = useState(false);

    const [name, setName] = useState('');
    const [spaces, setSpaces] = useState([]);
    const [selectedSpaceId, setSelectedSpaceId] = useState(null);

    useEffect(() => {
        getSpaces();
    }, [])

    const getSpaces = async () => {
        setLoading(true);
        const { data } = await getToDOSpaces();
        if (data) {
            setSpaces(data?.list || []);
        }
        setLoading(false);
    }

    const spaceAddModalCloseHandler = () => {
        setSelectedSpaceId(null);
        setName('');
        setSpaceAddNew(false);
    }

    const addUpdateSpaceHandler = async () => {
        setAddLoading(true);
        const { data, error } = selectedSpaceId ?
            await updateToDOSpaces({ title: name, toDoSpaceId: selectedSpaceId }) :
            await addToDOSpaces({ title: name });

        if (data) {
            spaceAddModalCloseHandler();
            getSpaces();
            toast.success(`Successfully ${selectedSpaceId ? 'updated' : 'added'} space`, toastConfiguration);
        } else {
            toast.error(error || `Error in ${selectedSpaceId ? 'updating' : 'adding'} space`, toastConfiguration);
        }

        setAddLoading(false);
    }

    const deleteHandler = async (id) => {
        setDeleteLoading(true);

        const { data, error } = await deleteToDoSpace(id);
        if (data) {
            getSpaces();
            toast.success('Space deleted successfully', toastConfiguration);
        } else {
            toast.error(error || 'Error in deleting space', toastConfiguration);
        }

        setDeleteLoading(false);
    }

    return (
        <>
            <ToastContainer />
            <Modal
                visible={spaceAddNew}
                outerClassName={styles.modalOuter}
                onClose={spaceAddModalCloseHandler}
                hideCloseButton={true}
                children={
                    <Card
                        title={"Spaces"}
                        head={
                            <button className='button' onClick={addUpdateSpaceHandler} style={{ width: 90 }}>
                                {addLoading ?
                                    <Spinner size="24" color="white" />
                                    :
                                    "Save"
                                }
                            </button>
                        }
                        classCardHead={styles.cardHeader}
                    >
                        <div >
                            <TextInput
                                onChange={(e) => setName(e.target.value)}
                                classInput={styles.input}
                                value={name}
                                label='Name'
                                name='name'
                                type='text'
                                required
                            />
                        </div>

                    </Card>
                }
            />
            <div className={cn(styles.table)}>
                <>
                    <div className={cn(styles.row)}>
                        <div className={styles.col}>Name</div>
                    </div>
                    {loading ?
                        <div className={styles.spinner}>
                            <Spinner size="48" color="gray" />
                        </div>
                        :
                        spaces?.length > 0 ?
                            spaces?.map((x, index) => (
                                <Row
                                    item={x}
                                    key={index}
                                    rowClickHandler={() => {
                                        setName(x?.title);
                                        setSelectedSpaceId(x?.toDoSpaceId);
                                        setSpaceAddNew(true);
                                    }}
                                    deleteModal={
                                        <DeleteModal
                                            className={styles.deleteBtn}
                                            btnClass={' '}
                                            icon={<Icon name="trash" size="24" fill="#FF4900" />}
                                            btnTxt={' '}
                                            deleteBtn={
                                                <button
                                                    className="button"
                                                    style={{ width: 150 }}
                                                    onClick={() => deleteHandler(x?.toDoSpaceId)}
                                                >
                                                    {deleteLoading ?
                                                        <Spinner size="24" color="white" loading={deleteLoading} />
                                                        :
                                                        "Yes I'm sure!"
                                                    }
                                                </button>
                                            }
                                        />
                                    }
                                />

                            ))
                            :
                            <div className={styles.noData}>
                                <span>No Spaces to show</span>
                            </div>
                    }
                </>
            </div>
        </>
    );
};

export default Spaces;
