export const accountsLedger = [
    {
      id: 1,
      no: 1029,
      accName: "Checking Account - ABC Bank",
      type: "Expense",
      debit: 120989,
      credit: 0,
      balance: 0,
      editIcon: "edit",
      deleteIcon: "trash",
    },
    {
      id: 2,
      no: 1029,
      accName: "Checking Account - ABC Bank",
      type: "Expense",
      debit: 120989,
      credit: 0,
      balance: 0,
      editIcon: "edit",
      deleteIcon: "trash",
    },
    {
      id: 3,
      no: 1029,
      accName: "Checking Account - ABC Bank",
      type: "Expense",
      debit: 120989,
      credit: 0,
      balance: 0,
      editIcon: "edit",
      deleteIcon: "trash",
    },
    {
      id: 4,
      no: 1029,
      accName: "Checking Account - ABC Bank",
      type: "Expense",
      debit: 120989,
      credit: 0,
      balance: 0,
      editIcon: "edit",
      deleteIcon: "trash",
    },
  ];