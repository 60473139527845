import React, { useState } from "react";
import Card from "../../../../components/Card";
import styles from "./PersonalInformation.module.sass";
import TextInput from "../../../../components/TextInput";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import Dropdown from "../../../../components/Dropdown";
import { NAME_FIELD_ERROR } from "../../../../utils/appConstanst";

const countries = ["USA", "UK", "Spain"];

const PersonalInformation = ({ className,
  setSelectCountries,
  nameSetHandler,
  lastNameSetHandler,
  selectCountries,
  errorMessage,
  nameError
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Personal Information"
        /* classTitle="title-yellow" */
      >
        <div className={styles.nameWrapper}>
          <TextInput
            className={nameError ? styles.hasError : styles.field}
            name="fname"
            type="text"
            onChange={nameSetHandler}
            placeholder="Jane"
            label="First Name"
            required
          />
        </div>
        {
          errorMessage === NAME_FIELD_ERROR ?
            <span className={styles.errorMessage}>{errorMessage}</span>
            :
            null
        }
        <TextInput
          className={styles.field}
          name="lname"
          type="text"
          onChange={lastNameSetHandler}
          placeholder="Doe"
          label="Last Name"
          required
        />
        <div className={styles.label}>Country</div>
        <Dropdown
          className={styles.dropdown}
          name="countries"
          classDropdownHead={styles.dropdownHead}
          value={selectCountries}
          setValue={setSelectCountries}
          options={countries}
          small
        />
      </Card>
    </>
  );
};

export default PersonalInformation;
