import React, { useEffect, useState } from "react";
import Card from "../../../components/Card";
import cn from "classnames";
import styles from "./BankAccount.module.sass";
import Icon from "../../../components/Icon";
import { getCardDetails } from "../../../utils/apiCallHanlder";
import Spinner from "../../../utils/spinner";
import Amount from "../../../components/Amount";

const bankAccountToggle = [
  {
    title: "Domestic",
    icon: "crosshairs",
  },
  {
    title: "International",
    icon: "internet",
  },
];


const BankAccount = ({ className }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [bankAccount, setBankAccount] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    const { data } = await getCardDetails({
      "onlyBank": true,
    });
    if (data) {
      setBankAccount(data?.bankAccount);
    }

    setLoading(false);
  }, [])



  const domesticAccount = [
    {
      title: "Account Holder Name",
      text: bankAccount.accountHolderName,
    },
    {
      title: "Currency",
      text: bankAccount.currency,
    },
    {
      title: "Account Number",
      text: bankAccount.accountNumber,
    },
    {
      title: "Routing Number",
      text: bankAccount.accountRoutingNumber,
    },
    {
      title: "Bank Name",
      text: bankAccount.accountBankName,
    },
  ];

  return (
    <Card
      className={cn(styles.card, className)}
      title="My Bank Account"
      /* classTitle="title-blue" */
    >
      {loading ?
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner size="40" color={"gray"} />
        </div>
        :
        <>
          <div className={styles.balanceWrap}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p>Balance</p>
              <div onClick={() => setIsVisible(!isVisible)} className={cn(styles.icon, !isVisible ? null : styles.revertIcon)}></div>
            </div>
            <h1 className={styles.amount}>
              <Amount amount={bankAccount.balance} subtitleEmValue={"0.7em"} />
            </h1>
          </div>
          {/* <div className={styles.typeNav}>
        {bankAccountToggle.map((x, index) => (
          <div
            className={cn(styles.item, {
              [styles.active]: index + 1 === activeIndex,
            })}
            key={index}
            onClick={() => setActiveIndex(index + 1)}
          >
            <Icon name={x.icon} size="16" />
            {x.title}
          </div>
        ))}
      </div> */}
          {/* {activeIndex === 1 &&  */}

          {isVisible ?
            <>
              <div className={styles.singleInfoBalance}>
                <div className={styles.title}>{"Stripe Balance"}</div>
                <div className={styles.text}>
                  <Amount amount={bankAccount.stripeBalance} subtitleEmValue={"0.6em"} />
                </div>
              </div>

              <div className={styles.singleInfoBalance}>
                <div className={styles.title}>{"Bank Account"}</div>
                <div className={styles.text}>
                  <Amount amount={bankAccount.bankBalance} subtitleEmValue={"0.6em"} />
                </div>
              </div>


              {domesticAccount.map((x, index) => (
                <div key={index} className={styles.singleInfo}>
                  <div className={styles.title}>{x.title}</div>
                  <div className={styles.text}>{x.text}</div>
                </div>
              ))}
            </>
            : null
          }
        </>
      }
    </Card>
  );
};

export default BankAccount;
