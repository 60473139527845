import React, { useState } from 'react';

import styles from './OfferRecieved.module.sass';


const paymentPlans = [
    {
        name: 'Day 1',
        graph1Height: 2,
        graph2Height: 130,
        amount: 120
    },
    {
        name: 'Day 2',
        graph1Height: 2.5,
        graph2Height: 170,
        amount: 186
    },
    {
        name: 'Day 3',
        graph1Height: 1.5,
        graph2Height: 90,
        amount: 95
    },
    {
        name: 'Day 3',
        graph1Height: 1.5,
        graph2Height: 90,
        amount: 95
    },
    {
        name: 'Day 3',
        graph1Height: 2.5,
        graph2Height: 120,
        amount: 120
    },
    {
        name: 'Day 5',
        graph1Height: 2.5,
        graph2Height: 120,
        amount: 120
    }
]

const OfferRecieved = ({
    applicationData,
    applicationOfferId,
    setApplicationOfferId,
}) => {
    return <div className={styles.container}>
        <span className={styles.title}>You are pre-qualified for a loan offer</span>
        <div className={styles.label}>Select your offer</div>
        <div className={styles.offerSelectionContainer}>
            {applicationData?.offer?.offerDetails?.map((item, index) => {
                return <div
                    className={applicationOfferId === item.capitalApplicationOfferDetailId ? styles.selectedOffer : styles.offer}
                    key={index}
                    onClick={() => setApplicationOfferId(item.capitalApplicationOfferDetailId)}
                >
                    <span>${item.amount}</span>
                    <span>${item.fee} loan fee</span>
                    <span>{item.repaymentRate}% repayment rate</span>
                </div>
            })}
        </div>
        <div className={styles.detailContainer}>
            <span className={styles.disclaimer}>Example payments based on daily sales</span>
            <b className={styles.disclaimerDetail}>{
                applicationData?.offer?.offerDetails?.find(item => item.capitalApplicationOfferDetailId === applicationOfferId)?.repaymentRate
            }% repayment rate (automatically deducted)</b>
        </div>
        <div className={styles.paymentPlanContainer}>
            {paymentPlans?.map((item, index) => {
                const selected = applicationData?.offer?.offerDetails?.find(item => item.capitalApplicationOfferDetailId === applicationOfferId)
                return <div className={styles.paymentPlan} key={index}>
                    <span className={styles.daySale}>{index === 4 ? 'No payment' : `$${item.amount}`}</span>

                    {index !== 4 && (
                        <div>
                            <div className={styles.graph1} style={{ height: item.graph1Height * selected?.repaymentRate }}></div>
                            <div className={styles.graph2} style={{ height: item.graph2Height }}></div>
                        </div>
                    )}

                    <div className={styles.flexColumn}>
                        <span className={styles.day}>Day {index + 1}</span>
                    </div>
                </div>
            })}
        </div>
        {/* <span className={styles.title}>If you accept, we’ll pay out the amount to your stripe balance within 24 hours</span> */}
    </div>
}

export default OfferRecieved;