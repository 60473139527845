import React, { useState } from "react";
import styles from "./Email.module.sass";
import NoEmail from "./NoEmail";
import NewEmailForm from "./NewEmailForm";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import SingleEmail from "./SingleEmail";
import { useEffect } from "react";
import { connectWithGoogleExternalInt, disconnectGoogleEmails, getAllEmailsStudio, getEmails, isGoogleConnected } from "../../../utils/apiCallHanlder";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../utils/spinner";
import ModalDetail from '../../Customers/EditNewCustomer/Emails/Modal/modal';
import cn from 'classnames';
import Image from "../../../components/Image";
import SingleMailbox from "./SingleMailbox";
import SendNewEmail from "./SendNewEmail";
import { dateFormatterWithTime, dateFormatterWithTimeZone, getReturnUrl, toastConfiguration, toTitleCase } from "../../../utils/utils";
import Dropdown from "../../../components/Dropdown";
import { useHistory } from "react-router";

const emailTabs = [
  "Templates",
  "Mailbox",
];

let page = 1;
let mailBoxEmail = 1;
const Email = ({
  setSinglePageStatus,
  getEmailFunction,
  emails,
  setEmails,
  isNextPageExists,
  startLoading
}) => {
  const [showEmail, setShowEmail] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1)
  const [showSendNewEmail, setShowSendNewEmail] = useState(false)
  const [mailBoxEmails, setMailBoxEmails] = useState([]);

  const [mainBoxLoading, setMainBoxLoading] = useState(true);

  const [isNextPageExistsMailBox, setIsNextPageExistsMailBox] = useState(false);
  const [emailTab, setEmailTab] = useState(emailTabs[0])

  const [unsyncLoading, setUnSyncLoading] = useState(false);
  const [nextPageInfo, setNextPageInfo] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [selectedEmailContent, setSelectedEmailContent] = useState(null);

  const history = useHistory();

  useEffect(async () => {
    await getMailBoxEmails(1);
  }, [])


  const getMailBoxEmails = async (currentPage) => {
    setMainBoxLoading(true);
    const { data } = await getEmails({
      pageSize: 30,
      nextPageInfo: currentPage === 1 ? null : nextPageInfo
    });
    if (data) {
      setIsNextPageExistsMailBox(data?.isNextPageExists);
      setNextPageInfo(data?.nextPageInfo)
      if (currentPage === 1) {
        setMailBoxEmails(data?.emails)
      } else {
        setMailBoxEmails(mailBoxEmails.concat(data?.emails))
      }
    }
    setMainBoxLoading(false);
  }


  const create = (newEmail) => {
    setEmails([...emails, newEmail]);
  };

  const remove = (id) => {
    setEmails(emails.filter((email) => email.id !== id));
  };

  const update = (updateEmail) => {
    const updateEmails = emails.map((email) => {
      if (email.id === updateEmail.id) {
        return {
          ...email,
          emailName: updateEmail.emailName,
          trixEditorValue: updateEmail.trixEditorValue,
        };
      }
      return email;
    });
    setEmails(updateEmails);
  };

  useEffect(() => {
    emails.length === 0 ? setShowEmail(false) : setShowEmail(true);
  }, [emails]);

  const onClose = () => {
    setOpenModal(false);
  };


  return (
    <>
      {!visible ? (
        <div className={styles.email}>
          <Dropdown
            options={emailTabs}
            value={emailTab}
            setValue={setEmailTab}
            handleChangeIndex={(index) => {
              setActiveIndex(index + 1)
            }}
            className={styles.dropdown}
          />
          {/* <div className={styles.typeNav}>
        {emailTabs.map((x, index) => (
          <div
            className={cn(styles.item, {
              [styles.active]: index + 1 === activeIndex,
            })}
            key={index}
            onClick={() => {
              setActiveIndex(index + 1);
            }}
          >
            {x}
          </div>
        ))}
      </div> */}
          <ToastContainer />
          {/* {(startLoading && emails?.length < 1) || (mainBoxLoading && mailBoxEmails?.length < 1) ? */}
          <>
            {activeIndex === 1 ? (
              <>
                {(startLoading && emails?.length < 1) ?
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                    <Spinner size="64" color="gray" />
                  </div>
                  :
                  <>
                    <div className={styles.addNewBtn}>
                      <button
                        className={"button"}
                        onClick={() => history.push("/studio/2/add")}
                      >
                        Add new template
                      </button>
                    </div>
                    {showEmail ? (
                      <>
                        <div className={styles.emailWrap}>
                          <div className={styles.wrap}>
                            <div className={styles.emailHead}>
                              <p>Name</p>
                              <p>Subject</p>
                              <p></p>
                            </div>
                            {emails?.map((x, index) => (
                              <SingleEmail
                                x={x}
                                remove={remove}
                                update={update}
                                key={index}
                                setEmails={setEmails}
                                getEmailFunction={getEmailFunction}
                              />
                            ))}
                          </div>

                          {isNextPageExists ?
                            <div className={styles.foot}>
                              <button className={cn("button-stroke-red button-small", styles.button)}
                                style={{ width: 250 }}
                                onClick={async () => {
                                  if (!startLoading) {
                                    page = page + 1;
                                    await getEmailFunction(page);
                                  }
                                }}>
                                {startLoading ?
                                  <Spinner size="24" color="gray" />
                                  :
                                  <>
                                    <span>See More templates</span>
                                    <Icon name="arrow-next" size="20"></Icon>
                                  </>
                                }
                              </button>
                            </div>
                            :
                            null
                          }

                        </div>
                      </>
                    ) : (
                      <NoEmail onClick={() => setVisible(true)} />
                    )}
                  </>
                }
              </>
            ) : activeIndex === 2 ? (
              <>
                {(mainBoxLoading && mailBoxEmails?.length < 1) ?
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                    <Spinner size="64" color="gray" />
                  </div>
                  :
                  <>
                    <div className={styles.addNewBtn}>
                      <button
                        className={"button"}
                        onClick={() => setShowSendNewEmail(true)}
                      >
                        <Icon name="mail" size="20" />
                        Send new email
                      </button>
                    </div>
                    {mailBoxEmails?.length > 0 ? (
                      <>
                        <ModalDetail
                          visible={openModal}
                          onClose={onClose}
                          selectedEmailContent={selectedEmailContent}
                        />
                        <div className={styles.emailWrap}>
                          <div className={styles.wrap}>
                            <div className={styles.table}>
                              <div className={styles.head}>
                                <div className={styles.col}>Subject</div>
                                <div className={styles.col}>Created</div>
                                <div className={styles.col}>Attachments</div>
                                <div className={styles.col}>Type</div>
                              </div>
                              {mailBoxEmails.map((x, index) => (
                                <div
                                  className={styles.row}
                                  key={index}
                                  onClick={() => {
                                    setSelectedEmailContent(x);
                                    setOpenModal(true);
                                  }}
                                >
                                  <div className={styles.col}>
                                    <p className={styles.mblLabel}>Subject</p>
                                    <div style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      width: '40vw'
                                    }}>{x.subject || "(No Subject)"}</div>
                                  </div>
                                  <div className={styles.col} style={{ width: 200 }}>
                                    <p className={styles.mblLabel}>Created</p>
                                    <span>{dateFormatterWithTime(x.date)}</span>
                                  </div>
                                  <div className={styles.col} style={{ textAlign: "center" }}>
                                    {x?.attachements?.length > 0 && (
                                      <Icon size="24" name="attachment" viewBox="0 0 24 24" />
                                    )}
                                  </div>
                                  <div className={styles.col}>
                                    <p className={styles.mblLabel}>Type</p>
                                    <span>{toTitleCase(x.boundType || "")}</span>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {isNextPageExistsMailBox ?
                              <div className={styles.foot}>
                                <button className={cn("button-stroke-red button-small", styles.button)}
                                  style={{ width: 250 }}
                                  onClick={async () => {
                                    if (!mainBoxLoading) {
                                      mailBoxEmail = mailBoxEmail + 1;
                                      await getMailBoxEmails(mailBoxEmail);
                                    }
                                  }}>
                                  {mainBoxLoading ?
                                    <Spinner size="24" color="gray" />
                                    :
                                    <>
                                      <span>See More emails</span>
                                      <Icon name="arrow-next" size="20"></Icon>
                                    </>
                                  }
                                </button>
                              </div>
                              :
                              null
                            }
                          </div>
                        </div>
                      </>
                    ) : (
                      <NoEmail onClick={() => setVisible(true)} />
                    )}
                  </>
                }
              </>
            ) : null}

          </>

          <SendNewEmail
            visible={showSendNewEmail}
            setVisible={setShowSendNewEmail}
          />
        </div>
      ) : (
        <NewEmailForm
          setVisible={setVisible}
          CreateEmail={create}
          setEmails={setEmails}
          getEmailFunction={getEmailFunction}
          setSinglePageStatus={setSinglePageStatus}
        />
      )}
    </>
  );
};

export default Email;
