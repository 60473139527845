import React, { useState } from "react";
import styles from "./Details.module.sass";
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import cn from "classnames";
import DefaultCard from "../../../../components/CreditCard";
import Image from "../../../../components/Image";
import TextInput from "../../../../components/TextInput";
import { updateSpendingLimit } from "../../../../utils/apiCallHanlder";
import { toast, ToastContainer } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import TextModal from "./TextModal";
import { id } from "date-fns/locale";
import MyCards from "../../MyCards";

let typingTimer;
let doneTypingInterval = 600;

const CardDetails = ({
  className,
  setColorName,
  setValue,
  cardDetail,
  setSpendingLimit,
  colorName,
  value,
  spendingLimit,
}) => {
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const setSpendingLimitHandler = async () => {
    if (spendingLimit !== "") {
      setLoading(true);
      const { data, error } = await updateSpendingLimit({
        id: cardDetail?.id,
        spending: parseFloat(spendingLimit),
      });
      if (data) {
        toast.success(
          "Successfully updated spending limit.",
          toastConfiguration
        );
      } else {
        toast.error(
          error || "Cannot update spending limit",
          toastConfiguration
        );
      }
      setLoading(false);
    }
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title="Card details"
      /* classTitle="title-blue" */
    >
      <ToastContainer />
      {showPopup ? (
        <TextModal showPopup={showPopup} setShowPopup={setShowPopup} />
      ) : null}
      <div className={styles.row}>
        <div className={styles.col}>
          <MyCards />
          <button
            className={styles.appleWallet}
            onClick={() => setShowPopup(true)}
          >
            <Image
              className={styles.pic}
              src="/images/content/apple-wallet.png"
              srcDark="/images/content/apple-wallet.png"
              alt="Core"
            />
          </button>
        </div>
        <div className={styles.col8}>
          <div className={styles.row}>
            <div className={styles.col6}>
              <TextInput
                label="Nickname"
                className={styles.input}
                placeholder="Facebook Ads"
              />
              <TextInput
                label="PIN"
                className={styles.input}
                currency={<Icon name="eye" size="16" />}
                placeholder="****"
              />
            </div>
            <div className={styles.col6}>
              <TextInput
                label="Spending Limit / Month"
                currency="$"
                placeholder="600"
              />
            </div>
            <div className={styles.col12}>
              <div className={styles.actionBtn}>
                <button className={cn("button-stroke")}>
                  <Icon name="snowflake" size="24" />
                  <span>Freeze card</span>
                </button>
                <button className={cn("button-stroke")}>
                  <Icon name="pin" size="24" />
                  <span>Make Default</span>
                </button>
                <button className={cn("button-stroke-red")}>
                  <Icon name="trash" size="24" />
                  <span>Delete card</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CardDetails;
