import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./DateInput.module.sass";
import DatePicker from "react-datepicker";
import { CalendarContainer } from "react-datepicker";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

import "react-datepicker/dist/react-datepicker.css";
import Card from "../Card";
import Spinner from "../../utils/spinner";

const DateInput = ({
  className,
  classLabel,
  loading,
  isWithPortal,
  label,
  req,
  icon,
  copy,
  currency,
  tooltip,
  place,
  classInputValue,
  portalClassInput,

  classInput,
  dateFormat,
  dateInputId,
  markAsPaidHandler,
  hideText,
  redeemText,
  dateform,
  setComponentStartDate,
  componentStartDate,
  allowFuture,
  hidePast,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openWithPortal, setOpenWithPortal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <Card
          className={styles.card}
          classCardHead={styles.head}
          title={
            redeemText && hideText ? "Redeem By" : hideText ? "Expire By" : "When did you get paid?"
          }
          /* classTitle={cn("title-blue", styles.title)} */
          head={
            <div className={styles.dotsBtnWrap}>
              <button
                className={styles.deleteBtn}
                onClick={() => {
                  setIsOpen(false);
                  setOpenWithPortal(false);
                }}
              >
                <Icon name="close" size="20" />
              </button>{" "}
            </div>
          }
        />{" "}
        <div style={{ position: "relative" }}> {children} </div>{" "}
      </CalendarContainer>
    );
  };

  return (
    <div
      className={cn(
        styles.field,
        {
          [styles.fieldIcon]: icon,
        },
        {
          [styles.fieldCopy]: copy,
        },
        {
          [styles.fieldCurrency]: currency,
        },
        className
      )}
    >
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {" "}
          {label} <b className={styles.required}> {req} </b>{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}{" "}
        </div>
      )}{" "}
      <div className={styles.wrap}>
        <span className={cn(styles.inputValue, classInputValue)}>
          {" "}
          {String(componentStartDate || startDate).slice(4, 15) === String(new Date()).slice(4, 15)
            ? "Today"
            : String(componentStartDate || startDate).slice(4, 10) +
              ", " +
              String(componentStartDate || startDate).slice(11, 15)}{" "}
        </span>{" "}
        {isWithPortal ? (
          <DatePicker
            selected={componentStartDate || startDate}
            onChange={(date) => {
              setComponentStartDate ? setComponentStartDate(date) : setStartDate(date);
              markAsPaidHandler && markAsPaidHandler(date, "cash");
            }}
            dateFormat="MMM d, yyyy"
            className={cn(styles.input, portalClassInput)}
            withPortal={openWithPortal}
            calendarContainer={MyContainer}
            open={isOpen}
            onSelect={() => {
              setIsOpen(false);
              setOpenWithPortal(false);
            }}
            onFocus={() => {
              setIsOpen(true);
              setOpenWithPortal(true);
            }}
            id={dateInputId}
            maxDate={!allowFuture && new Date()}
            minDate={allowFuture && new Date()}
          >
            <div className={styles.calendarClearBtn}>
              <button>
                <span
                  onClick={() => {
                    setComponentStartDate
                      ? setComponentStartDate(new Date())
                      : setStartDate(new Date());
                    setIsOpen(false);
                    setOpenWithPortal(false);
                  }}
                >
                  Clear{" "}
                </span>{" "}
              </button>{" "}
            </div>{" "}
          </DatePicker>
        ) : (
          <DatePicker
            selected={componentStartDate || startDate}
            onChange={(date) =>
              setComponentStartDate ? setComponentStartDate(date) : setStartDate(date)
            }
            dateFormat={dateFormat || "MMM d, yyyy"}
            className={
              dateform ? cn(styles.input1, portalClassInput) : cn(styles.input, classInput)
            }
            open={isOpen}
            id={dateInputId}
            onSelect={() => setIsOpen(false)}
            onFocus={() => {
              setIsOpen(true);
            }}
            minDate={hidePast && new Date()}
          >
            <div className={styles.calendarClearBtn}>
              <button>
                <span
                  onClick={() => {
                    setComponentStartDate
                      ? setComponentStartDate(new Date())
                      : setStartDate(new Date());
                    setIsOpen(false);
                  }}
                >
                  Clear{" "}
                </span>{" "}
              </button>{" "}
            </div>{" "}
          </DatePicker>
        )}{" "}
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} size="24" />{" "}
          </div>
        )}{" "}
        {copy && (
          <button className={styles.copy}>
            <Icon name="copy" size="24" />{" "}
          </button>
        )}{" "}
        {loading && (
          <div style={{ position: "absolute", right: 20, bottom: 6 }}>
            <Spinner color="gray" size="24" />
          </div>
        )}{" "}
        {currency && <div className={styles.currency}> {currency} </div>}{" "}
      </div>{" "}
    </div>
  );
};

export default DateInput;
