import React, { useState, useEffect } from "react";
import styles from "./ProductOverview.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Table from "./Table";
import Icon from "../../../components/Icon";
import EditNewCoupon from "../EditNewCoupon";
import MainContainer from "../index";
import { Redirect } from "react-router";
import {
  deleteBulkCoupons,
  getCouponsData,
} from "../../../utils/apiCallHanlder";
import Spinner from "../../../utils/spinner";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../utils/utils";

let lastId = "";

const CouponOverview = ({ activeIndex }) => {
  const [visible, setVisible] = useState(true);
  const [actionAvailableProduct, setActionAvailableCoupon] = useState(true);
  const [availableCheckCount, setAvailableCheckCount] = useState(0);
  const [actionArchivedProduct, setActionArchivedCoupon] = useState(true);
  const [archivedCheckCount, setArchivedCheckCount] = useState(0);
  const [actionBtn, setActionBtn] = useState(true);
  const [showMainContainer, setShowMainContainer] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteIdArray, setDeleteIdArray] = useState([]);

  const [couponsData, setCouponsData] = useState([]);
  const [hasMore, setHasMore] = useState(null);
  const [loading, setIsLoading] = useState(true);
  const [currentActiveIndex, setCurrentActiveIndex] = useState(
    activeIndex || 1
  );

  const handleEditProduct = (id) => {
    setVisible(false);
    setId(id);
  };

  useEffect(() => {
    if (availableCheckCount === 0) {
      setActionAvailableCoupon(true);
    } else {
      setActionAvailableCoupon(false);
    }
    if (archivedCheckCount === 0) {
      setActionArchivedCoupon(true);
    } else {
      setActionArchivedCoupon(false);
    }
  });

  const actionBtnHandle = () => {
    setActionBtn(!actionBtn);
    setArchivedCheckCount(0);
    setAvailableCheckCount(0);
  };

  const getCouponData = async (isActiveIndexChanged) => {
    if (couponsData.length === 0) {
      lastId = "";
    }
    setIsLoading(true);
    const couponsDataResponse = await getCouponsData(30, lastId, false);
    const { data } = couponsDataResponse;

    if (data) {
      setHasMore(data.isNextPageExist);
      lastId = data?.couponList?.at(-1)?.id;
      !isActiveIndexChanged
        ? setCouponsData(couponsData.concat(data.couponList))
        : setCouponsData(data.couponList);
    }
    setIsLoading(false);
    setCurrentActiveIndex(1);
  };

  return (
    <>
      {showMainContainer ? (
        <MainContainer />
      ) : visible ? (
        <>
          <Card
            className={styles.card}
            title='Products'
            border
            classCardHead={cn(styles.head, { [styles.hidden]: show })}
            head={
              <>
                {actionBtn ? (
                  availableCheckCount < 1 ? (
                    <button
                      onClick={() => {
                        setVisible(false);
                        setIsAdd(true);
                      }}
                      className={cn("button", styles.addProductBtn)}
                    >
                      Add new
                    </button>
                  ) : (
                    <button
                      onClick={async () => {
                        setDeleteLoading(true);
                        const { data } = await deleteBulkCoupons({
                          ids: deleteIdArray,
                        });
                        if (data) {
                          lastId = "";
                          setCouponsData([]);
                          await getCouponData(true);
                          setAvailableCheckCount(0);
                        } else {
                          toast.error(
                            "Error in deleting discount",
                            toastConfiguration
                          );
                        }
                        setDeleteLoading(false);
                      }}
                      className={cn(
                        "button-stroke-red",
                        styles.deleteMarkedBtn
                      )}
                      style={{ width: 150 }}
                    >
                      {deleteLoading ? (
                        <Spinner size='24' color='gray' />
                      ) : (
                        "Delete Marked"
                      )}
                    </button>
                    // <></>
                  )
                ) : archivedCheckCount < 1 ? (
                  <button
                    onClick={() => {
                      setVisible(false);
                      setIsAdd(true);
                    }}
                    className={cn("button", styles.addProductBtn)}
                  >
                    Add new
                  </button>
                ) : (
                  <button
                    onClick={async () => {}}
                    className={cn("button-stroke-red", styles.deleteMarkedBtn)}
                    style={{ width: 150 }}
                  >
                    Delete Marked
                  </button>
                )}
              </>
            }
          >
            <div className={cn(styles.row, { [styles.flex]: show })}>
              <Table
                className={styles.table}
                activeTable={show}
                pro
                setId={setId}
                setActionAvailableCoupon={setActionAvailableCoupon}
                setAvailableCheckCount={setAvailableCheckCount}
                setArchivedCheckCount={setArchivedCheckCount}
                archivedCheckCount={archivedCheckCount}
                setActionArchivedCoupon={setActionArchivedCoupon}
                availableCheckCount={availableCheckCount}
                setCustomerVisible={setVisible}
                deleteIdArray={deleteIdArray}
                setDeleteIdArray={setDeleteIdArray}
                setShowMainContainer={setShowMainContainer}
                setActiveTable={setShow}
                currentActiveIndex={activeIndex}
                activeIndex={currentActiveIndex}
                getCouponData={getCouponData}
                setIsLoading={setIsLoading}
                lastId={lastId}
                setActiveIndex={setCurrentActiveIndex}
                loading={loading}
                hasMore={hasMore}
                couponsData={couponsData}
                setCouponsData={setCouponsData}
                editProduct={handleEditProduct}
                onNoProductClickHandler={() => {
                  setVisible(false);
                  setIsAdd(true);
                }}
                actionBtnHandle={actionBtnHandle}
              />
            </div>
          </Card>
        </>
      ) : isAdd ? (
        <EditNewCoupon isAdd={true} />
      ) : (
        <Redirect to={`/discounts/${id}`} />
      )}
    </>
  );
};

export default CouponOverview;
