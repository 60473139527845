import React, { useEffect, useState } from "react";
import styles from "./AddEditEvent.module.sass";
import Modal from "../../../../components/Modal";
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import TextInput from "../../../../components/TextInput";
import DatePicker from "react-datepicker";
import DateInput from "../../../../components/DateInput";
import cn from "classnames";
import Spinner from "../../../../utils/spinner";
import DeleteModal from "../../../../components/DeleteModal";
import {
  createEvent,
  updateEvent,
  removeEvent,
  searchCustomer,
} from "../../../../utils/apiCallHanlder";
import { GithubPicker } from "react-color";
//import Search from "../../../Payments/Invoices/NewInvoice/Search";
import { toastConfiguration } from "../../../../utils/utils";
import { toast } from "react-toastify";
import Image from "../../../../components/Image";
import Search from "../../../Accounting/Reconcile/SearchContacts";
import AlternativeAvatar from "../../../../components/AlternativeAvatar";

const AddEditEvent = ({
  showNewEventModal,
  setShowNewEventModal,
  prefilStartDate,
  editEvent,
  forceRefreshEvents,
  prefilEndDate,
  Arguments,
  googleSync,
}) => {
  let defColor = "#5300eb";
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerImageUrl, setCustomerImageUrl] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [startDate, setStartDate] = useState(prefilStartDate);
  const [startTime, setStartTime] = useState(prefilStartDate);
  const [endDate, setEndDate] = useState(prefilStartDate);
  const [endTime, setEndTime] = useState(prefilEndDate);
  const [color, setColor] = useState(defColor);
  const [showActionBtn, setShowActionBtn] = useState(false);

  const [visible, setVisible] = useState(false);

  const [searchString, setSearchString] = useState("");
  const [searchNames, setSearchNames] = useState([]);

  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [isNew, setIsNew] = useState(false);

  const handleColorChangeComplete = (color) => {
    setColor(color.hex);
  };
  const dateFormatter = (date) => {
    if (date) {
      const tempDate = new Date(date);
      return new Date(tempDate?.setMilliseconds(0 - tempDate?.getTimezoneOffset() * 60 * 1000));
    } else {
      return new Date();
    }
  };

  useEffect(() => {
    if (editEvent) {
      setTitle("");
      setDescription("");
      setSearchString("");
      setCustomerName("");
      setCustomerImageUrl("");

      setCustomerId("");
      setCustomerEmail("");
      setCustomerPhone("");
      setColor("");

      setTitle(editEvent.title);
      setDescription(editEvent.description);
      setSearchString(editEvent.counterPartyName);
      setCustomerName(editEvent.counterPartyName);
      setCustomerImageUrl(editEvent.counterPartyImageUrl);

      setCustomerId(editEvent.counterPartyId);
      setCustomerEmail(editEvent?.email || editEvent?.counterPartyEmail);
      setCustomerPhone(editEvent?.phone || editEvent?.counterPartyPhone);
      setStartDate(editEvent.start);
      setEndDate(editEvent.end);
      setStartTime(editEvent.start);
      setEndTime(editEvent.end);
      setColor(editEvent.color);
    } else {
      setTitle("");
      setDescription("");
      setSearchString("");
      setCustomerName("");
      setCustomerImageUrl("");

      setCustomerId("");
      setCustomerEmail("");
      setCustomerPhone("");
      setStartDate(prefilStartDate);
      setEndDate(prefilStartDate);
      let date1 = new Date(prefilStartDate);
      let milliseconds = date1.getTime();
      let timeString = new Date(milliseconds).toLocaleTimeString("en-US");
      if (Arguments?.allDay && Arguments?.view?.type !== "dayGridMonth") {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let endOfDay = new Date(today);
        endOfDay.setHours(23, 59, 59, 999);
        setStartTime(today.setHours(0, 0, 0, 0));
        setEndTime(endOfDay.setHours(23, 59, 59, 999));
      } else {
        if (timeString === "12:00:00 AM") {
          setStartTime(new Date(new Date().getTime()));
          setEndTime(new Date(new Date().getTime() + 60 * 60 * 1000));
        } else {
          setStartTime(prefilStartDate);
          let dateString = prefilStartDate;
          setEndTime(new Date(dateString).getTime() + 60 * 60 * 1000);
        }
      }

      setColor(defColor);
    }
  }, [editEvent, showNewEventModal]);

  useEffect(() => {
    if (prefilStartDate) {
      var targetDate = new Date(
        prefilStartDate.getFullYear(),
        prefilStartDate.getMonth(),
        prefilStartDate.getDate(),
        new Date().getHours(),
        0,
        0
      );
      var targetEndDate = new Date(
        prefilStartDate.getFullYear(),
        prefilStartDate.getMonth(),
        prefilStartDate.getDate(),
        new Date().getHours(),
        30,
        0
      );

      setStartDate(targetDate);
      setEndDate(targetEndDate);
    }
  }, [prefilStartDate]);

  const combineDateAndTime = (date, time) => {
    let timeString1 = new Date(time);
    const timeString = timeString1.getHours() + ":" + timeString1.getMinutes() + ":00";

    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var dateString = "" + year + "-" + month + "-" + day;
    var combined = new Date(dateString + " " + timeString);

    return combined;
  };

  const update = async () => {
    const start = combineDateAndTime(startDate, startTime);
    const end = combineDateAndTime(endDate, endTime);

    if (!title) {
      return;
    }

    setUpdateLoading(true);
    const { data } = await updateEvent({
      calendarEventId: editEvent.calendarEventId,
      googleEventId: editEvent.googleCaledarEventId,
      title: title,
      description: description,
      color: color,
      start: start,
      end: end,
      counterPartyId: customerId,
      counterPartyName: customerName,
      isGoogleConnected: googleSync,
      DoCreateCounterParty: isNew ? true : false,
    });

    if (data) {
      toast.success("Successfully updated event", toastConfiguration);
      forceRefreshEvents && forceRefreshEvents();
    } else {
      toast.success("Error in updating event", toastConfiguration);
    }
    setUpdateLoading(false);
    setShowNewEventModal(false);
  };

  const save = async () => {
    if (title === "" || title.trim() === "") {
      toast.error("Title is required", toastConfiguration);

      return;
    }
    if (loading) {
      return;
    }
    combineDateAndTime(startDate, startTime);
    combineDateAndTime(endDate, endTime);

    setLoading(true);
    const { data } = await createEvent({
      title: title,
      description: description,
      color: color,
      start: combineDateAndTime(startDate, startTime),
      end: combineDateAndTime(endDate, endTime),
      counterPartyId: customerId,
      counterPartyName: customerName,
      DoCreateCounterParty: isNew ? true : false,
    });

    if (data) {
      toast.success("Successfully added event", toastConfiguration);
      forceRefreshEvents && forceRefreshEvents();
    } else {
      toast.success("Error in adding event", toastConfiguration);
    }
    setLoading(false);
    setShowNewEventModal(false);
  };

  const remove = async () => {
    if (!title) {
      return;
    }

    setLoading(true);
    const { data } = await removeEvent({
      calendarEventId: editEvent.calendarEventId,
      googleEventId: editEvent.googleCaledarEventId,
    });
    if (data) {
      toast.success("Successfully removed event", toastConfiguration);
      forceRefreshEvents && forceRefreshEvents();
    } else {
      toast.error("Error in removing calendar event", toastConfiguration);
    }
    setLoading(false);
    setShowNewEventModal(false);
  };

  const getCustomers = async (searchString) => {
    const { data } = await searchCustomer(searchString);
    if (data) {
      setSearchNames(data?.list);
    }
  };

  return (
    <>
      <Modal
        visible={showNewEventModal}
        onClose={() => {
          setShowNewEventModal(false);
        }}
        outerClassName={styles.outer}
      >
        <Card
          className={styles.card}
          title="Event" /* classTitle={cn("title-blue", styles.title)} */
        >
          <div className={styles.row}>
            <div className={styles.field}>
              <label>Title</label>
              <TextInput
                onChange={(event) => setTitle(event?.target?.value)}
                name="title"
                type="text"
                value={title}
                placeholder=""
                required
              />
            </div>
            <div className={styles.field}>
              <label>Start</label>
              <div className={styles.fieldWrap}>
                <DatePicker
                  //isWithPortal={false}
                  selected={startDate}
                  minDate={new Date()}
                  classInput={styles.inputValueFuckThisDatePicker}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  dateFormat="MMM d, yyyy"
                ></DatePicker>
                <DatePicker
                  showTimeSelect
                  showTimeSelectOnly
                  //isWithPortal={false}
                  selected={startTime}
                  classInput={styles.inputValueFuckThisDatePicker}
                  timeIntervals={15}
                  timeCaption="Time"
                  onChange={(time) => {
                    setStartTime(time);
                  }}
                  dateFormat="HH:mm"
                ></DatePicker>
              </div>
            </div>
            <div className={styles.field}>
              <label>End</label>
              <div className={styles.fieldWrap}>
                <DatePicker
                  //isWithPortal={false}
                  selected={endDate}
                  minDate={startDate}
                  classInput={styles.inputValueFuckThisDatePicker}
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                  dateFormat="MMM d, yyyy"
                ></DatePicker>
                <DatePicker
                  showTimeSelect
                  showTimeSelectOnly
                  //isWithPortal={false}
                  selected={endTime}
                  timeIntervals={15}
                  timeCaption="Time"
                  classInput={styles.inputValueFuckThisDatePicker}
                  onChange={(time) => {
                    setEndTime(time);
                  }}
                  dateFormat="HH:mm"
                ></DatePicker>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            {/* <div className={styles.field}>
              <label>Description</label>
              <TextInput
                onChange={(event) => setDescription(event?.target?.value)}
                name="description"
                value={description}
                placeholder=""
                type="text"
                required
              />
            </div> */}
            <div className={styles.field}>
              <label>Guest</label>
              {!customerName || customerName === "" ? (
                <Search
                  className={styles.search}
                  inputClassName={styles.customContactSearch}
                  inputPlaceholder="Name of contact"
                  setIsNew={setIsNew}
                  setCounterPartyName={setCustomerName}
                  //isNew={isNew}
                  showDetails={(x) => {
                    setCustomerId(x?.id);
                    setCustomerName(x?.name);
                    setSearchString(x?.name);
                    setCustomerImageUrl(x?.imageUrl);
                    setCustomerEmail(x?.email);
                    setCustomerPhone(x?.mobile);
                    setVisible(false);
                  }}
                />
              ) : (
                /* <Search
                className={styles.search}
                inputPlaceholder="Search customer"
                addNewBtnTxt="Add new Customer"
                hideAdd={true}
                setSelectedCustomerName={setCustomerName}
                searchNames={searchNames}
                selectedCustomerName={customerName}
                getCustomers={getCustomers}
                setSearchString={setSearchString}
                setCustomerId={setCustomerId}
                visible={visible}
                setVisible={setVisible}
                setSearchNames={setSearchNames}
                searchString={searchString}
                nameClickHandler={(name, id, imageUrl) => {
                  setCustomerId(id);
                  setCustomerName(name);
                  setSearchString(name);
                  setCustomerImageUrl(imageUrl);
                  setVisible(false);
                }}
              /> */
                <>
                  <div className={styles.infoWrapper}>
                    <div className={styles.left}>
                      {customerImageUrl ? (
                        <Image
                          src={customerImageUrl || "/images/content/avatar.jpg"}
                          srcDark={customerImageUrl || "/images/content/avatar.jpg"}
                          className={styles.pic}
                        />
                      ) : (
                        <AlternativeAvatar name={customerName} className={styles.pic} />
                      )}

                      <button
                        onClick={() => {
                          // setShowAccountInfos(false);
                          setCustomerName("");
                          setCustomerId(null);
                          setIsNew(false);
                          /* setShowDetails(); */
                        }}
                        className={styles.deleteBtn}
                      >
                      </button>
                    </div>
                    <div className={styles.infoWrap}>
                      <div className={styles.info}>
                        <p className={styles.label}>Name</p>
                        <p className={styles.txt}>{customerName}</p>
                      </div>
                      <div className={styles.info}>
                        <p className={styles.label}>Email</p>
                        <p className={styles.txt}>{customerEmail}</p>
                      </div>
                      <div className={styles.info}>
                        <p className={styles.label}>Mobile</p>
                        <p className={styles.txt}>{customerPhone}</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className={cn(styles.customerHead, styles.field)} style={{ marginBottom: 32 }}>
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: 'space-between',
                    marginTop: 8,
                    width: "100%"
                  }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}>
                  <img
                    src={ customerImageUrl || "/images/content/avatar.jpg"}
                    alt="customer-image"
                    className={styles.customerImage}
                  />
                  <h3 className={styles.name} style={{ marginLeft: 8 }}>{customerName}</h3>
              </div>
                <button
                  onClick={() => {
                    // setShowAccountInfos(false);
                    setCustomerName("")
                    setCustomerId(null)
                    // setShowDetails();
                  }}
                  className={styles.deleteBtn}
                >
                  <Icon
                    name="trash"
                    size="20"
                    fill="#FF4900"
                  />
                </button>
                  </div>
                </div> */}
                </>
              )}
            </div>
          </div>

          {/* <div className={styles.row}>
            <div className={styles.field}>
              <label>Color</label>
              <GithubPicker
                triangle="hide"
                color={color}
                onChangeComplete={handleColorChangeComplete}
              />
            </div>
          </div> */}
          <div className={styles.row}>
            {editEvent ? (
              <div className={styles.btnWrap}>
                <button
                  onClick={() => remove()}
                  className={cn("button-stroke-red", styles.button)}
                >
                  {loading ? (
                    <Spinner loading={loading} size={15} />
                  ) : (
                    <>
                      Delete
                    </>
                  )}
                </button>
                <button
                  onClick={async () => await update()}
                  className={cn("button", styles.button)}
                >
                  {updateLoading ? <Spinner loading={updateLoading} size={15} /> : <>Save</>}
                </button>
              </div>
            ) : (
              <>
                {/* <button
                  onClick={() => setShowNewEventModal(false)}
                  style={{ marginRight: 20, display: "inline" }}
                  className={cn("button-stroke", styles.button)}
                >
                  <Icon name="arrow-left" size="24" />
                  <span>Close</span>
                </button> */}
                <button
                  onClick={() => save()}
                  className={cn("button", styles.button)}
                  style={{ display: "inline", marginLeft: 8 }}
                >
                  {loading ? <Spinner loading={loading} size={15} /> : <>Save</>}
                </button>
              </>
            )}
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default AddEditEvent;
