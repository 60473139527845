import React from "react";
import styles from "./CancelModal.module.sass";
import Modal from "../../../../../../components/Modal";
import Icon from "../../../../../../components/Icon";
import Checkbox from "../../../../../../components/Checkbox";
import { useState } from "react";
import Spinner from "../../../../../../utils/spinner";

const CancelModal = ({
  showCancelModal,
  setShowCancelModal,
  onConfirm,
  invoiceNow,
  setInvoiceNow,
  cancelLoading
}) => {

  return (
    <>
      <Modal
        visible={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        outerClassName={styles.outer}
      >
        <div className={styles.title}>Cancel Subscription</div>
        <div className={styles.wrap}>
          <Checkbox
            value={invoiceNow}
            onChange={() => {
              setInvoiceNow(!invoiceNow)
            }}
            content="Invoice Now"
          />
          {/* <Checkbox 
            content="End of the current period Aug 30, 2022"
          /> */}
          <div className={styles.buttons}>
            <button onClick={() => setShowCancelModal(false)} className="button-stroke button-small">
              <Icon name="close" className={styles.btnIcon} size="20" />
              Don't Cancel
            </button>
            <button onClick={onConfirm} className="button button-small" style={{ width: 200 }}>
              {cancelLoading ?
                <Spinner size="24" color="white" />
                :
                <>
                  <Icon name="check" className={styles.btnIcon} size="20" />
                  Cancel Subscription
                </>
              }
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CancelModal;
