import React, { useState } from "react";
import styles from "./InvoicePreview.module.sass";
import Card from "../../../../../components/Card";
import cn from "classnames";
import Image from "../../../../../components/Image";
import { Link } from "react-router-dom";
import { dateFormatter, formatStripeAmount, toTitleCase } from "../../../../../utils/utils";
import Icon from "../../../../../components/Icon";
import Amount from "../../../../../components/Amount";

const taxObject = {
  "inclusive": "Included",
  "exclusive": "Excluded"
}

let isExclusive = true

const InvoicePreview = ({
  accountDetails,
  printDocument,
  invoiceObject,
  className
}) => {

  //formatStripeAmount
  var dueHead = formatStripeAmount(0) + ' due ' + dateFormatter(new Date());
  if (invoiceObject &&
    invoiceObject.status) {
    dueHead = `${formatStripeAmount(invoiceObject.total, invoiceObject.currencySymbol)} due  ${dateFormatter(invoiceObject?.dueDate)}`;

    if (invoiceObject?.status == 'paid' || invoiceObject?.status == 'void') {
      dueHead = '';
    }
  }

  var customerAddress = invoiceObject?.customerAddress;
  var accountAddress = invoiceObject?.accountAddress;

  var shippingAddress = invoiceObject?.customerShipping;
  if (!shippingAddress)
    shippingAddress = invoiceObject?.customerAddress;

  const descInfo = invoiceObject?.lines;

  for (let i = 0; i < descInfo?.length; i++) {
    if (descInfo[i]?.taxBehavior === "inclusive") {
      isExclusive = false;
      break;
    } else {
      isExclusive = true;
    }
  }

  return (
    <>
      <div id="divToPrint">
        <Card
          border
          className={cn(styles.card, className)}
          id="card-1"
        // classTitle="title-green"
        // title="Preview"

        >
          {/* <button onClick={() => printDocument()}
            data-html2canvas-ignore="true"
            style={{
              cursor: 'pointer',
              pointerEvents: 'auto',
              position: 'absolute',
              right: 30,
              bottom: 20
            }}>
            <Icon name={"download"} size="24" />
          </button> */}
          <div>
            <div className={styles.head}>

              <h4 className={styles.txt} id="text-1">Invoice</h4>
              {invoiceObject?.accountImage && (<Image
                className={styles.pic}
                id="img-1"
                src={invoiceObject?.accountImage}
                srcDark={invoiceObject?.accountImage} 
                alt="Core"
              />)}
            </div>
            {invoiceObject?.status !== "paid" ? (
              null
            ) : (
              <div className={styles.thankStump}>
                <Image
                  className={styles.stump}
                  src={"/images/paid-stump.png"}
                  srcDark={"/images/paid-stump.png"}
                />
              </div>
            )}
            <div className={styles.wrap}>
              <span className={styles.title}>Invoice Number</span>

              <span className={styles.text}>{invoiceObject?.invoiceNumber}</span>
            </div>
            {invoiceObject?.status !== "paid" && <div className={styles.wrap}>
              <span className={styles.title}>Due Date</span>
              <span className={styles.text}>{invoiceObject?.dueDate && dateFormatter(invoiceObject?.dueDate)}</span>
            </div>}
            <div className={styles.wrap}>
              <span className={styles.title}>Status</span>

              <span className={cn(styles.text, styles[invoiceObject?.status])}>
                {toTitleCase(invoiceObject?.status)}
              </span>
            </div>
            <div className={styles.addresses}>
              <div className={styles.wrap}>
                <div className={styles.title}>From</div>
                <div className={styles.name}>{invoiceObject?.accountName}</div>

                <p className={styles.name}>{invoiceObject?.accountEmail || null}</p>
                <p className={styles.name}>{invoiceObject?.accountPhone || null}</p>

                <p className={styles.addressLine}>{accountAddress?.line1 || null}</p>
                {(accountAddress?.line2 || null) && <p className={styles.addressLine}>{accountAddress?.line2 || null}</p>}
                <div className={styles.wrapAddress}>
                  <p className={styles.addressLine}>{accountAddress?.city || null}</p>
                  <p className={styles.addressLine}>{accountAddress?.stateName || null}</p>
                  <p className={styles.addressLine}>{accountAddress?.postalCode || null}</p>
                </div>
                <p className={styles.addressLine}>{accountAddress?.countryName || null}</p>
              </div>
              <div className={styles.wrap}>
                <div className={styles.title}>Billed to</div>

                <div className={styles.name}>{invoiceObject?.customerName || ""}</div>

                <p className={styles.name}>{invoiceObject?.customerEmail || null}</p>
                <p className={styles.name}>{invoiceObject?.customerPhone || null}</p>

                <p className={styles.addressLine}>{customerAddress?.line1 || null}</p>
                {(customerAddress?.line2 || null) && <p className={styles.addressLine}>{customerAddress?.line2 || null}</p>}
                <div className={styles.wrapAddress}>
                  <p className={styles.addressLine}>{customerAddress?.city || null}</p>
                  <p className={styles.addressLine}>{customerAddress?.stateName || null}</p>
                  <p className={styles.addressLine}>{customerAddress?.postalCode || null}</p>
                </div>
                <p className={styles.addressLine}>{customerAddress?.countryName || null}</p>
              </div>
              <div className={styles.wrap} style={{ opacity: 0, pointerEvents: 'none' }}>
                <div className={styles.title}>Ship to</div>
                <div className={styles.name}>{shippingAddress?.name || invoiceObject?.customerName || ""}</div>

                <p className={styles.name}>{shippingAddress?.email || null}</p>
                <p className={styles.name}>{shippingAddress?.phoneNo || null}</p>

                <p className={styles.addressLine}>{shippingAddress?.line1 || null}</p>
                {(shippingAddress?.line2 || null) && <p className={styles.addressLine}>{shippingAddress?.line2 || null}</p>}
                <div className={styles.wrapAddress}>
                  <p className={styles.addressLine}>{shippingAddress?.city || null}</p>
                  <p className={styles.addressLine}>{shippingAddress?.stateName || null}</p>
                  <p className={styles.addressLine}>{shippingAddress?.postalCode || null}</p>
                </div>
                <p className={styles.addressLine}>{shippingAddress?.countryName || null}</p>
              </div>
            </div>
            <div className={styles.due} style={{ display: 'none' }}>
              <div className={styles.dueHead}>{dueHead}</div>
              <Link className={styles.link} to={invoiceObject?.hostedInvoiceUrl || '#'} target="_blank">
                Pay Online
              </Link>
            </div>
            <div className={styles.descWrap}>
              <div className={styles.descHead}>
                <div className={styles.singleDesc}>Description</div>
                <div className={styles.singleDesc}>Qty</div>
                <div className={styles.singleDesc}>Unit Price</div>
                <div className={styles.singleDesc}>Tax</div>
                <div className={styles.singleDesc}>Amount</div>
              </div>
              {descInfo?.map((x, index) => (
                <div className={styles.descBody} key={x.lineId || index}>
                  <div className={styles.singleDesc}>{x.description}</div>
                  <div className={styles.singleDesc}>{x.quantity} </div>
                  <div className={styles.singleDesc}>
                    <Amount amount={formatStripeAmount(x.priceValue, invoiceObject?.currencySymbol)} subtitleEmValue={"0.8em"} />
                  </div>
                  <div className={styles.singleDesc}>
                    {x?.tax}
                  </div>
                  <div className={styles.singleDesc} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Amount amount={formatStripeAmount(x.amount, invoiceObject?.currencySymbol)} subtitleEmValue={"0.8em"} />
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.totalWrap}>
              <div className={styles.left}>
                <p className={styles.amount}>Subtotal</p>
                {invoiceObject?.discount?.amountDiscountValue > 0 && 
                    <p className={styles.amount}>{invoiceObject?.discount?.name +" " + "(" +invoiceObject?.discount?.label + ")" } </p>
                }
                {invoiceObject?.customerTaxExempt == "exempt" && <p className={styles.amount}> Customer is tax exempted. </p>}
                {invoiceObject?.taxAmounts?.length > 0 && 
                invoiceObject?.taxAmounts?.map((x, index) => (<p className={styles.amount}>{x.name} </p>))
                }

                {/* <p className={styles.amount}>
                  Tax
                  {isExclusive ?
                    <span className={(styles['exclusive'] || styles.exclusive)}>{toTitleCase(taxObject['exclusive'])}</span>
                    :
                    <span className={(styles['inclusive'] || styles.exclusive)}>{toTitleCase(taxObject['inclusive'])}</span>
                  }
                </p> */}
                <p className={styles.dueAmount}>Amount Due</p>
              </div>
              <div className={styles.right}>
                <p className={styles.amount}>
                  <Amount amount={formatStripeAmount(invoiceObject.subTotal || 0, invoiceObject.currencySymbol)} subtitleEmValue={"0.8em"} />
                </p>
                {invoiceObject?.discount?.amountDiscountValue > 0 && 
                    
                    <p className={styles.amount}>
                  <Amount amount={formatStripeAmount(invoiceObject?.discount?.amountDiscountValue || 0, invoiceObject.currencySymbol)} subtitleEmValue={"0.8em"} showMinus={true}/>
                </p>
                }
                {invoiceObject?.customerTaxExempt == "exempt" && <p className={styles.amount}> &nbsp; </p>}

                {invoiceObject?.taxAmounts?.length > 0 && 
                invoiceObject?.taxAmounts?.map((x, index) => (
                  <p className={styles.amount}>
                  <Amount amount={formatStripeAmount(x?.amountValue || 0, invoiceObject.currencySymbol)} subtitleEmValue={"0.8em"}/>
                </p>
                  ))
                }
                {/* <p className={styles.amount}>
                  <Amount amount={formatStripeAmount(invoiceObject.tax || 0, invoiceObject.currencySymbol)} subtitleEmValue={"0.8em"} />
                </p> */}
                <p className={styles.dueAmount}>
                  <Amount amount={formatStripeAmount(invoiceObject.amountDue || 0, invoiceObject.currencySymbol)} subtitleEmValue={"0.8em"} />
                </p>
              </div>
            </div>
            {invoiceObject?.lines?.length > 0 && (
              <div className={styles.payWrap}>
                <div className={styles.payInfoWrap}>
                  <div className={styles.head} id="text-2">Payment Details</div>
                  <div className={styles.payInfo}>
                    <div>
                      <span className={styles.title}>{
                        invoiceObject.achCreditDetail?.accountHolderName ?
                          "Account Name" :
                          "Bank Name"
                      }
                      </span>

                      <span className={styles.text}>{
                        invoiceObject.achCreditDetail?.accountHolderName ||
                        invoiceObject.achCreditDetail?.bankName
                      }</span>
                    </div>
                    {/* {invoiceObject.achCreditDetail?.accountHolderName && <div>
                      <span className={styles.title}>Account Holder Name</span>
                      <span className={styles.text}>{invoiceObject.achCreditDetail?.accountHolderName}</span>
                    </div>} */}
                    <>
                      <div>
                        <span className={styles.title}>Routing number</span>
                        <span className={styles.text}>{
                          invoiceObject.achCreditDetail?.routingNumber ||
                          invoiceObject.achCreditDetail?.detail2
                        }</span>
                      </div>
                      <div>
                        <span className={styles.title}>Account number</span>
                        <span className={styles.text}>{
                          invoiceObject.achCreditDetail?.accountNumber ||
                          invoiceObject.achCreditDetail?.detail1
                        }</span>
                      </div>
                      {invoiceObject.achCreditDetail?.isInternational && (
                        <>
                          <div>
                            <span className={styles.title}>IBAN</span>
                            <span className={styles.text}>{
                              invoiceObject.achCreditDetail?.iban
                            }</span>
                          </div>
                          <div>
                            <span className={styles.title}>BIC</span>
                            <span className={styles.text}>{
                              invoiceObject.achCreditDetail?.bic
                            }</span>
                          </div>
                        </>
                      )}
                    </>
                  </div>
                </div>
                <div className={styles.memo}>
                  <div className={styles.head} id="text-3">Memo</div>

                  <p className={styles.title}>{invoiceObject.description || null}</p>
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default InvoicePreview;
