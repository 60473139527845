import Icon from "../../../components/Icon";
import styles from './DownloadProject.module.sass';
import DealSheet from "./DealSheet";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetDashboardInitialData, getAccountDetail, getSingleProperty, propertyLendersList } from "../../../utils/apiCallHanlder";
import { transactionDateFormatter } from "../../../utils/utils";
import Spinner from "../../../utils/spinner";
import cn from "classnames";

let matchedLendersCount = 0;

const DownloadProject = (props) => {
    const history = useHistory();
    const propertyId = props.location?.state?.propertyId;  

    const [property, setProperty] = useState(null);
    const [companyDetails, setCompanyDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [accounts, setAccounts] = useState(null);
    const [transactions, setTransactions] = useState(null);

    const [propertyLendersIds, setPropertyLendersIds] = useState([]);

    useEffect(async () => {
        if (propertyId) {
            setLoading(true);
            const { data } = await getSingleProperty({
                propertyId: propertyId
            });
            if (data) {
                setProperty(data?.property);
                await loadAccountsAndGraphsData();
                await getCompanyDetails();
                await getLenders();
            }
            setLoading(false);
        }
    }, [propertyId])

    const getLenders = async () => {
        const lenders = await propertyLendersList({
            propertyId: propertyId,
            showAll: false
        })
        if (lenders?.data) {
            const ids = lenders?.data?.propertyLenders?.map(item => item?.lenderId);
            matchedLendersCount = lenders?.data?.matchedCount;
            setPropertyLendersIds(ids);
        }
    }

    const getCompanyDetails = async () => {
        const { data } = await getAccountDetail();

        if (data) {
            const { account } = data;
            setCompanyDetails(account);
        }
    }

    const loadAccountsAndGraphsData = async () => {
        const { data } = await GetDashboardInitialData({
            FromDate: transactionDateFormatter(new Date(new Date().setMonth(new Date().getMonth() - 1))),
            ToDate: transactionDateFormatter(new Date()),
            isFirstLoad: true,
        });

        if (data) {
            setAccounts(prepareAccountForOverview(data.accounts, "USD"));
            setTransactions(data.transactions);
        }
    };

    const prepareAccountForOverview = (accountsFromApi, defCurrency) => {
        if (!accountsFromApi || !defCurrency) return accountsFromApi;
        accountsFromApi = accountsFromApi?.filter(account => (account?.type !== "All" && account?.type !== 'stripebalance'));

        if (accountsFromApi?.length === 2) {
            accountsFromApi.shift();
        }

        defCurrency = defCurrency.toLowerCase();
        var allBalance = 0;
        var allAccountIdx = -1;
        for (let index = 0; index < accountsFromApi.length; ++index) {
            const accountInfo = accountsFromApi[index];
            if (accountInfo.id === "All") {
                allAccountIdx = index;
                continue;
            }

            if (accountInfo.balances && accountInfo.balances[defCurrency]) {
                accountInfo.displayBalance = accountInfo.balances[defCurrency];
                allBalance += accountInfo.balances[defCurrency];
            } else {
                accountInfo.displayBalance = 0;
            }
        }

        if (allAccountIdx >= 0) {
            accountsFromApi[allAccountIdx].displayBalance = allBalance;
        }
        return accountsFromApi;
    };

    const backBtn = (
        <div
                className={cn("button-stroke-red", styles.backBtn)}
                onClick={() => {
                    history.push({
                        pathname: `/projects/${propertyId}`
                    })
                }}
            >
                Cancel
            </div>
    )

    return (
        <>
            {loading ?
                <div className={styles.spinner}>
                    <Spinner size="48" color="gray" />
                </div>
                :
                <DealSheet
                    matchedLendersCount={matchedLendersCount}
                    property={property}
                    accounts={accounts}
                    transactions={transactions}
                    companyDetails={companyDetails}
                    loading={loading}
                    matchedLendersId={propertyLendersIds}
                    backBtn={backBtn}
                />
            }
        </>
    );
};

export default DownloadProject;
