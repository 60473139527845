import React, { useState } from "react";
import styles from "./Details.module.sass";
import Icon from "../../../../../components/Icon";
import Card from "../../../../../components/Card";
import cn from "classnames";
import DefaultCard from "../../../../../components/CreditCard";
import Image from "../../../../../components/Image";
import TextInput from "../../../../../components/TextInput";
import { updateCard, updateSpendingLimit } from "../../../../../utils/apiCallHanlder";
import { toast, ToastContainer } from 'react-toastify';
import { dateFormatter, getCurrencyFromLocalStorage, toastConfiguration } from "../../../../../utils/utils";
import TextModal from './TextModal';
import { id } from "date-fns/locale";
import DeleteCardModal from '../DeleteModal';
import Spinner from "../../../../../utils/spinner";
import { useHistory } from "react-router";
import Dropdown from "../../../../../components/Dropdown";
import DropdownCategoires from "../../../../../components/Dropdown_c";
import SingleCategory from "./SingleCategory/SingleCategory";

let typingTimer;
let doneTypingInterval = 600;

const periods = [
  {
    text: "All Time",
    value: "all_time",
    display: "All Time"
  },
  {
    text: "Daily",
    value: "daily",
    display: "Daily"
  },
  {
    text: "Month",
    value: "monthly",
    display: "Month"
  },
  {
    text: "Per Transaction",
    value: "per_authorization",
    display: "Per Transaction"
  },
  {
    text: "Week",
    value: "weekly",
    display: "Week"
  },
  {
    text: "Year",
    value: "yearly",
    display: "Week"
  },
];

const CardDetails = ({
  className,
  setColorName,
  setValue,
  cardDetail,
  setSpendingLimit,
  colorName,
  value,
  spendingLimit,
  statusChangeHandler,
  makeDefaultLoading,
  makeDefaultHandler,
  cardCategories,
  statusChangeLoading,
  cardRights
}) => {
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAppleWalletPopup, setShowAppleWalletPopup] = useState(false);
  const [showGooglePayPopup, setShowGooglePayPopup] = useState(false);
  const [visible, setVisible] = useState(false);

  const [searchCategory, setSearchCategory] = useState("");

  const [color, setColor] = useState(cardDetail?.cardColor || "#4464E2");
  const [selectedCategories, setSelectedCategories] = useState(cardDetail?.allowedCategories || []);

  const [period, setPeriod] = useState(cardDetail?.spendingLimitInterval || periods[0]?.value);
  const [currentCateogry, setCurrentCategory] = useState(cardCategories[0] ? cardCategories[0]?.value : null);

  const history = useHistory();
  const shippingDetail = cardDetail?.shippingDetail;

  const saveClickHandler = async () => {
    setLoading(true);
    const { data } = await updateCard({
      id: cardDetail?.id,
      spending: parseFloat(spendingLimit),
      spendingLimitInterval: period,
      cardColor: color,
      allowedCategories: selectedCategories
    })

    if (data) {
      toast.success("Successfully updated card.", toastConfiguration);
    } else {
      toast.error("Error in updating card", toastConfiguration);
    }

    setLoading(false);
  }

  const setSpendingLimitHandler = async () => {
    if (spendingLimit !== "") {
      setLoading(true);
      const { data, error } = await updateSpendingLimit({
        id: cardDetail?.id,
        spending: parseFloat(spendingLimit)
      })
      if (data) {
        toast.success("Successfully updated spending limit.", toastConfiguration);
      } else {
        toast.error(error || "Cannot update spending limit", toastConfiguration);
      }
      setLoading(false);
    }
  }

  let currency = getCurrencyFromLocalStorage();

  return (
    <Card
      className={cn(styles.card, className)}
       title="Card detailss"
      border
      head={
        <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
          <div className="button-stroke-red"
            onClick={() => {
              history.push("/cards")
            }}
          >
            Cancel
          </div>
          {cardDetail?.status === 'canceled' || cardRights === 2 ? null :
        <div className={styles.col12}>
          <div className={styles.actionBtn}>

          {
            cardDetail?.isDefaultCard ?
              <button className={cn("button-stroke-red")}
                style={{pointerEvents: 'none' }}>
                {makeDefaultLoading ?
                  <Spinner size="24" color="gray" />
                  :
                  <>
                    <span>Card is default</span>
                  </>
                }
              </button> :
              <button className={cn("button-stroke-red button-icon-rotate")}
                onClick={makeDefaultHandler}>
                {makeDefaultLoading ?
                  <Spinner size="24" color="gray" />
                  :
                  <>
                    <span>Make default</span>
                  </>
                }
              </button>
          }

<button className={cn("button-stroke-red")}
  onClick={() => setVisible(true)}
>
  <span>Delete</span>
</button>
            <button className={cn("button")}
              onClick={saveClickHandler}
            >
              {loading ?
                <Spinner size="24" color="gray" />
                :
                <>
                  <span>Save</span>
                </>
              }
            </button>
            {cardDetail?.status === "active" ?
              <button
                className={cn("button-stroke-lightBlue", styles.button)}
                onClick={() => statusChangeHandler("inactive")}>
                {statusChangeLoading ?
                  <Spinner size="24" color="gray" />
                  :
                  <>
                    <span>Freeze</span>
                  </>
                }
              </button>
              :
              <button
                className={cn("button-stroke", styles.button)}
                onClick={() => statusChangeHandler("active")}>
                {statusChangeLoading ?
                  <Spinner size="24" color="gray" />
                  :
                  <>
                    <span>Active</span>
                  </>
                }
              </button>
            }
          </div>
        </div>
      }
        </div>
      }
    >
      <ToastContainer />
      {visible ?
        <DeleteCardModal
          visible={visible}
          id={cardDetail?.id}
          setVisible={setVisible}
        />
        :
        null
      }

      <TextModal showPopup={showAppleWalletPopup} setShowPopup={setShowAppleWalletPopup}
        text="To add this card to your Apple Wallet, please open Wallet app on your iPhone and scan this card on a computer screen or enter the 16 digit number manually. After this you can use the card at any retail location accepting contactless payments. Please note cards are linked to your bank balance."
      />

      <TextModal showPopup={showGooglePayPopup} setShowPopup={setShowGooglePayPopup}
        text="To add this card to your Google Pay, please open google pay app on your Phone and scan this card on a computer screen or enter the 16 digit number manually. After this you can use the card at any retail location accepting contactless payments. Please note cards are linked to your bank balance."
      />
      <div className={styles.row}>
        <div className={styles.col}>
          {/* <div className={styles.cardOwner} style={{ marginBottom: 16, maxWidth: 320 }}>
            <div className={styles.label}>Nickname</div>
            <div className={styles.ownerName}>{cardDetail?.name}</div>
          </div> */}

          <DefaultCard
            className={cn(styles.defaultCard, styles[colorName])}
            cardDetail={cardDetail}
            setShowHidePass={setShowPass}
            color={color}
          />
          <button
            className={styles.appleWallet}
            style={{ marginTop: 30 }}
            onClick={() => setShowAppleWalletPopup(true)}
          >
            <Image
              className={styles.pic}
              src="/images/content/apple-wallet.png"
              srcDark="/images/content/apple-wallet.png"
              alt="Core"
            />
          </button>
          <button
            className={styles.appleWallet}
            style={{ marginTop: 30, marginLeft: 12 }}
            onClick={() => setShowGooglePayPopup(true)}
          >
            <Image
              className={styles.pic}
              src="/images/content/google-pay.png"
              srcDark="/images/content/google-pay.png"
              alt="Core"
            />
          </button>
        </div>
        <div className={styles.col8}>
          <div className={styles.row}>
            <div className={cn(styles.periods)}
              style={cardDetail?.status === 'canceled' ? { pointerEvents: 'none' } : {}}>
              <TextInput
                className={styles.fieldPeriod}
                name="spending Limit"
                type="text"
                //descriptionLoading={loading}
                onChange={(event) => setSpendingLimit(event.target.value)}
                placeholder="0"
                label="Spending Limit"
                currency={currency}
                // onKeyUp={() => {
                //   clearTimeout(typingTimer);
                //   typingTimer = setTimeout(setSpendingLimitHandler, doneTypingInterval);
                // }}
                // onKeyDown={() => {
                //   clearTimeout(typingTimer);
                // }}
                value={spendingLimit}
                required
              />

              <DropdownCategoires
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={period}
                setValue={setPeriod}
                options={periods || []}
              />
            </div>
            {shippingDetail ?
              <div className={styles.col12}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '90%'
                }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span >
                      Ship to
                    </span>
                    <span style={{
                      display: 'flex',
                      flexDirection: 'column',
                      color: "#6F767E",
                      fontSize: 14
                    }}>
                      <span>{shippingDetail?.name}</span>
                      <span>{shippingDetail?.addressLine1}</span>
                      <span>{shippingDetail?.addressLine2}</span>
                      <span>{`${shippingDetail?.addressCity}, 
                      ${shippingDetail?.addressState}
                      ${shippingDetail?.addressPostalCode}
                      ${shippingDetail?.addressCountry}
                      `}
                      </span>
                    </span>
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <span >
                      Carrier
                    </span>
                    <span style={{ color: "#6F767E", fontSize: 14 }}>
                      {shippingDetail?.carrier}
                    </span>
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <span >
                      Shipping Status
                    </span>
                    <span style={{ color: "#6F767E", fontSize: 14 }}>
                      {shippingDetail?.status}
                    </span>
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <span >
                      Delivery date (est.)
                    </span>
                    <span style={{ color: "#6F767E", fontSize: 14 }}>
                      {dateFormatter(shippingDetail?.eta)}
                    </span>
                  </div>
                </div>
              </div>
              :
              null}
            {/* <div className={styles.col12} style={{ margin: 'unset' }}>
              <label className={styles.label} style={{ lineHeight: 3.5 }}>Categories Allowed</label>
              <div style={{ width: 300 }}>
                <DropdownCategoires
                  classDropdownHead={styles.dropdownHead}
                  options={cardCategories}
                  width={'auto'}
                  showSearch={"true"}
                  setSearchString={setSearchCategory}
                  searchString={searchCategory}
                  value={currentCateogry}
                  taxCodesByGroups={cardCategories}
                  setValue={setCurrentCategory}
                  hideDropdown={true}
                  showSingle={true}
                  onChange={(value) => {
                    if (!selectedCategories.includes(value)) {
                      setSelectedCategories([...selectedCategories, value])
                      setSearchCategory("")
                    }
                  }}
                />
              </div>
              <div className={styles.categoryContainer}>
                {selectedCategories?.map((item, index) => {
                  return <SingleCategory
                    name={item}
                    options={cardCategories}
                    key={index}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                  />
                })}
              </div>
            </div> */}
            <div className={styles.col12} style={{marginTop: "12px"}}>
              <div className={styles.label}>Color</div>
              <div style={{
                paddingLeft: 8,
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 8,
                paddingBottom: 16,
                alignItems: 'center'
              }}>
                {[
                  "#464542",
                  "#FF4900",
                  "#490000",
                  "#FFA96B",
                  "#FFB7C3"
                ].map((item, index) => {
                  return <div
                    onClick={() => setColor(item)}
                    key={index}
                    style={{
                      background: item,
                      width: color === item ? 65 : 50,
                      height: color === item ? 65 : 50,
                      borderRadius: '50%',
                      cursor: 'pointer'
                    }}>
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </Card >
  );
};

export default CardDetails;
