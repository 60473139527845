import React from 'react';
import Checkbox from '../../../../components/Checkbox';

import styles from './Steps.module.sass';

const steps = ["Start application", "Offer received", "Load paid out", "Applied"]
const listedSteps = [0, 1, 2, 3]

const Steps = ({ selected }) => {

    let selectedItemIndex = listedSteps.indexOf(selected);
    if (selected === 1) {
        selectedItemIndex = 4
    }

    return <div className={styles.container}>
        {listedSteps?.filter(x => x !== 1)?.map((item, index) => {
            const isSelected = item === selected;
            return <div key={index} className={styles.itemContainer}>
                <span className={styles.title}>{steps[item < 1 ? item : item - 1]}</span>
                <div className={styles.checkboxWrapper}>
                    <Checkbox value={
                        index === 0 ? true :
                            (index === 0 && selectedItemIndex === 4) ? true :
                                (selectedItemIndex === 4) ? false :
                                    (index < selectedItemIndex)}
                        classCheckboxTick={(isSelected || (index === 1 && selectedItemIndex === 4)) && styles.classCheckboxTick} />
                    {(index !== (listedSteps?.length - 2)) && (
                        <div className={`${(selectedItemIndex === 4 ? ((index < selectedItemIndex) && index !== 1) : (index < (selectedItemIndex - 1))) ? styles.selectedLine : styles.line}`}></div>
                    )}
                </div>
            </div>
        })}
    </div>
}

export default Steps;