import React from "react";
import styles from "./CardsOverview.module.sass";
import Row from "./Row";
import Table from "./Table";
import { useHistory } from "react-router-dom";
import Spinner from "../../../utils/spinner";
import { useState } from "react";

const CardsOverview = ({
  activeIndex,
  activeVirtualCard,
  activePhysicalCard,
  setDeleteIdArray,
  deletedIdArray,
  idActive,
  idArchived,
  isLoaded,
  setActiveCardCheckCount,
  activeCardCheckCount,
  setArchivedCardCheckCount,
  archivedCardCheckCount,
  cardRights
}) => {
  const history = useHistory();

  const activeAllCards = activeVirtualCard.concat(activePhysicalCard);
  const [showCardHolderName, setShowCardHolderName] = useState(true)

  const handleActiveCardAll = (e) => {
    var ariaStatus = e.target.checked;
    if (ariaStatus === true) {
      const checkboxes = document.getElementsByName("archived-prod-checkbox");

      for (var i = 0, n = checkboxes?.length; i < n; i++) {
        checkboxes[i].checked = true;
      }

      deletedIdArray = [];

      for (let i = 0; i < activeAllCards.length; i++) {
        deletedIdArray.push(activeAllCards[i]?.id);
      }
      setDeleteIdArray([...deletedIdArray]);

      setActiveCardCheckCount(activeAllCards.length);
    }
    if (ariaStatus === false) {
      const checkboxes = document.getElementsByName("archived-prod-checkbox");

      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }

      setDeleteIdArray([]);
      setActiveCardCheckCount(0);
    }
  };

  const handleActiveCard = (e, id) => {
    if (e.target.checked) {
      if (activeCardCheckCount + 1 === activeAllCards.length) {
        const checkbox = document.getElementsByName("active-prod-all-checkbox");
        if (checkbox) {
          checkbox[0].checked = true;
        }
      }

      setDeleteIdArray([...deletedIdArray, id]);
      setActiveCardCheckCount(activeCardCheckCount + 1);
    }

    if (!e.target.checked) {
      const checkbox = document.getElementsByName("active-prod-all-checkbox");
      if (checkbox) {
        checkbox[0].checked = false;
      }

      const index = deletedIdArray.indexOf(id);
      if (index > -1) {
        deletedIdArray.splice(index, 1);
      }
      setDeleteIdArray([...deletedIdArray]);

      setActiveCardCheckCount(activeCardCheckCount - 1);
    }
  };

  const handleArchivedCardAll = (e) => {
    var ariaStatus = e.target.checked;
    if (ariaStatus === true) {
      const checkboxes = document.getElementsByName("archived-prod-checkbox");

      for (var i = 0, n = checkboxes?.length; i < n; i++) {
        checkboxes[i].checked = true;
      }

      deletedIdArray = [];

      for (let i = 0; i < activeAllCards.length; i++) {
        deletedIdArray.push(activeAllCards[i]?.id);
      }
      setDeleteIdArray([...deletedIdArray]);

      setArchivedCardCheckCount(activeAllCards.length);
    }
    if (ariaStatus === false) {
      const checkboxes = document.getElementsByName("archived-prod-checkbox");

      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }

      setDeleteIdArray([]);
      setArchivedCardCheckCount(0);
    }
  };

  const handleArchivedCard = (e, id) => {
    if (e.target.checked) {
      if (archivedCardCheckCount + 1 === activeAllCards.length) {
        const checkbox = document.getElementsByName(
          "archived-prod-all-checkbox"
        );
        if (checkbox) {
          checkbox[0].checked = true;
        }
      }

      setDeleteIdArray([...deletedIdArray, id]);
      setArchivedCardCheckCount(archivedCardCheckCount + 1);
    }

    if (!e.target.checked) {
      const checkbox = document.getElementsByName("archived-prod-all-checkbox");
      if (checkbox) {
        checkbox[0].checked = false;
      }

      const index = deletedIdArray.indexOf(id);
      if (index > -1) {
        deletedIdArray.splice(index, 1);
      }
      setDeleteIdArray([...deletedIdArray]);

      setArchivedCardCheckCount(archivedCardCheckCount - 1);
    }
  };

  return (
    <>
      {activeIndex === 1 ? (
        isLoaded ? (
          <Table
            id={idActive}
            removeCheckbox={false}
            isLoaded={isLoaded}
            name="active-prod-all-checkbox"
            cardRights={cardRights}
            handleActiveCardAll={handleActiveCardAll}
            setShowCardHolderName={setShowCardHolderName}
            showCardHolderName={showCardHolderName}
            rowContent={
              <>
                {activeVirtualCard.map((x, index) => (
                  <Row
                    cardData={x}
                    removeCheckbox={false}
                    name="archived-prod-checkbox"
                    onChange={handleActiveCard}
                    showCardHolderName={showCardHolderName}
                    activeCardCheckCount={activeCardCheckCount}
                    onClick={() =>
                      history.push(`/accountsandcards/cards/${x?.id}`)
                    }
                    key={index}
                    cardRights={cardRights}
                  />
                ))}
                {activePhysicalCard.map((x, index) => (
                  <Row
                    cardData={x}
                    removeCheckbox={false}
                    name="archived-prod-checkbox"
                    onChange={handleActiveCard}
                    showCardHolderName={showCardHolderName}
                    activeCardCheckCount={activeCardCheckCount}
                    onClick={() =>
                      history.push(`/accountsandcards/cards/${x?.id}`)
                    }
                    cardRights={cardRights}
                    key={index}
                  />
                ))}
              </>
            }
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: window.outerHeight - 450, alignItems: 'center'
            }}
          >
            <Spinner size="50px" color="gray" />
          </div>
        )
      ) : activeIndex === 2 ? (
        isLoaded ? (
          <Table
            id={idArchived}
            isLoaded={isLoaded}
            handleArchivedCardAll={handleArchivedCardAll}
            removeCheckbox={true}
            name="archived-prod-all-checkbox"
            cardRights={cardRights}
            rowContent={
              <>
                {activeVirtualCard.map((x, index) => (
                  <Row
                    cardRights={cardRights}
                    cardData={x}
                    removeCheckbox={true}
                    onChange={handleArchivedCard}
                    name="archived-prod-checkbox"
                    archivedCardCheckCount={archivedCardCheckCount}
                    onClick={() =>
                      history.push(`/accountsandcards/cards/${x?.id}`)
                    }
                    key={index}
                  />
                ))}
                {activePhysicalCard.map((x, index) => (
                  <Row
                    cardRights={cardRights}
                    cardData={x}
                    removeCheckbox={true}
                    onChange={handleArchivedCard}
                    name="archived-prod-checkbox"
                    archivedCardCheckCount={archivedCardCheckCount}
                    onClick={() =>
                      history.push(`/accountsandcards/cards/${x?.id}`)
                    }
                    key={index}
                  />
                ))}
              </>
            }
          />
        ) : (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Spinner size="34" color="gray" />
          </div>
        )
      ) : null}
    </>
  );
};

export default CardsOverview;
