import React from "react";
import styles from "./NoEmail.module.sass";
import Image from "../../../../components/Image";
import cn from "classnames";

const NoEmail = ({onClick}) => {
  return (
    <div>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.noEmail}>
            <Image
              className={styles.pic}
              src="/images/icons/empty-black.png"
              srcDark="/images/icons/empty-white.png"
              alt="Core"
            />
            {/* <div className={styles.txtWrap}>
              <h3>You don't have any email template</h3>
              <p>No time for coffee, grow your business now</p>
              <button
                className={cn("button", styles.button)}
                onClick={onClick}
              >
                Create new email
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoEmail;
