/* global Stripe */
import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./StatementPopup.module.sass";
import Icon from "../Icon";
import Card from "../Card";
import { Checkbox } from "react-tri-state-checkbox";
import { getStatement } from "../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../utils/utils";
import Spinner from "../../utils/spinner";
import DateRangeInput from "../DateRangeInput";
import Dropdown from "../COATYPE";


const StatementPoupup = ({
    className,
    visible,
    setVisible,
    overflow,
    accounts
}) => {
    const [isCsv, setIsCsv] = useState(true);
    const [isPdf, setIsPdf] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentlySelectedAccountId, setCurrentlySelectedAccountId] = useState(accounts[0] && accounts[0]?.id);

    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        if (accounts?.length > 0) {
            setCurrentlySelectedAccountId(accounts[0]?.id);
        }
    }, [accounts])

    const getStatementClickHandler = async () => {
        setLoading(true);
        const { data, error } = await getStatement({
            FromDate: startDate,
            ToDate: endDate,
            SelectedAccountId: currentlySelectedAccountId
        }, "csv")

        if (data) {
            const file = data?.file?.downloadURL;
            //window.open(file, "_blank")
            if (file)
                window.location = file;
        } else {
            toast.error("Error in downloading statement", toastConfiguration);
        }
        setLoading(false);
        setVisible(false);
    }

    return (
        <div
            className={cn(styles.deleteModal, className, {
                [styles.active]: visible,
            })}
        >
            <div className={`${styles.body} popupBody`}>
                <div className={styles.top}>
                    <button
                        className={cn("close", styles.close)}
                        onClick={() => setVisible(false)}
                    >
                        <Icon name="close" size="20" />
                    </button>
                </div>

                <Card
                    className={cn(styles.card, className)}
                    classCardHead={styles.head}
                    title="Statement"
                /* classTitle={cn("title-blue", styles.title)} */
                >
                    <DateRangeInput
                        classInputValue={styles.inputValue}
                        classInput={styles.input}
                        icon="calendar"
                        className={styles.calendar}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        startDate={startDate}
                        endDate={endDate}
                        hideWidth={true}
                    />

                    <div style={{ marginTop: 16, marginBottom: 8 }}>
                        <label className={styles.fieldLabel}>Account</label>
                    </div>
                    <div style={{ width: "70%" }}>
                        <Dropdown
                            className={styles.dropdown}
                            classDropdownHead={styles.dropdownHead}
                            value={currentlySelectedAccountId}
                            setValue={setCurrentlySelectedAccountId}
                            options={accounts || []}
                            comingFromStatement={true}
                        />
                    </div>

                    {/* <div className={styles.singleCard}>
                        <input
                            type="radio"
                            style={{ width: 18, height: 18 }}
                            onChange={() => {
                                // setIsCsv(!isCsv);
                                // setIsPdf(!isPdf);
                            }}
                            value={true}
                            checked={true}
                        />
                        <span>CSV
                            &nbsp;
                        </span>
                    </div> */}
                    {/* <div className={styles.singleCard} style={{ pointerEvents: 'none' }}>
                        <input
                            type="radio"
                            style={{ width: 18, height: 18 }}
                            onChange={() => {
                                // setIsCsv(!isCsv);
                                //setIsPdf(true);
                            }}
                            checked={false}
                        />
                        <span>PDF
                            &nbsp;
                            <span className={styles.comingSoon}>
                                (Coming soon)
                            </span>
                        </span>
                    </div> */}
                    <button
                        style={{ marginTop: 20, }}
                        onClick={getStatementClickHandler}
                        className={cn("button", styles.button)}
                    >
                        {loading ?
                            <Spinner size="24" color="white" />
                            :
                            <>
                                <span>Download</span>
                            </>
                        }
                    </button>
                </Card>
            </div >
            <div className={styles.overlay} onClick={() => setVisible(false)}></div>
        </div >
    );
};

export default StatementPoupup;
