import React, { useState, useEffect } from "react";
import styles from "./EditNewCoupon.module.sass";
import Card from "../../../components/Card";
import cn from "classnames";
import Details from "./Details";
import DeleteModal from "../../../components/DeleteModal";
import Icon from "../../../components/Icon";
import Pricing from "./Pricing";
import Spinner from "../../../utils/spinner";
import ProductMainContainer from "../index";
import { toast, ToastContainer } from "react-toastify";
import {
  toastConfiguration,
  trackIntercomEvent,
  dateFormatterUTC,
  dateFormatterWithTimeZone,
} from "../../../utils/utils";
import {
  addCouponsData,
  getSingleCoupon,
  updateCouponData,
  removeCouponsData,
} from "../../../utils/apiCallHanlder";
import { Redirect } from "react-router";

const durationOption = [
  { value: "forever", label: "Forever", text: "Forever" },
  { value: "once", label: "Once", text: "Once" },
  //{ value: "repeating", label: "Multiple Months", text: "Multiple Months" },
];

let haveDropdownsData = false;
let priceModel = [""];
let recurringType = [""];
let productCurrency = [""];
let taxcodes = [""];

let taxCodesGroups = [];
let taxCodesByGroups = {};

let showBilling = true;
const EditNewCoupon = ({
  id,
  isAdd,
  className,
  searchString,
  comingFromInvoice,
  productSelectHandler,
  showProduct,
  showOneTime,
  showSubscription,
}) => {
  if (comingFromInvoice) {
    showBilling = false;
  }
  if (!haveDropdownsData) {
    const productLookupdata = JSON.parse(localStorage.getItem("lookupData"));
    let data = null;
    if (productLookupdata) {
      data = productLookupdata?.data;
      priceModel = data?.price_mode;
      recurringType = data?.recurring_type;
      productCurrency = data?.product_currency;
      taxcodes = data?.taxcodes;

      for (let i = 0; i < taxcodes?.length; i++) {
        if (taxCodesGroups.includes(taxcodes[i]?.group)) {
          taxCodesByGroups[taxcodes[i]?.group] = [
            ...taxCodesByGroups[taxcodes[i]?.group],
            taxcodes[i],
          ];
        } else {
          taxCodesGroups.push(taxcodes[i]?.group);
          taxCodesByGroups[taxcodes[i]?.group] = [taxcodes[i]];
        }
      }

      haveDropdownsData = true;
    }
  }

  const [visible, setVisible] = useState(true);
  const [name, setName] = useState(searchString || "");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [archived, setArchived] = useState(false);

  const [redeemByCheckBox, setRedeemByCheckBox] = useState(false);
  const [maxRedemptionCheckBox, setMaxRedemptionCheckBox] = useState(false);

  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("11:59");

  //pricing states

  const [amountOff, setAmountOff] = useState("");
  const [percentOff, setPercentOff] = useState("");
  const [maxRedemptions, setMaxRedemptions] = useState("");
  const [redeemBy, setRedeemBy] = useState("");
  const [duration, setDuration] = useState("");
  const [selectDuration, setSelectDuration] = useState(
    durationOption[0]?.value
  );
  const [durationInMonths, setDurationInMonths] = useState(1);
  const [couponType, setCouponType] = useState("percentOff");

  const [selectCurrency, setSelectCurrency] = useState(
    productCurrency[0]?.value
  );
  const [selectPricingModel, setSelectPricingModel] = useState(
    priceModel[0].value
  );
  const [productPrice, setProductPrice] = useState(null);
  const [userChoose, setUserChoose] = useState(false);
  const [oneTime, setOneTime] = useState(true);
  const [subscriptions, setSubscriptions] = useState(
    showSubscription && comingFromInvoice ? true : false
  );
  let [selectPeriod, setSelectPeriod] = useState(recurringType[0]?.value);
  let [includeTax, setIncludeTax] = useState(false);

  //Error States
  const [priceError, setPriceError] = useState("");
  const [nameError, setNameError] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [startLoading, setStartLoading] = useState(true);
  const [taxCode, setTaxCode] = useState("");
  const [taxCodeName, setTaxCodeName] = useState("");

  if (typeof window !== "undefined") {
    const currencyValue = localStorage.getItem("currency");
    if (currencyValue) {
      productCurrency = [
        {
          value: currencyValue?.toLowerCase(),
          display: currencyValue?.toUpperCase(),
          text: currencyValue?.toUpperCase(),
        },
      ];
    }
  }

  useEffect(() => {
    setSelectCurrency(productCurrency[0]?.value);
  }, [productCurrency]);

  useEffect(async () => {
    setStartLoading(true);
    if (id) {
      const { data } = await getSingleCoupon({ id });
      if (data) {

        const { coupon } = data;
        
        let amt = '0';
        if(coupon.amountOff > 0)
        {
          amt = (coupon.amountOff / 100).toFixed(2);
        }
        setName(coupon.name);
        setAmountOff(amt);
        setPercentOff(coupon.percentOff);
        setMaxRedemptions(coupon.maxRedemptions);
        setRedeemBy(coupon.redeemBy);
        setDate(coupon.redeemBy ? new Date(coupon.redeemBy) : new Date());

        setRedeemByCheckBox(!!coupon.redeemBy);
        setMaxRedemptionCheckBox(!!coupon.maxRedemptions);

        setSelectDuration(coupon.duration);
        setDurationInMonths(coupon.durationInMonths);
        setSelectCurrency(coupon.currency);
        setDuration(coupon.duration);
        setCouponType(coupon.percentOff ? "percentOff" : "amountOff");
        setStartLoading(false);
      }
    } else {
      setStartLoading(false);
    }
  }, [id]);

  //onChange Hanlder
  const setValueHandler = (value, valueSetter) => {
    valueSetter(value);
  };

  useEffect(() => {
    if (redeemByCheckBox) {
      setRedeemBy(date);
    } else {
      setRedeemBy("");
    }
  }, [date, redeemByCheckBox]);

  const saveClickHandler = async () => {
    if (loading) {
      return;
    }
    if (name === "" || !name?.replace(/\s/g, '')?.length) {
      toast.error("Please provide coupon name.", toastConfiguration);
    } else if (
      maxRedemptionCheckBox == true &&
      (maxRedemptions === "" || parseInt(maxRedemptions) < 0)
    ) {
      toast.error(
        "Max Redemptions should be zero or positive number.",
        toastConfiguration
      );
    } else if (selectDuration === "") {
      toast.error("Duration is required", toastConfiguration);
    } else if (
      (couponType === "percentOff" && !percentOff) ||
      (parseInt(percentOff) < 0 ||
      parseInt(percentOff) > 100)
    ) {
      toast.error(
        "A percent based discount must be greater than 0 and less than 100 percent",
        toastConfiguration
      );
    } else if (
      couponType === "amountOff" && (!amountOff ||
      parseInt(amountOff) <= 0)
    ) {
      toast.error("Discount must be greater than 0.", toastConfiguration);
    } else {
      const productPayload = {
        name: name,
        percentOff: couponType == "percentOff" ? percentOff || 0 : null,
        amountOff: couponType == "amountOff" ? parseInt(amountOff  || 0) * 100 : null,
        currency: selectCurrency,
        duration: selectDuration,
        durationInMonths: durationInMonths,
        maxRedemptions: maxRedemptions ?? 0,
        redeemBy: redeemBy,
      };

      if (!redeemByCheckBox) {
        productPayload["redeemBy"] = null;
      }

      if (!maxRedemptionCheckBox || maxRedemptions < 0) {
        productPayload["maxRedemptions"] = null;
      }

      if (selectDuration != "repeating") {
        productPayload["durationInMonths"] = null;
      }

      const updateCouponPayload = {
        name: name,
      };

      if (id) {
        updateCouponPayload.id = id;
      }

      setLoading(true);

      const newProductResponse = id
        ? await updateCouponData(updateCouponPayload)
        : await addCouponsData(productPayload);
      const { data } = newProductResponse;
      if (data) {
        if (!id) trackIntercomEvent("Coupon-created");
        toast.success("Coupon data saved successfully", toastConfiguration);
        if (comingFromInvoice) {
          productSelectHandler(data?.coupon);
          setTimeout(() => {
            showProduct();
            setLoading(false);
          }, 2000);
        } else {
          setVisible(false);
          setLoading(false);
        }
      } else {
        toast.error(newProductResponse.error || "Error in coupon data", toastConfiguration);
        setServerError(newProductResponse.error);
        setLoading(false);
      }
    }
  };

  const backBtn = (visible? comingFromInvoice ? null : (
    <div className={cn("button")}>
      <div
        className={styles.backBtn}
        onClick={() => setVisible(false)}
      >
        Cancel
      </div>
    </div>
  ) : null)

  return (
    <>
      {!startLoading ? (
        visible ? (
          <>
            <ToastContainer />
            <Card border className={className}>
              
                <Details
                  name={name}
                  isAdd={isAdd}
                  nameError={nameError}
                  description={description}
                  setNameError={setNameError}
                  redeemByCheckBox={redeemByCheckBox}
                  setRedeemByCheckBox={setRedeemByCheckBox}
                  maxRedemptionCheckBox={maxRedemptionCheckBox}
                  setMaxRedemptionCheckBox={setMaxRedemptionCheckBox}
                  setDescription={setDescription}
                  setName={setName}
                  maxRedemptions={maxRedemptions}
                  setMaxRedemptions={setMaxRedemptions}
                  redeemBy={redeemBy}
                  setRedeemBy={setRedeemBy}
                  durationInMonths={durationInMonths}
                  setDurationInMonths={setDurationInMonths}
                  durationOption={durationOption}
                  selectDuration={selectDuration}
                  couponType={couponType}
                  date={date}
                  time={time}
                  setTime={setTime}
                  setDate={setDate}
                  setCouponType={setCouponType}
                  setSelectDuration={setSelectDuration}
                  setValueHandler={setValueHandler}
                  setImageUrl={setImageUrl}
                  archived={archived}
                  imageUrl={imageUrl}
                  taxcodes={taxcodes}
                  taxCode={taxCode}
                  taxCodesByGroups={taxCodesByGroups}
                  setTaxCode={setTaxCode}
                  taxCodeName={taxCodeName}
                  setTaxCodeName={setTaxCodeName}
                  comingFromInvoice={comingFromInvoice}
                  backBtn={backBtn}
                />
                <Pricing
                  priceModel={priceModel}
                  archived={archived}
                  priceError={priceError}
                  setPriceError={setPriceError}
                  isAdd={isAdd}
                  recurringType={recurringType}
                  amountOff={amountOff}
                  setAmountOff={setAmountOff}
                  percentOff={percentOff}
                  setPercentOff={setPercentOff}
                  couponType={couponType}
                  setCouponType={setCouponType}
                  durationInMonths={durationInMonths}
                  setDurationInMonths={setDurationInMonths}
                  productCurrency={productCurrency}
                  selectCurrency={selectCurrency}
                  setSelectCurrency={setSelectCurrency}
                  selectPricingModel={selectPricingModel}
                  setSelectPricingModel={setSelectPricingModel}
                  userChoose={userChoose}
                  setUserChoose={setUserChoose}
                  oneTime={oneTime}
                  setOneTime={setOneTime}
                  subscriptions={subscriptions}
                  setSubscriptions={setSubscriptions}
                  selectPeriod={selectPeriod}
                  setSelectPeriod={setSelectPeriod}
                  setValueHandler={setValueHandler}
                  productPrice={productPrice}
                  setProductPrice={setProductPrice}
                  includeTax={includeTax}
                  setIncludeTax={setIncludeTax}
                  showOneTime={showOneTime}
                  showSubscription={showSubscription}
                  comingFromInvoice={comingFromInvoice}
                  showBilling={showBilling}
                />
              <div className={styles.actionCard}>
                {archived ? null : (
                  <>
                    {id ? (
                      <DeleteModal
                        className={styles.deleteBtn}
                        deleteFunction={removeCouponsData}
                        id={id}
                        setShowMainContainer={setVisible}
                        setValue={false}
                      />
                    ) : null}

                    <button
                      onClick={saveClickHandler}
                      className={cn("button", styles.button)}
                    >
                      {loading ? (
                        <Spinner loading={loading} size={15} />
                      ) : (
                        <>
                          <label>Save</label>
                        </>
                      )}
                    </button>
                  </>
                )}
              </div>
            </Card>
          </>
        ) : (
          <>
            {isAdd ? (
              <ProductMainContainer />
            ) : (
              <Redirect
                to={{
                  pathname: "/discounts",
                  state: archived ? { activeIndex: 2 } : null,
                }}
              />
            )}
          </>
        )
      ) : (
        <div
          className={styles.spinner}
          style={{ height: window.outerHeight - 250 }}
        >
          <Spinner loading={startLoading} size={150} color={"gray"} />
        </div>
      )}
    </>
  );
};

export default EditNewCoupon;
