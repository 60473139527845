import React, { useState, useEffect } from "react";
import Card from "../../../../components/Card";
import styles from "./UpdateContractForm.module.sass";
import TextInput from "../../../../components/TextInput";
import Dropdown from "../../../../components/Dropdown";
import Modal from "../../../../components/Modal";
import Trix from "trix";
import cn from 'classnames'
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";
import Spinner from "../../../../utils/spinner";
import Icon from "../../../../components/Icon";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import { createAIContract } from "../../../../utils/apiCallHanlder";

const tokenList = [
  {
    name: "Customer Name",
    token: "{contact_name}",
  },
  // {
  //   name: "Customer First Name",
  //   token: "{customer_first_name}",
  // },
  // {
  //   name: "Mobile",
  //   token: "{contact_phone}",
  // },
  {
    name: "Customer Email",
    token: "{contact_email}",
  },
  {
    name: "Customer Address",
    token: "{contact_address}",
  },

  {
    name: "Current Date",
    token: "{current_date}",
  },
  // {
  //   name: "Link to Customer Portal",
  //   token: "{link_to_customer_portal}",
  // },

];

const signingPaties = [
  {
    name: "You",
    token: "{contact_name}",
  },
  {
    name: "Contact",
    token: "{customer_first_name}",
  }
];

const UpdateContractForm = ({
  handleSubmit,
  handleChange,
  userInput,
  handleTrixInputChange,
  updateContractLoading,
  setUserInput,
  head
}) => {
  const [token, setToken] = useState(tokenList.map((x) => x.name)[0]);
  const [generateLoading, setGenerateLoading] = useState(false);

  useEffect(() => {
    const trixEditor = document.querySelector("trix-editor");
    trixEditor.style.minHeight = '65vh';
  }, [])

  const toolbarActions = [
    "bold",
    "italic",
    "strike",
    "link",
    "heading1",
    "quote",
    "bullet",
    "number",
    "centered",
    "undo",
    "redo",
  ];

  const handleToken = (e) => {
    const tokenValue = e.target.getAttribute("data-value");
    const trixEditor = document.querySelector("trix-editor");
    let trixEditorValue = trixEditor.innerHTML;
    if (trixEditor.editor && trixEditor.editor.insertString) {
      trixEditor.editor.insertString(tokenValue);
    }
    else {
      trixEditorValue = trixEditorValue + tokenValue;
    }
    return trixEditorValue;
  };

  // useEffect(() => {
  //   const tokenValue = tokenList.map((x) => x.token)[mblTokenIndex];
  //   const trixEditor = document.querySelector("trix-editor");
  //   const addToken = () => {
  //     if (trixEditor === null) {
  //       return null;
  //     } else {
  //       let trixEditorValue = trixEditor.innerHTML;
  //       if (trixEditor.editor && trixEditor.editor.insertString) {
  //         trixEditor.editor.insertString(tokenValue);
  //       }
  //       else {
  //         trixEditorValue = trixEditorValue + tokenValue;
  //       }
  //       return trixEditorValue;
  //     }
  //   };
  //   addToken();
  // }, [mblTokenIndex]);

  const generateAIPrompt = async () => {
    if (generateLoading) return;
    if (userInput.contractName === "" || userInput.contractName.trim() === "") {
      toast.error("Name is required", toastConfiguration);
      return;
    }

    setGenerateLoading(true);
    setUserInput && setUserInput({ trixEditorValue: "" });
    const trixEditor = document.querySelector("trix-editor");
    if (trixEditor.editor) {
      trixEditor.innerHTML = "";
    }

    const { data, error } = await createAIContract({
      name: userInput.contractName
    });

    if (data) {
      toast.success("Successfully generated prompt", toastConfiguration);
      setUserInput && setUserInput({ trixEditorValue: data?.contract });

      if (trixEditor.editor) {
        trixEditor.innerHTML = data?.contract;
      }
    } else {
      toast.error(error || "Error in generating prompt", toastConfiguration);
    }

    setGenerateLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.row}>
      <div className={styles.colFirst}>
        <Card title={"Contract"} border classTitle={styles.title} classCardHead={styles.cardHead}
          head={
            <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
              {head}
              <button
              className={cn("button", styles.sendBtn)}
              onClick={handleSubmit}
            >
              {updateContractLoading ?
                <Spinner size="24" color="white" />
                :
                <>
                  Save
                </>
              }
            </button>
            </div>
          }
        >
          <form onSubmit={handleSubmit} noValidate>
            <div style={{ display: 'flex', gap: 8, alignItems: 'flex-end' }}>
              <TextInput
                className={styles.input}
                classLabel={styles.classLabel}
                label="Prompt"
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                name="contractName"
                id="contractName"
                type="text"
                value={userInput.contractName}
                required
              />
              <button
                className={cn("button-stroke-red", styles.sendBtn)}
                type="button"
                onClick={generateAIPrompt}
              >
                {generateLoading ? <Spinner size="16" color="gray" /> : <>Generate</>}
              </button>
            </div>
            <div className={styles.detailsWrap}>
              <span className={styles.fieldLabel}>Message</span>
              <div className={styles.tokenWrap}>
                {tokenList.map((x, index) => (
                  <div
                    data-value={x.token}
                    key={index}
                    className={styles.token}
                    onClick={handleToken}
                  >
                    {x.name}
                  </div>
                ))}
              </div>
            </div>

            <div className={cn(styles.detailsWrap, styles.margin)}>
              <span className={styles.fieldLabel}>Signing Party</span>
              <div className={styles.tokenWrap}>
                {signingPaties.map((x, index) => (
                  <div
                    data-value={x.token}
                    key={index}
                    className={styles.token}
                    onClick={() => { }}
                  >
                    {x.name}
                  </div>
                ))}
              </div>
            </div>
          </form>
        </Card>
      </div>
      <div className={styles.colSecond}>
        <Card title={userInput?.contractName || ""} border>
          <ReactTrixRTEToolbar
            toolbarActions={toolbarActions}
            toolbarId="react-trix-rte-editor"
          />
          <ReactTrixRTEInput
            toolbarId="react-trix-rte-editor"
            onChange={handleTrixInputChange}
            placeholder="Enter Content"
            defaultValue={userInput?.trixEditorValue}
          />
        </Card>
      </div>
    </div>
  );
};

export default UpdateContractForm;
