import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Checkbox from "../../../../../components/Checkbox";
import DeleteModal from "../../../../../components/DeleteModal";
import Icon from "../../../../../components/Icon";
import { dateFormatter, toastConfiguration } from "../../../../../utils/utils";
import Amount from "../../../../../components/Amount";
import Image from "../../../../../components/Image";
import { convertProspectToCustomer, deleteProspect } from "../../../../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import Spinner from "../../../../../utils/spinner";

const RowProspect = ({
  item,
  activeTable,
  activeId,
  onConfirm,
  singleCheck,
  editCustomer,
  checkCount,
}) => {
  const [visible, setVisible] = useState(false);
  const [prospectLoading, setProspectLoading] = useState(false);
  item.icons = "dots";
  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item?.customerProspectId },
          { [styles.active]: activeTable }
        )}
      >
        <div
          //onClick={() => editCustomer(item.id)}
          className={styles.editOverlay}
        ></div>
        <div className={styles.col} style={{ minWidth: 150 }}>
          <span className={styles.mblCreate}>Name:</span>
          <div className={styles.user}>
            <span>{(item?.firstName + " " + item?.lastName) || "-"}</span>
          </div>
        </div>
        <div className={styles.col}>
          <span className={styles.mblCreate}>Email:</span>
          <div className={styles.date}>{item?.email || "-"}</div>
        </div>
        <div className={styles.col}>
          <span className={styles.mblCreate}>Mobile:</span>
          <div className={styles.created}>{item?.phone || "-"}</div>
        </div>
        <div className={styles.col}>
          <span className={styles.mblCreate}>Message:</span>
          <div className={styles.created}>{item?.message || "-"}</div>
        </div>
        <div className={styles.col}>
          <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div
              className={cn(styles.dotsBtn, {
                [styles.active]: visible,
              })}
            >
              <div className={styles.head}>
                <button
                  className={cn(styles.btn)}
                  onClick={() => setVisible(!visible)}
                >
                  <Icon name={"dots"} />
                </button>
              </div>
              <div className={styles.actionBody}>
                {!item?.isCustomerCreated && (
                  <button
                    className={styles.actionItem}
                    onClick={async () => {
                      setProspectLoading(true);
                      const { data, error } = await convertProspectToCustomer({
                        CustomerProspectId: item?.customerProspectId,
                        FirstName: item?.firstName,
                        LastName: item?.lastName,
                        Email: item?.email,
                        Phone: item?.phone
                      });
                      if (data) {
                        toast.success("Propect converted to customer successfully", toastConfiguration);
                        await onConfirm();
                      } else {
                        toast.error(error || "Error in converting Propect to customer", toastConfiguration);
                      }
                      setProspectLoading(false);
                    }}
                    style={{ width: "100%", marginBottom: 8 }}
                  >
                    {prospectLoading ?
                      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Spinner size="24" color="gray" />
                      </div>
                      :
                      <>
                        <Icon name="multiSelect" size="24" />
                        Convert to customer
                      </>
                    }
                  </button>
                )}
                <DeleteModal
                  id={item.customerProspectId}
                  deleteFunction={deleteProspect}
                  onConfirm={onConfirm}
                  icon={
                    <Icon
                      className={styles.btnIcon}
                      fill="#FF4900"
                      name="trash"
                      size="20"
                    />
                  }
                  className={styles.deleteBtn}
                  btnClass={cn(styles.actionItemRed)}
                />
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </>
  );
};

export default RowProspect;
