import React, { PureComponent, useEffect, useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell, Tooltip } from 'recharts';
import styles from '../Overview.module.sass';
import toolTipStyles from '../Chart/Chart.module.sass'


const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload?.isNegative ? `-${payload?.amountValue}` : payload.amountValue}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
            {/* <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#333">
                {payload?.name?.length > 8 ? payload?.name?.slice(0, 8) : payload?.name}
            </text> */}
        </g>
    );
};

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className={toolTipStyles.tooltipContainer}>
                <p className={toolTipStyles.toolTipLabel}>{`${payload[0]?.name}`}</p>
                <div className={toolTipStyles.listContainer}>
                    {payload[0]?.payload?.isNegative ? `-$${payload[0]?.value}` : `$${payload[0]?.value}`}
                </div>
            </div>
        );
    }

    return null;
};

const CircleChart = ({ title, data }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isChartEmpty, setIsChartEmpty] = useState(false);

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    useEffect(() => {
        if (data?.length > 0) {
            setIsChartEmpty(data.every(item => (item?.amountValue == 0 || item?.amountValue == '-0')));
            data?.map((item, i) => {
                if (item?.amountValue != 0) {
                    setActiveIndex(i);
                }
            })
        }
    }, [data])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className={styles.title}>
                {isChartEmpty ? 'No data to display' : title}
            </span>
            <PieChart width={200} height={200}>
                <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="amountValue"
                    onMouseEnter={onPieEnter}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry?.fill} />
                    ))}
                </Pie>
                <Tooltip
                    content={<CustomTooltip />}
                />
            </PieChart>
        </div>
    )
}

export default CircleChart;
