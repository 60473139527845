import React from "react";
import styles from "./CustomerInfoBox.module.sass";
import Image from "../Image";
import Icon from "../Icon";
import AlternativeAvatar from "../AlternativeAvatar";

const CustomerInfoBox = ({
  imageUrl,
  name,
  email,
  mobile,
  mobileCode,
  onClick,
  disableCursor
}) => {
  return (
    <div className={styles.infoWrapper} style={{
      pointerEvents: disableCursor ? 'none' : 'auto'
    }}>
      <div className={styles.left}>
        {imageUrl
          ? (<Image
            className={styles.pic}
            src={imageUrl}
            srcDark={imageUrl}
          />)
          : (
            <AlternativeAvatar name={name} className={'customer-info'} />
          )
        }
        <button onClick={() => onClick()} className={styles.deleteBtn}>
          <Icon name="trash" size="20" fill="#FF4900" />
        </button>
      </div>
      <div className={styles.infoWrap}>
        <div className={styles.info}>
          <p className={styles.label}>Name</p>
          <p className={styles.txt}>{name}</p>
        </div>
        <div className={styles.info}>
          <p className={styles.label}>Email</p>
          <p className={styles.txt}>{email}</p>
        </div>
        <div className={styles.info}>
          <p className={styles.label}>Mobile</p>
          <p className={styles.txt}>
            {mobileCode}
            {mobile}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfoBox;
