import React, { useReducer, useState } from "react";
import styles from "./NewContractForm.module.sass";
import Modal from "../../../../components/Modal";
import uuid from "react-uuid";
import TextInput from "../../../../components/TextInput";
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import Trix from "trix";
import cn from "classnames";
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";
import Dropdown from "../../../../components/Dropdown";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import { addContract, createAIContract } from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";

const tokenList = [
  {
    name: "Customer Name",
    token: "{contact_name}",
  },
  // {
  //   name: "Customer First Name",
  //   token: "{customer_first_name}",
  // },
  // {
  //   name: "Mobile",
  //   token: "{contact_phone}",
  // },
  {
    name: "Customer Email",
    token: "{contact_email}",
  },
  {
    name: "Customer Address",
    token: "{contact_address}",
  },

  {
    name: "Current Date",
    token: "{current_date}",
  },
  // {
  //   name: "Link to Customer Portal",
  //   token: "{link_to_customer_portal}",
  // },

];

const signingPaties = [
  {
    name: "You",
    token: "{contact_name}",
  },
  {
    name: "Contact",
    token: "{customer_first_name}",
  }
];

const NewContractForm = ({
  visible,
  setVisible,
  CreateContract,
  setContracts,
  getContractFunction,
  onFinish,
  head
}) => {
  const [token, setToken] = useState(tokenList.map((x) => x.name)[0]);
  const [mblTokenIndex, setMblTokenIndex] = useState(0);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [addContractLoading, setAddContractLoading] = useState(false);
  const [value, setValue] = useState("");
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
    contractName: "",
    trixEditorValue: "",
  });

  useEffect(() => {
    const trixEditor = document.querySelector("trix-editor");
    trixEditor.style.minHeight = "65vh";
  }, []);

  const handleChange = (e) => {
    setUserInput({ [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (addContractLoading) {
      return;
    }
    if (userInput.contractName === "" || userInput.contractName.trim() === "") {
      toast.error("Name is required", toastConfiguration);
      return;
    }
    if (value === "" || value.trim() === "") {
      toast.error("Please add content", toastConfiguration);
      return;
    }
    setAddContractLoading(true);

    const { data } = await addContract({
      name: userInput.contractName,
      description: value,
    });

    if (data) {
      toast.success("Successfully added contract", toastConfiguration);
      onFinish && onFinish(data);
    } else {
      toast.error("Error in adding contract", toastConfiguration);
    }

    setUserInput({ contractName: "", trixEditorValue: "" });
    setValue("");
    setAddContractLoading(false);
  };

  const handleTrixInputChange = (e, newValue) => {
    setValue(newValue);
  };

  const toolbarActions = [
    "bold",
    "italic",
    "strike",
    "link",
    "heading1",
    "quote",
    "bullet",
    "number",
    "centered",
    "undo",
    "redo",
  ];

  const handleToken = (e) => {
    const tokenValue = e.target.getAttribute("data-value");
    const trixEditor = document.querySelector("trix-editor");
    let trixEditorValue = trixEditor.innerHTML;
    if (trixEditor.editor && trixEditor.editor.insertString) {
      trixEditor.editor.insertString(tokenValue);
    } else {
      trixEditorValue = trixEditorValue + tokenValue;
    }
    return trixEditorValue;
  };

  useEffect(() => {
    // const tokenValue = tokenList.map((x) => x.token)[mblTokenIndex];
    // const trixEditor = document.querySelector("trix-editor");
    // const addToken = () => {
    //   if (trixEditor === null) {
    //     return null;
    //   } else {
    //     let trixEditorValue = trixEditor.innerHTML;
    //     if (trixEditor.editor && trixEditor.editor.insertString) {
    //       trixEditor.editor.insertString(tokenValue);
    //     }
    //     else {
    //       trixEditorValue = trixEditorValue + tokenValue;
    //     }
    //     return trixEditorValue;
    //   }
    // };
    // addToken();
  }, [mblTokenIndex]);

  const generateAIPrompt = async () => {
    if (generateLoading) return;
    if (userInput.contractName === "" || userInput.contractName.trim() === "") {
      toast.error("Name is required", toastConfiguration);
      return;
    }

    setGenerateLoading(true);
    setValue("");
    setUserInput({ trixEditorValue: "" });
    const trixEditor = document.querySelector("trix-editor");
    if (trixEditor.editor) {
      trixEditor.innerHTML = "";
    }

    const { data, error } = await createAIContract({
      name: userInput.contractName
    });

    if (data) {
      toast.success("Successfully generated prompt", toastConfiguration);
      setValue(data?.contract);
      setUserInput({ trixEditorValue: data?.contract });

      if (trixEditor.editor) {
        trixEditor.innerHTML = data?.contract;
      }
    } else {
      toast.error(error || "Error in generating prompt", toastConfiguration);
    }

    setGenerateLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.row}>
      <ToastContainer />
      <div className={styles.colFirst}>
        <Card title={"Contract"} border classTitle={styles.title} 
          head={
            <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
              {head}
              { value && (
                <button
                  className={cn("button", styles.sendBtn)}
                  type="submit"
                  onClick={handleSubmit}
                >
                  {addContractLoading ? <Spinner size="16" color="white" /> : <>Save</>}
                </button>
              )}
            </div>
            }
        >
          <form onSubmit={handleSubmit} noValidate>
            <div style={{ display: 'flex', gap: 12, alignItems: 'flex-end' }}>
              <TextInput
                className={styles.input}
                classLabel={styles.classLabel}
                label="Prompt"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                name="contractName"
                id="contractName"
                type="text"
                value={userInput.contractName}
                required
              />
              <button
                className={cn("button-stroke-red", styles.sendBtn)}
                type="button"
                onClick={generateAIPrompt}
              >
                {generateLoading ? <Spinner size="16" color="gray" /> : <>Generate</>}
              </button>
            </div>

            {(userInput?.trixEditorValue || value) &&
              <>
                <div className={styles.detailsWrap}>
                  <span className={styles.fieldLabel}>Message</span>
                  <div className={styles.tokenWrap}>
                    {tokenList.map((x, index) => (
                      <div
                        data-value={x.token}
                        key={index}
                        className={styles.token}
                        onClick={handleToken}
                      >
                        {x.name}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={cn(styles.detailsWrap, styles.margin)}>
                  <span className={styles.fieldLabel}>Signing Party</span>
                  <div className={styles.tokenWrap}>
                    {signingPaties.map((x, index) => (
                      <div
                        data-value={x.token}
                        key={index}
                        className={styles.token}
                        onClick={() => { }}
                      >
                        {x.name}
                      </div>
                    ))}
                  </div>
                </div>
                {/* <button
                  className={cn("button", styles.sendBtn)}
                  style={{ marginTop: 16 }}
                  type="submit"
                >
                  {addContractLoading ? <Spinner size="16" color="white" /> : <>Save</>}
                </button> */}
              </>
            }
          </form>
        </Card>
      </div>
      <div className={styles.colSecond}>
        <Card border>
          <ReactTrixRTEToolbar toolbarActions={toolbarActions} toolbarId="react-trix-rte-editor" />
          <ReactTrixRTEInput
            toolbarId="react-trix-rte-editor"
            onChange={handleTrixInputChange}
            placeholder="Enter Content"
            defaultValue={userInput?.trixEditorValue}
          />
        </Card>
      </div>
    </div>
  );
};

export default NewContractForm;
