import React, { useState } from "react";
import styles from "./Board.module.sass";
import Card from "../../components/Card";
import Pipeline from "./Pipeline";
import LargeNav from "../../components/LargeNav";
import Spaces from "./Spaces";
import AdjustPipeline from "./Pipeline/AdjustPipeline";
import { useParams, useHistory } from "react-router-dom";
import Edit from "./Pipeline/Edit";

const tabs = ["Pipeline", "Spaces"];
const Board = () => {
    const { id } = useParams();
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState(0);
    const [showAddToDo, setShowAddToDo] = useState(false);
    const [spaceAddNew, setSpaceAddNew] = useState(false);
    const [showAdjustPipeline, setShowAdjustPipeline] = useState(false);

    return (
        <>
            {id ?
                <Edit />
                :
                showAdjustPipeline ?
                    <AdjustPipeline setShowAdjustPipeline={setShowAdjustPipeline} />
                    :
                    <Card
                        className={styles.card}
                        title='Task'
                        border
                        head={
                            <>
                                <div className={styles.buttonContainer}>
                                    {activeIndex === 0 && (
                                        <button className='button-stroke-red' onClick={() => setShowAdjustPipeline(true)}>
                                            Adjust Pipeline
                                        </button>
                                    )}
                                    <button className='button' onClick={() => {
                                        if (activeIndex === 0) history.push("/crm/task/Add");
                                        else if (activeIndex === 1) setSpaceAddNew(true);
                                    }}>
                                        Add new
                                    </button>
                                </div>
                            </>
                        }
                    >
                        <LargeNav
                            className={styles.typeNav}
                            tabOptions={tabs}
                            activeTab={activeIndex}
                            onClick={(_, index) => {
                                setActiveIndex(index);
                            }}
                        />
                        {activeIndex === 0 && <Pipeline showAddToDo={showAddToDo} setShowAddToDo={setShowAddToDo} />}
                        {activeIndex === 1 && <Spaces spaceAddNew={spaceAddNew} setSpaceAddNew={setSpaceAddNew} />}
                    </Card>
            }
        </>
    );
};

export default Board;
