import React, { useEffect, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";
import Search from '../../pages/Products/EditNewProduct/Details/SearchCode'
import Spinner from "../../utils/spinner";
import Icon from "../Icon";

const Dropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  onChange,
  options,
  showSearch,
  taxcodes,
  updateTransaction,
  label,
  tooltip,
  invoiceDueLoading,
  loading,
  updateTaxLoading,
  small,
  upBody,
  handleChange
}) => {
  const [visible, setVisible] = useState(false);
  const [searchArray, setSearchArray] = useState([]);
  const [searchString, setSearchString] = useState("");


  if (loading === undefined) {
    loading = false;
  }

  const handleClick = (value) => {
    setValue(value);
    setVisible(false);
    { handleChange && handleChange(value) }
    { onChange && onChange(value) }
    { updateTransaction && updateTransaction("category", value) }
  };

  const codeSearchHandler = (event) => {
    const valueToSeacrh = event.target.value;
    setSearchString(valueToSeacrh);
    const tempArray = [];

    if (valueToSeacrh) {
      Object.keys(taxcodes)?.map(item => {
        taxcodes[item]?.filter(o => {
          if (o?.display?.substring(0, valueToSeacrh?.length)?.toLowerCase() === valueToSeacrh?.toLowerCase()) {
            tempArray.push(o);
          }
        });
      })
      setSearchArray(tempArray);
    } else {
      setSearchArray([]);
    }
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(loading || invoiceDueLoading || updateTaxLoading ? styles.headWithoutIcon : styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
        >
          {loading || invoiceDueLoading || updateTaxLoading ?
            <div style={{ position: 'absolute', top: 5, right: 10 }}>
              <Spinner loading={loading || invoiceDueLoading || updateTaxLoading} color={"gray"} size={"20"} />
            </div>
            : null}

          <div className={styles.selection}>
            {Object.keys(options)?.map((i, index) => {
              return options[i]?.map((x) => {
                if (x.value === value) {
                  return x.text;
                }
              })
            })}
          </div>
        </div>
        {showSearch && showSearch === "true" && visible ?
          <div className={styles.xHead}>
            <button className={styles.start}>
              <Icon name="search" size="24" />
            </button>
            <input
              className={styles.xInput}
              type="text"
              value={searchString}
              onChange={codeSearchHandler}
              name="tax category"
              placeholder="Search"
            />
          </div>
          : null}
        {searchArray?.length > 0 || searchString !== "" ?
          <div className={cn(styles.body, { [styles.bodyUp]: upBody })}
            style={searchArray?.length > 6 ? { height: 200, overflowY: "scroll" } : null}
          >
            {searchArray?.map((x, index) => {
              return <div
                className={cn(styles.option, {
                  [styles.selectioned]: x.value === value,
                })}
                onClick={() => handleClick(x.value, index)}
                key={index}
              >
                {x.text}
              </div>
            })}
          </div> :
          <div className={cn(styles.body, { [styles.bodyUp]: upBody })}
            style={Object.keys(options).length > 0 ? { height: 200, overflowY: "scroll" } : null}
          >
            {Object.keys(options)?.map((i, ind) => {
              return <div className={styles.title}>
                <div>
                  <h4>{i}</h4>
                </div>
                {options[i]?.map((x, index) => {
                  return <div
                    className={cn(styles.option, {
                      [styles.selectioned]: x.value === value,
                    })}
                    onClick={() => handleClick(x.value, index)}
                    key={index}
                  >
                    {x.text}
                  </div>
                })
                }
              </div>
            })}
          </div>
        }
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
