import { React, useEffect, useState } from "react";
import Image from "../../../../components/Image";
import Spinner from "../../../../utils/spinner";
import { dateFormatterWithTime } from "../../../../utils/utils";
import styles from "./Events.module.sass";
import {
  getContactEvents,
} from "../../../../utils/apiCallHanlder";
import AddEditEvent from "./AddEditEvent";
import AlternativeAvatar from "../../../../components/AlternativeAvatar";

const emailsInfo = [
  {
    subject: "Quote 9878",
    created: "jul 22, 2022",
    status: "Opened",
    type: "Manual",
  },
  {
    subject: "Gotham Limited LLC",
    created: "jul 22, 2022",
    status: "Recipient",
    type: "Automated",
  },
];

const Events = ({
  id,
  name,
  email,
  mobileCode,
  mobile,
  imageUrl,
  showNewEventModal,
  setShowNewEventModal
}) => {
  const [loading, setLoading] = useState(true);
  const [emailInfo, setEmailInfo] = useState(null);
  const [editEvent, setEditEvent] = useState(null);

  useEffect(async () => {
    await getContactAllEvents();
  }, []);

  const getContactAllEvents = async () => {
    setLoading(true);
    const { data } = await getContactEvents({
      id: id,
      email: email,
    });
    if (data) {
      setEmailInfo(data);
    }
    setLoading(false);
  };

  const eventClick = (info) => {
    let event = {
      title: info.title,
      description: info.description,
      start: info.start,
      end: info.end,
      color: info.backgroundColor,
      calendarEventId: info.calendarEventId,
      googleCaledarEventId: info?.googleEventId,
      counterPartyId: info?.counterPartyId
    };
    setEditEvent(event);
    setShowNewEventModal(true);
  };

  return (
    <>
      <div className={styles.infoWrapper}>
        {imageUrl ?
          <Image
            src={imageUrl || "/images/content/avatar.jpg"}
            srcDark={imageUrl || "/images/content/avatar.jpg"}
            className={styles.pic}
          />
          :
          <AlternativeAvatar name={name} className={'small-icon'} />
        }
        <div className={styles.infoWrap}>
          <div className={styles.info}>
            <p className={styles.label}>Name</p>
            <p className={styles.txt}>{name}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.label}>Email</p>
            <p className={styles.txt}>{email}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.label}>Mobile</p>
            <p className={styles.txt}>
              {mobileCode} {mobile}
            </p>
          </div>
        </div>
      </div>
      {loading ? (
        <div className={styles.spinner}>
          <Spinner loading={loading} size={50} color={"gray"} />
        </div>
      ) : (
        <div className={styles.table}>
          <div className={styles.head}>
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Start</div>
            <div className={styles.col}>End</div>
            <div className={styles.col}>Description</div>
          </div>
          {emailInfo?.events.map((x, index) => (
            <div className={styles.row} key={index} onClick={(_) => eventClick(x)}>
              <div className={styles.col}>
                <p className={styles.mblLabel}>Name</p>
                <span>{x.title}</span>
              </div>
              <div className={styles.col}>
                <p className={styles.mblLabel}>Start</p>
                <span>{dateFormatterWithTime(x.start)}</span>
              </div>
              <div className={styles.col}>
                <p className={styles.mblLabel}>End</p>
                <span>{dateFormatterWithTime(x.end)}</span>
              </div>
              <div className={styles.col}>
                <p className={styles.mblLabel}>Description</p>
                <span>{x.description}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      <AddEditEvent
        forceRefreshEvents={getContactAllEvents}
        editEvent={editEvent}
        showNewEventModal={showNewEventModal}
        setShowNewEventModal={setShowNewEventModal}
        isSearch={true}
        id={id}
        customerName={name}
        customerImageUrl={imageUrl}
      />
    </>
  );
};

export default Events;
