import { messageDateTimeFormatter } from "../../../../utils/utils";
import styles from "./Message.module.sass";

const Message = ({ item, imageUrl }) => {
  return (
    <div className={item.sender !== 'admin' ? styles.message : styles.messageRight}>
      {/* <div className={styles.avatar}>
        <img src={item.avatar} alt="Avatar" />
      </div> */}
      <div className={styles.details}>
        <div className={styles.head}>
          <div className={styles.chatContainer}>
            {imageUrl && <img src={item.sender !== 'admin' ? imageUrl : '/gwLogo.png'} alt="Avatar" className={styles.userImage} />}
            <div className={styles.contentContainer}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={styles.man}>{item.sender !== 'admin' ? 'Me' : 'Co-Pilot'}</div>
                <div className={styles.time}>{messageDateTimeFormatter(item.timeStamp)}</div>
              </div>
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{ __html: item.body }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
