import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import styles from "./modal.module.sass";
import Icon from "../../../../../components/Icon";
import TextInput from "../../../../../components/TextInput";
import Card from "../../../../../components/Card";
import {
  downloadAttachment,
  sendContactEmail,
} from "../../../../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../../../utils/utils";
import Spinner from "../../../../../utils/spinner";
import SendNewEmail from "../../../../Studio/Email/SendNewEmail";

const Modal = ({
  outerClassName,
  visible,
  customerId,
  onClose,
  children,
  buttonMargin,
  replyToEmail,
  selectedEmailContent,
}) => {
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [showSendNewEmail, setShowSendNewEmail] = useState(false);
  const [currentFileName, setCurrentFileName] = useState(null);

  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal");
      enableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
    setShowSendNewEmail(false);
  }, [visible]);

  const sendEmail = () => {
    setShowSendNewEmail(true);
  };

  const base64toBlob = (base64Data, contentType) => {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  return createPortal(
    visible && (
      <div id='modal' className={cn("emailModal", styles.modal)}>
        <div className={cn(styles.outer, outerClassName)}>
          <OutsideClickHandler onOutsideClick={() => {}}>
            <button
              className={styles.close}
              onClick={() => {
                onClose();
              }}
              style={buttonMargin && { top: buttonMargin }}
            >
              <Icon name='close' size='20' />
            </button>
            <Card
              className={styles.card}
              classCardHead={styles.head}
              title='Email Details'
              /* classTitle={cn("title-blue", styles.title)} */
            >
              <TextInput
                label='From'
                value={selectedEmailContent?.from}
                name='email'
                type='text'
                required
              />
              <TextInput
                label='To'
                value={selectedEmailContent?.to}
                name='email'
                type='text'
                required
              />
              {selectedEmailContent?.cc && (
                <TextInput
                  label='Cc'
                  value={selectedEmailContent?.cc}
                  name='email'
                  type='text'
                  required
                />
              )}
              {selectedEmailContent?.bcc && (
                <TextInput
                  label='Bcc'
                  value={selectedEmailContent?.bcc}
                  name='email'
                  type='text'
                  required
                />
              )}
              <div style={{ marginTop: 20 }}>
                <span className={styles.fieldLabel}>Subject</span>
                <textarea
                  value={selectedEmailContent?.subject}
                  className={styles.input}
                  style={{ height: 50, marginTop: 16 }}
                  name='address'
                  type='text'
                  required
                />
              </div>
              <div style={{ marginTop: 20, pointerEvents: "auto" }}>
                <span className={styles.fieldLabel}>Body</span>
                <div
                  className={styles.input}
                  style={{ height: 200, marginTop: 16, overflow: "scroll" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      selectedEmailContent?.body.replace(
                        'class="container"',
                        'class="container1"'
                      ) || "",
                  }}
                >
                  {/* {selectedEmailContent?.body} */}
                </div>
              </div>
              {selectedEmailContent?.attachements?.length > 0 && (
                <div style={{ marginTop: 20 }}>
                  <span className={styles.fieldLabel}>Attachments</span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      marginTop: 12,
                      paddingBottom: 24,
                    }}
                  >
                    {selectedEmailContent?.attachements?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                            pointerEvents: "auto",
                          }}
                          onClick={async () => {
                            setCurrentFileName(item?.filename);
                            setAttachmentLoading(true);
                            const { data } = await downloadAttachment({
                              emailId: selectedEmailContent?.id,
                              attachmentId: item?.attachementId,
                              filename: item?.filename,
                              contentType: item?.contentType,
                            });
                            if (data) {
                              const blob = base64toBlob(
                                data.attachment.data,
                                item?.contentType
                              );
                              const blobUrl = URL.createObjectURL(blob);

                              const a = document.createElement("a");
                              a.style.display = "none";
                              document.body.appendChild(a);
                              const url = window.URL.createObjectURL(blob);
                              a.href = url;
                              a.download = item?.filename || "file.png";
                              a.click();
                              window.URL.revokeObjectURL(url);
                              document.body.removeChild(a);

                              toast.success(
                                "Successfully downloaded attachment",
                                toastConfiguration
                              );
                            } else {
                              toast.error(
                                "Error in dowloading attachment",
                                toastConfiguration
                              );
                            }
                            setAttachmentLoading(false);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 8,
                            }}
                          >
                            {item?.filename}
                            {attachmentLoading &&
                              currentFileName === item?.filename && (
                                <Spinner size='24' color='gray' />
                              )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </Card>
            <div className={styles.newEmailBtn}>
              <button
                className='button'
                style={{ width: 150 }}
                onClick={sendEmail}
              >
                <Icon size='24' name='refresh' viewBox='0 0 24 24' />
                Reply
              </button>
            </div>
          </OutsideClickHandler>
        </div>
        <SendNewEmail
          visible={showSendNewEmail}
          customerId={customerId}
          setVisible={setShowSendNewEmail}
          toMail={replyToEmail || selectedEmailContent?.to}
          mailSubject={"RE: " + selectedEmailContent?.subject}
          replyClose={() => {
            setShowSendNewEmail(false);
          }}
        />
      </div>
    ),
    document.body
  );
};

export default Modal;
