import React from 'react';
import Checkbox from '../../../components/Checkbox';
import Tooltip from '../../../components/Tooltip';
import styles from './AddNew/addnew.module.sass';

const CheckboxWithText = ({ value, setValue, text, showTooltip, onChange }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <Checkbox value={value} borderRadius={showTooltip ? false : true}
                onChange={onChange ? onChange : () => {
                    setValue(!value);
                }} />
            <span style={{
                fontFamily: "tomatoGrotesk",
                fontStyle: "normal",
                fontWeight: 600,
                marginLeft: 15,
                fontSize: "15px",
                lineHeight: "24px",
                letterSpacing: "-0.01em",
                color: "#6F767E"
            }}>{text}</span>
            {showTooltip ?
                <Tooltip
                    className={styles.tooltip}
                    title={"will add text later"}
                    icon="info"
                    place="right"
                /> : null}
        </div>
    )
}

export default CheckboxWithText;