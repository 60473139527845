import React, { useState, useMemo } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import Select from "react-select";
import Icon from "../../../components/Icon";
import Takeoff from "../Takeoff";
import countryList from "react-select-country-list";
import { STRIPE_COUNTRIES } from "../../../utils/appConstanst";
import {
  SetAlreadyLaunchedIntercom,
  getCurrentUser,
  getReturnUrl,
  getStripeReturnURL,
  handleLoginSignUpResponse,
  reverifyHandler,
  toastConfiguration,
} from "../../../utils/utils";
import {
  createStandardStripe,
  externalSignIn,
  getUserData,
  signupV2,
  validateEmailPinCode,
  resendEmailPinCode,
  completeSignUpV2,
} from "../../../utils/apiCallHanlder";
import { useHistory } from "react-router";
import Code from "../Code";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../utils/spinner";
import Checkbox from "../../../components/Checkbox";

const countries = [];

const Entry = ({
  onConfirm,
  istestmode,
  isCustom,
  setSignUpLoading,
  setActiveIndex,
  activeIndex,
  showCode,
  setShowCode,
  whereMeet,
  setWhereMeet,
  emailParam,
  signUpTokenParam,
  ProcessNextAction,
}) => {
  const [message, setMessage] = useState("");
  const [googleSignup, setGoogleSignup] = useState(false);
  const [error, setError] = useState(null);
  const [valid, setValid] = useState(null);
  const [email, setEmail] = useState(emailParam || "");
  const [signUpToken, setSignUpToken] = useState(signUpTokenParam || "");
  const [stripe, setStripe] = useState("no");
  const [password, setPassword] = useState("");
  const [meet, setMeet] = useState("");
  const [country, setCountry] = useState("");
  const [emailExists, setEmailExists] = useState(false);
  const [displayCountry, setDisplayCountry] = useState({
    value: null,
    label: "Choose Country",
  });
  const [errorMessage, setErrorMessage] = useState("");

  let options = useMemo(() => countryList().getData(), []);

  const history = useHistory();

  const [loading1, setloading1] = useState(false);
  const [loadingPin, setloadingPin] = useState(false);
  const [loadingResendPin, setLoadingResendPin] = useState(false);
  const [isDisclaimerChecked, setIsDisclaimerChecked] = useState(true);

  const [loading, setLoading] = useState(false);

  if (countries.length < 1) {
    for (let j = 0; j < STRIPE_COUNTRIES?.length; j++) {
      for (let i = 0; i < options?.length; i++) {
        if (
          options[i]?.label?.toLowerCase() ===
          STRIPE_COUNTRIES[j]?.toLowerCase()
        ) {
          countries.push(options[i]);
        }
      }
    }
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const emailHandleChange = (event) => {
    setErrorMessage("");
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else if (isValidEmail(event.target.value)) {
      setError(null);
      setEmail(event.target.value);
      setValid("Valid");
    } else {
      setError(null);
      setValid(null);
    }

    setMessage(event.target.value);
  };

  const passwordHandleChange = (event) => {
    setErrorMessage("");
    setPassword(event?.target?.value);
  };

  const externalSignInHandler = async () => {
    setSignUpLoading(true);

    const ReturnUrl = getReturnUrl("forward");
    const FailureURL = getReturnUrl("");
    const NewUserURL = getReturnUrl("sign-up-details");

    const signInResponse = await externalSignIn({
      ReturnUrl,
      FailureURL,
      NewUserURL,
      doSignup: true,
      version: "v2",
      isCustomStripeSignup: isCustom,
    });
    const { data } = signInResponse;
    if (data) {
      window.location = data.redirectURL;
      setTimeout(() => {
        setSignUpLoading(false);
      }, 8000);
    } else {
      history.push("/");
    }
  };

  const loginOnSignup = async () => {
    setSignUpLoading(true);

    const ReturnUrl = getReturnUrl("forward");
    const FailureURL = getReturnUrl("sign-up");
    const NewUserURL = getReturnUrl("sign-up/2");

    const signInResponse = await externalSignIn({
      ReturnUrl,
      FailureURL,
      NewUserURL,
      doSignup: true,
      version: "v2",
      istestmode,
      isCustomStripeSignup: isCustom,
    });
    const { data } = signInResponse;
    if (data) {
      window.location = data.redirectURL;
      setTimeout(() => {
        setSignUpLoading(false);
      }, 8000);
    } else {
      history.push("/");
    }
  };

  const ResendEmailPinCodeHandler = async () => {
    setLoadingResendPin(false);
    const signInResponse = await resendEmailPinCode({
      email,
    });
    const { data, message, error } = signInResponse;
    if (data) {
      toast && toast.success(message, toastConfiguration);
    } else {
      toast.error(error, toastConfiguration);
    }

    setLoadingResendPin(false);
  };

  const ValidateEmailPinCodeHandler = async ({ pin }) => {
    if (!pin || pin.length != 4) {
      toast && toast.error("Please Fill verification code", toastConfiguration);
    } else {
      setloadingPin(true);
      const signInResponse = await validateEmailPinCode({
        email,
        code: pin,
      });
      const { data, message, error } = signInResponse;
      if (data) {
        setSignUpLoading(false);
        history.push({
          pathname: "/sign-up-details",
          state: {
            token: data?.nextAction?.myNewToken,
            email: email
          },
        });
      } else {
        toast.error(error, toastConfiguration);
      }

      setloadingPin(false);
    }
  };
  const SetCountryHandler = async () => {
    if (!country) {
      toast.error("Please select country.", toastConfiguration);
    } else {
      setError(null);

      const fbclid = localStorage.getItem("fbclid") || "";
      const gclid = localStorage.getItem("gclid") || "";
      let parsedUser = null;

      if (!email) {
        parsedUser = getCurrentUser();
      }

      if (!country) {
        setErrorMessage("Please Choose Country");
      } else {
        setLoading(true);
        const signupResponse = await completeSignUpV2({
          email: email || parsedUser?.email,
          meet,
          moreInfo: "yes",
          istestmode: istestmode,
          isCustomStripeSignup: isCustom,
          country: country,
          returnURL: getStripeReturnURL(),
          fbclid,
          gclid,
        });
        const { data, code, error } = signupResponse;

        setTimeout(() => {
          setLoading(false);
        }, 2000);

        if (code === 200 && data) {
          data.redirectURL = null;
          localStorage.removeItem("fbclid");
          localStorage.removeItem("gclid");

          localStorage.setItem(
            "connectStatus",
            JSON.stringify(data?.connectStatus)
          );
          localStorage.setItem("stripeConnection", data.isConnected);
          localStorage.setItem("isPending", data.isPending);
          localStorage.setItem("isTestMode", data.istestmode);
          localStorage.setItem("currencySymbol", data.currencySymbol || "$");
          localStorage.setItem("currency", data.currency || "USD");
          localStorage.setItem("isAccountingEnabled", false);
          SetAlreadyLaunchedIntercom(false);

          var isIntercomAlreadyBooted = window?.Intercom?.booted;
          if (isIntercomAlreadyBooted) {
            window.Intercom("shutdown");
            window.Intercom.booted = false;
          }

          handleLoginSignUpResponse(data, history, false, true);
          history.push("/dashboard");
          setActiveIndex(5);
        } else {
          toast && toast.error(error, toastConfiguration);
        }
      }

      //setActiveIndex(3);
    }
  };

  const handleEmailPass = async () => {
    if (email === "" || password === "") {
      setErrorMessage("Please Fill out all fields");
    }

    if (error === "Email is invalid") {
      setErrorMessage("Please Provide Valid Email");
    } else if (password.length < 6) {
      setErrorMessage("Password must be 6 characters long.");
    } else {
      setloading1(true);
      const signInResponse = await signupV2({
        email,
        password,
        istestmode,
        IsCustomStripeSignup: isCustom
      });
      const { data, message, error } = signInResponse;
      if (data) {
        setSignUpLoading(false);
        ProcessNextAction(data.nextAction);
        toast && toast.success(message, toastConfiguration);
      } else {
        if (error === "Email already exists.") {
          setEmailExists(true);
          setActiveIndex(5);
        }
        toast.error(error, toastConfiguration);
      }

      setloading1(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className={styles.entry}>
        <div className={styles.body}>
          {activeIndex === 1 ? (
            !showCode ? (
              <>
                <div>
                  <div className={styles.externalLogin}>
                    <div
                      className={cn("button", styles.button)}
                      onClick={() => {
                        externalSignInHandler();
                      }}
                    >
                      <button className={cn(styles.link, styles.google)}>
                        Sign up with Google
                      </button>
                    </div>
                  </div>

                  <div
                    className={styles.terms}
                    style={{ marginBottom: 32, marginTop: 32 }}
                  >
                    <div className={styles.termsText}>
                      <span> Or signup with email </span>
                    </div>
                  </div>
                  <div className={styles.emailWrapper}>
                    <TextInput
                      className={error ? styles.hasError : styles.field}
                      name='email'
                      type='email'
                      placeholder='Your email'
                      required
                      icon={"mail"}
                      value={message}
                      onChange={emailHandleChange}
                    />
                    {error ? (
                      <Icon
                        name='warning'
                        className={styles.warningIcon}
                        size='24'
                      />
                    ) : valid ? (
                      <Icon
                        name='check'
                        className={styles.validIcon}
                        size='24'
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <TextInput
                    className={styles.field}
                    name='password'
                    type='password'
                    placeholder='Password'
                    onChange={passwordHandleChange}
                    required
                    icon='lock'
                  />
                </div>

                  <button
                    className={cn(
                      "button",
                      error || !isDisclaimerChecked
                        ? styles.disable
                        : valid
                          ? styles.button
                          : styles.disable
                    )}
                    onClick={handleEmailPass}
                  >
                    {loading1 ? (
                      <>
                        <Spinner loading={loading1} size={30} />
                      </>
                    ) : (
                      <>Sign up</>
                    )}
                  </button>

                {errorMessage !== "" ? (
                  <div className={styles.errorContainer}>
                    <span className={styles.errorMessage}>{errorMessage}</span>
                  </div>
                ) : null}

                <div className={styles.terms}>
                  <Checkbox
                    value={isDisclaimerChecked}
                    onChange={() =>
                      setIsDisclaimerChecked(!isDisclaimerChecked)
                    }
                    name='checkbox'
                    className={styles.termsIcon}
                    size='24'
                  />
                  <div className={styles.termsText}>
                    <span>By continuing, you agree to Great Week's </span>
                    <a
                      href='https://greatweek.com/terms/'
                      target='_blank'
                      className={styles.termsLink}
                      rel='noopener noreferrer'
                    >
                      Terms of Service
                    </a>
                    <span> and</span>
                    <a
                      href='https://greatweek.com/privacy-policy/'
                      target='_blank'
                      className={styles.termsLink}
                      rel='noopener noreferrer'
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <Code
                ValidateEmailPinCodeHandler={ValidateEmailPinCodeHandler}
                ResendEmailPinCodeHandler={ResendEmailPinCodeHandler}
                loadingPin={loadingPin}
                loadingResendPin={loadingResendPin}
              />
            )
          ) : activeIndex === 2 ? (
            <>
              <Select
                options={countries}
                className={cn(styles.dropdown, "drop")}
                value={displayCountry}
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={(item) => {
                  setCountry(item?.value);
                  setDisplayCountry(item);
                }}
              />
              <button
                className={cn("button-small", styles.button)}
                style={{ marginTop: 16 }}
                onClick={() => {
                  if (!country) {
                    toast.error(
                      "Please select country to proceed",
                      toastConfiguration
                    );
                    return;
                  }
                  setActiveIndex(5);
                }}
              >
                Continue
              </button>
            </>
          ) : activeIndex === 3 ? (
            whereMeet ? (
              <>
                <div className={styles.radioInputGroup}>
                  <button className='button-small'>
                    <input type='radio' name='radio' />
                    <label htmlFor='yes'>Google</label>
                  </button>
                </div>
                <div className={styles.radioInputGroup}>
                  <button className='button-small'>
                    <input type='radio' name='radio' />
                    <label htmlFor='yes'>LinkedIn</label>
                  </button>
                </div>
                <div className={styles.radioInputGroup}>
                  <button className='button-small'>
                    <input type='radio' name='radio' />
                    <label htmlFor='yes'>Facebook</label>
                  </button>
                </div>
                <div className={styles.radioInputGroup}>
                  <button className='button-small'>
                    <input type='radio' name='radio' />
                    <label htmlFor='yes'>Instagram</label>
                  </button>
                </div>
                <div className={styles.radioInputGroup}>
                  <button className='button-small'>
                    <input type='radio' name='radio' />
                    <label htmlFor='yes'>Twitter</label>
                  </button>
                </div>
                <div className={styles.radioInputGroup}>
                  <button className='button-small'>
                    <input type='radio' name='radio' />
                    <label htmlFor='yes'>Email Campaign</label>
                  </button>
                </div>
                <TextInput label={"Other"} className={styles.otherInput} />
                <button
                  className={cn("button-small", styles.button)}
                  onClick={() => setWhereMeet(false)}
                >
                  Continue
                </button>
              </>
            ) : (
              <>
                {
                  <>
                    <Takeoff
                      className={cn(styles.radioInputGroup)}
                      onClick={() => valid && onConfirm}
                      data={valid}
                      email={email}
                      password={password}
                      setError={setError}
                      error={error}
                      payloadData={{
                        email,
                        meet,
                        moreInfo: "yes",
                        istestmode,
                        country: country,
                        signUpToken,
                        returnURL: getStripeReturnURL(),
                      }}
                      errorMessage={errorMessage}
                      toast={toast}
                      toastConfiguration={toastConfiguration}
                      setErrorMessage={setErrorMessage}
                      onBoardDetails
                      children={
                        <>
                          <input type='radio' name='radio' />
                          <label htmlFor='verify'>
                            Continue adding details
                          </label>
                        </>
                      }
                    />

                    <Takeoff
                      className={cn(styles.radioInputGroup)}
                      onClick={() => valid && onConfirm}
                      data={valid}
                      email={email}
                      password={password}
                      setError={setError}
                      error={error}
                      payloadData={{
                        email,
                        meet,
                        moreInfo: "no",
                        istestmode,
                        country: country,
                        signUpToken,
                        returnURL: getStripeReturnURL(),
                      }}
                      errorMessage={errorMessage}
                      setErrorMessage={setErrorMessage}
                      children={
                        <>
                          <input type='radio' name='radio' />
                          <label htmlFor='verify'>I'll do this later</label>
                        </>
                      }
                    />
                  </>
                }
              </>
            )
          ) : activeIndex === 4 ? (
            <>
              <div className={styles.cardContainer}>
                <div className={styles.wrap}>
                  <img
                    className={styles.pic}
                    src='/SuccessTick.png'
                    style={{ width: 126.5, height: 126.5 }}
                    srcDark='/SuccessTick.png'
                    alt='delete'
                  />
                  <div className={styles.emailText}>
                    {`Your email is now verified!`}
                  </div>
                  <button
                    onClick={async () => setActiveIndex(2)}
                    className='button'
                    style={{ marginTop: 16 }}
                  >
                    Continue Signup
                  </button>
                </div>
              </div>
            </>
          ) : activeIndex === 5 ? (
            <>
              <div className={styles.cardContainer}>
                <div className={styles.wrap}>
                  <img
                    className={styles.pic}
                    src={emailExists ? "/Error.svg" : "/SuccessTick.png"}
                    style={{ width: 126.5, height: 126.5 }}
                    srcDark='/SuccessTick.png'
                    alt='delete'
                  />
                  <div className={styles.emailText}>
                    {emailExists
                      ? "There is already an account registered with this email."
                      : "Welcome! Your account is all set and ready for you to start using Greatweek. While you currently have access to limited functionalities, upgrading is easy thanks to our user-friendly dashboard. Simply enter more details to unlock the full potential of our platform. Let's get started!"}
                  </div>
                  {emailExists && (
                    <div className={styles.loginText}>Please login instead</div>
                  )}
                  <button
                    onClick={async () => {
                      if (emailExists) {
                        setEmailExists(false);
                        history.push("sign-in");
                      } else {
                        await SetCountryHandler();
                      }
                    }}
                    className='button'
                    style={{ marginTop: 16 }}
                  >
                    {loading ? (
                      <Spinner size='24' color='white' />
                    ) : emailExists ? (
                      "Login"
                    ) : (
                      "Continue"
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Entry;
