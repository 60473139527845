import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Checkbox from "../../../../../../components/Checkbox";
import Icon from "../../../../../../components/Icon";

const Row = ({
  item,
  onChange,
  activeTable,
  activeId,
  className,
  editAction,
  deleteAction,
  singleCheck,
  comingFromCustomer,
  editCustomer
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
        
      >
      <div onClick={editCustomer} className={styles.editOverlay}></div>
        {/* <div className={styles.col}>
          {singleCheck || (
            <Checkbox className={styles.checkbox} onChange={onChange} />
          )}
        </div> */}
        <div className={styles.col}>
          <div className={styles.user}>{item.name}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.date}>{item.email}</div>
        </div>
        <div className={styles.col}>
          <div className={cn(styles.amount)}>{item.accountRouting}</div>
        </div>
        <div className={styles.col}>
          <span className={styles.mblCreate}>Created</span>
          <div className={styles.created}>{item.accountType}</div>
        </div>
        <div className={styles.col}>
          <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div
              className={cn(styles.dotsBtn, className, {
                [styles.active]: visible,
              })}
            >
              <div className={styles.head}>
                <button
                  className={cn(styles.btn)}
                  onClick={() => setVisible(!visible)}
                >
                  <Icon name={"dots"} />
                </button>
              </div>
              <div className={styles.actionBody}>
                {editAction && editAction}
                {deleteAction && deleteAction}
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </>
  );
};

export default Row;
