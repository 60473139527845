import React from "react";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import styles from "./ProfitAndLoss.module.sass";
import cn from "classnames";
import { useEffect } from "react";
import { useState } from "react";
import { dateFormatter, toastConfiguration, transactionDateFormatter } from "../../../utils/utils";
import { getExcelReportOfProfitLoss, getProfitLossReport } from "../../../utils/apiCallHanlder";
import DateRangeInput from "../../../components/DateRangeInput";
import Spinner from "../../../utils/spinner";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Image from "../../../components/Image";
import { useHistory } from 'react-router-dom';
import LargeNav from "../../../components/LargeNav";
import { toast } from "react-toastify";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
const filters = ["All Time", "This Quarter", "Previous Quarter", "This Year", "Previous Year"]

const ProfitAndLoss = ({
  hideTabs,
  setPlData,
  plData,
  startDate,
  endDate
}) => {

  const history = useHistory();

  const todaysDate = new Date();
  const [loading, setLoading] = useState(false);
  const [profitLossReport, setProfitLossReport] = useState({});

  const [showMonths, setShowMonths] = useState(false);
  const [activeYear, setActiveYear] = useState(0);
  const [activeFilter, setActiveFilter] = useState(0);

  const [currentQuarter, setCurrentQuarter] = useState(0);
  const [downloadLoading, setDonwloadLoading] = useState(false);



  useEffect(async () => {

    if (plData) {
      setProfitLossReport(plData);
    } else {
      await profitLossReportGetter();
    }

    if (hideTabs) tabClickHandler(2);
  }, [])

  const profitLossReportGetter = async (start, end) => {
    setLoading(true);
    const { data } = await getProfitLossReport({
      fromDate: transactionDateFormatter(start || startDate),
      toDate: transactionDateFormatter(end || endDate)
    })
    if (data) {
      setPlData && setPlData(data?.report);
      setProfitLossReport(data?.report);
    }
    setLoading(false);
  }

  const printReport = async () => {
    setDonwloadLoading(true);
    const input = document.getElementById('profitReportDiv');
    const dateRange = document.getElementsByClassName('profitReportDateRange');


    if (input && dateRange[0]) {
      input.style.size = "8.3in 11.7in";
      input.style.padding = "0mm 0mm 5mm 0mm"
      dateRange[0].style.marginTop = "12px"
    }

    const canvas = await html2canvas(input, {
      scale: 3
    });
    if (canvas) {
      const imgData = canvas.toDataURL('image/jpeg');
      const pdf = new jsPDF('p', 'em', [50, 70]);

      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, "JPEG", 2, 0, width + 4, height - 22, '', "FAST");
      pdf.save(`${dateFormatter(todaysDate)}_profitSheet.pdf`);
      setDonwloadLoading(false);
    }

    if (input && dateRange[0]) {
      input.style.size = "unset";
      input.style.padding = "unset";
      dateRange[0].style.marginTop = "0px"
    }

    setDonwloadLoading(false);
  }

  const tabClickHandler = (index) => {
    setActiveFilter(index);
    setCurrentQuarter(0);

    if (index === 0) {
      setActiveYear(0);
      setShowMonths(false);
      return;
    }

    if (index > 0 && index < 4) {
      const today = new Date();
      const currentYear = today?.getFullYear();
      const quarter = Math.floor((today.getMonth() + 3) / 3);

      if (index === 1) {
        setCurrentQuarter(quarter);
        setActiveYear(currentYear);
      } else if (index === 2) {
        if (quarter === 1) {
          setActiveYear(currentYear - 1);
          setCurrentQuarter(4)
        } else {
          setCurrentQuarter(quarter - 1);
          setActiveYear(currentYear);
        }
      } else {
        setActiveYear(currentYear);
      }

      setShowMonths(true);
      return;
    }

    if (index === 4) {
      setActiveYear(new Date()?.getFullYear() - 1);
      setShowMonths(true);
      return;
    }
  }



  return (
    <div className={styles.row} style={{ overflowY: hideTabs ? 'auto' : 'unset' }}>
      <div className={styles.col7} id="profitReportDiv" style={{ minWidth: currentQuarter ? '871px' : '98%' }}>
        {!hideTabs && (
          <div style={{ marginTop: -16 }} data-html2canvas-ignore="true">
            <LargeNav
              tabOptions={filters}
              activeTab={activeFilter}
              onClick={(x, index) => {
                tabClickHandler(index);
              }}
            />
          </div>
        )}
        <Card
          /* classTitle="title-blue" */
          //title="Profit & Loss Report"
          className={styles.card}
        // head={
        //   downloadLoading ?
        //     <Spinner size="24" color="gray" />
        //     :
        //     <div className={styles.buttonContainer}>
        //       <DateRangeInput
        //         classInputValue={styles.inputValue}
        //         classInput={styles.input}
        //         icon="calendar"
        //         className={styles.calendar}
        //         setStartDate={setStartDate}
        //         setEndDate={setEndDate}
        //         getUpdatedDashBoardData={profitLossReportGetter}
        //         startDate={startDate}
        //         endDate={endDate}
        //         classNameToPick={"profitReportDateRange"}
        //         showMonthYearOnly={true}
        //       />

        //     </div>
        // }
        >
          {!hideTabs && (
            <div className={styles.buttonContainer2}>
              {
                downloadLoading ?
                  <Spinner size="24" color="gray" />
                  :
                  <>
                    <div className={styles.dateCreated}>{`Date Created: ${dateFormatter(todaysDate)}`}</div>
                    {/* <div onClick={printReport} style={{ cursor: 'pointer' }} data-html2canvas-ignore="true">
                    <Icon name="cloud_download" size="24" />
                  </div> */}
                  </>
              }
            </div>
          )}
        </Card>
        <div className={hideTabs ? cn(styles.autoOverflow, styles.tableWrapper) : styles.tableWrapper}
          style={{ overflowX: currentQuarter ? 'hidden' : 'auto' }}>
          {loading ?
            <div className={styles.spinner}>
              <Spinner size={"44"} loading={loading} color={"gray"} />
            </div> :
            profitLossReport?.years?.length < 1 ?
              <div className={styles.noDataImg}>
                <Image
                  src={"/images/icons/empty-black.png"}
                  srcDark={"/images/icons/empty-white.png"}
                />
              </div>
              :
              <div className={styles.tableWrap}>
                <div className={cn(styles.table, styles.yearTable)}>
                  <div
                    className={styles.col}
                    style={{
                      flex: `0 0 ${100 / (profitLossReport?.years?.length + 1)}%`,
                    }}
                  >
                    {''}
                  </div>
                  {profitLossReport?.years?.map((x, index) => {
                    return showMonths && x !== activeYear ? null : <div
                      className={styles.col}
                      key={index}
                      style={{
                        flex: `0 0 ${100 / (profitLossReport?.years?.length + 1)}%`,
                        textAlign: 'center'
                      }}
                    >
                      {x}
                      {activeFilter === 0 && (
                        <div
                          style={{ marginLeft: 8, marginTop: -1, cursor: 'pointer' }}
                          onClick={() => {
                            if (x === activeYear) {
                              setShowMonths(false);
                              setActiveYear(0);
                            } else {
                              setActiveYear(x);
                              setShowMonths(true);
                            }
                          }}
                        >
                          <Icon size="16" name={x === activeYear ? "hide" : "see"} />
                        </div>
                      )}
                    </div>
                  }
                  )}
                </div>
                {showMonths && (
                  <div className={cn(styles.table, styles.yearTable)}>
                    <div
                      className={styles.col}
                      style={{
                        flex: `0 0 ${100 / (profitLossReport?.years?.length + 1)}%`,
                      }}
                    >
                      {''}
                    </div>
                    {(currentQuarter ? months?.slice((currentQuarter - 1) * 3, currentQuarter * 3) : months)?.map((x, index) => (
                      <div
                        className={styles.colX12}
                        key={index}
                        style={{
                          flex: currentQuarter ? `0 0 ${100 / 4.5}%` : `0 0 ${100 / 9.02}%`,
                          borderRight: (currentQuarter && index === 2) || index === 11 ? "1px solid #000" : "unset",
                          borderBottom: '1px solid #000',
                          textAlign: 'center'
                        }}
                      >
                        {x + ' ' + activeYear?.toString()?.slice(-2)}
                      </div>
                    ))}
                  </div>
                )}
                {profitLossReport?.heads?.map((item, itemI) => {
                  return item?.isTotalOnly ?
                    <div
                      className={cn(
                        styles.table,
                        { [styles.gTotal]: true }
                      )}
                      style={{ borderTop: 'unset' }}
                    >
                      <div
                        className={styles.col}
                        style={{
                          flex: `0 0 ${100 / (profitLossReport?.years?.length + 1)}%`,
                          justifyContent: 'unset'
                        }}
                      >{`Total ${item?.title}`}
                      </div>
                      {showMonths ?
                        Object.keys(item?.byMonth).map((singleItem, singleIndex) => {
                          return singleItem === activeYear?.toString() &&
                            Object.keys(item?.byMonth[singleItem])?.map((singleMonth, singleMonthIndex) => {
                              return (currentQuarter ?
                                (singleMonth > (currentQuarter - 1) * 3 && singleMonth <= currentQuarter * 3) : true) &&
                                <div
                                  className={styles.colX12}
                                  style={{
                                    flex: currentQuarter ? `0 0 ${100 / 4.5}%` : `0 0 ${100 / 9.02}%`,
                                    borderLeft: singleMonthIndex === 0 ? 'unset' :
                                      currentQuarter ? (currentQuarter - 1) * 3 === singleMonthIndex ? 'unset' : '1px solid #000'
                                        : '1px solid #000',
                                    textAlign: 'center',
                                    backgroundColor: '#464542',
                                    marginTop: singleMonthIndex > 5 ? -1 : 0
                                  }}
                                  key={singleIndex + singleMonthIndex}
                                >
                                  {item?.byMonth[singleItem][singleMonth]}
                                </div>
                            })
                        })
                        :
                        Object.keys(item?.total).map((singleItem, singleIndex) => {
                          return <div
                            className={styles.col}
                            style={{
                              flex: `0 0 ${100 / (profitLossReport?.years?.length + 1)}%`,
                              borderRight: (singleIndex + 1) === profitLossReport?.years?.length ? "unset" : "1px solid #000",
                              textAlign: 'center'
                            }}
                            key={singleIndex}
                          >
                            {item?.total[singleItem]}
                          </div>
                        })}
                    </div>
                    : <div className={styles.tableGroup} key={itemI} style={{ marginTop: itemI !== 0 ? 16 : 0 }}>
                      {showMonths ?
                        <div className={styles.table}
                          style={{
                            borderRight: 'unset',
                            borderTop: 'unset'
                          }}
                        >
                          <div
                            className={styles.col}
                            style={{
                              flex: `0 0 33.40%`,
                              justifyContent: 'unset',
                              borderTop: '1px solid #000'
                            }}
                          >
                            {item?.title}
                          </div>
                          {months?.map((x, index) => (
                            <div
                              className={styles.colX12}
                              key={index}
                              style={{
                                flex: `0 0 ${(100 / 9.10)}%`,
                                textAlign: 'center',
                                borderTop: 'unset',
                                borderBottom: 'unset',
                                borderRight: 'unset',
                                borderLeft: 'unset'
                              }}
                            >
                              {''}
                            </div>
                          ))}
                        </div>
                        :
                        <div
                          className={styles.table}
                          style={{ borderRight: '1px solid #000' }}
                        >
                          <div className={styles.col12}>{item?.title}</div>
                        </div>
                      }
                      {item?.children?.map((x, index) => (
                        <div
                          className={cn(
                            styles.table,
                            { [styles.total]: x.total },
                          )}
                          key={index}
                          style={{ borderRight: showMonths ? 'unset' : '1px solid #000' }}
                        >
                          <div
                            className={styles.col}
                            style={{
                              flex: `0 0 ${100 / (profitLossReport?.years?.length + 1)}%`,
                              justifyContent: 'unset'
                            }}
                          >{x.title}
                          </div>
                          {showMonths ?
                            Object.keys(x?.byMonth).map((singleItem, singleIndex) => {
                              return singleItem === activeYear?.toString() &&
                                Object.keys(x.byMonth[singleItem])?.map((singleMonth, indexMonth) => {
                                  return (currentQuarter ?
                                    (singleMonth > (currentQuarter - 1) * 3 && singleMonth <= currentQuarter * 3) : true) &&
                                    <div
                                      className={styles.colX12}
                                      style={{
                                        flex: currentQuarter ? `0 0 ${100 / 4.5}%` : `0 0 ${100 / 9.02}%`,
                                        borderTop: indexMonth < 6 ? 'unset' : '1px solid #000',
                                        borderLeft: indexMonth === 0 ? 'unset' :
                                          currentQuarter ? (currentQuarter - 1) * 3 === indexMonth ? 'unset' : '1px solid #000'
                                            : '1px solid #000',
                                        borderRight: (currentQuarter && (singleMonth === (currentQuarter * 3)?.toString()) || indexMonth === 11) ?
                                          "1px solid #000" : "unset",
                                        marginTop: indexMonth > 5 ? -1 : 0,
                                        borderBottom: 'unset',
                                        textAlign: 'center',
                                        cursor: hideTabs ? 'unset' : 'pointer'
                                      }}
                                      onClick={() => {
                                        if (x?.type === "COA" && !hideTabs) {
                                          history.push({
                                            pathname: `/settings/coa/${x?.id}`,
                                            state: { year: singleItem, month: singleMonth },
                                          });
                                        }
                                      }}
                                      key={singleIndex + indexMonth}
                                    >
                                      <span style={
                                        {
                                          borderBottom: hideTabs ? 'unset' : '1px solid #000',
                                          height: "80%"
                                        }
                                      }>
                                        {x.byMonth[singleItem][singleMonth]}
                                      </span>
                                    </div>
                                })
                            })
                            :
                            Object.keys(x?.amount).map((singleItem, singleIndex) => {
                              return <div
                                className={styles.col}
                                style={{
                                  flex: `0 0 ${100 / (profitLossReport?.years?.length + 1)}%`,
                                  borderRight: (singleIndex + 1) === profitLossReport?.years?.length ? "unset" : "1px solid #000",
                                  textAlign: 'center',
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  if (x?.type === "COA") {
                                    history.push({
                                      pathname: `/settings/coa/${x?.id}`,
                                      state: { year: singleItem },
                                    });
                                  }
                                }}
                                key={singleIndex}
                              >
                                <span style={
                                  {
                                    borderBottom: '1px solid #000',
                                    height: "80%"
                                  }
                                }>
                                  {x?.amount[singleItem]}
                                </span>
                              </div>
                            })}
                        </div>
                      ))}

                      <div
                        className={cn(
                          styles.table,
                          { [styles.total]: true }
                        )}

                      >
                        <div
                          className={styles.col}
                          style={{
                            flex: `0 0 ${100 / (profitLossReport?.years?.length + 1)}%`,
                            justifyContent: 'unset'
                          }}
                        >{`Total ${item?.title}`}
                        </div>
                        {showMonths ?
                          Object.keys(item?.byMonth).map((singleItem, singleIndex) => {
                            return singleItem === activeYear?.toString() &&
                              Object.keys(item?.byMonth[singleItem])?.map((singleMonth, singleMonthIndex) => {
                                return (currentQuarter ?
                                  (singleMonth > (currentQuarter - 1) * 3 && singleMonth <= currentQuarter * 3) : true) &&
                                  <div
                                    className={styles.colX12}
                                    style={{
                                      flex: currentQuarter ? `0 0 ${100 / 4.5}%` : `0 0 ${100 / 9.02}%`,
                                      borderRight: (currentQuarter && (singleMonth === (currentQuarter * 3)?.toString()) || singleMonthIndex === 11) ? "1px solid #000" : "unset",
                                      borderTop: singleMonthIndex < 6 ? 'unset' : '1px solid #000',
                                      borderLeft: singleMonthIndex === 0 ? 'unset' :
                                        currentQuarter ? (currentQuarter - 1) * 3 === singleMonthIndex ? 'unset' : '1px solid #000'
                                          : '1px solid #000',
                                      textAlign: 'center',
                                      backgroundColor: '#EFEFEF',
                                      marginTop: singleMonthIndex > 5 ? -1 : 0,
                                    }}
                                    key={singleIndex + singleMonthIndex}
                                  >
                                    {item?.byMonth[singleItem][singleMonth]}
                                  </div>
                              })
                          })
                          :
                          Object.keys(item?.total).map((singleItem, singleIndex) => {
                            return <div
                              className={styles.col}
                              style={{
                                flex: `0 0 ${100 / (profitLossReport?.years?.length + 1)}%`,
                                borderRight: "1px solid #000",
                                textAlign: 'center'
                              }}
                              key={singleIndex}
                            >
                              {item?.total[singleItem]}
                            </div>
                          })}
                      </div>
                    </div>
                })}
              </div>
          }
        </div>
      </div>
    </div >
  );
};

export default ProfitAndLoss;
