import { useEffect, useState } from "react";
import Table from "./Table";
import cn from "classnames";
import styles from "./Lender.module.sass";
import Spinner from "../../../../utils/spinner";
import Styles from "../Details/Details.module.sass";
import TextInput from "../../../../components/TextInput";
import Dropdown from "../../../../components/Dropdown";
import { ToastContainer, toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import {
  createChatChannel,
  updateLoan,
} from "../../../../utils/apiCallHanlder";
import { useHistory } from "react-router-dom";
import Card from "../../../../components/Card";

const options = [...Array(30).keys()].map((i) =>
  i === 0 ? `${i + 1} Year` : `${i + 1} Years`
);
let typingTimer;
let doneTypingInterval = 600;
let doneApiInterval = 600;

const Lenders = ({
  checkCount,
  setCheckCount,
  deleteIdArray,
  setDeleteIdArray,
  lendersLoading,
  propertyLenders,
  id,
  property,
  getLendersData,
  matchedLendersCount,
  setShowAllLenders,
  showAllLenders,
  setProperty,
  setSelectedTab,
  setShowLenders,
  setChannelToCreate,
  backBtn,
  largeNav,
  propertyName,
}) => {
  const history = useHistory();

  const [purchaseLoan, setPurchaceLoan] = useState("");
  const [additionalLoan, setAdditionalLoan] = useState("");
  const [ltv, setLtv] = useState("");
  const [cashDownpayment, setCashDownPayment] = useState("");
  const [amortization, setAmortization] = useState(options[0]);
  const [purchasePrice, setPurchasePrice] = useState("");
  const [loanToCost, setLoanToCost] = useState("");

  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    setShowAllLenders(false);
    getLendersData(false);
  }, []);

  useEffect(() => {
    if (property) {
      setPurchaceLoan(property?.loanPurchaseRequested);
      setAdditionalLoan(property?.loanAdditionalRequested);
      setLtv(property?.loanLtvPercentage);
      setCashDownPayment(property?.loanCashDownpayment);
      setAmortization(
        property?.loanAmortization
          ? `${property?.loanAmortization} years`
          : options[0]
      );
      setPurchasePrice(property?.purchasePrice || "");
    }
  }, [property]);

  useEffect(() => {
    setCashDownPayment(
      parseInt(purchasePrice || 0) - parseInt(purchaseLoan || 0)
    );
  }, [purchasePrice, purchaseLoan]);

  useEffect(() => {
    const intPrice = parseInt(purchasePrice || "0");
    const intCashDownpayment = parseInt(cashDownpayment || "0");

    const ltvCalculated =
      ((intPrice - intCashDownpayment) / (intPrice || 1)) * 100;
    setLtv(ltvCalculated);
  }, [cashDownpayment, purchasePrice]);

  useEffect(() => {
    setLoanToCost(
      ((parseInt(purchaseLoan || "0") + parseInt(additionalLoan || "0")) /
        parseInt(purchasePrice || 1)) *
        100
    );
  }, [purchasePrice, purchaseLoan, additionalLoan]);

  const saveClickHandler = async (value) => {
    if (saveLoading) return;

    if (
      (parseInt(purchaseLoan || 0) > 0 || parseInt(additionalLoan || 0) > 0) &&
      parseInt(purchasePrice || 0) < 1
    ) {
      toast.error(
        "Cannot set purchance loan or additional Loan values when purchase price is empty.",
        toastConfiguration
      );
      return;
    }

    doneApiInterval = 600;
    setSaveLoading(true);
    const payload = {
      propertyId: property?.propertyId,
      LoanAmortization: parseInt((value || amortization)?.split(" ")[0]),
      LoanPurchaseRequested: purchaseLoan,
      LoanAdditionalRequested: additionalLoan,
      LoanLtvPercentage: ltv,
      LoanCashDownpayment: cashDownpayment,
      purchasePrice: parseInt(purchasePrice || "0"),
    };

    const { data } = await updateLoan(payload);

    if (data) {
      //toast.success("Successfully updated loan data", toastConfiguration);
      setProperty(data?.property);
      setTimeout(getLendersData(false), doneApiInterval);
    } else {
      toast.error("Error in updating loan values", toastConfiguration);
    }

    setSaveLoading(false);
  };

  const loanApplicationSendHandler = () => {
    history.push({
      pathname: "/projects-download",
      state: {
        propertyId: id,
        matchedLendersCount: matchedLendersCount,
      },
    });
  };

  const chatClickHanlder = async (item) => {
    setChannelToCreate(item?.lenderId);
    setShowLenders(false);
    setSelectedTab("Messages");
  };

  return (
    <>
      <ToastContainer />
      <Card
        title="Property"
        head={
          <div className={styles.btnWrap}>
            {backBtn}
            {lendersLoading ? (
              <div className={styles.spinner}>
                <Spinner size="48" color="gray" />
              </div>
            ) : (
              <>
                {matchedLendersCount > 0 && (
                  <button
                    className="button"
                    onClick={loanApplicationSendHandler}
                  >
                    Send application to all matched lenders
                  </button>
                )}
              </>
            )}
          </div>
        }
        border
      >
      {largeNav}
        <div className={styles.lenderWrapper}>
          {/* <div className={Styles.loanInputContainer}>
                    <div className={Styles.firstRow}>
                        <TextInput
                            label="Purchase Price"
                            name="offerPrice"
                            type="text"
                            className={Styles.input}
                            onChange={(e) => setPurchasePrice(e.target.value)}
                            value={purchasePrice}
                            required
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    saveClickHandler();
                                } else {
                                    clearTimeout(typingTimer);
                                    typingTimer = setTimeout(saveClickHandler, doneTypingInterval);
                                }
                            }}
                            onKeyDown={() => {
                                clearTimeout(typingTimer);
                            }}
                        />
                        <div className={Styles.input}></div>
                        <div className={Styles.input}></div>
                    </div>
                    <div className={cn(Styles.firstRow, Styles.margin)}>
                        <TextInput
                            label="Purchase loan"
                            name="purchaseLoan"
                            type="text"
                            className={Styles.input}
                            onChange={(e) => setPurchaceLoan(e.target.value)}
                            value={purchaseLoan}
                            required
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    saveClickHandler();
                                } else {
                                    clearTimeout(typingTimer);
                                    typingTimer = setTimeout(saveClickHandler, doneTypingInterval);
                                }
                            }}
                            onKeyDown={() => {
                                clearTimeout(typingTimer);
                            }}
                        />
                        <TextInput
                            label="Refurb loan"
                            name="additionalLoan"
                            type="text"
                            className={Styles.input}
                            onChange={(e) => setAdditionalLoan(e.target.value)}
                            value={additionalLoan}
                            required
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    saveClickHandler();
                                } else {
                                    clearTimeout(typingTimer);
                                    typingTimer = setTimeout(saveClickHandler, doneTypingInterval);
                                }
                            }}
                            onKeyDown={() => {
                                clearTimeout(typingTimer);
                            }}
                        />
                        <TextInput
                            label="Cash downpayment"
                            name="cashDownpayment"
                            type="text"
                            className={cn(Styles.disable, Styles.input)}
                            onChange={(e) => setCashDownPayment(e.target.value)}
                            value={cashDownpayment}
                            required
                        />

                    </div>
                    <div className={cn(Styles.firstRow, Styles.margin)}>
                        <TextInput
                            label="Loan to Value %"
                            name="ltv"
                            type="text"
                            className={cn(Styles.disable, Styles.input)}
                            onChange={(e) => setLtv(e.target.value)}
                            value={parseInt(ltv || 0)?.toFixed(2)}
                            required
                        />
                        <TextInput
                            label="Loan to Cost %"
                            name="cost"
                            type="text"
                            className={cn(Styles.disable, Styles.input)}
                            onChange={(e) => setLoanToCost(e.target.value)}
                            value={parseInt(loanToCost || 0)?.toFixed(2)}
                            required
                        />
                        <div className={Styles.input}>
                            <Dropdown
                                label="Amortization"
                                className={Styles.dropdown}
                                classDropdownHead={Styles.dropdownHead}
                                value={amortization}
                                setValue={setAmortization}
                                options={options || []}
                                bodyStyles={{ width: '100%' }}
                                onChange={(value) => {
                                    setTimeout(saveClickHandler(value), doneTypingInterval);
                                }}
                            />
                        </div>
                    </div>
                </div> */}

          {lendersLoading ? (
            <div className={styles.spinner}>
              <Spinner size="48" color="gray" />
            </div>
          ) : (
            <>
              <span className={styles.header}>
                <span className={styles.count}>{matchedLendersCount || 0}</span>{" "}
                Lenders Matched
              </span>
              {/* <div className={styles.subTitle}>
                            This is a soft  application. Your personal or business Credit Score won’t be affected. You should get the first loan offer within 24hrs. You might need to submit additional documentation, before getting approved.
                        </div>
                        <button className='button-stroke-red' style={{ width: 'max-content', marginTop: 12 }} onClick={async () => {
                            getLendersData(!showAllLenders);
                            setShowAllLenders(!showAllLenders);
                        }}>
                            {showAllLenders ? 'Show matched lenders' : "Show all lenders"}
                        </button> */}
              <div className={cn(styles.row, { [styles.flex]: false })}>
                <Table
                  checkCount={checkCount}
                  setCheckCount={setCheckCount}
                  setDeleteIdArray={setDeleteIdArray}
                  deleteIdArray={deleteIdArray}
                  lendersData={propertyLenders}
                  hasMore={false}
                  className={styles.table}
                  id={id}
                  chatClickHanlder={chatClickHanlder}
                />
              </div>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default Lenders;
