import React from 'react';
import styles from './Dropdown.module.sass';
import cn from 'classnames';
import Icon from '../Icon';
import { useState } from 'react';

const SingleItem = ({
    item,
    searchArray,
    value,
    handleClick,
    searchString
}) => {
    const [isSelected, setIsSelected] = useState(searchString ? true : false);

    return (
        <div style={{ marginTop: 16 }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: "4px 16px",
                cursor: 'pointer'
            }} onClick={() => setIsSelected(!isSelected)}>
                <label style={{
                    width: "max-content",
                    fontSize: 16,
                    cursor:'pointer'
                }}>{item}</label>
                <div style={{
                    cursor: 'pointer',
                    transform: isSelected && 'rotate(180deg)'
                }}
                >
                    <Icon name="arrow-down" size="24" />
                </div>
            </div>
            {isSelected && (
                <div>
                    {searchArray[item]?.map((x, index) => (
                        <div
                            className={cn(styles.option, {
                                [styles.selectioned]: x.value === value,
                            })}
                            onClick={() => handleClick(x.value, index, x)}
                            key={index}
                        >
                            {x.text}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default SingleItem;