import React, { useState } from "react";
import cn from "classnames";
import styles from "./addNew.module.sass";
import Icon from "../../../components/Icon";
import Dropdown from "../../../components/Dropdown_c";
import TextInput from "../../../components/TextInput";
import Spinner from "../../../utils/spinner";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router";
import { toastConfiguration } from "../../../utils/utils";
import Item from "../../Settings/Item";
import {
  addTaxRateValues,
  updateTaxRateValues,
} from "../../../utils/apiCallHanlder";
import { useEffect } from "react";
import Card from "../../../components/Card";

let haveDropdownsData = false;
let roles = [""];
const taxTypes = [
  {
    text: "GST",
    displayName: "GST",
    value: "gst",
  },
  {
    text: "HST",
    displayName: "HST",
    value: "hst",
  },
  {
    text: "JCT",
    displayName: "JCT",
    value: "jct",
  },
  {
    text: "PST",
    displayName: "PST",
    value: "pst",
  },
  {
    text: "QST",
    displayName: "QST",
    value: "qst",
  },
  {
    text: "RST",
    displayName: "RST",
    value: "rst",
  },
  {
    text: "Sales Tax",
    displayName: "Sales Tax",
    value: "sales_tax",
  },
  {
    text: "Vat",
    displayName: "Vat",
    value: "vat",
  },
];

const rateTypes = [
  {
    text: "Inclusive",
    displayName: "Inclusive",
    value: "inclusive",
  },
  {
    text: "Exlusive",
    displayName: "Exlusive",
    value: "exclusive",
  },
];

const AddEditModal = ({
  className,
  visible,
  setVisible,
  getTaxRateData,
  id,
  setId,
  setLoadingAfterAddUpdate,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [taxType, setTaxType] = useState(taxTypes[0]?.value);
  const [rateType, setRateType] = useState(rateTypes[0]?.value);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [rate, setRate] = useState("");

  useEffect(() => {
    if (id) {
      setTaxType(id.taxType);
      setRateType(id.inclusive ? "inclusive" : "exclusive");
      setName(id.name);
      setDescription(id.description);
      setRate(id.percentage);
    }
  }, [id]);

  const addTaxRateHandler = async () => {
    if (!name) {
      toast.error("Please enter name of tax rate", toastConfiguration);
      return;
    } else if (!rate) {
      toast.error("Rate field is empty", toastConfiguration);
      return;
    } else if (!description) {
      toast.error("Description field is empty", toastConfiguration);
      return;
    }
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    if (id) {
      const payload = {
        id: id.id,
        name: name,
      };

      const { data } = await updateTaxRateValues(payload);
      if (data) {
        toast.success("Successfully updated tax rate", toastConfiguration);
        setVisible(false);
        setLoadingAfterAddUpdate(true);
        await getTaxRateData(true);
        setLoadingAfterAddUpdate(false);
        resetStates();
      } else {
        toast.error("Error in updating tax Rate", toastConfiguration);
      }
    } else {
      const payload = {
        name: name,
        description: description,
        //inclusive: rateType === "inclusive" ? true : false,
        inclusive: false,
        percentage: rate || 0,
        taxType: taxType,
      };

      const { data } = await addTaxRateValues(payload);
      if (data) {
        toast.success("Successfully added tax rate", toastConfiguration);
        setVisible(false);
        setLoadingAfterAddUpdate(true);
        await getTaxRateData(true);
        setLoadingAfterAddUpdate(false);
        resetStates();
      } else {
        toast.error("Error in adding tax Rate", toastConfiguration);
      }
    }
    setIsLoading(false);
  };

  const resetStates = () => {
    setDescription("");
    setTaxType(taxTypes[0]?.value);
    setRateType(rateTypes[0]?.value);
    setName("");
    setRate("");
    setId("");
  };

  return (
    <>
      <div className={styles.title}>
        <div
          className={styles.backBtn}
          onClick={() => {
            setVisible(false);
            resetStates();
          }}
        >
          <Icon name='arrow-left' size='14' /> Back to Tax Rates
        </div>
      </div>
      <ToastContainer />
      <Card
        className={cn(styles.card, className)}
        border
        title={`${
          id ? (id.active ? "Update Tax Rate" : "Details") : "Add Tax Rate"
        }`}
      >
        <div className={styles.fieldset}>
          <div
            className={styles.field}
            style={{
              pointerEvents: id.active ? "auto" : !id ? "auto" : "unset",
            }}
          >
            <TextInput
              label='Display Name'
              name='name'
              type='text'
              value={name}
              onChange={(event) => {
                if (id?.id === undefined || id?.active === true) {
                  setName(event.target.value);
                }
              }}
              required
            />
          </div>
          <div className={styles.row}>
            <div className={styles.field}>
              <Dropdown
                label={"Type"}
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={taxType}
                setValue={setTaxType}
                options={taxTypes}
                id={id.active}
              />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className={styles.field} style={{ width: "40%" }}>
              <TextInput
                label='Rate'
                percentage='percentage'
                name='rate'
                type='rate'
                value={rate}
                onChange={(event) => {
                  if (
                    !event?.target?.value ||
                    parseFloat(event?.target?.value) < 101
                  ) {
                    if (id?.id === undefined) {
                      setRate(event.target.value);
                    }
                  }
                }}
                required
              />
            </div>
            {/* <div className={styles.field} style={{ marginLeft: 16 }}>
              <Dropdown
                label={"hideLabel"}
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={rateType}
                setValue={setRateType}
                options={rateTypes}
              />
            </div> */}
          </div>
          <div
            className={styles.field}
            style={{ position: "relative", marginTop: 8 }}
          >
            <TextInput
              label='Description'
              name='description'
              type='description'
              value={description}
              onChange={(event) => {
                if (id?.id === undefined) {
                  setDescription(event.target.value);
                }
              }}
              required
            />
          </div>
          {id && !id.active ? null : (
            <div className={styles.actionBtn}>
              <button
                onClick={addTaxRateHandler}
                className={cn("button", styles.button)}
                style={{
                  pointerEvents: id.active ? "auto" : !id ? "auto" : "unset",
                }}
              >
                {isLoading ? (
                  <Spinner loading={isLoading} size={15} />
                ) : (
                  <>
                    <Icon name='arrow-right' size='24' />
                    <span>{`${id ? "Update Tax Rate" : "Add Tax Rate"}`}</span>
                  </>
                )}
              </button>
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default AddEditModal;
