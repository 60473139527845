import React, { useState } from "react";
import styles from "./Income.module.sass";
import ModalImage from "react-modal-image";
import Icon from "../../../../components/Icon";
import TextInput from "../../../../components/TextInput";
import DateRangeInput from "../../../../components/DateRangeInput";
import { getCurrencyFromLocalStorage } from "../../../../utils/utils";
import DateInput from "../../../../components/DateInput";
import Search from "../../../Accounting/Reconcile/SearchAccounts";
import DropdownCategory from "../../../../components/DropdownCategory";
import Dropdown from "../../../../components/TaxRateDropdown";
import DropdownCOA from "../../../../components/Dropdown";
import CashAccountDropdown from "../../../../components/Dropdown_c";
import COATYPEDropDown from "../../../../components/COATYPE";

const Income = ({
  setStartDate,
  startDate,
  setCOAId,
  categoryName,
  setCategoryName,
  categories,
  setSelectedTaxRate,
  selectedTaxRate,
  taxRates,
  taxRateLoading,
  setDescription,
  cashAccounts,
  cashAccountLoading,
  setSelectedCashAccount,
  selectedCashAccount,
  setAmount,
  coaId,
  options,
  coaOptions,
  setSubTypes,
  subTypeName,
  setSubTypeName,
  subTypes
}) => {
  let currency = getCurrencyFromLocalStorage();

  return (
    <>
      <div className={styles.field}>
        <div className={styles.fieldLabel}>Cash Account</div>
        <CashAccountDropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={selectedCashAccount}
          setValue={setSelectedCashAccount}
          options={cashAccounts || []}
          backgroundColor='#F4F4F4'
          loading={cashAccountLoading}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 16,
        }}
      >
        <div className={styles.field}>
          <TextInput
            label='Amount'
            currency={currency}
            name='amount'
            type='text'
            required
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className={styles.field}>
          <DateInput
            classInputValue={styles.inputValue}
            label='Date'
            classInput={styles.dateRangeInput}
            icon='calendar'
            className={styles.calendar}
            setComponentStartDate={setStartDate}
            componentStartDate={startDate}
          />
        </div>
      </div>
      <div className={styles.field}>
        <DropdownCOA
          label="Type"
          className={styles.dropdownCOA}
          classDropdownHead={styles.dropdownHead}
          value={coaId}
          setValue={setCOAId}
          options={coaOptions}
          handleChange={(value) => {
            const filteredSubTypes = options?.filter(item => item?.name === value);
            if (filteredSubTypes?.length > 0) {
              setSubTypes(filteredSubTypes);
              setSubTypeName(filteredSubTypes[0]?.children[0]?.id)
            }
          }}
          bodyStyles={{ width: '100%' }}
        />

        <COATYPEDropDown
          label="Name"
          className={styles.dropdownCOA}
          classDropdownHead={styles.dropdownHead}
          value={subTypeName}
          setValue={setSubTypeName}
          options={subTypes}
          hideHeader

        />
      </div>
      <DropdownCategory
        label={"Category"}
        className={styles.dropdown}
        classDropdownHead={styles.dropdownHead}
        value={categoryName}
        showSearch={"true"}
        setValue={setCategoryName}
        options={categories}
        taxcodes={categories}
      />
      <div className={styles.field}>
        <div className={styles.fieldLabel}>Tax Rate</div>
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={selectedTaxRate}
          setValue={setSelectedTaxRate}
          options={taxRates || []}
          backgroundColor='#F4F4F4'
          loading={taxRateLoading}
        />
      </div>
      <div className={styles.field}>
        <TextInput
          label='Description'
          name='marketing service'
          type='text'
          required
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
    </>
  );
};

export default Income;
