import React, { useState } from "react";
import cn from "classnames";
import styles from "./DateRangeInput.module.sass";
import DatePicker from "react-datepicker";
import { CalendarContainer } from "react-datepicker";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

import "react-datepicker/dist/react-datepicker.css";
import Card from "../Card";
import { useEffect } from "react";

let isFirstSelected = false;
let updateState = false;

const DateRangeInput = ({
  className,
  classLabel,
  isWithPortal,
  label,
  req,
  icon,
  copy,
  allowFutureDates,
  currency,
  tooltip,
  place,
  classInputValue,
  classInput,
  dateInputId,
  startDate,
  endDate,
  setStartDate,
  getUpdatedDashBoardData,
  setEndDate,
  classNameToPick,
  setLoading,
  hideWidth,
  singleDate,
  isEndDate,
  zIndex,
  showMonthYearOnly,
  addPoperClass,
  setActiveDateTab
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isStartDateClicked, setIsStartDateClicked] = useState(false);

  const [oldStartDate, setOldStartDate] = useState(startDate);
  const [oldEndDate, setOldEndDate] = useState(endDate);


  const onChange = async (dates) => {
    // const [start, end] = dates;
    // setStartDate(start);
    // setEndDate(end);
    // if (end) {
    //   setIsOpen(false)
    //   setLoading && setLoading(true);
    //   getUpdatedDashBoardData && await getUpdatedDashBoardData(start, end, null, "");
    //   setLoading && setLoading(false)
    // }

    //Habib: Reverted, we need to make it as in ticket
    const [start, end] = dates;
    if (!end && !isFirstSelected) {
      isFirstSelected = true;
      setIsOpen(false);
      setEndDate(new Date());
      setStartDate(start);
      if (!isStartDateClicked) {
        setTimeout(() => {
          setIsOpen(true);
        }, 2)
      } else {
        updateState = true;
        setIsStartDateClicked(false);
      }

    } else {
      if (Date.parse(startDate) < Date.parse(start)) {
        setStartDate(startDate);
        setEndDate(start);
        isFirstSelected = false;

        setIsOpen(false)
        setLoading && setLoading(true);
        setActiveDateTab && setActiveDateTab("");
        getUpdatedDashBoardData && await getUpdatedDashBoardData(startDate, start, null, "");
        setLoading && setLoading(false)
      } else {
        isFirstSelected = true;
        setEndDate(new Date());
        setStartDate(start);
      }
    }
  };

  const onChangeSingle = async (date) => {
    isEndDate ? setEndDate(date) :
      setStartDate(date);
    setIsOpen(false)
  }

  useEffect(() => {
    if (updateState) {
      setIsOpen(true);
      updateState = false;
    }
  }, [isStartDateClicked])

  return (
    <div
      className={cn(
        styles.field,
        { [styles.fieldIcon]: icon },
        { [styles.fieldCopy]: copy },
        { [styles.fieldCurrency]: currency },
        className
      )}
    >
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label} <b className={styles.required}>{req}</b>
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        <div style={{ display: 'flex', justifyContent: 'center', gap: 6, height: "100%" }}>
          <span className={cn(styles.inputValue)}
            onClick={() => {
              isFirstSelected = false;
              if (isStartDateClicked) {
                setIsOpen(true);
              } else {
                updateState = true;
                setIsStartDateClicked(true);
              }
            }}>
            <span className={isStartDateClicked && isOpen && styles.activeTab}>
              {String(startDate).slice(4, 10) +
                ", " +
                String(startDate).slice(11, 15)
              }
            </span>
            &nbsp;-
          </span>
          <span className={cn(styles.inputValueEnd)}
            style={(isStartDateClicked && isOpen) ? {
              left: 170
            } : {}}
            onClick={() => {
              isFirstSelected = true;
              if (!isStartDateClicked) {
                setIsOpen(true);
              } else {
                updateState = true;
                setIsStartDateClicked(false);
              }
            }}>
            <span className={!isStartDateClicked && isOpen && styles.activeTab}>
              {String(endDate).slice(4, 10) +
                ", " +
                String(endDate).slice(11, 15)}
            </span>
          </span>
        </div>
        {!singleDate ?
          <DatePicker
            selected={startDate}
            onChange={onChange}
            openToDate={isStartDateClicked ? startDate : endDate}
            onClickOutside={async () => {
              setIsOpen(false);
              isFirstSelected = false;
              //setLoading && setLoading(true);
              getUpdatedDashBoardData && await getUpdatedDashBoardData(startDate, endDate, null, "");
              //setLoading && setLoading(false)
            }}
            // onInputClick={(e) => {
            //   setIsOpen(!isOpen)
            // }}
            dateFormat={showMonthYearOnly ? "MMM yyyy" : "MMM d, yyyy"}
            showMonthYearPicker={showMonthYearOnly ? true : false}
            className={cn(styles.input, classInput, classNameToPick)}
            open={isOpen}
            // onFocus={() => {
            //   setIsOpen(true);
            // }}
            selectsEnd={() => setIsOpen(false)}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            /*  */
            /* onSelect={() => setIsOpen(false)} */
            /* onFocus={() => {
              setIsOpen(true);
            }} */
            startDate={startDate}
            popperClassName={addPoperClass ? styles.popperClass : ''}
            endDate={endDate}
            selectsRange={true}
            id={dateInputId}
            maxDate={allowFutureDates ? null : new Date()}
          >
            {!showMonthYearOnly && (
              <div className={styles.calendarClearBtn}>
                <button>
                  <span
                    onClick={() => {
                      setStartDate(oldStartDate);
                      setEndDate(oldEndDate);
                    }}
                  >
                    Clear
                  </span>
                </button>
              </div>
            )}
          </DatePicker>
          :
          <div>
            {/* <div style={{
              position: 'absolute',
              zIndex: 10,
              top: 12,
              left: 210,
              fontSize: "15px",
              fontWeight: "600",
              lineHeight: "1.6",
              color: "#6F767E"
            }}>
              Forever
            </div>
            <img
              src="/images/arrow_forward.png"
              alt="arrow"
              style={{
                position: 'absolute',
                zIndex: 10,
                top: 15,
                left: 160
              }}
            /> */}
            <DatePicker
              selected={isEndDate ? endDate : startDate}
              onChange={onChangeSingle}
              onClickOutside={() => setIsOpen(false)}
              onInputClick={() => setIsOpen(!isOpen)}
              dateFormat="MMM d, yyyy"
              className={cn(styles.input, classInput, classNameToPick)}
              calendarClassName={zIndex && styles.calendarZIndex}
              open={isOpen}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              value={isEndDate ? endDate : startDate}
              id={dateInputId}
              maxDate={allowFutureDates ? null : new Date()}
            >
              <div className={styles.calendarClearBtn}>
                <button>
                  <span
                    onClick={() => {
                      setStartDate(null);
                      setEndDate(null)
                    }}
                  >
                    Clear
                  </span>
                </button>
              </div>
            </DatePicker>
          </div>
        }
        {icon && (
          <div className={styles.icon} style={hideWidth && { width: 'unset' }}>
            <Icon name={icon} size="24" />{" "}
          </div>
        )}
        {copy && (
          <button className={styles.copy} >
            <Icon name="copy" size="24" />{" "}
          </button>
        )}
        {currency && <div className={styles.currency}>{currency}</div>}
      </div>
    </div >
  );
};

export default DateRangeInput;
