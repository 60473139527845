
import React, { useEffect, useState } from "react";
import styles from "./AccountsAndCards.module.sass";
import cn from "classnames";
import BankAccount from "./BankAccount";
import MyCards from "./MyCards";
import LinkedAccounts from "./LinkedAccounts";
import { getCardDetails, getIndividualCardDetail, GetLinkAccountData } from '../../utils/apiCallHanlder';
import { useLocation } from "react-router-dom";
import { Router, useParams } from "react-router";
import MoneyFlowExplainer from '../../components/MoneyFlowExplainer';
import Icon from "../../components/Icon";
import Spinner from "../../utils/spinner";
import CardDetails from "./MyCards/CardDetails";
import NewCard from "./MyCards/NewCard";
import NewLinkedAccounts from "./LinkedAccounts/NewLinkedAccounts";

let haveDropdownsData = false;
let cardCategories = [];

const AccountsAndCards = (props) => {
  if (!haveDropdownsData) {
    const lookupData = JSON.parse(localStorage.getItem("lookupData"));
    let data = null;
    if (lookupData) {
      data = lookupData?.data;

      cardCategories = data?.CardCategories
      haveDropdownsData = true;
    }
  }

  const isPhysicalCardRequest = props.location?.state?.isPhysical;

  const [addCard, setAddCard] = useState(false);
  const [addLinkedAccounts, setAddLinkedAccounts] = useState(false);
  const [loading, setLoading] = useState(true);
  const [individualCardDetail, setIndividualCardDetail] = useState({});

  const [isVirtual, setIsVirtual] = useState(isPhysicalCardRequest ? false : true);
  const [isPhysical, setIsPhysical] = useState(isPhysicalCardRequest ? true : false);

  const stateAddCard = useLocation()?.state?.setAddCard;
  const { id, linkedAccountId } = useParams();
  const [linkAccount, setLinkAccount] = useState({});
  const [count, setCount] = useState(0);

  const [visible, setVisible] = useState(false);


  useEffect(() => {
    setAddCard(stateAddCard)
  }, [stateAddCard])


  useEffect(async () => {
    if (count === 0) {
      setCount(count + 1);
    } else {
      resetState();
      setLoading(true);
      if (id && id !== "createNew") {
        const { data } = await getIndividualCardDetail({ id });
        if (data) {
          setIndividualCardDetail(data?.card);
        }
      } else if (linkedAccountId) {
        const { data } = await GetLinkAccountData({ id: linkedAccountId });
        if (data) {
          setLinkAccount(data.account);
        }
      }
      setLoading(false);
    }
  }, [count, id, linkedAccountId])

  const resetState = () => {
    setAddCard(false);
    setAddLinkedAccounts(false);
    setIndividualCardDetail({});
    setLinkAccount({});
  }

  const modalCloseHandler = () => {
    setVisible(false);
  }

  return (
    <>
      {visible ?
        <MoneyFlowExplainer
          visible={visible}
          onClose={modalCloseHandler}
        />
        : null
      }
      {!id && !linkedAccountId ? (
        <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
          <div className={cn("h3", styles.pageTitle)}>Accounts &amp; Cards</div>
          <div onClick={() => setVisible(true)} style={{ marginBottom: '24px', marginLeft: 10 }} className={styles.info}>
            <Icon name="infoLg" size="34px" viewBox="0 0 34 34" fill="#9A9FA5" />
          </div>
        </div>
      ) : null}
      {loading ?
        <div className={styles.spinner}>
          <Spinner size="60" color={"gray"} />
        </div>
        :
        id === "createNew" ?
          <NewCard
            isVirtual={isVirtual}
            isPhysical={isPhysical}
            setIsVirtual={setIsVirtual}
            setIsPhysical={setIsPhysical}
          />
          :
          id ?
            <CardDetails
              id={id}
              cardDetail={individualCardDetail}
              setIndividualCardDetail={setIndividualCardDetail}
              cardCategories={cardCategories}
               />
            :
            linkedAccountId ?
              <NewLinkedAccounts
                linkAccount={linkAccount}
                count={count}
                setCount={setCount}
                linkedAccountId={linkedAccountId}
              />
              :
              <div className={styles.row}>
                {!addCard && !addLinkedAccounts ? (
                  <div className={styles.col}>
                    <BankAccount />
                  </div>
                ) : null}
                {(!addCard && !addLinkedAccounts) || (addCard && !addLinkedAccounts) ? <div className={styles.col}
                  style={addCard ? { width: '100%', flexBasis: 'unset' } : null}>
                  <MyCards setAddCard={setAddCard} />
                </div> : null}
                {(!addCard && !addLinkedAccounts) || (!addCard && addLinkedAccounts) ? (
                  <div className={styles.col}>
                    <LinkedAccounts setAddLinkedAccounts={setAddLinkedAccounts} />
                  </div>
                ) : null}
              </div>
      }
    </>
  );
};

export default AccountsAndCards;
