import React from 'react';
import styles from './nonDefaultCard.module.sass';

const NonDefaultCard = ({ cardDetail }) => {
    return (
        <div style={{
            width: 120,
            height: 70
        }}
            className={cardDetail?.status === "inactive" ? styles.blur : null}>
            <div className={styles.cardInfo}>
                <div className={styles.name}>{cardDetail?.name}</div>
                <div className={styles.number}>{"****" + cardDetail?.cardNumberLast4} </div>
            </div>
        </div>
    )
}

export default NonDefaultCard;