import { React, useState, useEffect } from "react";
import Image from "../../../../components/Image";
import { getContactDocuments } from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { dateFormatterWithTime } from "../../../../utils/utils";
import styles from "./Documents.module.sass";
import AlternativeAvatar from "../../../../components/AlternativeAvatar";

const documentsInfo = [
  {
    id: "DOC-989",
    created: "jul 22, 2022",
    type: "Expense",
  },
  {
    id: "DOC-989",
    created: "jul 22, 2022",
    type: "Income",
  },
];

const Documents = ({
  name,
  email,
  mobileCode,
  mobile,
  imageURL,
  customerId,
  updateDocumentObject,
  setCurrentlySelectedDocument,
  setShowNewDocModal
}) => {
  const [documents, setDocuments] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    await getDocumentList();
  }, [updateDocumentObject]);

  const getDocumentList = async () => {
    setLoading(true);
    const { data } = await getContactDocuments({
      counterPartyId: customerId,
      pageSize: 30,
    });
    if (data) {
      setDocuments(data);
    }
    setLoading(false);
  };

  return (
    <>
      <div className={styles.infoWrapper}>
        {imageURL ?
          <Image
            src={imageURL || "/images/content/avatar.jpg"}
            srcDark={imageURL || "/images/content/avatar.jpg"}
            className={styles.pic}
          />
          :
          <AlternativeAvatar name={name} className={'small-icon'} />
        }
        <div className={styles.infoWrap}>
          <div className={styles.info}>
            <p className={styles.label}>Name</p>
            <p className={styles.txt}>{name}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.label}>Email</p>
            <p className={styles.txt}>{email}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.label}>Mobile</p>
            <p className={styles.txt}>
              {mobileCode} {mobile}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.table}>
        <div className={styles.head}>
          <div className={styles.col}>Name</div>
          <div className={styles.col}>Created</div>
          <div className={styles.col}>Type</div>
        </div>
        {loading ? (
          <div className={styles.spinner}>
            <Spinner loading={loading} size={50} color={"gray"} />
          </div>
        ) : (
          documents?.documents.map((x, index) => (
            <div className={styles.row} key={index} onClick={() => {
              setCurrentlySelectedDocument(x);
              setShowNewDocModal(true);
            }}>
              <div className={styles.col}>
                <p className={styles.mblLabel}>Name</p>
                <span>{x.displayName}</span>
              </div>
              <div className={styles.col}>
                <p className={styles.mblLabel}>Created</p>
                <span>{dateFormatterWithTime(x.uploadedOn)}</span>
              </div>
              <div className={styles.col}>
                <p className={styles.mblLabel}>Type</p>
                <span>{x.documentType}</span>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Documents;
