import React, { useEffect, useState } from "react";
import cn from "classnames";
import Icon from "../../../../../../components/Icon";
import TextInput from "../../../../../../components/TextInput";
import DropdownC from "../../../../../../components/Dropdown_c";
import Dropdown from "../../../../../../components/Dropdown";
import styles from "./DetailsCustomer.module.sass";
import {
  removeLineFromInvoice,
  searchProductCoupons,
  updateLineOfInvoice,
} from "../../../../../../utils/apiCallHanlder";
import Spinner from "../../../../../../utils/spinner";
import { formatStripeAmount } from "../../../../../../utils/utils";
import Amount from "../../../../../../components/Amount";

const Coupons = ({
  setDiscount,
  discount,
  setdiscount,
  saveDiscountHandler,
}) => {
  const IsDiscountExists = (discountObj) => {
    return discountObj?.amountDiscountValue > 0;
  };

  const [loadingCoupons, setLoadingCoupons] = useState(false);

  const [showAddDiscountBtn, setShowAddDiscountBtn] = useState(
    !IsDiscountExists(discount)
  );
  const [showAddDiscount, setShowAddDiscount] = useState(false);
  const [displayValue, setDisplayValue] = useState("");
  const [couponsArray, setCouponsArray] = useState([]);
  const [coupons, setCoupons] = useState(discount?.name);

  useEffect(async () => {
    if (!couponsArray || couponsArray.length == 0) {
      setLoadingCoupons(true);
      const { data } = await searchProductCoupons("");
      if (data) {
        for (let index = 0; index < data.couponList.length; index++) {
          const element = data.couponList[index];
          element.name = element.name + " (" + getTerm(element) + ")";
        }
        setCouponsArray(data?.couponList);
      }
    }

    setShowAddDiscount(true);
    setShowAddDiscountBtn(false);

    let labelToShow = discount?.name + " (" + discount?.label + ")";

    if (labelToShow?.length > 30) {
      labelToShow = labelToShow?.substring(0, 30) + "...";
    }

    setDisplayValue(labelToShow);
    setLoadingCoupons(false);
  }, [discount]);

  const getTerm = (item) => {
    var term = "";
    if (item.amountOff) {
      term = formatStripeAmount(item.amountOff || 0, item.currency) + " off";
    } else if (item.percentOff) {
      term = item.percentOff + "% off";
    }
    if (item.duration == "once" || item.duration == "forever") {
      term = term + " " + item.duration;
    } else if (item.duration == "repeating") {
      term = term + " for " + item.durationInMonths;
      if (item.durationInMonths == 1) {
        term = term + " month";
      } else {
        term = term + " months";
      }
    }
    return term;
  };

  const removeCoupon = async (event) => {
    setLoadingCoupons(true);
    await saveDiscountHandler(null, null, true);

    setShowAddDiscount(false);
    setShowAddDiscountBtn(true);

    setLoadingCoupons(false);
  };

  const handleChangeHandler = async (value, x) => {
    setLoadingCoupons(true);
    await saveDiscountHandler(value, x);
    setShowAddDiscount(false);
    setShowAddDiscountBtn(true);
    setLoadingCoupons(false);
  };

  const hideDiscountSelectionHandler = () => {
    setLoadingCoupons(false);
    setShowAddDiscount(false);
    setShowAddDiscountBtn(true);
  };

  return (
    <div>
      <div className={styles.wrap}>
        <h4 className={styles.label}>Discount</h4>
        {loadingCoupons ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spinner size='24' color={"gray"} />
          </div>
        ) : (
          <div>
            <div className={styles.couponWrap}>
              <div className={styles.couponDropdown}>
                <DropdownC
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  setValue={setCoupons}
                  onChange={handleChangeHandler}
                  options={couponsArray}
                  value={coupons}
                  dontShowOptions={true}
                />

                <div className={styles.discountWrap}>
                  {discount ? (
                    // <span>{discount.label.split("off").join("")}</span>
                    <span>{displayValue}</span>
                  ) : (
                    <span>Select</span>
                  )}
                </div>
              </div>

              {/* <button onClick={hideDiscountSelectionHandler}>
                <Icon name="cross" size="16" />
              </button> */}
            </div>
            {/* {!showAddDiscount && !discount && (
              <>
                {!loadingCoupons && showAddDiscountBtn && (
                  <>
                    <div className={styles.detailsTxt}>
                      <button
                        onClick={showCouponDropdown}
                        className={styles.addProductBtn}
                      >
                        Add Discount
                      </button>
                    </div>
                  </>
                )}
              </>
            )} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Coupons;
