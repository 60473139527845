import React, { useEffect, useState } from "react";
import styles from "./Transactions.module.sass";
import cn from "classnames";
import Card from "../Card";
import Icon from "../Icon";
import Dropdown from "../TaxRateDropdown";
import { GetFileNameFromPath, toastConfiguration, toTitleCase } from "../../utils/utils";
import Image from "../Image";

// date
import { dateFormatter } from "../../utils/utils";
import Spinner from "../../utils/spinner";
import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import Search from "../../pages/Accounting/Reconcile/SearchAccounts";
import SearchCounterParty from "../../pages/Accounting/Reconcile/SearchContacts";
import TextInput from "../TextInput";
import {
  AddTransactionAttachment,
  bookTransaction,
  deleteTransactionAttachment,
  uploadFile,
} from "../../utils/apiCallHanlder";
import DateRangeInput from "../DateRangeInput";

const imageWRTExtension = {
  common: "/images/content/image.png",
  xlsx: "/images/content/xlsx.png",
  xls: "/images/content/xlsx.png",
  pdf: "/images/content/pdf.png",
};

const categories = [
  {
    text: "Select",
    value: "select",
    display: "select",
  },
  {
    text: "IT",
    value: "IT",
    display: "IT",
  },
  {
    text: "Software",
    value: "Software",
    display: "Software",
  },
  {
    text: "Saas",
    value: "Saas",
    display: "Saas",
  },
  {
    text: "Hotel",
    value: "Hotel",
    display: "Hotel",
  },
  {
    text: "Taxi",
    value: "Taxi",
    display: "Taxi",
  },
  {
    text: "Train",
    value: "Train",
    display: "Train",
  },
  {
    text: "Resturant",
    value: "Resturant",
    display: "Resturant",
  },
  {
    text: "Gift",
    value: "Gift",
    display: "Gift",
  },
  {
    text: "Other",
    value: "Other",
    display: "Other",
  }
]

const Transactions = ({
  className,
  payThroughTrans,
  transactions,
  isNextPageExist,
  getUpdatedTransactions,
  loading,
  isExpandable,
  setActiveIndex,
  activeIndex,
  taxRates,
  page,
  showDate,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  setLoading,
  isGLedger,
  hideHeader,
  coaOptions,
  coaTypes,
  subTypes,
  setSubTypes,
  comingFromReconcile = false,
}) => {
  const history = useHistory();
  const [updateState, setUpdateState] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedTaxRate, setSelectedTaxRate] = useState(null);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const [nextPageLoading, setNextPageLoading] = useState(false);

  const [attachmentLinks, setAttachmentLinks] = useState([]);
  const [counterPartyId, setCounterPartyId] = useState("");
  const [counterPartyName, setCounterPartyName] = useState("");
  const [coaId, setCOAId] = useState("");

  const [currentSortOrder, setCurrentSortOrder] = useState("desc");
  const [currentSortColumn, setCurrentSortColumn] = useState("Date");

  useEffect(() => {
    setSelectedTaxRate(taxRates?.length > 0 ? taxRates[0]?.companyTaxId : null);
  }, [taxRates]);

  const handleSingleTransaction = (x) => {
    history.push({
      pathname: `/transactionDetails/${x?.stripeTransactionId}`,
      state: {
        isFetchTransactionData: false,
        transactionPayload: x,
        transactionDetail: x.type,
        accountType: x.accountType,
        accountId: x.accountId,
        status: x.status,
        amount: x.amount,
        desc: x.description,
      },
    });
  };

  const imageUploadHandler = async (event) => {
    setImageUploadLoading(true);
    setSelectedImage(event.target.files[0]);
    const formData = new FormData();
    formData.append("", event.target.files[0]);
    const { data, error } = await uploadFile(formData);

    if (data) {
      setAttachmentLinks([...attachmentLinks, data?.file?.url]);
    }
    else {
      toast.error(error || "Error in uploading file", toastConfiguration);
    }
    setImageUploadLoading(false);
  };

  const deleteImageHandler = async (e, attachmentLink) => {
    setAttachmentLinks(
      attachmentLinks.filter(function (item) {
        return item !== attachmentLink;
      })
    );
  };

  const saveButtonClickHandler = async (transactionId, objPayload) => {

    setSaveLoading(true);
    const { data, error } = await bookTransaction(
      objPayload
        ? objPayload
        : {
          id: transactionId,
          CounterPartyId: counterPartyId,
          DoCreateCounterParty: isNew ? true : false,
          CounterPartyName: counterPartyName,
          coaId: coaId,
          description: description,
          companyTaxId: selectedTaxRate,
          attachments: attachmentLinks,
        }
    );
    if (data) {
      setDescription("");
      setAttachmentLinks([]);
      setIsNew(false);
      setSelectedTaxRate(taxRates?.length > 0 ? taxRates[0]?.companyTaxId : null);
      toast.success("Transaction Booked successfully", toastConfiguration);
      setSaveLoading(false);
      setLoading(true);
      await getUpdatedTransactions(null, false, []);
      setLoading(false);
    } else {
      toast.error("Error in booking transaction: " + error, toastConfiguration);
    }
    setSaveLoading(false);
  };

  const subTypesSelector = (value, x) => {
    const filteredSubTypes = coaOptions?.filter(item => item?.name === value);
    if (filteredSubTypes?.length > 0) {
      const subTypesSelect = document.getElementById(`coa-${x?.id}`);
      if (subTypesSelect) {
        while (subTypesSelect?.firstChild) {
          subTypesSelect.removeChild(subTypesSelect.firstChild);
        }

        filteredSubTypes.forEach(option => {
          option?.children?.map((item, index) => {
            const optionElement = document.createElement("option");
            optionElement.value = item.id;
            optionElement.textContent = item.name;
            optionElement.selected = (x?.bookCoaId === item.id)
            optionElement.key = index;
            subTypesSelect.appendChild(optionElement);
          })
        });
      }
    } else {
      const subTypesSelect = document.getElementById(`coa-${x?.id}`);
      while (subTypesSelect?.firstChild) {
        subTypesSelect.removeChild(subTypesSelect.firstChild);
      }
      const optionElement = document.createElement("option");
      optionElement.value = 'select';
      optionElement.textContent = 'Select';
      subTypesSelect?.appendChild(optionElement);
    }
  }

  return (
    <>
      <ToastContainer />
      <Card
        className={cn(styles.card, className)}
        classCardHead={styles.head}
        title={hideHeader ? "" : "Transactions"}
        /* classTitle={hideHeader ? null : cn("title-blue", styles.title)} */
        head={
          showDate ? (
            <div className={styles.buttonContainer}>
              <DateRangeInput
                classInputValue={styles.inputValue}
                classInput={styles.input}
                icon="calendar"
                className={styles.calendar}
                setStartDate={setStartDate}
                setLoading={setLoading}
                setEndDate={setEndDate}
                getUpdatedDashBoardData={getUpdatedTransactions}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          ) : null
        }
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 100,
            }}
          >
            <Spinner size="50px" color="gray" loading={loading} />
          </div>
        ) : transactions?.length < 1 ? (
          <div className={styles.noDataImg}>
            <Image
              src={"/images/icons/empty-black.png"}
              srcDark={"/images/icons/empty-white.png"}
            />
          </div>
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col} >
                  <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                    onClick={async () => {
                      setCurrentSortColumn("Date");
                      const currentSortValue = currentSortOrder === 'asc' ? 'desc' : 'asc';
                      setLoading(true);
                      await getUpdatedTransactions(null, false, [], "Date", currentSortValue);
                      setLoading(false);
                      setCurrentSortOrder(currentSortValue);
                    }}
                  >
                    <span>Date</span>
                    {currentSortColumn === "Date" && (
                      <div style={{
                        marginLeft: 4,
                        rotate: currentSortOrder === "asc" ? '180deg' : '0deg'
                      }}
                      >
                        <Icon name="sort_descending" size="20" fill="#C7C5BF" />
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.col} >
                  <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                    onClick={async () => {
                      setCurrentSortColumn("ToFrom");
                      const currentSortValue = currentSortOrder === 'asc' ? 'desc' : 'asc';
                      setLoading(true);
                      await getUpdatedTransactions(null, false, [], "ToFrom", currentSortValue);
                      setLoading(false);
                      setCurrentSortOrder(currentSortValue);
                    }}
                  >
                    <span>To/From</span>
                    {currentSortColumn === "ToFrom" && (
                      <div style={{
                        marginLeft: 4,
                        rotate: currentSortOrder === "asc" ? '180deg' : '0deg'
                      }}
                      >
                        <Icon name="sort_descending" size="20" fill="#C7C5BF" />
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.col} >
                  <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                    onClick={async () => {
                      setCurrentSortColumn("Amount");
                      const currentSortValue = currentSortOrder === 'asc' ? 'desc' : 'asc';
                      setLoading(true);
                      await getUpdatedTransactions(null, false, [], "Amount", currentSortValue);
                      setLoading(false);
                      setCurrentSortOrder(currentSortValue);
                    }}
                  >
                    <span>Amount</span>
                    {currentSortColumn === "Amount" && (
                      <div style={{
                        marginLeft: 4,
                        rotate: currentSortOrder === "asc" ? '180deg' : '0deg'
                      }}
                      >
                        <Icon name="sort_descending" size="20" fill="#C7C5BF" />
                      </div>
                    )}
                  </div>
                </div>

                {comingFromReconcile ? (
                  <>
                    <div className={styles.col} >
                      <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                        onClick={async () => {
                          setCurrentSortColumn("Category");
                          const currentSortValue = currentSortOrder === 'asc' ? 'desc' : 'asc';
                          setLoading(true);
                          await getUpdatedTransactions(null, false, [], "Category", currentSortValue);
                          setLoading(false);
                          setCurrentSortOrder(currentSortValue);
                        }}
                      >
                        <span>Category</span>
                        {currentSortColumn === "Category" && (
                          <div style={{
                            marginLeft: 4,
                            rotate: currentSortOrder === "asc" ? '180deg' : '0deg'
                          }}
                          >
                            <Icon name="sort_descending" size="20" fill="#C7C5BF" />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={styles.col} >
                      <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                        onClick={async () => {
                          setCurrentSortColumn("COA");
                          const currentSortValue = currentSortOrder === 'asc' ? 'desc' : 'asc';
                          setLoading(true);
                          await getUpdatedTransactions(null, false, [], "COA", currentSortValue);
                          setLoading(false);
                          setCurrentSortOrder(currentSortValue);
                        }}
                      >
                        <span>Chart of Account</span>
                        {currentSortColumn === "COA" && (
                          <div style={{
                            marginLeft: 4,
                            rotate: currentSortOrder === "asc" ? '180deg' : '0deg'
                          }}
                          >
                            <Icon name="sort_descending" size="20" fill="#C7C5BF" />
                          </div>
                        )}
                      </div>
                    </div>

                    {activeIndex !== 2 && <div className={styles.col}
                     style={{
                      width: 100,
                      wordWrap: "break-word",
                      display: "flex",
                      justifyContent: "flex-end"
                    }}
                    >Action</div>}
                  </>
                ) : (
                  <>
                    <div className={styles.col}>Account</div>
                    <div className={styles.col}>Type</div>
                    <div className={styles.col}></div>
                  </>
                )}
              </div>
              {transactions.map((x, index) => {
                const selectedCOA = coaOptions?.filter(item => item?.children?.filter(i => i?.id === x?.bookCoaId)?.length > 0);
                if (selectedCOA?.length > 0 && !confirmLoading) {
                  setTimeout(() => { subTypesSelector(selectedCOA[0]?.name, x); }, 200)
                }
                return <>
                  <div
                    onClick={() => {
                      activeIndex === 2 && payThroughTrans && payThroughTrans(x);
                      if (activeIndex !== 2 && x.accountingAction === "confirm") {
                        payThroughTrans && payThroughTrans(x);
                      }
                    }}
                    className={styles.row}
                    key={index}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      height: x.isExpandable ? 455 : "unset",
                      backgroundColor: x.isExpandable ? "#EFEFEF" : "",
                    }}
                  >
                    <div className={styles.col} style={{ minWidth: 96 }}>
                      <div className={styles.label}>Date</div>
                      {dateFormatter(x.date || x.transactionDate)}
                    </div>
                    <div className={styles.col}>
                      <div className={styles.label}>Name</div>
                      {x.description}
                    </div>
                    <div
                      className={styles.col}
                      style={
                        parseInt(x.amountF?.replace("$", "")) < 0
                          ? { color: "red", width: 150 }
                          : { width: 150 }
                      }
                    >
                      <div className={styles.label}>Credit</div>
                      {x.amountF}
                    </div>
                    {isGLedger ? null : (
                      <>
                        {comingFromReconcile ? (
                          <>
                            <div className={styles.col} onClick={(e) => e.stopPropagation()}>
                              <div className={styles.label}>category</div>
                              {activeIndex !== 2 ?
                                <select
                                  id={`category-${x?.id}`}
                                  className={styles.listDropdown}
                                  disabled={x?.accountingAction !== "confirm"}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    subTypesSelector(value, x);
                                  }}
                                >
                                  {coaTypes?.map((item, index) => {
                                    return <option
                                      value={item}
                                      key={index}
                                      selected={selectedCOA?.length > 0 ? item === selectedCOA[0]?.name : false}
                                    >
                                      {item}
                                    </option>
                                  })}
                                </select>
                                :
                                <span>{x.coa1Name || "N/A"}</span>
                              }
                            </div>
                            <div
                              className={styles.col}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div className={styles.label}>COA</div>
                              {activeIndex !== 2 ? (
                                <select
                                  id={`coa-${x?.id}`}
                                  className={styles.listDropdown}
                                  disabled={x?.accountingAction !== "confirm"}
                                >
                                  <option value='select'>Select</option>
                                </select>
                              )
                                :
                                <span>{x.bookCoaName || "N/A"}</span>
                              }
                            </div>
                            {activeIndex !== 2 && (
                              <div
                                className={styles.col}
                                style={{
                                  width: 100,
                                  wordWrap: "break-word",
                                  display: "flex",
                                  justifyContent: "flex-end"
                                }}
                                onClick={async (e) => {
                                  e.stopPropagation();
                                  if (x.accountingAction === "confirm") {
                                    const category = document.getElementById(`category-${x?.id}`)?.value;
                                    const coa = document.getElementById(`coa-${x?.id}`)?.value

                                    const selectedCategory = coaTypes?.find(item => item === category);
                                    const coaParent = coaOptions?.filter(item => item?.children?.filter(i => i.id == coa)?.length > 0);
                                    let selectedCOA = null;
                                    if (coaParent?.length > 0) {
                                      selectedCOA = coaParent[0]?.children?.find(item => item.id == coa);
                                    }
                                    if (!selectedCategory || selectedCategory?.value === 'select') {
                                      toast.error("Please select any category", toastConfiguration);
                                      return;
                                    }


                                    let _coaId = selectedCOA?.id;
                                    if (!_coaId || isNaN(_coaId)) {
                                      toast.error("Please select any chart of account", toastConfiguration);
                                      return;
                                    }

                                    setConfirmLoading(x?.id);
                                    const objPayload = {
                                      id: x.id,
                                      CounterPartyId: x.counterpartyId,
                                      DoCreateCounterParty: false,
                                      CounterPartyName: x.counterpartyName,
                                      coaId: _coaId,
                                      description: x.notes,
                                      companyTaxId: 0,
                                      attachments: [],
                                      isConfirm: true,
                                    };
                                    await saveButtonClickHandler(x.id, objPayload);
                                    setUpdateState(!updateState);
                                    setConfirmLoading(null);
                                  } else {
                                    payThroughTrans(x);
                                  }
                                }}
                              >
                                <div className={styles.label}>Action</div>
                                {x.accountingAction === "confirm" ? (
                                  <span
                                    style={{ textAlign: 'center', padding: "2px 12px", background: "#FF4900", color: '#FFF', fontWeight: 300, width: 60 }}
                                  >
                                    {(confirmLoading === x?.id) ? <Spinner size="12" color="white" /> : "Book"}
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      padding: "2px 8px",
                                      color: '#FF4900',
                                      border: '1px solid #FF4900',
                                      fontWeight: 300,
                                      background: "#FFF",
                                    }}
                                  >
                                    Review
                                  </span>
                                )}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <div className={styles.col}>
                              <div className={styles.label}>Account</div>
                              {x.coaName || x.accountName}
                            </div>
                            <div className={styles.col}>
                              <div className={styles.label}>Type</div>
                              <span>
                                {toTitleCase(x.type || x.accountType || x.transactionDetail || "")}
                              </span>
                            </div>
                            <div className={styles.col} style={{ width: 150 }}>
                              <div className={styles.label}>Doc</div>
                              <span
                                className={cn(styles.attachment, {
                                  [styles.none]: x.attachmentCount === "none",
                                })}
                              >
                                {x.attachmentCount > 0 && (
                                  <Icon size="24" name="attachment" viewBox="0 0 24 24" />
                                )}
                              </span>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {/* {activeIndex !== 2 && x.accountingAction === "confirm" && (
                      <div
                        className={styles.col}
                        onClick={() => {
                          payThroughTrans && payThroughTrans(x);
                        }}
                      >
                        <Icon size="24" name="edit" />
                      </div>
                    )} */}

                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 63,
                        width: "100%",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {x.isExpandable ? (
                        <div className={styles.expandableContainer}>
                          <div className={styles.col}>
                            <div>
                              <div className={styles.fieldLabel}>Who</div>
                              <SearchCounterParty
                                className={styles.search}
                                inputPlaceholder="Name of contact"
                                setIsNew={setIsNew}
                                setCounterPartyName={setCounterPartyName}
                                isNew={isNew}
                                showDetails={(x) => {
                                  setCounterPartyId(x?.id);
                                  setCounterPartyName(x?.name);
                                }}
                              />
                            </div>
                            <div style={{ marginTop: -20 }}>
                              <div className={styles.fieldLabel}>What</div>
                              <Search
                                className={styles.search}
                                inputPlaceholder="Choose the account"
                                showDetails={(x) => setCOAId(x?.id)}
                              />
                            </div>
                            <div style={{ marginTop: -20 }}>
                              <div className={styles.fieldLabel}>Why</div>
                              <TextInput
                                placeholder="Enter a description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              <div className={styles.fieldLabel}>Tax Rate</div>
                              <Dropdown
                                className={styles.dropdown}
                                classDropdownHead={styles.dropdownHead}
                                value={selectedTaxRate}
                                setValue={setSelectedTaxRate}
                                options={taxRates || []}
                                backgroundColor={"#F4F4F4"}
                              />
                            </div>
                          </div>

                          <div className={styles.inputWrap}>
                            {imageUploadLoading ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div style={{ width: "70%" }}>{selectedImage?.name}</div>
                                <div
                                  style={{
                                    display: "flex",
                                    marginLeft: 10,
                                  }}
                                >
                                  <Spinner size="24px" color="gray" loading={imageUploadLoading} />
                                </div>
                              </div>
                            ) : (
                              <div className={styles.imgInput}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {attachmentLinks?.map((x, index) => {
                                    const fileNameObj = GetFileNameFromPath(x, true);
                                    return (
                                      <div style={{ marginLeft: 10, marginTop: 10 }} key={index}>
                                        <div className={styles.selectedImageWrap}>
                                          <img
                                            className={styles.selectedImage}
                                            style={{ cursor: "pointer" }}
                                            src={
                                              fileNameObj.extension === "png" ||
                                                fileNameObj.extension === "jpg" ||
                                                fileNameObj.extension === "jpeg"
                                                ? x
                                                : imageWRTExtension[fileNameObj.extension] ||
                                                imageWRTExtension["common"]
                                            }
                                            //large={previewImage}
                                            hideZoom
                                          />
                                          <div className={styles.action}>
                                            {deleteLoading ? (
                                              <>
                                                <div className={styles.modal}>
                                                  <div
                                                    style={{
                                                      position: "fixed",
                                                      left: "50%",
                                                      top: "45%",
                                                    }}
                                                  >
                                                    <Spinner size="48" color="gray" />
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <button onClick={(e) => deleteImageHandler(e, x)}>
                                                <Icon name="close" size="16" />
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                  <div style={{ marginLeft: 10, marginTop: 10 }}>
                                    <label className={styles.inputLabel} htmlFor="fileInput">
                                      <Icon name="add" />
                                    </label>
                                    <input
                                      type="file"
                                      className={styles.input}
                                      name="myImage"
                                      id="fileInput"
                                      //accept="image/png, image/gif, image/jpeg"
                                      onChange={(event) => imageUploadHandler(event)}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <button
                            style={{
                              position: "absolute",
                              bottom: 50,
                              right: 50,
                            }}
                            className={cn("button", styles.addBtn)}
                            onClick={async () => {
                              await saveButtonClickHandler(x.id);
                              x.isExpandable = false;
                              setUpdateState(!updateState);
                            }}
                          >
                            {saveLoading ? <Spinner size="24" color="white" /> : <>Save</>}
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div >
                </>
              })}

            </div>
          </div>
        )}
        {(isNextPageExist && !loading) ? (
          <div className={styles.foot}>
            <button
              className={cn("button-stroke-red button-small", styles.button)}
              style={{ width: 250 }}
              onClick={async () => {
                if (!nextPageLoading) {
                  setNextPageLoading(true);
                  showDate
                    ? await getUpdatedTransactions(null, null, page, transactions)
                    : await getUpdatedTransactions(page, false, transactions, currentSortColumn, currentSortOrder);
                  setNextPageLoading(false);
                }
              }}
            >
              {nextPageLoading ? (
                <Spinner size="24" color="gray" />
              ) : (
                <>
                  <span>See More Transactions</span>
                  <Icon name="arrow-next" size="20"></Icon>
                </>
              )}
            </button>
          </div>
        ) : null}
      </Card >
    </>
  );
};

export default Transactions;
