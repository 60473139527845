import React, { useEffect, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";
import Search from '../../pages/Products/EditNewProduct/Details/SearchCode'
import Spinner from "../../utils/spinner";
import Icon from "../Icon";

const Dropdown = ({
    className,
    classDropdownHead,
    classDropdownLabel,
    value,
    setValue,
    onChange,
    options,
    showSearch,
    taxcodes,
    taxCodeName,
    setTaxCodeName,
    label,
    tooltip,
    invoiceDueLoading,
    loading,
    updateTaxLoading,
    small,
    autoFocus,
    upBody,
    backgroundColor,
    handleChange
}) => {
    const [visible, setVisible] = useState(false);
    const [searchArray, setSearchArray] = useState([]);
    const [searchString, setSearchString] = useState("");

    if (loading === undefined) {
        loading = false;
    }

    const handleClick = (value, index, x) => {
        setValue(value);
        setVisible(false);

        { handleChange && handleChange(value, x) }
        { onChange && onChange(value, x) }
    };

    const codeSearchHandler = (event) => {
        const valueToSeacrh = event.target.value;
        //setTaxCodeName(valueToSeacrh);
        setSearchString(valueToSeacrh);
        if (valueToSeacrh) {
            let obj = taxcodes.filter(o => o.display.substring(0, valueToSeacrh.length).toLowerCase() === valueToSeacrh.toLowerCase());
            setSearchArray(obj);
        } else {
            setSearchArray([]);
        }

    }

    return (
        <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            {label && (
                <div className={cn(styles.label, classDropdownLabel)}>
                    {label}{" "}
                    {tooltip && (
                        <Tooltip
                            className={styles.tooltip}
                            title={tooltip}
                            icon="info"
                            place="right"
                        />
                    )}
                </div>
            )}
            <div
                className={cn(
                    styles.dropdown,
                    className,
                    { [styles.small]: small },
                    {
                        [styles.active]: visible,
                    }
                )}
            >
                <div
                    className={cn(loading || invoiceDueLoading || updateTaxLoading ? styles.headWithoutIcon : styles.head, classDropdownHead)}
                    style={backgroundColor ? { backgroundColor: backgroundColor } : {}}
                    onClick={() => setVisible(!visible)}
                >
                    {loading || invoiceDueLoading || updateTaxLoading ?
                        <div style={{ position: 'absolute', top: 5, right: 10 }}>
                            <Spinner loading={loading || invoiceDueLoading || updateTaxLoading} color={"gray"} size={"20"} />
                        </div>
                        : null}

                    <div className={styles.selection}>
                        {options.map((x, index) => {
                            if (x.companyTaxId === value) {
                                return x.name;
                            }
                        })}
                    </div>
                </div>
                {showSearch && showSearch === "true" && visible ?
                    <div className={styles.xHead}>
                        <button className={styles.start}>
                            <Icon name="search" size="24" />
                        </button>
                        <input
                            className={styles.xInput}
                            type="text"
                            value={searchString}
                            onChange={codeSearchHandler}
                            name="tax category"
                            placeholder="Search"
                            autoFocus={autoFocus}
                        />
                    </div>
                    : null}
                {searchArray.length > 0 || searchString !== "" ?
                    <div className={cn(styles.body, { [styles.bodyUp]: upBody })}
                        style={searchArray.length > 6 ? { height: 200, overflowY: "scroll" } : null}
                    >
                        {searchArray.map((x, index) => (
                            <div
                                className={cn(styles.option, {
                                    [styles.selectioned]: x.id === value,
                                })}
                                onClick={() => handleClick(x.id, index)}
                                key={index}
                            >
                                {x.name}
                            </div>
                        ))}
                    </div> :
                    <div className={cn(styles.body, { [styles.bodyUp]: upBody })}
                        style={options.length > 6 ? { height: 200, overflowY: "scroll", overflowX: 'hidden' } : { overflowX: 'hidden' }}
                    >
                        {options.map((x, index) => (
                            <div
                                className={cn(styles.option, {
                                    [styles.selectioned]: x.companyTaxId === value,
                                })}
                                onClick={() => handleClick(x.companyTaxId, index, x)}
                                key={index}
                            >
                                {x.displayName + " (" + (x.rate || 0) + "%)" }
                            </div>
                        ))}
                    </div>
                }
            </div>
        </OutsideClickHandler>
    );
};

export default Dropdown;
