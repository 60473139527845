/* global Stripe */
import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./DeleteMultiCards.module.sass";
import Icon from "../../../../components/Icon";
import Image from "../../../../components/Image";
import Spinner from "../../../../utils/spinner";
import { useState } from "react";
import { deleteBulkCards } from "../../../../utils/apiCallHanlder";
import Dropdown from "../../../../components/Dropdown";
import { ToastContainer, toast } from "react-toastify";
import {
  toastConfiguration,
  trackIntercomEvent,
} from "../../../../utils/utils";
import { Router, useHistory } from "react-router";

const DeleteMultiCards = ({
  className,
  visible,
  setVisible,
  id,
  fetchDetail,
}) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("None");

  useEffect(() => {
    setReason("None");
  }, [])

  const history = useHistory();
  return (
    <div
      className={cn(styles.deleteModal, className, {
        [styles.active]: visible,
      })}
    >
      <ToastContainer />
      <div className={styles.body}>
        <div className={styles.top}>
          <button
            className={cn("close", styles.close)}
            onClick={() => setVisible(false)}
          >
            <Icon name='close' size='20' />
          </button>
        </div>
        <div
          style={{
            marginTop: -10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={styles.imgWrapper} style={{ marginTop: 20 }}>
            <Image
              className={styles.pic}
              src='/images/content/close.png'
              srcDark='/images/content/close.png'
              alt='delete'
            />
          </div>
          <div style={{ marginTop: 15 }}>
            <Dropdown
              label='Card Cancel Reason'
              value={reason}
              setValue={setReason}
              options={["None", "Lost", "Stolen"]}
              className={styles.dropdown}
            />
          </div>
          <div style={{ marginTop: 35 }}>
            <button
              className='button'
              style={{ width: 150, height: 50 }}
              onClick={async () => {
                setLoading(true);
                try {
                  const { data } = await deleteBulkCards({
                    ids: id,
                    description: reason,
                  });
                  if (data) {
                    trackIntercomEvent("card-cancelled");
                    toast.success(
                      "Card canceled successfully!",
                      toastConfiguration
                    );
                    history.push({
                      pathname: "/cards",
                      state: { isArchived: true },
                    });
                    setVisible(false);
                    fetchDetail();
                  } else {
                    toast.error("Error in canceling card", toastConfiguration);
                  }
                } catch (error) {
                  toast.error("Error in canceling card", toastConfiguration);
                }

                setLoading(false);
              }}
            >
              {loading ? (
                <Spinner loading={loading} size={30} />
              ) : (
                "Yes I'm sure!"
              )}
            </button>
          </div>
        </div>
      </div>
      <div className={styles.overlay} onClick={() => setVisible(false)}></div>
    </div>
  );
};

export default DeleteMultiCards;
