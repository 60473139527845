import Dropdown from '../../../components/Dropdown';
import DateInput from '../../../components/DateInput';
import styles from './header.module.sass';

const suppliers = ["Supplier 1", "Supplier 2", "Supplier 3"];

const InventoryHeader = ({
    supplier,
    datePlaced,
    recieveDate,
    setSupplier,
    setDatePlaced,
    setRecieveDate
}) => {

    return <div className={styles.headercontainer}>
        <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            label={"Supplier"}
            value={supplier}
            setValue={setSupplier}
            options={suppliers || []}
        />
        <DateInput
            classInputValue={styles.inputValue}
            label='Date placed'
            classInput={styles.dateRangeInput}
            icon='calendar'
            className={styles.calendar}
            setComponentStartDate={setDatePlaced}
            componentStartDate={datePlaced}
        />
        <DateInput
            classInputValue={styles.inputValue}
            label='Date placed'
            classInput={styles.dateRangeInput}
            icon='calendar'
            className={styles.calendar}
            setComponentStartDate={setRecieveDate}
            componentStartDate={recieveDate}
        />
    </div>
}

export default InventoryHeader;