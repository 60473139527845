import React, { useState } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import { TriStateContext } from "react-tri-state-checkbox";
import Checkbox from "../../../../components/Checkbox";
import "react-tri-state-checkbox/styles/checkbox.css";
import { ToastContainer } from "react-toastify";
import NoProjects from "../../NoProjects";
import Row from "../Row";
import Spinner from "../../../../utils/spinner";
import { useHistory } from "react-router-dom";

const Table = ({
    className,
    checkCount,
    setCheckCount,
    setDeleteIdArray,
    deleteIdArray,
    propertiesData,
    hasMore,
    loading,
    getPropertiesData
}) => {
    const history = useHistory();
    const [nextPageLoading, setNextPageLoading] = useState(false);

    const propertiesId = propertiesData?.map((x) => {
        return x.id;
    });

    const changePropertiesAll = (e) => {
        var ariaStatus = e.target.checked;
        if (ariaStatus === true) {
            const checkboxes = document.getElementsByName("cus-checkbox");

            for (var i = 0, n = checkboxes?.length; i < n; i++) {
                checkboxes[i].checked = true;
            }

            deleteIdArray = [];

            for (let i = 0; i < propertiesData.length; i++) {
                deleteIdArray.push(propertiesData[i]?.propertyId);
            }

            setDeleteIdArray([...deleteIdArray]);

            setCheckCount(propertiesData?.length);
        }
        if (ariaStatus === false) {
            const checkboxes = document.getElementsByName("cus-checkbox");

            for (var i = 0, n = checkboxes.length; i < n; i++) {
                checkboxes[i].checked = false;
            }
            setCheckCount(0);
            setDeleteIdArray([]);
        }
        if (ariaStatus === "mixed") {
            setCheckCount(propertiesData?.length);
        }
    };

    const changeProperty = (e, id) => {
        if (e.target.checked) {
            if (checkCount + 1 === propertiesData?.length) {
                const checkbox = document.getElementsByName("cus-all-checkbox");
                if (checkbox) {
                    checkbox[0].checked = true;
                }
            }

            setCheckCount(checkCount + 1);
            setDeleteIdArray([...deleteIdArray, id]);
        }
        if (!e.target.checked) {
            const checkbox = document.getElementsByName("cus-all-checkbox");
            if (checkbox) {
                checkbox[0].checked = false;
            }

            const index = deleteIdArray.indexOf(id);
            if (index > -1) {
                deleteIdArray.splice(index, 1);
            }
            setDeleteIdArray([...deleteIdArray]);

            setCheckCount(checkCount - 1);
        }
    };

    return (
        <TriStateContext controls={propertiesId}>
            <ToastContainer />
            <>
                <div className={cn(styles.wrapper, className)}>
                    {loading ?
                        <div className={styles.spinner}>
                            <Spinner size="48" color="gray" />
                        </div>
                        :
                        propertiesData?.length > 0 ? (
                            <div className={cn(styles.table)}>
                                <div className={cn(styles.row)}>
                                    <div className={styles.col}>
                                        <Checkbox
                                            className={styles.checkbox}
                                            onChange={changePropertiesAll}
                                            name={"cus-all-checkbox"}
                                        />
                                    </div>
                                    <div className={styles.col}></div>
                                    <div className={styles.col}>Address</div>
                                    <div className={styles.col}>Customer</div>
                                    <div className={styles.col}>Type</div>
                                    <div className={styles.col}>Status</div>
                                </div>
                                {propertiesData?.map((x, index) => {
                                    return (
                                        <Row
                                            item={x}
                                            key={index}
                                            checkCount={checkCount}
                                            onChange={changeProperty}
                                            deleteIdArray={deleteIdArray}
                                            editProperty={() => history.push(`/projects/${x?.propertyId}`)}
                                        />
                                    )
                                })}
                            </div>
                        ) : (
                            <NoProjects />
                        )}
                </div>
                {hasMore && (
                    <div className={styles.foot}>
                        <button
                            className={cn("button-stroke-red button-small", styles.button)}
                            onClick={async () => {
                                setNextPageLoading(true);
                                await getPropertiesData(true);
                                setNextPageLoading(false);
                            }}
                            style={{ width: 200 }}
                        >
                            <>
                                {nextPageLoading ?
                                    <Spinner size="24" color="gray" />
                                    :
                                    <>
                                        <span>See More Properties</span>
                                        <Icon name='arrow-next' size='20'></Icon>
                                    </>
                                }
                            </>
                        </button>
                    </div>
                )}
            </>
        </TriStateContext>
    );
};

export default Table;
