import React, { useEffect, useState } from "react";
import styles from "./MyCards.module.sass";
import DefaultCard from "../../../components/CreditCard";
import { getCardDetails } from "../../../utils/apiCallHanlder";
import { useHistory } from "react-router-dom";
import Carousel from "react-multi-carousel";
import Spinner from "../../../utils/spinner";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 20,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    partialVisibilityGutter: 20,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
    partialVisibilityGutter: 150,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 50,
  },
};

const MyCards = ({ setShowCardDetails, setAddNewCard, isLoaded, cardsArray,id }) => {
  const history = useHistory();
  return (
    <>
      {isLoaded ?
        cardsArray?.length > 0 ? (
          <>
            <Carousel
              responsive={responsive}
              partialVisbile={true}
              containerClass={styles.container}
              arrows={true}
              className={`crousel-${id} cr-${id}`}
              beforeChange={() => {
                const el = document.getElementsByClassName(`crousel-${id}`);
                if(el.length > 0){
                  el[0].classList.remove(`cr-${id}`)
                }
              }}
            >
              {cardsArray?.map((x, index) => {
                return <div
                  key={index}
                  style={index !== 0 ? { marginLeft: 20 , 
                    backgroundColor: x?.cardColor } : {backgroundColor: x?.cardColor}}
                  className={styles.defaultCard}
                  onClick={() => history.push(`/accountsandcards/cards/${x?.id}`)}
                >
                  <DefaultCard cardDetail={x} />
                </div>
              })}
            </Carousel>
          </>
        ) :
          null
        // <p>You don’t have any Virtual Cards in your account.</p>
        :
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Spinner size="34" color="gray" />
        </div>
      }
    </>
  );
};

export default MyCards;
