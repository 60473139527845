import React, { useEffect, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";
import Search from "../../pages/Products/EditNewProduct/Details/SearchCode";
import Spinner from "../../utils/spinner";
import Icon from "../Icon";
import SingleItem from "./singleItem";

const Dropdown = ({
  className,
  classNameContainer,
  dontShowOptions,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  selectionStyle,
  onChange,
  options,
  showSearch,
  taxcodes,
  taxCodeName,
  setTaxCodeName,
  label,
  tooltip,
  invoiceDueLoading,
  loading,
  updateTaxLoading,
  small,
  autoFocus,
  upBody,
  handleChange,
  hideDropdown,
  inputStyles,
  searchString,
  setSearchString,
  classOptionText,
  taxCodesByGroups,
  id,
  showSingle,
  showAddNew,
  createNewHandler
}) => {
  const [visible, setVisible] = useState(false);
  const [searchArray, setSearchArray] = useState([]);

  if (loading === undefined) {
    loading = false;
  }

  const handleClick = (value, index, x) => {
    setValue(value);
    setVisible(false);
    setSearchString && setSearchString(x.text);
    setSearchArray([]);

    {
      handleChange && handleChange(value, x);
    }
    {
      onChange && onChange(value, x);
    }
  };

  const codeSearchHandler = (event) => {
    const valueToSeacrh = event.target.value;
    // if (valueToSeacrh === "") {
    //   setValue("");
    // }
    //setTaxCodeName(valueToSeacrh);
    setSearchString(valueToSeacrh);
    if (valueToSeacrh) {
      let searchTaxCodesByGroup = [];

      if (showSingle) {
        let obj = taxCodesByGroups.filter(
          (o) =>
            o.display.substring(0, valueToSeacrh.length).toLowerCase() ===
            valueToSeacrh.toLowerCase()
        );
        if (valueToSeacrh === "") {
          setSearchArray([]);
        }
        searchTaxCodesByGroup = obj;
      } else {
        let obj = taxcodes.filter(
          (o) =>
            o.display.substring(0, valueToSeacrh.length).toLowerCase() ===
            valueToSeacrh.toLowerCase()
        );
        let taxCodesGroups = [];

        for (let i = 0; i < obj?.length; i++) {
          if (taxCodesGroups.includes(obj[i]?.group)) {
            searchTaxCodesByGroup[obj[i]?.group] = [
              ...searchTaxCodesByGroup[obj[i]?.group],
              obj[i],
            ];
          } else {
            taxCodesGroups.push(obj[i]?.group);
            searchTaxCodesByGroup[obj[i]?.group] = [obj[i]];
          }
        }
      }
      setSearchArray(searchTaxCodesByGroup);
    } else {
      setSearchArray([]);
    }
  };

  return (
    <div className={cn(classNameContainer, styles.classNameContainer)}>
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div
          className={cn(styles.label, classDropdownLabel)}
          style={{
            opacity: label === "hideLabel" ? 0 : 1,
          }}
        >
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon='info'
              place='right'
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible || Object.keys(searchArray)?.length > 0,
          }
        )}
      >
        {hideDropdown ? null : (
          <div
            className={cn(
              loading ||
                invoiceDueLoading ||
                updateTaxLoading ||
                id !== undefined
                ? styles.headWithoutIcon
                : styles.head,
              classDropdownHead
            )}
            onClick={() => {
              setVisible(!visible);
              if (showSingle) {
                setSearchArray([]);
                setSearchString("");
              }
            }}
          >
            {loading || invoiceDueLoading || updateTaxLoading ? (
              <div style={{ position: "absolute", top: 5, right: 10 }}>
                <Spinner
                  loading={loading || invoiceDueLoading || updateTaxLoading}
                  color={"gray"}
                  size={"20"}
                />
              </div>
            ) : null
            }

            {!dontShowOptions && (
              <div className={styles?.selection} style={selectionStyle || {}}>
                {options.map((x, index) => {
                  if (x.value === value || x.id === value || x.userid === value) {
                    return `${x.text || x.name} ${x.accountNumberLast4
                      ? `(****${x.accountNumberLast4})`
                      : ""
                      }`;
                  }
                })}
              </div>
            )}
          </div>
        )}
        {showSearch && showSearch === "true" ? (
          <div className={styles.xHead}>
            <button className={styles.start}>
              <Icon name='search' size='24' />
            </button>
            <input
              className={styles.xInput}
              style={inputStyles}
              type='text'
              value={searchString}
              onChange={codeSearchHandler}
              name='tax category'
              placeholder='Search'
              autoFocus={false}
              onClick={() => {
                if (!searchString && searchArray?.length < 1) {
                  setSearchArray(taxCodesByGroups);
                } else {
                  setSearchArray([]);
                }
              }}
            />
            {(showSingle && searchString) ? (
              <button
                className={styles.close}
                onClick={() => {
                  setVisible(false);
                  setSearchArray([]);
                  setSearchString("");
                }}
              >
                <Icon name='close-circle' size='24' />
              </button>
            ) :
              <button
                className={styles.close}
                onClick={() => {
                  setValue('');
                  setVisible(false);
                  setSearchArray([]);
                  setSearchString("");
                }}
              >
                <Icon name='close-circle' size='24' />
              </button>
            }
          </div>
        ) : null}
        {showSingle && (searchArray?.length > 0 || searchString) ? (
          <div
            className={cn(styles.body, { [styles.bodyUp]: upBody })}
            style={
              searchArray?.length > 4
                ? {
                  height: 220,
                  overflowY: "scroll",
                }
                : {}
            }
          >
            {searchArray?.map((x, index) => {
              return (
                <div
                  className={cn(styles.option, {
                    [styles.selectioned]: x.value === value,
                  })}
                  onClick={() => handleClick(x.value, index, x)}
                  key={index}
                >
                  {x.text}
                </div>
              );
            })}
          </div>
        ) : Object.keys(searchArray)?.length > 0 && !showSingle ? (
          <div
            className={cn(styles.body, { [styles.bodyUp]: upBody })}
            style={{
              height: 220,
              overflowY: "scroll",
            }}
          >
            {Object.keys(searchArray)?.map((item, i) => {
              return (
                <div key={i}>
                  <SingleItem
                    item={item}
                    searchArray={searchArray}
                    value={value}
                    handleClick={handleClick}
                    searchString={searchString}
                  />
                </div>
              );
            })}
          </div>
        ) : hideDropdown ? null : (
          id === undefined && (
            <>
              <div
                className={cn(styles.body, { [styles.bodyUp]: upBody })}
                style={
                  options?.length > 6
                    ? {
                      height: showAddNew ? 220 : 200,
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }
                    : { overflowX: "hidden" }
                }
              >
                {showAddNew && (
                  <div
                    className={cn(
                      styles.option,
                      styles.addSection,
                      classOptionText
                    )}
                    style={{
                      display: 'flex',
                      gap: 8,
                      marginLeft: 12,
                      marginRight: 12,
                      paddingTop: 12,
                      paddingBottom: 12,
                      backgroundColor: '#E9E7E0'
                    }}
                    onClick={createNewHandler}
                  >
                    <Icon name="plus-circle" size="24" />
                    Create new agreement
                  </div>
                )}
                {options?.filter(item => item?.id !== "choose")?.map((x, index) => (
                  <div
                    className={cn(
                      styles.option,
                      {
                        [styles.selectioned]: x.value === value,
                      },
                      classOptionText
                    )}
                    onClick={() => {
                      handleClick(x.value || x.id || x.userid, index, x);
                    }}
                    key={index}
                  >
                    {x.text || x.name}
                    {x.accountNumberLast4 && ` (****${x.accountNumberLast4})`}
                  </div>
                ))}
              </div>
            </>
          )
        )}
      </div>
    </OutsideClickHandler>
    </div>
  );
};

export default Dropdown;
