import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Entry from "./Entry";
import withUserAuth from "../../utils/withUserAuth";
import { ToastContainer } from "react-toastify";
import Image from "../../components/Image";
import Icon from '../../components/Icon';
import Theme from "../../components/Theme";
import { useState } from "react";

const SignIn = (props) => {
  const heightWindow = use100vh();
  const [themeVisible, setThemeVisible] = useState(true);

  return (
    <div className={styles.row}>
      <ToastContainer />
      <div className={styles.col} >
        <div className={styles.head}>
          <Link
            className={styles.logo}
            to={{ pathname: "https://greatweek.com" }}
            target="_blank"
          >
            <Image
              className={styles.pic}
              src="/images/logo-dark.png"
              srcDark="/images/logo-light.png"
              alt="Core"
            />
          </Link>
        </div>
        <div className={styles.wrap}>
          <div className={cn("h4", styles.title)}>
            {props.istestmode && <span>Test </span>} Welcome back
            <div className="h5">&nbsp;</div>
          </div>
          <Entry istestmode={props.istestmode} />
          <div className={styles.info}>
            Don't have an account?{" "}
            <Link className={styles.link} to="/sign-up">
              Sign Up
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.themeWrap}>
        {/* <a
          className={styles.link}
          href="https://help.greatweek.com/"
          target="blank"
        >
          <Icon name="help" size="18" />
          <span>Help Center</span>
        </a> */}
        <Theme className={styles.theme} visibleSidebar={themeVisible} />
      </div>
    </div>
  );
};

export default withUserAuth(SignIn);
