import React, { useState } from "react";
import Card from "../../../components/Card";
import cn from "classnames";
import styles from "./NoCustomer.module.sass";
import Image from "../../../components/Image";
import AddNewCustomer from '../EditNewCustomer';
import { useParams } from "react-router";

const NoCustomer = ({ onConfirm, id, activeIndex }) => {
  const [visible, setVisible] = useState(false);
  const value = activeIndex === 1 ? "contacts" : activeIndex === 2 ? "customer" : "recipients";

  return (
    <>
      {!visible ?
        <div className={styles.row}>
          <div className={styles.col}>
            <Card className={styles.card}>
              <Image
                className={styles.pic}
                src="/images/icons/empty-black.png"
                srcDark="/images/icons/empty-white.png"
                alt="Core"
              />
              {/* <div className={styles.txtWrap}>
                <h3>You don't have any registered {value}</h3>
                <p>No time for coffee, grow your business now</p>
                {activeIndex !== 1 && (
                  <button onClick={onConfirm ? onConfirm : () => setVisible(true)} className={cn("button", styles.button)}>
                    Create a {value}
                  </button>
                )}
              </div> */}
            </Card>
          </div>
        </div>
        :
        <AddNewCustomer isAdd={true} id={id} />
      }

    </>
  );
};

export default NoCustomer;
