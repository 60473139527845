import React, { useState, useEffect } from "react";
import ProductOverview from "./ProductOverview";
import { useParams, withRouter } from "react-router";
import EditNewProduct from "./EditNewProduct";
import ErrorModal from "../../components/ErrorModal";
import { stripeConnectionStatus } from "../../utils/utils";

const Products = (props) => {
  const isNewHeader = props?.location?.state?.isNewHeader;

  const [visible, setVisible] = useState(true);
  //const [isStripedVerified, setIsStripedVerified] = useState(stripeConnectionStatus());
  const [isStripedVerified, setIsStripedVerified] = useState(true);

  const { id } = useParams();

  return (
    <>
      {!isStripedVerified ? (
        <ErrorModal visibility={true} />
      ) : id ? (
        <EditNewProduct id={id} />
      ) : isNewHeader ? (
        <EditNewProduct isAdd={true} />
      ) : (
        <ProductOverview activeIndex={props?.location?.state?.activeIndex} />
      )}
    </>
  );
};

export default withRouter(Products);
