import React, { useState } from 'react';
import Spinner from '../../../utils/spinner';
import styles from './LinkedAccounts.module.sass';
import { useHistory } from 'react-router';
import cn from "classnames";

import Icon from '../../../components/Icon';

const SingleAccount = ({ x, refreshButtonHandler }) => {

    const [refreshButtonLoading, setRefreshButtonLoading] = useState(false);
    const history = useHistory();

    const refresHandler = async (x, event) => {
        event.stopPropagation();
        setRefreshButtonLoading(true);
        await refreshButtonHandler(x, setRefreshButtonLoading);
    }

    const clickHandler = (x) => {
        history.push(`/accountsandcards/linkedAccount/${x.id}`)
    }

    return (
        <div className={styles.singleAccount} onClick={() => clickHandler(x)} style={x?.status === "active" ? { cursor: 'pointer' } : { pointerEvents: 'none' }} >
            <div className={cn(styles.card, styles[x.color || 'gray'])}></div>
            <div className={styles.info}>
                <div className={styles.name}>{x.name} | {x.bankName}</div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className={styles.amount} style={x?.status === "active" ? null : { color: 'red' }}>{x.balance}</div>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: 50,
                        position: 'absolute',
                        right: 45,
                    }}>{x?.status}</div>
                    {x?.status === "active" ?
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            height: 50,
                            position: 'absolute',
                            right: 15,
                            cursor: 'pointer'
                        }}>
                            {x.isBalanceRefreshInprogress || refreshButtonLoading ?
                                <>
                                    < Spinner size="24" color="gray" />
                                </>
                                :
                                <div onClick={(e) => refresHandler(x, e)}>
                                    <Icon name="refresh" size={16} viewBox={"0 0 24 24"} />
                                </div>
                            }
                        </div>
                        : null}
                </div>
            </div>
        </div>
    )
}

export default SingleAccount;