import { useState } from 'react';
import Card from '../../components/Card';
import InventoryHeader from './Header';
import styles from './inventory.module.sass';
import TableHeader from './TableHeader';
import { defaultOrderValues, inventoryOrder } from '../../utils/appConstanst'
import TableInputRow from './TableIInputRow';
import TableAddedRow from './TableAddedRow';
import TotalBar from './TotalBar';
import cn from 'classnames';

const Inventory = () => {
    const [supplier, setSupplier] = useState("Supplier 1");
    const [datePlaced, setDatePlaced] = useState(new Date());
    const [recieveDate, setRecieveDate] = useState(new Date());

    //tableInputRow States
    const [orderValues, setOrderValues] = useState(defaultOrderValues);
    const [rows, setRows] = useState([inventoryOrder]);
    const [notes, setNotes] = useState("");

    return <>
        <Card
            title={'New Order'}
            id={"inventoryCard"}
            head={
                <div className={styles.headerButtonContainer}>
                    <button className='button-stroke'>
                        Discard
                    </button>
                    <button className='button'>
                        Save
                    </button>
                </div>
            }
        >

            <InventoryHeader
                supplier={supplier}
                datePlaced={datePlaced}
                recieveDate={recieveDate}
                setSupplier={setSupplier}
                setDatePlaced={setDatePlaced}
                setRecieveDate={setRecieveDate}
            />

            <TableHeader />
            <TableInputRow
                orderValues={orderValues}
                setOrderValues={setOrderValues}
                rows={rows}
                setRows={setRows}
            />
            <div className={styles.line}></div>


            {rows?.map((item, index) => {
                return <TableAddedRow
                    row={item}
                    rows={rows}
                    setRows={setRows}
                    key={index}
                    isLastItem={rows[index + 1] ? false : true}
                />
            })}

            <TotalBar />

            <div className={styles.notes}>
                <div className={styles.fieldLabel}>Notes</div>
                <textarea
                    rows={"4"}
                    className={cn(styles.description, styles.field)}
                    name="notes"
                    onChange={(event) => setNotes(event?.target?.value)}
                    value={notes}
                    type="text"
                    required
                ></textarea>
            </div>
        </Card>
    </>
}

export default Inventory;