import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Image from "../../../../components/Image";
import { getContactPayments } from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { dateFormatterWithTime, formatAmount, toastConfiguration, toTitleCase } from "../../../../utils/utils";
import styles from "./Payments.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import { useHistory } from "react-router";
import AlternativeAvatar from "../../../../components/AlternativeAvatar";
import TransactionPopup from "../../../Accounting/Reconcile/TransactionPopup";

let page = 0;

const Payments = ({ id, name, email, mobileCode, mobile, imageURL }) => {

  let pageSize = 100;
  const [paymentsInfo, setPaymentsInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isNextPageExist, setIsNextPageExist] = useState(false);
  const [newDataLoading, setNewDataLoading] = useState(false);

  const [showTransactionPopup, setShowTransactionPopup] = useState(false);
  const [currentTransactionObject, setCurrentTransactionObject] = useState(null);
  const [amountPaid, setAmountPaid] = useState("");
  const [amountBooked, setAmountBooked] = useState("");
  
  const history = useHistory();

  useEffect(async () => {
    page = 0;
    await getContactPaymentHandler();
  }, []);

  const getContactPaymentHandler = async (loader) => {
    if (loader)
      loader(true)
    else
      setLoading(true);
    page = page + 1;
    const { data, error } = await getContactPayments({
      page: page || 1,
      pageSize,
      id: id,
      email: email,
    });
    
    setAmountPaid(formatAmount(data?.amountPaid));
    setAmountBooked(data?.amountPaid > 0 ? formatAmount(data?.amountPaid) : "");
    
    if (data?.payments) {
      setPaymentsInfo(data?.payments);
    }
    else {
      toast.error(error, toastConfiguration);
    }

    setIsNextPageExist(data?.isNextPageExist || false);


    if (loader)
      loader(false)
    else
      setLoading(false);
  };

  const editClickHandler = (item) => {
    if (item.paymentType == "Invoice")
      history.push(`/payments/${item.id}`);
    if (item.paymentType == "Subscription")
      history.push(`/subscriptions/${item.id}`);
    if(item.paymentType == "Transaction") {
      setCurrentTransactionObject(item);
      setShowTransactionPopup(true)
    }

  };

  return (
    <>
      <ToastContainer></ToastContainer>
      <div className={styles.infoWrapper}>
        {imageURL ?
          <Image
            src={imageURL || "/images/content/avatar.jpg"}
            srcDark={imageURL || "/images/content/avatar.jpg"}
            className={styles.pic}
          />
          :
          <AlternativeAvatar name={name} className={'small-icon'} />
        }
        <div className={styles.infoWrap}>
          <div className={styles.info}>
            <p className={styles.label}>Name</p>
            <p className={styles.txt}>{name}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.label}>Email</p>
            <p className={styles.txt}>{email}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.label}>Mobile</p>
            <p className={styles.txt}>
              {mobileCode} {mobile}
            </p>
          </div>
          {amountPaid && <div className={styles.infoWrap}>
          <div className={styles.info}>
            <p className={cn(styles.label, styles.labelAmountPaid)}>Amount Paid</p>
            <p className={cn(styles.txt, styles.txtAmountPaid)}>{amountPaid}</p>
          </div>
          </div>}
          {/* {amountBooked && <div className={styles.infoWrap}>
          <div className={styles.info}>
            <p className={cn(styles.label, styles.labelAmountPaid)}>Amount Booked</p>
            <p className={cn(styles.txt, styles.txtAmountPaid)}>{amountBooked}</p>
          </div>
          </div>} */}
        </div>
      </div>
      <div className={styles.table}>
        {loading ? (
          <div className={styles.spinner}>
            <Spinner loading={loading} size={50} color={"gray"} />
          </div>
        ) : (<>
          <div className={styles.head}>
            {/* <div className={styles.col}>Name</div> */}
            <div className={styles.col}>Detail</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Created</div>
          </div>
          <>
            {paymentsInfo.map((x, index) => (
              <div className={styles.row} key={index} onClick={() => { editClickHandler(x) }}>
                {/* <div className={styles.col}>
                  <p className={styles.mblLabel}>ID</p>
                  <span>{x.invoiceNumber || x.productName}</span>
                </div> */}
                <div className={styles.col}>
                  <p className={styles.mblLabel}>Detail</p>
                  <span>{x.detail}</span>
                </div>
                <div className={styles.col}>
                  <p className={styles.mblLabel}>Status</p>
                  {/* className={cn(styles[x.status] || styles.draft)} */}
                  <span className={styles[x?.status]}>
                    {toTitleCase(x.status)}
                  </span>

                </div>
                <div className={styles.col}>
                  {/* className={cn(styles[x.status] || styles.draft)} */}
                  <span >
                    {x.amount || "-"}
                  </span>

                </div>
                <div className={styles.col}>
                  <p className={styles.mblLabel}>Created</p>
                  <span>{dateFormatterWithTime(x.createdOn)}</span>
                </div>
              </div>
            ))}
          </>
        </>
        )}

        {isNextPageExist ?
          <div className={styles.foot}>
            <button className={cn("button-stroke-red button-small", styles.button)}
              onClick={() => getContactPaymentHandler(setNewDataLoading)}
              style={{ width: "250px" }}
            >
              {newDataLoading ?
                <Spinner size="24px" color="gray" loading={newDataLoading} />
                :
                (
                  <>
                    <span>See More Payments</span>
                    <Icon name="arrow-next" size="20"></Icon>
                  </>
                )

              }
            </button>
          </div>
          : null}

        <TransactionPopup
          showTransactionPopup={showTransactionPopup}
          setShowTransactionPopup={setShowTransactionPopup}
          setCurrentTransactionObject={setCurrentTransactionObject}
          currentTransactionObject={currentTransactionObject}
        />

      </div>
    </>
  );
};

export default Payments;
