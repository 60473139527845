import React, { useState, useEffect, useCallback } from "react";
import styles from "./Documents.module.sass";
import Card from "../../../components/Card";
import cn from "classnames";
import Icon from "../../../components/Icon";
import Table from "./Table";
import AccountInfo from "./AccountInfo";
import DeleteModal from "../../../components/DeleteModal";
import Dropzone, { useDropzone } from "react-dropzone";
import { getAccountingDocuments, uploadAccountingDocuments, deleteAccountingDocuments, uploadStudioDocuments, accountingDocumentAttacher, getDocumentTransactions } from "../../../utils/apiCallHanlder";
import Spinner from "../../../utils/spinner";
import DocumentOverviewPopup from "./DocumentOverviewPopup";
import { ToastContainer, toast } from "react-toastify";
import { dateFormatter, toastConfiguration } from "../../../utils/utils";
import Modal from "../../../components/Modal";
import Amount from "../../../components/Amount";
import transactionStyles from '../../../components/Transactions/Transactions.module.sass';
import Checkbox from "../../../components/Checkbox";

let pageNumber = 1;

const Documents = ({ className }) => {
  const [actionBtn, setActionBtn] = useState(true);
  const [show, setShow] = useState(false);
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [attachmentLink, setAttachmentLink] = useState("");
  const [docId, setDocId] = useState(0);
  const [attachLoading, setAttachLoading] = useState(false);
  const [transactionsModal, setTransactionsModal] = useState(false);
  const [isNextPageExist, setIsNextPageExist] = useState(false);
  const [nextPageDate, setNextPageDate] = useState(new Date());
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const [showNewDocModal, setShowNewDocModal] = useState(false);
  const [editDocItem, setEditDocItem] = useState(null);

  const [transactionsLoading, setTransactionsLoading] = useState(false);

  const [loading, setLoading] = useState(true);

  const onDrop = useCallback(async (acceptedFiles) => {
    const formData = new FormData();
    setUploading(true);

    acceptedFiles.map((x) => {
      formData.append('files', x);
    })

    const { data } = await uploadAccountingDocuments(formData);

    if (data?.transactions?.length > 0) {
      setSelectedTransactions([]);
      setAttachmentLink(data?.document?.attachmentLink);
      setDocId(data?.document?.documentFileId)
      setTransactions(data?.transactions);
      setTransactionsModal(true);
    } else {
      fetchDocuments();
    }

    setUploading(false);
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })


  const handleEditDocumentMetadata = (docInfo) => {
    setEditDocItem(docInfo);
    setShowNewDocModal(true);
  };

  const deleteItem = async (obj) => {

    const { data } = await deleteAccountingDocuments([obj.id]);
    if (data) {
      for (var i = 0; i < documents.length; i++) {
        if (documents[i].documentFileId == obj.id) {
          documents.splice(i, 1);
          setDocuments(documents.slice(0));
          break;
        }
      }
      toast.success("Document deleted successfully", toastConfiguration);
    } else {
      toast.error("Error in deleting document", toastConfiguration);
    }


    return { data: true };
  };

  useEffect(() => {
    if (!showNewDocModal) {
      fetchDocuments();
    }
  }, [showNewDocModal]);

  const fetchDocuments = async (pageNo = 1) => {
    pageNo === 1 && setLoading(true);
    const { data } = await getAccountingDocuments({
      page: pageNo || pageNumber,
      pageSize: 15
    });
    if (data && data.documents) {
      if (pageNo === 1) {
        setDocuments(data.documents);
      } else {
        setDocuments([...(documents || []), ...(data.documents || [])])
      }

      setIsNextPageExist(data.hasNextPage);
    }
    pageNo === 1 && setLoading(false)
  };

  const onItemDeleted = (item) => {
    const newdocuments = documents.filter((t) => t !== item);
    setDocuments(newdocuments);
  }

  const onItemUpdated = (item) => {
    let index = documents.indexOf(item);
    const newdocuments = [...documents];
    newdocuments[index] = item;
    setDocuments(newdocuments);
  }

  const fetchNextPage = async () => {
    setNextPageLoading(true);
    pageNumber = pageNumber + 1;
    await fetchDocuments(pageNumber)
    // const formData = new FormData();
    // formData.append('showOlderThan', nextPageDate);
    // const { data } = await getAccountingDocuments(formData);
    // if (data && data.documents) {
    //   setDocuments([...documents, ...data.documents]);
    //   setIsNextPageExist(data.hasNextPage);
    //   setNextPageDate(data.minUploadedOnDate);
    // }
    setNextPageLoading(false);
  };

  const actionBtnHandle = () => {
    setActionBtn(!actionBtn);
  };

  const onCheckboxChange = (id) => {
    if (selectedTransactions?.includes(id)) {
      const filtered = selectedTransactions?.filter(item => item !== id);
      setSelectedTransactions([...filtered]);
    } else {
      setSelectedTransactions([...selectedTransactions, id])
    }
  }

  const attachHandler = async () => {
    if (selectedTransactions?.length < 1) {
      toast.error('Please select at least one transaction', toastConfiguration);
      return;
    }
    setAttachLoading(true);
    const { data } = await accountingDocumentAttacher({
      transactionIds: selectedTransactions,
      attachmentLink: attachmentLink,
      docId: docId
    });

    if (data) {
      toast.success("Transaction(s) attached to document successfully", toastConfiguration);
      setAttachmentLink("");
      setDocId(0);
      setTransactions([]);
      fetchDocuments();
      setTransactionsModal(false);
    } else {
      toast.error("Error in attaching transaction(s) to document", toastConfiguration);
    }

    setAttachLoading(false);
  }

  const attachmentClickHandler = async (id, link) => {
    setTransactionsModal(true);
    setTransactionsLoading(true);
    const { data } = await getDocumentTransactions(id);
    if (data) {
      setSelectedTransactions([]);
      setAttachmentLink(link);
      setTransactions(data?.transactions);
      setDocId(id)
    }
    setTransactionsLoading(false);
  }

  return (
    <>
      <ToastContainer />
      <Card
        className={cn(styles.card, className)}
        classCardHead={styles.head}
        title=""
      >
        <AccountInfo
          visible={showAccountInfo}
          setVisible={setShowAccountInfo}
        />
        <div {...getRootProps({ className: styles.dropzone })}>
          <div style={{ left: "50%", top: "50%" }}>
            <Spinner loading={uploading} color="gray" size="45px" />
          </div>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the file here ...</p> :
              <p>Drop a file here, or click to select a file</p>
          }
        </div>
        {loading ?
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}>
            <Spinner size="64" color="gray" />
          </div>
          :
          <Table
            className={styles.table}
            activeTable={show}
            attachmentClickHandler={attachmentClickHandler}
            setActiveTable={setShow}
            deleteItem={deleteItem}
            editAction={
              <button
                className={styles.actionItem}
                onClick={() => { }}
              >
                <Icon name="edit" size="24" />
                Edit
              </button>
            }
            editDocument={handleEditDocumentMetadata}
            actionBtnHandle={actionBtnHandle}
            documents={documents}
            isNextPageExist={isNextPageExist}
            fetchNextPage={fetchNextPage}
            nextPageLoading={nextPageLoading}
          />
        }

      </Card>

      <DocumentOverviewPopup
        editDocItem={editDocItem}
        showNewDocModal={showNewDocModal}
        setShowNewDocModal={setShowNewDocModal}
        onItemDeleted={onItemDeleted}
        onItemUpdated={onItemUpdated}
        customerId={editDocItem?.contactId}
        customerName={editDocItem?.contactName}
      />

      <Modal
        outerClassName={styles.outer}
        visible={transactionsModal}
        onClose={() => {
          setTransactionsModal(false);
          fetchDocuments();
        }}
        children={
          <Card
            className={cn(styles.card, className)}
            classCardHead={styles.head}
            title="Matching Transaction(s) Found"
          >
            <div className={transactionStyles.wrapper} style={{
              height: 305,
              overflowY: "auto"
            }}>
              {transactionsLoading ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Spinner size="36" color="gray" />
                </div>
                :
                <div className={transactionStyles.table}>
                  <div className={transactionStyles.rowWithoutHover}>
                    <div className={transactionStyles.col}></div>
                    <div className={transactionStyles.col}>Date</div>
                    <div className={transactionStyles.col}>To/From</div>
                    <div className={transactionStyles.col}>Account</div>
                    <div className={transactionStyles.col} style={{ width: 150 }}>
                      Type
                    </div>
                    <div className={transactionStyles.col}>Amount</div>
                  </div>
                  {transactions.map((x, index) => (
                    <div
                      className={transactionStyles.row}
                      key={index}
                      onClick={() => onCheckboxChange(x.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className={transactionStyles.col} style={{ width: 20 }} onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                          className={styles.checkbox}
                          value={selectedTransactions?.includes(x.id) ? true : false}

                          onChange={() => onCheckboxChange(x.id)}
                        />
                      </div>
                      <div className={transactionStyles.col} style={{ width: 150 }} >
                        <div className={transactionStyles.label}>Date</div>
                        {dateFormatter(x.created)}
                      </div>
                      <div className={transactionStyles.col}>
                        <div className={transactionStyles.label}>To/From</div>
                        {x.description}
                      </div>
                      <div className={transactionStyles.col}>
                        <div className={transactionStyles.label}>Type</div>
                        {x.accountType}
                      </div>
                      <div className={transactionStyles.col}>
                        <div className={transactionStyles.label}>Type</div>
                        <span style={{ display: "flex", width: "max-content" }}>
                          {x.transactionDetail || x.accountType}
                        </span>
                      </div>
                      <div
                        className={transactionStyles.col}
                        style={
                          parseInt(x.amount?.replace("$", "")) < 0
                            ? { color: "red" }
                            : {}
                        }
                      >
                        <div className={transactionStyles.label}>Amount</div>
                        <Amount amount={x.amount} subtitleEmValue={"0.7em"} />
                      </div>
                    </div>
                  ))}
                </div>
              }
            </div>
            {!transactionsLoading && (
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                marginTop: 12
              }}>
                <button
                  className={"button"}
                  onClick={attachHandler}
                >
                  {attachLoading ?
                    <Spinner size="24" color="white" />
                    :
                    "Attach"
                  }
                </button>
              </div>
            )}
          </Card>
        }
      />
    </>
  );
};

export default Documents;
