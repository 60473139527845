import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Search.module.sass";
import Icon from "../../Icon";
import Item from "./Item";
import Suggestion from "./Suggestion";
import ModalProduct from "../../ModalProduct";
import Spinner from "../../../utils/spinner";
import { globalSearch } from "../../../utils/apiCallHanlder";
import { useHistory } from "react-router";

const result = [
  {
    title: "Put your title here",
    content: "Small caption",
    image: "/images/content/product-pic-3.jpg",
    image2x: "/images/content/product-pic-3@2x.jpg",
  },
  {
    title: "Put your title here",
    content: "Small caption",
    image: "/images/content/product-pic-4.jpg",
    image2x: "/images/content/product-pic-4@2x.jpg",
  },
];

const suggestions = [
  {
    title: "Put your title here",
    content: "Small caption",
    icon: "photos",
  },
  {
    title: "Put your title here",
    content: "Small caption",
    icon: "photos",
  },
];

let typingTimer;
let doneTypingInterval = 600;

const Search = ({ className, visibleBar }) => {
  const [visible, setVisible] = useState(false);
  const [searchString, setSearchString] = useState("");
  let [loading, setLoading] = useState(false);
  let [noDataToShow, setNoDataToShow] = useState(false);

  let [customerArray, setCustomerArray] = useState([]);
  let [productArray, setproductArray] = useState([]);
  let [invoiceArray, setInvoiceArray] = useState([]);

  let [transactionsArray, setTransactionsArray] = useState([]);
  let [contracts, setContracts] = useState([]);
  let [recipients, setRecipients] = useState([]);
  let [commands, setCommands] = useState([]);

  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  const history = useHistory();

  const wrapperRef = useRef();
  useOutsideAlerter(wrapperRef);

  const onChangeHandler = (e) => {
    setSearchString(e.target.value);
  };

  const getSearchResult = async () => {
    setCustomerArray([]);
    setproductArray([]);
    setInvoiceArray([]);
    setTransactionsArray([]);
    setContracts([]);
    setRecipients([]);
    setCommands([]);

    setVisible(true);
    setLoading(true);
    const { data } = await globalSearch(searchString);

    if (data) {
      customerArray = [];
      productArray = [];
      invoiceArray = [];
      transactionsArray = [];
      contracts = [];
      recipients = [];
      commands = [];

      if (data.results.length > 0) {
        setNoDataToShow(false);
        for (let i = 0; i < data?.results?.length; i++) {
          if (data.results[i].type === "customer") {
            customerArray.push(data.results[i]);
          } else if (data.results[i].type === "product") {
            productArray.push(data.results[i]);
          } else if (data.results[i].type === "invoice") {
            invoiceArray.push(data.results[i]);
          } else if (data.results[i].type === "transaction") {
            transactionsArray.push(data.results[i]);
          } else if (data.results[i].type === "contract") {
            contracts.push(data.results[i]);
          } else if (data.results[i].type === "recipient") {
            recipients.push(data.results[i]);
          } else if (data.results[i].type === "command") {
            commands.push(GetCommandDisplayName(data.results[i], searchString));
          }
        }
      } else {
        setNoDataToShow(true);
      }

      setCustomerArray([...customerArray]);
      setproductArray([...productArray]);
      setInvoiceArray([...invoiceArray]);
      setTransactionsArray([...transactionsArray]);
      setContracts([...contracts]);
      setRecipients([...recipients]);
      setCommands([...commands]);

      setLoading(false);
    }
  };

  function GetCommandDisplayName(item, searchString) {
    item.name2 = searchString;
    if (item.command == "AddCard") {
      item.name = "Add a Card";
    } else if (item.command == "AddCustomer") {
      item.name = "Add a Customer";
    } else if (item.command == "AddInvoice") {
      item.name = "Add Invoice";
    } else if (item.command == "AddPaymentLink") {
      item.name = "Add Payment Link";
    } else if (item.command == "AddProduct") {
      item.name = "Add a Product";
    } else if (item.command == "AddRecipient") {
      item.name = "Add a Recipient";
    } else if (item.command == "AddSubscription") {
      item.name = "Add a Subscription";
    } else if (item.command == "AddTeam") {
      item.name = "Invite Team Member";
    }
    return item;
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisible(false);
          setSearchString("");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const resetSearch = () => {
    setSearchString("");
    setCustomerArray([]);
    setproductArray([]);
    setInvoiceArray([]);
    setTransactionsArray([]);
    setContracts([]);
    setCommands([]);
    setVisible(false);
  };

  const onCommandClickHandler = (item) => {
    if (item.command) {
      if (item.command == "AddCard") {
        history.push({
          pathname: `/accountsandcards/cards/createNew`,
          state: { isNewHeader: true },
        });
      } else if (item.command == "AddCustomer") {
        history.push({
          pathname: `/contacts`,
          state: { isNewHeader: true },
        });
      } else if (item.command == "AddInvoice") {
        history.push({
          pathname: `/payments/create`,
          state: { setNewInvoice: false, isNewHeader: true },
        });
      } else if (item.command == "AddPaymentLink") {
        history.push({
          pathname: `/paymentLinks`,
          state: { setNewInvoice: false, isNewHeader: true },
        });
      } else if (item.command == "AddProduct") {
        history.push({
          pathname: `/products`,
          state: { setNewInvoice: false, isNewHeader: true },
        });
      } else if (item.command == "AddRecipient") {
        history.push({
          pathname: `/contacts/add`,
          state: { setNewInvoice: false, isNewHeader: true },
        });
      } else if (item.command == "AddSubscription") {
        history.push({
          pathname: `/subscriptions/add`,
          state: { setNewInvoice: false, isNewHeader: true },
        });
      } else if (item.command == "AddTeam") {
        history.push({
          pathname: `/settings/Team`,
          state: { setNewInvoice: false, isNewHeader: true },
        });
      }
    }
  };

  return (
    <>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
        ref={wrapperRef}
      >
        <div className={styles.head}>
          <button className={styles.start}>
            <Icon name='search' size='24' />
          </button>
          <button
            className={styles.direction}
            onClick={() => setVisible(false)}
          >
            <Icon name='arrow-left' size='24' />
          </button>
          <input
            className={styles.input}
            type='text'
            placeholder='Search'
            value={searchString}
            onClick={() => {
              if (!visible && searchString) {
                getSearchResult();
              }
            }}
            onChange={onChangeHandler}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                getSearchResult();
              } else {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(getSearchResult, doneTypingInterval);
              }
            }}
            onKeyDown={() => {
              clearTimeout(typingTimer);
            }}
          />
          <button
            className={styles.close}
            onClick={() => {
              resetSearch();
            }}
          >
            {loading ? (
              <Spinner size={"16"} color={"gray"} />
            ) : (
              <Icon name='close-circle' size='24' />
            )}
          </button>
        </div>
        <div
          className={styles.body}
        >
          {commands?.length > 0 ? (
            <div className={styles.box}>
              <div className={styles.category}>Actions</div>
              <div className={styles.list}>
                {commands.map((x, index) => (
                  <Item
                    resetSearch={resetSearch}
                    className={styles.item}
                    item={x}
                    onClickHandler={onCommandClickHandler}
                    key={index}
                    setVisible={setVisible}
                  />
                ))}
              </div>
            </div>
          ) : null}

          {productArray?.length > 0 ? (
            <div className={styles.box}>
              <div className={styles.category}>Products</div>
              <div className={styles.list}>
                {productArray.map((x, index) => (
                  <Item
                    resetSearch={resetSearch}
                    className={styles.item}
                    item={x}
                    redirectUrl={`/products/${x.id}`}
                    key={index}
                    setVisible={setVisible}
                  />
                ))}
              </div>
            </div>
          ) : null}
          {customerArray?.length > 0 ? (
            <div className={styles.box}>
              <div className={styles.category}>Customers</div>
              <div className={styles.list}>
                {customerArray.map((x, index) => (
                  <Item
                    resetSearch={resetSearch}
                    className={styles.item}
                    redirectUrl={`/contacts/${x.id}`}
                    item={x}
                    key={index}
                    setVisible={setVisible}
                  />
                ))}
              </div>
            </div>
          ) : null}
          {recipients?.length > 0 ? (
            <div className={styles.box}>
              <div className={styles.category}>Recipients</div>
              <div className={styles.list}>
                {recipients?.map((x, index) => (
                  <Item
                    className={styles.item}
                    item={x}
                    resetSearch={resetSearch}
                    onClickHandler={() => {
                      history.push(`/contacts/${x?.id}`);
                    }}
                    key={index}
                    setVisible={setVisible}
                  />
                ))}
              </div>
            </div>
          ) : null}
          {invoiceArray?.length > 0 ? (
            <div className={styles.box}>
              <div className={styles.category}>Invoice</div>
              <div className={styles.list}>
                {invoiceArray.map((x, index) => (
                  <Item
                    resetSearch={resetSearch}
                    className={styles.item}
                    item={x}
                    redirectUrl={`/payments/${x.id}`}
                    key={index}
                    setVisible={setVisible}
                  />
                ))}
              </div>
            </div>
          ) : null}
          {transactionsArray?.length > 0 ? (
            <div className={styles.box}>
              <div className={styles.category}>Transactions</div>
              <div className={styles.list}>
                {transactionsArray?.map((x, index) => (
                  <Item
                    resetSearch={resetSearch}
                    className={styles.item}
                    item={x}
                    onClickHandler={() => {
                      history.push({
                        pathname: `/transactions`,
                        state: {
                          isFetchTransactionData: false,
                          transactionPayload: {
                            ...x,
                            fetchTransactionPayload: true,
                          },
                          showTransactionPopupFromPayload: true,
                        },
                      });
                    }}
                    key={index}
                    setVisible={setVisible}
                  />
                ))}
              </div>
            </div>
          ) : null}
          {contracts?.length > 0 ? (
            <div className={styles.box}>
              <div className={styles.category}>Contracts</div>
              <div className={styles.list}>
                {contracts?.map((x, index) => (
                  <Item
                    resetSearch={resetSearch}
                    className={styles.item}
                    item={x}
                    onClickHandler={() => {
                      history.push({
                        pathname: `/studio/4`,
                        state: {
                          searchedContractId: x?.id,
                        },
                      });
                    }}
                    key={index}
                    setVisible={setVisible}
                  />
                ))}
              </div>
            </div>
          ) : null}
          {noDataToShow ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              No Matched Item
            </div>
          ) : null}
          {/* <div className={styles.box}>
            <div className={styles.category}>Suggestions</div>
            <div className={styles.list}>
              {suggestions.map((x, index) => (
                <Suggestion
                  className={styles.item}
                  item={x}
                  key={index}
                  onClick={() => setVisibleModalProduct(true)}
                />
              ))}
            </div>
          </div> */}
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Search;
