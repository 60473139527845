import React, { useEffect } from 'react';
import styles from './ProfileDetails.module.sass';
import ProfileInformation from '../../Settings/Tabs/ProfileInformation/ProfileInformation';
import { ToastContainer, toast } from 'react-toastify';
import { handleLoginSignUpResponse, toastConfiguration } from '../../../utils/utils';
import { signUpWithUserDetails } from '../../../utils/apiCallHanlder';
import { useHistory } from 'react-router-dom';
import Spinner from '../../../utils/spinner';

const ProfileDetails = ({
    setCurrentStep,
    setDetails,
    details,
    token
}) => {
    const history = useHistory();

    useEffect(async () => {
       await doSignupDirect('');
      }, []);

    const doSignupDirect = async (code) => {
        const payload = {
          ...details,
          onlyCompany: false,
          myNewToken: token,
          skipCompany: true,
          refferedBy: code,
          returnUrl: "https://somedummyUrl.com"
        }
    
        const { data, error } = await signUpWithUserDetails(payload, false);
        if (data) {
          localStorage.removeItem("fbclid");
          localStorage.removeItem("gclid");
          localStorage.setItem(
            "connectStatus",
            JSON.stringify(data?.connectStatus)
          );
    
          localStorage.setItem("stripeConnection", data.isConnected);
          localStorage.setItem("isPending", data.isPending);
          localStorage.setItem("isTestMode", data.istestmode);
          localStorage.setItem("currencySymbol", data.currencySymbol || "$");
          localStorage.setItem("currency", data.currency || "USD");
          localStorage.setItem("isAccountingEnabled", false);
          data["redirectURL"] = null;
          data["noRedirect"] = false;
    
          await handleLoginSignUpResponse(data, history, true);
        } else {
          toast.error(error || "Error in signing up", toastConfiguration)
          setTimeout(() => {
            history.push("/dashboard");
          }, 2500);
        }
      }

    return <div className={styles.wrapper}>
        <ToastContainer />
        <div className={styles.spinner} style={{ height: window.outerHeight - 250 }}>
              <Spinner loading={true} size={150} color={"gray"} />
        </div>
    </div>
}

export default ProfileDetails;