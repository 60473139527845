import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Checkbox from "../../../../../components/Checkbox";
import Icon from "../../../../../components/Icon";
import DeleteModal from "../../../../../components/DeleteModal";
import { useHistory } from "react-router";

const Row = ({
  item,
  onChange,
  activeTable,
  activeId,
  singleCheck,
  editProduct,
}) => {
  const history = useHistory();

  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
        onClick={() => {
          history.push(`/settings/COA/${item?.coaId}`)
        }}
      >
        <div className={styles.editOverlay} ></div>
        {/* <div className={styles.col}>
          {singleCheck || <Checkbox onChange={onChange} />}
        </div> */}
        {/* <div className={styles.col}>
          <div className={styles.title}>#</div>
          <div className={styles.num}>{item.code}</div>
        </div> */}
        <div className={styles.col}>
          <div className={styles.title}>Account Name</div>
          <div className={styles.price}>{item?.name}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.title}>Type</div>
          <div className={cn(styles.tax)}>{item?.coa2Name}</div>
        </div>
        <div className={cn(styles.col, styles.typeNumber)}>
          <span className={styles.title}>Debit</span>
          <div className={styles.type}>{parseFloat(item?.debit).toFixed(2)}</div>
        </div>
        <div className={cn(styles.col, styles.typeNumber)}>
          <span className={styles.title}>Credit</span>
          <div className={styles.credit}>{parseFloat(item?.credit).toFixed(2)}</div>
        </div>
        <div className={cn(styles.col, styles.typeNumber)}>
          <span className={styles.title}>Balance</span>
          <div className={styles.balance}>{parseFloat(item.balance).toFixed(2)}</div>
        </div>
        {/* <div className={cn(styles.col, styles.typeNumber)}>
          <div className={styles.action}>
            <button onClick={() => {
              history.push(`/settings/COA/${item?.coaId}`)
            }} className={styles.editBtn} >
              <Icon name={"edit"} size="24" />
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Row;
