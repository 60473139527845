import React, { useEffect, useState } from "react";
import styles from "./Transactions.module.sass";
import cn from "classnames";
import Card from "../Card";
import Icon from "../Icon";
import Dropdown from "../TaxRateDropdown";
import { GetFileNameFromPath, toastConfiguration, toTitleCase } from "../../utils/utils";
import Image from '../Image';
// date
import { dateFormatter } from "../../utils/utils";
import Spinner from "../../utils/spinner";
import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import Search from "../../pages/Accounting/Reconcile/SearchAccounts";
import SearchCounterParty from "../../pages/Accounting/Reconcile/SearchContacts";
import TextInput from "../TextInput";
import { AddTransactionAttachment, bookTransaction, deleteTransactionAttachment, uploadFile } from "../../utils/apiCallHanlder";
import DateRangeInput from "../DateRangeInput";

const imageWRTExtension = {
  common: "/images/content/image.png",
  xlsx: "/images/content/xlsx.png",
  xls: "/images/content/xlsx.png",
  pdf: "/images/content/pdf.png"
}

const Transactions = ({
  className,
  headerTitle,
  payThroughTrans,
  transactions,
  isNextPageExist,
  getUpdatedTransactions,
  loading,
  page,
  showDate,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  setLoading,
  onClickHandler
}) => {
  const history = useHistory();
  const [nextPageLoading, setNextPageLoading] = useState(false);

  const handleSingleTransaction = (x) => {
    history.push({
      pathname: `/transactionDetails/${x?.stripeTransactionId}`,
      state: {
        isFetchTransactionData: false,
        transactionPayload: x,
        transactionDetail: x.type,
        accountType: x.accountType,
        accountId: x.accountId,
        status: x.status,
        amount: x.amount,
        desc: x.description
      }
    });
  }

  return (
    <>
      <ToastContainer />
      <Card
        className={cn(styles.card, className)}
        classCardHead={styles.head}
        title={headerTitle || "Accounting"}
        border
        /* classTitle={cn("title-blue", styles.title)} */
        head={
          showDate ?
            <div className={styles.buttonContainer}>
              <DateRangeInput
                classInputValue={styles.inputValue}
                classInput={styles.input}
                icon="calendar"
                className={styles.calendar}
                setStartDate={setStartDate}
                setLoading={setLoading}
                setEndDate={setEndDate}
                getUpdatedDashBoardData={getUpdatedTransactions}
                startDate={startDate}
                endDate={endDate}
                setLoa
              />
            </div>
            :
            null
        }
      > 
        {loading ?
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Spinner size="44px" color="gray" loading={loading} />
              </div>
              : transactions?.length < 1 ? (
                <div className={styles.noDataImg}>
                  <Image 
                    src={"/images/icons/empty-black.png"}
                    srcDark={"/images/icons/empty-white.png"}
                  />
                </div>
            ) : (
              <>
                
              <div className={styles.wrapper}>
                <div className={styles.table}>
                  <div className={styles.row}>
                    <div className={styles.col}>Date</div>
                    <div className={styles.col}>To/From</div>
                    <div className={styles.col}>Description</div>
                    <div className={styles.col}>Debit</div>
                    <div className={styles.col}>Credit</div>
                  </div>
                  {transactions.map((x, index) => (
                      <>
                        <div onClick={() => {
                          onClickHandler && onClickHandler(x?.stripeTransactionId);
                          //payThroughTrans ? payThroughTrans(x) : handleSingleTransaction(x);
                        }} className={styles.row} key={index} style={{
                          cursor: 'pointer',
                          position: 'relative'
                        }}>
                          <div className={styles.col} style={{ width: 150 }}>
                            <div className={styles.label}>Date</div>
                            {dateFormatter(x.date || x.transactionDate)}
                          </div>
                          <div className={styles.col}>
                            <div className={styles.label}>Name</div>
                            {x.coaName || x.accountName}
                          </div>
                          <div className={styles.col}>
                            <div className={styles.label}>Description</div>
                            {x.description}
                          </div>
                          <div className={styles.col}>
                            <div className={styles.label}>Debit</div>
                            <span
                              className={cn({ [styles.none]: x.attachmentCount === "none" })}>
                              {parseFloat(x.debit).toFixed(2)}
                            </span>
                          </div>
                          <div className={styles.col}
                            style={parseInt(x.amountF?.replace("$", "")) < 0 ? { color: 'red' } : {}}
                          >
                            <div className={styles.label}
                            >Credit</div>{parseFloat(x.credit).toFixed(2)}
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
              </>
            )
            }
            {isNextPageExist ?
              <div className={styles.foot}>
                <button className={cn("button-stroke-red button-small", styles.button)}
                  style={{ width: 250 }}
                  onClick={async () => {
                    if (!nextPageLoading) {
                      setNextPageLoading(true);
                      await getUpdatedTransactions(null, null, page, transactions)
                      setNextPageLoading(false);
                    }
                  }}>
                  {nextPageLoading ?
                    <Spinner size="24" color="gray" />
                    :
                    <>
                      <span>See More Transactions</span>
                      <Icon name="arrow-next" size="20"></Icon>
                    </>
                  }
                </button>
              </div>
              : null}
      </Card>
    </>
  );
};

export default Transactions;
