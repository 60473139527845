import React, { useState, useEffect } from "react";
import styles from "./Team.module.sass";
import cn from "classnames";
import Row from "./Row";
import DeleteModal from "../../../../components/DeleteModal";
import Icon from "../../../../components/Icon";
import { Checkbox } from "react-tri-state-checkbox";
import "react-tri-state-checkbox/styles/checkbox.css";
import { deleteCustomer, getAllTeam } from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import MemberDetail from "./MemberDetail";

let haveDropdownsData = false;
let roles = [""];

const Table = ({ className, memberId, setMemberId }) => {
  if (!haveDropdownsData) {
    const accountLookupData = JSON.parse(localStorage.getItem("lookupData"));
    let data = null;
    if (accountLookupData) {
      data = accountLookupData?.data;
      roles = data?.Roles;
      haveDropdownsData = true;
    }
  }

  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateUseEffect, setUpdateUseEffect] = useState(false);

  useEffect(async () => {
    setLoading(true);
    const { data } = await getAllTeam();
    if (data) {
      setTeamData(data?.team);
    }
    setLoading(false);
  }, [updateUseEffect]);

  const onRowClickHandler = (id) => {
    setMemberId(id);
  }

  return (
    <>
      {loading ? (
        <div
          className={styles.spinner}
          style={{ height: window.outerHeight - 250 }}
        >
          <Spinner loading={loading} size={150} color={"gray"} />
        </div>
      ) : (
        <div className={cn(styles.wrapper, className)}>
          <div className={cn(styles.table)}>
            <div className={cn(styles.row)}>
              <div className={styles.editOverlayHead}></div>
              <div></div>
              <div className={styles.col}>Name</div>
              <div className={styles.col} style={{ padding: "10px 46px" }}>
                Email
              </div>
              <div className={styles.col}>Role</div>
              <div className={styles.col}>Card</div>
              <div className={styles.col} style={{ padding: "0px 30px 16px" }}>
                Last Login
              </div>
              <div className={styles.col}></div>
            </div>
            {teamData.map((x, index) => (
              <Row
                item={x}
                key={index}
                setUpdateUseEffect={setUpdateUseEffect}
                updateUseEffect={updateUseEffect}
                roles={roles}
                singleCheck={
                  <Checkbox
                    // onClick={changeCustomer}
                    className={styles.triCheckbox}
                    id={x.id}
                  />
                }
                onClickHandler={onRowClickHandler}
                editAction={
                  <button
                    className={styles.actionItem}
                  // onClick={() => {
                  //     setVisible(false);
                  // }}
                  >
                    <Icon name='edit' size='24' />
                    Edit
                  </button>
                }
                deleteAction={
                  <DeleteModal
                    id={x.id}
                    deleteFunction={deleteCustomer}
                    setValue={true}
                    icon={
                      <Icon
                        className={styles.btnIcon}
                        fill='#FF4900'
                        name='trash'
                        size='20'
                      />
                    }
                    className={styles.deleteBtn}
                    btnClass={cn(styles.btnClass)}
                  />
                }
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
