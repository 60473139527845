import { isUserLoggedIn, getCurrentPageName } from "./utils";
import { Redirect } from "react-router-dom";

const withUserAuth = (Component) => {

    const WrappedComponent = (props) => {
        const page = getCurrentPageName();
        const isLoggedIn = isUserLoggedIn();

        const publicPath = (
            page === "" ||
            page === "sign-up" ||
            page === "sign-in-google" ||
            page === "sign-up-test" ||
            page === "sign-up-custom" ||
            page === "sign-in-test" ||
            page == "no-account-found" ||
            (page > 0 && page < 5) //For signup sequences
        );

        if (publicPath && !isLoggedIn) {
            return <Component {...props} />;
        } else if (publicPath && isLoggedIn) {
            return <Redirect to={{ pathname: "/dashboard" }} />
        } else if (!isLoggedIn) {
            return <Redirect to={{ pathname: "/" }} />
        }

        return <Component {...props} />
    }
    return WrappedComponent;
}

export default withUserAuth;
