import React, { useState } from "react";
import cn from "classnames";
import styles from "./ErrorModal.module.sass";
import Icon from "../Icon";
import Image from "../Image";
import Spinner from '../../utils/spinner';
import { deleteCustomer } from "../../utils/apiCallHanlder";
import MainCustomerContainer from '../../pages/Customers/index';
import { reverifyHandler } from "../../utils/utils";

const DeleteModal = ({ className, children, visibility }) => {
  const [visible, setVisible] = useState(visibility);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div
      className={cn(styles.deleteModal, className, {
        [styles.active]: visible,
      })}
    >
      <div className={styles.body}>
        <div className={styles.top}>
          <button
            className={cn("close", styles.close)}
            onClick={() => setVisible(false)}
          >
            <Icon name="close" size="20" />
          </button>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.imgWrapper}>
            <Image
              className={styles.pic}
              src="/images/content/lock.png"
              srcDark="/images/content/lock.png"
              alt="delete"
            />
          </div>
          <div className={styles.title}>Please complete stripe verification</div>
          <div className={styles.para} style={{ textAlign: 'center' }}>
            We work in close partnership with stripe.com and you will need to fill in some more information to start using the Great Week service.
          </div>
          <button
            className={cn("button", styles.verifyBtn)}
            style={{ backgroundColor: '#490000', marginTop: 20, width: 150 }}
            onClick={() => reverifyHandler(setIsLoading)}
          >
            {isLoading ?
              <Spinner loading={isLoading} size={20} />
              :
              "Verify Now"
            }
          </button>
          <div className={styles.para}></div>
        </div>
        {children}
      </div>
      <div className={styles.overlay} onClick={() => setVisible(false)}></div>
    </div>
  );
};

export default DeleteModal;
