import DownloadProject from "./DownloadProject";
import PropertiesList from "./ProjectOverview";
import { useParams, withRouter } from "react-router";

const Projects = (props) => {
    const { id } = useParams();
    const showLenders = props.location?.state?.showLenders;

    return (
        <>
            {/* <DownloadProject /> */}
            <PropertiesList id={id} showLenders={showLenders} />
        </>
    );
};

export default withRouter(Projects);
