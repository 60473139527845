import React from 'react';
import { formatCentsAmount } from '../../utils/utils';

const AmountInCents = ({ amountInCents, subtitleEmValue, currency, isReversed }) => {

    let centsPart = amountInCents % 100;
    if (centsPart < 0) centsPart *= -1;
    let formattedString = formatCentsAmount(amountInCents, currency);

    let centsPartString = centsPart.toString().padStart(2, "0");
    let centsPartIdx = 0;
    if(formattedString && formattedString.lastIndexOf)
        centsPartIdx = formattedString.lastIndexOf(centsPartString) - 1;

    if (centsPartIdx > 0) {

       formattedString = formattedString.substring(0, centsPartIdx) +
            '<div style=" display: inline; font-size: ' + subtitleEmValue + '; margin-left: 2px;">' + formattedString.substring(centsPartIdx, centsPartIdx + 1 + centsPartString.length) + '</div>' +
            formattedString.substring(centsPartIdx + centsPartString.length + 1);

        formattedString = '<div style="font-size:1em">' + formattedString + '</div>';
    }

    let flexDir = 'row';
    if (isReversed) {
        flexDir = 'row-reverse';
    }

    return (
        <div style={{ display: 'flex', flexDirection: flexDir }}>
            {/* <div style={{ fontSize: "1em" }}> */}
                <div dangerouslySetInnerHTML={{__html: formattedString}} /> 
            {/* </div> */}
            {/* {subValue ?
                <div style={{ fontSize: subtitleEmValue, marginLeft: 2 }}>
                    {"." + subValue}
                </div>
                : null} */}
        </div >
    )
}

export default AmountInCents;