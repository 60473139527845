import React, { useState, useEffect } from "react";
import styles from "./Transactions.module.sass";
import cn from "classnames";
import Card from "../Card";
import Icon from "../Icon";
import { toTitleCase } from "../../utils/utils";
import Image from "../Image";

// date
import { dateFormatter } from "../../utils/utils";
import { cardTransactions } from "../../utils/apiCallHanlder";
import Spinner from "../../utils/spinner";
import { useHistory } from "react-router";
import Amount from "../Amount";


const Transactions = ({
  className,
  id,
  onClickHandler
}) => {
  const [loading, setLoading] = useState(false);
  const [lastId, setLastId] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [isNextPageExist, setIsNextPageExist] = useState(false);
  const [currentSpendValue, setCurrentSpendValue] = useState(0);
  const [currentSpend, setCurrentSpend] = useState(0);

  const history = useHistory();

  useEffect(() => {
    getUpdatedTransactions();
  }, [])

  const getUpdatedTransactions = async (lastid) => {
    setLoading(true);
    const { data } = await cardTransactions({
      id: id,
      currentSpendValue: currentSpendValue
    }, 100, lastid ? lastid : '')


    if (data) {
      setLastId(data?.transactions?.at(-1)?.id);
      setTransactions(data.transactions);
      setIsNextPageExist(data?.isNextPageExist);
      setCurrentSpend(data?.currentSpend);
    }
    setLoading(false);
  }

  const handleSingleTransaction = (x) => {
    onClickHandler(x?.id, x);
    // history.push({
    //   pathname: `/transactionDetails/${x?.id}`,
    //   state: {
    //     isFetchTransactionData: false,
    //     transactionPayload: x,
    //     accountType: x.accountType,
    //     accountId: x.accountId,
    //     status: x.status,
    //     amount: x.amount,
    //     desc: x.description
    //   }
    // });
  }


  return (
    <>
      <Card
        className={cn(styles.card, className)}
        classCardHead={styles.head}
        border
        title="Accounting"
        /* classTitle={cn("title-red", styles.title)} */
      >
        {loading ?
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Spinner size="44px" color="gray" loading={loading} />
          </div>
          :
          transactions?.length < 1 ? (
              <div className={styles.noDataImg}>
                <Image 
                  src={"/images/icons/empty-black.png"}
                  srcDark={"/images/icons/empty-white.png"}
                />
              </div>
          ) : (
          <>
            {/* <div className={styles.balanceWrapper}>
              <div className={styles.left}>
                <div className={styles.balance}>
                  <p>Current Spending</p>
                  <h2>{currentSpend}</h2>
                </div>
              </div>
            </div> */}

            <div className={styles.wrapper}>
              <div className={styles.table}>
                <div className={styles.row} style={{pointerEvents:'none'}}>
                  <div className={styles.col}>Date</div>
                  <div className={styles.col}>Description</div>
                  <div className={styles.col}>Transaction Type</div>
                  <div className={styles.col}>Card</div>
                  <div className={styles.col}>Status</div>
                  <div className={styles.col}>Attachments</div>
                  <div className={styles.col}>Amount</div>
                </div>
                {transactions.map((x, index) => (
                    <div onClick={() => handleSingleTransaction(x)} className={styles.row} key={index} style={{ cursor: 'pointer' }}>
                      <div className={styles.col}>
                        <div className={styles.label}>Date</div>
                        {dateFormatter(x.created)}
                      </div>
                      <div className={styles.col}>
                        <div className={styles.label}>Counter Party</div>
                        {x.description}
                      </div>
                      <div className={styles.col}>
                        <div className={styles.label}>Related to Order</div>
                        {x.accountType}
                      </div>
                      <div className={styles.col}>
                        <div className={styles.label}>Payment Type</div>
                        {x.cardLast4}
                      </div>
                      <div className={styles.col}>
                        <div className={styles.label}>ID</div>
                        <span className={cn(styles[x.status] || styles.draft)}>
                          {toTitleCase(x.status)}
                        </span>
                      </div>
                      <div className={styles.col}>
                        <div className={styles.label}>Attachments</div>
                        <span
                          className={cn({ [styles.none]: x.attachmentCount === "none" })}
                        >
                          {x.attachmentCount}
                        </span>
                      </div>
                      <div className={styles.col}>
                        <div className={styles.label}>Amount</div>
                        <Amount amount={x.amount} subtitleEmValue={"0.7em"}/>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </>
        )
        }
        {isNextPageExist ?
          <div className={styles.foot}>
            <button className={cn("button-stroke-red button-small", styles.button)} onClick={() => getUpdatedTransactions(lastId)}>
              <span>See More Transactions</span>
              <Icon name="arrow-next" size="20"></Icon>
            </button>
          </div>
          : null}
      </Card>
    </>
  );
};

export default Transactions;
