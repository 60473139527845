import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { useHistory } from "react-router";
import Icon from "../../../Icon";
import AlternativeAvatar from "../../../AlternativeAvatar";

const Item = ({ className, item, setVisible, redirectUrl, resetSearch, onClickHandler }) => {
  const history = useHistory();

  let icon = item.imageUrl || item.icon;

  return (
    <div className={cn(styles.item, className)}>
      <div className={styles.link} onClick={() => {
        setVisible(false);
        resetSearch && resetSearch();
        if (onClickHandler)
          onClickHandler(item);
        else
          history.push(redirectUrl)
      }}>
        <div className={cn(styles.userImage, styles.preview, styles.showUserImg)}>
          {icon
            ?
            (<img srcSet={`${item.image2x} 2x`} className={styles.avatar} src={item.icon || item.imageUrl || "/images/placeholder.png"} alt="Product" />)
            :
            (<AlternativeAvatar name={
              item.type === "transaction" ? "T" :
              item.type === "contract" ? "C" : 
               item.name
            } />)}
        </div>
        <div className={styles.details}>
          <div className={styles.content}>{item.name}</div>
          <div className={styles.title}>{item.name2}</div>
        </div>
      </div>
      {/* <button className={styles.close}>
        <Icon name="close" size="24" />
      </button> */}
    </div>
  );
};

export default Item;
