import React, { useEffect, useState } from "react";
import styles from "./EditNewCustomer.module.sass";
import Icon from "../../../components/Icon";
import Card from "../../../components/Card";
import cn from "classnames";
import AccountInfo from "./AccountInfo";
import DeleteModal from "../../../components/DeleteModal";
import Spinner from "../../../utils/spinner";
import OutsideClickHandler from "react-outside-click-handler";
import {
  addInvoice,
  getContractById,
  updateContact,
  addContact,
  deleteContact,
  getContactEmails,
} from "../../../utils/apiCallHanlder";
import MainCustomerContainer from "../index";
import { toast, ToastContainer } from "react-toastify";
import { toastConfiguration, trackIntercomEvent } from "../../../utils/utils";
import { Redirect, useHistory } from "react-router";
import Emails from "./Emails";
import Events from "./Events";
import Geocode from "react-geocode";
import Payments from "./Payments";
import Documents from "./Documents";
import Contracts from "./Contract";
import Questioners from "./Questioners";
import Dropdown from "../../../components/Dropdown";
import DocumentOverviewPopup from "../../Accounting/Documents/DocumentOverviewPopup";
import SendNewEmail from "../../Studio/Email/SendNewEmail";
import NewContract from "./Contract/NewContract";
import NewQuestioners from "./Questioners/NewQuestioners";
import { FetchAddress } from "../../../components/FetchAddress";
import LargeNav from "../../../components/LargeNav";
import Modal from "../../../components/Modal";
import AlternativeAvatar from "../../../components/AlternativeAvatar";
import Checkbox from "../../../components/Checkbox";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

let haveDropdownsData = false;

let eins = [""];
let countries = [""];
let countryCodes = [""];
let states = [""];

const customerInfos = [
  "Details",
  "Emails",
  "Events",
  "Payments",
  "Documents",
  "Contracts",
  "Questionnaires",
];

const accountHolderTypeOptions = ["Individual", "Company"];

const EditNewCustomer = ({
  id,
  isAdd,
  comingFromModal,
  searchString,
  nameClickHandler,
  activeCustomerTab,
  templateId,
  questionnaireId,
  className,
  isComingFromRecipient,
  setIsCustomer,
  selectedTypeForAddNewContact,
}) => {
  if (!haveDropdownsData) {
    const customerLookupdata = JSON.parse(localStorage.getItem("lookupData"));
    let data = null;
    if (customerLookupdata) {
      data = customerLookupdata?.data;
      eins = data?.Customer_TaxId;
      countryCodes = data?.MobileCountries;

      //embedding default Value
      countries = [
        {
          display: "Select Country",
          text: "Select Country",
          value: "select",
        },
      ].concat(data.Countries);

      states = [
        {
          display: "Select State",
          text: "Select State",
          value: " ",
        },
      ].concat(data.US_States);

      haveDropdownsData = true;
    }
  }

  //states
  const [visible, setVisible] = useState(true);
  const [showPaymentBtn, setShowPaymentBtn] = useState(false);
  const [showNewDocModal, setShowNewDocModal] = useState(false);
  const [startLoading, setStartLoading] = useState(true);
  const [showNewEventModal, setShowNewEventModal] = useState(false);
  const [showSendNewEmail, setShowSendNewEmail] = useState(false);
  const [customerId, setCustomerId] = useState(undefined);
  const [updateDocumentObject, setUpdateDocumentObject] = useState(false);
  const [getUpdatedContract, setUpdatedContract] = useState(false);
  const [accountHolderType, setAccountHolderType] = useState(
    accountHolderTypeOptions[0]
  );

  //contract states
  const [showNewContract, setShowNewContract] = useState(
    templateId ? true : false
  );
  const [editMode, setEditMode] = useState(true);
  const [templateName, setTemplateName] = useState("");
  const [createdOn, setCreatedOn] = useState(null);
  const [link, setLink] = useState(null);
  const [viewedOn, setViewedOn] = useState(null);
  const [signedOn, setSignedOn] = useState(null);

  //Questioners data
  const [showNewQuestioners, setShowNewQuestioners] = useState(
    questionnaireId ? true : false
  );
  const [answeredOn, setAnsweredOn] = useState(false);

  //Account States
  const [name, setName] = useState(searchString || "");
  const [accountEmail, setAccountEmail] = useState("");
  Geocode.setApiKey("AIzaSyCTpbuCUVv_C2RRr09oRimIlrjGzcP9C-0");

  //Billing States
  // const [billingEmail, setBillingEmail] = useState("");
  const [country, setCountry] = useState("select");
  const [mobileCode, setMobileCode] = useState(countryCodes[0]?.value);
  // const [billingTick, setBillingTick] = useState(true)
  const [mobile, setMobile] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState(null);
  const [bookTransactionExists, setBookTransactionExists] = useState(false);

  const [state, setState] = useState(states[0]?.value);

  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");

  const [isAddCustomer, SetIsAddCustomer] = useState(true);

  const [imageUrl, setImageUrl] = useState("");

  //shipping states
  let [shippingCountry, setShippingCountry] = useState("select");
  let [shippingAddress1, setShippingAddress1] = useState("");
  let [shippingAddress2, setShippingAddress2] = useState("");
  let [shippingCity, setShippingCity] = useState("");
  let [shippingZip, setShippingZip] = useState(null);
  let [shippingState, setShippingState] = useState(states[0]?.value);
  const [shippingTick, setShippingTick] = useState(true);

  //tax States
  const [taxId, setTaxId] = useState("");
  const [existingContacts, setExistingContacts] = useState([]);

  const [invoiceLoading, setInvoiceLoading] = useState(false);

  //error States
  const [error, setError] = useState("");
  const [serverError, setServerError] = useState(null);
  const [nameError, setNameError] = useState("error");
  const [loading, setLoading] = useState(false);

  const [doContactExists, setDoContactExist] = useState(false);

  const [emails, setEmails] = useState([]);
  const [isNextPageExists, setIsNextPageExists] = useState(false);
  const [nextPageInfo, setNextPageInfo] = useState(null);

  const [startLoadingEmails, setStartLoadingEmails] = useState(true);

  const [currentlySelectedDocument, setCurrentlySelectedDocument] =
    useState(null);
  const [getUpdatedQuestionnaire, setGetUpdatedQuestionnaire] = useState(false);

  const [type, setType] = useState(
    selectedTypeForAddNewContact ? selectedTypeForAddNewContact : "Customer"
  );

  const history = useHistory();

  const [isBoth, setIsBoth] = useState(false);

  //customer nav state
  const [activeIndex, setActiveIndex] = useState(1);
  const [activeCustomerInfo, setActiveCustomerInfo] = useState(
    activeCustomerTab || 1
  );
  const [customerInfo, setCustomerInfo] = useState(customerInfos[0]);

  const [autocompleteRef, setAutocompleteRef] = useState(null);

  useEffect(() => {
    if (country === "select") {
      setCity("");
      setState(states[0]?.value);
      setZip("");
      if (autocompleteRef) {
        autocompleteRef.current.clearValue();
      }
      setAddress1("");
    } else {
      setCity("");
      setState(states[0]?.value);
      setZip("");
      setAddress1("");
    }
    if (country === "US") {
      setState(states[0]?.value);
    }
  }, [country]);

  useEffect(() => {
    if (!address1) {
      setCity("");
      setCountry(country);
      setState(states[0]?.value);
      setZip("");
    }

    if (!shippingAddress1) {
      setShippingCity("");
      setShippingCountry(shippingCountry);
      setShippingState(states[0]?.value);
      setShippingZip("");
    }
  }, [address1, shippingAddress1]);

  useEffect(() => {
    if (isComingFromRecipient) {
      setType("Recipient");
    } else if (comingFromModal) {
      setType("Customer");
    }
  }, [isComingFromRecipient, comingFromModal]);

  useEffect(() => {
    if (templateId) {
      setShowNewContract(true);
    }
    if (questionnaireId) {
      setShowNewQuestioners(true);
    }
  }, [templateId, questionnaireId]);

  useEffect(() => {
    setActiveIndex(parseInt(activeCustomerInfo));
  }, [activeCustomerInfo]);

  useEffect(async () => {
    setStartLoading(true);
    if (id && id !== "add") {
      const { data } = await getContractById({ id });
      if (data) {
        const account = data?.contact;

        //For Recipients Only
        setAccountNumber(account.accountNumber);
        setRoutingNumber(account.accountRouting);

        setType(account?.type || account?.accountHolderType);
        setAccountHolderType(
          account?.accountHolderType || accountHolderTypeOptions[0]
        );

        //Account data
        setName(account.name);
        setAccountEmail(account.email);
        setImageUrl(account?.imageUrl);
        setCustomerId(account?.id);

        setIsBoth(account?.isBoth);

        //Billing data
        // setBillingEmail(account.billingEmail);
        setCountry(
          account.addressCountry !== ""
            ? account.addressCountry
            : countries[0]?.value
        );
        // setBillingTick(account.isBillingEmailSameAsAccount);
        setShippingTick(account.isShippingSameAsBilling);
        setMobileCode("+");
        setMobile(account?.mobile);
        setAddress1(account.addressLine1);

        setAddress2(account.addressLine2);
        setCity(account.addressCity);
        setState(
          account.addressState !== "" ? account.addressState : states[0]?.value
        );
        setZip(account.addressPostalCode);

        //Shipping data
        setShippingCountry(
          account.shippingAddressCountry !== ""
            ? account.shippingAddressCountry
            : countries[0]?.value
        );
        setShippingAddress1(account.shippingAddressLine1);

        setShippingAddress2(account.shippingAddressLine2);
        setShippingCity(account.shippingAddressCity);
        setShippingState(
          account.shippingAddressState !== ""
            ? account.shippingAddressState
            : states[0]?.value
        );
        setShippingZip(account.shippingAddressPostalCode);
        setTaxId(account.taxIdNumber);
        setStartLoading(false);
      }
    } else {
      setStartLoading(false);
    }
  }, [id]);

  //onChange Hanlder
  const setValueHanlder = (value, valueSetter) => {
    valueSetter(value);
  };
  useEffect(() => {
    if (mobile === undefined) {
      setMobile("");
    }
  }, [mobile]);
  const saveButtonClickHandler = async (forcedValidationRemoved = false) => {
    setBookTransactionExists(false);
    if (!loading) {
      if (
        name === "" ||
        !name?.replace(/\s/g, "")?.length ||
        name.trim() === ""
      ) {
        window.scrollTo(0, 0);
        toast.error("Please Provide Name", toastConfiguration);
      } else if (name && name.length >= 200) {
        window.scrollTo(0, 0);
        toast.error("Name length in 200", toastConfiguration);
      } else if (accountEmail === "") {
        window.scrollTo(0, 0);
        toast.error("Please Provide Email", toastConfiguration);
      } else if (!accountEmail && comingFromModal) {
        setError("Email is required.");
      } else if (accountEmail.length > 200) {
        window.scrollTo(0, 0);
        toast.error("Email Field had 200 characters", toastConfiguration);
      } else if (error === "Email is invalid") {
        setError("Please provide Valid Email");
      } else {
        if (shippingTick) {
          shippingCity = city;
          shippingCountry = country === "select" ? "" : country;
          shippingAddress1 = address1;
          shippingAddress2 = address2;
          shippingZip = zip;
          shippingState = state === "select" ? "" : state;
        }

        const newCustomerPayload = {
          name: name,
          email: accountEmail,
          imageUrl: imageUrl,
          type: type,
          accountHolderType: accountHolderType?.toLowerCase(),
          accountRouting: routingNumber,
          accountNumber: accountNumber,
          // billingEmail: billingTick ? accountEmail : billingEmail,
          addressCountry: country === "select" ? "" : country,
          mobile: "+" + mobile?.length <= 4 ? "" : mobile,
          addressLine1: address1,
          addressLine2: address2,
          addressCity: city,
          addressPostalCode: zip,
          isShippingSameAsBilling: shippingTick,
          addressState: state === "select" ? "" : state,
          shippingAddressCountry:
            shippingCountry === "select" ? "" : shippingCountry,
          shippingAddressLine1: shippingAddress1,
          shippingAddressLine2: shippingAddress2,
          shippingAddressCity: shippingCity,
          shippingAddressPostalCode: shippingZip,
          shippingAddressState: shippingState === "select" ? "" : shippingState,
          //taxIdType: selectEin,
          taxIdNumber: taxId,
        };

        if (id) {
          newCustomerPayload.id = id;
        }
        setLoading(true);

        const newCustomerResponse = id
          ? await updateContact(newCustomerPayload)
          : await addContact(
              newCustomerPayload,
              forcedValidationRemoved ? false : true
            );

        const { data, error } = newCustomerResponse;
        if (data) {
          if (data?.isContactNameExists) {
            setExistingContacts(data?.contacts);
            setDoContactExist(true);
            setLoading(false);
            return;
          }
          if (!id) trackIntercomEvent("contact-created");
          toast.success("Successfully saved contact data", toastConfiguration);
          if (comingFromModal) {
            nameClickHandler(
              data?.contact?.name,
              data?.contact?.id,
              data?.contact?.imageUrl,
              data?.contact?.email,
              data?.contact?.phone
            );
            setTimeout(() => {
              setLoading(false);
            }, 3000);
          } else if (isComingFromRecipient) {
            history.push({
              pathname: "/transactions/send",
              state: { recipient: data?.contact },
            });
          } else {
            if (type === "Recipient") {
              SetIsAddCustomer(false);
              setIsCustomer && setIsCustomer(false);
            } else {
              SetIsAddCustomer(true);
              setIsCustomer && setIsCustomer(true);
            }

            setVisible(false);
            setLoading(false);
          }
        } else {
          toast.error("Error in saving contact data", toastConfiguration);
          //setServerError(newCustomerResponse.error);
          setLoading(false);
        }
      }
    }
  };

  const onChange = (setValue, valueToSet) => {
    setValue(valueToSet);
  };
  const handleAddressChange = async (value, name) => {
    let address = value.label;
    FetchAddress(
      address,
      name,
      setCity,
      setCountry,
      setZip,
      setState,
      setShippingCity,
      setShippingCountry,
      setShippingState,
      setShippingZip
    );
  };

  const resetStates = (countryReset) => {
    setState(states[0]?.value);
    setAddress1("");
    setCity("");
    setZip("");
    const divToClear = document.getElementsByClassName(
      " css-1uccc91-singleValue"
    )[0];
    if (divToClear) {
      divToClear.innerHTML = "";
    }
    if (countryReset) resetShippingStates();
  };

  const resetShippingStates = () => {
    setShippingState(states[0]?.value);
    setShippingAddress1("");
    setShippingCity("");
    setShippingZip("");
    const divToClear = document.getElementsByClassName(
      " css-1uccc91-singleValue"
    )[1];
    const secondDivToClear = document.getElementsByClassName(
      " css-1uccc91-singleValue"
    )[0];
    if (divToClear) {
      divToClear.innerHTML = "";
    } else if (secondDivToClear) {
      secondDivToClear.innerHTML = "";
    }
  };

  const getEmails = async (doConcat) => {
    setStartLoadingEmails(true);
    if (id) {
      const { data } = await getContactEmails({
        id,
        nextPageInfo: !doConcat ? null : nextPageInfo,
        PageSize: 15,
      });
      if (data) {
        if (data.emails) {
          setIsNextPageExists(data?.isNextPageExists);
          if (doConcat) {
            setEmails(emails.concat(data.emails));
          } else {
            setEmails(data?.emails);
          }
          setNextPageInfo(data?.nextPageInfo);
        }
      }
    }
    setStartLoadingEmails(false);
  };

  return !showNewContract && !showNewQuestioners ? (
    <>
      <Modal
        visible={doContactExists}
        onClose={() => {
          setDoContactExist(false);
        }}
        outerClassName={styles.outer}
      >
        <Card
          className={styles.card}
          title={
            bookTransactionExists ? "Transaction Exists" : "Contact Exists"
          }
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            {bookTransactionExists
              ? "Contact has existing booked transaction, Unable to delete account which has booked transactions."
              : "Looks like you already have a contact with that name. Are you sure you wish to create a new contact?"}
            {!bookTransactionExists &&
              existingContacts?.map((item) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 12,
                    }}
                  >
                    <AlternativeAvatar
                      name={item?.name || ""}
                      className={"small-icon"}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ lineHeight: 1 }}>{item?.name}</span>
                      <span style={{ color: "gray", fontSize: 12 }}>
                        {item?.email}
                      </span>
                    </div>
                  </div>
                );
              })}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 36,
                gap: 12,
              }}
            >
              <button
                className="button-stroke"
                onClick={() => setDoContactExist(false)}
                style={{ width: 192 }}
              >
                Cancel
              </button>
              {!bookTransactionExists && (
                <button
                  className="button"
                  onClick={() => {
                    setDoContactExist(false);
                    saveButtonClickHandler(true);
                  }}
                >
                  Create Contact Anyway
                </button>
              )}
            </div>
          </div>
        </Card>
      </Modal>

      {!startLoading ? (
        visible ? (
          <>
            <ToastContainer />
            <Card
              title="Contact Info"
              border
              /* classTitle="title-blue" */
              className={cn(
                comingFromModal ? styles.marginCard : styles.card,
                className
              )}
            >
              <AccountInfo
                setName={setName}
                id={id}
                setAccountEmail={setAccountEmail}
                setValueHandler={setValueHanlder}
                setError={setError}
                isComingFromRecipient={isComingFromRecipient}
                type={type}
                setType={setType}
                setNameError={setNameError}
                error={error}
                mobile={mobile}
                setMobile={setMobile}
                nameError={nameError}
                name={name}
                email={accountEmail}
                imageUrl={imageUrl}
                setImageUrl={setImageUrl}
                activeNavIndex={activeIndex}
                setActiveNavIndex={setActiveIndex}
                comingFromModal={comingFromModal}
                country={country}
                setCountry={setCountry}
                countries={countries}
                address1={address1}
                setAddress1={setAddress1}
                resetStates={resetStates}
                handleAddressChange={handleAddressChange}
                taxId={taxId}
                setTaxId={setTaxId}
                accountNumber={accountNumber}
                setAccountNumber={setAccountNumber}
                onChange={onChange}
                customerNav={
                  <>
                    {id && (
                      <LargeNav
                        tabOptions={customerInfos}
                        activeTab={activeIndex - 1}
                        onClick={(x, index) => {
                          setActiveIndex(index + 1);
                          setCustomerInfo(customerInfos[index]);
                          history.push(`/contacts/${customerId}/${index + 1}`);
                        }}
                      />
                    )}
                    {/* <div className={styles.mobileNav}>
                      <Dropdown
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        value={customerInfo}
                        handleChange={(x, index) => {
                          setActiveIndex(index + 1);
                          history.push(`/contacts/${customerId}/${index + 1}`);
                        }}
                        setValue={setCustomerInfo}
                        options={customerInfos}
                        small
                      />
                    </div> */}
                  </>
                }
              />
              {activeIndex === 1 ? (
                <>
                  <div className={styles.row}>
                    <div className={styles.shippingWrap}>
                      <div className={styles.fieldLabel}>Shipping Details</div>
                      <div className={styles.checkStatus}>
                        <Checkbox
                          className={styles.checkbox}
                          classCheckboxTick={styles.checkboxTick}
                          value={shippingTick}
                          onChange={() => {
                            !shippingTick && resetShippingStates();
                            setShippingTick(!shippingTick);
                          }}
                        />
                        <span>Same as billing details</span>
                      </div>
                    </div>
                  </div>
                  {!shippingTick && (
                    <div className={styles.row}>
                      <div
                        className={styles.col}
                        style={{ position: "relative" }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: 1.71429,
                            color: "#C7C5BF",
                          }}
                        >
                          Address
                        </div>
                        <GooglePlacesAutocomplete
                          autocompletionRequest={{
                            componentRestrictions: { country: [country] },
                            types: ["geocode"],
                          }}
                          selectProps={{
                            placeholder: shippingAddress1
                              ? shippingAddress1
                              : "Enter Address",
                            formatOptionLabel: ({ label, description }) => (
                              <div>
                                <span>{label}</span>
                                <span>{description}</span>
                              </div>
                            ),
                            styles: {
                              control: (provided, state) => ({
                                ...provided,
                                fontFamily: "tomatoGrotesk, sans-serif",
                                fontSize: "15px",
                                fontWeight: 600,
                                lineHeight: "1.6",
                                color: "#464542",
                                transition: "all .2s",
                                backgroundColor: "#E9E7E0",
                                border: "2px solid #C7C5BF",
                                color: "#46455A",
                                borderRadius: "0px",
                                height: "48px",
                                borderColor: state.isFocused
                                  ? "#C7C5BF"
                                  : "#C7C5BF",
                                boxShadow: "none",
                                "&:hover": {
                                  borderColor: "#C7C5BF",
                                  backgroundColor: "#ffffff"
                                },
                                padding: "0 8px"
                              }),
                              input: (provided) => ({
                                ...provided,
                                width: "33%",
                                height: "100%",
                                fontFamily: "tomatoGrotesk, sans-serif",
                                fontSize: "15px",
                                fontWeight: 600,
                                lineHeight: "1.6",
                                color: "#464542",
                                transition: "all .2s",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#464542",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                borderRadius: 0,
                                background: "#fff",
                                boxShadow:
                                  "inset 0 0 0 2px #EFEFEF, 0 4px 12px rgba(244, 244, 244, 0.1)",
                              }),
                              option: (proviced) => ({
                                ...proviced,
                                fontFamily: "tomatoGrotesk, sans-serif",
                                fontSize: "15px",
                                fontWeight: 600,
                                lineHeight: "1.6",
                                color: "#464542",
                                transition: "all .2s",
                              }),
                            },

                            address1,
                            onChange: (e) => {
                              setShippingAddress1(e.label);
                              handleAddressChange(e, "address");
                            },
                          }}
                        ></GooglePlacesAutocomplete>
                        <button
                          className={styles.close}
                          onClick={resetShippingStates}
                        >
                          <Icon name="close-circle" size="24" />
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : activeIndex === 2 ? (
                <>
                  {id ? (
                    <Emails
                      name={name}
                      id={id}
                      imageUrl={imageUrl}
                      email={accountEmail}
                      mobileCode={mobileCode}
                      mobile={mobile}
                      emails={emails}
                      setEmails={setEmails}
                      nextPageInfo={nextPageInfo}
                      setIsNextPageExists={setIsNextPageExists}
                      setNextPageInfo={setNextPageInfo}
                      isNextPageExists={isNextPageExists}
                      startLoading={startLoadingEmails}
                      setStartLoading={setStartLoadingEmails}
                      getEmails={getEmails}
                    />
                  ) : null}
                </>
              ) : activeIndex === 3 ? (
                <>
                  {id ? (
                    <Events
                      id={id}
                      name={name}
                      email={accountEmail}
                      mobileCode={mobileCode}
                      mobile={mobile}
                      imageUrl={imageUrl}
                      setShowNewEventModal={setShowNewEventModal}
                      showNewEventModal={showNewEventModal}
                    />
                  ) : null}
                </>
              ) : activeIndex === 4 ? (
                <>
                  {id ? (
                    <Payments
                      id={id}
                      imageURL={imageUrl}
                      name={name}
                      email={accountEmail}
                      mobileCode={mobileCode}
                      mobile={mobile}
                    />
                  ) : null}
                </>
              ) : activeIndex === 5 ? (
                <>
                  {id ? (
                    <Documents
                      name={name}
                      imageURL={imageUrl}
                      email={accountEmail}
                      mobileCode={mobileCode}
                      mobile={mobile}
                      customerId={id}
                      updateDocumentObject={updateDocumentObject}
                      setCurrentlySelectedDocument={
                        setCurrentlySelectedDocument
                      }
                      setShowNewDocModal={setShowNewDocModal}
                    />
                  ) : null}
                </>
              ) : activeIndex === 6 ? (
                <>
                  {id ? (
                    <Contracts
                      name={name}
                      imageURL={imageUrl}
                      email={accountEmail}
                      mobileCode={mobileCode}
                      mobile={mobile}
                      customerId={id}
                      id={id}
                      setTemplateName={setTemplateName}
                      setLink={setLink}
                      getUpdatedContract={getUpdatedContract}
                      setCreatedOn={setCreatedOn}
                      setViewedOn={setViewedOn}
                      setSignedOn={setSignedOn}
                      onClick={() => {
                        setShowNewContract(true);
                        setEditMode(false);
                      }}
                    />
                  ) : null}
                </>
              ) : activeIndex === 7 ? (
                <>
                  {id ? (
                    <Questioners
                      name={name}
                      imageURL={imageUrl}
                      email={accountEmail}
                      mobileCode={mobileCode}
                      mobile={mobile}
                      id={id}
                      customerId={id}
                      setTemplateName={setTemplateName}
                      setLink={setLink}
                      setCreatedOn={setCreatedOn}
                      setAnsweredOn={setAnsweredOn}
                      getUpdatedQuestionnaire={getUpdatedQuestionnaire}
                      onClick={() => {
                        setShowNewQuestioners(true);
                        setEditMode(false);
                      }}
                    />
                  ) : null}
                </>
              ) : null}

              {activeIndex === 1 ? (
                <div className={styles.actionCard}>
                  {comingFromModal ? null : (
                    <button
                      onClick={() => setVisible(false)}
                      className={cn("button-stroke-red", styles.button)}
                    >
                      <span>Cancel</span>
                    </button>
                  )}
                  {id ? (
                    <DeleteModal
                      className={styles.deleteBtn}
                      id={id}
                      deleteFunction={deleteContact}
                      //setShowMainContainer={setVisible}
                      onConfirm={(data) => {
                        if (data?.bookedTransactionsExists) {
                          setDoContactExist(true);
                          setBookTransactionExists(true);
                          return;
                        }
                        setVisible(false);
                      }}
                      setValue={false}
                      // deleteBtn={
                      //   <button className="button" onClick={() => {
                      //     setVisible(false)
                      //   }}>
                      //     Yes I'm sure!
                      //   </button>
                      // }
                    />
                  ) : null}
                  <button
                    onClick={() => saveButtonClickHandler(false)}
                    className={cn("button", styles.button)}
                    style={{
                      marginRight: comingFromModal ? 50 : 0,
                    }}
                  >
                    {loading ? (
                      <Spinner loading={loading} size={15} />
                    ) : (
                      <>Save</>
                    )}
                  </button>
                  {serverError !== "" ? (
                    <div className={styles.errorContainer}>
                      <span className={styles.errorMessage}>{serverError}</span>
                    </div>
                  ) : null}
                </div>
              ) : activeIndex === 2 ? (
                <div className={styles.actionCard}>
                  <button
                    className="button"
                    onClick={() => setShowSendNewEmail(true)}
                  >
                    Send new email
                  </button>
                </div>
              ) : activeIndex === 3 ? (
                <div className={styles.actionCard}>
                  <button
                    onClick={() => setShowNewEventModal(true)}
                    className="button"
                  >
                    Add new event
                  </button>
                </div>
              ) : activeIndex === 4 ? (
                <div className={styles.actionCard}>
                  <OutsideClickHandler
                    onOutsideClick={() => setShowPaymentBtn(false)}
                  >
                    <div
                      className={cn(styles.dotsBtn, {
                        [styles.active]: showPaymentBtn,
                      })}
                    >
                      <div className={styles.head}>
                        <button
                          className={cn("button", styles.btn)}
                          onClick={() => setShowPaymentBtn(!showPaymentBtn)}
                        >
                          Add new payment
                          {showPaymentBtn ? (
                            <Icon name="arrow-up" size="24" />
                          ) : (
                            <Icon name="arrow-down" size="24" />
                          )}
                        </button>
                      </div>
                      <div className={styles.actionBody}>
                        <button
                          className={styles.actionItem}
                          onClick={async () => {
                            setInvoiceLoading(true);
                            const { data, error } = await addInvoice({
                              customerId: id,
                              customerImage: imageUrl,
                            });
                            if (data) {
                              history.push(`/payments/${data?.invoice?.id}`);
                            } else {
                              toast.error(error, toastConfiguration);
                            }
                            setInvoiceLoading(false);
                          }}
                        >
                          <Icon name="document" size="24" />
                          Invoice
                          {invoiceLoading && (
                            <label style={{ marginLeft: 14, marginTop: 4 }}>
                              <Spinner size="16" color="gray" />
                            </label>
                          )}
                        </button>
                        <button
                          className={styles.actionItem}
                          onClick={() => {
                            history.push({
                              pathname: "/subscriptions/add",
                              state: { customerId: id },
                            });
                          }}
                        >
                          <Icon name="sync" size="24" />
                          Subscription
                        </button>
                        <button
                          className={styles.actionItem}
                          onClick={() => {
                            history.push({
                              pathname: "/paymentLinks",
                              state: { customerId: id },
                            });
                          }}
                        >
                          <Icon name="link" size="24" />
                          Payment Link
                        </button>
                        {isBoth && (
                          <button
                            className={styles.actionItem}
                            onClick={() => {
                              history.push({
                                pathname: "/transactions/send",
                                state: { recipientId: id },
                              });
                            }}
                          >
                            <Icon name="pay" size="20" viewBox="0 0 20 20" />
                            Pay to someone
                          </button>
                        )}
                      </div>
                    </div>
                  </OutsideClickHandler>
                </div>
              ) : activeIndex === 5 ? (
                <div className={styles.actionCard}>
                  <button
                    onClick={() => {
                      setCurrentlySelectedDocument(null);
                      setShowNewDocModal(true);
                    }}
                    className="button"
                  >
                    Add new document
                  </button>
                </div>
              ) : activeIndex === 6 ? (
                <div className={styles.actionCard}>
                  <button
                    onClick={() => {
                      setShowNewContract(true);
                    }}
                    className="button"
                  >
                    Start new contract
                  </button>
                </div>
              ) : activeIndex === 7 ? (
                <div className={styles.actionCard}>
                  <button
                    onClick={() => setShowNewQuestioners(true)}
                    className="button"
                  >
                    Create new questioners
                  </button>
                </div>
              ) : null}
            </Card>
          </>
        ) : (
          <>
            <ToastContainer />
            {isAdd ? (
              <MainCustomerContainer isAddRecipient={!isAddCustomer} />
            ) : (
              <Redirect to="/contacts" exact />
            )}
          </>
        )
      ) : (
        <div
          className={styles.spinner}
          style={{ height: window.outerHeight - 250 }}
        >
          <Spinner loading={startLoading} size={150} color={"gray"} />
        </div>
      )}
      {showNewDocModal ? (
        <DocumentOverviewPopup
          showNewDocModal={showNewDocModal}
          setShowNewDocModal={setShowNewDocModal}
          customerId={id}
          customerName={name}
          setUpdateDocumentObject={setUpdateDocumentObject}
          updateDocumentObject={updateDocumentObject}
          currentlySelectedDocument={currentlySelectedDocument}
          setCurrentlySelectedDocument={setCurrentlySelectedDocument}
        />
      ) : null}
      {/* <AddEditEvent
          forceRefreshEvents={handleReload}
          editEvent={editEvent}
          showNewEventModal={showNewEventModal}
          setShowNewEventModal={setShowNewEventModal}
          isSearch={true}
          id={id}
          customerName={name}
          customerImageUrl={imageUrl}
        /> */}
      <SendNewEmail
        visible={showSendNewEmail}
        setVisible={setShowSendNewEmail}
        customerId={id}
        toMail={accountEmail || ""}
        getEmails={getEmails}
        setEmails={setEmails}
      />
    </>
  ) : showNewContract && !showNewQuestioners ? (
    <NewContract
      templateId={templateId}
      customerId={id}
      imageURL={imageUrl}
      name={name}
      email={accountEmail}
      mobileCode={mobileCode}
      mobile={mobile}
      setShowNewContract={setShowNewContract}
      editMode={editMode}
      setEditMode={setEditMode}
      templateName={templateName}
      link={link}
      createdOn={createdOn}
      viewedOn={viewedOn}
      getUpdatedContract={getUpdatedContract}
      setUpdatedContract={setUpdatedContract}
      signedOn={signedOn}
    />
  ) : !showNewContract && showNewQuestioners ? (
    <NewQuestioners
      customerId={id}
      imageURL={imageUrl}
      name={name}
      email={accountEmail}
      mobileCode={mobileCode}
      mobile={mobile}
      setShowNewQuestioners={setShowNewQuestioners}
      editMode={editMode}
      setEditMode={setEditMode}
      templateName={templateName}
      link={link}
      createdOn={createdOn}
      answeredOn={answeredOn}
      getUpdatedQuestionnaire={getUpdatedQuestionnaire}
      setGetUpdatedQuestionnaire={setGetUpdatedQuestionnaire}
      questionnaireId={questionnaireId}
    />
  ) : null;
};

export default EditNewCustomer;
