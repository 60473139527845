import React, { useState } from "react";
import cn from "classnames";
import styles from "./addNew.module.sass";
import Icon from "../../../components/Icon";
import Dropdown from "../../../components/Dropdown_c";
import TextInput from "../../../components/TextInput";
import Spinner from "../../../utils/spinner";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router";
import { toastConfiguration } from "../../../utils/utils";
import Item from "../Item";
import {
  addTaxRateValues,
  updateTaxRateValues,
} from "../../../utils/apiCallHanlder";
import { useEffect } from "react";
import Card from "../../../components/Card";

let haveDropdownsData = false;
let roles = [""];
const taxTypes = [
  {
    text: "GST",
    displayName: "GST",
    value: "gst",
  },
  {
    text: "HST",
    displayName: "HST",
    value: "hst",
  },
  {
    text: "JCT",
    displayName: "JCT",
    value: "jct",
  },
  {
    text: "PST",
    displayName: "PST",
    value: "pst",
  },
  {
    text: "QST",
    displayName: "QST",
    value: "qst",
  },
  {
    text: "RST",
    displayName: "RST",
    value: "rst",
  },
  {
    text: "Sales Tax",
    displayName: "Sales Tax",
    value: "sales_tax",
  },
  {
    text: "Vat",
    displayName: "Vat",
    value: "vat",
  },
];

const rateTypes = [
  {
    text: "Inclusive",
    displayName: "Inclusive",
    value: "inclusive",
  },
  {
    text: "Exlusive",
    displayName: "Exlusive",
    value: "exclusive",
  },
];

const salesTaxes = [
  {
    display: "Yes - Tax is included in price",
    text: "Yes - Tax is included in price",
    value: "inclusive",
  },
  {
    display: "No - Tax is excluded in price",
    text: "No - Tax is excluded in price",
    value: "exclusive",
  },
];

const AddTaxRate = ({ className, visibletax, setvisibletax }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [includeTax, setIncludeTax] = useState(salesTaxes[0]?.value);
  const [taxType, setTaxType] = useState(taxTypes[0]?.value);
  const [rateType, setRateType] = useState(rateTypes[0]?.value);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [rate, setRate] = useState("");

  const addTaxRateHandler = async () => {
    if (!name) {
      toast.error("Please enter name of tax rate", toastConfiguration);
      return;
    } else if (!rate) {
      toast.error("Rate field is empty", toastConfiguration);
      return;
    }
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const payload = {
      name: name,
      inclusive: includeTax === "inclusive",
      percentage: rate || 0,
      taxType: taxType,
    };

    const { data } = await addTaxRateValues(payload);
    if (data) {
      toast.success("Successfully added tax rate", toastConfiguration);
      setvisibletax(false);
      resetStates();
    } else {
      toast.error("Error in adding tax Rate", toastConfiguration);
    }

    setIsLoading(false);
  };

  const resetStates = () => {
    setDescription("");
    setTaxType(taxTypes[0]?.value);
    setRateType(rateTypes[0]?.value);
    setName("");
    setRate("");
  };

  return (
    <>
      <ToastContainer />
      <div style={{ display: "flex", justifyContent: "flex-end" }}></div>

      <Card
        className={cn(styles.card, className)}
        border
        title={"Tax Rate"}
        head={
          <div className={styles.buttonWrap}>
          <div
          className={cn("button-stroke-red", styles.backBtn)}
          onClick={() => {
            setvisibletax(false);
            // resetStates();
          }}
        >
         Cancel
        </div>
            <button
              onClick={addTaxRateHandler}
              className="button"
            >
              {isLoading ? (
                <Spinner loading={isLoading} size={15} />
              ) : (
                <>
                  <span>Save</span>
                </>
              )}
            </button>
          </div>
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "31%" }}>
            {" "}
            <TextInput
              label='Display Name'
              name='name'
              type='text'
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              required
            />
          </div>
          <div style={{ width: "31%" }}>
            <Dropdown
              label={"Type"}
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={taxType}
              setValue={setTaxType}
              options={taxTypes}
            />
          </div>
          <div style={{ width: "31%" }}>
            <TextInput
              label='Rate'
              percentage='percentage'
              name='rate'
              type='rate'
              value={rate}
              onChange={(event) => {
                setRate(event.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ width: '31%', marginTop: 12 }}>
          <Dropdown
            label="Include tax in price"
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={includeTax}
            setValue={setIncludeTax}
            options={salesTaxes}
            selectionStyle={{ paddingTop: 4 }}
            small
          />
        </div>
      </Card>
    </>
  );
};

export default AddTaxRate;
