import React, { useState } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import Spinner from "../../../utils/spinner";
import { useHistory } from "react-router";
import Icon from "../../../components/Icon";

const Entry = ({ onConfirm, error, setError, loading }) => {
  const [message, setMessage] = useState("");
  const [valid, setValid] = useState(null);

  const history = useHistory();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else if (isValidEmail(event.target.value)) {
      setError(null);
      setValid("Valid");
    } else {
      setError(null);
      setValid(null);
    }

    setMessage(event.target.value);
  };

  return (
    <div className={styles.entry}>
      <div className={styles.body}>
        <div className={styles.emailWrapper}>
          <TextInput
            className={error ? styles.hasError : styles.field}
            name="email"
            type="email"
            placeholder="Your email"
            required
            icon={error ? "arrow-left" : "mail"}
            value={message}
            onChange={handleChange}
          />
          {error ? (
            <Icon name="warning" className={styles.warningIcon} size="24" />
          ) : valid ? (
            <Icon name="check" className={styles.validIcon} size="24" />
          ) : (
            ""
          )}
        </div>
        <button
          className={cn(
            "button",
            error ? styles.disable : valid ? styles.button : styles.disable
          )}
          onClick={() => {
            if (!error) {
              onConfirm(message)
            }
          }}
        >
          {loading ?
            <Spinner size="18" color="white" />
            :
            "Continue"
          }
        </button>         
        {
          error && error !== "Email is invalid" ?
            <div className={styles.errorContainer}>
              <span className={styles.errorMessage}>
                {error}
              </span>
            </div>
            : null
        }
      </div>
    </div>
  );
};

export default Entry;
