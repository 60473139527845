import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import styles from "./Modal.module.sass";
import Icon from "../Icon";

const Modal = ({
  outerClassName,
  visible,
  onClose,
  children,
  buttonMargin,
  id,
  dontCloseOnClickOutside,
  modalClass,
  hideCloseButton,
  buttonClass
}) => {
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal");
      enableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  return createPortal(
    visible && (
      <div id='modal' className={cn(styles.modal, modalClass)}>
        <div className={cn(styles.outer, outerClassName)} id={id}>
          <OutsideClickHandler
            onOutsideClick={dontCloseOnClickOutside ? () => { } : onClose}
          >
            {children}
            {hideCloseButton ? null : (
              <button
                className={cn(styles.close, buttonClass)}
                onClick={onClose}
                style={buttonMargin && { top: buttonMargin }}
              >
                <Icon name='close' size='20' />
              </button>
            )}
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  );
};

export default Modal;
