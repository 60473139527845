import React, { useState } from "react";
import { useEffect } from "react";
import Card from "../../components/Card";
import AddNewCard from "./AddNewCard";
import styles from "./Cards.module.sass";
import CardDetails from "./CardDetails";
import "react-multi-carousel/lib/styles.css";
import cn from "classnames";
import { useHistory } from "react-router";
import { getCardDetails, getIssuingBalance } from "../../utils/apiCallHanlder";
import LargeNav from "../../components/LargeNav";
import CardsOverview from "./CardsOverview";
import DeleteMultiCards from "./CardsOverview/DeleteMultiCards";
import { gettingPermissionByFeature } from "../../utils/utils";
import Item from "../Home/Overview/Item";


const Cards = (props) => {
  const isArchived = props.location?.state?.isArchived;
  const [addNewCard, setAddNewCard] = useState(false);
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const [physicalCardArray, setPhysicalCardArray] = useState([]);
  const [virtualCardArray, setVirtualCardArray] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentlySelectedType, setCurrentlySelectedType] = useState(
    isArchived ? "Archived" : "Active"
  );
  const [activeIndex, setActiveIndex] = useState(1);
  const [deleteIdArray, setDeleteIdArray] = useState([]);
  const [activeCardCheckCount, setActiveCardCheckCount] = useState(0);
  const [archivedCardCheckCount, setArchivedCardCheckCount] = useState(0);
  const [visible, setVisible] = useState(false);
  const [connectLoading, setConnectLoading] = useState(true);
  const history = useHistory();
  const [issuingBalances, setIssuingBalances] = useState([]);

  const cardRights = gettingPermissionByFeature("Card");

  useEffect(async () => {
    setConnectLoading(false);

    const { data } = await getIssuingBalance();
    if (data && data.balances) {
      setIssuingBalances(data.balances);
    }
  }, []);

  const fetchDetail = async () => {
    setIsLoaded(false);
    let physicalArray = [];
    let virtualArray = [];
    let cards = null;
    let data = null;

    if (currentlySelectedType === "Active") {
      data = await getCardDetails({ onlyCard: true });
      cards = data?.data?.cards;
    } else {
      data = await getCardDetails({ onlyCanceledCard: true });
      cards = data?.data?.canceledCards;
    }

    if (data) {
      for (let i = 0; i < cards?.length; i++) {
        if (cards[i].type === "physical") {
          physicalArray.push(cards[i]);
        } else {
          virtualArray.push(cards[i]);
        }
      }

      physicalArray = getDefaultCartOnTop(physicalArray);
      virtualArray = getDefaultCartOnTop(virtualArray);

      setPhysicalCardArray(physicalArray);
      setVirtualCardArray(virtualArray);
    }

    setIsLoaded(true);
  };
  useEffect(() => {
    fetchDetail();
  }, [currentlySelectedType]);

  const getDefaultCartOnTop = (arrayToShift) => {
    let result = [];
    for (let i = arrayToShift.length - 1; i >= 0; i--) {
      if (arrayToShift[i]?.isDefaultCard) {
        result.push(arrayToShift[i]);
      } else {
        result.unshift(arrayToShift[i]);
      }
    }
    result.reverse();
    return result;
  };

  return (
    <>
      {!connectLoading && (
        <Card
          title={"Cards"}
          border
          /* classTitle={"title-blue"} */
          head={
            <div>
              <button className={cn("button-stroke-red")} style={{ marginRight: '8px' }}>Add to balance</button>
              {
                cardRights === 2 ? null : activeCardCheckCount > 0 ||
                  archivedCardCheckCount > 0 ? (
                  <>
                    <button
                      className={cn("button-stroke-red")}
                      onClick={() => setVisible(true)}
                    >
                      {activeCardCheckCount > 1 || archivedCardCheckCount > 1
                        ? "Delete Cards"
                        : "Delete Card"}
                    </button>
                    <DeleteMultiCards
                      fetchDetail={async () => {
                        setDeleteIdArray([]);
                        setActiveCardCheckCount(0);
                        await fetchDetail();
                      }}
                      visible={visible}
                      setVisible={setVisible}
                      id={deleteIdArray}
                    />
                  </>
                ) : (
                  <button
                    onClick={() =>
                      history.push({
                        pathname: "/accountsandcards/cards/createNew",
                        state: { isPhysical: false },
                      })
                    }
                    className='button'
                  >
                    Order new card
                  </button>
                )
              }
            </div>
          }
        >

          {!addNewCard && !showCardDetails ? (
            <div className={styles.cards}>
              <Card className={styles.card}>
                {issuingBalances.map((x, index) => {
                  return (
                    <Item
                      className={cn(
                        styles.balanceItem,
                        {
                          [styles["activeItem" + index]]: true,
                        }
                      )}
                      style={{
                        border: 'unset',
                      }}
                      key={index}
                      item={{
                        title: "Balance",
                        counter: x.amount ?? "0",
                      }}
                      currency={x.currency}
                    />
                  );
                })}
              </Card>
              <LargeNav
                tabOptions={["Active", "Canceled"]}
                activeTab={currentlySelectedType}
                value=' '
                onClick={(x, index) => {
                  setCurrentlySelectedType(x);
                  setActiveIndex(index + 1);
                  setActiveCardCheckCount(0);
                  setDeleteIdArray([]);
                }}
                onlyLarge
              />
              <Card className={styles.card}>
                <CardsOverview
                  activeIndex={activeIndex}
                  activeVirtualCard={virtualCardArray}
                  activePhysicalCard={physicalCardArray}
                  setDeleteIdArray={setDeleteIdArray}
                  deletedIdArray={deleteIdArray}
                  setActiveCardCheckCount={setActiveCardCheckCount}
                  activeCardCheckCount={activeCardCheckCount}
                  setArchivedCardCheckCount={setArchivedCardCheckCount}
                  archivedCardCheckCount={archivedCardCheckCount}
                  idActive={1}
                  idArchived={2}
                  isLoaded={isLoaded}
                  cardRights={cardRights}
                />
                {/* <div>
              <div className={styles.cardTitle}>Virtual Cards</div>
              <MyCards
                setShowCardDetails={setShowCardDetails}
                setAddNewCard={setAddNewCard}
                isLoaded={isLoaded}
                cardsArray={virtualCardArray}
                id={1}
              />
            </div> */}
              </Card>
              <Card className={styles.card}>
                {/* <div>
              <div className={styles.cardTitle}>Physical Cards</div>
              <MyCards
                setShowCardDetails={setShowCardDetails}
                setAddNewCard={setAddNewCard}
                isLoaded={isLoaded}
                cardsArray={physicalCardArray}
                id={2}
              />
            </div> */}
                {/* <button onClick={() => history.push({
              pathname: "/accountsandcards/cards/createNew",
              state: { isPhysical: true }
            })} className="button">
              <Icon name="add" size="24" />
              Order One New!
            </button> */}
              </Card>
            </div>
          ) : null}
          {addNewCard && !showCardDetails ? (
            <AddNewCard setAddNewCard={setAddNewCard} setShowCard={setShowCard} />
          ) : null}
          {!addNewCard && showCardDetails ? (
            <CardDetails setCardDetails={setShowCardDetails} />
          ) : null}
        </Card>
      )}
    </>

  );
};

export default Cards;
