import React, { useState, useEffect } from "react";
import styles from "./EditNewProduct.module.sass";
import Card from "../../../components/Card";
import cn from "classnames";
import Details from "./Details";
import DeleteModal from "../../../components/DeleteModal";
import Icon from "../../../components/Icon";
import Pricing from "./Pricing";
import Spinner from "../../../utils/spinner";
import ProductMainContainer from "../index";
import { toast, ToastContainer } from "react-toastify";
import { toastConfiguration, trackIntercomEvent } from "../../../utils/utils";
import {
  addProductsData,
  getSingleProduct,
  updateProductData,
  removeProductsData,
  getCompanyTextSettings,
} from "../../../utils/apiCallHanlder";
import { Redirect } from "react-router";

let haveDropdownsData = false;
let priceModel = [""];
let recurringType = [""];
let productCurrency = [""];
let taxcodes = [""];

let taxCodesGroups = [];
let taxCodesByGroups = {};

let showBilling = true;
const EditNewProduct = ({
  id,
  isAdd,
  className,
  searchString,
  comingFromInvoice,
  productSelectHandler,
  showProduct,
  showOneTime,
  showSubscription,
  onClose
}) => {
  if (comingFromInvoice) {
    showBilling = false;
  }
  if (!haveDropdownsData) {
    const productLookupdata = JSON.parse(localStorage.getItem("lookupData"));
    let data = null;
    if (productLookupdata) {
      data = productLookupdata?.data;
      priceModel = data?.price_mode;
      recurringType = data?.recurring_type;
      productCurrency = data?.product_currency;
      taxcodes = data?.taxcodes;

      for (let i = 0; i < taxcodes?.length; i++) {
        if (taxCodesGroups.includes(taxcodes[i]?.group)) {
          taxCodesByGroups[taxcodes[i]?.group] = [
            ...taxCodesByGroups[taxcodes[i]?.group],
            taxcodes[i],
          ];
        } else {
          taxCodesGroups.push(taxcodes[i]?.group);
          taxCodesByGroups[taxcodes[i]?.group] = [taxcodes[i]];
        }
      }

      haveDropdownsData = true;
    }
  }

  const [visible, setVisible] = useState(true);
  const [name, setName] = useState(searchString || "");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [archived, setArchived] = useState(false);

  //pricing states
  const [selectCurrency, setSelectCurrency] = useState(productCurrency[0]?.value);
  const [selectPricingModel, setSelectPricingModel] = useState(priceModel[0].value);
  const [productPrice, setProductPrice] = useState(null);
  const [userChoose, setUserChoose] = useState(false);

  const [cost, setCost] = useState(null);
  const [margin, setMargin] = useState(null);

  const [oneTime, setOneTime] = useState(true);
  const [subscriptions, setSubscriptions] = useState(false);
  let [selectPeriod, setSelectPeriod] = useState(recurringType[0]?.value);
  let [includeTax, setIncludeTax] = useState(false);

  //Error States
  const [priceError, setPriceError] = useState("");
  const [nameError, setNameError] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [startLoading, setStartLoading] = useState(true);
  const [taxCode, setTaxCode] = useState("");
  const [uploadingError, setUploadingError] = useState("");
  const [taxCodeName, setTaxCodeName] = useState("");

  if (typeof window !== "undefined") {
    const currencyValue = localStorage.getItem("currency");
    if (currencyValue) {
      productCurrency = [
        {
          value: currencyValue?.toLowerCase(),
          display: currencyValue?.toUpperCase(),
          text: currencyValue?.toUpperCase(),
        },
      ];
    }
  }

  useEffect(() => {
    setSelectCurrency(productCurrency[0]?.value);
  }, [productCurrency]);

  useEffect(async () => {
    setStartLoading(true);
    if (id) {
      const { data } = await getSingleProduct({ id });
      if (data) {
        const { product } = data;

        //setting subscription model
        if (product.priceType === "recurring") {
          setSubscriptions(true);
          setOneTime(false);
        } else if (product.priceType === "one_time") {
          setSubscriptions(false);
          setOneTime(true);
        }

        //setting pricing model
        if (product?.pricingModel === "customer_choose") {
          setUserChoose(true);
        }

        setCost(product?.cost);
        setMargin(product?.margin);
        setArchived(product?.isArchived);
        setSelectCurrency(product?.currency);
        setDescription(product?.description);
        setImageUrl(product?.imageUrl);
        setName(product?.name);
        setIncludeTax(product?.isTaxIncluded);
        setProductPrice(product?.price);
        setSelectPricingModel(product?.pricingModel);
        setSelectPeriod(product?.recurringType);
        setStartLoading(false);
        if (product?.taxCode) {
          setTaxCode(product?.taxCode);
          setTaxCodeName(product?.taxCodeName);
        } else {
          getCompanyTaxSettings();
        }
      }
    } else {
      setStartLoading(false);
      getCompanyTaxSettings();
    }
  }, [id]);

  const getCompanyTaxSettings = async () => {
    if (!taxCode) {
      let { data } = await getCompanyTextSettings();
      Object.keys(taxCodesByGroups)?.map((key) => {
        for (let i = 0; i < taxCodesByGroups[key]?.length; i++) {
          if (taxCodesByGroups[key][i]?.value === data?.companyTaxSettings?.defaultTaxCategory) {
            setTaxCodeName(taxCodesByGroups[key][i]?.display);
          }
        }
      });
      setTaxCode(data?.companyTaxSettings?.defaultTaxCategory);
    }
  };

  //onChange Hanlder
  const setValueHandler = (value, valueSetter) => {
    valueSetter(value);
  };
  const saveClickHandler = async () => {
    const re = new RegExp("^-?[0-9]d*(.d+)");
    let isExist = false;

    for (let i = 0; i < taxcodes?.length; i++) {
      if (taxcodes[i]?.value === taxCode) {
        isExist = true;
      }
    }

    if (!isExist && taxCode) {
      toast.error("Please select a specified product category", toastConfiguration);
    } else if (name === "") {
      toast.error("Please Provide Product Name", toastConfiguration);
    } else if (name?.length >= 250) {
      toast.error("Product Name must be at most 250 characters", toastConfiguration);
    } else if (
      selectPricingModel !== "customer_choose" &&
      (!productPrice || (productPrice && productPrice?.toString()?.trim() === ""))
    ) {
      toast.error("Please Provide Product Price", toastConfiguration);
    } else if (parseInt(productPrice) < 0) {
      toast.error("Price should be greater than zero", toastConfiguration);
    } else if (isNaN(productPrice)) {
      toast.error("Please enter a valid number", toastConfiguration);
    } else if (parseInt(productPrice?.length) > 6) {
      toast.error("Product Price must be at most 6 characters", toastConfiguration);
      return;
    } else if (uploadingError) {
      toast.error("Format is not supported", toastConfiguration);
      return;
    } else {
      let priceType = oneTime ? "one_time" : "recurring";
      if (showSubscription) {
        priceType = "recurring";
      }
      if (selectPricingModel === "customer_choose") {
        selectPeriod = null;
        includeTax = null;
        priceType = null;
      }

      const productPayload = {
        name: name,
        description: description,
        imageUrl: imageUrl,
        price: parseInt(productPrice * 100) / 100 || 0,
        pricingModel: selectPricingModel,
        currency: selectCurrency,
        priceType: priceType,
        recurringType: selectPeriod,
        isTaxIncluded: includeTax || false,
        taxCode: taxCode,
        cost: parseInt(cost || "0"),
        margin: margin || 0
      };

      if (id) {
        productPayload.id = id;
      }
      if (loading) {
        return;
      }

      setLoading(true);

      const newProductResponse = id
        ? await updateProductData(productPayload)
        : await addProductsData(productPayload);
      const { data, error } = newProductResponse;
      if (data) {
        if (!id) trackIntercomEvent("product-created");
        toast.success("Product data saved successfully", toastConfiguration);
        if (comingFromInvoice) {
          productSelectHandler(data?.product);
          setTimeout(() => {
            showProduct();
            setLoading(false);
          }, 2000);
        } else {
          setVisible(false);
          setLoading(false);
        }
      } else {
        toast.error(error || "Error in updating product data", toastConfiguration);
        // setServerError(newProductResponse.error);
        setLoading(false);
      }
    }
  };

  return (
    <>
      {!startLoading ? (
        visible ? (
          <>
            <ToastContainer />
            {/* {comingFromInvoice ? null : (
              <div className={styles.title}>
                <div className={styles.backBtn} onClick={() => setVisible(false)}>
                  Cancel
                </div>
                <p>{id ? archived ? "View Details" : "Edit details" : "Add New Product"}</p>
              </div>
            )} */}
            <Card border className={className}>
              {/* <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.typeNav}>
                  {productType.map((x, index) => (
                    <div
                      className={cn(styles.item, {
                        [styles.active]: index + 1 === activeIndex,
                      })}
                      key={index}
                      onClick={() => setActiveIndex(index + 1)}
                    >
                      {x.title}
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.information}>
                  {activeIndex === 1 && ""}
                  {activeIndex === 2 && (
                    <div className={styles.field}>
                      <div className={styles.fieldLabel}>
                        Billing Period <span>*</span>
                      </div>
                      <Dropdown
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        value={selectPeriod}
                        setValue={setSelectPeriod}
                        options={period}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div> */}
              <Details
                name={name}
                isAdd={isAdd}
                nameError={nameError}
                description={description}
                setNameError={setNameError}
                setDescription={setDescription}
                setName={setName}
                setValueHandler={setValueHandler}
                uploadingError={uploadingError}
                setUploadingError={setUploadingError}
                setImageUrl={setImageUrl}
                archived={archived}
                imageUrl={imageUrl}
                taxcodes={taxcodes}
                taxCode={taxCode}
                taxCodesByGroups={taxCodesByGroups}
                setTaxCode={setTaxCode}
                taxCodeName={taxCodeName}
                setTaxCodeName={setTaxCodeName}
                comingFromInvoice={comingFromInvoice}
                head={
                  <div className={styles.actionCard}>
                    <button
                      onClick={comingFromInvoice ? onClose : () => setVisible(false)}
                      className={cn("button-stroke-red", styles.button)}
                    >
                      <span>Cancel</span>
                    </button>
                    {archived ? null : (
                      <>
                        {id ? (
                          <DeleteModal
                            className={styles.deleteBtn}
                            deleteFunction={removeProductsData}
                            id={id}
                            setShowMainContainer={setVisible}
                            setValue={false}
                          />
                        ) : null}

                        <button onClick={saveClickHandler} className={cn("button", styles.button)}>
                          {loading ? (
                            <Spinner loading={loading} size={15} />
                          ) : (
                            <>
                              <label>Save</label>
                            </>
                          )}
                        </button>
                      </>
                    )}
                  </div>
                }
              />
              <Pricing
                priceModel={priceModel}
                cost={cost}
                margin={margin}
                setCost={setCost}
                setMargin={setMargin}
                archived={archived}
                priceError={priceError}
                setPriceError={setPriceError}
                recurringType={recurringType}
                productCurrency={productCurrency}
                selectCurrency={selectCurrency}
                setSelectCurrency={setSelectCurrency}
                selectPricingModel={selectPricingModel}
                setSelectPricingModel={setSelectPricingModel}
                userChoose={userChoose}
                setUserChoose={setUserChoose}
                oneTime={oneTime}
                setOneTime={setOneTime}
                subscriptions={subscriptions}
                setSubscriptions={setSubscriptions}
                selectPeriod={selectPeriod}
                setSelectPeriod={setSelectPeriod}
                setValueHandler={setValueHandler}
                productPrice={productPrice}
                setProductPrice={setProductPrice}
                includeTax={includeTax}
                setIncludeTax={setIncludeTax}
                showOneTime={showOneTime}
                showSubscription={showSubscription}
                comingFromInvoice={comingFromInvoice}
                showBilling={showBilling}
              />
            </Card>
          </>
        ) : (
          <>
            <ToastContainer />
            {isAdd ? (
              <ProductMainContainer />
            ) : (
              <Redirect
                to={{
                  pathname: "/products",
                  state: archived ? { activeIndex: 2 } : null,
                }}
              />
            )}
          </>
        )
      ) : (
        <div className={styles.spinner} style={{ height: window.outerHeight - 250 }}>
          <Spinner loading={startLoading} size={150} color={"gray"} />
        </div>
      )}
    </>
  );
};

export default EditNewProduct;
