import React from "react";
import useDarkMode from "use-dark-mode";

const Image = ({ className, src, srcDark, srcSet, srcSetDark, alt , id , onClick , onError }) => {
  const darkMode = useDarkMode(false);

  return (
    <img
      className={className}
      id={id && id}
      srcSet={darkMode.value ? srcSetDark : srcSet}
      src={darkMode.value ? srcDark : src}
      alt={alt}
      onClick={onClick && onClick}
      onError={onError}
    />
  );
};

export default Image;
