import React, { useReducer, useState } from "react";
import styles from "./NewChecklistForm.module.sass";
import Modal from "../../../../components/Modal";
import uuid from "react-uuid";
import TextInput from "../../../../components/TextInput";
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import { addCheckList } from "../../../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import Spinner from "../../../../utils/spinner";

const NewChecklistForm = ({
  visible,
  setVisible,
  CreateChecklist,
  getCheckListFunction,
  setChecklists,
  setCurrentChecklistId,
  setTargetId
}) => {
  const [addCheckListLoading, setAddCheckListLoading] = useState(false);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      checklistName: "",
    }
  );
  const handleChange = (e) => {
    setUserInput({ [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userInput?.checklistName || userInput?.checklistName.trim() === "") {
      toast.error("Please enter checklist name", toastConfiguration);
      return;
    }

    setAddCheckListLoading(true);

    const { data } = await addCheckList({
      name: userInput.checklistName,
    });

    if (data) {
      setChecklists([]);
      toast.success("Successfully added checklist", toastConfiguration);
      setCurrentChecklistId(null)
      setTargetId(null);
      await getCheckListFunction(1);
    } else {
      setCurrentChecklistId(null)
      setTargetId(null);
      toast.error("Error in adding checklist", toastConfiguration);
    }

    setAddCheckListLoading(false);
    setUserInput({ checklistName: "" });
    setVisible(false);
  };
  return (
    <Modal
      visible={visible}
      onClose={() => {
        setUserInput({ checklistName: "" });
        setVisible(false);
      }}
      children={
        <Card
          /* classTitle="title-blue" */
          title="Checklist"
          className={styles.card}
        >
          <form onSubmit={handleSubmit} noValidate>
            <TextInput
              className={styles.input}
              label="Name"
              onChange={handleChange}
              name="checklistName"
              id="checklistName"
              type="text"
              value={userInput.checklistName}
              required
            />
            <div className={styles.newChecklistBtn}>
              <button className="button">
                {addCheckListLoading ? (
                  <Spinner size="24" color="white" />
                ) : (
                  <>
                    Save
                  </>
                )}
              </button>
            </div>
          </form>
        </Card>
      }
    />
  );
};

export default NewChecklistForm;
