import { useEffect, useState } from "react";
import { addToDoTaskComment, deleteToDoTaskComment, updateToDoTaskComment, uploadFile } from "../../../../utils/apiCallHanlder";
import styles from './Comments.module.sass';
import Styles from '../Pipeline.module.sass'
import { toast } from "react-toastify";
import { GetFileNameFromPath, timeAgo, toastConfiguration } from "../../../../utils/utils";
import Spinner from "../../../../utils/spinner";
import DeleteModal from "../../../../components/DeleteModal";
import Icon from "../../../../components/Icon";
import { useDropzone } from "react-dropzone";
import PdfViewer from "../PdfViewer";

const imageWRTExtension = {
    common: "/images/content/image.png",
    xlsx: "/images/content/xlsx.png",
    xls: "/images/content/xlsx.png",
    pdf: "/images/content/pdf.png",
};

const Comment = ({ item, id, setShowAddNewComment, comments, setComments, autoFocus, userImage, downloadAttachment }) => {
    const [addLoading, setAddLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [imageUploadLoading, setImageUploadLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [showEdit, setShowEdit] = useState(false);


    const { getRootProps } = useDropzone({
        noDragEventsBubbling: true,
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            if (acceptedFiles?.length > 0) {
                imageUploadHandler(acceptedFiles[0])
            }
        },
    });

    const imageUploadHandler = async (file) => {
        setImageUploadLoading(true);
        const formData = new FormData();
        formData.append("", file);
        const { data, error } = await uploadFile(formData);

        if (data) {
            const imageUrl = data?.file?.url;
            setAttachments([...attachments, imageUrl]);
        }
        else {
            toast.error(error || "Error in uploading file", toastConfiguration);
        }
        setImageUploadLoading(false);
    };

    const addComment = async () => {
        if (comment?.trim() === 0) {
            toast.error("Please add comment", toastConfiguration);
            return;
        }

        setAddLoading(true);
        const payload = {
            detail: comment,
            toDoTaskId: id,
            attachments: attachments
        }

        const { data, error } = await addToDoTaskComment(payload)

        if (data) {
            toast.success("Comment added successfully", toastConfiguration);
            //setComments([...(comments || []), { ...payload, ...(data?.comment || {}) }])
            setComments([{ ...payload, ...(data?.comment || {}) }, ...(comments || [])]);
            setShowAddNewComment(false);
        } else {
            toast.error(error || "Error in adding comment", toastConfiguration);
        }

        setAddLoading(false);
    }

    const deleteHandler = async (commentId) => {
        const { data, error } = await deleteToDoTaskComment(commentId);
        if (data) {
            const newComments = comments?.filter(item => item?.toDoTaskHistoryId !== commentId);
            setComments(newComments);
            toast.success("Task delete successfully", toastConfiguration);
        } else {
            toast.error(error || "Error in deleting task", toastConfiguration);
        }
    }

    const updateHandler = async (commentId) => {
        if (comment?.trim() === 0) {
            toast.error("Please add comment", toastConfiguration);
            return;
        }

        setUpdateLoading(true);
        const { data, error } = await updateToDoTaskComment({
            toDoTaskHistoryId: commentId,
            detail: comment,
            ToDoTaskId: id,
            attachments: attachments
        });
        if (data) {
            const updatedComments = comments?.map(item => {
                if (item?.toDoTaskHistoryId === commentId) {
                    return data?.comment
                }

                return item;
            });
            setComments(updatedComments || [])
            toast.success("Comment updated successfully", toastConfiguration);
            setShowEdit(false);
        } else {
            toast.error(error || "Error in updating comment", toastConfiguration);
        }
        setUpdateLoading(false);
    }

    return <>
        {(item?.toDoTaskHistoryId && !showEdit) ?
            <>
                <div className={styles.wholeCommentContainer}>
                    {item?.imageUrl ? (
                        <img alt="user-profile" src={item?.imageUrl} className={styles.userImge} />
                    ) :
                        <div className={styles.userAvatar}>{item?.createdByName && item?.createdByName[0]}</div>
                    }

                    <div className={styles.nameContainer}>
                        <div className={styles.nameTimeContainer}>
                            <span className={styles.name}>{item?.createdByName}</span>
                            <span className={styles.time}>{item?.createdOn ? timeAgo(new Date(item?.createdOn)) : '-'}</span>
                        </div>
                        <span>{item?.detail}</span>
                    </div>
                    <div className={styles.actionButtonContainer}>
                        <div className={styles.editButton} onClick={() => {
                            setComment(item?.detail || []);
                            setAttachments(item?.attachments || []);
                            setShowEdit(true);
                        }}>
                            <Icon name="edit" size="24" />
                        </div>
                        <DeleteModal
                            icon={<Icon name="trash" size="24" fill="#FF4900" />}
                            btnClass={' '}
                            btnTxt={' '}
                            id={item?.toDoTaskHistoryId}
                            onClickHandler={deleteHandler}
                        />
                    </div>
                </div>
                <div className={Styles.selectedImageWrapComment}>
                    {item?.attachments?.length > 0 &&
                        item?.attachments?.map((x, index) => {
                            const fileNameObj = GetFileNameFromPath(x, true);
                            return (
                                <div key={index} style={{ position: 'relative' }}>
                                    {(fileNameObj?.extension === "pdf") ?
                                        <PdfViewer src={x}
                                        closeButton={
                                            <div className={Styles.actionDownload}>
                                                <button
                                                    onClick={() => downloadAttachment(x)}
                                                >
                                                    <Icon name='download' size='24' />
                                                </button>
                                            </div>
                                        }  />
                                        :
                                        <>
                                        <img
                                            className={Styles.selectedImage}
                                            src={
                                                fileNameObj.extension === "png" ||
                                                    fileNameObj.extension === "jpg" ||
                                                    fileNameObj.extension === "jpeg"
                                                    ? x
                                                    : imageWRTExtension[
                                                        fileNameObj.extension
                                                    ] || imageWRTExtension["common"]
                                                }
                                                hideZoom
                                                />
                                        <div className={Styles.actionDownload}>
                                                <button
                                                    onClick={() => downloadAttachment(x)}
                                                >
                                                    <Icon name='download' size='24' />
                                                </button>
                                            </div>
                                        </>
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </>
            :
            <div className={styles.contentContainer}>
                <div className={styles.titleContainer}>
                    {userImage && (
                        <img alt="user-profile" src={userImage} className={styles.userImge} />
                    )}
                    <textarea
                        onChange={(e) => setComment(e.target.value)}
                        rows={"4"}
                        value={comment}
                        className={styles.input}
                        autoFocus={autoFocus}
                        label='Comment'
                        name='number'
                        type='text'
                        required
                    />
                </div>

                <div className={Styles.selectedImageWrapComment}>
                    {attachments?.length > 0 &&
                        attachments?.map((x, index) => {
                            const fileNameObj = GetFileNameFromPath(x, true);
                            return (
                                <div key={index} style={{ position: 'relative', height: 'max-content' }}>
                                    {(fileNameObj?.extension === "pdf") ?
                                        <PdfViewer src={x} closeButton={
                                            <div className={Styles.actionDownload}>
                                                <button
                                                    onClick={() => downloadAttachment(x)}
                                                >
                                                    <Icon name='download' size='24' />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        const filtertedAttachments = attachments?.filter(item => item !== x);
                                                        setAttachments(filtertedAttachments);
                                                    }}
                                                >
                                                    <Icon name='trash' size='24' fill="#FF4900" />
                                                </button>
                                            </div>
                                        } />
                                        :
                                        <>
                                            <img
                                                className={Styles.selectedImage}
                                                src={
                                                    fileNameObj.extension === "png" ||
                                                        fileNameObj.extension === "jpg" ||
                                                        fileNameObj.extension === "jpeg"
                                                        ? x
                                                        : imageWRTExtension[
                                                        fileNameObj.extension
                                                        ] || imageWRTExtension["common"]
                                                }
                                                hideZoom
                                            />
                                            <div className={Styles.actionDownload}>
                                                <button
                                                    onClick={() => downloadAttachment(x)}
                                                >
                                                    <Icon name='download' size='24' />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        const filtertedAttachments = attachments?.filter(item => item !== x);
                                                        setAttachments(filtertedAttachments);
                                                    }}
                                                >
                                                    <Icon name='trash' size='24' fill="#FF4900" />
                                                </button>
                                            </div>
                                        </>
                                    }
                                </div>
                            );
                        })
                    }
                <div className={styles.dropzonewrapCommnetWrapper}>
                    <div {...getRootProps({ className: `dropzone` })} >
                        <div className={Styles.dropzoneWrapComment}>
                            <p className={Styles.dropzoneComment}>
                                {" "}
                                <Icon name='file-add' size='64' />
                                <span>
                                    {imageUploadLoading ? 'Uploading ...' : 'Drag and drop or click to upload'}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                </div>
                    <div className={styles.buttonContainer}>
                            {<button className="button-stroke-red" style={{ width: 84 }} onClick={() => {
                                if (item) {
                                    setShowEdit(false);
                                } else {
                                    setShowAddNewComment(false);
                                }
                            }}>Cancel</button>}
                            {!item?.detail && <button className="button" onClick={addComment} style={{ width: 84 }}>
                                {addLoading ?
                                    <Spinner size="24" color='white' />
                                    :
                                    "Add"
                                }
                            </button>}
                            {item?.toDoTaskHistoryId && <button
                                className="button-stroke-red"
                                style={{ width: 84 }}
                                onClick={() => updateHandler(item?.toDoTaskHistoryId)}
                            >
                                {updateLoading ?
                                    <Spinner size="24" color='gray' />
                                    :
                                    "Update"
                                }</button>}
                    </div>
            </div>
        }
    </>
}

export default Comment;