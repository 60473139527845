import React from "react";
import styles from "./SaveSuccess.module.sass";
import Modal from "../../../../../components/Modal";
import Icon from "../../../../../components/Icon";

const SaveSuccess = ({
  showSuccessModal,
  setShowSuccessModal,
  onConfirm,
  name,
  isQuestionnaire
}) => {
  return (
    <>
      <Modal
        visible={showSuccessModal}
        onClose={onConfirm}
        outerClassName={styles.outer}
      >
        <div className={styles.wrap}>
          <img
            className={styles.pic}
            src="/success.gif"
            style={{ width: 440, height: 287 }}
            srcDark="/images/content/takeoff.png"
            alt="delete"
          />
          <div className={styles.email}>
            Fantastic. Your have sent {`${isQuestionnaire ? 'Questionnaire' : "contract"} to`}  {name}
          </div>
          {/* <div className={styles.email}>
            It was sent to: <span style={{ color: "black" }}>{subscriptionResponse?.customerEmail}</span>
          </div> */}
          <button onClick={onConfirm} className="button">
            <Icon name="coffee" size="20" />
            Done
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SaveSuccess;
