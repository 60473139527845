import React, { useState, useEffect, createRef } from "react";
import Card from "../../../../components/Card";
import styles from "./UpdateEmbeddedForm.module.sass";
import TextInput from "../../../../components/TextInput";
import Modal from "../../../../components/Modal";
import $ from "jquery";
import Spinner from "../../../../utils/spinner";
import Icon from "../../../../components/Icon";
import Code from "../Code";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const UpdateEmbeddedForm = ({
  handleSubmit,
  handleChange,
  userInput,
  setIsUpdate,
  setUserInput,
  editEmbededFormLoading,
  x,
  isUpdate,
}) => {
  const fb = createRef();
 
  var options = {
    dataType: "json",
    disableFields: [
      "autocomplete",
      "file",
      "hidden",
      "date",
      "button",
      "checkbox-group",
      "header",
      "number",
      "paragraph",
      "radio-group",
      "select",
      "text",
      "textarea",
    ],
    formData: x?.data?.length > 0 ? x?.data : [
      {
        type: "text",
        label: "First Name",
        className: "form-control",
        placeholder: "Enter your first name",
        required: true,
      },
      {
        type: "text",
        label: "Last Name",
        className: "form-control",
        placeholder: "Enter your last name",
        required: true,
      },
      {
        type: "text",
        label: "Email",
        className: "form-control",
        placeholder: "Enter your email address",
        required: true,
      },
      {
        type: "text",
        label: "Phone Number",
        className: "form-control",
        placeholder: "Enter your phone number",
      },
      {
        type: "text",
        label: "Referred By",
        className: "form-control",
        placeholder: "How did you hear about us?",
      },
      {
        type: "text",
        label: "Session Type",
        className: "form-control",
        placeholder: "Enter the session type",
      },
      {
        type: "date",
        label: "Session Date",
        className: "form-control",
      },
      {
        type: "text",
        label: "Session Location",
        className: "form-control",
        placeholder: "Enter the session location",
      },
      {
        type: "textarea",
        label: "Message",
        className: "form-control",
        placeholder: "Enter your message here",
        required: true,
      },
      {
        type: "checkbox",
        label: "Newsletter",
        values: [
          {
            label: "Yes, I would like to signup to your newsletter",
            value: "option-1",
            selected: true,
          },
        ],
      },
      {
        type: "button",
        subtype: "submit",
        label: "Submit",
        className: "form-control",
      },
    ],
  };

  useEffect(() => {
    $(fb.current).formBuilder(options);
  }, [isUpdate]);

  return (
    <Modal
      visible={isUpdate}
      outerClassName={styles.outerWrap}
      onClose={() => {
        setIsUpdate(false);
        setUserInput({
          EmbeddedFormName: x.name,
          formBuilderData: x.data,
        });
      }}
      children={
        <Card
          /* classTitle="title-blue" */
          title="Embedded Form"
          className={styles.card}
        >
          <form className={styles.formWrap} onSubmit={handleSubmit} noValidate>
            <TextInput
              className={styles.input}
              label="Name"
              placeholder="Enter the name for this Embedded Form"
              onChange={handleChange}
              name="embeddedFormName"
              id="embeddedFormName"
              type="text"
              value={userInput.embeddedFormName}
              required
            />
            <div className={styles.newEmbeddedFormBtn}>
              {/* <button
                onClick={() => {
                  setIsUpdate(false);
                  setUserInput({ embeddedFormName: x.name });
                }}
                className="button-stroke"
              >
                Cancel
              </button> */}
              <button className="button">
                {editEmbededFormLoading ?
                  <Spinner size="24" color="white" />
                  :(
                    <>
                    Save
                    </>
                  )}
              </button>
            </div>
            <div className={styles.codeWrap}>
              <Code x={x} userInput={userInput} />
            </div>
            <div className={styles.embeddedForm}>
              <div id="fbEditorX" ref={fb} />
            </div>
          </form>
        </Card>
      }
    />
  );
};

export default UpdateEmbeddedForm;
