import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Card from "../../../../../components/Card";
import styles from "./GeneralDetails.module.sass";
import React, { useEffect, useState } from "react";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { icons } from "../../../../../utils/appConstanst";
import { dateFormatter, getNumberWithSpaces } from "../../../../../utils/utils";
import ImagePreview from "../../../../../components/ImagePreview";
import cn from "classnames";

const GeneralDetails = ({ property, showMapControl }) => {
  const [imagePreviewPopup, setImagePreviewPopup] = useState(false);
  const [currentlySelectedImage, setCurrentlySelectedImage] = useState("");

  const intPrice = parseFloat(property?.listedPrice || "0");
  const intCashDownpayment = parseFloat(property?.cashInvested || "0");
  const intBorrowedCapital = parseFloat(property?.borrowedCapital || "0");
  const intProjectCost = parseFloat(property?.projectCost || "1");
  const intYears = parseFloat(property?.projectTimeline || "1");

  const ltv = (
    ((intPrice - intCashDownpayment) / (intPrice || 1)) *
    100
  )?.toFixed(2);
  const ltc = ((intBorrowedCapital / intProjectCost) * 100)?.toFixed(2);
  const holdingCost = parseFloat(property?.monthlyCost || "0") * intYears;

  const [mapCenter, setMapCenter] = useState(null);
  const containerStyle = {
    width:
      window?.innerWidth < 1024
        ? "100%"
        : property?.imageUrl
        ? "calc(100% - 224px)"
        : "100%",
    height: "200px",
  };

  useEffect(() => {
    if (property?.address) setMapLocation(property?.address);
  }, [property]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCTpbuCUVv_C2RRr09oRimIlrjGzcP9C-0",
    libraries: ['geometry']
  });

  const onLoad = React.useCallback(function callback(map) {
    if (mapCenter) {
      const bounds = new window.google.maps.LatLngBounds(mapCenter);
      bounds?.extend(mapCenter);
      map.fitBounds(bounds);
    }
  }, []);

  const setMapLocation = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        if (lat && lng) {
          setMapCenter({
            lat: lat,
            lng: lng,
          });
        }
      });
  };

  return (
    <>
      <ImagePreview
        visible={imagePreviewPopup}
        setVisible={setImagePreviewPopup}
        imageUrl={currentlySelectedImage}
      />
      <Card
        title={property?.name}
        classCardHead={styles.cardHead}
        classTitle={styles.cardTitle}
        border
      >
        <div id="printAbleDiv">
          <div className={styles.text}>{property?.address}</div>
          <div className={styles.headerImageContainer}>
            {property?.imageUrl && (
              <img
                src={property?.imageUrl}
                alt="main-img"
                className={styles.mainImage}
              />
            )}
            {isLoaded && mapCenter && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={mapCenter}
                zoom={15}
                onLoad={onLoad}
                options={{
                  disableDefaultUI: true,
                  fullscreenControl: false,
                  zoomControl: showMapControl,
                }}
              >
                <Marker position={mapCenter} />
              </GoogleMap>
            )}
          </div>
          <div className={styles.generalDetails}>
            <div className={styles.singleDetail}>
              <div className={styles.header}>Type</div>
              <div className={styles.value}>
                {icons[property?.propertyTypeId - 1]?.name}
              </div>
            </div>
            <div className={styles.singleDetail}>
              <div className={styles.header}>Status</div>
              <div className={styles.value}>{property?.status || "-"}</div>
            </div>
            <div className={styles.singleDetail}>
              <div className={styles.header}>Price</div>
              <div className={styles.value}>
                {getNumberWithSpaces(property?.listedPrice) || "-"}
              </div>
            </div>
            <div className={styles.singleDetail}>
              <div className={styles.header}>Refurb Cost</div>
              <div className={styles.value}>
                {getNumberWithSpaces(property?.refurbCost) || "-"}
              </div>
            </div>
            <div className={styles.singleDetail}>
              <div className={styles.header}>Other Cost</div>
              <div className={styles.value}>
                {getNumberWithSpaces(property?.otherCost) || "-"}
              </div>
            </div>
          </div>
          <div className={styles.total}>
            Total fixed cost ={" "}
            {getNumberWithSpaces(property?.totalFixedCost) || "-"}
          </div>
          <div className={styles.seperator}></div>
          <div className={styles.generalDetails}>
            <div className={styles.singleDetail}>
              <div className={styles.header}>Downpayment</div>
              <div className={styles.value}>
                {getNumberWithSpaces(property?.cashInvested) || "-"}
              </div>
            </div>
            <div className={styles.singleDetail}>
              <div className={styles.header}>Interest Rate</div>
              <div className={styles.value}>
                {`${property?.interestRate || "0"}%`}
              </div>
            </div>
            <div className={styles.singleDetail}>
              <div className={styles.header}>Loan</div>
              <div className={styles.value}>
                {getNumberWithSpaces(property?.borrowedCapital) || "-"}
              </div>
            </div>
            <div className={styles.singleDetail}>
              <div className={styles.header}>LTV</div>
              <div className={styles.value}>{`${ltv || "0"}%`}</div>
            </div>
            <div className={styles.singleDetail}>
              <div className={styles.header}>LTC</div>
              <div className={styles.value}>{`${ltc || "0"}%`}</div>
            </div>
          </div>
          <div className={styles.generalDetails}>
            <div className={styles.singleDetail}>
              <div className={styles.header}>Insurance</div>
              <div className={styles.value}>
                {getNumberWithSpaces(property?.homeInsurance) || "-"}
              </div>
            </div>
            <div className={styles.singleDetail}>
              <div className={styles.header}>HOA</div>
              <div className={styles.value}>
                {getNumberWithSpaces(property?.hoa) || "-"}
              </div>
            </div>
            <div className={styles.singleDetail}>
              <div className={styles.header}>Property taxes</div>
              <div className={styles.value}>
                {getNumberWithSpaces(property?.propertyTaxes) || "-"}
              </div>
            </div>
            <div className={styles.singleDetail}>
              <div className={styles.header}>Maintenance</div>
              <div className={styles.value}>
                {getNumberWithSpaces(property?.maintenance) || "-"}
              </div>
            </div>
            <div className={styles.singleDetail}>
              <div className={styles.header}>Other</div>
              <div className={styles.value}>
                {getNumberWithSpaces(property?.otherUtilities) || "-"}
              </div>
            </div>
          </div>
          <div
            className={styles.generalDetails}
            style={{ justifyContent: "center" }}
          >
            <div className={styles.singleDetail}>
              <div className={styles.header}>Borrowing cost</div>
              <div className={styles.value}>
                {getNumberWithSpaces(property?.borrowedCost) || "-"}
              </div>
            </div>
          </div>
          <div className={styles.total}>
            Monthly cost = {getNumberWithSpaces(property?.monthlyCost) || "-"}
          </div>
          <div className={styles.seperator}></div>
          <div
            className={styles.generalDetails}
            style={{ justifyContent: "center" }}
          >
            <div className={styles.singleDetail}>
              <div className={styles.header}>Start date</div>
              <div className={styles.value}>
                {dateFormatter(property?.startedOn) || "-"}
              </div>
            </div>
            <div className={styles.singleDetail}>
              <div className={styles.header}>Timeline</div>
              <div className={styles.value}>
                {`${property?.projectTimeline || "0"} Months`}
              </div>
            </div>
          </div>
          <div className={styles.total}>
            Holding cost = {getNumberWithSpaces(holdingCost) || "-"}
          </div>
          <div className={cn(styles.total, styles.projectCost)}>
            Project cost = {getNumberWithSpaces(property?.projectCost) || "-"}
          </div>
        </div>
      </Card>
      <Card className={styles.secondCard} border>
        <div id="printAbleDiv">
          <div
            className={styles.generalDetails}
            style={{ justifyContent: "center" }}
          >
            <div className={styles.singleDetail} style={{ width: 145 }}>
              <div className={styles.header}>Sales Price (ARV)</div>
              <div className={styles.value}>
                {getNumberWithSpaces(property?.salePriceAfterCost) || "-"}
              </div>
            </div>
            <div className={styles.singleDetail}>
              <div className={styles.header}>Selling cost</div>
              <div className={styles.value}>
                {getNumberWithSpaces(property?.sellingCost) || "-"}
              </div>
            </div>
          </div>
          <div className={styles.total}>
            Profit = {getNumberWithSpaces(property?.profit) || "-"}
          </div>
          <div className={cn(styles.total, styles.totalMargin)}>
            Profit Margin ={" "}
            {(
              ((parseFloat(property?.salePriceAfterCost || "0") -
                (parseFloat(property?.sellingCost || "0") +
                  parseFloat(property?.projectCost || "0"))) /
                parseFloat(property?.salePriceAfterCost || "1")) *
              100
            )?.toFixed(2)}
            %
          </div>
          <div className={cn(styles.total, styles.totalMargin)}>
            COCR (Cash-on-Cash-Return) ={" "}
            {(
              (parseFloat(property?.profit || "0") /
                parseFloat(property?.cashInvested || "1")) *
              100
            )?.toFixed(2)}
            %
          </div>
          <div className={styles.seperator}></div>
          {property?.attachments?.length > 0 && (
            <div className={styles.imageWrapper}>
              <div className={styles.imageContainer}>
                {property?.attachments?.map((item, index) => {
                  return (
                    item !== property?.imageUrl && (
                      <img
                        src={item}
                        className={styles.img}
                        key={index}
                        onClick={() => {
                          setCurrentlySelectedImage(item);
                          setImagePreviewPopup(true);
                        }}
                      />
                    )
                  );
                })}
              </div>
            </div>
          )}
          <div
            className={styles.generalDetails}
            style={{ justifyContent: "center" }}
          >
            <div className={styles.singleDetail} style={{ width: "60%" }}>
              <div className={styles.header}>Link</div>
              <div className={cn(styles.value, styles.ellipsis)}>
                <a
                  href={property?.link}
                  target="_blank"
                  style={{
                    color: "#FF4900",
                  }}
                >
                  {property?.link || "-"}
                </a>
              </div>
            </div>
          </div>
          <div
            className={styles.generalDetails}
            style={{ justifyContent: "center" }}
          >
            <div className={styles.singleDetail} style={{ width: "60%" }}>
              <div className={styles.header}>Description</div>
              <div className={styles.value} style={{ wordWrap: "break-word" }}>
                {property?.description || "-"}
              </div>
            </div>
          </div>
          {property?.propertyFields?.map((item, index) => {
            return (
              <div
                className={styles.generalDetails}
                style={{ justifyContent: "center" }}
                key={index}
              >
                <div className={styles.singleDetail} style={{ width: "60%" }}>
                  <div className={styles.header}>{item?.name}</div>
                  <div
                    className={styles.value}
                    style={{ wordWrap: "break-word" }}
                  >
                    {item?.value || "-"}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Card>
    </>
  );
};

export default GeneralDetails;
