import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Checkbox from "../../../../../components/Checkbox";
import Icon from "../../../../../components/Icon";
import Amount from "../../../../../components/Amount";

const Row = ({
  item,
  onChange,
  activeTable,
  activeId,
  className,
  editAction,
  deleteAction,
  singleCheck,
  name,
  editProduct,
  archiveAction,
  isArchived,
  setId,
  setCustomerVisible,
  availableCheckCount,
  archivedCheckCount
}) => {
  const [visible, setVisible] = useState(false);
  item.icon = "dots";

  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }

        )}
      >
        <div className={styles.editOverlay} onClick={() => {
          editProduct(item.id);
        }}></div>
        <div className={styles.col}>
          {!isArchived && (
            singleCheck || (
              <Checkbox name={name} className={/* item.imageUrl ? styles.checkboxImg : */ styles.checkbox} onChange={(e) => onChange(e, item.id)} />
            )
          )}
          <div className={cn(styles.userImage, isArchived ? (archivedCheckCount < 1 ? [styles.showUserImg] : [styles.hideUserImg]) : (availableCheckCount < 1 ? [styles.showUserImg] : [styles.hideUserImg]))} >
            <img className={styles.image} src={item.imageUrl ? item.imageUrl : "/images/content/image.png"} alt="product" />
          </div>
        </div>
        <div className={styles.col} style={{ maxWidth: 150, wordWrap: 'break-word' }}>
          <div className={styles.priceTag}>Name</div>
          <div className={styles.product} onClick={() => editProduct(item.id)}>
            <div onClick={() => editProduct(item.id)}>
              <span >{item.name}
                {isArchived ?
                  <>
                    <br />
                    <span className={styles.archivedText}> Archived </span>
                  </>
                  : null
                }
              </span>
              <br />
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.priceTag}>Price</div>
          <div className={styles.price}>
            {item.price === "Customer chooses" ?
              item.price :
              <Amount amount={item.price} subtitleEmValue={"0.8em"} />
            }
          </div>
        </div>
        <div className={styles.col}>
          <span className={styles.typeTag}>Type</span>
          <div className={styles.price}>{item.priceType === "One time" ? "Regular" : "Subscription"}</div>
        </div>
        <div className={styles.col}>
          <span className={styles.typeTag}>Status</span>
          <div className={styles.price}>
            {item.active ? 'Active' : 'Inactive'}
          </div>
        </div>
      </div>
    </>
  );
};

export default Row;
