import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

function Spinner({ loading, size, color }) {
    const colorToApply = color ? color : "white";
    return (
        <div className="sweet-loading">
            <ClipLoader loading={loading} color={colorToApply} size={size} />
        </div>
    );
}

export default Spinner;