import React, { useState, useEffect } from "react";
import styles from "./EditNewCoupon.module.sass";
import Card from "../../../components/Card";
import cn from "classnames";
import Details from "./Details";
import DeleteModal from "../../../components/DeleteModal";
import Icon from "../../../components/Icon";
import Pricing from "./Pricing";
import Spinner from '../../../utils/spinner';
import ProductMainContainer from '../index';
import { toast, ToastContainer } from 'react-toastify';
import { toastConfiguration, trackIntercomEvent, dateFormatterUTC, dateFormatterWithTimeZone } from "../../../utils/utils";
import { addCouponsData, getSingleCoupon, updateCouponData, removeCouponsData } from '../../../utils/apiCallHanlder';
import { Redirect } from "react-router";

const durationOption = {
  forever: "forever",
  once: "once",
  repeating: "repeating"
}

let haveDropdownsData = false;
let priceModel = [""];
let recurringType = [""];
let productCurrency = [""];
let taxcodes = [""];

let taxCodesGroups = [];
let taxCodesByGroups = {};

let showBilling = true;
const EditNewCoupon = ({
  id,
  isAdd,
  className,
  searchString,
  comingFromInvoice,
  productSelectHandler,
  showProduct,
  showOneTime,
  showSubscription
}) => {

  if (comingFromInvoice) {
    showBilling = false
  }
  if (!haveDropdownsData) {
    const productLookupdata = JSON.parse(localStorage.getItem("lookupData"));
    let data = null;
    if (productLookupdata) {
      data = productLookupdata?.data;
      priceModel = data?.price_mode;
      recurringType = data?.recurring_type;
      productCurrency = data?.product_currency;
      taxcodes = data?.taxcodes;

      for (let i = 0; i < taxcodes?.length; i++) {
        if (taxCodesGroups.includes(taxcodes[i]?.group)) {
          taxCodesByGroups[taxcodes[i]?.group] = [...taxCodesByGroups[taxcodes[i]?.group], taxcodes[i]];
        } else {
          taxCodesGroups.push(taxcodes[i]?.group);
          taxCodesByGroups[taxcodes[i]?.group] = [taxcodes[i]];
        }
      }

      haveDropdownsData = true;
    }
  }

  const [visible, setVisible] = useState(true);
  const [name, setName] = useState(searchString || "");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [archived, setArchived] = useState(false);

  //pricing states

  const [amountOff, setAmountOff] = useState("")
  const [percentOff, setPercentOff] = useState("")
  const [maxRedemptions, setMaxRedemptions] = useState("")
  const [redeemBy, setRedeemBy] = useState("")
  const [duration, setDuration] = useState("")
  const [selectDuration, setSelectDuration] = useState("")
  const [durationInMonths, setDurationInMonths] = useState("")
  const [couponType, setCouponType] = useState("")

  const [selectCurrency, setSelectCurrency] = useState(productCurrency[0]?.value);
  const [selectPricingModel, setSelectPricingModel] = useState(priceModel[0].value);
  const [productPrice, setProductPrice] = useState(null);
  const [userChoose, setUserChoose] = useState(false);
  const [oneTime, setOneTime] = useState(true);
  const [subscriptions, setSubscriptions] = useState(showSubscription && comingFromInvoice ? true : false);
  let [selectPeriod, setSelectPeriod] = useState(recurringType[0]?.value);
  let [includeTax, setIncludeTax] = useState(false);

  //Error States
  const [priceError, setPriceError] = useState("");
  const [nameError, setNameError] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [startLoading, setStartLoading] = useState(true);
  const [taxCode, setTaxCode] = useState("");
  const [taxCodeName, setTaxCodeName] = useState("");

  if (typeof window !== "undefined") {
    const currencyValue = localStorage.getItem("currency");
    if (currencyValue) {
      productCurrency = [{
        value: currencyValue?.toLowerCase(),
        display: currencyValue?.toUpperCase(),
        text: currencyValue?.toUpperCase()
      }]
    }
  }

  useEffect(() => {
    setSelectCurrency(productCurrency[0]?.value)
  }, [productCurrency])


  useEffect(async () => {
    setStartLoading(true);
    if (id) {
      const { data } = await getSingleCoupon({ id });
      if (data) {

        const { coupon } = data;
        setName(coupon.name)
        setAmountOff(coupon.amountOff)
        setPercentOff(coupon.percentOff)
        setMaxRedemptions(coupon.maxRedemptions)
        setRedeemBy(coupon.redeemBy)
        setSelectDuration(coupon.duration)
        setDurationInMonths(coupon.durationInMonths)
        setSelectCurrency(coupon.currency)
        setDuration(coupon.duration)
        setCouponType(coupon.percentOff ? "percentOff" : "amountOff")
        setStartLoading(false)
      }
    } else {
      setStartLoading(false);
    }

  }, [id])

  //onChange Hanlder
  const setValueHandler = (value, valueSetter) => {
    valueSetter(value);
  }

  const saveClickHandler = async () => {

    if (name === "" || !name?.replace(/\s/g, '')?.length) {
      toast.error("Please Provide Coupon Name", toastConfiguration);
    } else if (maxRedemptions === "") {
      toast.error("Max Redemptions should be zero or positive number", toastConfiguration);
    } else if (selectDuration === "") {
      toast.error("Duration is required", toastConfiguration);
    } else if (!percentOff && !amountOff) {
      toast.error("Value of AmountOff or PercentOff is required", toastConfiguration);
    } else if (percentOff && amountOff) {
      toast.error("Received both Percent off and Amount off parameters. Please pass in only one.", toastConfiguration);
    } else {

      const productPayload = {
        name: name,
        percentOff: percentOff,
        amountOff: amountOff,
        currency: selectCurrency,
        duration: selectDuration,
        durationInMonths: durationInMonths,
        maxRedemptions: maxRedemptions,
        redeemBy: redeemBy
      }


      const updateCouponPayload = {
        name: name,
      }

      if (id) {
        updateCouponPayload.id = id
      }

      setLoading(true);

      const newProductResponse = id ?
        await updateCouponData(updateCouponPayload) :
        await addCouponsData(productPayload)
      const { data } = newProductResponse;
      if (data) {
        if (!id) trackIntercomEvent('Coupon-created');
        toast.success("Coupon data saved successfully", toastConfiguration);
        if (comingFromInvoice) {
          productSelectHandler(data?.coupon);
          setTimeout(() => {
            showProduct();
            setLoading(false);
          }, 2000)
        } else {
          setVisible(false);
          setLoading(false);
        }

      } else {
        toast.error(newProductResponse.error || "Error in taxRate data", toastConfiguration);
        setServerError(newProductResponse.error);
        setLoading(false);
      }
    }

  }

  return (
    <>
      {!startLoading ?
        visible ? (
          <>
            <ToastContainer />
            {comingFromInvoice ? null :
              <div className={styles.title}>
                <div className={styles.backBtn} onClick={() => setVisible(false)}>
                  <Icon name="arrow-left" size="14" /> Back to Coupons
                </div>
                {/* <p>{id ? archived ? "View Details" : "Edit details" : "Add New Product"}</p> */}
              </div>
            }
            <Card border className={className}>
              <Details
                name={name}
                isAdd={isAdd}
                nameError={nameError}
                description={description}
                setNameError={setNameError}
                setDescription={setDescription}
                setName={setName}
                // percentOff={percentOff}
                // setPercentOff={setPercentOff}
                maxRedemptions={maxRedemptions}
                setMaxRedemptions={setMaxRedemptions}
                redeemBy={redeemBy}
                setRedeemBy={setRedeemBy}
                // duration={duration}
                // setDuration={setDuration}
                durationInMonths={durationInMonths}
                setDurationInMonths={setDurationInMonths}
                durationOption={durationOption}
                selectDuration={selectDuration}
                couponType={couponType}
                setCouponType={setCouponType}
                setSelectDuration={setSelectDuration}
                setValueHandler={setValueHandler}
                setImageUrl={setImageUrl}
                archived={archived}
                imageUrl={imageUrl}
                taxcodes={taxcodes}
                taxCode={taxCode}
                taxCodesByGroups={taxCodesByGroups}
                setTaxCode={setTaxCode}
                taxCodeName={taxCodeName}
                setTaxCodeName={setTaxCodeName}
                comingFromInvoice={comingFromInvoice}
              />
              <Pricing
                priceModel={priceModel}
                archived={archived}
                priceError={priceError}
                setPriceError={setPriceError}
                recurringType={recurringType}
                amountOff={amountOff}
                setAmountOff={setAmountOff}
                percentOff={percentOff}
                setPercentOff={setPercentOff}
                couponType={couponType}
                setCouponType={setCouponType}
                durationInMonths={durationInMonths}
                setDurationInMonths={setDurationInMonths}
                productCurrency={productCurrency}
                selectCurrency={selectCurrency}
                setSelectCurrency={setSelectCurrency}
                // handleChangeCouponType={handleChangeCouponType}
                selectPricingModel={selectPricingModel}
                setSelectPricingModel={setSelectPricingModel}
                userChoose={userChoose}
                setUserChoose={setUserChoose}
                oneTime={oneTime}
                setOneTime={setOneTime}
                subscriptions={subscriptions}
                setSubscriptions={setSubscriptions}
                selectPeriod={selectPeriod}
                setSelectPeriod={setSelectPeriod}
                setValueHandler={setValueHandler}
                productPrice={productPrice}
                setProductPrice={setProductPrice}
                includeTax={includeTax}
                setIncludeTax={setIncludeTax}
                showOneTime={showOneTime}
                showSubscription={showSubscription}
                comingFromInvoice={comingFromInvoice}
                showBilling={showBilling}
              />
              <div className={styles.actionCard}>
                {/* {!comingFromInvoice ?
                  <button
                    onClick={() => setVisible(false)}
                    className={cn("button-stroke", styles.button)}
                  >
                    <Icon name="arrow-left" size="24" />
                    <span>Cancel</span>
                  </button>
                  : null
                } */}
                {archived ? null :
                  <>
                    {id ?
                      <DeleteModal
                        className={styles.deleteBtn}
                        deleteFunction={removeCouponsData}
                        id={id}
                        setShowMainContainer={setVisible}
                        setValue={false}

                      // deleteBtn={
                      //   <button className="button" onClick={() => setVisible(false)}>
                      //     Yes I'm sure!
                      //   </button>
                      // }
                      /> : null}

                    <button
                      onClick={saveClickHandler}
                      className={cn("button", styles.button)}
                    >
                      {
                        loading ?
                          <Spinner loading={loading} size={15} />
                          :
                          <>
                            <label>Save</label>
                          </>
                      }
                    </button>
                  </>
                }
              </div>
            </Card>
          </>
        ) : (
          <>
            <ToastContainer />
            {isAdd ? <ProductMainContainer />
              : <Redirect to={{
                pathname: "/taxrate",
                state: archived ? { activeIndex: 2 } : null
              }} />
            }
          </>
        ) :
        <div className={styles.spinner} style={{ height: window.outerHeight - 250 }}>
          <Spinner loading={startLoading} size={150} color={'gray'} />
        </div>
      }
    </>
  );
};

export default EditNewCoupon;
