import React, { useState } from "react";
import cn from "classnames";
import styles from "./takeoff.module.sass";
import Icon from "../../../components/Icon";
import Image from "../../../components/Image";
import { Link, Router, useHistory } from "react-router-dom";
import { signupUser, signupV2 } from "../../../utils/apiCallHanlder";
import { localStorageItem, toastConfiguration } from "../../../utils/utils";
import { handleLoginSignUpResponse } from "../../../utils/utils";
import Spinner from "../../../utils/spinner";
import { toast, ToastContainer } from "react-toastify";

const Takeoff = ({
  className,
  data,
  payloadData,
  errorMessage,
  setErrorMessage,
  email,
  password,
  setError,
  error,
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const continueButtonClickHandler = async () => {
    setLoading(true);
    const signupResponse = await signupV2({
      email,
      authtype: payloadData.authtype,
      name: payloadData.name,
      signUpToken: payloadData.signUpToken,
    });
    const { data, code, error } = signupResponse;
    setLoading(false);
    if (code === 200 && data) {
      if (
        data.nextAction &&
        (data.nextAction.nextAction == "signup_verifyemail" ||
          data.nextAction.nextAction == "signup_details")
      ) {
        let nextActionStr = data.nextAction.nextAction;
        let signUpToken = data.nextAction.signUpToken;
        history.push({
          pathname: "/sign-up",
          state: { nextAction: nextActionStr, email: email, signUpToken },
        });
      }
    } else {
      toast.error(error, toastConfiguration);
    }

    //   history.push({
    //     pathname: '/sign-up',
    //     state: {nextAction: "signup_details", email : email}
    //   });
  };

  return (
    <>
      <ToastContainer></ToastContainer>
      <div
        className={cn(styles.takeoff, className, { [styles.active]: visible })}
      >
        <button
          className={cn("button-small", styles.head)}
          onClick={continueButtonClickHandler}
        >
          {loading ? (
            <Spinner loading={loading} size={30} />
          ) : (
            "Yes, create a new account"
          )}
        </button>
      </div>

      {errorMessage !== "" ? (
        <div className={styles.errorContainer}>
          <span className={styles.errorMessage}>{errorMessage}</span>
        </div>
      ) : null}

      <div className={styles.body}>
        <div className={styles.top}>
          <button
            className={cn("close", styles.close)}
            onClick={() => setVisible(false)}
          >
            <Icon name='close' size='20' />
          </button>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.imgWrapper}>
            <Image
              className={styles.pic}
              src='/images/content/takeoff.png'
              srcDark='/images/content/takeoff.png'
              alt='Core'
            />
          </div>
          <div className={styles.title}>
            9...8...7... get ready for takeoff!
          </div>
          <div className={styles.para}>
            Please check your email to verify your account
          </div>
          <Link className={styles.link} to='/'>
            Sign in
          </Link>
        </div>
      </div>
      <div className={styles.overlay} onClick={() => setVisible(false)}></div>
    </>
  );
};

export default Takeoff;
