import React, { useEffect, useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Checkbox from "../../../../../components/Checkbox";
import DeleteModal from "../../../../../components/DeleteModal";
import Icon from "../../../../../components/Icon";
import { dateFormatter } from "../../../../../utils/utils";
import Amount from "../../../../../components/Amount";
import Image from "../../../../../components/Image";
import AlternativeAvatar from "../../../../../components/AlternativeAvatar";

const Row = ({
  item,
  onChange,
  activeTable,
  activeId,
  className,
  editAction,
  deleteAction,
  singleCheck,
  editCustomer,
  activeIndex,
  checkCount,
  deleteIdArray,
}) => {
  const [visible, setVisible] = useState(false);
  const [phone, setphone] = useState("");
  item.icons = "dots";
  useEffect(() => {
    if (/\d+/.test(item?.phone)) {
      if (item?.phone?.length > 2) {
        setphone(item?.phone);
      }
    }
  }, [item?.phone]);
  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
      >
        <div className={styles.col}>
          <div className={styles.checkboxCol}>
            {singleCheck ? (
              singleCheck
            ) : (
              <>
                <Checkbox
                  className={styles.checkbox}
                  onChange={(e) => onChange(e, item.id)}
                  name={"cus-checkbox"}
                  value={deleteIdArray?.includes(item.id) ? true : false}
                />
                <div
                  className={cn(
                    styles.userImage,
                    checkCount < 1 ? [styles.showUserImg] : [styles.hideUserImg]
                  )}
                >
                  {item.imageUrl ? (
                    <Image
                      className={styles.avatar}
                      src={item.imageUrl}
                      srcDark={item.imageUrl}
                    />
                  ) : (
                    <AlternativeAvatar name={item.name} />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div
          onClick={() => editCustomer(item.id)}
          className={styles.editOverlay}
        ></div>
        <div className={styles.col}>
          <span className={styles.mblCreate}>Name:</span>
          <div className={styles.user}>
            <span>{item.name}</span>
          </div>
        </div>
        <div className={styles.col}>
          <span className={styles.mblCreate}>Email:</span>
          <div className={styles.date}>{item.email}</div>
        </div>
        {/* {activeIndex === 1 && (
          <div className={styles.col}>
            <div className={cn(styles.amount)}>
              {item.type}
              <Amount amount={item.amount} subtitleEmValue={"0.8em"} />
            </div>
          </div>
        )} */}
        {/* <div className={styles.col}>
          <span className={styles.mblCreate}>Created</span>
          <div className={styles.created}>{dateFormatter(item.createdOn)}</div>
        </div> */}
        <div className={styles.col}>
          <span className={styles.mblCreate}>Mobile:</span>
          <div className={styles.created}>{phone}</div>
        </div>
        {/* <div className={styles.col}>
          <span className={styles.mblCreate}>Type</span>
          <div className={styles.created}>{item.type}</div>
        </div> */}
        {/* <div className={styles.col}>
          <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div
              className={cn(styles.dotsBtn, className, {
                [styles.active]: visible,
              })}
            >
              <div className={styles.head}>
                <button
                  className={cn(styles.btn)}
                  onClick={() => setVisible(!visible)}
                >
                  <Icon name={item.icons} />
                </button>
              </div>
              <div className={styles.actionBody}>
                {editAction && editAction}
                {deleteAction && deleteAction}
              </div>
            </div>
          </OutsideClickHandler>
        </div> */}
      </div>
    </>
  );
};

export default Row;
