import React from "react";
import styles from "./PlanSubscription.module.sass";
import Modal from "../Modal";

const PlanSubscription = ({
  clientReferenceId,
  showPlanModal,
  onClose,
  customerEmail,
  clientSecret,
  isLivePlan
}) => {

  return (
    <>
      <Modal
        visible={showPlanModal}
        onClose={onClose}
        outerClassName={styles.outer}
      >
        <div className={styles.wrap}>
          <img
            className={styles.pic}
            src="/PlanSubscription.png"
            style={{ width: 126.5, height: 126.5 }}
            srcDark="/images/content/takeoff.png"
            alt="delete"
          />
          <div className={styles.email}>
            You discovered a premium feature.
          </div>
          <div className={styles.emailText}>
            Unlock advanced tools and features to take your business to the next level. Upgrade your account and scale faster with enhanced analytics, automation, and premium support.
          </div>
        </div>

        {isLivePlan == true ? 
          <>
            <stripe-pricing-table pricing-table-id="prctbl_1PiYDkHrICMON4bUWF9FTVRP"
              publishable-key="pk_live_51Kc5qGHrICMON4bUP3Px7R2bSt3NQaU4DQjyeF3GwsrMXbhoZ6d4gKXYhTbgVq91Epo9W4RxQ7GUxwjGbdu7nvRE00sxgUgSMs"
              client-reference-id={clientReferenceId}
              customer-session-client-secret={clientSecret}>
            </stripe-pricing-table>
          </>
          :
          <>
            <stripe-pricing-table pricing-table-id="prctbl_1LUHVSHrICMON4bUvXtmSgJM"
              publishable-key="pk_test_51Kc5qGHrICMON4bU1q7HapT1k5E9qncUkR5eMhlHlTdsWWOUaWCpuPCAiTQIlTPt4s7GVzee8RzKX0XdWxuy4Oky00xLgPXWuy"
              client-reference-id={clientReferenceId}
              customer-session-client-secret={clientSecret}>
            </stripe-pricing-table>
          </>
        }

      </Modal>
    </>
  );
};

export default PlanSubscription;
