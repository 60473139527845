export const invoices = [
    {
      id: 1,
      customer: "hello@gotham.com",
      amount: 3200,
      invoiceStatus: "Open",
      created: "May 9, 2022",
      due: "June 9, 2022",
      invoiceNo: "DCC3DBD7-0006",
      icon: "dots",
      invoiceType: "Scheduled"
    },
    {
      id: 2,
      customer: "hello@gotham.com",
      amount: 3200,
      invoiceStatus: "Draft",
      created: "May 9, 2022",
      due: "June 9, 2022",
      invoiceNo: "DCC3DBD7-0006",
      icon: "dots",
      invoiceType: "Draft"
    },
    {
      id: 3,
      customer: "hello@gotham.com",
      amount: 3200,
      invoiceStatus: "Draft",
      created: "May 9, 2022",
      due: "June 9, 2022",
      invoiceNo: "DCC3DBD7-0006",
      icon: "dots",
      invoiceType: "Outstanding"
    },
    {
      id: 4,
      customer: "hello@gotham.com",
      amount: 3200,
      invoiceStatus: "Draft",
      created: "May 9, 2022",
      due: "June 9, 2022",
      invoiceNo: "DCC3DBD7-0006",
      icon: "dots",
      invoiceType: "Past Due"
    },
    {
      id: 5,
      customer: "hello@gotham.com",
      amount: 3200,
      invoiceStatus: "Open",
      created: "May 9, 2022",
      due: "June 9, 2022",
      invoiceNo: "DCC3DBD7-0006",
      icon: "dots",
      invoiceType: "Paid"
    },
  ];
  