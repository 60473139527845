import cn from "classnames";
import styles from "./Item.module.sass";

const Item = ({ item, setVisible, activeId, setActiveId, conversationActivated, showMode}) => {
  const handleClick = (id) => {
    setVisible(true);
    setActiveId(id);
    conversationActivated(id, item.title);
  };



  return (
    <div
      className={cn(
        styles.item,
        { [styles.new]: item.new },
        { [styles.online]: item.online },
        { [styles.active]: activeId === item.id },
        { [styles.hideclass]: (showMode == "open" ? !item.open : item.open) }
      )}
      onClick={() => handleClick(item.id)}
    >
      <div className={styles.details}>
        <div className={styles.head}>
          <div className={styles.title}>{item.title}</div>
          {/* <div className={styles.time}>{item.time}</div> */}
          <div className={styles.time}>{new Date(item.updated_at * 1000).toLocaleDateString()}</div>
        </div>
        <div
          className={styles.message}
          dangerouslySetInnerHTML={{ __html: item.source ? (item.source.body.substring(0, 20)) + '...' : '' }}
        ></div>
      </div>
    </div>
  );
};

export default Item;
