import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import Search from "./Search";
import Messages from "./Messages";
import Create from "./Create";
import Notification from "./Notification";
import User from "./User";

const Header = ({ onOpen, verificationStatus, changeProfile, connectStatus, forLogoutOnly, changeBuisnessLogo }) => {
  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    onOpen();
    setVisible(false);
  };

  return (
    <>
      {forLogoutOnly ?
        <div style={{
          position: 'absolute',
          right: 24,
          top: 24
        }}>
          <User
            className={styles.user}
            changeProfile={changeProfile}
            connectStatus={connectStatus}
            forLogoutOnly={forLogoutOnly}
          />
        </div>
        :
        <header className={styles.header}>
          <button
            className={styles.burger}
            onClick={() => handleClick()}
          ></button>
          <Search className={cn(styles.search, { [styles.visible]: visible })} />
          <button
            className={cn(styles.buttonSearch, { [styles.active]: visible })}
            onClick={() => {
              setVisible(!visible)
            }}
          >
            <Icon name="search" size="24" />
          </button>
          <div className={styles.control} onClick={() => setVisible(false)}>
            {/* <Create 
          className={styles.create}
           setCurrentlySelectedTab={setCurrentlySelectedTab}
           currentSelectedTab={currentSelectedTab}
           /> */}
            {/* <Notification className={styles.notification} /> */}
            <User
              className={styles.user}
              changeProfile={changeProfile}
              connectStatus={connectStatus}
              changeBuisnessLogo={changeBuisnessLogo}
            />
          </div>
        </header>
      }
      {verificationStatus && verificationStatus}
    </>
  );
};

export default Header;
