import React, { useReducer, useEffect, createRef, useState } from "react";
import Card from "../../../components/Card";
import styles from "./EmbeddedForms.module.sass";
import TextInput from "../../../components/TextInput";
import uuid from "react-uuid";
import Icon from "../../../components/Icon";
import $ from "jquery";
import SingleEmbeddedForm from "./SingleEmbeddedForm";
import Spinner from "../../../utils/spinner";
import { toast, ToastContainer } from "react-toastify";
import cn from "classnames";
import { addEmbededForm, getAllEmbededForm } from "../../../utils/apiCallHanlder";
import { toastConfiguration } from "../../../utils/utils";
import Modal from "../../../components/Modal";
import NewEmbeddedForm from "./NewEmbeddedForm";
import NoEmbeddedForm from "./NoEmbeddedForm";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

let page = 1;

const EmbeddedForms = () => {
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
    embeddedFormName: "",
    formBuilderData: "",
  });
  const [embeddedForms, setEmbeddedForms] = useState([]);
  const [visible, setVisible] = useState(false);
  const [showNewForm, setShowNewForm] = useState(false);

  const [startLoading, setStartLoading] = useState(false);
  const [isNextPageExists, setIsNextPageExists] = useState(false);

  const [createEmbededFormLoading, setCreateEmbededFormLoading] = useState(false);

  useEffect(async () => {
    await getEmbededFormFunction(1);
  }, []);

  const getEmbededFormFunction = async (currentPage) => {
    setStartLoading(true);
    const { data } = await getAllEmbededForm({
      pageSize: 30,
      page: currentPage,
    });
    if (data) {
      setIsNextPageExists(data?.isNextPageExist);
      if (currentPage === 1) {
        setEmbeddedForms(data?.embeddedForm);
      } else {
        setEmbeddedForms(embeddedForms.concat(data?.embeddedForm));
      }
    }
    setStartLoading(false);
  };

  const handleChange = (e) => {
    setUserInput({ [e.target.name]: e.target.value });
  };

  const remove = (id) => {
    setEmbeddedForms(embeddedForms.filter((embeddedForm) => embeddedForm.id !== id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (createEmbededFormLoading) {
      return;
    }
    if (parseInt(userInput?.embeddedFormName?.trim()?.length || 0) < 1) {
      toast.error("Name is required", toastConfiguration);
      return;
    }
    setCreateEmbededFormLoading(true);
    const getDataBtn = document.querySelector(".get-data");
    if (getDataBtn !== null) {
      getDataBtn.click();
    }

    const formData = document.querySelector(".formData-json").innerText;

    const getDataOverlay = document.querySelector(".form-builder-overlay");
    if (getDataOverlay !== null) {
      getDataOverlay.click();
    }

    const { data } = await addEmbededForm({
      name: userInput.embeddedFormName,
      data: formData,
      baseUrl: "https://google.com",
    });

    if (data) {
      setEmbeddedForms([]);
      toast.success("Successfully created embeded form", toastConfiguration);
      await getEmbededFormFunction(1);
    } else {
      toast.error("Error in creating embeded form", toastConfiguration);
    }

    setUserInput({ embeddedFormName: "", formBuilderData: "" });
    $(fb.current).formBuilder([]);
    setVisible(false);
    setCreateEmbededFormLoading(false);
    setShowNewForm(false);
  };

  const update = (updateEmbeddedForm) => {
    const updateEmbeddedForms = embeddedForms.map((embeddedForm) => {
      if (embeddedForm.id === updateEmbeddedForm.id) {
        return {
          ...embeddedForm,
          embeddedFormName: updateEmbeddedForm.embeddedFormName,
          formBuilderData: updateEmbeddedForm.formBuilderData,
        };
      }
      return embeddedForm;
    });
    setEmbeddedForms(updateEmbeddedForms);
  };

  const fb = createRef();

  var options = {
    dataType: "json",
    disableFields: [
      "autocomplete",
      "file",
      "hidden",
      "date",
      "button",
      "checkbox-group",
      "header",
      "number",
      "paragraph",
      "radio-group",
      "select",
      "text",
      "textarea",
    ],
    /* inputSets: [ */
    formData: [
      {
        type: "text",
        label: "First Name",
        className: "form-control",
        placeholder: "Enter your first name",
        required: true,
      },
      {
        type: "text",
        label: "Last Name",
        className: "form-control",
        placeholder: "Enter your last name",
        required: true,
      },
      {
        type: "text",
        label: "Email",
        className: "form-control",
        placeholder: "Enter your email address",
        required: true,
      },
      {
        type: "text",
        label: "Phone Number",
        className: "form-control",
        placeholder: "Enter your phone number",
      },
      {
        type: "text",
        label: "Referred By",
        className: "form-control",
        placeholder: "How did you hear about us?",
      },
      /* {
        type: "text",
        label: "Session Type",
        className: "form-control",
        placeholder: "Enter the session type",
      }, */
      {
        type: "date",
        label: "Session Date",
        className: "form-control",
      },
      /* {
        type: "text",
        label: "Session Location",
        className: "form-control",
        placeholder: "Enter the session location",
      }, */
      {
        type: "textarea",
        label: "Message",
        className: "form-control",
        placeholder: "Enter your message here",
        required: true,
      },
      /* {
        type: "checkbox",
        label: "Newsletter",
        values: [
          {
            label: "Yes, I would like to signup to your newsletter",
            value: "option-1",
            selected: true,
          },
        ],
      }, */
      {
        type: "button",
        subtype: "submit",
        label: "Submit",
        className: "form-control",
      },
    ],
  };

  useEffect(() => {
    $(fb.current).formBuilder(options);
  }, [showNewForm]);

  return (
    <div>
      <button onClick={() => setShowNewForm(true)} className={cn("button", styles.addNewBtn)}>
        Create new form
      </button>
      <div className={styles.embeddedFormWrap}>
        <ToastContainer />
        {startLoading && embeddedForms?.length < 1 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Spinner size="64" color="gray" />
          </div>
        ) : (
          <>
            <div className={styles.left}>
              {embeddedForms.length > 0 ? (
                <div className={styles.embeddedFormsWrap}>
                  <div className={styles.embeddedFormHead}>
                    <p>Name</p>
                    <p></p>
                  </div>
                  {embeddedForms.map((x, index) => (
                    <SingleEmbeddedForm
                      x={x}
                      remove={remove}
                      update={update}
                      key={index}
                      fb={fb}
                      setEmbeddedForms={setEmbeddedForms}
                      getEmbededFormFunction={getEmbededFormFunction}
                    />
                  ))}
                  {isNextPageExists ? (
                    <div className={styles.foot}>
                      <button
                        className={cn("button-stroke-red button-small", styles.button)}
                        style={{ width: 250 }}
                        onClick={async () => {
                          if (!startLoading) {
                            page = page + 1;
                            await getEmbededFormFunction(page);
                          }
                        }}
                      >
                        {startLoading ? (
                          <Spinner size="24" color="gray" />
                        ) : (
                          <>
                            <span>See More forms</span>
                            <Icon name="arrow-next" size="20"></Icon>
                          </>
                        )}
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : (
                <NoEmbeddedForm onClick={() => setShowNewForm(true)} />
              )}
            </div>
          </>
        )}
      </div>
      {/* <Modal
        visible={showNewForm}
        onClose={() => setShowNewForm(false)}
        children={
          <Card 
            className={styles.card}
            classTitle={"title-blue"}
            title={"Embedded form"}
          >
            <form onSubmit={handleSubmit} id="formContainer">
              <div className={styles.top}>
                <TextInput
                  label={"Name of form"}
                  className={styles.input}
                  placeholder="Enter the Form name here"
                  onChange={handleChange}
                  name="embeddedFormName"
                  id="embeddedFormName"
                  type="text"
                  value={userInput.embeddedFormName}
                  required
                />
                <button className="button">
                  {createEmbededFormLoading ?
                    <Spinner size="24" color="white" />
                    :
                    <>
                      Save
                    </>
                  }
                </button>
              </div>
            </form>
          </Card>
        }
      /> */}
      <NewEmbeddedForm
        visible={showNewForm}
        onClose={() => setShowNewForm(false)}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        userInput={userInput}
        createEmbededFormLoading={createEmbededFormLoading}
        fb={fb}
      />
    </div>
  );
};

export default EmbeddedForms;
