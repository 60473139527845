import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./ConversationList.module.sass";
import Icon from "../../../components/Icon";
import Form from "../../../components/Form";
import Item from "./Item";
import { getAllUserConversations } from "../../../utils/apiCallHanlder";

const ConversationList = ({
  className,
  navigation,
  setVisible,
  search,
  setSearch,
  onSubmit,
  intercomUserId,
  newConversation,
  conversationActivated,
  isSignalFlag,
  currentMessangerConversationId
}) => {
  const [activeId, setActiveId] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [conversationMode, setConversationMode] = useState();

  useEffect(async () => {
    if (!intercomUserId) return;
    setLoading(true);
    const { data } = await getAllUserConversations(intercomUserId);
    if (data && data.conversations) {
      setConversations(data?.conversations);
      if (currentMessangerConversationId > 0) {
        setActiveId(currentMessangerConversationId);
      }
    }
    setLoading(false);
}, [isSignalFlag])

  
  useEffect(async () => {
    let currentSelection = navigation[activeIndex];
    setConversationMode(currentSelection.type);
  }, [activeIndex]);

  return (
    <div className={cn(className, styles.users)}>
      <Form
        className={styles.form}
        value={search}
        setValue={setSearch}
        onSubmit={onSubmit}
        placeholder="Search message"
        type="text"
        name="search"
        icon="search"
      />
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.button, {
              [styles.active]: activeIndex === index,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            <Icon name={x.icon} size="24" />
            {x.title}
          </button>
        ))}
      </div>

      <div className={styles.list}>
        {conversations.map((x, index) => (
          <Item
            item={x}
            activeId={activeId}
            setActiveId={setActiveId}
            setVisible={setVisible}
            key={index}
            conversationActivated={conversationActivated}
            showMode={navigation[activeIndex].type}
          />
        ))}
      </div>
          {
              <button type="button" className={styles.newConversation} onClick={newConversation}>
                  New conversation
              </button>
          }
      {/* <Form
        className={styles.form}
        value={search}
        setValue={setSearch}
        onSubmit={onSubmit}
        placeholder="Search message"
        type="text"
        name="search"
        icon="search"
      /> */}
    </div>
  );
};

export default ConversationList;
