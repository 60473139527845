import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./takeoff.module.sass";
import Icon from "../../../components/Icon";
import Image from "../../../components/Image";
import { Link, useHistory } from "react-router-dom";
import { useParams } from 'react-router';
import { handleLoginSignUpResponse, toastConfiguration } from "../../../utils/utils";
import { completeTeamInvite } from "../../../utils/apiCallHanlder";
import Spinner from '../../../utils/spinner';
import { toast, ToastContainer } from "react-toastify";

const Takeoff = ({ directSend, setDirectSend, className, data, name, password, errorMessage, setErrorMessage, code, lastName }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(async ()=>{
      if(directSend)
      {
        await continueButtonClickHandler(true);
      }
  },[])

  const continueButtonClickHandler = async (skipNameValidation) => {
    if(!skipNameValidation)
    {
        if (!name || !lastName) {
          toast.error("First and last names are required", toastConfiguration);
          return;
        }
    }

    const resendTeamInvitePayload = {
      name,
      password,
      code,
      surName: lastName,
      directSend
    }


    setLoading(true);
    const signupResponse = await completeTeamInvite(resendTeamInvitePayload);
    const { data, error } = signupResponse;

    if (data) {
      localStorage.setItem("stripeConnection", data.isConnected);
      localStorage.setItem("isPending", data.isPending);
      localStorage.setItem("isTestMode", data.istestmode);

      localStorage.setItem("connectStatus", JSON.stringify(data?.connectStatus));
      localStorage.setItem("currencySymbol", data.currencySymbol || "$");
      localStorage.setItem("currency", data.currency || 'USD');
        
      handleLoginSignUpResponse(data, history)
    } else {
      setErrorMessage(error);
      setDirectSend(false);
    }

    setLoading(false);
  }

  return (
    <>
      <ToastContainer />
      <div
        className={cn(styles.takeoff, className, { [styles.active]: visible })}
      >
        <button
          className={cn("button-small", styles.head)}
          onClick={continueButtonClickHandler}
        >
          {loading ? <Spinner loading={loading} size={30} /> : "Continue"}
        </button>
      </div>

      {errorMessage !== "" ? <div className={styles.errorContainer}><span className={styles.errorMessage}>{errorMessage}</span></div> : null}

      <div className={styles.body}>
        <div className={styles.top}>
          <button
            className={cn("close", styles.close)}
            onClick={() => setVisible(false)}
          >
            <Icon name="close" size="20" />
          </button>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.imgWrapper}>
            <Image
              className={styles.pic}
              src="/images/content/takeoff.png"
              srcDark="/images/content/takeoff.png"
              alt="Core"
            />
          </div>
          <div className={styles.title}>9...8...7... get ready for takeoff!</div>
          <div className={styles.para}>Please check your email to verify your account</div>
          <Link className={styles.link} to="/">
            Sign in
          </Link>
        </div>
      </div>
      <div className={styles.overlay} onClick={() => setVisible(false)}></div>
    </>
  );
};

export default Takeoff;
