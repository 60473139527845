import React, { useState } from "react";
import Card from "../../../../components/Card";
import Icon from "../../../../components/Icon";
import Modal from "../../../../components/Modal";
import styles from "./Code.module.sass";
import { NavLink } from "react-router-dom";
import cn from 'classnames'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const Code = ({ visible, setVisible, x }) => {
  const [showCode, setShowCode] = useState(false)
  const [copied, setCopied] = useState(false)
  const [code, setCode] = useState("")

  useState(() => {
    setCode(x?.code)
  })

  const handleCopied = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000);
  }

  return (
    <Card
          className={styles.card}
          children={
            <>
                <div onClick={() => setShowCode(true)} className={cn("button-stroke", styles.generateCodeBtn)}>
                  <Icon name="web_development" size="24" />
                  Generate code
                </div>
                {showCode ? ( 
                  x?.code &&
                <div className={styles.wrap}>
                  <div className={styles.code}>
                      <div className={styles.copyBtnWrap}>
                        <CopyToClipboard text={code} onCopy={handleCopied} >
                          <div className={cn(styles.copyBtn, {[styles.tooltip] : copied})}>
                            <Icon name="clipboard" size="24" />
                          </div>
                        </CopyToClipboard>
                      </div>
                      <p>{x?.code}</p>
                  </div>
                </div>
                ) : (
                  null
                ) }

                {/* <div className={styles.wrap}>
                  <div className={styles.code}>
                      <p>Form code will show here</p>
                  </div>
                  <div className={styles.helpWrap}>
                    <div className={styles.left}>
                      <div className={styles.icon}>
                      <Icon name="info" size="24" />
                      </div>
                      <p>
                        Need Help? If so, click the link to the right and Jon will
                        get back to you ASAP to assist with getting your forms up
                        and running on your website.
                      </p>
                    </div>
                    <div className={styles.right}>
                      <NavLink className={styles.link} to="#">
                        Email Support
                        <Icon name="arrow-right" size="24" />
                      </NavLink>
                    </div>
                  </div> 
                </div> */}
            </>
          }
        />
  );
};

export default Code;
