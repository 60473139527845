import React from "react";
import Image from "../../../components/Image";
import Card from "../../../components/Card";
import cn from "classnames";
import styles from "./MainBank.module.sass";
import Modal from "../../../components/Modal";
import { useState } from "react";
import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";
import Tooltip from "../../../components/Tooltip";

const mainBankAccounts = [
  {
    accName: "Evolve Bank Trust",
    accNum: "****6769",
    routingNum: "****0001",
    currency: "USD",
    flag: "./images/flag/us.png",
  },
];

const MainBank = ({ mainBankDetails }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [showMainDetails, setShowMainDetails] = useState(false);

  return (
    <>
      <Card className={cn(styles.cardWrap)}>
        <Card
          className={cn(styles.card)}
          classCardHead={styles.head}
        /* head={
          <div className={styles.headRight}>
            <div className={styles.headBtns}>
              <button
                className={cn("button-stroke button-small", styles.button)}
                onClick={() => {
                  setShowMainDetails(!showMainDetails);
                  setChangeMoreBtn(!changeMoreBtn);
                }}
              >
                {changeMoreBtn ? (
                  <>
                    <Icon name="arrow-up" size="16" />
                    Hide details
                  </>
                ) : (
                  <>
                    <Icon name="arrow-down" size="16" />
                    More details
                  </>
                )}
              </button>
            </div>
          </div>
        } */
        >
          <div className={styles.wrapper}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>Bank Account name</div>
                <div className={styles.col}>Account number</div>
                <div className={styles.col}>Routing Number</div>
                {/* <div className={styles.col}>Currency</div>
                <div className={styles.col}>Country</div> */}
                <div className={styles.col}></div>
              </div>
              {/* {mainBankAccounts.map((x, index) => ( */}
              <div className={styles.row}>
                <div className={styles.col}>
                  <p>Bank Account name</p>
                  <p>{mainBankDetails?.accountBankName}</p>
                </div>
                <div className={styles.col}>
                  <p>Account Number</p>
                  <p>{showMainDetails ?
                    mainBankDetails?.accountNumber
                    :
                    "****" + mainBankDetails?.accountNumberLast4
                  }</p>{" "}
                </div>
                <div className={styles.col}>
                  <p>Routing Number</p>
                  <p>
                    {showMainDetails ?
                      mainBankDetails?.accountRoutingNumber
                      :
                      "****" + mainBankDetails?.accountRoutingNumber?.substr(mainBankDetails?.accountRoutingNumber.length - 4)
                    }
                  </p>
                </div>
                {/* <div className={styles.col}>
                  <p>Currency</p>
                  <p>{mainBankDetails?.currency}</p>{" "}
                </div>
                <div className={styles.col}>
                  <p>Country</p>
                  <p>{mainBankDetails?.country?.toUpperCase()}</p>{" "}
                </div> */}
                <div className={styles.col} style={{ width: 200 }}>
                  <p></p>
                  <button className="button-stroke" onClick={() => setShowMainDetails(!showMainDetails)}>
                    {showMainDetails ? (
                      <>
                        <Icon name="lock" size="16" />
                        Hide details
                      </>
                    ) : (
                      <>
                        <Icon name="see" size="16" />
                        Show details
                      </>
                    )}
                  </button>
                </div>
                <div>

                </div>
              </div>
              {/* ))} */}
            </div>
          </div>
        </Card>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <h3>Your Main Bank Account</h3>
        <p>Your Payment Balance will go to this account</p>
        <TextInput label="Account Holder Name" className={styles.input} />
        <TextInput label="Routing Number" className={styles.input} />
        <TextInput label="Account Number" className={styles.input} />
        <button
          className={cn("button", styles.button)}
          onClick={() => setVisibleModal(false)}
        >
          Save
        </button>
        <p>Your Bank Account must be a checking account</p>
      </Modal>
    </>
  );
};

export default MainBank;
