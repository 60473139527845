import React, { useEffect, useState } from "react";
import Tabs from "./Tabs";
import Page from "../../components/Page";
import { useParams, withRouter } from "react-router";
import AddTaxRate from "./AddtaxRate";

const Settings = (props) => {
  const { activeIndex } = useParams();
  const [visible, setvisible] = useState(false);
  const [changeProfile, setChangeProfile] = useState(false);
  const [isFeatureChanged, setIsFeatureChanged] = useState(false);

  const [changeBuisnessLogo, setChangeBusinessLogo] = useState(false);

  const openModal = props.location?.state?.openModal;
  const isNewHeader = props?.location?.state?.isNewHeader;

  return (
    <Page
      title=''
      changeProfile={changeProfile}
      changeBuisnessLogo={changeBuisnessLogo}
      isFeatureChanged={isFeatureChanged}
    >
      {visible ? (
        <AddTaxRate visibletax={visible} setvisibletax={setvisible} />
      ) : (
        <Tabs
          visibletax={visible}
          setvisibletax={setvisible}
          activeIndex={activeIndex || "Profile"}
          setChangeProfile={setChangeProfile}
          changeProfile={changeProfile}
          openModal={openModal}
          isNewHeader={isNewHeader}
          setChangeBusinessLogo={setChangeBusinessLogo}
          changeBuisnessLogo={changeBuisnessLogo}
          isFeatureChanged={isFeatureChanged}
          setIsFeatureChanged={setIsFeatureChanged}
        />
      )}
    </Page>
  );
};

export default withRouter(Settings);
