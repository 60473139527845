import React, { useEffect, useState } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Row from "./Row";
import DeleteModal from "../../../../components/DeleteModal";
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import { coupons } from "../../../../mocks/coupons";

import Checkbox from "../../../../components/Checkbox";
import { TriStateCheckbox, TriStateContext } from "react-tri-state-checkbox";
import "react-tri-state-checkbox/styles/checkbox.css";

// data

import { productStatus } from "../../../../mocks/productStatus";
import {
  removeProductsData,
  getProductsData,
  getCouponsData,
  removeCouponsData,
} from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { zhCN } from "date-fns/locale";
import NoCoupon from "../../NoCoupon";
import LargeNav from "../../../../components/LargeNav";
import { useHistory } from "react-router";

let archivedLastId = "";
const tabContent = ["Contacts", "Products", "Templates", "Event", "Documents"];

const Table = ({
  className,
  setArchivedCheckCount,
  archivedCheckCount,
  editProduct,
  availableCheckCount,
  setActionAvailableProduct,
  deleteIdArray,
  setDeleteIdArray,
  setAvailableCheckCount,
  setShowMainContainer,
  setId,
  onNoProductClickHandler,
  setCustomerVisible,
  activeIndex,
  getCouponData,
  setIsLoading,
  loading,
  hasMore,
  couponsData,
}) => {
  const [archivedCouponsData, setArchivedCouponsData] = useState([]);
  const [archivedHasMore, setArchivedHasMore] = useState(null);

  const history = useHistory();

  useEffect(async () => {
    if (activeIndex === 1) {
      await getCouponData(false);
    } else {
      await getArchivedCouponsData(false);
    }
  }, []);

  const getArchivedCouponsData = async (isActiveIndexChanged) => {
    if (archivedCouponsData.length === 0) {
      archivedLastId = "";
    }
    setIsLoading(true);
    const archivedCouponsDataResponse = await getCouponsData(
      30,
      archivedLastId,
      true
    );
    const { data } = archivedCouponsDataResponse;

    if (data) {
      setArchivedHasMore(data.isNextPageExist);
      archivedLastId = data?.productList?.at(-1)?.id;
      !isActiveIndexChanged
        ? setArchivedCouponsData(archivedCouponsData.concat(data.productList))
        : setArchivedCouponsData(data.productList);
    }
    setIsLoading(false);
  };

  const couponsAvailableId = coupons
    .filter((x) => !x.isArchived)
    .map((x) => x.id);

  const couponsArchivedId = coupons
    .filter((x) => x.isArchived)
    .map((x) => x.id);

  /* Available coupon handling */
  const handleAvailableAll = (e) => {
    var ariaStatus = e.target.checked;
    if (ariaStatus === true) {
      const checkboxes = document.getElementsByName("prod-checkbox");

      for (var i = 0, n = checkboxes?.length; i < n; i++) {
        checkboxes[i].checked = true;
      }

      deleteIdArray = [];

      for (let i = 0; i < couponsData.length; i++) {
        deleteIdArray.push(couponsData[i]?.id);
      }

      setDeleteIdArray([...deleteIdArray]);

      setAvailableCheckCount(couponsData.length);
    }
    if (ariaStatus === false) {
      const checkboxes = document.getElementsByName("prod-checkbox");

      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }
      setAvailableCheckCount(0);
    }

    if (ariaStatus === "mixed") {
      setActionAvailableProduct(false);
      setAvailableCheckCount(0);
    }
  };

  const handleAvailableCoupon = (e, id) => {
    if (e.target.checked) {
      if (availableCheckCount + 1 === couponsData?.length) {
        const checkbox = document.getElementsByName("prod-all-checkbox");
        if (checkbox) {
          checkbox[0].checked = true;
        }
      }

      setAvailableCheckCount(availableCheckCount + 1);
      setDeleteIdArray([...deleteIdArray, id]);
    }
    if (!e.target.checked) {
      const checkbox = document.getElementsByName("prod-all-checkbox");
      if (checkbox) {
        checkbox[0].checked = false;
      }

      const index = deleteIdArray.indexOf(id);
      if (index > -1) {
        deleteIdArray.splice(index, 1);
      }
      setDeleteIdArray([...deleteIdArray]);

      setAvailableCheckCount(availableCheckCount - 1);
    }
  };

  /* Archived coupon handling */
  const handleArchivedAll = (e) => {
    var ariaStatus = e.target.checked;
    if (ariaStatus === true) {
      const checkboxes = document.getElementsByName("a-prod-checkbox");

      for (var i = 0, n = checkboxes?.length; i < n; i++) {
        checkboxes[i].checked = true;
      }

      deleteIdArray = [];

      for (let i = 0; i < archivedCouponsData.length; i++) {
        deleteIdArray.push(archivedCouponsData[i]?.id);
      }

      setDeleteIdArray([...deleteIdArray]);
      setArchivedCheckCount(archivedCouponsData.length);
    }
    if (ariaStatus === false) {
      const checkboxes = document.getElementsByName("a-prod-checkbox");

      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }
      setArchivedCheckCount(0);
    }

    if (ariaStatus === "mixed") {
      setArchivedCheckCount(0);
    }
  };

  const handleArchivedCoupon = (e, id) => {
    if (e.target.checked) {
      if (archivedCheckCount + 1 === archivedCouponsData?.length) {
        const checkbox = document.getElementsByName("a-prod-all-checkbox");
        if (checkbox) {
          checkbox[0].checked = true;
        }
      }

      setArchivedCheckCount(archivedCheckCount + 1);
      setDeleteIdArray([...deleteIdArray, id]);
    }
    if (!e.target.checked) {
      const checkbox = document.getElementsByName("a-prod-all-checkbox");
      if (checkbox) {
        checkbox[0].checked = false;
      }

      const index = deleteIdArray.indexOf(id);
      if (index > -1) {
        deleteIdArray.splice(index, 1);
      }
      setDeleteIdArray([...deleteIdArray]);

      setArchivedCheckCount(archivedCheckCount - 1);
    }
  };

  return (
    <>
      <LargeNav
        tabOptions={productStatus}
        activeTab={1}
        onClick={(x, index) => {
          if (index === 1) {
            history.push("/discounts");
          } else if (index === 2) {
            history.push("/taxRate");
          } else if (index === 0) {
            history.push({
              pathname: "/products",
            });
          }
        }}
        category="title"
        onlyLarge
      />
      {loading && couponsData.length < 1 && archivedCouponsData.length < 1 ? (
        <div
          className={styles.spinner}
          style={{ height: window.outerHeight - 450 }}
        >
          <Spinner loading={loading} size={50} color={"gray"} />
        </div>
      ) : (
        <>
          <div>
            {activeIndex === 1 && (
              <TriStateContext controls={couponsAvailableId}>
                <div className={cn(styles.wrapper, className)}>
                  {
                    couponsData.length > 0 ? (
                      <div className={cn(styles.table)}>
                        <div className={cn(styles.row)}>
                          <div className={styles.editOverlayHead}></div>
                          <div></div>
                          <div className={styles.col}>
                            <Checkbox
                              className={styles.checkbox}
                              onChange={handleAvailableAll}
                              name={"prod-all-checkbox"}
                            />
                          </div>
                          <div className={styles.col}>Name</div>
                          <div className={styles.col}>Terms</div>
                          <div className={styles.col}>Redemptions</div>
                          <div className={styles.col}>Expires</div>
                        </div>

                        {couponsData.map((x, index) => (
                          <Row
                            item={x}
                            key={index}
                            name="prod-checkbox"
                            onChange={handleAvailableCoupon}
                            isArchived={false}
                            availableCheckCount={availableCheckCount}
                            editAction={
                              <button
                                className={styles.actionItem}
                                onClick={() => {
                                  setId(x.id);
                                  setCustomerVisible(false);
                                }}
                              >
                                <Icon name="edit" size="24" />
                                Edit
                              </button>
                            }
                            archiveAction={
                              <button
                                className={styles.actionItem}
                                // onClick={() => {
                                //   setId(x.id);
                                // }}
                              >
                                <Icon name="inbox" size="24" />
                                Archive discount
                              </button>
                            }
                            toggleArchivedText="Archived Product"
                            deleteAction={
                              <DeleteModal
                                id={x.id}
                                deleteFunction={removeCouponsData}
                                setShowMainContainer={setShowMainContainer}
                                setValue={true}
                                icon={
                                  <Icon
                                    className={styles.btnIcon}
                                    fill="#FF4900"
                                    name="trash"
                                    size="20"
                                  />
                                }
                                className={styles.deleteBtn}
                                btnClass={cn(styles.btnClass)}
                              />
                            }
                            editProduct={editProduct}
                          />
                        ))}
                      </div>
                    ) : (
                      <NoCoupon onConfirm={onNoProductClickHandler} />
                    )
                    // <></>
                  }
                </div>
                {hasMore ? (
                  <div className={styles.foot}>
                    <button
                      className={cn(
                        "button-stroke-red button-small",
                        styles.button
                      )}
                      onClick={() => getCouponData(false)}
                      style={{ width: 200 }}
                    >
                      {loading ? (
                        <Spinner loading={loading} size={20} color={"gray"} />
                      ) : (
                        <>
                          <span>See More Coupons</span>
                          <Icon name="arrow-next" size="20"></Icon>
                        </>
                      )}
                    </button>
                  </div>
                ) : null}
              </TriStateContext>
            )}
          </div>
          <div>
            {activeIndex === 2 && (
              <TriStateContext controls={couponsArchivedId}>
                <div className={cn(styles.wrapper, className)}>
                  {archivedCouponsData.length > 0 ? (
                    <div className={cn(styles.table)}>
                      <div className={cn(styles.row)}>
                        <div className={styles.editOverlayHead}></div>
                        <div></div>
                        {/* <div className={styles.col}>
                            <Checkbox className={styles.checkbox} onChange={handleArchivedAll} name={"a-prod-all-checkbox"} />
                          </div> */}
                        <div className={styles.col}>Name</div>
                        <div className={styles.col}>price</div>
                        <div className={styles.col}>Tax</div>
                        <div className={styles.col}>Tax Category</div>
                        <div className={styles.col}>Type</div>
                        <div className={styles.col}></div>
                      </div>
                      {archivedCouponsData.map((x, index) => (
                        <Row
                          item={x}
                          isArchived={true}
                          name="a-prod-checkbox"
                          onChange={handleArchivedCoupon}
                          archivedCheckCount={archivedCheckCount}
                          key={index}
                          setId={setId}
                          setCustomerVisible={setCustomerVisible}
                          // singleCheck={
                          //   <Checkbox
                          //     onClick={changeArchivedProduct}
                          //     className={cn(x.image ? styles.triCheckboxImg : styles.triCheckbox)}
                          //     id={x.id}
                          //   />
                          // }
                          archiveStatus="Archived"
                          toggleArchivedText="Unarchived Product"
                          deleteAction={
                            <DeleteModal
                              icon={
                                <Icon
                                  className={styles.btnIcon}
                                  fill="#FF4900"
                                  name="trash"
                                  size="20"
                                />
                              }
                              className={styles.deleteBtn}
                              btnClass={cn(styles.btnClass)}
                            />
                          }
                          editProduct={editProduct}
                        />
                      ))}
                    </div>
                  ) : (
                    <NoCoupon onConfirm={onNoProductClickHandler} />
                  )}
                </div>
                {archivedHasMore ? (
                  <div className={styles.foot}>
                    <button
                      className={cn(
                        "button-stroke-red button-small",
                        styles.button
                      )}
                      onClick={() => getArchivedCouponsData(false)}
                      style={{ width: 250 }}
                    >
                      {loading ? (
                        <Spinner loading={loading} size={20} color={"gray"} />
                      ) : (
                        <>
                          <span>See More Archived Products</span>
                          <Icon name="arrow-next" size="20"></Icon>
                        </>
                      )}
                    </button>
                  </div>
                ) : null}
              </TriStateContext>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Table;
