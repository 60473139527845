import React, { useState, useEffect } from "react";
import styles from "./MyCards.module.sass";
import Card from "../../../components/Card";
import cn from "classnames";
import Icon from "../../../components/Icon";
import NewCard from "./NewCard";
import DefaultCard from "../../../components/CreditCard";
import CardDetails from "./CardDetails";
import { useHistory } from "react-router-dom";
import { getCardDetails } from "../../../utils/apiCallHanlder";
import Spinner from "../../../utils/spinner";
import { useLocation } from "react-router-dom";
import NonDefaultCard from "../../../components/nonDefaultCard";

const otherCards = [
  {
    name: "Staff name Card",
    number: "**9878",
    cardColor: "purple",
  },
  {
    name: "Staff name Card",
    number: "**8790",
    cardColor: "green",
  },
];

const cardOwnersArray = [
  {
    cardOwnerName: "My own Card",
    value: true
  },
  {
    cardOwnerName: "Team Member 2",
    value: false
  },
  {
    cardOwnerName: "Team Member 3",
    value: false
  },
  {
    cardOwnerName: "Team Member 4",
    value: false
  },
];

const MyCards = ({
  className,
  setAddCard
}) => {
  const [showNewCard, setShowNewCard] = useState(false);
  const [visibleCardDetails, setVisibleCardDetails] = useState(false);
  const [showCards, setShowCards] = useState(false);

  const [isVirtual, setIsVirtual] = useState(true);
  const [isPhysical, setIsPhysical] = useState(false);
  const [cardOwners, setCardOwners] = useState(cardOwnersArray);
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [cardsArray, setCardsArray] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    const { data } = await getCardDetails({
      "onlyCard": true,
    });
    if (data) {
      setCardsArray(data?.cards);
    }

    setLoading(false);
  }, [])

  const history = useHistory();

  const NextClickHandler = () => {
    setShowCardDetails(true);
  }

  const stateCardDetails = useLocation()?.state?.visibleCardDetails;

  useEffect(() => {
    setVisibleCardDetails(stateCardDetails)
  }, [stateCardDetails])

  return (
    <>
      {!showNewCard && !visibleCardDetails ? (
        <Card
          className={cn(styles.card, className)}

          title="My Cards"
          /* classTitle="title-yellow" */
          head={
            <button
              //className={cn("button", styles.addBtn)}
              onClick={() => {
                history.push(`/accountsandcards/cards/createNew`)
                // setShowNewCard(true);
                // setAddCard(true);
              }}
            >
              <img src="/images/content/addCircle.svg" alt="plus-icon" />
              {/* <Icon name="plus" />
              Add */}
            </button>
          }
        >
          {loading ?
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Spinner size="40" color={"gray"} />
            </div>
            :
            cardsArray?.length > 0 ? (
              <>
                {/* <div className={styles.cardTitle}>Default</div> */}
                {cardsArray?.map((x, index) => {
                  return x?.isDefaultCard ? <div
                    key={index}
                    className={styles.defaultCard}
                    onClick={() => {
                      history.push(`/accountsandcards/cards/${x?.id}`)
                      // setAddCard(true);
                      // setVisibleCardDetails(true);
                    }}
                  >
                    <DefaultCard cardDetail={x} />
                  </div> : null
                })}

                {/* <div className={styles.cardTitle}>Other Cards</div> */}
                <div className={styles.otherCardsWrap}
                  style={cardsArray?.length > 3 ? { height: 300, overflowY: 'scroll' } : null}
                >
                  {cardsArray?.map((x, index) => {
                    return !x?.isDefaultCard ?
                      <div
                        key={index}
                        style={cardsArray?.length > 3 ? { marginRight: 10, height:110 } : { height:110 } }
                        className={styles.defaultCard}
                        onClick={() => {
                          history.push(`/accountsandcards/cards/${x?.id}`)
                        }}
                      >
                        <NonDefaultCard cardDetail={x} />
                      </div>
                      // <div
                      //   onClick={() => {
                      //     history.push(`/accountsandcards/cards/${x?.id}`)
                      //     //setAddCard(true);
                      //     //setVisibleCardDetails(true);
                      //   }}
                      //   key={index}
                      //   className={styles.singleCard}
                      // >
                      //   <div
                      //     className={cn(styles.creditCard, styles[x?.cardColor || "purple"])}
                      //   ></div>
                      //   <div className={styles.cardInfo}>
                      //     <div className={styles.name}>{x?.name}</div>
                      //     <div className={styles.number}>{"**" + x?.cardNumberLast4} </div>
                      //   </div>
                      // </div> 
                      : null
                  })}
                </div>
              </>
            ) : null}
        </Card>
      ) : null}
      {showNewCard && !visibleCardDetails ? (
        <NewCard
          setShowCards={setShowCards}
          setShowNewCard={setShowNewCard}
          setAddCard={setAddCard}
          isVirtual={isVirtual}
          isPhysical={isPhysical}
          showCardDetails={showCardDetails}
          cardOwners={cardOwners}
          setIsPhysical={setIsPhysical}
          setShowCardDetails={setShowCardDetails}
          setIsVirtual={setIsVirtual}
          NextClickHandler={NextClickHandler}
          setCardOwners={setCardOwners}
        />
      ) : null}
      {!showNewCard && visibleCardDetails ? (
        <CardDetails
          setShowCards={setShowCards}
          setShowNewCard={setShowNewCard}
          setAddCard={setAddCard}
          setVisibleCardDetails={setVisibleCardDetails}
        />
      ) : null}
    </>
  );
};

export default MyCards;
