import React from "react";
import Modal from "../Modal";
import styles from "./SaveSuccess.module.sass";
import Spinner from "../../utils/spinner";


const SaveSuccess = ({
  showSuccessModal,
  text,
  onClose,
  onConfirm,
  isForStripe,
  loading
}) => {
  return (
    <>
      <Modal
        visible={showSuccessModal}
        onClose={onClose}
        outerClassName={styles.outer}
      >
        <div className={styles.wrap}>
          <img
            className={styles.pic}
            src={isForStripe ? "/Error.svg" : "/SuccessTick.png"}
            style={{ width: 126.5, height: 126.5 }}
            alt="delete"
          />
          {!isForStripe && (
            <div className={styles.email}>
              Success!
            </div>
          )}
          <div className={styles.emailText}>
            {text}
          </div>
          <button onClick={onConfirm} className="button" style={{ width: 92 }}>
            {loading ?
              <Spinner size="24" color="white" />
              :
              (isForStripe ? "Connect" : "Done")
            }
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SaveSuccess;
