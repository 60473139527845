/* global Stripe */
import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ExportAllCoaAccountsPopup.module.sass";
import Icon from "../Icon";
import Card from "../Card";
import { Checkbox } from "react-tri-state-checkbox";
import { exportAllCOA, exportAllCOATransactions, exportCOATransactions, getStatement } from "../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../utils/utils";
import Spinner from "../../utils/spinner";
import DateRangeInput from "../DateRangeInput";
import Dropdown from "../COATYPE";


const ExportAllCoaAccountsPopup = ({
    className,
    visible,
    setVisible,
    overflow,
    accountData,
    downloadClickHandler,
    coa1Id,
}) => {

    const [isCsv, setIsCsv] = useState(true);
    const [isPdf, setIsPdf] = useState(false);
    const [loading, setLoading] = useState(false);

    const getStatementClickHandler = async () => {
        setLoading(true);
        const { data, error } = await exportAllCOA({
            ...accountData,
            coa1Id
        }, "csv")

        if (data) {
            const file = data?.file?.downloadURL;
            //window.open(file, "_blank")
            if (file)
                window.location = file;
        } else {
            toast.error("Error in downloading", toastConfiguration);
        }
        setLoading(false);
        setVisible(false);
    }

    return (
        <div
            className={cn(styles.deleteModal, className, {
                [styles.active]: visible,
            })}
        >
            <div className={`${styles.body} popupBody`}>
                <div className={styles.top}>
                    <button
                        className={cn("close", styles.close)}
                        onClick={() => setVisible(false)}
                    >
                        <Icon name="close" size="20" />
                    </button>
                </div>

                <Card
                    className={cn(styles.card, className)}
                    classCardHead={styles.head}
                    title="Export"
                    /* classTitle={cn("title-blue", styles.title)} */
                >
                    <br/>
                    <button
                        style={{ marginTop: 20, }}
                        onClick={getStatementClickHandler}
                        className={cn("button", styles.button)}
                    >
                        {loading ?
                            <Spinner size="24" color="white" />
                            :
                            <>
                                <span>Download</span>
                            </>
                        }
                    </button>
                </Card>
            </div >
            <div className={styles.overlay} onClick={() => setVisible(false)}></div>
        </div >
    );
};

export default ExportAllCoaAccountsPopup;
