import React, { useReducer, useState } from "react";
import styles from "./Studio.module.sass";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import cn from "classnames";
import Events from "./Events";
import Checklist from "./Checklist";
import Contract from "./Contract";
import Questionnaire from "./Questionnaires";
import Email from "./Email";
import EmbeddedForms from "./EmbeddedForms";
import { useHistory, useParams, withRouter } from "react-router";
import Icon from "../../components/Icon";
import { useEffect } from "react";
import {
  getAllContracts,
  getAllEmailsStudio,
  getSingleContractById,
} from "../../utils/apiCallHanlder";
import LargeNav from "../../components/LargeNav";
import Portal from "./Portal";
import NewEmailForm from "./Email/NewEmailForm";
import Setup from "./Setup";
import Documents from "../Accounting/Documents";
import Spinner from "../../utils/spinner";

const tabContent = [
  "Contacts",
  "Products",
  "Templates",
  "Event",
  "Documents",
];

const subMenus = [
  "Email",
  "Contract",
  //"Questionnaire",
  "Form",
  "Checklist"
]

const Studio = (props) => {
  const { tabIndex, id } = useParams();
  const history = useHistory();
  const [singlePageStatus, setSinglePageStatus] = useState(false);

  const [mobileTab, setMobileTab] = useState(tabContent[0]);
  const [activeIndex, setActiveIndex] = useState(parseInt(tabIndex) || 0);

  const [visible, setVisible] = useState(false);
  const [isUpdateVisible, setIsUpdateVisible] = useState(false);

  const [startLoading, setStartLoading] = useState(false);

  const [emails, setEmails] = useState([]);
  const [isNextPageExists, setIsNextPageExists] = useState(false);
  const [subMenu, setSubMenu] = useState(1);
  const [getContractLoading, setGetContractLoading] = useState(false);

  const [startEmailLoading, setStartEmailLoading] = useState(true);

  useEffect(() => {
    if (tabIndex !== undefined) {
      setActiveIndex(parseInt(tabIndex))
    }
  }, [tabIndex])

  const searchedContractId = props.location?.state?.searchedContractId;
  let isNewQuestionnaire = props.location?.state?.isNewQuestionnaire;
  let isNewContract = props.location?.state?.isNewContract;
  let comingFromChat = props.location?.state?.comingFromChat;
  let isUpdatedContract = props.location?.state?.isUpdatedContract;
  let contract = props.location?.state?.contract;

  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      contractName: null,
      trixEditorValue: null,
    }
  );

  const [x, setX] = useState(null);

  useEffect(() => {
    if (activeIndex === 0) {
      history.push(`/contacts`);
    } else if (activeIndex === 1) {
      history.push(`/products`);
    }
  }, [activeIndex])

  useEffect(async () => {
    setIsUpdateVisible(false);
    if (searchedContractId) {
      setStartLoading(true);
      const { data } = await getAllContracts({
        contractId: searchedContractId,
      });
      if (data) {
        setX(data?.contracts[0]);
        setIsUpdateVisible(true);
      }
      setStartLoading(false);
    }
  }, [searchedContractId]);

  useEffect(() => {
    if (x) {
      setUserInput({
        trixEditorValue: x?.description,
        contractName: x?.name,
      });
      setIsUpdateVisible(true);
    }
  }, [x]);

  useEffect(() => {
    if (isNewContract) {
      setVisible(true);
    }
  }, [isNewContract])

  useEffect(async () => {
    if (contract) {
      setGetContractLoading(true);
      const { data } = await getSingleContractById(contract);
      if (data) {
        const extractedContract = data?.contractTemplate;
        setX(extractedContract);
        setUserInput({
          trixEditorValue: extractedContract?.description,
          contractName: extractedContract?.name,
        });
        setIsUpdateVisible(true);
      }
      setGetContractLoading(false);
    }
  }, [isUpdatedContract])



  useEffect(async () => {
    if (!id) {
      setEmails([]);
      await getEmailFunction(1);
    }
  }, [id]);

  const getEmailFunction = async (currentPage) => {
    setStartEmailLoading(true);
    const { data } = await getAllEmailsStudio({
      pageSize: 30,
      page: currentPage,
    });
    if (data) {
      setIsNextPageExists(data?.isNextPageExist);
      if (currentPage === 1) {
        setEmails(data?.emailTemplates);
      } else {
        setEmails(emails.concat(data?.emailTemplates));
      }
    }
    setStartEmailLoading(false);
  };

  return (
    <>
      {getContractLoading ? <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spinner size="64" color="gray" />
      </div>
        : id ? (
          <NewEmailForm
            setSinglePageStatus={setSinglePageStatus}
            id={id === "add" ? null : id}
          />
        ) : (
          <>
            {!visible && !isUpdateVisible && (
              <>
                <Card
                  title={
                    (!visible || !isUpdateVisible) &&
                    (!singlePageStatus ? tabContent[tabIndex] : null)
                  }
                  border
                  classTitle={styles.cardTitle}
                  className={cn({
                    [styles.singlePageShow]: singlePageStatus
                  })}
                >
                  {(activeIndex === 2 || activeIndex > 4) && (
                    <LargeNav
                      className={styles.typeNav}
                      tabOptions={subMenus}
                      activeTab={subMenu - 1}
                      onClick={(x, index) => {
                        if (index === 0) {
                          setSubMenu(index + 1);
                          setActiveIndex(2);
                          history.push(`/studio/2`);
                          return;
                        }
                        setSubMenu(index + 1);
                        setActiveIndex(index + 4);
                        history.push(`/studio/${index + 4}`);
                      }}
                    />
                  )}
                  {activeIndex === 3 ? <Events /> : null}
                  {activeIndex === 4 && <Documents />}
                  {activeIndex === 2 ? (
                    <Email
                      setSinglePageStatus={setSinglePageStatus}
                      singlePageStatus={singlePageStatus}
                      emails={emails}
                      setEmails={setEmails}
                      isNextPageExists={isNextPageExists}
                      startLoading={startEmailLoading}
                      getEmailFunction={getEmailFunction}
                    />
                  ) : null}
                  {activeIndex === 5 && (!visible || !isUpdateVisible) && (
                    <Contract
                      visible={visible}
                      isNew={isNewContract}
                      setVisible={setVisible}
                      setIsUpdateVisible={setIsUpdateVisible}
                      isUpdateVisible={isUpdateVisible}
                      userInput={userInput}
                      startLoading={startLoading}
                      setStartLoading={setStartLoading}
                      setUserInput={setUserInput}
                      x={x}
                      setX={setX}
                      isComingFromChat={comingFromChat}
                    />
                  )}
                  {/* {activeIndex === 6 ? <Questionnaire isNew={isNewQuestionnaire} /> : null} */}
                  {activeIndex === 6 ? <EmbeddedForms /> : null}
                  {activeIndex === 7 ? <Checklist /> : null}
                </Card>
              </>
            )}
            {activeIndex === 5 && (visible || isUpdateVisible) ? (
              <Contract
                visible={visible}
                isNew={isNewContract}
                setVisible={setVisible}
                setIsUpdateVisible={setIsUpdateVisible}
                isUpdateVisible={isUpdateVisible}
                userInput={userInput}
                startLoading={startLoading}
                setStartLoading={setStartLoading}
                setUserInput={setUserInput}
                x={x}
                setX={setX}
                isComingFromChat={comingFromChat}
                head={
                <div
                  className={cn("button-stroke-red", styles.backBtn)}
                  onClick={() => {
                    if (comingFromChat) {
                      history.push("/ai");
                      return;
                    }

                    if (isNewContract) {
                      history.push({
                        pathname: "/studio/4",
                        state: { isNewContract: false }
                      })
                    }

                    setVisible(false);
                    setIsUpdateVisible(false);
                    setUserInput({
                      contractName: null,
                      trixEditorValue: null,
                    });
                    setX(null);
                  }}
                >
                  {comingFromChat ? "Cancel" : "Cancel"}
                </div>
                } 
              />
            ) : null}
          </>
        )}
    </>
  );
};

export default withRouter(Studio);
