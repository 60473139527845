import React, { useState, useEffect } from "react";
import Card from "../../../../../components/Card";
import styles from "./Accounts.module.sass";
import cn from "classnames";
import { GetLinkAccountData } from "../../../../../utils/apiCallHanlder";
import TextInput from "../../../../../components/TextInput";
import Spinner from "../../../../../utils/spinner";

const colorPallet = [
  {
    colorName: "gray",
  },
  {
    colorName: "blue",
  },
  {
    colorName: "purple",
  },
  {
    colorName: "green",
  },
  {
    colorName: "red",
  },
];

const Accounts = ({
  className,
  colorName,
  setColorName,
  ownerName,
  setOwnerName,
  linkAccount
}) => {
  return (
    <Card
      className={cn(styles.card, className)}
      title="Linked account"
      /* classTitle="title-green" */
    >
      <div className={styles.row} style={{ pointerEvents: 'none' }}>
        <div className={styles.col}>
          <TextInput
            className={styles.field}
            value={linkAccount.name}
            onChange={(event) => setOwnerName(event?.target?.value)}
            name="owner name"
            type="text"
            placeholder="Wells Fango Checking USD"
            label="Label of this account"
            required
          />
        </div>
        {/* <div className={styles.col}>
            <div className={styles.colorWrap}>
              <div className={styles.label}>Choose Account Color</div>
              {colorPallet.map((x, index) => (
                <button
                  onClick={() => setColorName(x.colorName)}
                  key={index}
                  className={cn(styles[x.colorName], styles.singleColor)}
                ></button>
              ))}
            </div>
          </div> */}
      </div>
    </Card>
  );
};

export default Accounts;
