import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../../components/Checkbox";
import Icon from "../../../../../components/Icon";
import DeleteModal from "../../../../../components/DeleteModal";
import Image from "../../../../../components/Image";
import { dateFormatter } from "../../../../../utils/utils";

const Row = ({
  item,
  onChange,
  activeTable,
  activeId,
  singleCheck,
  editDocument,
  deleteItem,
  attachmentClickHandler
}) => {
  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.documentFileId },
          { [styles.active]: activeTable }
        )}
      >
        <div className={styles.editOverlay}></div>
        {/* <div className={styles.col}>
          {singleCheck || <Checkbox onChange={onChange} />}
        </div> */}
        <div onClick={() => editDocument(item)} className={styles.col}>
          <div className={styles.title}>Id</div>
          <div className={styles.num}>{item.documentFileId}</div>
        </div>
        {/* <div className={styles.col}>
          <div className={styles.title}>DOC</div>
          <div className="">
            <Image src={item.img} srcDark={item.img} className={styles.img} />
          </div>
        </div> */}
        <div onClick={() => editDocument(item)} className={cn(styles.col, styles.nameCol)} style={{ width: 180 }}>
          <div className={styles.title}>Document Name</div>
          <div className={cn(styles.documentName)}>{item.displayName}</div>
        </div>
        {/* <div className={styles.col}>
          <span className={styles.title}>Transaction #</span>
          <div className={styles.transaction}>{item.transaction}</div>
        </div> */}
        <div onClick={() => editDocument(item)} className={styles.col}>
          <span className={styles.title}>Uploaded</span>
          <div className={styles.uploaded}>{dateFormatter(item.uploadedOn)}</div>
        </div>
        <div onClick={() => editDocument(item)} className={styles.col}>
          <span className={styles.title}>Status</span>
          <div className={styles.status}>{item.status}</div>
        </div>
        <div onClick={() => editDocument(item)} className={styles.col}>
          <span className={styles.title}>Type</span>
          <div className={styles.status}>{item?.documentType}</div>
        </div>
        <div className={cn(styles.col, styles.typeNumber)}>
          <div className={styles.action}>
            {/* <button className={styles.editBtn} onClick={() => { editDocument(item); }}>
              <Icon name="edit" size="24" />
            </button> */}

            <button className={styles.editBtn}>
              {item?.nextAction === "TransactionMatched" ? (
                <div onClick={() => attachmentClickHandler(item?.documentFileId, item?.attachmentLink)}>
                  <Icon name="attachment" size="24" />
                </div>
              ) :
                <Icon name="" size="24" />
              }
            </button>

            <a href={item.attachmentLink} download >
              <button className={styles.editBtn}>
                <Icon name="download" size="24" />
              </button>
            </a>
            <button className={styles.removeBtn}>
              <DeleteModal
                btnTxt={<Icon name="trash" size="24" />}
                btnClass=" "
                deleteFunction={deleteItem}
                id={item.documentFileId}
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Row;
