/* global Stripe */
import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import styles from "./Transactions.module.sass";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import Card from "../Card";
import Dropdown from "../Dropdown";
import Icon from "../Icon";
import StatementPopup from "../StatementPopup";
import DateRangeInput from "../DateRangeInput";
import {
  dateFormatterWithTimeZone,
  toastConfiguration,
  toTitleCase,
  transactionDateFormatter,
} from "../../utils/utils";
import Image from "../Image";

// date
import { dateFormatter } from "../../utils/utils";
import {
  getTransactionsNextPage,
  GetLinkAccountData,
  RefreshLinkAccountData,
  createLinkAccountSession,
} from "../../utils/apiCallHanlder";
import Spinner from "../../utils/spinner";
import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import Amount from "../Amount";
import LargeNav from "../LargeNav";

const mainTab = ["Reconcile", "Reports","Categories"];
const commonOptions = [
  "Action",
  "Statement",
  "Link a Bank Account",
  "See & edit account details"
]

const Transactions = ({
  className,
  payThroughTrans,
  setShowTransactionSend,
  comingFromTransactions,
  transactions: transaction,
  accountDetailPayload,
  notShowDropdown,
  notShowWholeTopInfo,
  setAccountDetailPayload,
  loadingForDetailData,
  accounts,
  isNextPageExist,
  lastId,
  id,
  setLastId,
  linkedAccountId,
  setTransactions,
  boxComponent,
  setIsNextPageExist,
  customNextPageLoad,
  currentlySelectedType,
  currentlySelectedAccountId,
  nextPageInfo,
  setNextPageInfo,
  isComingFromModal,
  tabSwitchLoading,
  setShowTransactionPopup,
  setCurrentTransactionObject,
  setCarouselWidth,
  setStartDateTransaction,
  setEndDateTransaction,
  startDateTransaction,
  endDateTransaction,
  setShowAddNewTransactions,
  showLinkButton,
  connect = 1
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const dropdownOptions = [
    ...commonOptions,
    "Add transaction",
  ]
  const transactionTargetRef = useRef();
  const [first, setfirst] = useState(type);
  const [account, setAccount] = useState(notShowDropdown ? "" : accounts[0]);
  const [selectedAccountId, setSelectedAccountId] = useState(
    notShowDropdown ? linkedAccountId : accounts[0]?.id
  );
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [transactions, settransactions] = useState(transaction);
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);
  const [refreshButtonLoading, setRefreshButtonLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [newDataLoading, setNewDataLoading] = useState(false);

  const [dropDownValue, setDropdownValue] = useState("Action");

  const history = useHistory();

  useEffect(() => {
    settransactions(transaction);
  }, [transaction]);

  useLayoutEffect(() => {
    var transactionCarouselWrapper =
      document.getElementById("transactionBoxSize");
    // setCarouselWidth(transactionCarouselWrapper.offsetWidth);
  });

  useEffect(() => {
    var transactionCarouselWrapper =
      document.getElementById("transactionBoxSize");
    setCarouselWidth && setCarouselWidth(transactionCarouselWrapper.offsetWidth);
    const handleResize = () => {
      setCarouselWidth && setCarouselWidth(transactionCarouselWrapper.offsetWidth);
    };
    if (transactionCarouselWrapper) {
      window.addEventListener("resize", handleResize);
    }
  }, []);

  const handleSingleTransaction = (x) => {
    setCurrentTransactionObject(x);
    setShowTransactionPopup(true);

    // history.push({
    //   pathname: `/transactionDetails/${x?.id}`,
    //   state: {
    //     isFetchTransactionData: false,
    //     transactionPayload: x,
    //     accountType: x.accountType,
    //     accountId: x.accountId,
    //     status: x.status,
    //     amount: x.amount,
    //     desc: x.description
    //   }
    // });
  };

  const getUpdatedTransactions = async (start, end, id, lastid, pagingInfo) => {
    if (customNextPageLoad) {
      customNextPageLoad();
      return;
    } else if (start || startDateTransaction || startDate) {
      if (pagingInfo) {
        setNewDataLoading(true);
      } else {
        setLoading(true);
      }
      const { data } = await getTransactionsNextPage({
        SelectedAccountId: currentlySelectedAccountId, //id ? id : selectedAccountId,
        FromDate: transactionDateFormatter(start ? start : (startDateTransaction || startDate)),
        ToDate: transactionDateFormatter(end ? end : (endDateTransaction || endDate)),
        NextPageInfo: pagingInfo,
        PageSize: 50,
      });
      if (data) {
        if (pagingInfo) {
          setTransactions([...transactions, ...data.transactions]);
          settransactions([...transactions, ...data.transactions]);
        } else {
          setTransactions(data.transactions);
          settransactions(data.transactions);
        }
        setIsNextPageExist(data?.isNextPageExist);
        setAccountDetailPayload(data.accountDetailPayload);
        if (setNextPageInfo) setNextPageInfo(data.nextPageInfo);
        if (setLastId) setLastId(data?.transactions?.at(-1)?.id);
      }
      if (pagingInfo) {
        setNewDataLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const getUpdatedTransactionsOnDateRangeChange = async (start, end, id) => {
    if (setLastId) {
      setLastId("");
      lastId = "";
    }
    getUpdatedTransactions(start, end, id);
  };

  const getUpdatedTransactionsOnAccountChange = async (start, end, id) => {
    if (setLastId) {
      setLastId("");
      lastId = "";
    }
    getUpdatedTransactions(start, end, id);
  };

  const refreshButtonHandler = async () => {
    setRefreshButtonLoading(true);
    const { data } = await RefreshLinkAccountData({ id: linkedAccountId });
    if (data) {
      await getLinkedAccountAfter30sec();
    }
  };

  const getLinkedAccountAfter30sec = async () => {
    setTimeout(async () => {
      const { data } = await GetLinkAccountData({
        id: linkedAccountId,
      });

      if (data) {
        accountDetailPayload.balance = data?.account?.balance;
      }
      setRefreshButtonLoading(false);
    }, 30000);
  };

  useEffect(() => {
    if (type) {
      if (type === "moneyout") {
        const negativeAmounts = transactions.filter((item) => {
          const amount = item.amount.replace(/\$/g, "");
          return parseFloat(amount) < 0;
        });
        settransactions(negativeAmounts);
      } else if (type === "moneyin") {
        const positiveAmounts = transactions.filter((item) => {
          const amount = item.amount.replace(/\$/g, "");
          return parseFloat(amount) > 0;
        });
        settransactions(positiveAmounts);
      }
    }
  }, []);

  const linkExistingBankAccountHandler = async () => {
    setLinkLoading(true);
    const { data } = await createLinkAccountSession();

    if (data) {
      var stripe = Stripe(data?.stripe_pk, {
        stripeAccount: data.stripeAccountId
      });
      stripe.collectFinancialConnectionsAccounts({
        clientSecret: data?.session?.clientSecret
      })
        .then(async function (result) {
          //result.financialConnectionsSession.accounts[0].id
          if (result.error) {
            setLinkLoading(false);
            // Inform the customer that there was an error.
            toast.error(result.error.message || "Failed to add linked account", toastConfiguration);
            // Handle next step based on length of accounts array
          } else if (result.financialConnectionsSession.accounts.length === 0) {
            setLinkLoading(false);
            toast.error("No accounts were linked", toastConfiguration);
          } else {
            await getUpdatedTransactions(startDateTransaction, endDateTransaction, "All")
            setLinkLoading(false)
          }
        }).catch(() => {
          setLinkLoading(false);
        })
    }
  }

  return (
    <>
      <StatementPopup
        visible={visible}
        setVisible={setVisible}
        currentlySelectedAccountId={currentlySelectedAccountId}
        startDate={transactionDateFormatter(startDateTransaction || startDate)}
        endDate={transactionDateFormatter(endDateTransaction || endDate)}
        overflow={"hidden"}
        accounts={accounts}
      />
      <ToastContainer />
      <Card
        className={cn(styles.card, className)}
        classCardHead={styles.head}
        title='Accounting'
        border
        /* classTitle={cn("title-blue", styles.title)} */
        id='transactionCard'
        head={
          <div
            className={styles.buttonContainer}
            style={{ marginRight: isComingFromModal ? 50 : 0 }}
          >
            {!isComingFromModal && (
              <Dropdown
                className={styles.actionDropdown}
                classDropdownHead={styles.actionDropdownHead}
                value={dropDownValue}
                setValue={() => { }}
                options={dropdownOptions}
                statementClickHandler={() => setVisible(true)}
                addTransactionClickHandler={() => {
                  setCurrentTransactionObject([]);
                  setShowAddNewTransactions(true);
                }}
                paysomeOneClickHandler={() => {
                  linkExistingBankAccountHandler();
                  // let hasFinancialAccount = false;
                  // for (let i = 0; i < accounts?.length; i++) {
                  //   if (accounts[i]?.type?.toLowerCase() === "financial") {
                  //     history.push("/transactions/send");
                  //     setShowTransactionSend(true);
                  //     hasFinancialAccount = true;
                  //     break;
                  //   }
                  // }

                  // if (!hasFinancialAccount) {
                  //   toast.error(
                  //     "No Financial account, signup for one !!!",
                  //     toastConfiguration
                  //   );
                  // }
                }}
                seeAndEditClickHanlder={() => {
                  if (currentlySelectedAccountId !== 'All') {
                    localStorage.setItem(
                      "currentlySelectedAccountId",
                      currentlySelectedAccountId
                    );
                  }

                  history.push("/settings/Accounts");
                }}
                width={280}
                showIcon={true}
              />
            )}
            <>
              {/* {currentlySelectedType === "financial" ?
                  <button
                    onClick={() => {
                      history.push("/transactions/send");
                      setShowTransactionSend(true);
                    }}
                    className={cn("button-stroke", styles.button)}
                  >
                    <Icon name="pay" size="24" viewBox="0 0 20 20" />
                    <span>Pay Someone</span>
                  </button>
                  : null
                }
                <button
                  className={cn("button-stroke", styles.button)}
                  onClick={() => setVisible(true)}
                >
                  <Icon name="statement" size="24" viewBox="0 0 20 20" />
                  <span>Statement</span>
                </button> */}
            </>
            {loadingForDetailData ? (
              <div style={{ marginTop: 10, marginLeft: 10 }}>
                <Spinner size='24' color='gray' />
              </div>
            ) : null}
          </div>
        }
      >
        <LargeNav
          className={styles.typeNav}
          tabOptions={mainTab}
          activeTab={0}
          onClick={(x, index) => {
            if (index === 1) {
              history.push("/accounting")
            } else if (index === 2) {
              history.push("/accounting/Reports")
            } else if (index === 3) {
              history.push("/accounting/COA");
            }
          }}
        />

        <div id='transactionBoxSize' ref={transactionTargetRef}>
          {boxComponent ? boxComponent : null}
        </div>

        <div className={styles.balanceWrapper}>
          <DateRangeInput
            classInputValue={styles.inputValue}
            addPoperClass={true}
            classInput={styles.input}
            icon='calendar'
            className={styles.calendar}
            setStartDate={setStartDateTransaction || setStartDate}
            setEndDate={setEndDateTransaction || setEndDate}
            getUpdatedDashBoardData={getUpdatedTransactions}
            startDate={startDateTransaction || startDate}
            endDate={endDateTransaction || endDate}
          />
          {
            /* accountDetailPayload.type === "External" && */ notShowDropdown ? (
              <div className={styles.lastRefresh}>
                <p>Last Refreshed</p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h5>
                    {dateFormatterWithTimeZone(
                      accountDetailPayload?.lastRefresh
                    )}
                  </h5>

                  <div
                    style={{
                      height: 50,
                      cursor: "pointer",
                      marginLeft: 20,
                    }}
                  >
                    {refreshButtonLoading ? (
                      <>
                        <Spinner size='24' color='gray' />
                      </>
                    ) : (
                      <div onClick={() => refreshButtonHandler()}>
                        <Icon name='refresh' size={16} viewBox={"0 0 24 24"} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null
          }
        </div>
        {(loading && nextPageInfo) || tabSwitchLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner
              size='44px'
              color='gray'
              loading={loading || tabSwitchLoading}
            />
          </div>
        ) : transactions?.length < 1 ? (
          showLinkButton ?
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 24
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 12
              }}>
                No Transactions Found
                <button
                  className="button"
                  onClick={linkExistingBankAccountHandler}
                  style={{ width: 250 }}
                >
                  {linkLoading ? <Spinner size="24" color="white" /> :
                    "Link Existing Bank Account"
                  }
                </button>
              </div>
            </div>
            :
            <div className={styles.noDataImg}>
              <Image
                src={"/images/icons/empty-black.png"}
                srcDark={"/images/icons/empty-white.png"}
              />
            </div>
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.table}>
              <div className={styles.rowWithoutHover}>
                <div className={styles.col}>Date</div>
                <div className={styles.col}>To/From</div>
                <div className={styles.col}>Account</div>
                <div className={styles.col} style={{ width: 150 }}>
                  Type
                </div>
                {/* <div className={styles.col}>Flow Type</div> */}
                {/* <div className={styles.col}>Status</div> */}
                <div className={styles.col}>Amount</div>
                <div className={styles.col}></div>
              </div>
              {transactions.map((x, index) => (
                <div
                  onClick={() => {
                    payThroughTrans
                      ? payThroughTrans(x)
                      : handleSingleTransaction(x);
                    //handleSingleTransaction(x);
                  }}
                  className={styles.row}
                  key={index}
                  style={{ cursor: "pointer" }}
                >
                  <div className={styles.col} style={{ width: 150 }}>
                    <div className={styles.label}>Date</div>
                    {dateFormatter(x.created)}
                  </div>
                  <div className={styles.col}>
                    <div className={styles.label}>To/From</div>
                    {x.description}
                  </div>
                  <div className={styles.col}>
                    <div className={styles.label}>Type</div>
                    {x.accountFullName || x.accountType}
                  </div>
                  <div className={styles.col}>
                    <div className={styles.label}>Type</div>
                    <span style={{ display: "flex", width: "max-content" }}>
                      {x.transactionDetail || x.accountType}
                    </span>
                  </div>
                  {/* <div className={styles.col}>
                          <div className={styles.label}>Payment Type</div>
                          {toTitleCase(x.flowType)}
                        </div> */}
                  {/* <div className={styles.col}>
                          <div className={styles.label}>Status</div>
                          <span className={cn(styles[x.status] || styles.draft)}>
                            {toTitleCase(x.status === "posted" ? "completed" : x.status)}
                          </span>
                        </div> */}
                  <div
                    className={styles.col}
                    style={
                      parseInt(x.amount?.replace("$", "")) < 0
                        ? { color: "red" }
                        : {}
                    }
                  >
                    <div className={styles.label}>Amount</div>
                    <Amount amount={x.amount} subtitleEmValue={"0.7em"} />
                  </div>
                  <div className={styles.col}>
                    <div className={styles.label}>Doc</div>
                    <span
                      className={cn(styles.doc, {
                        [styles.none]: x.attachmentCount === "none",
                      })}
                    >
                      {x.attachmentCount > 0 && (
                        <Icon size='24' name='attachment' viewBox='0 0 24 24' />
                      )}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {isNextPageExist ? (
          <div className={styles.foot}>
            <button
              className={cn("button-stroke-red button-small", styles.button)}
              onClick={() =>
                getUpdatedTransactions(null, null, null, lastId, nextPageInfo)
              }
              style={{ width: "250px" }}
            >
              {newDataLoading && nextPageInfo ? (
                <Spinner size='24px' color='gray' loading={newDataLoading} />
              ) : (
                !tabSwitchLoading && (
                  <>
                    <span>See More Transactions</span>
                    <Icon name='arrow-next' size='20'></Icon>
                  </>
                )
              )}
            </button>
          </div>
        ) : null}
      </Card>
    </>
  );
};

export default Transactions;
