import React, {useState} from "react";
import styles from "./Services.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Checkbox from "../../../components/Checkbox";

const service = ["Banking", "Payment", "Accounting"];

const Services = ({className , servicesFilterHandler , selectedFilters}) => {
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Services"
        /* classTitle="title-red" */
      >
        <div className={styles.txt}>
          Please choose what services you wish to use. You can update this
          later.{" "}
        </div>
        <ul className={styles.list}>
          {service.map((x, index) => (
            <li key={index}>
              <Checkbox
                className={styles.checkbox}
                content={x}
                value={selectedFilters.includes(x)}
                onChange={() => servicesFilterHandler(x)}
                key={index}
              />
            </li>
          ))}
        </ul>
      </Card>
      <Card
        className={cn(styles.card, className)}
        title="Financial Services"
        /* classTitle="title-green" */
      >
        <div className={styles.txt}>
          We use Stripe.com to make sure you get paid on time and to keep your
          bank and details secure. Click Save and continue to link your Great
          Week Account with Stripe.
        </div>
      </Card>
    </>
  );
};

export default Services;
