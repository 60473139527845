import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Row from "./Row";
import DeleteModal from "../../../../../components/DeleteModal";
import Icon from "../../../../../components/Icon";
import {
  TriStateCheckbox,
  TriStateContext,
} from "react-tri-state-checkbox";
import "react-tri-state-checkbox/styles/checkbox.css";
import { deleteCustomer, getPaymentLinks } from "../../../../../utils/apiCallHanlder";
import Spinner from '../../../../../utils/spinner';
import NoProductLink from "../../NoProductLink";
import { ToastContainer } from "react-toastify";

let lastId = "";

const Table = ({
  className,
  editCustomer,
  setCustomerId,
  setVisible,
  setShowMainContainer,
  setShowAddPaymentLink,

}) => {

  let [customerData, setCustomerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(null);
  const [fetchNewRecords, setFetchNewRecords] = useState(false);

  const customerId = customerData.map((x) => {
    return x.id;
  });

  if (customerData.length === 0) {
    lastId = "";
  }

  useEffect(async () => {
    lastId = "";
    setIsLoading(true);
    setCustomerData([]);
    customerData = [];
    await getCustomerData();
    setIsLoading(false);
  }, [fetchNewRecords])

  const getCustomerData = async () => {
    setIsLoading(true);
    const customersData = await getPaymentLinks(30, lastId);
    const { data } = customersData;
    if (data) {
      setHasMore(data.isNextPageExist);
      lastId = data?.paymentLinkList?.at(-1)?.id;
      setCustomerData(customerData.concat(data?.paymentLinkList));
    }
    setIsLoading(false);
  }

  return (
    <TriStateContext controls={customerId}>
      <div className={cn(styles.wrapper, className)}>
        <ToastContainer />
        {isLoading && lastId === "" ?
          <div className={styles.spinner} style={{ height: window.outerHeight - 250 }}>
            <Spinner loading={isLoading} size={60} color={'gray'} />
          </div>
          :
          <div className={cn(styles.table)}>
            {customerData.length > 0 ?
              <>
                <div className={cn(styles.row)}>
                  {/* <div className={styles.editOverlayHead}></div> */}
                  <div className={styles.col} style={{ display: 'none' }}>

                  </div>
                  <div className={styles.col}>Link URL</div>
                  <div className={styles.col}>Name</div>
                  <div className={styles.col}>Price</div>
                  <div className={styles.col}>Status</div>
                    
                  <div className={styles.col}>Created</div>
                </div>
                {customerData.map((x, index) => (
                  <Row
                    item={x}
                    key={index}
                    setFetchNewRecords={setFetchNewRecords}
                    fetchNewRecords={fetchNewRecords}
                    editAction={
                      <button
                        className={styles.actionItem}
                        onClick={() => {
                          setVisible(false);
                          setCustomerId(x.id);
                        }}
                      >
                        <Icon name="edit" size="24" />
                        Edit
                      </button>
                    }
                    //onChange={changeCustomer}
                    deleteAction={
                      <DeleteModal
                        id={x.id}
                        deleteFunction={deleteCustomer}
                        setValue={true}
                        setShowMainContainer={setShowMainContainer}
                        icon={
                          <Icon
                            className={styles.btnIcon}
                            fill="#FF4900"
                            name="trash"
                            size="20"
                          />
                        }
                        className={styles.deleteBtn}
                        btnClass={cn(styles.btnClass)}
                      />
                    }
                    editCustomer={editCustomer}
                  />
                ))}
              </>
              :
              <NoProductLink
                onConfirm={() => {
                  setShowAddPaymentLink(true);
                }}
              />
            }
          </div>
        }
      </div>
      {hasMore ?
        <div className={styles.foot}>
          <button className={cn("button-stroke-red button-small", styles.button)} onClick={getCustomerData} style={{ width: 250 }}>
            {isLoading ?
              <Spinner loading={isLoading} size={20} color={'gray'} />
              :
              <>
                <span>See More Payment Links</span>
                <Icon name="arrow-next" size="20"></Icon>
              </>
            }

          </button>
        </div>
        : null
      }
    </TriStateContext>
  );
};

export default Table;
