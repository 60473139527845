import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Card from "../../../../../components/Card";
import TableInfo from "./TableInfo";
import Dropdown from "../../../../../components/Dropdown";
import Settings from "../Settings";
import Icon from "../../../../../components/Icon";
import Filters from "../../../../../components/Filters";
import NoQuote from "../../NoQuote";
// data
import { subscriptions } from "../../../../../mocks/subscriptions";
import { getAllQuotes, getSubscriptionsByStatus } from "../../../../../utils/apiCallHanlder";
import { useHistory } from "react-router";
import Spinner from "../../../../../utils/spinner";

const subscriptionsTypes = ["Current", "Canceled"];
const subscriptionsValues = ["active", "canceled"];
let lastId = "";

const Table = ({
  handleCurrentSubscriptions,
  handleCurrentSubscriptionsSingle,
  handleScheduledSubscriptions,
  handleScheduledSubscriptionsSingle,
  handleCancelledSubscriptions,
  handleCancelledSubscriptionsSingle,
  editAction,
  activeIndexx,
  actionBtnHandle,
  setSubscriptionId,
  setVisible
}) => {
  if (!activeIndexx) {
    activeIndexx = 1;
  }

  const [activeType, setActiveType] = useState(subscriptionsTypes[activeIndexx - 1]);
  const [activeIndex, setActiveIndex] = useState(1);
  const [hasSubscriptions, setHasSubscriptions] = useState(true);

  const [startLoading, setStartLoading] = useState(true);
  let [subscriptionData, setSubscriptionData] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const [needNewData, setNeedNewData] = useState(false);

  const history = useHistory()

  useEffect(async () => {
    if (subscriptionData.length < 1) {
      lastId = "";
    }
    await getSubscriptionByStatusHandler(subscriptionsValues[activeIndexx - 1]);
  }, [needNewData])


  const getSubscriptionByStatusHandler = async (status) => {
    setStartLoading(true);
    const { data } = await getAllQuotes(20, lastId);
    if (data) {
      setSubscriptionData(subscriptionData.concat(data?.quoteList));
      lastId = data?.quoteList?.at(-1)?.id;
      setHasMore(data?.isNextPageExist);
    }
    setStartLoading(false);
  }

  useEffect(() => {
    setActiveIndex(subscriptionsTypes.indexOf(activeType) + 1);
  }, [activeType]);

  const currentSubscriptionsId = subscriptions
    .filter((x) => x.subscriptionsType === "Current")
    .map((x) => x.id);
  const scheduledSubscriptionsId = subscriptions
    .filter((x) => x.subscriptionsType === "Scheduled")
    .map((x) => x.id);
  const CancelledSubscriptionsId = subscriptions
    .filter((x) => x.subscriptionsType === "Cancelled")
    .map((x) => x.id);

  const needNewDataFunction = () => {
    subscriptionData = []
    setSubscriptionData([]);
    lastId = "";
    setNeedNewData(!needNewData);
  }


  return (
    <>
      <Card className={styles.card}>
        <div>
          <div className={styles.nav}>
            {/* <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={activeType}
              onChange={(value) => {
                const index = subscriptionsTypes?.indexOf(value);
                if (index > -1) {
                  lastId = "";
                  history.push(`/subscriptions/tabs/${index + 1}`)
                  setNeedNewData(!needNewData);
                  setSubscriptionData([]);
                }
              }}
              setValue={setActiveType}
              options={subscriptionsTypes}
              small
            /> */}
          </div>
        </div>
        <div>
          {startLoading && subscriptionData.length < 1 ?
            <div className={styles.spinner} style={{ height: window.outerHeight - 450 }}>
              <Spinner loading={startLoading} size={60} color={'gray'} />
            </div>
            :
            subscriptionData?.length > 0 ? (
              <>
                <TableInfo
                  subscriptionsId={currentSubscriptionsId}
                  handleSubscriptions={handleCurrentSubscriptions}
                  handleSubscriptionsSingle={handleCurrentSubscriptionsSingle}
                  editAction={editAction}
                  setSubscriptionId={setSubscriptionId}
                  subscriptions={subscriptionData}
                  setVisible={setVisible}
                  needNewData={needNewDataFunction}
                  isSubscriptionsType="Current"
                />
                {hasMore ?
                  <div className={styles.foot}>
                    <button className={cn("button-stroke-red button-small", styles.button)}
                      onClick={() => getSubscriptionByStatusHandler(subscriptionsValues[activeIndexx - 1])}
                      style={{ width: 220 }}>
                      {startLoading ?
                        <Spinner loading={startLoading} size={20} color={'gray'} />
                        :
                        <>
                          <span>See More Quotes</span>
                          <Icon name="arrow-next" size="20"></Icon>
                        </>
                      }

                    </button>
                  </div>
                  : null
                }
              </>
            ) : (
              <NoQuote
                onConfirm={() => {
                  history.push("/quote/add");
                }}
              />
            )}
        </div>
        {/* <div>
          {activeIndex === 2 &&
            (hasSubscriptions ? (
              <TableInfo
                subscriptionsId={scheduledSubscriptionsId}
                handleSubscriptions={handleScheduledSubscriptions}
                handleSubscriptionsSingle={handleScheduledSubscriptionsSingle}
                editAction={editAction}
                isSubscriptionsType="Scheduled"
              />
            ) : (
              <NoSubscriptions 
              noSubscriptionsText="You don't have any Scheduled Subscriptions"
              />
            ))}
        </div> */}
        {/* <div>
          {activeIndex === 2 &&
            (subscriptionData?.length > 0 ? (
              <TableInfo
                subscriptionsId={CancelledSubscriptionsId}
                handleSubscriptions={handleCancelledSubscriptions}
                handleSubscriptionsSingle={handleCancelledSubscriptionsSingle}
                editAction={editAction}
                isSubscriptionsType="Cancelled"
              />
            ) : (
              <NoSubscriptions
                noSubscriptionsText="You don't have any Cancelled Subscriptions"
              />
            ))}
        </div> */}
      </Card>
    </>
  );
};

export default Table;
