import React from 'react';
import TradingHistory from './TradingHistory';
import Steps from './Steps';
import Application from './Application';
import OfferRecieved from './OfferRecieved';
import LoadPaidOut from './LoadPaidOut';
import Spinner from '../../../utils/spinner';

import styles from './WorkingCapital.module.sass'

const WorkingCapital = ({
    currentStep,
    setCurrentStep,
    showApplication,
    setShowApplication,
    applicationData,
    loading,
    terms,
    setTerms,
    loanAmount,
    setLoanAmount,
    selectedAccount,
    setSelectedAccount,
    applicationOfferId,
    setApplicationOfferId,
    accounts,
    setAccounts,
    showWarning
}) => {
    return <>
        <Steps selected={currentStep} />
        {loading ?
            <div className={styles.spinner}>
                <Spinner size={48} color={"grey"} />
            </div>
            :
            <>
                {/* {currentStep === 0 &&
                    <TradingHistory
                        setCurrentStep={setCurrentStep}
                    />
                } */}
                {(currentStep === 0 || currentStep === 1) &&
                    <Application
                        currentStep={currentStep}
                        showApplication={showApplication}
                        setShowApplication={setShowApplication}
                        applicationData={applicationData}
                        terms={terms}
                        setTerms={setTerms}
                        loanAmount={loanAmount}
                        setLoanAmount={setLoanAmount}
                        selectedAccount={selectedAccount}
                        setSelectedAccount={setSelectedAccount}
                        accounts={accounts}
                        setAccounts={setAccounts}
                        showWarning={showWarning}
                    />
                }
                {(currentStep === 2) &&
                    <OfferRecieved
                        applicationData={applicationData}
                        applicationOfferId={applicationOfferId}
                        setApplicationOfferId={setApplicationOfferId}
                    />
                }
                {(currentStep === 3) &&
                    <LoadPaidOut applicationData={applicationData}/>
                }
            </>
        }
    </>
}

export default WorkingCapital;