import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import styles from './PreviewProject.module.sass'
import Image from "../../../components/Image";
import GeneralDetails from "../DownloadProject/DealSheet/GeneralDetails";
import { useEffect, useState } from "react";
import { getPropertyLink } from "../../../utils/apiCallHanlder";
import { useParams, useHistory } from "react-router-dom";
import Spinner from "../../../utils/spinner";
import ProofAndFunds from "../DownloadProject/DealSheet/ProofAndFunds";
import ProfitAndLoss from "../../Accounting/ProfitAndLoss";
import BalanceSheet from "../../Accounting/BalanceSheet";
import CompanyDetails from "../DownloadProject/DealSheet/CompanyDetails";
import documentStyles from '../EditNewProject/Documents/Documents.module.sass';
import cn from 'classnames';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const DownloadPreview = ({
}) => {
    const history = useHistory();

    const { companyId, propertyId } = useParams();
    const [loading, setLoading] = useState(true);
    const [previewData, setPreviewData] = useState(null);
    const [parsedData, setParsedData] = useState(null);
    const [pdfLoading, setPdfLoading] = useState(false);

    useEffect(async () => {
        setLoading(true);
        const { data } = await getPropertyLink({
            unqiueCompanyId: companyId,
            uniquePropertyId: propertyId
        })
        if (data) {
            setParsedData(JSON.parse(data?.propertyLinkData?.dataString || ""))
            setPreviewData(data?.propertyLinkData)
        }

        setLoading(false);
    }, [])

    const downloadPdfHandler = async () => {
        fetch(previewData?.pdfUrl)
            ?.then(response => {
                response.blob()?.then(blob => {
                    let url = window?.URL?.createObjectURL(blob);
                    let a = document?.createElement('a');
                    a.href = url;
                    a.download = `${previewData?.returnUrl}.pdf`;
                    a.click();
                });
            })
    }

    const sharePdf = async () => {
        const shareData = {
            title: parsedData?.property?.name,
            text: 'Checkout my property',
            url: previewData?.returnUrl
        };
        if (navigator?.canShare(shareData)) {
            await navigator?.share(shareData);
        }
    }

    const generatePdf = async () => {
        setPdfLoading(true);
        window?.scrollTo(0, 0);
        document.body.style.overflowY = 'hidden';
        const hideElementsMapClass1 = document?.getElementsByClassName('gmnoprint');
        const hideElementsMapClass2 = document?.getElementsByClassName('gm-style-cc');
        const documentNameList = document?.getElementsByClassName('documentName');
        const crouselList = document?.getElementsByClassName('react-multi-carousel-list');
        const proofFundSection = document?.getElementById('proofFund');

        if (crouselList[0] && proofFundSection) {
            proofFundSection.style.width = `${crouselList[0]?.scrollWidth < 1000 ? 1000 : crouselList[0]?.scrollWidth}px`;
        }

        for (let i = 0; i < hideElementsMapClass1?.length; i++) {
            if (hideElementsMapClass1[i]) {
                hideElementsMapClass1[i].style.display = 'none';
            }
        }

        for (let i = 0; i < hideElementsMapClass2?.length; i++) {
            if (hideElementsMapClass2[i]) {
                hideElementsMapClass2[i].style.display = 'none';
            }
        }

        for (let i = 0; i < documentNameList?.length; i++) {
            if (documentNameList[i]) {
                documentNameList[i].style.color = 'blue';
            }
        }

        const content = document?.querySelector('#pdf-container');
        const divs = content?.querySelectorAll('#printAbleDiv');
        const doc = new jsPDF('portrait', 'mm', 'a4', true);

        for (let i = 0; i < divs?.length; i++) {
            if (divs[i]) {
                divs[i].style.backgroundColor = '#FFFFFF';
            }
        }

        let page = 1;

        function addContentToPDF(index) {
            if (index < divs?.length) {
                if (index > 0) {
                    doc.addPage();
                    page = page + 1;
                }

                html2canvas(divs[index], {
                    useCORS: true
                }).then(canvas => {
                    let imgData = canvas?.toDataURL('image/png');
                    let imgWidth = 210;
                    let pageHeight = 295;
                    let imgHeight = canvas.height * imgWidth / canvas.width;
                    let heightLeft = imgHeight;
                    let position = 10;

                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;

                    while (heightLeft >= 0) {
                        position += heightLeft - imgHeight;
                        doc.addPage();
                        page = page + 1;
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                    }

                    addContentToPDF(index + 1);
                });
            } else {
                for (let i = 0; i < hideElementsMapClass1?.length; i++) {
                    if (hideElementsMapClass1[i]) {
                        hideElementsMapClass1[i].style.display = 'unset';
                    }
                }

                for (let i = 0; i < hideElementsMapClass2?.length; i++) {
                    if (hideElementsMapClass2[i]) {
                        hideElementsMapClass2[i].style.display = 'unset';
                    }
                }

                for (let i = 0; i < documentNameList?.length; i++) {
                    if (documentNameList[i]) {
                        documentNameList[i].style.color = 'unset';
                    }
                }

                for (let i = 0; i < divs?.length; i++) {
                    if (divs[i]) {
                        divs[i].style.backgroundColor = '#FFFDF9';
                    }
                }

                if (proofFundSection) proofFundSection.style.width = 'unset';
                document.body.style.overflowY = 'auto';

                const textX = 7, textY = 42;
                parsedData?.documents?.map((item, index) => {
                    doc.link(textX, textY + (index * 10), 40, 5, {
                        url: item?.link,
                        pageNumber: page,
                        magFactor: 'Fit',
                    });
                })

                doc.save('property.pdf');
                setPdfLoading(false);
            }
        }

        addContentToPDF(0);
    }

    return (
        <>
            {loading ?
                <div className={styles.spinner}>
                    <Spinner size="48" color="gray" />
                </div>
                :

                <div className={styles.mainContainer}>
                    <Card
                        className={styles.card}
                    >
                        <div className={styles.head}>
                            <Link
                                className={styles.logo}
                                to={{ pathname: "https://greatweek.com" }}
                                target="_blank"
                            >
                                <Image
                                    className={styles.pic}
                                    src="/images/logo-dark.png"
                                    srcDark="/images/logo-light.png"
                                    alt="Core"
                                />
                            </Link>
                        </div>
                        <div className={styles.text}>
                            You are reviewing a deal sheet created for free on <a href="https://greatweek.com" target="_blank">www.greatweek.com</a>
                        </div>
                        <div className={styles.rowButton}>
                            <button className="button-stroke-red" onClick={generatePdf} style={{ width: 120 }}>
                                {pdfLoading ?
                                    <Spinner size="24" color="gray" />
                                    :
                                    "Download"
                                }
                            </button>
                            {/* <button className="button-stroke-red" onClick={sharePdf}>Share</button> */}
                        </div>
                        <div className={styles.signupButton}>
                            <button className="button" onClick={() => {
                                history.push("/sign-up");
                            }}>Sign up for a free account</button>
                        </div>
                    </Card>
                    <div className={styles.detailContainer}>
                        <div className={styles.detailCard} id="pdf-container">
                            {
                                parsedData?.property && (
                                    <GeneralDetails
                                        property={parsedData?.property}
                                        showMapControl={true}
                                    />
                                )
                            }
                            {
                                parsedData?.accounts && (
                                    <div id="proofFund">
                                        <ProofAndFunds
                                            accounts={parsedData?.accounts}
                                            transactions={parsedData?.transactions}
                                            filteredAccounts={parsedData?.accounts?.map(item => item?.id) || []}
                                            selectedAccounts={parsedData?.accounts?.map(item => item?.id) || []}
                                        />
                                    </div>
                                )
                            }
                            {
                                parsedData?.plData && (
                                    <Card title={"Company P&L"} className={styles.companyCard} id="printAbleDiv">
                                        <ProfitAndLoss
                                            hideTabs={true}
                                            plData={parsedData?.plData}
                                        />
                                    </Card>
                                )
                            }
                            {
                                parsedData?.balanceSheetData && (
                                    <Card title={"Balance Sheet"} className={styles.companyCard} id="printAbleDiv">
                                        <BalanceSheet
                                            hideTabs={true}
                                            balanceSheetData={parsedData?.balanceSheetData}
                                        />
                                    </Card>
                                )
                            }
                            {
                                parsedData?.companyDetails && (
                                    <CompanyDetails companyDetails={parsedData?.companyDetails} />
                                )
                            }
                            {
                                parsedData?.documents?.length > 0 && (
                                    <Card
                                        title={"Document Appendix"}
                                        className={styles.companyCard}
                                        id="printAbleDiv"
                                    >
                                        <div className={documentStyles.table}>
                                            <div className={documentStyles.head}>
                                                <div className={cn(documentStyles.col, documentStyles.colName)}>Name</div>
                                                <div className={cn(documentStyles.col, documentStyles.colName)}>Type</div>
                                            </div>
                                            {parsedData?.documents?.map((x, index) => (
                                                <div className={documentStyles.row} key={index}>
                                                    <div className={documentStyles.col}>
                                                        <p className={documentStyles.mblLabel}>Name</p>
                                                        <span className="documentName">{x.displayName}</span>
                                                    </div>
                                                    <div className={documentStyles.col}>
                                                        <p className={documentStyles.mblLabel}>Type</p>
                                                        <span>{x.documentType}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Card>
                                )
                            }
                        </div>
                    </div>
                </div >
            }
        </>

    );
};

export default DownloadPreview;
