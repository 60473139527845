import AlternativeAvatar from '../../../../components/AlternativeAvatar';
import Card from '../../../../components/Card';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { useEffect, useRef, useState } from 'react';
import Icon from '../../../../components/Icon';
import { chatDateFormatter, getChatMemberImageUrl, getChatMemberName, getCompanyData, getCurrentUser, toastConfiguration } from '../../../../utils/utils';
import styles from './messages.module.sass';
import messageStyles from '../Lenders/Table/Table.module.sass';
import TextInput from '../../../../components/TextInput';
import { createChatChannel, joinSingleChannel, listAllChannelsHandler } from '../../../../utils/apiCallHanlder';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../../../../utils/spinner';
import { useHistory } from 'react-router-dom';
import Image from '../../../../components/Image';

const ChatAPI = require("twilio-chat");

const Messages = ({ client, id, channelToCreate, backBtn, largeNav, propertyName }) => {
    const ref = useRef();
    const user = getCurrentUser();
    const company = getCompanyData();
    const history = useHistory();

    const [channels, setChannels] = useState([]);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    const [chatContainerWidth, setChatContainerWidth] = useState(0);
    const [currentChannel, setCurrentChannel] = useState(null);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [chatLoading, setChatLoading] = useState(false);
    const [channel, setChannel] = useState(null);

    useEffect(async () => {
        if (channelToCreate) {
            const { data } = await createChatChannel({
                lenderId: channelToCreate,
                propertyId: id
            })
            if (data) {
                setTimeout(async () => {
                    await listAllChannelsToShow(data?.channelId);
                }, 2000)
            } else {
                toast.error("Error in creating chat channel", toastConfiguration);
            }
        } else {
            await listAllChannelsToShow();
        }
    }, [channelToCreate])

    useEffect(async () => {
        if (currentChannel) {
            setChannel(null);
            setMessages([]);
            setChatLoading(true);
            const { data, error } = await joinSingleChannel({
                channelId: currentChannel?.conversation_sid
            })
            if (data) {
                try {
                    let chatClient = client;
                    if (!chatClient) {
                        const chatApiToken = localStorage.getItem("chatAPiToken");
                        chatClient = new ChatAPI.Client(chatApiToken, { logLevel: 'info' });
                    }
                    const clientChannel = await chatClient?.getChannelBySid(currentChannel?.conversation_sid);
                    const previousMessages = await clientChannel?.getMessages(1000);

                    setChannel(clientChannel);
                    setMessages([...(previousMessages?.items || [])])
                    await joinChannel(clientChannel);
                } catch (err) {
                    toast.error("Error in creating chat channel", toastConfiguration);
                }
            } else {
                toast.error(error || "Error in creating chat channel", toastConfiguration);
            }

            if (ref.current?.clientWidth) setChatContainerWidth(ref.current?.clientWidth);
            setChatLoading(false);
            scrollToBottom();
        }
    }, [currentChannel])

    const listAllChannelsToShow = async (channelToOpen) => {
        const { data } = await listAllChannelsHandler({
            propertyId: id
        });
        if (data?.channels) {
            if (channelToOpen) {
                data?.channels?.map(item => {
                    if (item?.conversation_sid === channelToOpen) {
                        const attributes = JSON.parse(item?.conversation_attributes || "");
                        setCurrentChannel({
                            ...item,
                            attributes: attributes
                        })
                    }
                })
            } else {
                if (data?.channels[0]) {
                    const attributes = JSON.parse(data?.channels[0]?.conversation_attributes || "");
                    setCurrentChannel({
                        ...data?.channels[0],
                        attributes: attributes
                    })
                }
            }

            setChannels(data?.channels)
        }
        setLoading(false);
    }

    const addLeadingZeroToSingleDigit = (number) => {
        return String(number).padStart(2, '0');
    }

    const joinChannel = async (clientChannel) => {
        if (clientChannel?.channelState?.status !== "joined") {
            await clientChannel.join();
        }

        clientChannel._events.messageAdded = undefined;
        clientChannel.on('messageAdded', function (message) {
            handleMessageAdded(message)
        });
    };

    const handleMessageAdded = message => {
        setMessages(messages => {
            if (messages?.length > message?.state?.index) {
                return [...messages]
            }
            return [...messages, message]
        });
        scrollToBottom();
    };

    const scrollToBottom = () => {
        const scrollDiv = document?.getElementById("chatDiv");
        const scrollHeight = scrollDiv?.scrollHeight;
        const height = scrollDiv?.clientHeight;
        const maxScrollTop = scrollHeight - height;
        scrollDiv.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    };

    const sendMessage = () => {
        if (message) {
            channel?.sendMessage(String(message).trim());
            setMessage('');
        }
    };

    return <Card
        className={id ? styles.card : ''}
        title="Property"
        head={backBtn}
        border
    >
        <ToastContainer />
        {largeNav}
        {loading ?
            <div className={styles.spinner}>
                <Spinner size="48" color="gray" />
            </div>
            :
            channels?.length > 0 ?
                <div className={styles.messagesWrapper}>
                    <div className={styles.leftWrapper}>
                        <span className={styles.header}>All messages</span>
                        <div className={styles.allMessages}>
                            {channels?.map((item, index) => {
                                const date = new Date(item?.conversation_date_updated || new Date());
                                const attributes = JSON.parse(item?.conversation_attributes || "");

                                return <div
                                    className={cn(styles.singleChannel,
                                        { [styles.activeChannel]: item?.conversation_sid === currentChannel?.conversation_sid })}
                                    key={index}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setCurrentChannel({
                                            ...item,
                                            attributes: attributes
                                        })
                                    }}
                                >
                                    <div className={styles.profileContainer}>
                                        {(
                                            attributes?.lenderImageUrl ||
                                            attributes?.imageUrl
                                        ) ?
                                            <img
                                                className={styles.profileImage}
                                                src={
                                                    company?.companyid === attributes?.CompanyId ?
                                                        attributes?.lenderImageUrl :
                                                        attributes?.companyImageUrl
                                                }
                                            />
                                            :
                                            <AlternativeAvatar name={attributes?.PropertyName || item?.conversation_friendly_name} />
                                        }

                                        <div className={styles.singleChannelInfo}>
                                            <span className={styles.lenderName}>
                                                {attributes?.PropertyName || item?.conversation_friendly_name}
                                            </span>
                                            <span className={styles.type}>{attributes?.LenderName}</span>
                                        </div>
                                    </div>
                                    <span className={styles.date}>
                                        {`${addLeadingZeroToSingleDigit(date?.getDate())}/${addLeadingZeroToSingleDigit(date?.getMonth() + 1)}`}
                                    </span>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className={styles.rightWrapper} ref={ref}>
                        <div className={styles.singleChannel}
                            style={{ alignItems: 'center', borderBottom: '1px solid #C7C5BF', borderRadius: 0 }}
                        >
                            <div className={styles.profileContainer}>
                                <div className={styles.link} onClick={() => {
                                    navigator?.clipboard?.writeText(currentChannel?.attributes?.propertyLinkUrl);
                                    toast.success("Property link copied successfully", toastConfiguration);
                                }}>
                                    <Icon name="link" size="24" fill="#C7C5BF" />
                                </div>
                                <div className={styles.profileImageWrapper}>
                                    {(
                                        currentChannel?.attributes?.imageUrl
                                    ) ?
                                        <img
                                            className={styles.companyImage}
                                            src={currentChannel?.attributes?.imageUrl}
                                        />
                                        :
                                        <AlternativeAvatar name={
                                            currentChannel?.attributes?.PropertyName ||
                                            currentChannel?.conversation_friendly_name
                                        } />
                                    }
                                </div>
                                <div className={styles.singleChannelInfo}>
                                    <span className={styles.lenderName}>{
                                        currentChannel?.attributes?.PropertyName ||
                                        currentChannel?.conversation_friendly_name
                                    }</span>
                                    <span className={styles.type}>{currentChannel?.attributes?.LenderName}</span>
                                </div>
                            </div>
                            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
                                <div
                                    className={cn(styles.dotsBtn, {
                                        [styles.active]: visible,
                                    })}
                                >
                                    <div className={styles.head}>
                                        <button
                                            className={cn(styles.btn)}
                                            onClick={() => setVisible(!visible)}
                                        >
                                            <Icon name="dots" />
                                        </button>
                                    </div>
                                    <div className={styles.actionBody}>
                                        <div className={styles.option} onClick={() => {
                                            setVisible(false);
                                            history.push({
                                                pathname: "/projects-download",
                                                state: {
                                                    propertyId: id || currentChannel?.attributes?.PropertyId
                                                }
                                            });
                                        }}>
                                            Edit property details
                                        </div>
                                        {/* <div className={styles.option} onClick={async () => {
                                        try {
                                            await channel?.delete();
                                        } catch (e) {
                                            console.log("err", e)
                                        }
                                    }}>Delete thread</div> */}
                                    </div>
                                </div>
                            </OutsideClickHandler>
                        </div>
                        <div className={cn("lendersChat", messageStyles.chatWrapper)} id="chatDiv">
                            <div className={messageStyles.messagesWrapper}>
                                {chatLoading ?
                                    <div className={messageStyles.chatSpinner}>
                                        <Spinner size="24" color="grey" />
                                        <span className={messageStyles.label}>Loading Chats</span>
                                    </div>
                                    :
                                    messages?.length > 0 ? messages?.map((item, index) => {
                                        const date = chatDateFormatter(item?.state?.timestamp || new Date());
                                        const _chatMemberName = getChatMemberName(item) || item?.state?.author;
                                        const _chatMemberImageUrl = getChatMemberImageUrl(item);
                                        return <div className={cn(messageStyles.singleMessageWrapper, {
                                            [messageStyles.senderMessage]: user?.email === item?.state?.author,
                                        })}>
                                            <div
                                                className={messageStyles.message}
                                                key={index}
                                            >
                                                {_chatMemberImageUrl
                                                    ? (<Image
                                                        className={styles.pic}
                                                        src={_chatMemberImageUrl}
                                                        srcDark={_chatMemberImageUrl}
                                                    />)
                                                    : (
                                                        <AlternativeAvatar name={_chatMemberName} />
                                                    )
                                                }
                                                <div className={messageStyles.messageBody}>
                                                    <div className={messageStyles.messageNameContainer}>
                                                        <span className={messageStyles.chatName}>{_chatMemberName}</span>
                                                        <span className={messageStyles.date}>
                                                            {date}
                                                        </span>
                                                    </div>
                                                    <span className={messageStyles.messageText}>
                                                        {item?.state?.body}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    }) : <div className={messageStyles.chatSpinner}>
                                        <span className={messageStyles.label}>No Messages !!!</span>
                                    </div>
                                }
                            </div>
                            {chatContainerWidth > 0 && (
                                <div className={messageStyles.chatInputContainer} style={{ width: chatContainerWidth }}>
                                    <div className={messageStyles.inputFlexContainer}>
                                        {/* <Icon name="attachment" size="24" fill="#C7C5BF" /> */}
                                        <TextInput
                                            className={messageStyles.chatInput}
                                            classInput={messageStyles.chatClassInput}
                                            value={message}
                                            onChange={(e) => {
                                                setMessage(e.target.value);
                                            }}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    sendMessage();
                                                }
                                            }}
                                        />
                                        <div onClick={sendMessage}>
                                            <Icon name="send" size="24" fill="#C7C5BF" />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                :
                <div className={styles.noChannel}>
                    No Chat initiated with lender yet
                </div>
        }

    </Card>
}

export default Messages;