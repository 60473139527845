import React from "react";
import QuotesOverview from "./QuotesOverview";
import NewQuote from "./NewQuote";

const Quotes = ({  subId, currentPage, customerId }) => {
  return (
    <>
      {subId ?
        <NewQuote id={subId === "add" ? null : subId} customerId={customerId} />
        :
        <QuotesOverview
          currentPage={currentPage}
        />
      }
    </>
  );
};

export default Quotes;
