import { useEffect, useState } from "react";

const PdfViewer = ({ src, closeButton }) => {
    const [loading, setLoading] = useState(true);
    const [blob, setBlob] = useState(null);

    useEffect(() => {
        if (src) {
            getBlobUrl(src);
        }
    }, [src])

    const getBlobUrl = async (url) => {
        const logoVideoXhr = new XMLHttpRequest();
        logoVideoXhr.open("GET", url, true);
        logoVideoXhr.responseType = "arraybuffer";

        logoVideoXhr.send();

        logoVideoXhr.onload = function (oEvent) {
            const blob = new Blob([oEvent.target.response], {
                type: "application/pdf",
            });
            const blobURL = URL.createObjectURL(blob);
            setLoading(false);
            setBlob(blobURL);
        };
    };

    return !loading && <>
        <iframe
            src={blob + "#toolbar=0"}
            height={172}
            width={172}
            style={{ pointerEvents: "auto" }}
        />
        {closeButton}
    </>
}

export default PdfViewer;