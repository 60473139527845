import React, { useState } from "react";
import cn from "classnames";
import styles from "./UpdatePasswrod.module.sass";
import Icon from "../../../../../components/Icon";
import Item from "../../../Item";
import TextInput from "../../../../../components/TextInput";
import { updatePassword } from '../../../../../utils/apiCallHanlder';
import Spinner from '../../../../../utils/spinner';

const DeleteModal = ({ className, children, visible, setVisible }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPasswrod] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const [error, setNewError] = useState("");

    const passwordChangeHandler = async () => {
        if (oldPassword === "" || newPassword === "" || confirmNewPassword === "") {
            setNewError("Please Fill out all fields");
        }
        else if (newPassword !== confirmNewPassword) {
            setNewError("New password and confirm new password doesn't match");
        } else {
            const updatePasswordPayload = {
                currentpassword: oldPassword,
                newpassword: newPassword
            }

            setIsLoading(true);
            const updatePasswordResponse = await updatePassword(updatePasswordPayload);
            const { data } = updatePasswordResponse;

            if (data) {
                setVisible(false);
            } else {
                setNewError(updatePasswordResponse.error);
            }
            setIsLoading(false);
        }
    }

    return (
        <div
            className={cn(styles.deleteModal, className, {
                [styles.active]: visible,
            })}
        >
            <div className={styles.body}>
                <div className={styles.top}>
                    <button
                        className={cn("close", styles.close)}
                        onClick={() => setVisible(false)}
                    >
                        <Icon name="close" size="20" />
                    </button>
                </div>
                <div>
                    <Item
                        className={cn(styles.card, className)}
                        title="Update Password"
                        /* classTitle="title-secondary-03" */
                    >
                        <div className={styles.fieldset}>
                            <TextInput
                                className={styles.field}
                                label="Old password"
                                name="old-password"
                                type="password"
                                onChange={(event) => {
                                    setNewError("");
                                    setOldPassword(event?.target?.value)
                                }}
                                /* tooltip="Maximum 100 characters. No HTML or emoji allowed" */
                                required
                            />
                            <div className={styles.row}>
                                <TextInput
                                    className={styles.field}
                                    label="New password"
                                    name="new-password"
                                    type="password"
                                    onChange={(event) => {
                                        setNewError("");
                                        setNewPasswrod(event?.target?.value)
                                    }}
                                    /* tooltip="Maximum 100 characters. No HTML or emoji allowed" */
                                    required
                                />
                                <TextInput
                                    className={styles.field}
                                    label="Confirm new password"
                                    name="confirm-password"
                                    type="password"
                                    onChange={(event) => {
                                        setNewError("");
                                        setConfirmNewPassword(event?.target?.value)
                                    }}
                                    /* tooltip="Maximum 100 characters. No HTML or emoji allowed" */
                                    required
                                />
                            </div>
                            <button
                                className={cn("button", styles.button)}
                                onClick={passwordChangeHandler}
                            >
                                {
                                    isLoading ?
                                        <Spinner loading={isLoading} size={15} color={"blue"} />
                                        :
                                        <>
                                            <span> Save</span>
                                        </>
                                }
                            </button>
                            {error !== "" ?
                                <div className={styles.errorContainer}>
                                    <span className={styles.errorMessage}>{error}</span>
                                </div>
                                : null
                            }
                        </div>
                    </Item>
                </div>
            </div>
            <div className={styles.overlay} onClick={() => setVisible(false)}></div>
        </div>
    );
};

export default DeleteModal;
