import cn from 'classnames';
import styles from './totalbar.module.sass';

const TotalBar = () => {
    return <>
        <div className={styles.tableHeaderContainer}>
            <span className={styles.columnName}>Total</span>
            <span className={styles.columnName}>Lines: 2</span>
            <span className={styles.columnName}>Qty: 5</span>
            <span className={styles.columnName}></span>
            <span className={styles.columnName}>NET: $4210</span>
            <span className={styles.columnName}></span>
            <span className={styles.columnName}></span>
            <span className={styles.columnName}>VAT: 0.00</span>
            <span className={styles.columnName}>Total: $59.70</span>
            <span className={styles.columnName}></span>
            <span className={styles.plus}></span>
        </div>

        <div className={styles.lineTop}></div>
        <div className={styles.mainTotalBarContainer}>
            <div className={cn(styles.mainBarColumnContainer,styles.childWithPadding)}>
                <span className={styles.mainBarHeading}>Total</span>
                <span className={styles.mainBarValue}>$59.70</span>
            </div>
            <div className={cn(styles.mainBarColumnContainer,styles.childWithPadding)}>
                <span className={styles.mainBarHeading}>Discount %</span>
                <span className={styles.mainBarValue}>0.00</span>
            </div>
            <div className={cn(styles.mainBarColumnContainer,styles.childWithPadding)}>
                <span className={styles.mainBarHeading}>Discount Total</span>
                <span className={styles.mainBarValue}>$0.00</span>
            </div>
            <div className={cn(styles.mainBarColumnContainer,styles.childWithPadding)}>
                <span className={styles.mainBarHeading}>VAT</span>
                <span className={styles.mainBarValue}>0.00</span>
            </div>
            <div className={cn(styles.mainBarColumnContainer,styles.childWithPadding)}>
                <span className={styles.mainBarHeading}>Shipment</span>
                <span className={styles.mainBarValue}>$0.00</span>
            </div>
            <div className={cn(styles.mainBarColumnContainer,styles.childWithPadding)}>
                <span className={styles.mainBarHeading}>Adjustment</span>
                <span className={styles.mainBarValue}>$0.00</span>
            </div>
            <div className={cn(styles.mainBarColumnContainer, styles.totalBox)}>
                <span className={cn(styles.mainBarHeading, styles.totalBoxText)}>Total</span>
                <span className={cn(styles.mainBarValue, styles.totalBoxText)}>$59.70</span>
            </div>
        </div>
        <div className={styles.line}></div>
    </>
}

export default TotalBar;