import cn from 'classnames';
import styles from '../../../../Customers/CustomersOverview/Table/Table.module.sass';
import Card from "../../../../../components/Card";
import Row from './Row';
import { useEffect, useState } from 'react';
import {
    addCapitalVenturePipelineColumn,
    deleteVentureCapitalPipelineColumn,
    getVentureCapitalColumnList,
    updateCapitalVenturePipelineColumn,
    updateVentureCapitalColumnOrder
} from '../../../../../utils/apiCallHanlder';
import Spinner from '../../../../../utils/spinner';
import Modal from '../../../../../components/Modal';
import TextInput from '../../../../../components/TextInput';
import { ToastContainer, toast } from 'react-toastify';
import { toastConfiguration } from '../../../../../utils/utils';
import Icon from '../../../../../components/Icon';
import DeleteModal from '../../../../../components/DeleteModal';

const AdjustPipeline = ({ setShowAdjustPipeline, setShowAddColumnModal, showAddColumnModal, setColumnId, columnId }) => {
    const [columnName, setColumnName] = useState("");
    const [columnPercentage, setColumnPercentage] = useState("");
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);

    const [visibleDelete, setVisibleDelete] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteColumnId, setDeleteColumnId] = useState(undefined);

    useEffect(async () => {
        if (setShowAdjustPipeline) {
            await getColumnsList();
        } else {
            setItems([]);
            setLoading(false);
        }
    }, [setShowAdjustPipeline])

    const getColumnsList = async () => {
        setLoading(true);
        const { data } = await getVentureCapitalColumnList({});
        if (data) {
            setItems(data?.columns);
        }
        setLoading(false);
    }

    const handleDragStart = (event, item) => {
        event.dataTransfer.setData('text/plain', item?.pipelineColumnId);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = async (event, targetItem) => {
        event.preventDefault();

        if (targetItem?.pipelineColumnId) {
            setLoading(true);
            const itemId = event.dataTransfer.getData('text/plain');
            const updatedItems = items;

            const sourceIndex = updatedItems?.findIndex(item => item?.pipelineColumnId === parseInt(itemId || "0"));
            const targetIndex = updatedItems?.findIndex(item => item?.pipelineColumnId === targetItem?.pipelineColumnId);

            if (
                updatedItems[sourceIndex]?.columnType === "Prospect" ||
                updatedItems[targetIndex]?.columnType === "Prospect" ||
                parseInt(itemId || "0") === targetItem?.pipelineColumnId
            ) {
                setLoading(false);
                return;
            }

            const temp = updatedItems[sourceIndex];
            updatedItems[sourceIndex] = updatedItems[targetIndex];
            updatedItems[targetIndex] = temp;

            setItems(updatedItems);

            const ids = updatedItems?.map(item => item?.pipelineColumnId)
            await updateVentureCapitalColumnOrder({
                ids: ids
            })
            setLoading(false);
        }
    };

    const columnModalCloseHandler = () => {
        setShowAddColumnModal(false);
        setColumnName("");
        setColumnPercentage("");
        setColumnId(null);
    }

    const saveColumnHandler = async () => {
        if (!columnName || columnName?.trim() === "") {
            toast.error("Column name is required", toastConfiguration);
            return;
        }

        setAddLoading(true);
        const payload = {
            title: columnName,
            probability: columnPercentage || 0
        }

        const { data } = columnId ?
            await updateCapitalVenturePipelineColumn({
                pipelineColumnId: columnId,
                ...payload
            })
            : await addCapitalVenturePipelineColumn(payload)

        if (data) {
            toast.success(`Successfully ${columnId ? "updated" : "added"} pipeline column`, toastConfiguration);
            columnModalCloseHandler();
            await getColumnsList();
        } else {
            toast.error(`Error in ${columnId ? "updated" : "added"} pipeline column`, toastConfiguration);
        }
        setAddLoading(false);
    }

    const editColumnHandler = (item) => {
        setColumnId(item?.pipelineColumnId);
        setColumnName(item?.title);
        setColumnPercentage(item?.probability);
        setShowAddColumnModal(true);
    }

    const pipelineDeleteHandler = async (id) => {
        setDeleteLoading(true);
        const { data } = await deleteVentureCapitalPipelineColumn(id, {});
        if (data) {
            toast.success("Column deleted Successfully", toastConfiguration);
            setVisibleDelete(false);
            await getColumnsList();
        } else {
            toast.error("Error in deleting Column", toastConfiguration);
        }
        setDeleteLoading(false);
    }

    return (
        <>
            <ToastContainer />
            {/* Add Column Modal */}
            <Modal
                visible={showAddColumnModal}
                outerClassName={styles.modalOuter}
                onClose={columnModalCloseHandler}
                children={
                    <Card
                        title={"Column"}
                        head={
                            <button className='button' onClick={saveColumnHandler}>
                                {addLoading ?
                                    <Spinner size="24" color="white" />
                                    :
                                    columnId ? "Update" : "Save"
                                }
                            </button>
                        }
                        classCardHead={styles.cardHeader}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <TextInput
                                onChange={(event) => setColumnName(event.target.value)}
                                classInput={styles.input}
                                label='Name'
                                name='name'
                                type='text'
                                value={columnName}
                                required
                            />
                            <TextInput
                                onChange={(event) => setColumnPercentage(event.target.value)}
                                classInput={styles.input}
                                label='% Probability'
                                name='probability'
                                type='text'
                                value={columnPercentage}
                                required
                            />
                        </div>

                    </Card>
                }
            />

            <>
                <Card
                    id="card-pipe"
                >
                    {loading ?
                        <div className={styles.spinnerContainer}>
                            <Spinner size="48" color="gray" />
                        </div>
                        :
                        <div className={cn(styles.table)}>
                            <>
                                <div className={cn(styles.row)}>
                                    <div className={styles.col}></div>
                                    <div className={styles.col}>Name</div>
                                    <div className={styles.col}>% Probability</div>
                                    <div className={styles.col}># Prospects</div>
                                    <div className={styles.col}></div>
                                </div>
                                {items.map((item, index) => {
                                    return <>
                                        <div
                                            key={item.pipelineColumnId}
                                            draggable={true}
                                            onDragStart={(e) => handleDragStart(e, item)}
                                            onDragOver={handleDragOver}
                                            onDrop={(e) => handleDrop(e, item)}
                                            style={{
                                                position: 'relative',
                                                display: 'table-row',
                                                verticalAlign: 'middle',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => editColumnHandler(item)}
                                        >
                                            <Row
                                                index={index}
                                                item={item}
                                            />
                                        </div>
                                        <div style={{
                                            position: 'relative',
                                            left: (document?.getElementById("card-pipe")?.clientWidth) - 150,
                                            top: -38,
                                            visibility: item?.columnType !== "Prospect" ? 'visible' : 'hidden'
                                        }}>
                                            <DeleteModal
                                                id={item?.pipelineColumnId}
                                                visibleDelete={visibleDelete}
                                                setVisibleDelete={setVisibleDelete}
                                                deleteBtn={
                                                    <button
                                                        className='button'
                                                        onClick={() => pipelineDeleteHandler(deleteColumnId)}
                                                    >
                                                        {deleteLoading ? (
                                                            <Spinner loading={deleteLoading} size={30} />
                                                        ) : (
                                                            "Yes I'm sure!"
                                                        )}
                                                    </button>
                                                }
                                                button={
                                                    <button onClick={(event) => {
                                                        if (item?.columnType !== "Prospect") {
                                                            event.stopPropagation();
                                                            setDeleteColumnId(item?.pipelineColumnId);
                                                            setVisibleDelete(true);
                                                        }
                                                    }}>
                                                        <Icon name="trash" size="20" fill='#FF4900' />
                                                    </button>
                                                }
                                                icon={
                                                    <Icon
                                                        className={styles.btnIcon}
                                                        fill='#FF4900'
                                                        name='trash'
                                                        size='20'
                                                    />
                                                }
                                                className={styles.deleteBtn}
                                                btnClass={cn(styles.btnClass)}
                                            />
                                        </div>
                                    </>
                                })}
                            </>
                        </div>
                    }
                </Card>
            </>

        </>
    )
}

export default AdjustPipeline;