/* global Stripe */
import React, { useState, useEffect } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { GetAlreadyLaunchedIntercom, SetAlreadyLaunchedIntercom, getCompanyData, getCurrentUser, localStorageGetItem, localStorageItem, reverifyHandler, toastConfiguration } from "../../utils/utils";
import Modal from "../Modal";
import Card from "../Card";
import Icon from "../Icon";
import {
  createLinkAccountSession,
  syncBankAccountWithAccounting,
  getIdentityVerifySession,
  getStripeConnectionStatus,
  getAlerts,
  createFinancialAccount,
  getUserData
} from "../../utils/apiCallHanlder";
import Spinner from "../../utils/spinner";
import VerifyIdentityModal from "../VerifyIdentityModal";
import Image from "../Image";
import Theme from "../Theme";
import { toast } from "react-toastify";

let connectStatus = {
  status: -1
}; 

const Page = ({
  wide,
  children,
  title,
  changeProfile,
  setChangeProfile,
  viewnName,
  changeBuisnessLogo,
  isFeatureChanged
}) => {
  const [visible, setVisible] = useState(false);
  const [currentlySelectedTab, setCurrentlySelectedTab] = useState("none");

  const [showUI, setShowUI] = useState(false);
  // const [showUI, setShowUI] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [savedConnectStatus, setSavedConnectStatus] = useState(null);

  const [modalLoading, setModalLoading] = useState(false);
  const [isUnverified, setIsUnverified] = useState(false);
  const [showIdentityModal, setShowIdentityModal] = useState(false);

  const [bankAccountLoading, setBankAccountLoading] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [hasUnreadNotifications, setHasUnReadNotifications] = useState(false);

  const [verifyLink, setVerifyLink] = useState("");
  const history = useHistory();
  
  const searchParams = new URLSearchParams(window.location.search);
  const connectBank = searchParams.get("connectBank");
  const connectStripe = searchParams.get("connectStripe");
  const bookCall = searchParams.get("bookCall");
  const openAccount = searchParams.get("openAccount");

  let user = null;

  if (typeof window !== "undefined") {
    user = getCurrentUser();
  }

  const openFinancialAccount = async () => {
    const {data, error, message} = await createFinancialAccount({})
    
    if (data) {
      toast.success(message || "Financial account created successfully.", toastConfiguration);
    }
    else {
      toast.error(error || "Error in creating account", toastConfiguration);
    } 
  }

  const registerNotificationsHandler = async () => {
    const alerts = await getAlerts({
      OnlyUnread: true,
      page: 1,
      pageSize: 1
    })

    if (alerts?.data?.alertEvents?.length > 0) {
      setHasUnReadNotifications(true);
    }

    setTimeout(() => {
      registerNotificationsHandler();
    }, 60000)
}

useEffect(async () => {
  //for notification on left bottom
  if (!window?.isNotificationHandlerRegister) {
    window.isNotificationHandlerRegister = true;
    registerNotificationsHandler();
  }

  var isIntercomAlreadyBooted = window?.Intercom?.booted;

  connectStatus = JSON.parse(localStorage.getItem("connectStatus"));
  setSavedConnectStatus(connectStatus?.status);
  setIsHide(connectStatus?.isHide)

  if (connectStatus?.status !== 1) {
    checkUserConnectStatus();
  }

  if (
    connectStatus?.status === 0 ||
    connectStatus?.status === 2
  ) {
    setShowModal(false);
    setShowUI(true);
  } else if (connectStatus?.status === 3) {

    setModalLoading(true);
    setShowModal(false);
    setIsUnverified(true);
    setShowUI(true);
    setShowIdentityModal(true);

    const { data } = await getIdentityVerifySession();
    if (
      data &&
      (data?.status === "canceled" || data?.status === "requires_input")
    ) {
      setVerifyLink(data.redirectURL);
    }
    setModalLoading(false);
  } else {
    setShowUI(true);
    localStorage.setItem("stripeConnection", true);
  }

  let user = getCurrentUser();

  // if (
  //   !GetAlreadyLaunchedIntercom() &&
  //   user &&
  //   user.email &&
  //   user.intercomUserHash
  // ) {

  //   window.Intercom("boot", {
  //     app_id: "s0nhsawl",
  //     email: user.email,
  //     user_hash: user.intercomUserHash,
  //     hide_default_launcher: false,
  //     alignment: "right",
  //   });

   
  // let old_intercom_AlreadyShown = localStorageGetItem('intercom_AlreadyShown') || false;
  // localStorageItem("intercom_AlreadyShown", true);
  // console.log(GetAlreadyLaunchedIntercom(), 'GetAlreadyLaunchedIntercom',  old_intercom_AlreadyShown)
  // SetAlreadyLaunchedIntercom(true);
  //   if (connectStatus?.status !== 1) {
  //     window.Intercom('showSpace', 'tasks');
  //   }
  //   else {
  //     window.Intercom('show');
  //   }
  // }

  if (connectBank !== undefined && connectBank != null && connectBank === "true") {
    if (connectStatus?.status === 2) {
      await handleExistingBankAccount();
    }
  }

  if (connectStripe !== undefined && connectStripe != null && connectStripe === "true")  {
    if (connectStatus?.status === 0) {
      await reverifyHandler();
    }
  }

  if (bookCall !== undefined && bookCall != null && bookCall === "true")  {
    window.open("https://calendly.com/greatweek/30", "_blank");
  }

  if (openAccount !== undefined && openAccount != null && openAccount === "true")  {
    await openFinancialAccount();
  }

}, []);

const checkUserConnectStatus = async () => {
  const currentStatus = JSON.parse(localStorage.getItem("connectStatus"));
  const { data } = await getStripeConnectionStatus();
  if (data) {
    if (
      data.connectStatus?.status === currentStatus?.status &&
      data.connectStatus?.isHide === currentStatus?.isHide
    ) {
      try
      {
      let callSetChangeProfile = false;

      if(data.companyName)
      {
        let anyChangeDone = false;
        let company = getCompanyData();
        if(company && company.name != data.companyName)
        {
          anyChangeDone = true;
          company.name = data.companyName;
        }
        if(anyChangeDone)
        {
          callSetChangeProfile = true;
          localStorageItem("company", JSON.stringify(company));
        }
      }

      if(data.userFirstName || data.userLastName)
      {
        let anyChangeDone = false;
        let user = getCurrentUser();
        if(user && user.name != data.userFirstName)
        {
          anyChangeDone = true;
          user.name = data.userFirstName;
        }

        if(user && user.surname != data.userLastName)
        {
          anyChangeDone = true;
          user.surname = data.userLastName;
        }
        if(anyChangeDone)
        {
          callSetChangeProfile = true;
          localStorageItem("user", JSON.stringify(user));
        }
      }
      if(callSetChangeProfile && setChangeProfile)
      {
        setChangeProfile(!changeProfile);
      }
    }catch(e){}
      setTimeout(() => {
        checkUserConnectStatus();
      }, 15000);
    } else {
      SetAlreadyLaunchedIntercom(false);
      localStorage.setItem(
        "connectStatus",
        JSON.stringify(data.connectStatus)
      );
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }
};

const handleExistingBankAccount = async () => {
  setBankAccountLoading(true)
  const { data } = await createLinkAccountSession();

  if (data) {
    var stripe = Stripe(data?.stripe_pk, {
      stripeAccount: data.stripeAccountId
    });
    stripe.collectFinancialConnectionsAccounts({
      clientSecret: data?.session?.clientSecret
    })
      .then(async function (result) {
        //result.financialConnectionsSession.accounts[0].id
        if (result.error) {
          // Inform the customer that there was an error.
          toast.error(result.error.message || "Failed to add linked account", toastConfiguration);
          // Handle next step based on length of accounts array
        } else if (result.financialConnectionsSession.accounts.length === 0) {
          toast.error("No accounts were linked", toastConfiguration);
        } else {
          await syncBankAccountWithAccounting({isBankAccountCreated: true});
          history.push("/settings/Accounts");
        }
      })
  }

  setBankAccountLoading(false)
};



return (
  <>
    <div className={styles.verifyPage}>
      {showUI && (
        <>
          <Sidebar
            className={cn(styles.sidebar, { [styles.visible]: visible })}
            onClose={() => setVisible(false)}
            currentTab={currentlySelectedTab}
            setCurrentlySelectedTab={setCurrentlySelectedTab}
            hasUnreadNotifications={hasUnreadNotifications}
            setHasUnreadNotification={setHasUnReadNotifications}
            isFeatureChanged={isFeatureChanged}
          />
          <Header
            onOpen={() => setVisible(true)}
            changeProfile={changeProfile}
            setCurrentlySelectedTab={setCurrentlySelectedTab}
            currentSelectedTab={currentlySelectedTab}
            connectStatus={savedConnectStatus}
            changeBuisnessLogo={changeBuisnessLogo}
          />
          <div className={styles.inner}>
            <div
              className={cn(styles.container, {
                [styles.wide]: wide,
              })}
            >
              {showModal && (
                isUnverified ?
                  <div className={styles.cardContainer}>
                    <Card className={styles.card}>
                      {modalLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <Spinner size='24' color='gray' />
                        </div>
                      ) : (
                        <div className={styles.wrap}>
                          <>
                            <div
                              className={styles.headerText}
                            >
                              Verify your ID
                            </div>
                            <div className={styles.email} style={{ minHeight: 100 }}>
                              One last step, we need to verify your identity,
                              to enable all features. It only takes a few minutes.
                            </div>
                          </>
                          <button
                            onClick={async () => {
                              window.open(verifyLink, "_blank");
                            }}
                            className='button'
                            style={{ marginTop: 16 }}
                          >
                            Verify
                          </button>
                        </div>
                      )}
                    </Card>
                    {!isHide && <Card className={styles.card}>
                      <div className={styles.wrap}>
                        <div
                          className={styles.headerText}
                        >
                          Connect a bank account
                        </div>
                        <div className={styles.email} style={{ minHeight: 100 }}>
                          Let’s start by connecting an existing bank account to your Greatweek platform.
                          Once connected, you can start categorising your transactions and send invoices.
                        </div>
                        <button
                          onClick={handleExistingBankAccount}
                          className='button'
                          style={{ marginTop: 16, width: 106 }}
                        >
                          {bankAccountLoading ? <Spinner size="24" color="white" /> :
                            "Continue"
                          }
                        </button>
                      </div>
                    </Card>}
                  </div>
                  :
                  <div className={styles.cardContainer}>
                    {connectStatus?.status === 2 && (
                      <Card className={styles.card}>
                        <div className={styles.wrap}>
                          <div
                            className={styles.headerText}
                          >
                            You have completed signup
                          </div>
                          <div className={styles.email} style={{ minHeight: 100 }}>
                            We are reviewing some details and will notify you once your account is approved.
                            If you want you can set up an intro call.
                          </div>
                          <button
                            onClick={async () => {
                              window.open("https://calendly.com/greatweek/30", "_blank");
                            }}
                            className='button'
                            style={{ marginTop: 16 }}
                          >
                            Book a call
                          </button>
                        </div>
                      </Card>
                    )
                    }
                    {/* {!isHide && <Card className={styles.card}>
                      <div className={styles.wrap}>
                        <div
                          className={styles.headerText}
                        >
                          Connect a bank account
                        </div>
                        <div className={styles.email} style={{ minHeight: 100 }}>
                          Let’s start by connecting an existing bank account to your Greatweek platform.
                          Once connected, you can start categorising your transactions and send invoices.
                        </div>
                        <button
                          onClick={handleExistingBankAccount}
                          className='button'
                          style={{ marginTop: 16, width: 106 }}
                        >
                          {bankAccountLoading ? <Spinner size="24" color="white" /> :
                            "Continue"
                          }
                        </button>
                      </div>
                    </Card>}
                    {connectStatus?.status === 0 && (
                      <Card className={styles.cardFirst}>
                        <div className={styles.wrap}>
                          <div
                            className={styles.headerText}
                          >
                            Verify your business details
                          </div>
                          <div className={styles.email} style={{ minHeight: 100 }}>
                            You need to complete the business verification in order to use all features of the platform.
                            You will be redirected to our partner Stripe.com to complete onboarding.
                          </div>
                          <button
                            onClick={async () => reverifyHandler()}
                            className='button'
                            style={{ marginTop: 16 }}
                          >
                            Continue
                          </button>
                        </div>
                      </Card>
                    )} */}
                  </div>
              )}
              {viewnName !== "dashboard" && title && (
                <div className={cn("h3", styles.title)}>{title}</div>
              )}
              {children}
            </div>
          </div>
        </>
      )}
    </div>
  </>
);
};

export default withRouter(Page);
