import React, { useEffect, useState } from "react";
import styles from "./CreateNewProduct.module.sass";
import Modal from "../../../../../../components/Modal";
import AddNewProduct from '../../../../../Products/EditNewProduct';

const CreateNewProduct = ({
  showNewProductModal,
  setShowNewProductModal,
  searchString,
  showProduct,
  productSelectHandler,
  showSubscription,
  showOneTime
}) => {
  const onClose = () => {
    setShowNewProductModal(false);
  }

  return (
    <div style={{ width: '100%' }}>
      <AddNewProduct
        searchString={searchString}
        isAdd={true}
        comingFromInvoice={true}
        productSelectHandler={productSelectHandler}
        showProduct={showProduct}
        showOneTime={showOneTime}
        showSubscription={showSubscription}
        className={styles.card}
        onClose={onClose}
      />
    </div>
  );
};

export default CreateNewProduct;
