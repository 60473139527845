import React, { useEffect, useState } from "react";
import cn from "classnames";
import Icon from "../../../../../components/Icon";
import TextInput from "../../../../../components/TextInput";
import styles from "../../../PaymentLink/AddNew/addnew.module.sass";
import Spinner from "../../../../../utils/spinner";
import {
  formatStripeAmount,
  getCurrencyFromLocalStorage,
  toastConfiguration,
} from "../../../../../utils/utils";
import {
  removeSubscriptionLine,
  updateSubscriptionLine,
} from "../../../../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import Amount from "../../../../../components/Amount";

let typingTimer;
let doneTypingInterval = 600;

const Lines = ({
  x,
  index,
  updateInvoicee,
  setLines,
  dropdownValue,
  setShowActionBtn,
  lines,
  setLineId,
  setContainsPriceTypeRecurring,
  currencySymbol,
  subscription,
}) => {
  const [qty, setQty] = useState(x.quantity);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateQuantityLoading, setUpdateQuantityLoading] = useState(false);
  const [customerAdjustValueCheckbox, setCustomerAdjustCheckbox] =
    useState(false);
  const [updateQuantityTo1, setUpdateQuantityTo1] = useState(null);

  const [isAmended, setIsAmended] = useState(true);
  useEffect(() => {
    if (qty === 1 && updateQuantityTo1 !== null) {
      updateQuantity();
    }
  }, [updateQuantityTo1]);

  useEffect(() => {
    setQty(x.quantity);
  }, [x.quantity]);

  useEffect(() => {
    setCustomerAdjustCheckbox(x.isCustomerAdjustQuantity);
  }, [x.isCustomerAdjustQuantity]);

  const updateQuantity = async (event) => {
    setUpdateQuantityLoading(true);

    if (qty !== "0" && qty !== "") {
      if (subscription?.id) {
        const { data, error } = await updateSubscriptionLine({
          id: subscription?.id,
          customerId: subscription?.customerId,
          lineId: x?.lineId,
          quantity: qty,
        });
        if (data) {
          x.quantity = qty;
        } else {
          toast.error(
            error || "Error in updating product quantity",
            toastConfiguration
          );
          if (error && error.startsWith("Amount must be no more")) {
            setQty(1);
            x.quantity = 1;
            setUpdateQuantityTo1(!updateQuantityTo1);
          }
        }
      } else {
        setLineId((prevLineId) => prevLineId + 1);
        x.quantity = qty;
      }
    }
    setUpdateQuantityLoading(false);
  };

  const deleteButtonHandler = async (e) => {
    e.stopPropagation();
    setDeleteLoading(true);
    if (subscription?.id) {
      const { data, error } = await removeSubscriptionLine({
        id: subscription?.id,
        customerId: subscription?.customerId,
        lineId: x?.lineId,
      });

      if (data) {
        const newLines = lines?.filter((item) => item.lineId !== x.lineId);
        if (newLines?.length === 0) {
          setShowActionBtn(false);
        }
        setLines(newLines);
      } else {
        toast.error(error || "Error in removing product", toastConfiguration);
      }
    } else {
      const newLines = lines?.filter((item) => item.lineId !== x.lineId);
      if (newLines?.length === 0) {
        setShowActionBtn(false);
      }
      setLines(newLines);
    }
    setDeleteLoading(false);
  };

  let currency = getCurrencyFromLocalStorage();

  return (
    <div key={index} className={styles.productWrap}>
      {deleteLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 50,
            marginTop: 20,
          }}
        >
          <Spinner loading={deleteLoading} size={"40"} color={"blue"} />
        </div>
      ) : isAmended ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          //onClick={() => setIsAmended(false)}
        >
          <div
            className={styles.name}
            style={{
              width: "70%",
              fontSize: 15,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <labe>{x.name}</labe>
            <label
              style={{
                fontFamily: "tomatoGrotesk",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "15px",
                lineHeight: "24px",
                letterSpacing: "-0.01em",
                color: "#6F767E",
                textTransform: "capitalize",
              }}
            >{`$${parseInt(x?.priceValue) / 100}${x?.recurringType ? "/" + x?.recurringType : ""
              }`}</label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 10,
              marginRight: 1,
              width: 130,
            }}
          >
            <div style={{ width: 100 }}>
              <TextInput
                className={styles.field}
                name='description'
                value={qty}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                updateQuantityLoading={updateQuantityLoading}
                onChange={(event) => {
                  event.stopPropagation();
                  setQty(event.target.value);
                }}
                type='text'
                onKeyUp={() => {
                  clearTimeout(typingTimer);
                  typingTimer = setTimeout(updateQuantity, doneTypingInterval);
                }}
                onKeyDown={() => {
                  clearTimeout(typingTimer);
                }}
                currency={"x"}
                currencyBoxWidth={29}
                required
              />
            </div>
            <div style={{ marginLeft: 10 }}>
              <button onClick={deleteButtonHandler}>
                <Icon name='trash' size='20' fill='#FF4900' />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className={styles.head}
            style={{ alignItems: "center", cursor: "pointer" }}
            onClick={() => setIsAmended(true)}
          >
            <div className={cn(styles.left, styles.name)}>{x.name}</div>
            <div className={styles.right}>
              <button
                onClick={deleteButtonHandler}
                className={styles.deleteBtn}
              >
                <Icon name='trash' size='20' fill='#FF4900' />
              </button>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.left}>
              <div style={{ display: "flex" }}>
                <span className={styles.title}>Price</span>
                {currency}
                <Amount
                  amount={formatStripeAmount(
                    x.priceValue,
                    updateInvoicee?.currencySymbol
                  )}
                  subtitleEmValue={"0.8em"}
                />
              </div>
            </div>
          </div>
          <div
            className={styles.row}
            style={{ justifyContent: "space-between" }}
          >
            <div className={styles.qtyWrap} style={{ width: 150 }}>
              <span className={styles.title}>Qty</span>
              <TextInput
                className={styles.field}
                name='description'
                value={qty}
                updateQuantityLoading={updateQuantityLoading}
                onChange={(event) => {
                  setQty(event.target.value);
                }}
                placeholder='Quantity'
                type='text'
                onKeyUp={() => {
                  clearTimeout(typingTimer);
                  typingTimer = setTimeout(updateQuantity, doneTypingInterval);
                }}
                onKeyDown={() => {
                  clearTimeout(typingTimer);
                }}
                required
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className={styles.title}>Total</span>
              {currency}
              <Amount
                amount={formatStripeAmount(
                  x.priceValue * x.quantity,
                  updateInvoicee?.currencySymbol
                )}
                subtitleEmValue={"0.8em"}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Lines;
