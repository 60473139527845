import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({
    item,
    rowClickHandler,
    key,
    deleteModal
}) => {
    return (
        <>
            <div className={cn(styles.row)} key={key} onClick={rowClickHandler}>
                <div className={styles.col}>
                    <div className={styles.user}>
                        <span>{item?.title}</span>
                    </div>
                </div>

                <div className={styles.col} onClick={(e) => e.stopPropagation()}>
                    {deleteModal}
                </div>
            </div>
        </>
    );
};

export default Row;
