import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import DeleteModal from "../../../../../../components/DeleteModal";
import Icon from "../../../../../../components/Icon";
import { dateFormatter, toastConfiguration } from "../../../../../../utils/utils";
import { toast } from "react-toastify";
import SuccessModal from "../../../successModal";
import { deactivePaymentLink } from "../../../../../../utils/apiCallHanlder";

const Row = ({
  item,
  activeTable,
  activeId,
  setFetchNewRecords,
  fetchNewRecords,
}) => {
  const [visible, setVisible] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const deactivateLinkHandler = async () => {
    const { data } = await deactivePaymentLink({
      id: item?.id
    })

    if (data) {
      toast.success("Payment link deactivated successfully");
    } else {
      toast.error("Error in deactivating payment link");
    }

  }

  item.icons = "dots";
  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}

      >
        {/* <div onClick={() => editCustomer(item.id)} className={styles.editOverlay}></div> */}
        <div className={cn(styles.col, styles.firstChild)}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            wordBreak: 'break-word'
          }}>
            <div className={styles.user} style={{ width: 180 }}>{item?.linkUrl.slice(0, 22) + " ..."}</div>
            <div style={{ marginLeft: 5, marginTop: -3, cursor: 'pointer' }} onClick={() => {
              navigator?.clipboard?.writeText(item?.linkUrl);
              toast.success("Payment link copied successfully", toastConfiguration);
            }}>
              <img src="/images/copyLink.svg" /></div>
            <div style={{ marginLeft: 5, marginTop: -3, cursor: 'pointer' }} onClick={() => {
              setShowSuccessModal(true);
            }}>
              <img src="/images/qrScanner.svg" />
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.mblTag}>Name</div>
          <div className={styles.date}>{item.name}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.mblTag}>Price</div>
          <div className={cn(styles.amount)}>{item.amount}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.mblTag}>Status</div>
          <div className={styles.product}>
            <span className={item.status === "Active" ? styles.open : styles.archivedText}> {item.status} </span>
            <br />
          </div>
        </div>
        <div className={styles.col}>
          <span className={styles.mblCreate}>Created</span>
          <div className={styles.created}>{dateFormatter(item.createdOn)}</div>
        </div>

        <div className={styles.col} style={{ width: 40 }}>
          {item.status === "Active" ?
            <DeleteModal
              id={item.id}
              deleteFunction={deactivePaymentLink}
              visibleDelete={visible}
              setFetchNewRecords={setFetchNewRecords}
              fetchNewRecords={fetchNewRecords}
              setVisibleDelete={setVisible}
              toast={toast}
              button={
                <div className={styles.head}>
                  <button
                    className={cn(styles.btn)}
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    <Icon
                      className={styles.btnIcon}
                      fill="#FF4900"
                      name="trash"
                      size="20"
                    />
                  </button>
                </div>
              }
              setValue={true}
              icon={
                <Icon
                  className={styles.btnIcon}
                  fill="#FF4900"
                  name="trash"
                  size="20"
                />
              }
              className={styles.deleteBtn}
              btnClass={cn(styles.btnClass)}
            /> : null}

        </div>

        {showSuccessModal ?
          <SuccessModal
            visible={showSuccessModal}
            paymentObject={item}
            setVisible={setShowSuccessModal}
          />
          : null
        }
      </div>
    </>
  );
};

export default Row;
