import React, { useState } from "react";
import Card from "../../../../components/Card";
import styles from "./CompanyInformation.module.sass";
import TextInput from "../../../../components/TextInput";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import { NAME_FIELD_ERROR } from "../../../../utils/appConstanst";
import Dropdown from "../../../../components/Dropdown";

const countries = ["United States", "UK", "Spain"];

const CompanyInformation = ({ className,
  nameSetHandler,
  setSelectCountries,
  selectCountries,
  errorMessage,
  nameError
}) => {
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Company Information"
        /* classTitle="title-yellow" */
      >
        <div className={styles.nameWrapper}>
          <TextInput
            className={nameError ? styles.hasError : styles.field}
            name="cname"
            type="text"
            onChange={nameSetHandler}
            placeholder="Company Name"
            label="Company Name"
            required
          />
          {nameError ? (
            <Icon name="warning" className={styles.warningIcon} size="24" />
          ) : (
            ""
          )}
        </div>
        {
          errorMessage === NAME_FIELD_ERROR ?
            <span className={styles.errorMessage}>{errorMessage}</span>
            :
            null
        }
        <div className={styles.label}>Country</div>
        <Dropdown
          className={styles.dropdown}
          name="countries"
          classDropdownHead={styles.dropdownHead}
          value={selectCountries}
          setValue={setSelectCountries}
          options={countries}
          small
        />
      </Card>
    </>
  );
};

export default CompanyInformation;
