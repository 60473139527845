import React from "react";
import Card from "../../../../components/Card";
import cn from "classnames";
import styles from "./NoSubscriptions.module.sass";
import Image from "../../../../components/Image";
import Icon from '../../../../components/Icon'

const NoSubscriptions = ({ onConfirm, noSubscriptionsText, noSubscriptionsPara, createNewBtn }) => {
  return (
    <>
      <div className={styles.row} style={{ padding: 24 }}>
        <div className={styles.col}>
          <Image
            className={styles.pic}
            src="/images/icons/empty-black.png"
            srcDark="/images/icons/empty-white.png"
            alt="Core"
          />
          {/* <div className={styles.txtWrap}>
               <h3>{noSubscriptionsText || "You don't have any Current Subscriptions"} </h3>
              {noSubscriptionsPara || <p>No time for coffee, grow your business now!</p>}
              {createNewBtn || (
                <button
                  onClick={onConfirm}
                  className={cn("button", styles.button)}
                >
                <Icon 
                size="24"
                name="add"
                className={styles.addIcon}
                />
                  Create a Subscriptions
                </button>
              )}
            </div> */}
        </div>
      </div>
    </>
  );
};

export default NoSubscriptions;
