import React from "react";

const readNotifications = ({ fill, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.43994 11.064C1.43994 10.8101 1.53896 10.5671 1.71427 10.3907L2.3728 9.72817C2.62348 9.47597 2.76459 9.12817 2.76355 8.76511L2.75749 6.66311C2.74902 3.72217 5.03539 1.33337 7.85869 1.33337C10.676 1.33337 12.9599 3.71243 12.9599 6.64715V8.78111C12.9599 9.13471 13.0948 9.47384 13.3349 9.72391L13.9749 10.3906C14.1446 10.5674 14.2399 10.8072 14.2399 11.0572C14.2399 11.5779 13.8347 12 13.3349 12H10.3999C10.3999 13.4728 9.25377 14.6667 7.83994 14.6667C6.42612 14.6667 5.27994 13.4728 5.27994 12H2.33855C1.84226 12 1.43994 11.581 1.43994 11.064ZM6.55994 12C6.55994 12.7364 7.133 13.3334 7.83994 13.3334C8.54689 13.3334 9.11994 12.7364 9.11994 12H6.55994ZM11.6799 8.78111C11.6799 9.48831 11.9496 10.1666 12.4298 10.6667H3.28219C3.77096 10.164 4.04561 9.47744 4.04354 8.76111L4.03749 6.65911C4.03114 4.45611 5.74381 2.66671 7.85869 2.66671C9.96909 2.66671 11.6799 4.44881 11.6799 6.64715V8.78111Z" fill={fill}/>
<path d="M15.5199 3.33333C15.5199 1.49238 14.0872 0 12.3199 0C10.5526 0 9.11987 1.49238 9.11987 3.33333C9.11987 5.17428 10.5526 6.66667 12.3199 6.66667C14.0872 6.66667 15.5199 5.17428 15.5199 3.33333Z" fill="#FF4900"/>
</svg>

  );
};

export default readNotifications;
