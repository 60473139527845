import React, { useState, useMemo } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import Select from 'react-select';
import Icon from "../../../components/Icon";
import Takeoff from "../Takeoff";
import countryList from 'react-select-country-list'
import { STRIPE_COUNTRIES } from "../../../utils/appConstanst";
import { getStripeReturnURL } from "../../../utils/utils";

const countries = [];

const Entry = ({ onConfirm, istestmode, email, authtype, signUpToken, name }) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [valid, setValid] = useState(null);
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("US");
  const [displayCountry, setDisplayCountry] = useState({ value: "US", label: "United States" });
  const [errorMessage, setErrorMessage] = useState("");
 

  return (
    <div className={styles.entry}>
      <div className={styles.body}>
           
         

        <Takeoff
          className={cn(
            "button",
            error ? styles.disable : valid ? styles.button : styles.disable
          )}
          onClick={valid && onConfirm}
          data={valid}

          email={email}
          password={password}
          setError={setError}
          error={error}

          payloadData={{
            email,
            password,
            deviceid: "1",
            istestmode,
            authtype,
            signUpToken,
            name,
            country: country,
            returnURL: getStripeReturnURL()
          }}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />

        <div className={styles.terms}>
          <Icon name="checkbox" className={styles.termsIcon} size="24" />
          <div className={styles.termsText}>
            <span>By continuing, you agree to Great Week's </span>
            <a
              href="https://greatweek.com/terms/"
              target="_blank"
              className={styles.termsLink}
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            <span> and</span>
            <a
              href="https://greatweek.com/privacy-policy/"
              target="_blank"
              className={styles.termsLink}
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entry;
