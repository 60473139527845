import React, { useEffect, useState } from 'react'
import { getMemberDetailsById, updateMemberDetailsById, uploadProductImage } from '../../../../../utils/apiCallHanlder';
import Card from '../../../../../components/Card';
import { ToastContainer, toast } from 'react-toastify';
import { toastConfiguration } from '../../../../../utils/utils';
import cn from 'classnames'
import ReactPhoneInput from "react-phone-input-2";
import Icon from '../../../../../components/Icon';
import Image from '../../../../../components/Image';

import styles from './MemberDetail.module.sass'
import modalStyles from '../../ProfileInformation/ProfileInformation.module.sass'
import "react-phone-input-2/lib/style.css";
import TextInput from '../../../../../components/TextInput';
import Dropdown_c from '../../../../../components/Dropdown_c';
import Dropdown from '../../../../../components/Dropdown';
import Switch from '../../../../../components/Switch';
import Spinner from '../../../../../utils/spinner';
import Modal from '../../../../../components/Modal';
import AlternativeAvatar from "../../../../../components/AlternativeAvatar";

const inputStyle = {
    position: "relative",
    fontSize: "14px",
    letterSpacing: ".01rem",
    marginTop: "0px !important",
    marginBottom: " 0px !important",
    paddingLeft: "48px",
    marginLeft: "0px",
    background: "#F4F4F4",
    border: "none",
    borderRadius: "10px",
    lineHeight: "25px",
    height: "45px",
    width: "100%",
    outline: "none",
};

const defaultAccessValues = [
    {
        name: 'Dashboard',
        fieldName: 'allowDashboard',
        isAllowed: false
    },
    {
        name: 'Invoices',
        fieldName: 'allowInvoices',
        isAllowed: false
    },
    {
        name: 'Subscription',
        fieldName: 'allowSubscription',
        isAllowed: false
    },
    {
        name: 'Payment Links',
        fieldName: 'allowPaymentLinks',
        isAllowed: false
    },
    {
        name: 'Quotes',
        fieldName: 'allowQuotes',
        isAllowed: false
    },
    {
        name: 'Capital',
        fieldName: 'allowCapital',
        isAllowed: false
    },
    {
        name: 'Cards',
        fieldName: 'allowCards',
        isAllowed: false
    },
    {
        name: 'Accounting',
        fieldName: 'allowAccounting',
        isAllowed: false
    },
    {
        name: 'Contacts',
        fieldName: 'allowContacts',
        isAllowed: false
    },
    {
        name: 'Products',
        fieldName: 'allowProducts',
        isAllowed: false
    },
    {
        name: 'Documents',
        fieldName: 'allowDocuments',
        isAllowed: false
    },
    {
        name: 'Templates',
        fieldName: 'allowTemplates',
        isAllowed: false
    },
    {
        name: 'Event',
        fieldName: 'allowEvents',
        isAllowed: false
    },
    {
        name: 'Task',
        fieldName: 'allowTask',
        isAllowed: false
    },
    {
        name: 'Property',
        fieldName: 'allowProperty',
        isAllowed: false
    },
    {
        name: 'Notifications',
        fieldName: 'allowNotifications',
        isAllowed: false
    },
    {
        name: 'AI Co-Pilot',
        fieldName: 'allowAICoPilot',
        isAllowed: false
    },
    {
        name: 'Settings',
        fieldName: 'allowSettings',
        isAllowed: false
    }
]

const avatarTypes = ["Human", "Jungle", "Modern", "Monster", "Superhero"];
let haveDropdownsData = false;
let roles = [""];

const MemberDetail = ({ id, setMemberId }) => {
    if (!haveDropdownsData) {
        const accountLookupData = JSON.parse(localStorage.getItem("lookupData"));
        let data = null;
        if (accountLookupData) {
            data = accountLookupData?.data;
            roles = data?.Roles;
            haveDropdownsData = true;
        }
    }

    const [loading, setLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState(undefined);
    const [imageUrl, setImageUrl] = useState('');
    const [uploadLoading, setUploadLoading] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [role, setRole] = useState('');

    const [access, setAccess] = useState([]);
    const [isOwner, setIsOwner] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [showAvatarModal, setShowAvatarModal] = useState(false);
    const [text, setText] = useState("Upload or drag here")
    const [activeIndex, setActiveIndex] = useState(1)

    useEffect(() => {
        if (id) {
            getMemberDetails();
        }
    }, [id])


    const getMemberDetails = async () => {
        const { data } = await getMemberDetailsById(id);
        if (data) {
            setName(data?.teamMemberDetail?.name);
            setEmail(data?.teamMemberDetail?.email);
            setPhone(data?.teamMemberDetail?.phone);
            setImageUrl(data?.teamMemberDetail?.imageUrl);
            setRole(data?.teamMemberDetail?.roleid?.toString());
            setIsOwner(data?.teamMemberDetail?.isOwner)
            const accessMaping = defaultAccessValues.map(item => {
                item['isAllowed'] = data?.teamMemberDetail[item.fieldName]
                return item
            })

            setAccess([...(accessMaping || [])])
        }

        setLoading(false);
    }

    const imageUploadHandler = async (imageFile) => {
        const supportedTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];

        if (!supportedTypes.includes(imageFile.type)) {
            toast.error("Please Upload image of type .jpg,.jpeg,.gif,.png.", toastConfiguration);
            return
        }
        setUploadLoading(true);
        setSelectedImage(imageFile);

        const formData = new FormData();
        formData.append("", imageFile);
        const { data, error } = await uploadProductImage(formData);
        if (data) {
            setImageUrl(data.image.url);
        } else {
            toast.error(error || "Error in uploading image", toastConfiguration)
        }

        setUploadLoading(false);
        setShowAvatarModal(false);
    };

    const onDropHandler = (ev) => {
        ev.preventDefault();

        let file = "";
        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            file = [...ev.dataTransfer.items].find((item) => item.kind === "file").getAsFile();
        } else {
            // Use DataTransfer interface to access the file(s)
            file = ev.dataTransfer.files[0];
        }
        imageUploadHandler(file);
        setText(file?.name);
    };

    const onDragOver = (ev) => ev.preventDefault();

    const accessChangeHandler = (fieldName) => {
        const newAccess = access?.map(item => {
            if (item.fieldName === fieldName) {
                item.isAllowed = !item.isAllowed;
            }
            return item;
        })

        setAccess([...(newAccess || [])])
    }

    const updateTeamMateHandler = async () => {
        if (updateLoading) return;

        setUpdateLoading(true);
        const payload = {
            userid: id,
            name,
            phone,
            imageUrl,
            roleid: role,
            ...(access?.reduce((acc, item) => {
                acc[item.fieldName] = item.isAllowed;
                return acc;
            }, {}) || {})
        }

        const { data, error } = await updateMemberDetailsById(payload);
        if (data) {
            toast.success("Team member record updated successfully", toastConfiguration)
        } else {
            toast.success(error || "Error in updating team record", toastConfiguration)
        }

        setUpdateLoading(false);
    }

    return <>
        <ToastContainer />
        <Card
            className={styles.card}
            classCardHead={styles.head}
            title="Team member details"
            border
            head={
                <div className={styles.btnWraper}>
                    <button
                        className="button-stroke-red"
                        onClick={() => setMemberId(undefined)}
                    >
                        Cancel
                    </button>
                    {!isOwner && (
                        <button
                            className="button"
                            onClick={updateTeamMateHandler}
                            style={{ width: 74 }}
                        >
                            {updateLoading ? <Spinner size={24} color={'white'} /> :
                                "Save"
                            }
                        </button>
                    )}
                </div>
            }
        >
            {loading ?
                <div
                    className={styles.spinner}
                    style={{ height: window.outerHeight - 250 }}
                >
                    <Spinner loading={loading} size={150} color={"gray"} />
                </div>
                :
                <div style={isOwner ? { pointerEvents: 'none' } : {}}>
                    <span className={styles.label}>Image</span>
                    <div className={styles.avatarWrap}>
                        {!isOwner && (
                            <button onClick={() => setShowAvatarModal(true)}>
                                <Icon name="close" size="16" />
                            </button>
                        )}
                        {imageUrl ?
                            <Image
                                className={styles.avatar}
                                src={imageUrl}
                                srcDark={imageUrl}
                            />
                            :
                            <AlternativeAvatar name={name || ""} className={"big-icon"} />
                        }
                    </div>
                    {/* <div className={styles.inputWrap}>
                        {selectedImage || imageUrl ? (
                            <div className={styles.selectedImageWrap}>
                                <Image
                                    className={styles.selectedImage}
                                    src={selectedImage ? URL.createObjectURL(selectedImage) : imageUrl}
                                    srcDark={selectedImage ? URL.createObjectURL(selectedImage) : imageUrl}
                                />
                                <div className={styles.action}>
                                    <button
                                        onClick={() => {
                                            setSelectedImage(null);
                                            setImageUrl("");
                                        }}
                                    >
                                        <Icon name="close" size="16" />
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.imgInput} style={{ width: 100, height: 100 }}>
                                <label
                                    className={cn(selectedImage ? styles.inputLabelImg : styles.inputLabel)}
                                    style={{ cursor: "pointer" }}
                                    htmlFor="fileInput"
                                >
                                    <Icon name="add" />
                                </label>
                                <input
                                    type="file"
                                    className={styles.input}
                                    name="myImage"
                                    id="fileInput"
                                    onChange={(event) => imageUploadHandler(event.target.files[0])}
                                />
                            </div>
                        )}
                    </div> */}

                    <div className={styles.row}>
                        <div className={styles.field}>
                            <TextInput
                                onChange={(e) => setName(e.target.value)}
                                label='Name'
                                name='name'
                                type='text'
                                value={name}
                                required
                            />
                        </div>
                        <div className={styles.field}>
                            <TextInput
                                onChange={(e) => setEmail(e.target.value)}
                                label='Email'
                                name='email'
                                type='text'
                                value={email}
                                required
                                disabled={true}
                            />
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldLabel}>Phone</div>
                            <div className={styles.mobileField}>
                                <ReactPhoneInput
                                    inputExtraProps={{
                                        name: "phone",
                                        required: true,
                                        autoFocus: true,
                                    }}
                                    buttonStyle={{ border: "none" }}
                                    inputStyle={inputStyle}
                                    country={"us"}
                                    countryCodeEditable={false}
                                    value={phone?.indexOf('+') > -1 ? phone : '+' + phone}
                                    onChange={(value) => setPhone(value)}
                                />
                            </div>
                        </div>
                        <div className={styles.field}>
                            {isOwner ? (
                                <Dropdown
                                    label='Role'
                                    bodyStyles={{ width: "100%" }}
                                    value={'Owner'}
                                    setValue={setRole}
                                    options={["Owner"]}
                                    className={styles.TypeDropdown}
                                />
                            ) :
                                <Dropdown_c
                                    label='Role'
                                    bodyStyles={{ width: "100%" }}
                                    value={role}
                                    setValue={setRole}
                                    options={roles || []}
                                    className={styles.TypeDropdown}
                                    onChange={(value) => {
                                        if (value === "1") {
                                            const newAccess = access?.map(item => {
                                                item.isAllowed = true;
                                                return item;
                                            })

                                            setAccess([...(newAccess || [])])
                                        }
                                    }}
                                />
                            }
                        </div>
                    </div>

                    <div className={styles.accessWrapper} style={role === "1" ? { pointerEvents: 'none' } : {}}>
                        <span className={styles.label}>Has access to:</span>
                        <div className={styles.flexContainer}>
                            {access?.map((item, index) => {
                                return (
                                    <div className={styles.switchContainer} key={index}>
                                        <Switch
                                            value={item.isAllowed}
                                            onChange={() => accessChangeHandler(item.fieldName)}
                                        />
                                        <span className={styles.title} style={{ marginLeft: 24 }}>
                                            {item.name}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            }
        </Card>

        {/* Avatar Modal */}
        <Modal
            visible={showAvatarModal}
            onClose={() => setShowAvatarModal(false)}
            outerClassName={modalStyles.outerClass}
            children={
                <>
                    <Card
                        /* classTitle="title-blue" */
                        title="Image"
                        className={modalStyles.card}
                    >
                        <div
                            className={modalStyles.uploadImage}
                            id="drop_zone"
                            onDrop={onDropHandler}
                            onDragOver={onDragOver}
                            onClick={() => {
                                document?.getElementById("imgupload").click();
                            }}
                        >
                            {uploadLoading ? (
                                <div style={{ marginTop: 5 }}>
                                    <Spinner size="24" color="gray" />
                                </div>
                            ) : (
                                <Icon name="upload" size="24" />
                            )}
                            {text}
                        </div>
                        <input
                            type="file"
                            id="imgupload"
                            accept="image/png, image/gif, image/jpeg"
                            style={{
                                display: "none",
                            }}
                            onChange={(event) => imageUploadHandler(event.target.files[0])}
                        />
                        <p className={modalStyles.orChoose}>Or choose an avatar</p>
                        <div className={modalStyles.nav}>
                            <div className={modalStyles.typeNav}>
                                {avatarTypes.map((x, index) => (
                                    <div
                                        className={cn(modalStyles.item, {
                                            [modalStyles.active]: index === activeIndex - 1,
                                        })}
                                        key={index}
                                        onClick={() => {
                                            setActiveIndex(index + 1);
                                        }}
                                    >
                                        {x}
                                    </div>
                                ))}
                            </div>
                        </div>
                        {activeIndex === 1 ? (
                            <div className={modalStyles.avatarFlex}>
                                {[...Array(36).keys()].map((x, index) => (
                                    <Image
                                        key={index}
                                        className={modalStyles.avatar}
                                        src={`/avatars/HUMANS/GREATWEEK.COM AVATAR ${x + 1}.png`}
                                        srcDark={`/avatars/HUMANS/GREATWEEK.COM AVATAR ${x + 1}.png`}
                                        onClick={() => {
                                            setShowAvatarModal(false);
                                            setImageUrl(
                                                `/avatars/HUMANS/GREATWEEK.COM AVATAR ${x + 1}.png`
                                            );
                                        }}
                                    />
                                ))}
                            </div>
                        ) : activeIndex === 2 ? (
                            <div className={modalStyles.avatarFlex}>
                                {[...Array(18).keys()].map((x, index) => (
                                    <Image
                                        key={index}
                                        className={modalStyles.avatar}
                                        src={`/avatars/JUNGLE/GREATWEEK.COM JUNGLE ${x + 1}.png`}
                                        srcDark={`/avatars/JUNGLE/GREATWEEK.COM JUNGLE ${x + 1}.png`}
                                        onClick={() => {
                                            setShowAvatarModal(false);
                                            setImageUrl(
                                                `/avatars/JUNGLE/GREATWEEK.COM JUNGLE ${x + 1}.png`
                                            );
                                        }}
                                    />
                                ))}
                            </div>
                        ) : activeIndex === 3 ? (
                            <div className={modalStyles.avatarFlex}>
                                {[...Array(75).keys()].map((x, index) => (
                                    <Image
                                        key={index}
                                        className={modalStyles.avatar}
                                        src={`/avatars/MODERN/GREATWEEK.COM ANIMATED ${x + 1}.png`}
                                        srcDark={`/avatars/MODERN/GREATWEEK.COM ANIMATED ${x + 1}.png`}
                                        onClick={() => {
                                            setImageUrl(
                                                `/avatars/MODERN/GREATWEEK.COM ANIMATED ${x + 1}.png`
                                            );
                                            setShowAvatarModal(false);
                                        }}
                                    />
                                ))}
                            </div>
                        ) : activeIndex === 4 ? (
                            <div className={modalStyles.avatarFlex}>
                                {[...Array(17).keys()].map((x, index) => (
                                    <Image
                                        key={index}
                                        className={modalStyles.avatar}
                                        src={`/avatars/MONSTER/GREATWEEK.COM MONSTER ${x + 1}.png`}
                                        srcDark={`/avatars/MONSTER/GREATWEEK.COM MONSTER ${x + 1}.png`}
                                        onClick={() => {
                                            setImageUrl(
                                                `/avatars/MONSTER/GREATWEEK.COM MONSTER ${x + 1}.png`
                                            );
                                            setShowAvatarModal(false);
                                        }}
                                    />
                                ))}
                            </div>
                        ) : activeIndex === 5 ? (
                            <div className={modalStyles.avatarFlex}>
                                {[...Array(17).keys()].map((x, index) => (
                                    <Image
                                        key={index}
                                        className={modalStyles.avatar}
                                        src={`/avatars/SUPERHERO/GREATWEEK.COM SUPERHERO ${x + 1}.png`}
                                        srcDark={`/avatars/SUPERHERO/GREATWEEK.COM SUPERHERO ${x + 1
                                            }.png`}
                                        onClick={() => {
                                            setImageUrl(
                                                `/avatars/SUPERHERO/GREATWEEK.COM SUPERHERO ${x + 1}.png`
                                            );
                                            setShowAvatarModal(false);
                                        }}
                                    />
                                ))}
                            </div>
                        ) : null}
                    </Card>
                </>
            }
        />
    </>
}


export default MemberDetail;