import Icon from '../../../components/Icon';
import TextInput from '../../../components/TextInput';
import { inventoryOrder } from '../../../utils/appConstanst';
import styles from './tableInputRow.module.sass';

const inputValues = [
    "sCode",
    "product",
    "qty",
    "costPrice",
    "total",
    "discPercent",
    "discount",
    "vat",
    "subTotal",
    "sellPrice"
]

const TableInputRow = ({
    orderValues,
    rows,
    setOrderValues,
    setRows
}) => {
    return <div className={styles.tableInputRowContainer}>
        {inputValues.map((item, index) => {
            return <div
                className={styles.inputContainer}
                style={{ width: index === 0 ? `calc(9% + 12px)` : '9%' }}
                key={index}
            >
                <TextInput
                    key={index}
                    value={orderValues[item]}
                    onChange={(e) => {
                        setOrderValues({ ...orderValues, [item]: e.target.value })
                    }}
                />
            </div>
        })}
        <div className={styles.plusIcon} onClick={() => {
            const newRow = JSON.parse(JSON.stringify(inventoryOrder));
            newRow.sCode = rows?.length + 1;
            setRows([...rows, newRow])
        }}>
            <Icon name="circle_add" size="24" viewBox="0 0 24 24" />
        </div>
    </div>
}

export default TableInputRow;