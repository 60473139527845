import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Checkbox from "../../../../../components/Checkbox";
import Icon from "../../../../../components/Icon";
import Amount from "../../../../../components/Amount";
import { dateFormatter, formatStripeAmount } from "../../../../../utils/utils";

const Row = ({
  item,
  onChange,
  activeTable,
  activeId,
  className,
  editAction,
  deleteAction,
  singleCheck,
  name,
  editProduct,
  archiveAction,
  isArchived,
  setId,
  setCustomerVisible,
  availableCheckCount,
  archivedCheckCount
}) => {
  const [visible, setVisible] = useState(false);
  item.icon = "dots";

  const getTerm = (item) =>
  {
    var term = "";
    if(item.amountOff)
    {
      term = formatStripeAmount(item.amountOff || 0, item.currency) + " off";
    }
    else if(item.percentOff)
    {
      term = item.percentOff + "% off";
    }
    if(item.duration == "once" || item.duration == "forever")
    {
      term = term + " " + item.duration;
    }
    else if(item.duration == "repeating")
    {
      term = term + " for " + item.durationInMonths;
      if(item.durationInMonths == 1)
      {
        term = term + " month";
      }
      else
      {
        term = term + " months";
      }
    }
    return term;
  }

  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }

        )}
      >
        <div className={styles.editOverlay} onClick={() => {
          editProduct(item.id);
        }}></div>
        <div className={styles.col}>
          {!isArchived && (
            singleCheck || (
              <Checkbox name={name} className={/* item.imageUrl ? styles.checkboxImg : */ styles.checkbox} onChange={(e) => onChange(e, item.id)} />
            )
          )}
          <div className={cn(styles.userImage, isArchived ? (archivedCheckCount < 1 ? [styles.showUserImg] : [styles.hideUserImg]) : (availableCheckCount < 1 ? [styles.showUserImg] : [styles.hideUserImg]))} >
            <img className={styles.image} src={item.imageUrl ? item.imageUrl : "/images/content/image.png"} alt="product" />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.priceTag}>Name</div>
          <div className={styles.product} onClick={() => editProduct(item.id)}>
            <div onClick={() => editProduct(item.id)}>
              <span>{item.name}
                {isArchived ?
                  <>
                    <br />
                    <span className={styles.archivedText}> Archived </span>
                  </>
                  : null
                }
              </span>
              <br />
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.taxTag}>Terms</div>
          <div className={cn(styles.tax)}>
            {getTerm(item)}
          </div>
        </div>
        <div className={styles.col}>
          <span className={styles.taxTag}>Redemptions</span>
          <div className={styles.tax}>{item.timesRedeemed || 0}</div>
        </div>

        <div className={styles.col}>
          <span className={styles.taxTag}>Expires</span>
          <div className={styles.tax}>{item.redeemBy && dateFormatter(item.redeemBy)}</div>
        </div>

      </div>
    </>
  );
};

export default Row;
