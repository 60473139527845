import React, { useState, useEffect } from "react";
import styles from "./RecipientOverview.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Filters from "../../../../components/Filters";
import Settings from "./Settings";
import Table from "./Table";
import Icon from "../../../../components/Icon";
import AdjustRecipient from "../AdjustRecipient";
import DeleteModal from "../../../../components/DeleteModal";

import { customers } from "../../../../mocks/customers";
import { useHistory } from "react-router";

const RecipientOverview = ({
  setShowRecipientOverview,
  setShowTransactionSend,
  setShowRecipient,
}) => {
  const [visible, setVisible] = useState(true);
  const [actionCustomer, setActionCustomer] = useState(true);
  const [show, setShow] = useState(false);
  const [checkCount, setCheckCount] = useState(0);
  const [deleteIdArray, setDeleteIdArray] = useState([]);

  const [recipientData, setRecipientData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [getMoreLoading, setGetMoreLoading] = useState(false);
  const [hasMore, setHasMore] = useState(null);

  const customerLength = Object.keys(customers).length;
  const history = useHistory();

  const handleChangeCustomerAll = (e) => {
    var ariaStatus = e.target.getAttribute("aria-Checked");
    if (ariaStatus === "true") {
      setActionCustomer(true);
      setCheckCount(0);
      deleteIdArray = [];

      for (let i = 0; i < recipientData.length; i++) {
        deleteIdArray.push(recipientData[i]?.id);
      }

      setDeleteIdArray([...deleteIdArray])
    }
    if (ariaStatus === "false") {
      setActionCustomer(false);
      setCheckCount(customerLength);
      setRecipientData([]);
    }
    if (ariaStatus === "mixed") {
      setActionCustomer(false);
      setCheckCount(customerLength);
    }
  };

  const handleChangeCustomer = (e, id) => {
    if (e.target.checked) {
      setCheckCount(checkCount + 1);
      setDeleteIdArray([...deleteIdArray, id])
    }
    if (!e.target.checked) {
      setCheckCount(checkCount - 1);
      const index = deleteIdArray.indexOf(id);
      if (index > -1) {
        deleteIdArray.splice(index, 1);
      }
      setDeleteIdArray([...deleteIdArray])
    }
  };

  const handleEditCustomer = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (checkCount === 0) {
      setActionCustomer(true);
    } else {
      setActionCustomer(false);
    }
  }, [checkCount]);

  return (
    <>
      {visible ? (
        <>
          <div className={styles.title}>
            <div
              className={styles.backBtn}
              onClick={() => history.push("/transactions")}
            >
              Transactions <Icon name="arrow-next" size="14" />
            </div>
            <p>Choose Recipient</p>
          </div>
          <Card
            className={styles.card}
            title="Overview"
            border
            /* classTitle={cn("title-blue", styles.pageTitle)} */
            classCardHead={cn(styles.head, { [styles.hidden]: show })}
            head={
              <>
                {/* <Filters
                  className={styles.filters}
                  title="Showing 9 of 32 Recipient"
                  iconHead={
                    <Icon
                      className={styles.filterBtn}
                      name="adjustments"
                      size="24"
                    />
                  }
                >
                  <Settings isClick={() => setVisible(false)} />
                </Filters>
                <button className={styles.downloadBtn}>
                  <Icon name="download" size="24" />
                </button> */}
                {actionCustomer ? (
                  <button
                    onClick={() => history.push("/transactions/add")}
                    className={cn("button", styles.addCustomerBtn)}
                  >
                    Add new Recipient
                  </button>
                ) : (
                  <>
                    <DeleteModal
                      icon={
                        <Icon
                          className={styles.btnIcon}
                          fill="#FF4900"
                          name="trash"
                          size="20"
                        />
                      }
                      className={cn(styles.deleteBtn, styles.deleteMarkedBtn)}
                      btnClass={cn(styles.btnClass)}
                    />
                  </>
                )}
              </>
            }
          >
            <div className={cn(styles.row, { [styles.flex]: show })}>
              <Table
                className={styles.table}
                activeTable={show}
                setActiveTable={setShow}
                recipientData={recipientData}
                setRecipientData={setRecipientData}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                getMoreLoading={getMoreLoading}
                setGetMoreLoading={setGetMoreLoading}
                hasMore={hasMore}
                setHasMore={setHasMore}
                editAction={
                  <button
                    className={styles.actionItem}
                    onClick={() => setVisible(false)}
                  >
                    <Icon name="edit" size="24" />
                    Edit
                  </button>
                }
                editCustomer={handleEditCustomer}
                changeCustomerAll={handleChangeCustomerAll}
                changeCustomer={handleChangeCustomer}
              />
            </div>
          </Card>
        </>
      ) : (
        <AdjustRecipient
          setShowTransactionSend={setShowTransactionSend}
          setShowRecipientOverview={setShowRecipientOverview}
          setShowRecipient={setShowRecipient}
        />
      )}
    </>
  );
};

export default RecipientOverview;
