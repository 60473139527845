import React, { useEffect, createRef, useState } from "react";
import styles from "./NewQuestioners.module.sass";
import { useHistory } from "react-router";
import Icon from "../../../../../components/Icon";
import Card from "../../../../../components/Card";
import CustomerInfoBox from "../../../../../components/CustomerInfoBox";
import Dropdown from "../../../../../components/COATYPE";
import cn from "classnames";
import { dateFormatter, toastConfiguration } from "../../../../../utils/utils";
import $ from "jquery";
import Spinner from "../../../../../utils/spinner";
import SaveSuccess from "../../Contract/SaveSuccess";
import {
  getQuestionnaireTemplate,
  getQuestionnaireTemplateData,
  getSingleQuestionnaire,
  resendQuestionnaire,
  sendQuestionnaire,
} from "../../../../../utils/apiCallHanlder";
import { toast, ToastContainer } from "react-toastify";
import Modal from "../../../../../components/Modal";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const templates = ["Pre meeting", "template 2", "template 3"];

const NewQuestioners = ({
  customerId,
  imageURL,
  name,
  email,
  mobileCode,
  mobile,
  editMode,
  setEditMode,
  templateName,
  link,
  createdOn,
  answeredOn,
  setShowNewQuestioners,
  getUpdatedQuestionnaire,
  setGetUpdatedQuestionnaire,
  questionnaireId,
}) => {
  const history = useHistory();
  const [template, setTemplate] = useState("choose");
  const [templateArray, setTemplateArray] = useState([]);
  const [startLoading, setStartLoading] = useState(false);

  const [data, setData] = useState("");

  const [getTemplateLoading, setGetTemplateLoading] = useState(true);
  const [sendLoading, setSendLoading] = useState(false);

  const [showNewTemplate, setShowNewTemplate] = useState(false);

  const [templateNameX, setTemplateName] = useState("");
  const [linkX, setLink] = useState("");
  const [questionnair, setQuestionnaire] = useState(null);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const fb = createRef();

  useEffect(async () => {
    setGetTemplateLoading(true);
    const { data } = await getQuestionnaireTemplate({});
    if (data) {
      const firstElement = {
        name: "Choose template",
        id: "choose",
      };
      setTemplateArray([firstElement].concat(data?.templates));
    }

    if (questionnaireId) {
      const questionnaireInof = await getSingleQuestionnaire(
        questionnaireId,
        {}
      );
      if (questionnaireInof?.data) {
        const questionnaire = questionnaireInof?.data?.contactQuestionnaire;
        setData(questionnaire?.answeredForm || questionnaire?.questionForm);
        setTemplate(questionnaire?.studioQuestionnaireId);
        setTemplateName(questionnaire?.templateName);
        setLink(questionnaire?.link);
        setQuestionnaire(questionnaire);
        setEditMode(false);
      }
    }

    setGetTemplateLoading(false);
  }, []);

  const onConfirm = () => {
    if (questionnaireId) {
      if (customerId?.slice(0, 2) === "cu") {
        history.push({
          pathname: `/contacts/${customerId}`,
        });
      } else {
        history.push({
          pathname: `/contacts/${customerId}`,
        });
      }
    }

    setShowNewQuestioners(false);
    setGetUpdatedQuestionnaire(!getUpdatedQuestionnaire);
    setShowSuccessModal(false);
  };

  useEffect(() => {
    if (data) {
      if (fb.current) {
        fb.current.innerHTML = "";
      }

      var options = {
        dataType: "json",
        disableFields: [
          "autocomplete",
          "file",
          "hidden",
          "date",
          "button",
          "checkbox-group",
          "header",
          "number",
          "paragraph",
          "radio-group",
          "select",
          "text",
          "textarea",
        ],
        /* inputSets: [ */
        formData: JSON.parse(data),
      };

      $(fb.current).formRender(options);
    }
  }, [data]);

  const sendToContact = async () => {
    setSendLoading(true);
    const { data, error } = questionnaireId
      ? await resendQuestionnaire(questionnaireId, {})
      : await sendQuestionnaire({
          studioQuestionnaireId: template,
          contactId: customerId,
        });
    if (data) {
      setShowSuccessModal(true);
    } else {
      toast.error(error || "error in sending contract", toastConfiguration);
    }
    setSendLoading(false);
  };

  return (
    <>
      <Modal
        visible={showNewTemplate}
        onClose={() => {
          setShowNewTemplate(false);
        }}
        outerClassName={styles.outer}
      >
        <div className={styles.wrap}>
          <div className={styles.email} style={{ textAlign: "center" }}>
            Click below button to add new questionnaire template to proceed.
          </div>
          <button
            onClick={() => {
              history.push({
                pathname: "/studio/5",
                state: { isNewQuestionnaire: true },
              });
            }}
            className="button"
            style={{ marginTop: 25 }}
          >
            Create new Questionnaire
          </button>
        </div>
      </Modal>
      <ToastContainer />

      <div className={styles.row}>
        <div className={styles.col}>
          <Card
            title={"Questionnaire"}
            border
            head={
              <div
                className={cn("button-stroke-red", styles.backBtn)}
                onClick={() => {
                  if (customerId?.slice(0, 2) === "cu") {
                    history.push({
                      pathname: `/contacts/${customerId}/6`,
                    });
                  } else {
                    history.push({
                      pathname: `/contacts/${customerId}`,
                    });
                  }
                  setShowNewQuestioners(false);
                  setEditMode(true);
                }}
              >
                Cancel
              </div>
            }
          >
            <h2 className={styles.title}>Contact</h2>
            <CustomerInfoBox
              imageUrl={imageURL}
              name={name}
              email={email}
              mobileCode={mobileCode}
              mobile={mobile}
              disableCursor={questionnaireId ? true : false}
            />
            {editMode ? (
              <>
                {getTemplateLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: 16,
                    }}
                  >
                    <Spinner size="24" color="gray" />
                  </div>
                ) : (
                  <>
                    <Dropdown
                      className={styles.templateDropdown}
                      classDropdownLabel={styles.templateLabel}
                      label={"Template"}
                      value={template}
                      setValue={setTemplate}
                      loading={dataLoading}
                      options={templateArray}
                      comingFromStatement={true}
                      onChange={async (value, x) => {
                        if (x.id !== "choose") {
                          setDataLoading(true);
                          const { data } = await getQuestionnaireTemplateData({
                            StudioQuestionnaireId: x?.id,
                          });
                          setData(data?.data);
                          setDataLoading(false);
                        }
                      }}
                      emptyTemplateListHandler={() => {
                        setShowNewTemplate(true);
                      }}
                    />
                    {template !== "choose" ? (
                      <button
                        className={cn("button", styles.sendContactBtn)}
                        style={{ width: "100%" }}
                        onClick={sendToContact}
                      >
                        {sendLoading ? (
                          <Spinner size="24" color="white" />
                        ) : (
                          <>
                            <Icon name="rocket" size="24" />
                            Send to contact now
                          </>
                        )}
                      </button>
                    ) : null}
                  </>
                )}
              </>
            ) : (
              <>
                <div className={styles.singleData}>
                  <div className={styles.dataTitle}>Template</div>
                  <p>{templateNameX}</p>
                </div>
                <div className={styles.singleData}>
                  <div className={styles.dataTitle}>Link</div>
                  <div className={styles.linkWrap}>
                    <p>{linkX}</p>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator?.clipboard?.writeText(linkX);
                        toast.success(
                          "link copied successfully.",
                          toastConfiguration
                        );
                      }}
                    >
                      <Icon name="multiselect" size="24" />
                    </button>
                  </div>
                </div>
                <div className={styles.singleData}>
                  <div className={styles.dataTitle}>Sent</div>
                  <p>
                    {questionnair?.createdOn
                      ? dateFormatter(questionnair?.createdOn)
                      : "-"}
                  </p>
                </div>
                <div className={styles.singleData}>
                  <div className={styles.dataTitle}>Answered</div>
                  {questionnair?.answeredOn ? (
                    <p className={styles.success}>
                      {dateFormatter(questionnair?.answeredOn)}
                    </p>
                  ) : (
                    <p className={styles.danger}>No</p>
                  )}
                </div>
                {!questionnair?.answeredOn && (
                  <button
                    className={cn("button", styles.sendContactAgainBtn)}
                    style={{ width: "100%" }}
                    onClick={sendToContact}
                  >
                    {sendLoading ? (
                      <Spinner size="24" color="white" />
                    ) : (
                      <>
                        <Icon name="rocket" size="24" />
                        Resend to contact now
                      </>
                    )}
                  </button>
                )}
              </>
            )}
          </Card>
        </div>
        <div className={styles.col}>
          <Card className={styles.questionerWrap} border>
            {/* {dataLoading &&
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%'
                  }}>
                    <Spinner size={24} color="gray" />
                  </div>
                } */}
            <div style={{ pointerEvents: "none" }}>
              <div id="fbEditor" ref={fb} />
              {/* <button className="button">
                      <Icon name="save" size="24" />
                      Save
                    </button> */}
            </div>
          </Card>

          <SaveSuccess
            showSuccessModal={showSuccessModal}
            setShowSuccessModal={setShowSuccessModal}
            onConfirm={onConfirm}
            name={name}
            isQuestionnaire={true}
          />
        </div>
      </div>
    </>
  );
};

export default NewQuestioners;
