import React, { useState } from "react";
import cn from "classnames";
import styles from "./TextModal.module.sass";

const TextModal = ({ className, showPopup, setShowPopup, text }) => {
    return (
        <div
            className={cn(styles.deleteModal, className, {
                [styles.active]: showPopup,
            })}
        >
            <div className={styles.body}>
                <div style={{ padding: 10 }} className={styles.text}>
                    {text}
                </div>
            </div>
            <div className={styles.overlay} onClick={() => setShowPopup(false)}></div>
        </div>
    );
};

export default TextModal;
