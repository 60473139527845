export const documentsDetails = [
    {
      id: 1,
      no: 10,
      img: "./images/content/product-pic-3.jpg",
      documentName: "Checking Account - ABC Bank",
      transaction: "1238",
      uploaded: "14 July, 2022",
      status: "Booked",
      editIcon: "edit",
      deleteIcon: "trash",
    },
    {
      id: 2,
      no: 10,
      img: "./images/content/product-pic-3.jpg",
      documentName: "Checking Account - ABC Bank",
      transaction: "1238",
      uploaded: "14 July, 2022",
      status: "Booked",
      editIcon: "edit",
      deleteIcon: "trash",
    },
  ];