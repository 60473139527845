import React, { useState } from "react";
import styles from "./Panel.module.sass";
import Actions from "../../../../components/Actions";
import Icon from "../../../../components/Icon";
import ModalImage from "react-modal-image";
import Modal from "../../../../components/Modal";

const Panel = ({
  panelTitle,
  actions,
  parameters,
  setVisible,
  selectedImages,
  deleteImage,
}) => {
  const [renameTitleModal, setRenameTitleModal] = useState(false);
  const [titleText, setTitleText] = useState("Attach a receipt");
  const [inputText, setInputText] = useState(titleText);
  return (
    <div className={styles.panel}>
      <div className={styles.line}>
        <div className={styles.titleWrap}>
          <div
            onClick={() => setRenameTitleModal(true)}
            className={styles.title}
          >
            {titleText}
          </div>
          <Modal
            visible={renameTitleModal}
            onClose={() => {
              setInputText(titleText)
              setRenameTitleModal(false)
            }}
            children={
              <div className={styles.renameWrap}>
                <h3>Rename The Message Subject:</h3>
                <input
                  type="text"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                />
                <button
                  className="button-stroke"
                  onClick={() => {
                    setRenameTitleModal(false)
                    setTitleText(inputText)
                  }}
                >
                  Save
                </button>
              </div>
            }
          />
        </div>
        <Actions
          className={styles.actions}
          classActionsHead={styles.actionsHead}
          classActionsBody={styles.actionsBody}
          items={actions}
        />
        <button className={styles.close} onClick={() => setVisible(false)}>
          <Icon name="close" size="24" />
        </button>
      </div>
      <div className={styles.parameters}>
        {parameters.map((x, index) => (
          <div className={styles.parameter} key={index}>
            {x.title}: <span>{x.content}</span>
          </div>
        ))}
        {selectedImages && (
          <div className={styles.selectedImageContainer}>
            {selectedImages.map((x, index) => (
              <div className={styles.selectedImageWrap} key={index}>
                <ModalImage
                  className={styles.selectedImage}
                  small={x}
                  large={x}
                  hideZoom
                />
                <div className={styles.action}>
                  <button onClick={() => deleteImage(index)}>
                    <Icon name="close" size="16" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Panel;
