import React, { createRef, useEffect } from "react";
import styles from "./CustomerHtmlEmbeddedForm.module.sass";
import $ from "jquery";
import Icon from "../../components/Icon";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const CustomerHtmlEmbeddedForm = () => {
  const fb = createRef();

  var options = {
    dataType: "json",
    disableFields: [
      "autocomplete",
      "file",
      "hidden",
      "date",
      "button",
      "checkbox-group",
      "header",
      "number",
      "paragraph",
      "radio-group",
      "select",
      "text",
      "textarea",
    ],
    formData: [
      {
        type: "header",
        subtype: "h3",
        label: "Contact form",
      },
      {
        type: "text",
        label: "Name",
        className: "form-control",
        required: true,
      },
      {
        type: "text",
        label: "Email",
        className: "form-control",
        required: true,
      },
      {
        type: "text",
        label: "Mobile",
        className: "form-control",
        required: true,
      },
      {
        type: "radio-group",
        label: "Where will the session take place?",
        name: "radio-group-1676692608763",
        values: [
          {
            label: "Option 1",
            value: "option-1",
          },
          {
            label: "Option 2",
            value: "option-2",
          },
          {
            label: "Option 3",
            value: "option-3",
          },
        ],
      },
      {
        type: "textarea",
        label: "Who will be attending this session?",
        className: "form-control",
        required: true,
      },
      {
        type: "button",
        subtype: "submit",
        label: "Submit",
        className: "form-control",
      },
    ],
  };

  useEffect(() => {
    $(fb.current).formBuilder(options);
  }, []);
  return (
    <div className={styles.customerHtmlQuestionerWrap}>
      <div id="fbEditor" ref={fb} />
    </div>
  );
};

export default CustomerHtmlEmbeddedForm;
