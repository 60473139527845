import React from "react";
import styles from "./LargeNav.module.sass";
import cn from "classnames";

const LargeNav = ({
  tabOptions,
  activeTab,
  onClick,
  onlyLarge,
  value,
  category,
  className
}) => {
  return (
    <>
      <div className={cn(styles.largeNav, className, { [styles.onlyLarge]: onlyLarge })}>
        {tabOptions.map((x, index) => (
          <div
            className={cn(styles.item, {
              [styles.active]: (value ? (value === " " ? x : x[value]) : index) === activeTab,
            })}
            key={index}
            onClick={() => onClick(x, index)}
          >
            {x[category] ? x[category] : x}
          </div>
        ))}
      </div>
    </>
  );
};

export default LargeNav;
