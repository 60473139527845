import Icon from '../../../../../../components/Icon';
import styles from '../../../../../Customers/CustomersOverview/Table/Row/Row.module.sass';

const Row = ({
    item,
    deleteAction
}) => {
    return (
        <>
            <div
                className={styles.col}
            >
                {item?.columnType !== "Prospect" && <Icon name="drag" size="25" viewBox="0 0 25 25" />}</div>
            <div className={styles.col}>
                <span className={styles.mblCreate}>Name:</span>
                <div className={styles.user}>
                    <span>{item?.title}</span>
                </div>
            </div>
            <div className={styles.col}>
                <span className={styles.mblCreate}>Email:</span>
                <div className={styles.date}>{`${item?.probability}%`}</div>
            </div>
            <div className={styles.col}>
                <span className={styles.mblCreate}>Mobile:</span>
                <div className={styles.created}>{item?.prospectsCount}</div>
            </div>
            {/* <div className={styles.col}>
                {deleteAction}
            </div> */}
        </>
    )
}

export default Row;