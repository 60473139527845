import React, { useState, useEffect } from "react";
import Card from "../../../../components/Card";
import styles from "./UpdateEmailForm.module.sass";
import TextInput from "../../../../components/TextInput";
import Dropdown from "../../../../components/Dropdown";
import Modal from "../../../../components/Modal";
import Trix from "trix";
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";
import Spinner from "../../../../utils/spinner";
import Icon from "../../../../components/Icon";

const tokenList = [
  {
    name: "Clients Name",
    token: "{clients_name}",
  },
  {
    name: "Clients First Name",
    token: "{clients_first_name}",
  },
  {
    name: "Clients Phone",
    token: "{clients_phone}",
  },
  {
    name: "Clients Address",
    token: "{clients_address}",
  },
  {
    name: "Clients Email",
    token: "{clients_email}",
  },
  {
    name: "Link to Client Portal",
    token: "{link_to_client_portal}",
  },
  {
    name: "Current Date",
    token: "{current_date}",
  },
];

const emailTemplates = [
  "Choose an email template:",
  "Inquiry Response",
  "Invoice Reminder",
  "Payment Received",
];
const subjects = [
  "Thank you so much for your inquiry!",
  "I’m just following-up on an unpaid invoice",
  "I have received your payment.",
];

const defaultTemplates = [
  "Confirmation Template",
  "Reminder Template",
  "Receipt Template",
];

const defaultTemplatesDetails = [
  "Choose a template",
  "Booking Confirmations - Anytime a session is booked an email will automatically be sent to the client with this template.",
  "Booking Reminders - A session reminder email will be sent automatically after the set amount of days you set.",
  "Receipts - Once an invoice is paid the client will automatically receive a receipt with this template.",
];

const UpdateEmailForm = ({
  handleSubmit,
  handleChange,
  userInput,
  setIsUpdate,
  setUserInput,
  x,
  isUpdate,
  handleTrixInputChange,
  emailTemplate,
  setEmailTemplate,
  defaultTemplate,
  setDefaultTemplate,
  updateEmailTemplateLoading
}) => {
  const [token, setToken] = useState(tokenList.map((x) => x.name)[0]);
  const [mblTokenIndex, setMblTokenIndex] = useState(0);
  const [showAddReminderDay, setShowAddReminderDay] = useState(false);

  const toolbarActions = [
    "bold",
    "italic",
    "strike",
    "link",
    "heading1",
    "quote",
    "bullet",
    "number",
    "centered",
    "undo",
    "redo",
  ];

  const handleToken = (e) => {
    const tokenValue = e.target.getAttribute("data-value");
    const trixEditor = document.querySelector("trix-editor");
    let trixEditorValue = trixEditor.innerHTML;
    if(trixEditor.editor && trixEditor.editor.insertString)
    { 
      trixEditor.editor.insertString(tokenValue);
    }
    else
    {
      trixEditorValue = trixEditorValue + tokenValue;
    }
    return trixEditorValue;
  };

  useEffect(() => {
    const tokenValue = tokenList.map((x) => x.token)[mblTokenIndex];
    const trixEditor = document.querySelector("trix-editor");
    const addToken = () => {
      if (trixEditor === null) {
        return null;
      } else {
        let trixEditorValue = trixEditor.innerHTML;
        if(trixEditor.editor && trixEditor.editor.insertString)
        { 
          trixEditor.editor.insertString(tokenValue);
        }
        else
        {
          trixEditorValue = trixEditorValue + tokenValue;
        }
        return trixEditorValue;
      }
    };
    addToken();
  }, [mblTokenIndex]);

  useEffect(
    () =>
      emailTemplate === emailTemplates[0]
        ? setUserInput({ subject: "", emailTemplate: emailTemplates[0] })
        : emailTemplate === emailTemplates[1]
          ? setUserInput({
            subject: subjects[0],
            emailTemplate: emailTemplates[1],
          })
          : emailTemplate === emailTemplates[2]
            ? setUserInput({
              subject: subjects[1],
              emailTemplate: emailTemplates[2],
            })
            : emailTemplate === emailTemplates[3]
              ? setUserInput({
                subject: subjects[2],
                emailTemplate: emailTemplates[3],
              })
              : null,
    [emailTemplate]
  );

  useEffect(
    () =>
      defaultTemplate === defaultTemplatesDetails[0]
        ? (setShowAddReminderDay(false),
          setUserInput({ defaultTemplate: defaultTemplates[0] }))
        : defaultTemplate === defaultTemplatesDetails[1]
          ? (setShowAddReminderDay(false),
            setUserInput({ defaultTemplate: defaultTemplates[1] }))
          : defaultTemplate === defaultTemplatesDetails[2]
            ? (setShowAddReminderDay(true),
              setUserInput({ defaultTemplate: defaultTemplates[2] }))
            : defaultTemplate === defaultTemplatesDetails[3]
              ? (setShowAddReminderDay(false),
                setUserInput({ defaultTemplate: defaultTemplates[3] }))
              : null,
    [defaultTemplate]
  );

  return (
    <Modal
      visible={isUpdate}
      outerClassName={styles.outerWrap}
      onClose={() => {
        setIsUpdate(false);
        setUserInput({
          emailName: x.name,
          trixEditorValue: x.body,
        });
      }}
      children={
        <Card
          /* classTitle="title-blue" */
          title="Email Template"
          className={styles.card}
        >
          <form onSubmit={handleSubmit}>
            <TextInput
              className={styles.input}
              label="Name"
              onChange={handleChange}
              name="emailName"
              id="emailName"
              type="text"
              value={userInput.emailName}
              required
            />

            <TextInput
              className={styles.input}
              label="Subject"
              onChange={handleChange}
              name="subject"
              id="subject"
              type="text"
              value={userInput.subject}
              required
            />

            <div className={styles.detailsWrap}>
              <h3 className={styles.title}>Details</h3>
              <div className={styles.tokenMblWrap}>
                <Dropdown
                  options={tokenList.map((x) => x.name)}
                  value={token}
                  setValue={setToken}
                  handleChangeIndex={(index) => setMblTokenIndex(index)}
                />
              </div>
              <div className={styles.tokenWrap}>
                {tokenList.map((x, index) => (
                  <div
                    data-value={x.token}
                    key={index}
                    className={styles.token}
                    onClick={handleToken}
                  >
                    {x.name}
                  </div>
                ))}
              </div>
              <ReactTrixRTEToolbar
                toolbarActions={toolbarActions}
                toolbarId="react-trix-rte-editor"
              />
              <ReactTrixRTEInput
                toolbarId="react-trix-rte-editor"
                onChange={handleTrixInputChange}
                placeholder="Enter Content"
                defaultValue={userInput.trixEditorValue}
              />
            </div>

            <Dropdown
              classDropdownLabel={styles.dropdown}
              className={styles.input}
              value={emailTemplate}
              setValue={setEmailTemplate}
              options={emailTemplates}
              label="Template Automation"
            />
            {/* <Dropdown
              value={defaultTemplate}
              setValue={setDefaultTemplate}
              options={defaultTemplatesDetails}
              label="Email Template"
            />

            {showAddReminderDay ? (
              <TextInput
                placeholder="Enter the number of days you want the reminder email send before the session start date"
                onChange={handleChange}
                name="reminderDay"
                id="reminderDay"
                type="number"
                value={userInput.reminderDay}
                required
              />
            ) : null} */}
            <div className={styles.newEmailBtn}>
              {/* <button
                onClick={() => {
                  setIsUpdate(false);
                  setUserInput({ emailName: x.name });
                }}
                className="button-stroke"
              >
                Cancel
              </button> */}
              <button className="button">
                {updateEmailTemplateLoading ?
                  <Spinner size="24" color="white" />
                  :(
                  <>
                  Save
                  </>
                )}
              </button>
            </div>
          </form>
        </Card>
      }
    />
  );
};

export default UpdateEmailForm;
