import React from 'react';
import Card from '../../../../../components/Card';
import DeleteModal from '../../../../../components/DeleteModal';
import Icon from '../../../../../components/Icon';
import Spinner from '../../../../../utils/spinner';
import styles from './DeleteAccount.module.sass';
import cn from 'classnames';
import { useState } from 'react';

const DeleteAccount = ({ className, title, subTitle, buttonText, setVisible, deleteLoading, onClick }) => {
    const [visibleDelete, setVisibleDelete] = useState(false);

    return (
        <Card className={className} border>
            <div className={styles.closeTitle}>
                {title || "Close Your Account"}
            </div>
            <div className={styles.container}>
                <div className={styles.closeSubtitle}>
                    {subTitle ||
                        `By closing your account, you will not be able to access your financial data for reporting and tax purposes,
                    create future charges and refunds, and respond to new disputes. Please message us before deleting your Great Week account. In particular if you have your accounting managed by us.`
                    }
                </div>
                <DeleteModal
                    setVisibleDelete={setVisibleDelete}
                    visibleDelete={visibleDelete}
                    button={true}
                    className={cn({ [styles.deleteModal]: false })}
                    deleteBtn={
                        <button
                            className={cn("button-stroke-red")}
                            onClick={async () => {
                                await onClick();
                                setVisibleDelete(false);
                            }}
                        >
                            {deleteLoading ?
                                <Spinner size="24" color="gray" />
                                :
                                <>
                                    <span>{buttonText || "Delete"}</span>
                                </>
                            }
                        </button>
                    }
                />
                <div style={{ marginTop: 14 }}>
                    <button
                        className={cn("button-stroke-red")}
                        onClick={
                            () => setVisible ? setVisible(true) : setVisibleDelete(true)
                        }
                    >
                        <span>{buttonText || "Delete"}</span>
                    </button>
                </div>
            </div>
        </Card>
    )
}

export default DeleteAccount;