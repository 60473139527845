import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Row from "./Row";
import DeleteModal from "../../../../components/DeleteModal";
import Icon from "../../../../components/Icon";
import { TriStateCheckbox, TriStateContext } from "react-tri-state-checkbox";
import Checkbox from "../../../../components/Checkbox";
import "react-tri-state-checkbox/styles/checkbox.css";
import {
  deleteCustomer,
  deleteRecipient,
  getContactsData,
  getCustomer,
  getProspectList,
} from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import NoCustomer from "../../NoCustomer";
import TableRecipients from "../../../Transactions/TransactionSend/RecipientOverview/Table";
import { useHistory } from "react-router";
import RowProspect from "./RowProspect";
import { ToastContainer } from "react-toastify";
import LargeNav from "../../../../components/LargeNav";
import Dropdown from "../../../../components/Dropdown";
import Pipeline from "../Pipeline";

let lastId = "";
const types = ["Customers", "Recipients"];
const customerTypes = ["List", "Pipeline"];
const tabContent = [
  "Contacts",
  "Products",
  "Templates",
  "Event",
  "Documents",
];
let page = 1;

const Table = ({
  className,
  editCustomer,
  setCustomerId,
  visibility,
  setVisible,
  checkCount,
  setShowMainContainer,
  setActionCustomer,
  setCheckCount,
  setDeleteIdArray,
  deleteIdArray,
  noCustomerButtonClickHandler,
  activeIndex,
  setActiveIndex,
  customerData,
  setCustomerData,
  setNextPageInfo,
  isLoading,
  hasMore,
  getCustomerData,
  setShowAddDeal,
  showAddDeal,
  setShowAdjustPipeline,
  showAdjustPipeline,
  customersType,
  setCustomersType,
  isCustomer,
  setIsCustomer,
  setCurrentSelected
}) => {
  const history = useHistory();
  const [showProspectOnly, setShowProspectOnly] = useState(false);
  const [customerProspect, setCustomerProspect] = useState([]);
  const [prospectNextPageExist, setProspectNextPageExists] = useState(false);
  const [prospectLoading, setIsProspectLoading] = useState(false);

  const [selectedType, setSelectedType] = useState("All");

  const [nameSortOrder, setNameSortOrder] = useState("asc");
  const [emailSortOrder, setEmailSortOrder] = useState("asc");
  const [mobileSortOrder, setMobileSortOrder] = useState("asc");
  const [currentSortColumn, setCurrentSortColumn] = useState("name");

  const [filterType, setFilterType] = useState("Customers");

  useEffect(() => {
    if (isCustomer) {
      setFilterType("Customers");
      setSelectedType("Customer");
    } else {
      setFilterType("Recipients")
      setSelectedType("Recipient");
    }
  }, [isCustomer])

  const customerId = customerData.map((x) => {
    return x.id;
  });

  if (customerData.length === 0) {
    lastId = "";
  }

  // const onlyCustomerData = [];
  // const onlyRecipientData = [];
  // customerData.map((x) => {
  //   if (x.type === "Customer") {
  //     onlyCustomerData.push(x);
  //   } else {
  //     onlyRecipientData.push(x);
  //   }
  // });

  const changeCustomerAll = (e) => {
    var ariaStatus = e.target.checked;
    if (ariaStatus === true) {
      const checkboxes = document.getElementsByName("cus-checkbox");

      for (var i = 0, n = checkboxes?.length; i < n; i++) {
        checkboxes[i].checked = true;
      }

      deleteIdArray = [];

      for (let i = 0; i < customerData.length; i++) {
        deleteIdArray.push(customerData[i]?.id);
      }

      setDeleteIdArray([...deleteIdArray]);

      setCheckCount(customerData?.length);
    }
    if (ariaStatus === false) {
      const checkboxes = document.getElementsByName("cus-checkbox");

      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }
      setCheckCount(0);
      setDeleteIdArray([]);
    }
    if (ariaStatus === "mixed") {
      setActionCustomer(false);
      setCheckCount(customerData?.length);
    }
  };

  const changeCustomer = (e, id) => {
    if (e.target.checked) {
      if (checkCount + 1 === customerData?.length) {
        const checkbox = document.getElementsByName("cus-all-checkbox");
        if (checkbox) {
          checkbox[0].checked = true;
        }
      }

      setCheckCount(checkCount + 1);
      setDeleteIdArray([...deleteIdArray, id]);
    }
    if (!e.target.checked) {
      const checkbox = document.getElementsByName("cus-all-checkbox");
      if (checkbox) {
        checkbox[0].checked = false;
      }

      const index = deleteIdArray.indexOf(id);
      if (index > -1) {
        deleteIdArray.splice(index, 1);
      }
      setDeleteIdArray([...deleteIdArray]);

      setCheckCount(checkCount - 1);
    }
  };

  const getProspectData = async (page) => {
    setIsProspectLoading(true);
    const { data } = await getProspectList({
      pageSize: 30,
      page: page || 1,
    });
    if (data) {
      if (page !== 1) {
        setCustomerProspect(customerProspect?.concat(data?.customerProspect));
      } else {
        setCustomerProspect(data?.customerProspect);
      }

      setProspectNextPageExists(data?.isNextPageExist);
    }
    setIsProspectLoading(false);
  };

  return (
    <TriStateContext controls={customerId}>
      <ToastContainer />
      <>
        <>
         
          <LargeNav
            tabOptions={types}
            activeTab={filterType === "Recipients" ? 1 : 0}
            onClick={async (x) => {
              setFilterType(x);

              setNameSortOrder('asc');
              setMobileSortOrder('asc');
              setEmailSortOrder('asc');

              if (x === "Recipients") {
                setCurrentSelected(1);
                setCustomersType("List");
                setIsCustomer(false)
                setCustomerData([]);
                await getCustomerData(false, "Recipient");
              } else {
                setCurrentSelected(0)
                setIsCustomer(true);
                setCustomerData([]);
                await getCustomerData(false, "Customer");
              }
            }}
            onlyLarge
          />

          {filterType !== "Recipients" && (
            <LargeNav
              tabOptions={customerTypes}
              activeTab={customersType === "Pipeline" ? 1 : 0}
              onClick={async (x) => {
                setCustomersType(x);
                if (x === "Pipeline") {
                  // page = 1;
                  // setShowProspectOnly(true);
                  // setCustomerProspect([]);
                  // setProspectNextPageExists(false);
                  // await getProspectData(page);
                } else {
                  lastId = "";
                  setCustomerData([]);
                  setNextPageInfo(null);
                  setShowProspectOnly(false);
                  await getCustomerData(false);
                }
              }}
              onlyLarge
            />
          )}

          {/* <Dropdown
            options={customerTypes}
            className={styles.dropdown}
            handleChange={async () => {
              
            }}
            value={customersType}
            setValue={setCustomersType}
            small
          /> */}
        </>

        {customersType === "Pipeline" ?
          <Pipeline
            setShowAddDeal={setShowAddDeal}
            showAddDeal={showAddDeal}
            setShowAdjustPipeline={setShowAdjustPipeline}
            showAdjustPipeline={showAdjustPipeline}
          />
          : <>
            <div
              className={cn(styles.wrapper, className)}
              style={{ marginTop: 36 }}
            >
              {showProspectOnly ? (
                prospectLoading && customerProspect?.length < 1 ? (
                  <div
                    className={styles.spinner}
                    style={{ height: window.outerHeight - 450 }}
                  >
                    <Spinner loading={prospectLoading} size={50} color={"gray"} />
                  </div>
                ) : (
                  <div className={cn(styles.table)}>
                    {customerProspect.length > 0 ? (
                      <>
                        <div className={cn(styles.row)}>
                          <div className={styles.col}></div>
                          <div className={styles.col}>Name</div>
                          <div className={styles.col}>Email</div>
                          <div className={styles.col}>Mobile</div>
                          <div className={styles.col}>Notes</div>
                          <div className={styles.col}></div>
                        </div>
                        {customerProspect?.map((x, index) => (
                          <RowProspect
                            item={x}
                            onConfirm={async () => {
                              page = 1;
                              setShowProspectOnly(true);
                              setCustomerProspect([]);
                              setProspectNextPageExists(false);
                              await getProspectData(page);
                            }}
                          />
                        ))}
                        {prospectNextPageExist ? (
                          <div className={styles.foot}>
                            <button
                              className={cn(
                                "button-stroke-red button-small",
                                styles.button
                              )}
                              onClick={async () => {
                                page = page + 1;
                                await getProspectData(page);
                              }}
                              style={{ width: 300 }}
                            >
                              {prospectLoading ? (
                                <Spinner
                                  loading={prospectLoading}
                                  size={20}
                                  color={"gray"}
                                />
                              ) : (
                                <>
                                  <span>See More Prospect Customers</span>
                                  <Icon name='arrow-next' size='20'></Icon>
                                </>
                              )}
                            </button>
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <NoCustomer
                        onConfirm={noCustomerButtonClickHandler}
                        activeIndex={activeIndex}
                      />
                    )}
                  </div>
                )
              ) : isLoading && customerData.length < 1 ? (
                <div
                  className={styles.spinner}
                  style={{ height: window.outerHeight - 450 }}
                >
                  <Spinner loading={isLoading} size={50} color={"gray"} />
                </div>
              ) : (
                <div className={cn(styles.table)}>
                  {customerData.length > 0 ? (
                    <>
                      <div className={cn(styles.row)}>
                        {/* <div className={styles.editOverlayHead}></div> */}
                        <div className={styles.col}>
                          <Checkbox
                            className={styles.checkbox}
                            onChange={changeCustomerAll}
                            name={"cus-all-checkbox"}
                          />
                        </div>
                        <div className={styles.col}></div>
                        <div className={styles.col} >
                          <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                            onClick={async () => {
                              setCurrentSortColumn("name");
                              const currentSortValue = nameSortOrder === 'asc' ? 'desc' : 'asc';
                              setCustomerData([]);
                              await getCustomerData(
                                false,
                                filterType === "Recipients" ? 'Recipient' : 'Customer',
                                "name",
                                currentSortValue
                              );
                              setNameSortOrder(currentSortValue)
                              setEmailSortOrder('asc');
                              setMobileSortOrder('asc');
                            }}
                          >
                            <span>Name</span>
                            {currentSortColumn === "name" && (
                              <div style={{
                                marginLeft: 4,
                                rotate: nameSortOrder === "asc" ? '180deg' : '0deg'
                              }}
                              >
                                <Icon name="sort_descending" size="20" fill="#C7C5BF" />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles.col} >
                          <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                            onClick={async () => {
                              setCurrentSortColumn("email");
                              const currentSortValue = emailSortOrder === 'asc' ? 'desc' : 'asc';
                              setCustomerData([]);
                              await getCustomerData(
                                false,
                                filterType === "Recipients" ? 'Recipient' : 'Customer',
                                "email",
                                currentSortValue
                              );
                              setEmailSortOrder(currentSortValue)
                              setNameSortOrder('asc');
                              setMobileSortOrder('asc');
                            }}
                          >
                            <span>Email </span>
                            {currentSortColumn === "email" && (
                              <div style={{
                                marginLeft: 4,
                                rotate: emailSortOrder === "asc" ? '180deg' : '0deg'
                              }}
                              >
                                <Icon name="sort_descending" size="20" fill="#C7C5BF" />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles.col}>
                          <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                            onClick={async () => {
                              setCurrentSortColumn("phone")
                              const currentSortValue = mobileSortOrder === 'asc' ? 'desc' : 'asc';
                              setCustomerData([]);
                              await getCustomerData(
                                false,
                                filterType === "Recipients" ? 'Recipient' : 'Customer',
                                "phone",
                                currentSortValue
                              );
                              setMobileSortOrder(currentSortValue)
                              setEmailSortOrder('asc');
                              setNameSortOrder('asc');
                            }}
                          >
                            <span> Mobile </span>
                            {currentSortColumn === "phone" && (
                              <div style={{
                                marginLeft: 4,
                                rotate: mobileSortOrder === "asc" ? '180deg' : '0deg'
                              }}
                              >
                                <Icon name="sort_descending" size="20" fill="#C7C5BF" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {customerData.map((x, index) => {
                        return (
                          (selectedType === "All" ||
                            (selectedType === "Customer" &&
                              ["Customer", "Both"].includes(x.type)) ||
                            (selectedType === "Recipient" &&
                              ["Recipient", "Both"].includes(x.type)) ||
                            x.type === selectedType) && (
                            <Row
                              item={x}
                              key={index}
                              activeIndex={activeIndex}
                              checkCount={checkCount}
                              editAction={
                                <button
                                  className={styles.actionItem}
                                  onClick={() => {
                                    if (x.type === "Recipient") {
                                      history.push({
                                        pathname: `/contacts/${x.id}`,
                                        state: { isComingFromCustomer: true },
                                      });
                                    } else {
                                      setVisible(false);
                                      setCustomerId(x.id);
                                    }
                                  }}
                                >
                                  <Icon name='edit' size='24' />
                                  Edit
                                </button>
                              }
                              onChange={changeCustomer}
                              deleteIdArray={deleteIdArray}
                              deleteAction={
                                x.type === "Recipient" ? (
                                  <DeleteModal
                                    icon={
                                      <Icon
                                        className={styles.btnIcon}
                                        fill='#A08B5E'
                                        name='trash'
                                        size='20'
                                      />
                                    }
                                    className={styles.deleteBtn}
                                    deleteFunction={deleteRecipient}
                                    setValue={true}
                                    setShowMainContainer={setShowMainContainer}
                                    id={x.id}
                                    btnClass={cn(styles.btnClass)}
                                  />
                                ) : (
                                  <DeleteModal
                                    id={x.id}
                                    deleteFunction={deleteCustomer}
                                    setValue={true}
                                    setShowMainContainer={setShowMainContainer}
                                    icon={
                                      <Icon
                                        className={styles.btnIcon}
                                        fill='#A08B5E'
                                        name='trash'
                                        size='20'
                                      />
                                    }
                                    className={styles.deleteBtn}
                                    btnClass={cn(styles.btnClass)}
                                  />
                                )
                              }
                              editCustomer={() => {
                                if (x.type === "Recipient") {
                                  history.push({
                                    pathname: `/contacts/${x.id}`,
                                    state: { isComingFromCustomer: true },
                                  });
                                } else {
                                  setVisible(false);
                                  setCustomerId(x.id);
                                }
                              }}
                            />
                          )
                        );
                      })}
                    </>
                  ) : (
                    <NoCustomer
                      onConfirm={noCustomerButtonClickHandler}
                      activeIndex={activeIndex}
                    />
                  )}
                </div>
              )}
            </div>
            {(hasMore && customersType !== "Pipeline") ? (
              <div className={styles.foot}>
                <button
                  className={cn("button-stroke-red button-small", styles.button)}
                  onClick={async () => {
                    let sort = "name";
                    let sortOrder = "asc";

                    if (nameSortOrder === "desc") {
                      sortOrder = "desc";
                    } else if (emailSortOrder === "desc") {
                      sort = "email";
                      sortOrder = "desc"
                    } else if (mobileSortOrder === "desc") {
                      sort = "phone";
                      sortOrder = "desc";
                    }

                    await getCustomerData(
                      true,
                      filterType === "Recipients" ? 'Recipient' : 'Customer',
                      sort,
                      sortOrder
                    );
                  }}
                  style={{ width: 200 }}
                >
                  {isLoading && customerData?.length > 0 ? (
                    <Spinner loading={isLoading} size={20} color={"gray"} />
                  ) : (
                    <>
                      <span>{`See More ${customerData?.length > 0 ? customerData[0]?.type === "Customer" ?
                        'Customers' : 'Recipients' : 'Customers'}`}</span>
                      <Icon name='arrow-next' size='20'></Icon>
                    </>
                  )}
                </button>
              </div>
            ) : null}
          </>
        }
      </>
    </TriStateContext>
  );
};

export default Table;
