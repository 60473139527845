import React, { useEffect, useState } from 'react';

import styles from './VentureCapital.module.sass';
import cn from 'classnames';
import Search from '../../MessagesWithAI/prompts/Search';
import Row from './Row';
import LargeNav from '../../../components/LargeNav';
import { convertLenderToProspect, getCapitalLendersVentureList } from '../../../utils/apiCallHanlder';
import { toast } from 'react-toastify';
import { toastConfiguration } from '../../../utils/utils';
import Spinner from '../../../utils/spinner';
import Icon from '../../../components/Icon';
import Pipeline from './Pipeline';
import AdjustPipeline from './Pipeline/AdjustPipeline';

const list = ["List", "Pipeline"]

const VentureCapital = ({
    activeIndex,
    setActiveIndex,
    setShowAddProspect,
    showAddProspect,
    showAdjustPipeline,
    setShowAdjustPipeline,
    setShowAddColumnModal,
    showAddColumnModal,
    setColumnId,
    columnId
}) => {
    const [searchString, setSearchString] = useState('');

    const [ventures, setVentures] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [getMoreLoading, setGetMoreLoading] = useState(false);

    useEffect(() => {
        getVentureCapitalList();
    }, [])

    const getVentureCapitalList = async (pageNo) => {
        if (pageNo) setPage(pageNo);
        else setPage(1);

        const { data, error } = await getCapitalLendersVentureList({
            searchString,
            page: pageNo || 1,
            pageSize: 10
        })

        if (data) {
            setHasMore(data?.isNextPageExist);
            pageNo ? setVentures(ventures?.concat(data?.vendorList)) : setVentures(data?.vendorList)
        } else if (error) {
            toast.error(error || "Error in getting capital venture list", toastConfiguration);
        }

        setLoading(false);
    }

    const addLenderToPipeline = async (id) => {
        const { data, error } = await convertLenderToProspect({ LenderId: id });
        if (error) {
            toast.error(error || "Error in adding lender to pipeline", toastConfiguration)
            return;
        } else if (data) {
            toast.success("Lender added to prospect", toastConfiguration);
            setVentures(ventures.filter(v => v.lenderId !== id));
            return data
        }
    }

    return <div>
        <LargeNav
            className={styles.typeNav}
            tabOptions={list}
            activeTab={activeIndex}
            onClick={(_, index) => {
                setShowAdjustPipeline(false);
                setActiveIndex(index)
                if (index === 0) getVentureCapitalList();
            }}
        />
        {activeIndex === 0 && (
            <>
                <Search
                    getSearchResult={() => {
                        setLoading(true);
                        getVentureCapitalList()
                    }}
                    resetSearch={() => { }}
                    searchString={searchString}
                    setSearchString={setSearchString}
                />
                <div className={styles.table}>
                    <div className={styles.row}>
                        <div className={styles.col}></div>
                        <div className={styles.col}>Name</div>
                        <div className={styles.col}>Links</div>
                        <div className={styles.col}>Email</div>
                        <div className={styles.col}>Focus</div>
                        <div className={styles.col}></div>
                    </div>
                    {loading ?
                        <div className={styles.spinner}>
                            <Spinner size={48} color={"grey"} />
                        </div>
                        :
                        ventures?.length > 0 ?
                            ventures?.map((x, index) => (
                                <Row
                                    item={x}
                                    key={index}
                                    rowClickHandler={() => { }}
                                    deleteModal={null}
                                    addLenderToPipeline={addLenderToPipeline}
                                />

                            ))
                            :
                            <div className={styles.noData}>
                                <span>No venture capitals to show</span>
                            </div>
                    }
                </div>

                {hasMore ?
                    <div className={styles.foot}>
                        <button
                            className={cn("button-stroke-red button-small", styles.button)}
                            onClick={async () => {
                                setGetMoreLoading(true);
                                await getVentureCapitalList(page + 1);
                                setGetMoreLoading(false);
                            }}
                            style={{ width: 264 }}
                        >
                            {getMoreLoading ?
                                <Spinner loading={getMoreLoading} size={20} color={'gray'} />
                                :
                                <>
                                    <span>See More Venture Capitals</span>
                                    <Icon name="arrow-next" size="20"></Icon>
                                </>
                            }

                        </button>
                    </div>
                    : null
                }
            </>
        )}
        {activeIndex === 1 && (
            showAdjustPipeline ?
                <AdjustPipeline
                    setShowAdjustPipeline={setShowAdjustPipeline}
                    setColumnId={setColumnId}
                    columnId={columnId}
                    setShowAddColumnModal={setShowAddColumnModal}
                    showAddColumnModal={showAddColumnModal}
                />
                :
                <Pipeline
                    setShowAddProspect={setShowAddProspect}
                    showAddProspect={showAddProspect}
                />
        )}
    </div>
}

export default VentureCapital;