import React from "react";
import Card from "../../../../components/Card";
import cn from "classnames";
import styles from "./NoSubscriptions.module.sass";
import Image from "../../../../components/Image";
import Icon from '../../../../components/Icon'

const NoSubscriptions = ({ onConfirm, noSubscriptionsText, noSubscriptionsPara, createNewBtn }) => {
  return (
    <>
      <div className={styles.row} style={{ padding: 24 }}>
        <div className={styles.col}>
          <Image
            className={styles.pic}
            src="/images/icons/empty-black.png"
            srcDark="/images/icons/empty-white.png"
            alt="Core"
          />
        </div>
      </div>
    </>
  );
};

export default NoSubscriptions;
