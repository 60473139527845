import { useEffect, useState } from "react";
import {
  addToDOTask,
  deleteToDOTask,
  getAllTasks,
  getAllTeam_TodoTask,
  getSingleToDoTask,
  getToDOSpaces,
  updateToDOTask,
  uploadFile,
} from "../../../../utils/apiCallHanlder";
import Styles from "../Pipeline.module.sass";
import Icon from "../../../../components/Icon";
import {
  GetFileNameFromPath,
  getCurrentUser,
  toastConfiguration,
  transactionDateFormatter,
  truncateWithEllipsis,
} from "../../../../utils/utils";
import Spinner from "../../../../utils/spinner";
import Comment from "../Comments";
import Dropdown from "../../../../components/Dropdown";
import TeamsDropdown from "../../../../components/Dropdown_c";
import DateInput from "../../../../components/DateInput";
import DeleteModal from "../../../../components/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Card from "../../../../components/Card";
import TextInput from "../../../../components/TextInput";
import { useHistory, useParams } from "react-router-dom";
import PdfViewer from "../PdfViewer";
import cn from "classnames"

const defaultChooseTeams = {
  userid: "choose",
  name: "Choose",
};

const timeArray = Array.from({ length: 24 }, (_, index) => {
  const hour = index + 1;
  const suffix = hour === 1 ? " hr" : " hrs";
  return `${hour}${suffix}`;
});

const imageWRTExtension = {
  common: "/images/content/image.png",
  xlsx: "/images/content/xlsx.png",
  xls: "/images/content/xlsx.png",
  pdf: "/images/content/pdf.png",
};

let boardData = { columns: [] };
let userImage = null;

const Edit = () => {
  const history = useHistory();
  const { id } = useParams();

  const [name, setName] = useState("");
  const [date, setDate] = useState(new Date());
  const [responsible, setResponsible] = useState("");
  const [timeSpent, setTimeSpent] = useState("Choose");
  const [status, setStatus] = useState("");
  const [space, setSpace] = useState("");
  const [taskDescription, setTaskDescription] = useState("");

  const [statuses, setStatuses] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [comments, setComments] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);

  const [showAddNewComment, setShowAddNewComment] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [taskNumber, setTaskNumber] = useState(null);

  useEffect(() => {
    if (id) {
      getCurrentTask(id);
    }
  }, [id]);

  const getCurrentTask = async (taskId) => {
    setFetchLoading(true);
    userImage = getCurrentUser()?.imageurl;
    await getTeamMembers();
    await getSpaces();
    await getPipelineColumnsList();

    if (taskId === "Add") {
      setFetchLoading(false);
      return;
    }

    const { data } = await getSingleToDoTask(taskId);
    if (data?.task) {
      const task = data?.task;
      const column = boardData?.columns?.filter(
        (item) => item?.toDoPipelineId === task?.toDoPipelineId
      );

      setComments(data?.taskComments || []);
      setName(task?.title || "");
      setDate(task?.dueOn ? new Date(task.dueOn) : new Date());
      setResponsible(task?.assignedTo || defaultChooseTeams?.userid);
      setTimeSpent(
        task?.timeSpent ? `${(task?.timeSpent || 0) / 60} hr` : "Choose"
      );
      setStatus(
        (column[0] && column[0]?.title) || (statuses[0] && statuses[0])
      );
      setSpace(task?.toDoSpaceId || (spaces[0] && spaces[0]?.id));
      setTaskDescription(task?.detail || "");
      setAttachments(task?.attachments || []);
      setTaskNumber(task?.taskNumber);
      setSelectedTaskId(task?.toDoTaskId);
    }

    setFetchLoading(false);
  };

  const getTeamMembers = async () => {
    const { data } = await getAllTeam_TodoTask();
    if (data?.team) {
      setResponsible(defaultChooseTeams?.userid);
      setTeams([...(data?.team || [])]);
    }
  };

  const getSpaces = async () => {
    const { data } = await getToDOSpaces();
    if (data?.list) {
      const newPopulatedValues = data?.list?.map((item) => {
        return {
          name: item?.title,
          id: item?.toDoSpaceId,
        };
      });
      setSpace(newPopulatedValues[0] && newPopulatedValues[0]?.id);
      setSpaces(newPopulatedValues || []);
    }
  };

  const getPipelineColumnsList = async () => {
    const { data } = await getAllTasks({});

    if (data) {
      boardData = { columns: data?.columns };
      const stages = data?.columns?.map((item) => item?.title);
      setStatuses(stages);
      setStatus(stages[0]);
    }
  };

  const { getRootProps } = useDropzone({
    noDragEventsBubbling: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        imageUploadHandler(acceptedFiles[0]);
      }
    },
  });

  const imageUploadHandler = async (file) => {
    setImageUploadLoading(true);
    const formData = new FormData();
    formData.append("", file);
    const { data, error } = await uploadFile(formData);

    if (data) {
      const imageUrl = data?.file?.url;
      setAttachments([...attachments, imageUrl]);
    } else {
      toast.error(error || "Error in uploading file", toastConfiguration);
    }
    setImageUploadLoading(false);
  };

  const addTaskHandler = async () => {
    if (name?.trim() === "") {
      toast.error("Name is required", toastConfiguration);
      return;
    }

    setAddLoading(true);
    const column = boardData?.columns?.filter((item) => item?.title === status);
    const payload = {
      title: name,
      detail: taskDescription,
      toDoPipelineId: column[0] && column[0]?.toDoPipelineId,
      ToDoSpaceId: space === "All" ? undefined : space,
      AssignedTo: responsible === "choose" ? undefined : responsible,
      dueOn: transactionDateFormatter(date),
      timeSpent:
        timeSpent?.toLowerCase() === "choose"
          ? undefined
          : (timeSpent?.split(" ")[0] || 1) * 60,
      attachments: attachments,
    };

    const { data, error } = selectedTaskId
      ? await updateToDOTask({
          toDoTaskId: selectedTaskId,
          ...payload,
        })
      : await addToDOTask(payload);

    if (data) {
      redirectionToBoard();
      toast.success(
        `Task ${selectedTaskId ? "updated" : "added"} successfully`,
        toastConfiguration
      );
    } else {
      toast.error(
        error ||
          `Error in ${selectedTaskId ? "adding" : "updating"} to do task`,
        toastConfiguration
      );
    }

    setAddLoading(false);
  };

  const deleteHandler = async (id) => {
    setDeleteLoading(true);
    const { data, error } = await deleteToDOTask(id);
    if (data) {
      redirectionToBoard();
      toast.success("Task deleted Successfully", toastConfiguration);
    } else {
      toast.error(error || "Error in deleting task", toastConfiguration);
    }
    setDeleteLoading(false);
  };

  const redirectionToBoard = () => {
    history.push("/crm/task");
  };

  const downloadAttachment = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);

    link.style.display = "none";
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  return (
    <>
      <ToastContainer />
      <Card
        title={
          !fetchLoading && id && id !== "Add"
            ? `Task - ${taskNumber}` || "Task"
            : "Task"
        }
        classTitle={Styles.cardTitleClass}
        classCardHead={Styles.cardHead}
        border
        head={
          <div style={{ display: "flex", gap: 12 }}>
            <button className="button-stroke-red" onClick={redirectionToBoard}>
              Cancel
            </button>
            {selectedTaskId && (
              <>
                <DeleteModal
                  className={Styles.deleteBtn}
                  btnTxt={"Delete"}
                  deleteBtn={
                    <button
                      className="button"
                      style={{ width: 150 }}
                      onClick={() => deleteHandler(selectedTaskId)}
                    >
                      {deleteLoading ? (
                        <Spinner
                          size="24"
                          color="white"
                          loading={deleteLoading}
                        />
                      ) : (
                        "Yes I'm sure!"
                      )}
                    </button>
                  }
                />
              </>
            )}
            <button
              className="button"
              onClick={addTaskHandler}
              style={{ width: 80 }}
            >
              {addLoading ? <Spinner size="24" color="white" /> : "Save"}
            </button>
          </div>
        }
      >
        {fetchLoading ? (
          <div className={Styles.spinnerContainer}>
            <Spinner size="48" color="gray" />
          </div>
        ) : (
          <div className={Styles.wrapper}>
            <div className={Styles.inputBoxWrap}>
              <TextInput
                onChange={(e) => setName(e.target.value)}
                className={Styles.inputBox}
                value={name}
                label="Name"
                name="name"
                type="text"
                required
              />
              <DateInput
                classInputValue={Styles.inputValue}
                label="Due"
                classInput={Styles.dateRangeInput}
                icon=""
                hidePast={true}
                className={cn(Styles.calendar, Styles.inputBox)}
                setComponentStartDate={setDate}
                componentStartDate={date}
              />
              <div className={Styles.inputBox}>
              <TeamsDropdown
                label="Responsible"
                className={Styles.dropdown}
                classDropdownHead={Styles.dropdownHead}
                value={responsible}
                setValue={setResponsible}
                bodyStyles={{ width: "100%" }}
                options={[defaultChooseTeams, ...(teams || [])]}
              />
              </div>
              <div className={Styles.inputBox}>
                <TeamsDropdown
                  label="Spaces"
                  className={Styles.dropdown}
                  classDropdownHead={Styles.dropdownHead}
                  value={space}
                  setValue={setSpace}
                  bodyStyles={{ width: "100%" }}
                  options={spaces?.filter((value) => value?.name !== "All")}
                />
              </div>
              <div className={Styles.inputBox}>
                <Dropdown
                  label={"Time Spent"}
                  className={Styles.dropdown}
                  classDropdownHead={Styles.dropdownHead}
                  setValue={setTimeSpent}
                  value={timeSpent}
                  options={["Choose", ...timeArray]}
                  bodyStyles={{ width: "100%" }}
                />
              </div>
              <div className={Styles.inputBox}>
                <Dropdown
                  label={"Status"}
                  className={Styles.dropdown}
                  classDropdownHead={Styles.dropdownHead}
                  setValue={setStatus}
                  value={status}
                  options={statuses}
                  bodyStyles={{ width: "100%" }}
                />
              </div>
            </div>
            <div>
              <span className={Styles.fieldName}>Task Description</span>
              <textarea
                onChange={(e) => setTaskDescription(e.target.value)}
                rows={"4"}
                value={taskDescription}
                className={Styles.inputArea}
                label="description"
                type="text"
                required
              />
            </div>

            <div className={Styles.titleContainer}>
              <span className={Styles.fieldName}>Attachments</span>
              {attachments?.length > 0 && (
                <div className={Styles.number}>
                  <div style={{ paddingTop: 2 }}>{attachments?.length}</div>
                </div>
              )}
            </div>

            <div className={Styles.selectedImageWrap}>
              {attachments?.length > 0 &&
                attachments?.map((x, index) => {
                  const fileNameObj = GetFileNameFromPath(x, true);
                  return (
                    <div
                      key={index}
                      style={{ position: "relative", height: "max-content" }}
                    >
                      {fileNameObj?.extension === "pdf" ? (
                        <PdfViewer
                          src={x}
                          closeButton={
                            <div className={Styles.actionDownload}>
                              <button onClick={() => downloadAttachment(x)}>
                                <Icon name="download" size="24" />
                              </button>
                              <button
                                onClick={() => {
                                  const filtertedAttachments =
                                    attachments?.filter((item) => item !== x);
                                  setAttachments(filtertedAttachments);
                                }}
                              >
                                <Icon name="trash" size="24" fill="#FF4900" />
                              </button>
                            </div>
                          }
                        />
                      ) : (
                        <>
                          <img
                            className={Styles.selectedImage}
                            src={
                              fileNameObj.extension === "png" ||
                              fileNameObj.extension === "jpg" ||
                              fileNameObj.extension === "jpeg"
                                ? x
                                : imageWRTExtension[fileNameObj.extension] ||
                                  imageWRTExtension["common"]
                            }
                            hideZoom
                          />
                          <div className={Styles.actionDownload}>
                            <button onClick={() => downloadAttachment(x)}>
                              <Icon name="download" size="24" />
                            </button>
                            <button
                              onClick={() => {
                                const filtertedAttachments =
                                  attachments?.filter((item) => item !== x);
                                setAttachments(filtertedAttachments);
                              }}
                            >
                              <Icon name="trash" size="24" fill="#FF4900" />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              <div {...getRootProps({ className: "dropzone" })}>
                <div className={Styles.dropzoneWrap}>
                  <p className={Styles.dropzone}>
                    {" "}
                    <Icon name="file-add" size="64" />
                    <span>
                      {imageUploadLoading
                        ? "Uploading ..."
                        : "Drag and drop or click to upload"}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className={Styles.titleContainer}>
              <span className={Styles.fieldName}>Comments</span>
              {comments?.length > 0 && (
                <div className={Styles.number}>
                  <div style={{ paddingTop: 2 }}>{comments?.length}</div>
                </div>
              )}
            </div>
            {selectedTaskId && (
              <>
                {showAddNewComment ? (
                  <Comment
                    id={selectedTaskId}
                    setShowAddNewComment={setShowAddNewComment}
                    comments={comments}
                    setComments={setComments}
                    autoFocus={true}
                    userImage={userImage}
                    downloadAttachment={downloadAttachment}
                  />
                ) : (
                  <div className={Styles.titleContainer}>
                    {userImage && (
                      <img
                        alt="user-profile"
                        src={userImage}
                        className={Styles.userImge}
                      />
                    )}
                    <TextInput
                      onClick={() => setShowAddNewComment(true)}
                      className={Styles.width100}
                      placeholder={"Add a comment..."}
                      name="name"
                      type="text"
                      required
                    />
                  </div>
                )}
                {comments?.map((item, index) => {
                  return (
                    <div key={index}>
                      <Comment
                        item={item}
                        id={selectedTaskId}
                        setComments={setComments}
                        comments={comments}
                        setShowAddNewComment={setShowAddNewComment}
                        userImage={userImage}
                        downloadAttachment={downloadAttachment}
                      />
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}
      </Card>
    </>
  );
};

export default Edit;
