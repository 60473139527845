import React from "react";
import cn from "classnames";
import styles from "./Chart.module.sass";
import {
  ComposedChart,
  BarChart,
  LineChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import useDarkMode from "use-dark-mode";
import { toTitleCase } from "../../../../utils/utils";


const datas = [
  {
    name: "Apr",
    income: 700,
    expense: 600,
    profit: 100,
    taxes: 300,
  },
  {
    name: "May",
    income: 800,
    expense: 900,
    profit: 300,
    taxes: 500,
  },
  {
    name: "Jun",
    income: 400,
    expense: 500,
    profit: 900,
    taxes: 300,
  },
  {
    name: "Jul",
    income: 400,
    expense: 900,
    profit: 600,
    taxes: 500,
  },
  {
    name: "Aug",
    income: 800,
    expense: 300,
    profit: 400,
    taxes: 800,
  },
  {
    name: "Sep",
    income: 900,
    expense: 600,
    profit: 700,
    taxes: 400,
  },
];

const CustomTooltip = ({ active, payload, label, comparedWithPrevYear }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltipContainer}>
        <p className={styles.toolTipLabel}>{`${label + " " + payload[0]?.payload?.year}`}</p>
        <div className={styles.listContainer}>
          {payload.map((item) => {
            return <div className={styles.contentContainer} style={{ fontSize: 12 }}>
              <div className={styles.box} style={{
                backgroundColor: item?.fill
              }}></div>
              <div>
                {(item?.name === "revenue" ? "Income" : toTitleCase(item?.name)) + " " + item?.payload[item?.name]}
              </div>
            </div>
          })}
          {comparedWithPrevYear && payload[0]?.payload?.lastYear && (
            <>
              <div className={styles.line}></div>
              <p className={styles.toolTipLabel}>{`vs ${label + " " + (payload[0]?.payload?.year - 1)}`}</p>
              {payload.map((item) => {
                return <div className={styles.contentContainer} style={{ fontSize: 12 }}>
                  <div className={styles.box} style={{
                    backgroundColor: item?.fill
                  }}></div>
                  <div>
                    {(item?.name === "revenue" ? "Income" : toTitleCase(item?.name)) + " " + item?.payload?.lastYear[item?.name] + ` (${item?.payload[`${item.name}VsLast`] > 0 ?
                      '+' + item?.payload[`${item.name}VsLast`]?.toFixed(1) : item?.payload[`${item.name}VsLast`]?.toFixed(1)
                      }%)`}
                  </div>
                </div>
              })}
            </>
          )}
        </div>
      </div>
    );
  }

  return null;
};

const Chart = ({ activeIncome, activeExpense, activeProfit, comparedWithPrevYear, data }) => {

  return (
    <div className={styles.chart}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={data} margin={{ top: 20, left: 20, right: 20, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={
            <CustomTooltip
              comparedWithPrevYear={comparedWithPrevYear}
            />}
          />
          <Legend />
          {activeIncome && (<Bar dataKey="revenue" fill="#C7C5BF" />)}
          {activeExpense && (<Bar dataKey="expense" fill="#FF4900" />)}
          {activeProfit && (<Line type="monotone" dataKey="profit" stroke="#FFA96B" />)}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
