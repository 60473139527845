/* global Stripe */

import React, { useEffect, useState } from 'react';
import Slider from 'react-input-slider';
import cn from 'classnames';
import TextInput from '../../../../components/TextInput';

import styles from './Application.module.sass';
import Checkbox from '../../../../components/Checkbox';
import Item from '../../../Home/Overview/Item';
import { GetLinkAccountData, createLinkAccountSession, getCapitalLinkAccount } from '../../../../utils/apiCallHanlder';
import { toast } from 'react-toastify';
import { formatDateCapital, toastConfiguration } from '../../../../utils/utils';
import Spinner from '../../../../utils/spinner';

const sliderStyles = {
    track: {
        marginTop: 18,
        backgroundColor: '#FF4900',
        width: '100%'
    },
    active: {
        backgroundColor: '#FF4900'
    },
}

const Application = ({
    applicationData,
    currentStep,
    terms,
    setTerms,
    loanAmount,
    setLoanAmount,
    selectedAccount,
    setSelectedAccount,
    accounts,
    setAccounts,
    showWarning
}) => {
    const [linkLoading, setLinkLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isStripeTrandingHistoryExists, setIsStripeTrandingHistoryExists] = useState(false);

    useEffect(() => {
        if (applicationData) {
            setTerms(applicationData?.capitalApplied?.term);
            setLoanAmount(applicationData?.capitalApplied?.amount);
        }
    }, [applicationData])

    useEffect(() => {
        if (currentStep === 0) {
            getAccounts();
        }
    }, [currentStep])

    const getAccounts = async () => {
        const { data } = await getCapitalLinkAccount({});
        if (data) {
            setAccounts(data?.accounts || []);
            setIsStripeTrandingHistoryExists(data?.isStripeTrandingHistoryExists || false);
        }

        setLoading(false);
    }

    const linkExistingBankAccountHandler = async () => {
        setLinkLoading(true);
        const { data } = await createLinkAccountSession();

        if (data) {
            var stripe = Stripe(data?.stripe_pk, {
                stripeAccount: data.stripeAccountId
            });
            stripe.collectFinancialConnectionsAccounts({
                clientSecret: data?.session?.clientSecret
            })
                .then(async function (result) {
                    if (result.error) {
                        setLinkLoading(false);
                        toast.error(result.error.message || "Failed to add linked account", toastConfiguration);
                    } else if (result.financialConnectionsSession.accounts.length === 0) {
                        setLinkLoading(false);
                        toast.error("No accounts were linked", toastConfiguration);
                    } else {
                        getAccounts();
                        setLinkLoading(false)
                    }
                }).catch(() => {
                    setLinkLoading(false);
                })
        }
    }

    return currentStep === 1 ?
        <div className={styles.contentContainer}>
            <span className={styles.disclaimerTop}>
                {`You applied for
                $${applicationData?.capitalApplied?.amount}
                to be repaid in
                ${applicationData?.capitalApplied?.term}
                months on
                ${formatDateCapital(new Date(applicationData?.capitalApplied?.appliedOn))}.
                We are reviewing your application and will notify you as soon as we are able to extend an offer.
                If you want to get in touch, please send us an email on hello@greatweek.com
                `}
            </span>
        </div>
        :
        <div className={styles.applicationContainer}>
            <div className={styles.inputContainer}>
                <div className={styles.singleInput}>
                    <TextInput
                        label="Loan amount"
                        value={loanAmount}
                        type="number"
                        onChange={(e) => setLoanAmount(e.target.value)}
                    //currency={'$'}
                    />
                    <Slider
                        axis="x"
                        xmin={10000}
                        xmax={500000}
                        styles={sliderStyles}
                        x={loanAmount}
                        onChange={({ x }) => setLoanAmount(x)}
                    />
                </div>
                <div className={styles.singleInput}>
                    <TextInput
                        label="Term"
                        value={terms}
                        type="number"
                        onChange={(e) => setTerms(e.target.value)}
                        text={'Months'}
                    />
                    <Slider
                        axis="x"
                        xmin={1}
                        xmax={18}
                        styles={sliderStyles}
                        x={terms}
                        onChange={({ x }) => setTerms(x)}
                    />
                </div>
            </div>
            <div className={styles.checkboxContainer}>
                <span className={styles.label}>Share</span>
                <div className={styles.checkbox}>
                    <Checkbox
                        content={`<span class=${styles.checkboxTitle}>Min 1 Bank account linked</span>`}
                        value={accounts?.length > 1}
                        disabled
                    />
                    <Checkbox
                        content={`<span class=${styles.checkboxTitle}>Min 6 Months Stripe Trading History</span>`}
                        value={isStripeTrandingHistoryExists}
                        disabled
                    />
                    {showWarning && (
                        <div className={styles.warning}>
                            You need to have either trading history with Stripe or have atleast one bank account linked to apply for a loan
                        </div>
                    )}
                </div>
            </div>
            <div>
                <button className='button-stroke-red' onClick={linkExistingBankAccountHandler} style={{ width: 184 }}>
                    {linkLoading ?
                        <Spinner size={24} color="gray" />
                        :
                        "Link bank account"
                    }
                </button>
            </div>
            <div className={styles.linkedAccounts}>
                <span>Linked Accounts</span>
                {loading ?
                    <div className={styles.spinner}>
                        <Spinner size={48} color={'grey'} />
                    </div>
                    :
                    <div className={styles.accounts}>
                        {accounts?.map((x, index) => {
                            return (
                                <Item
                                    onActive={() => setSelectedAccount(x?.linkAccountId)}
                                    className={x?.linkAccountId === selectedAccount ? cn(styles.item, styles.activeItem) : styles.item}
                                    key={index}
                                    item={{
                                        title: x.name,
                                        accountDigit: x?.accountNumberLast4,
                                        counter: x.balance ?? "0",
                                    }}
                                    currency={'USD'}
                                />
                            );
                        })}
                    </div>
                }
            </div>
            {/* <div className={styles.contentContainer}>
                <span className={styles.disclaimer}>
                    Get Funding to grow your business in minutes.
                </span>
                <span className={styles.disclaimer}>
                    <b>Understanding eligibility. </b>
                    Your offers are based on a combination of factors, including your payment volume and history on Stripe. If you also process payments outside of Stripe, you can connect external accounts or move more volume onto Stripe to improve your eligibility.
                </span>
                <span className={styles.disclaimer}>
                    <b>Get funding fast. </b>
                    Connect accounts and see if you're eligible for a Capital offer within minutes. If your application is approved by Stripe, on behalf of Celtic Bank, funds are deposited into your Stripe account as soon as the next business day.
                </span>
                <span className={styles.disclaimer}>
                    <b>Pay one fixed fee. </b>
                    The total amount you owe is the loan amount plus one fixed fee, paid back over time through your sales. There is no compounding interest, early repayment fee, or late fee.
                </span>
                <span className={styles.disclaimer}>
                    <b>Replay automatically. </b>
                    Stripe deducts a percentage of each sale until the total amount owed is repaid. If you don’t reach the minimum payment amount through sales in a given period, Stripe automatically deducts the remainder from your bank account.
                </span>
            </div> */}
        </div>
}

export default Application;