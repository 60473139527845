import React from 'react';
import Routing from './pages/routing';
import "./styles/app.sass";
import { useEffect } from 'react';
import { getCurrentUser, settingUpSentry } from './utils/utils';

function App() {

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser?.userid) {
      settingUpSentry(currentUser);
    }

    if (document) {
      document.addEventListener('keydown', function (event) {
        if (event.key === 'Backspace') {
          let target = event.target;

          let isInputField = target.tagName === 'INPUT' || target.tagName === 'TEXTAREA';
          let isContentEditable = target.isContentEditable;

          if (!isInputField && !isContentEditable) {
            event.preventDefault();
          }
        }
      });
    }
  }, []);

  return (
    <Routing />
  );
}

export default App;
