import React, { useEffect, useState } from "react";
import styles from "./TaxTable.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Icon from "../../../../components/Icon";
import {
  toTitleCase,
  dateFormatter,
  toastConfiguration,
} from "../../../../utils/utils";
import Spinner from "../../../../utils/spinner";
import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import Modal from "../../../../components/Modal";
import TextInput from "../../../../components/TextInput";
import { addTaxRate, updateTaxRate } from "../../../../utils/apiCallHanlder";
import Image from "../../../../components/Image";

const Transactions = ({
  className,
  loading,
  taxRates,
  updateTaxRateListAfterAdding,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [rate, setRate] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [errorString, setErrorString] = useState("");
  const [id, setId] = useState(null);

  const onClose = () => {
    setShowModal(false);
    setName("");
    setRate("");
    setDisplayName("");
    setErrorString("");
    setId(null);
  };

  const saveTaxRate = async () => {
    if (!name) {
      setErrorString("Please enter tax rate display name.");
    } else if (rate < 0) {
      setErrorString("Please enter tax rate greater than 0.");
    } else {
      setSaveLoading(true);
      let response = null;
      if (id) {
        response = await updateTaxRate({
          id: id,
          name: name,
          rate: rate || 0.0,
          displayName: name,
        });
      } else {
        response = await addTaxRate({
          name: name,
          rate: rate || 0.0,
          displayName: name,
        });
      }

      if (response?.data) {
        toast.success(
          `Successfully ${id ? "updated" : "added"} tax rate`,
          toastConfiguration
        );
        setName("");
        setRate("");
        setShowModal(false);
        updateTaxRateListAfterAdding();
      } else {
        setErrorString(
          response?.error || `Error in ${id ? "updating" : "adding"} tax rate`
        );
      }
      setSaveLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        visible={showModal}
        onClose={onClose}
        outerClassName={styles.outer}
        children={
          <Card
            className={cn(styles.card, className)}
            classCardHead={styles.head}
            title={id ? "Update Tax Rate" : "Add Tax Rate"}
            /* classTitle={cn("title-blue", styles.title)} */
          >
            <TextInput
              label='Name'
              placeholder='Internal Tax'
              value={name}
              onChange={(e) => {
                setErrorString("");
                setName(e.target.value);
              }}
            />
            <div style={{ marginTop: 25 }}>
              <TextInput
                label='Display Name'
                placeholder='Internal Tax'
                value={displayName}
                onChange={(e) => {
                  setErrorString("");
                  setDisplayName(e.target.value);
                }}
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <TextInput
                label='Tax Rate (%)'
                placeholder='10.00'
                value={rate}
                onWheel={(e) => e.target.blur()}
                type='number'
                onChange={(e) => {
                  setErrorString("");
                  setRate(e.target.value);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <button
                className={cn("button", styles.addBtn)}
                onClick={saveTaxRate}
                style={{ width: 220 }}
              >
                {saveLoading ? (
                  <Spinner size='24' color='white' />
                ) : (
                  <>
                    <Icon name='save' size='24' />
                    {id ? "Update Tax Rate" : "Add new Tax Rate"}
                  </>
                )}
              </button>
            </div>
            {errorString !== "" ? (
              <div className={styles.errorContainer}>
                <span className={styles.errorMessage}>{errorString}</span>
              </div>
            ) : null}
          </Card>
        }
      />
      <Card
        className={cn(styles.card, className)}
        classCardHead={styles.head}
        title='Tax Rates'
        head={
          <button
            className={cn("button", styles.addBtn)}
            onClick={() => setShowModal(true)}
          >
            Add new Tax Rate
          </button>
        }
        /* classTitle={cn("title-blue", styles.title)} */
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner size='44px' color='gray' loading={loading} />
          </div>
        ) : taxRates?.length < 1 ? (
          <div className={styles.noDataImg}>
            <Image
              src={"/images/icons/empty-black.png"}
              srcDark={"/images/icons/empty-white.png"}
            />
          </div>
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>Name</div>
                <div className={styles.col}>Tax Rate</div>
                <div className={styles.col}>Edit</div>
              </div>
              {taxRates.map((x, index) => (
                <>
                  <div
                    className={styles.row}
                    key={index}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <div className={styles.col}>
                      <div className={styles.label}>Name</div>
                      {x.name}
                    </div>
                    <div className={styles.col}>
                      <div className={styles.label}>Tax Rate</div>
                      {x.rate}
                    </div>
                    <div
                      className={styles.col}
                      onClick={() => {
                        setId(x?.companyTaxId);
                        setRate(x?.rate);
                        setName(x?.name);
                        setDisplayName(x?.displayName);
                        setShowModal(true);
                      }}
                    >
                      <div className={styles.label}>Edit</div>
                      <Icon name='edit' />
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default Transactions;
