import React, { useState } from "react";
import cn from "classnames";
import styles from "./TextModal.module.sass";

const TextModal = ({ className, showPopup, setShowPopup }) => {
    return (
        <div
            className={cn(styles.deleteModal, className, {
                [styles.active]: showPopup,
            })}
        >
            <div className={styles.body}>
                <div style={{ padding: 10 }} className={styles.text}>
                    To add this card to your Apple Wallet, please open Wallet app on your iPhone and scan this card on a computer screen or enter the 16 digit number manually. After this you can use the card at any retail location accepting contactless payments. Please note cards are linked to your bank balance.
                </div>
            </div>
            <div className={styles.overlay} onClick={() => setShowPopup(false)}></div>
        </div>
    );
};

export default TextModal;
