import React, { useEffect } from "react";
import styles from "./NewContract.module.sass";
import { useHistory } from "react-router";
import Icon from "../../../../../components/Icon";
import Card from "../../../../../components/Card";
import CustomerInfoBox from "../../../../../components/CustomerInfoBox";
import { useState } from "react";
import Dropdown from "../../../../../components/COATYPE";
import cn from "classnames";
import { dateFormatter, dateFormatterWithTime, toastConfiguration } from "../../../../../utils/utils";
import { getDropdownTemplates, getSingleContract, resendContract, resolveTemplate, sendContract, updateSignature } from "../../../../../utils/apiCallHanlder";
import Spinner from "../../../../../utils/spinner";
import { toast, ToastContainer } from "react-toastify";
import TextInput from "../../../../../components/TextInput";
import SaveSuccess from "../SaveSuccess";
import Modal from "../../../../../components/Modal";

const templates = ["Rent agreement", "template 2", "template 3"];

const NewContract = ({
  customerId,
  setShowNewContract,
  imageURL,
  name,
  email,
  mobileCode,
  mobile,
  editMode,
  setEditMode,
  templateName,
  createdOn,
  viewedOn,
  signedOn,
  getUpdatedContract,
  setUpdatedContract,
  templateId
}) => {
  const history = useHistory();
  const [template, setTemplate] = useState("choose");
  const [templateArray, setTemplateArray] = useState([]);

  const [resolvedContract, setResolvedContract] = useState(null);
  const [resolvedContractName, setResolvedContractName] = useState(null);

  const [getTemplateLoading, setGetTemplateLoading] = useState(true);
  const [resolveTemplateLoading, setResolveTemplateLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  const [contract, setContract] = useState(null);
  const [showSignatureInput, setShowSignatureInput] = useState(false);
  const [contractSignaturesArray, setContractSignatureArray] = useState([]);
  const [signature, setSignature] = useState("");

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showNewTemplate, setShowNewTemplate] = useState(false);

  const [signatureLoading, setSignatureLoading] = useState("");

  useEffect(async () => {
    setGetTemplateLoading(true);
    const { data } = await getDropdownTemplates({});
    if (data) {
      const firstElement = {
        name: "Choose template",
        id: "choose"
      }
      setTemplateArray([firstElement].concat(data?.templates))
      if (templateId) {
        const contractInfo = await getSingleContract(templateId, {});
        if (contractInfo?.data) {
          const contract = contractInfo?.data?.contract;
          setTemplate(contract?.contractTemplateId);
          setResolvedContract(contract.contractDescription);
          setResolvedContractName(contract?.contractName);
          setContractSignatureArray(contract?.contactSignatures);
          setContract(contract);
          setEditMode(false);
        }
      }
    }
    setGetTemplateLoading(false)
  }, [])

  const templateChangeHandler = async (templateId) => {
    if (templateId === "choose") {
      setResolvedContract("");
      setResolvedContractName("");
    } else {
      setResolveTemplateLoading(true);
      const { data } = await resolveTemplate({
        contractTemplateId: templateId,
        contactId: customerId,
      })
      if (data) {
        setResolvedContract(data?.contract?.contractDescription);
        setResolvedContractName(data?.contract?.contractName);
      } else {
        toast.error("Error in resolving contract", toastConfiguration);
      }
      setResolveTemplateLoading(false);
    }
  }

  const onConfirm = () => {
    if (templateId) {
      if (customerId?.slice(0, 2) === "cu") {
        history.push({
          pathname: `/contacts/${customerId}`,
        });
      } else {
        history.push({
          pathname: `/contacts/${customerId}`,
        });
      }
    }

    setShowNewContract(false);
    setUpdatedContract(!getUpdatedContract);
    setShowSuccessModal(false);
  }

  const sendToContact = async () => {
    setSendLoading(true);
    const { data, error } = templateId ?
      await resendContract(templateId, {}) :
      await sendContract({
        contractTemplateId: template,
        contactId: customerId
      });
    if (data) {
      setShowSuccessModal(true);

    } else {
      toast.error(error || "error in sending contract", toastConfiguration)
    }
    setSendLoading(false);
  }


  const updateSignatureHandler = async () => {
    setSignatureLoading(true);
    const { data } = await updateSignature(templateId, {
      Signature: signature
    })
    if (data) {
      const contract = data?.contract;
      setContractSignatureArray(contract?.contactSignatures);
      setContract(contract);
      setShowSignatureInput(false);
      toast.success("Signature added successfully", toastConfiguration);
    } else {
      toast.error("Error in adding signature", toastConfiguration);
    }
    setSignatureLoading(false);
  }

  return (
    <>
      <Modal
        visible={showNewTemplate}
        onClose={() => { setShowNewTemplate(false) }}
        outerClassName={styles.outer}
      >
        <div className={styles.wrap}>
          <div className={styles.email} style={{ textAlign: 'center' }}>
            Click below button to add new contract template to proceed.
          </div>
          <button onClick={() => {
            history.push({
              pathname: "/studio/4",
              state: { isNewContract: true }
            })
          }} className="button" style={{ marginTop: 25 }}>
            Create new contract
          </button>
        </div>
      </Modal>

      <ToastContainer />
      <div className={styles.row}>
        <div className={styles.col}>
          <Card title={"Contract"} border 
            head={
      <div
        className={cn("button-stroke-red",styles.backBtn)}
        onClick={() => {
          if (customerId?.slice(0, 2) === "cu") {
            history.push({
              pathname: `/contacts/${customerId}/6`,
            });
          } else {
            history.push({
              pathname: `/contacts/${customerId}`,
            });
          }
          setShowNewContract(false);
          setEditMode(true);
        }}
      >
        Cancel
      </div>
            }
          >
            <h2 className={styles.title}>Contact</h2>
            <CustomerInfoBox
              imageUrl={imageURL}
              name={name}
              email={email}
              mobileCode={mobileCode}
              mobile={mobile}
            />
            {editMode ? (
              <>
                {getTemplateLoading ?
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: 16
                  }}>
                    <Spinner size="24" color="gray" />
                  </div>
                  :
                  <Dropdown
                    className={styles.templateDropdown}
                    classDropdownLabel={styles.templateLabel}
                    label={"Template"}
                    value={template}
                    setValue={setTemplate}
                    options={templateArray}
                    comingFromStatement={true}
                    handleChange={templateChangeHandler}
                    loading={resolveTemplateLoading}
                    emptyTemplateListHandler={() => {
                      setShowNewTemplate(true);
                    }}
                  />
                }
                {template && template !== "choose" ? (
                  <button
                    className={cn("button", styles.sendContactButton)}
                    style={{ width: '100%', marginTop: 24 }}
                    onClick={sendToContact}
                  >
                    {sendLoading ?
                      <Spinner size="24" color="white" />
                      :
                      <>
                        <Icon name="rocket" size="24" />
                        Send to contact now
                      </>
                    }
                  </button>
                ) : null}
              </>
            ) : (
              <>
                {getTemplateLoading ?
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: 16
                  }}>
                    <Spinner size="24" color="gray" />
                  </div>
                  :
                  <>
                    <div className={styles.singleData}>
                      <div className={styles.dataTitle}>Template</div>
                      <p>{templateName}</p>
                    </div>
                    <div className={styles.singleData}>
                      <div className={styles.dataTitle}>Sent on</div>
                      <p>{dateFormatter(contract?.createdOn)}</p>
                    </div>
                    <div className={styles.singleData}>
                      <div className={styles.dataTitle}>Link</div>
                      <div className={styles.linkWrap}>
                        <p>{contract?.link}</p>
                        <button onClick={(e) => {
                          navigator?.clipboard?.writeText(contract?.link);
                          toast.success("Link copied successfully", toastConfiguration);
                        }}>
                          <Icon name="multiselect" size="24" />
                        </button>
                      </div>
                    </div>
                    <div className={styles.singleData}>
                      <div className={styles.dataTitle}>Viewed</div>
                      {contract?.viewedOn ? (
                        <p className={styles.success}>{dateFormatter(contract?.viewedOn)}</p>
                      ) : (
                        <p className={styles.danger}>No</p>
                      )}
                    </div>
                    <div className={styles.singleData}>
                      <div className={styles.dataTitle}>Signed</div>
                      {contract?.signedOn ? (
                        <p className={styles.success}>{dateFormatter(contract?.signedOn)}</p>
                      ) : (
                        <p className={styles.danger}>No</p>
                      )}
                    </div>
                    {!contract?.isSignedByCompany && !contract?.isSignedByContact && (
                      <button
                        className={cn("button", styles.sendContactAgainBtn)}
                        style={{ width: '100%' }}
                        onClick={sendToContact}
                      >
                        {sendLoading ?
                          <Spinner size="24" color="white" />
                          :
                          <>
                            <Icon name="rocket" size="24" />
                            Resend to contact now
                          </>
                        }
                      </button>
                    )}
                  </>
                }
              </>
            )}
          </Card>
        </div>
        <div className={styles.col}>
          {template ? (
            <Card className={styles.agreementWrap} border >
              <div className={styles.head}>{resolvedContractName}</div>
              <div
                //className={styles.para}
                dangerouslySetInnerHTML={{ __html: resolvedContract }}
              >
              </div>
              {contractSignaturesArray?.map((item, index) => {
                return <div key={index} style={{ marginBottom: 24, marginTop: 24 }}>
                  <div className={styles.signedWrap}>
                    <h3 className={styles.signTitle}>Signed by: </h3>
                    <p className={styles.signSignature}>{item?.signature}</p>
                    <p className={styles.signName}>{item.signature || item.personName}</p>
                    <p>{dateFormatterWithTime(item?.signedOn)}</p>
                  </div>
                </div>
              })}

              <div className={styles.signedWrap}>
                {showSignatureInput ?
                  <>
                    <TextInput
                      placeHolder="Sign here"
                      className={styles.signature}
                      classLabel={styles.label}
                      onChange={(event) => setSignature(event?.target?.value)}
                      value={signature}
                      required
                    /* close={"close"}
                    onClickClose={() => setShowSignatureInput(false)} */
                    />
                    <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
                      <button className={cn("button", styles.signedBtn)}
                        onClick={updateSignatureHandler}
                        style={{ width: 200 }}
                      >
                        {signatureLoading ? <Spinner size="24" color="white" />
                          :
                          <>
                            <Icon name="edit" size="24" />
                            Sign Contract
                          </>
                        }
                      </button>

                      <button
                        onClick={() => setShowSignatureInput(false)}
                        className={cn("button-stroke-red", styles.button)}
                        style={{ width: 150, marginLeft: 12 }}
                      >
                        Remove Field
                      </button>
                    </div>
                  </>
                  :
                  !contract?.isSignedByCompany && !getTemplateLoading && templateId ?
                    <button className={cn("button", styles.signedBtn)}
                      style={{ marginTop: 16 }}
                      onClick={() => setShowSignatureInput(true)}>
                      <Icon name="edit" size="24" />
                      Sign here
                    </button>
                    :
                    null
                }
              </div>
            </Card>
          )
            : null}

          <SaveSuccess
            showSuccessModal={showSuccessModal}
            setShowSuccessModal={setShowSuccessModal}
            onConfirm={onConfirm}
            name={name}
          />
        </div>
      </div>
    </>
  );
};

export default NewContract;
