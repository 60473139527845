import React, { useEffect, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./multipleDropdown.module.sass";
import Tooltip from "../Tooltip";
import Search from '../../pages/Products/EditNewProduct/Details/SearchCode'
import Spinner from "../../utils/spinner";
import Checkbox from "../Checkbox";
import Icon from "../Icon";

const Dropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  onChange,
  options,
  showSearch,
  taxcodes,
  taxCodeName,
  setTaxCodeName,
  label,
  tooltip,
  invoiceDueLoading,
  loading,
  updateTaxLoading,
  small,
  upBody,
  handleChange,
  selectedCountries,
  setSelectedCountries
}) => {
  const [visible, setVisible] = useState(false);
  const [searchArray, setSearchArray] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [reRender, setRerender] = useState(false);

  if (loading === undefined) {
    loading = false;
  }

  const handleClick = (value) => {
    setValue(value);
    setVisible(false);

    { handleChange && handleChange(value) }
    { onChange && onChange(value) }
  };

  const codeSearchHandler = (event) => {
    setVisible(true);
    const valueToSeacrh = event.target.value;
    //setTaxCodeName(valueToSeacrh);
    setSearchString(valueToSeacrh);
    if (valueToSeacrh) {
      let obj = options.filter(o => o.label.substring(0, valueToSeacrh.length).toLowerCase() === valueToSeacrh.toLowerCase());
      setSearchArray(obj);
    } else {
      setSearchArray([]);
    }

  }

  const checkboxHandler = (event, x) => {
    for (let i = 0; i < options?.length; i++) {
      if (options[i].value === x.value) {
        if (x?.isChecked) {
          options[i].isChecked = false;
          const index = selectedCountries.indexOf(x?.value);
          if (index > -1) {
            selectedCountries.splice(index, 1);
            setSelectedCountries([...selectedCountries])
          }
        } else {
          options[i].isChecked = true;
          setSelectedCountries([...selectedCountries, x.value])
        }
      }
    }

    setRerender(!reRender);
  }


  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        {/* <div
          className={cn(loading || invoiceDueLoading || updateTaxLoading ? styles.headWithoutIcon : styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
        >
          {loading || invoiceDueLoading || updateTaxLoading ?
            <div style={{ position: 'absolute', top: 5, right: 10 }}>
              <Spinner loading={loading || invoiceDueLoading || updateTaxLoading} color={"gray"} size={"20"} />
            </div>
            : null}

          <div className={styles.selection}>
            {options.map((x, index) => {
              if (x.value === value) {
                return x.label;
              }
            })}
          </div>
        </div> */}
        {showSearch && showSearch === "true" ?
          <div className={styles.xHead}>
            <button className={styles.start}>
              <Icon name="search" size="24" />
            </button>
            <input
              onClick={() => setVisible(!visible)}
              className={styles.xInput}
              type="text"
              autoComplete="off"
              value={searchString}
              onChange={codeSearchHandler}
              name="tax category"
              placeholder="Search"
            />
          </div>
          : null}
        {searchArray.length > 0 || searchString !== "" ?
          <div className={cn(styles.body, { [styles.bodyUp]: upBody })}
            style={searchArray.length > 6 ? { height: 200, overflowY: "scroll" } : null}
          >
            {searchArray.map((x, index) => (
              <div
                className={cn(styles.option, {
                  [styles.selectioned]: x.value === value,
                })}
                onClick={() => handleClick(x.value, index)}
                key={index}
              >
                {x.label}
              </div>
            ))}
          </div> :
          <div className={cn(styles.body, { [styles.bodyUp]: upBody })}
            style={options.length > 6 ? { height: 200, overflowY: "scroll" } : null}
          >
            {options.map((x, index) => (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
                <Checkbox
                  value={x.isChecked ? true : false}
                  onChange={(event) => checkboxHandler(event, x)}
                />
                <div
                  className={cn(styles.option, {
                    [styles.selectioned]: x.value === value,
                  })}
                  onClick={() => handleClick(x.value, index)}
                  key={index}
                >
                  {x.label}
                </div>
              </div>
            ))}
          </div>
        }
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
