import React, { useState } from "react";
import cn from "classnames";
import styles from "./DeleteModal.module.sass";
import Icon from "../Icon";
import Image from "../Image";
import Spinner from "../../utils/spinner";
import { toastConfiguration } from "../../utils/utils";

const DeleteModal = ({
  className,
  hideButton,
  btnTxt,
  children,
  deleteBtn,
  btnClass,
  setIsLoading,
  getRecipients,
  icon,
  button,
  visibleDelete,
  setVisibleDelete,
  id,
  setShowMainContainer,
  deleteFunction,
  setValue,
  toast,
  setFetchNewRecords,
  fetchNewRecords,
  onConfirm,
  onClickHandler,
  title,
  subTitle,
  confirmationButtonText,
  imgSrc
}) => {
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <div
      className={cn(styles.deleteModal, className, {
        [styles.active]: visibleDelete ? visibleDelete : visible,
      })}
    >
      {button ? button :
        <button
          className={cn(btnClass || "button-stroke-red", styles.head)}
          onClick={() => setVisibleDelete ? setVisibleDelete(true) : setVisible(true)}
        >
          {icon && icon}
          {btnTxt || "Delete"}
        </button>
      }
      <div className={styles.body}>
        <div className={styles.top}>
          <button
            className={cn("close", styles.close)}
            onClick={() => setVisibleDelete ? setVisibleDelete(false) : setVisible(false)}
          >
            <Icon name="close" size="20" />
          </button>
        </div>
        <div className={styles.wrapper}>
          {imgSrc ?
            <Image
              className={styles.pic}
              src={imgSrc}
              srcDark={imgSrc}
              alt="delete"
            />
            :
            <div className={styles.imgWrapper}>
              <Image
                className={styles.pic}
                src={"/images/content/close.png"}
                srcDark="/images/content/close.png"
                alt="delete"
              />
            </div>
          }
          <div className={styles.title}>{title || "Are You Sure?"}</div>
          <div className={styles.para}>{subTitle || "This action can't be undone..."}</div>
          {deleteBtn || (
            <button
              className="button"
              style={{ width: 150, height: 50 }}
              onClick={async () => {
                setLoading(true);
                if (onClickHandler) {
                  await onClickHandler(id);
                  setVisible && setVisible(false);
                  setLoading(false);
                  return;
                }

                const deleteResponse = await deleteFunction({ id });
                const { data } = deleteResponse;
                if (data) {
                  onConfirm && await onConfirm(data);
                  setVisibleDelete ? setVisibleDelete(false) : setVisible(false);
                  setShowMainContainer && setShowMainContainer(setValue);
                  setIsLoading && setIsLoading(true);
                  getRecipients && await getRecipients("");
                  setIsLoading && setIsLoading(false);
                  toast && toast.success("Payment Link deactivated successfully", toastConfiguration);
                  setFetchNewRecords && setFetchNewRecords(!fetchNewRecords);
                } else {
                  setError(deleteResponse.error);
                }
                setLoading(false);
              }}
            >
              {loading ? (
                <Spinner loading={loading} size={30} />
              ) : (
                confirmationButtonText ? confirmationButtonText : "Yes I'm sure!"
              )}
            </button>
          )}
          {error !== "" ? (
            <div className={styles.errorContainer}>
              <span className={styles.errorMessage}>{error}</span>
            </div>
          ) : null}
        </div>
        {children}
      </div>
      <div className={styles.overlay} onClick={() => setVisibleDelete ? setVisibleDelete(false) : setVisible(false)}></div>
    </div>
  );
};

export default DeleteModal;