import React, { useState } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";
import { signInUser } from "../../../utils/apiCallHanlder";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { SetAlreadyLaunchedIntercom, handleLoginSignUpResponse } from "../../../utils/utils";
import Spinner from '../../../utils/spinner';

const Entry = ({ onConfirm, istestmode }) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [valid, setValid] = useState(null);
  const [username, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const emailHandleChange = (event) => {
    setErrorMessage("");
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else if (isValidEmail(event.target.value)) {
      setEmail(event.target.value);
      setError(null);
      setValid("Valid");
    } else {
      setError(null);
      setValid(null);
    }

    setMessage(event.target.value);
  };

  const passwordHandleChange = (event) => {
    setErrorMessage("");
    setPassword(event?.target?.value);
  }



  const loginClickHandler = async () => {
    const deviceId = "2";
    if (username === "" || password === "") {
      setErrorMessage("Please Fill out all fields");
    } else if (error === "Email is invalid") {
      setErrorMessage("Please Provide Valid Email");
    } else {
      setLoading(true);
      const signInResponse = await signInUser({ username, password, deviceId });
      const { data, message } = signInResponse;
      if (data) {
        if (data.nextAction && (data.nextAction.nextAction == "signup_verifyemail")) {
          let nextActionStr = data.nextAction.nextAction;
          let signUpToken = data.nextAction.signUpToken;
          history.push({
            pathname: istestmode ? '/sign-up-test' : '/sign-up',
            state: { nextAction: nextActionStr, email: username, signUpToken }
          });
        } else if (data.nextAction.nextAction == "signup_details") {
          history.push({
            pathname: "/sign-up-details",
            state: {
              token: data?.nextAction?.myNewToken,
              email: data?.nextAction?.email
            },
          });
        } else {
          localStorage.setItem("connectStatus", JSON.stringify(data?.connectStatus));
          localStorage.setItem("stripeConnection", data.isConnected);
          localStorage.setItem("isPending", data.isPending);
          localStorage.setItem("isTestMode", data.istestmode);
          localStorage.setItem("currencySymbol", data.currencySymbol || "$");
          localStorage.setItem("currency", data.currency || 'USD');
          localStorage.setItem("hasIntercomUserId", data?.company?.hasIntercomUserId);
          SetAlreadyLaunchedIntercom(false);

          handleLoginSignUpResponse(data, history);
        }
      } else {
        setErrorMessage(signInResponse.error);
      }
      setLoading(false);
    }
  }

  const loginWithGmail = () => {

    history.push({
      pathname: `/sign-in-google`,
    });

  }

  return (
    <>
      <div className={styles.entry}>
        <div className={styles.body}>
          <div className={styles.externalLogin}>
            <div
              className={cn(
                "button",
                styles.button
              )}
              onClick={loginWithGmail}
            >
              <button className={cn(styles.link, styles.google)} >
                Sign in with Google
              </button>
            </div>
          </div>

          {/* <div className={styles.divider}>
            OR
          </div> */}
          <div
            className={styles.terms}
            style={{ marginBottom: 32, marginTop: 32 }}
          >
            <div className={styles.termsText}>
              <span> Or continue with email </span>
            </div>
          </div>

          <div className={styles.emailWrapper}>
            <TextInput
              className={error ? styles.hasError : styles.field}
              name="email"
              type="email"
              placeholder="Your email"
              required
              icon={"mail"}
              value={message}
              onKeyPress={(e) => {
                if (e.which === 13) {
                  loginClickHandler();
                }
              }
              }
              onChange={emailHandleChange}
            />
            {error ? (
              <Icon name="warning" className={styles.warningIcon} size="24" />
            ) : valid ? (
              <Icon name="check" className={styles.validIcon} size="24" />
            ) : (
              ""
            )}
          </div>
          <TextInput
            className={styles.field}
            name="password"
            type="password"
            placeholder="Password"
            onChange={passwordHandleChange}
            required
            icon="lock"
            onKeyPress={(e) => {
              if (e.which === 13) {
                loginClickHandler();
              }
            }
            }
          />
          <div
            className={cn(
              "button",
              error ? styles.disable : valid ? styles.button : styles.disable
            )}
            onClick={loginClickHandler}
          // onClick={valid && onConfirm}
          >
            {valid ? <button className={styles.link} >
              {
                loading ?
                  <Spinner loading={loading} size={30} />
                  : "Login"}
            </button> : "Login"}
          </div>

          {errorMessage !== "" ? <div className={styles.errorContainer}><span className={styles.errorMessage}>{errorMessage}</span></div> : null}

          <div className={styles.forgot}>
            <Link className={styles.link} to="/forgot-password">
              Forgot Password
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Entry;
