import React from 'react';
import Card from '../../../../../components/Card';
import styles from './PaymentMethod.module.sass'
import Tooltip from '../../../../../components/Tooltip';
import Spinner from '../../../../../utils/spinner';
import Notifications from '../Notifications';
import cn from 'classnames'

const PaymentMethod = ({
  startLoading,
  settings,
  setErrorMessage,
  cardPaymentArray,
  setCardPaymentArray,
  isLoading,
  bankAccountsRight
}) => {
  return (
    <Card
      /* classTitle="title-secondary-03" */
      title={
        <>
          <span>Payment Method</span>
          {/* <Tooltip
            className={styles.tooltip}
            title='Your account can have many different payment methods. By default we have activated Card and ACH Direct Debits on your account and you send us a message if you wish to activate more payment types. When you create a payment you can switch payment "on" or "off" for that specific payment when creating and invoice or subscription.'
            icon="info"
            //place="right"
          /> */}
        </>
      }
      className={styles.card}
      classCardHead={styles.cardHead}
    >
      <div className={cn(styles.row, styles.rowHead)}>
        <div
          style={{
            width: "25%",
            paddingRight: "2.5%",
            color: '#6f767e',
            fontSize: 15,
            fontWeight: 500,
            lineHeight: 1.6
          }}>Your Payment types</div>
        <div style={{
          width: "75%",
          color: '#6f767e',
          fontSize: 15,
          fontWeight: 600,
          lineHeight: 1.6
        }}>Description</div>
      </div>
      <div style={
        bankAccountsRight === 2 ? { pointerEvents: 'none', marginTop: 20 } : { marginTop: 20 }
      }>
        <div className={styles.list}>
          {startLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 150,
              }}
            >
              <Spinner loading={startLoading} size="50" color={"gray"} />
            </div>
          ) : (
            settings.map((x, index) => (
              <div key={index}>
                <Notifications
                  x={x}
                  setErrorMessage={setErrorMessage}
                  settings={settings}
                  cardPaymentArray={cardPaymentArray}
                  setCardPaymentArray={setCardPaymentArray}
                  showBanks={true}
                  isLoading={isLoading}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </Card>
  );
};

export default PaymentMethod;