import React, { useState, useEffect } from "react";
import styles from "./Billing.module.sass";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Icon from "../../../../components/Icon";
import cn from "classnames";
import {
  getBaseURLOfCurrentApp,
  getCurrencyFromLocalStorage,
} from "../../../../utils/utils";
import {
  createCustomerPortalUrl,
  getCurrentSubscriptionState,
} from "../../../../utils/apiCallHanlder";
import { dateFormatter } from "../../../../utils/utils";
import AddOns from "./AddOns";

const Billing = ({
  isFeatureChanged,
  setIsFeatureChanged
}) => {
  const manageBilling = async () => {
    let returnUrl = getBaseURLOfCurrentApp() + "/settings/Billing";
    const { data } = await createCustomerPortalUrl(returnUrl);
    if (data && data.customerPortalUrl) {
      window.location = data.customerPortalUrl;
    }
  };

  return (
    <>
      <button
        className={cn("button", styles.updateBtn)}
        type="button"
        style={{
          marginBottom: 20,
        }} onClick={manageBilling}
      >
        <Icon name="external_link" size="20" viewBox="0 0 24 24" />
        See & Update your billing details
      </button>
      <AddOns
        isFeatureChanged={isFeatureChanged}
        setIsFeatureChanged={setIsFeatureChanged}
      />
      <TooltipGlodal />
    </>
  );
};

export default Billing;
