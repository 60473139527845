import React from "react";
import SubscriptionsOverview from "./SubscriptionsOverview";
import NewSubscriptions from "./NewSubscriptions";

const Subscriptions = ({ activeIndex, subId, currentPage, customerId }) => {
  return (
    <>
      {subId ?
        <NewSubscriptions id={subId === "add" ? null : subId} customerId={customerId} />
        :
        <SubscriptionsOverview activeIndex={activeIndex} currentPage={currentPage} />
      }
    </>
  );
};

export default Subscriptions;
