import React, { useReducer, useState, useEffect } from "react";
import styles from "./SendNewEmail.module.sass";
import Modal from "../../../../components/Modal";
import TextInput from "../../../../components/TextInput";
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";
import Spinner from "../../../../utils/spinner";
import Dropdown from "../../../../components/Dropdown";
import { toast } from "react-toastify";
import {
  getAllEmailsStudio,
  sendContactEmail,
} from "../../../../utils/apiCallHanlder";
import { toastConfiguration } from "../../../../utils/utils";
import { useHistory } from "react-router";

const eTemplates = ["Choose", "Option 1", "Option 2"];

const SendNewEmail = ({
  visible,
  setVisible,
  customerId,
  toMail,
  mailSubject,
  replyClose,
  getEmails,
  setEmails
}) => {
  const [addEmailLoading, setAddEmailLoading] = useState(false);
  const [addLink, setAddLink] = useState(false);
  const [eTemplate, setETemplate] = useState("Choose");
  const [value, setValue] = useState("");
  const [toEmail, setToEmail] = useState(toMail || "");
  const [subject, setSubject] = useState(mailSubject || "");
  const [body, setBody] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [isNextPageExists, setIsNextPageExists] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const [showNewTemplate, setShowNewTemplate] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setSubject(mailSubject || "");
    setToEmail(toMail || "");

    if (!visible) {
      setBody("");
      setSelectedTemplate({});
      setETemplate("Choose");
      const trixEditor = document.querySelector("trix-editor");
      if (trixEditor) trixEditor.innerHTML = "";
    }
  }, [visible]);

  const handleTrixInputChange = (e, newValue) => {
    setValue(newValue);
  };

  useEffect(async () => {
    await getEmailFunction();
  }, []);

  const getEmailFunction = async (currentPage) => {
    setAddEmailLoading(true);
    const { data } = await getAllEmailsStudio({
      pageSize: 30,
      page: currentPage,
    });
    if (data) {
      setIsNextPageExists(data?.isNextPageExist);
      setEmailTemplates([{ name: "Choose" }, ...data?.emailTemplates]);
    }
    setAddEmailLoading(false);
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    if (!toEmail || toEmail == "") {
      toast.error("To email is required", toastConfiguration);
    } else {
      setAddEmailLoading(true);
      try {
        const { data, error } = await sendContactEmail({
          Subject: subject,
          countryPartyId: customerId,
          Body: value,
          To: toEmail,
          Cc: "",
        });
        if (data) {
          toast.success("Successfully Sent Email", toastConfiguration);
        } else {
          toast.error(error || "Error in sending email", toastConfiguration);
        }
        setSelectedTemplate({});
        setVisible(false);
        setAddEmailLoading(false);
        setETemplate("Choose");
        setEmails && setEmails([]);
        getEmails && getEmails(false);
        if (replyClose) replyClose();
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    const selected = emailTemplates.find(
      (template) => template.name === eTemplate
    );
    setSelectedTemplate(selected || {});
    if (selected) {
      setSubject(selected.subject);
    }
  }, [eTemplate, emailTemplates]);

  const handleChange = (event) => {
    if (event !== "Choose") {
      setETemplate(event);
      const trixEditor = document.querySelector("trix-editor");

      const selected = emailTemplates.find(
        (template) => template.name === event
      );
      if (selected?.body && trixEditor) trixEditor.innerHTML = selected?.body;
    } else {
      const trixEditor = document.querySelector("trix-editor");
      trixEditor.innerHTML = "";
      setSubject("");
    }
  };

  const toolbarActions = [
    "bold",
    "italic",
    "strike",
    "link",
    "heading1",
    "quote",
    "bullet",
    "number",
    "centered",
    "undo",
    "redo",
  ];
  const onCloseHandler = () => {
    if (!showNewTemplate) {
      setVisible(false);
      if (replyClose) replyClose();
    }
  };
  return (
    <>
      <Modal
        visible={showNewTemplate}
        onClose={() => {
          setShowNewTemplate(false);
        }}
        outerClassName={styles.outer}
        modalClass={styles.modal}
      >
        <div className={styles.wrap}>
          <div className={styles.email} style={{ textAlign: "center" }}>
            Click below button to add new email template to proceed.
          </div>
          <button
            onClick={() => {
              history.push({
                pathname: "/studio/2/add",
              });
            }}
            className='button'
            style={{ marginTop: 25 }}
          >
            Add new template
          </button>
        </div>
      </Modal>
      <Modal
        visible={visible}
        outerClassName={styles.outerWrap}
        onClose={onCloseHandler}
        children={
          <Card
            /* classTitle="title-blue" */
            title='Send new Email'
            className={styles.card}
          >
            <form className={styles.formWrap} onSubmit={sendEmail}>
              <TextInput
                className={styles.input}
                label='To'
                name='emailName'
                id='emailName'
                type='text'
                value={toEmail}
                onChange={(e) => setToEmail(e.target.value)}
                required
              />

              <TextInput
                className={styles.input}
                label='Subject'
                name='subject'
                id='subject'
                type='text'
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
              <div className={styles.templateLinkWrap}>
                <Dropdown
                  value={eTemplate}
                  setValue={setETemplate}
                  options={emailTemplates?.map((res) => res.name) || eTemplates}
                  onChange={handleChange}
                  width={"100%"}
                  noOptionsHandler={() => {
                    setShowNewTemplate(true);
                  }}
                />
              </div>
              <div className={styles.detailsWrap}>
                <h3 className={styles.title}>Message</h3>
                <ReactTrixRTEToolbar
                  toolbarActions={toolbarActions}
                  toolbarId='react-trix-rte-editor'
                />
                <ReactTrixRTEInput
                  toolbarId='react-trix-rte-editor'
                  onChange={handleTrixInputChange}
                  defaultValue={selectedTemplate.body}
                  placeholder={"Enter Content"}
                />
              </div>

              <div className={styles.newEmailBtn}>
                <button className='button' style={{ width: 150 }}>
                  {addEmailLoading ? (
                    <Spinner size='24' color='white' />
                  ) : (
                    <>
                      <Icon name='mail' size='20' />
                      Send
                    </>
                  )}
                </button>
              </div>
            </form>
          </Card>
        }
      />
    </>
  );
};

export default SendNewEmail;
