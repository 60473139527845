/* global Stripe */
import React from "react";
import styles from "./Accounting.module.sass";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import { useState, useEffect } from "react";
import {
  GetDashboardNextPage,
  createLinkAccountSession,
  getCardDetails,
  getExcelReportOfBalanceSheet,
  getExcelReportOfProfitLoss,
  getTaxRate,
  lookupData,
} from "../../utils/apiCallHanlder";
import { GetDashboardInitialData } from "../../utils/apiCallHanlder";
import cn from "classnames";
import Overview from "./Overview";
import { isUserLoggedIn, prepareAccountForOverview, toastConfiguration, transactionDateFormatter } from "../../utils/utils";
import ProfitAndLoss from "./ProfitAndLoss";
import BalanceSheet from "./BalanceSheet";
import ChartOfAccounts from "./ChartOfAccounts";
import AccountDetails from "./AccountDetails";
import Documents from "./Documents";
import Reconcile from "./Reconcile";
import Advanced from "./Advanced";
import { useHistory, useParams } from "react-router";
import LargeNav from "../../components/LargeNav";
import AddNewTransactions from "../Transactions/AddNewTransactions";
import Modal from "../../components/Modal";
import { toast } from "react-toastify";
import Spinner from "../../utils/spinner";
import StatementPoupup from "../../components/StatementPopup";

const mainTab = ["Reconcile", "Booked", "Reports", "Categories"];
const reportsTab = [
  "Overview",
  "Profit & Loss",
  "Balance Sheet",
  /* "Tax Reports", */
];
const dropdownOptions = [
  "Action",
  "Statement",
  "Export",
  "Link a Bank Account",
  "See & edit account details",
  "Add transaction",
]

const todaysDate = new Date();

const Accounting = (props) => {
  const { coaId, tabName } = useParams();

  const [mobileTab, setMobileTab] = useState(mainTab[0]);
  const [reportsMobileTab, setReportsMobileTab] = useState(reportsTab[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeReportsIndex, setActiveReportsIndex] = useState(1);
  const [accounts, setAccounts] = useState([]);
  const [graph, setGraph] = useState([]);
  const [income, setIncome] = useState("");
  const [expense, setExpense] = useState("");
  const [profit, setProfit] = useState("");
  const [taxes, setTaxes] = useState("");
  const [visible, setVisible] = useState(true);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [selectedAccountId, setSelectAccountId] = useState(null);
  const [showAddNewTransactions, setShowAddNewTransactions] = useState(false);

  const [visibleStatement, setVisibleStatement] = useState(false);
  const [visibleExportPopup, setVisibleExportPopup] = useState(false);
  const [bookedActiveIndex, setBookedActiveIndex] = useState(1);
  const [visibleExportCOAPopup, setVisibleExportCOAPopup] = useState(false);

  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
  const [endDate, setEndDate] = useState(todaysDate);

  const [refetchDetails, setRefetchDetails] = useState(false);
  const [overviewCashOnHandAccounts, setCashOnHandOverviewAccounts] = useState([])

  const [linkLoading, setLinkLoading] = useState(false);
  const [cashOnHandGraph, setCashOnHandGraph] = useState([])
  const [cashOnHandTransactions, setCashOnHandTransactions] = useState([]);
  const [cashOnHandDefCurrency, setCashOnHandDefCurrency] = useState("")
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (props?.value === undefined && visible && activeIndex === 1 && activeReportsIndex === 1) {
      loadInitialData();
    }
  }, [props.value, visible, activeIndex, activeReportsIndex])

  useEffect(() => {
    setActiveIndex(
      tabName === "COA" && coaId
        ? 5
        : tabName === "Advanced"
          ? 4
          : tabName === "COA"
            ? 2
            : tabName === "Documents"
              ? 3
              : tabName === "Reports"
                ? 1
                : 0
    );
    setVisible(
      tabName === "COA" && coaId ? false : tabName === "COA" ? true : true
    );
  }, [tabName, coaId]);

  useEffect(() => {
    if (coaId) {
      setActiveIndex(3);
      setVisible(false);
    }
  }, [coaId]);

  const linkExistingBankAccountHandler = async () => {
    setLinkLoading(true);
    const { data } = await createLinkAccountSession();

    if (data) {
      var stripe = Stripe(data?.stripe_pk, {
        stripeAccount: data.stripeAccountId
      });
      stripe.collectFinancialConnectionsAccounts({
        clientSecret: data?.session?.clientSecret
      })
        .then(async function (result) {
          //result.financialConnectionsSession.accounts[0].id
          if (result.error) {
            setLinkLoading(false);
            // Inform the customer that there was an error.
            toast.error(result.error.message || "Failed to add linked account", toastConfiguration);
            // Handle next step based on length of accounts array
          } else if (result.financialConnectionsSession.accounts.length === 0) {
            setLinkLoading(false);
            toast.error("No accounts were linked", toastConfiguration);
          } else {
            history.go(0);
            setLinkLoading(false)
          }
        }).catch(() => {
          setLinkLoading(false);
        })
    }
  }

  const exportProfitLossReportHandler = async (type) => {
    setLinkLoading(true);
    const { data, error } = await getExcelReportOfProfitLoss(type, {
      fromDate: transactionDateFormatter(startDate),
      toDate: transactionDateFormatter(endDate)
    });
    if (data) {
      const link = document.createElement("a");
      link.download = "profit";
      link.href = data?.httpPath;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error(error || `Error in exporting ${type} file`, toastConfiguration);
    }

    setLinkLoading(null);
  }

  const balanceSheetExportHandler = async (type) => {
    setLinkLoading(true);
    const { data, error } = await getExcelReportOfBalanceSheet(type, {
      fromDate: transactionDateFormatter(startDate),
      toDate: transactionDateFormatter(endDate)
    });
    if (data) {
      const link = document.createElement("a");
      link.download = "balancesheet";
      link.href = data?.httpPath;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error(error || `Error in exporting ${type} file`, toastConfiguration);
    }

    setLinkLoading(false);
  }

  const initialLoadTransactions = async (
    selectedAccountId,
    startDate,
    endDate
  ) => {
    const { data } = await GetDashboardNextPage({
      SelectedAccountId: selectedAccountId,
      FromDate: transactionDateFormatter(startDate),
      ToDate: transactionDateFormatter(endDate),
      NextPageInfo: null,
    });

    if (data) {
      setCashOnHandTransactions(data.transactions);
    }
  };

  const loadInitialData = async () => {
    setLoading(true)
    const { data } = await GetDashboardInitialData({
      FromDate: transactionDateFormatter(new Date()),
      ToDate: transactionDateFormatter(new Date()),
      isFirstLoad: true,
    });

    if (data) {
      setCashOnHandOverviewAccounts(
        prepareAccountForOverview(data?.accounts, data.defaultAccountCurrency)
      );
      setCashOnHandTransactions(data.transactions);
      setCashOnHandDefCurrency(data.defaultAccountCurrency);
    };
    setLoading(false)
  }

  const onCashOnHandDateRangeChanged = (startdt, enddt, acc) => {
    initialLoadTransactions(acc, startdt, enddt);
  };


  return (
    <>
      <StatementPoupup
        visible={visibleStatement}
        setVisible={setVisibleStatement}
        currentlySelectedAccountId={selectedAccountId}
        overflow={"hidden"}
        accounts={accounts}
      />

      <Modal
        visible={showAddNewTransactions}
        onClose={() => { setShowAddNewTransactions(false) }}
        children={<AddNewTransactions
          setShowAddNewTransactions={setShowAddNewTransactions}
          getAccountSwitchTransactions={(isBooked) => {
            if (isBooked) {
              setBookedActiveIndex(2);
              history.push("/accounting/Reconcile")
            } else {
              setBookedActiveIndex(1);
              history.push("/accounting/Reconcile")
            }
            if ((isBooked && bookedActiveIndex === 2) || (!isBooked && bookedActiveIndex === 1)) {
              setTimeout(() => setRefetchDetails(!refetchDetails), 2000);
            }
          }}
        />}
        outerClassName={styles.outer}
      />
      <div className={styles.accounting}>
        {props?.value === "Accounting" && (
          <Overview
            className={styles.cards}
            accounts={accounts}
            graph={graph}
            setIncome={setIncome}
            setExpense={setExpense}
            setProfit={setProfit}
            setTaxes={setTaxes}
            setGraph={setGraph}
            income={income}
            expense={expense}
            profit={profit}
            taxes={taxes}
            isDashboardView={true}
            showOverviewOnly={props?.showOverviewOnly}
            reportsData={props?.reportsData}
            loadingForDetailData={props.loadingForDetailData}
            overviewAccounts={props.overviewAccounts}
            transactions={props.transactions}
            cashOnHandGraph={props.cashOnHandGraph}
            setCashOnHandGraph={props.setCashOnHandGraph}
            onDateRangeChanged={props.onDateRangeChanged}
            defCurrencyCode={props.defCurrencyCode}
          />
        )}
        {props?.value === undefined && visible ? (
          <Card
            title={"Accounting"}
            border
            /* classTitle={cn("title-blue", styles.title)} */
            head={
              <div
                className={styles.buttonContainer}
              >
                <Dropdown
                  className={cn(styles.actionDropdown, "button")}
                  classDropdownHead={styles.actionDropdownHead}
                  value={"Action"}
                  setValue={() => { }}
                  options={(
                    (activeIndex === 0 || activeIndex === 2) ||
                    (activeIndex === 1 && activeReportsIndex === 2) ||
                    (activeIndex === 1 && activeReportsIndex === 3)) ?
                    dropdownOptions : dropdownOptions.filter((_, index) => index !== 2)
                  }

                  statementClickHandler={() => setVisibleStatement(true)}
                  exportButtonClickHandler={() => {
                    if (activeIndex === 0) setVisibleExportPopup(true);
                    if (activeIndex === 1 && activeReportsIndex === 2) exportProfitLossReportHandler('excel');
                    if (activeIndex === 1 && activeReportsIndex === 3) balanceSheetExportHandler('excel');
                    if (activeIndex === 2) setVisibleExportCOAPopup(true);
                  }}
                  addTransactionClickHandler={() => setShowAddNewTransactions(true)}
                  paysomeOneClickHandler={() => { linkExistingBankAccountHandler() }}
                  seeAndEditClickHanlder={() => {
                    if (selectedAccountId !== 0) {
                      localStorage.setItem(
                        "currentlySelectedAccountId",
                        selectedAccountId
                      );
                    }

                    history.push("/settings/Accounts");
                  }}
                  width={280}
                  showIcon={true}
                />
                {linkLoading && <Spinner size="24" color="gray" />}
                {activeIndex === 3 && showDeleteButton ? (
                  <button className={"button-stroke-red"}>Delete Marked</button>
                ) : null}
              </div>
            }
            className={cn({[styles.accCard] : (activeReportsIndex === 1 && activeIndex === 1)})}
          >
            {/* <div className={styles.mobileNav}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={mobileTab}
              setValue={setMobileTab}
              options={mainTab}
              handleChangeIndex={(index) => {
                setActiveIndex(index + 1);
              }}
              small
            />
          </div> */}
            <LargeNav
              className={styles.typeNav}
              tabOptions={mainTab}
              activeTab={(bookedActiveIndex === 2) ? 1 : activeIndex === 0 ? 0 : (activeIndex + 1)}
              onClick={(x, index) => {
                if (index === 1) {
                  history.push("/accounting/Reconcile");
                  setBookedActiveIndex(2);
                  return;
                }

                setBookedActiveIndex(1);
                if (index === 3) {
                  history.push("/accounting/COA")
                  setActiveIndex(2);
                  return;
                }
                history.push(
                  index === 0
                    ? "/accounting/Reconcile"
                    : index === 2
                      ? "/accounting/Reports"
                      : "/"
                );
                setMobileTab(mainTab[index]);
              }}
            />
            {activeIndex === 0 && bookedActiveIndex !== 2 ? <Reconcile
              accounts={accounts}
              visibleExportPopup={visibleExportPopup}
              setVisibleExportPopup={setVisibleExportPopup}
              setAccounts={setAccounts}
              selectedAccountId={selectedAccountId}
              setSelectAccountId={setSelectAccountId}
              activeIndex={bookedActiveIndex}
              setActiveIndex={setBookedActiveIndex}
              refetchDetails={refetchDetails}
            /> : null}
            {activeIndex === 0 && bookedActiveIndex === 2 ? <Reconcile
              accounts={accounts}
              visibleExportPopup={visibleExportPopup}
              setVisibleExportPopup={setVisibleExportPopup}
              setAccounts={setAccounts}
              selectedAccountId={selectedAccountId}
              setSelectAccountId={setSelectAccountId}
              activeIndex={bookedActiveIndex}
              setActiveIndex={setBookedActiveIndex}
              refetchDetails={refetchDetails}
            /> : null}
            {activeIndex === 1 ? (
              <>
                {/* <div className={styles.typeNav}>
                  {reportsTab.map((x, index) => (
                    <div
                      className={cn(styles.item, {
                        [styles.active]: index + 1 === activeReportsIndex,
                      })}
                      key={index}
                      onClick={() => {
                        setActiveReportsIndex(index + 1);
                        setReportsMobileTab(reportsTab[index]);
                      }}
                    >
                      {x}
                    </div>
                  ))}
                </div> */}
                {/* <div className={cn(styles.mobileNav, styles.subNav)}> */}
                <LargeNav
                  className={styles.typeNav}
                  tabOptions={reportsTab}
                  activeTab={
                    reportsMobileTab === "Overview" ? 0 :
                      reportsMobileTab === "Profit & Loss" ? 1 :
                        reportsMobileTab === "Balance Sheet" ? 2 :
                          3
                  }
                  onClick={(x, index) => {
                    setReportsMobileTab(x);
                    setActiveReportsIndex(index + 1);
                  }}
                />
                {/* <Dropdown
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={reportsMobileTab}
                  setValue={setReportsMobileTab}
                  options={reportsTab}
                  handleChangeIndex={(index) => {
                    setActiveReportsIndex(index + 1);
                  }}
                  small
                /> */}
                {/* </div> */}
                {activeReportsIndex === 2 ? (
                  <>
                    <ProfitAndLoss
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </>
                ) : null}
                {activeReportsIndex === 3 ? (
                  <>
                    <BalanceSheet />
                  </>
                ) : null}
              </>
            ) : null}
            {activeIndex === 2 ? (
              <ChartOfAccounts
                setVisible={setVisible}
                coaId={coaId}
                visibleExportCOAPopup={visibleExportCOAPopup}
                setVisibleExportCOAPopup={setVisibleExportCOAPopup}
              />
            ) : null}
            {activeIndex === 3 ? (
              <Documents
                setVisible={setVisible}
                setShowDeleteButton={setShowDeleteButton}
              />
            ) : null}
            {activeIndex === 4 ? <Advanced setVisible={setVisible} /> : null}
          </Card>
        ) : (
          props?.value === undefined && (
            <AccountDetails setVisible={setVisible} coaId={coaId} />
          )
        )}

        {props?.value === undefined && visible && activeIndex === 1 && activeReportsIndex === 1 ? (
          <>
            <Overview
              className={styles.card}
              accounts={accounts}
              graph={graph}
              setIncome={setIncome}
              setExpense={setExpense}
              setProfit={setProfit}
              setTaxes={setTaxes}
              setGraph={setGraph}
              income={income}
              expense={expense}
              profit={profit}
              taxes={taxes}
              loadingForDetailData={loading}
              overviewAccounts={overviewCashOnHandAccounts}
              transactions={cashOnHandTransactions}
              cashOnHandGraph={cashOnHandGraph}
              setCashOnHandGraph={setCashOnHandGraph}
              onDateRangeChanged={onCashOnHandDateRangeChanged}
              defCurrencyCode={cashOnHandDefCurrency}
            />
          </>
        ) : null}
      </div>
    </>
  );
};

export default Accounting;
