import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "../Overview.module.sass";
import Card from "../../../../components/Card";
import LargeNav from "../../../../components/LargeNav";
import CircleChart from "../CircleChart";
import { getRandomColor } from "../../../../utils/utils";
import List from "../List";


const filters = ["Chart", "List"]
const dateFilters = ["All Time", "This Quarter", "Previous Quarter", "This Year", "Previous Year"]
const dbValues = {
    "All Time": "amount",
    "This Quarter": "amountThisQuarter",
    "Previous Quarter": "amountPrevQuarter",
    "This Year": "amountThisYear",
    "Previous Year": "amountPrevYear"
}
const colors = ["#FF4900", "#E9E7E0", "#FFA96B", "#FFB7C3"]

const Expense = ({
    className,
    data,
    categoryData,
    isDashbord
}) => {
    const [activeFilterExpense, setActiveFilterExpense] = useState(0);
    const [expenseWho, setExpenseWho] = useState([]);
    const [expenseCategory, setExpenseCategory] = useState([]);
    const [expenseFilter, setExpenseFilter] = useState(3);

    useEffect(() => {
        if (data && categoryData) {
            const fieldName = dbValues[dateFilters[expenseFilter]];
            let yearsData = [];
            let yearsCategoryData = [];

            Object.keys(data)?.map(item => {
                yearsData = [...yearsData, ...(data[item] || [])];
            });

            Object.keys(categoryData)?.map(item => {
                yearsCategoryData = [...yearsCategoryData, ...(categoryData[item] || [])];
            });

            const calculatedExpenseWho = valueSetter(yearsData, fieldName, "expense");
            const calculatedExpenseCategory = valueSetter(yearsCategoryData, fieldName, "expense");

            setExpenseWho(calculatedExpenseWho);
            setExpenseCategory(calculatedExpenseCategory);
        }
    }, [data, categoryData, expenseFilter])

    const valueSetter = (data, fieldName, condition) => {
        let finalArray = [];

        data?.map((item) => {
            item.amountValue = item[fieldName];
            if (item?.amountValue < 0) {
                item.amountValue = -item.amountValue;
                item.isNegative = true;
            }

            if (item?.type === condition) {
                finalArray.push(item);
            }
        })

        finalArray.map((item, index) => item.fill = colors[index] ? colors[index] : getRandomColor())
        return finalArray;
    }

    return (
        <div className={styles.container}>
            {/* Expense Year */}
            <Card
                className={cn(styles.incomeCard, className)}
                title={
                    <div>
                        <span>
                            Expenses
                        </span>
                        <LargeNav
                            className={styles.filters}
                            tabOptions={dateFilters}
                            activeTab={expenseFilter}
                            onClick={(x, index) => {
                                setExpenseFilter(index);
                            }}
                        />
                        <LargeNav
                            tabOptions={filters}
                            activeTab={activeFilterExpense}
                            onClick={(x, index) => {
                                setActiveFilterExpense(index);
                            }}
                        />
                    </div>
                }
                classCardHead={styles.cardHead}
                border
            >
                <div className={styles.noContent}>
                    {expenseWho?.length < 1 && expenseCategory?.length < 1 && (
                        <span className={styles.title}>No Data to show</span>
                    )}
                </div>
                <div className={styles.cicularCharts} style={isDashbord ? { paddingLeft: 0 } : {}}>
                    {activeFilterExpense === 1 ?
                        <>
                            {expenseWho?.length > 0 &&
                                <List
                                    title={"Primary Suppliers"}
                                    data={expenseWho}
                                />
                            }
                            {expenseCategory?.length > 0 &&
                                <List
                                    title={"Expense Categories"}
                                    data={expenseCategory}
                                />
                            }
                        </>
                        :
                        <>
                            {expenseWho?.length > 0 &&
                                <CircleChart
                                    title={"Primary Suppliers"}
                                    data={expenseWho}
                                />
                            }
                            {expenseCategory?.length > 0 &&
                                <CircleChart
                                    title={"Expense Categories"}
                                    data={expenseCategory}
                                />
                            }
                        </>
                    }
                </div>
            </Card>
        </div>
    );
};

export default Expense;
