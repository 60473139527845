import React, { useEffect, useRef, useLayoutEffect } from "react";
import Image from "../../../../components/Image";
import styles from "./Accounts.module.sass";
import cn from "classnames";
import Carousel from "react-multi-carousel";
import { useState } from "react";
import Spinner from "../../../../utils/spinner";
import NoCustomer from '../../../Customers/NoCustomer/index';
import {
  dislinkAccount,
  getAccountDetails,
  getCardDetails,
  getIndividualAccountDetails,
  deleteCashAccount,
  getCompanyStripeBalanceSettings,
} from "../../../../utils/apiCallHanlder";
import { useHistory } from "react-router";
import AddModal from "./AddModal";
import { toast, ToastContainer } from "react-toastify";
import "react-multi-carousel/lib/styles.css";
import Item from "../../../Accounting/Overview/Item";
import PayoutBank from "../../../Transactions/PayoutBank";
import MainBank from "../../../Transactions/MainBank";
import DeleteAccount from "../Account/DeleteAccount";
import LinkedAccount from "./LinkedAccount";
import { getConnectStatus, gettingPermissionByFeature, toastConfiguration } from "../../../../utils/utils";
import AccountingEnabled from "../Settings";
import Icon from "../../../../components/Icon";
import Payment from "../Payment";
import AddNewAccount from "./AddNewAccount";
import Item2 from "../../../Home/Overview/Item";

const nav = [
  {
    title: "",
    counter: "",
    value: 37.8,
    id: null,
    accountDigit: "",
  },
  {
    title: "",
    counter: "",
    value: 47.8,
    id: 2,
    accountDigit: "",
  },
];

let getAccountDetail = false;
let connect = 1;

const Accounts = ({ setChangeAccountingStatus, changeAccountingStatus, openModal }) => {
  const history = useHistory();
  const targetRef = useRef();
  const [carouselWidth, setCarouselWidth] = useState(0);

  const [color, setColor] = useState("#4464E2");
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [accountSwitchLoading, setAccountSwitchLoading] = useState(false);

  const [stripeBalanceTab, setStripeBalanceTab] = useState(true);
  const [linkedAccountTab, setLinkedAccountTab] = useState(false);
  const [evolveBankTab, setEvolveBankTab] = useState(false);

  const [stipePaymentAccount, setStripePaymentAccount] = useState(null);
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [cashAccounts, setCashAccounts] = useState([]);
  const [mainBankDetails, setMainBankDetails] = useState(null);
  const [linkedAccountDetails, setLinkedAccountDetails] = useState(null);
  const [cashAccountDetails, setCashAccountDetails] = useState(null);

  const [cashAccountTab, setCashAccountTab] = useState(false);

  const [currentlyActive, setCurrentlyActive] = useState(null);

  const [visible, setVisible] = useState(false);
  const [currencyCode, setDefCurrencyCode] = useState("USD");

  //For Payout Accouting Settings
  const [automaticallyBookTransaction, setAutomaticallyBookTransaction] = useState(false);
  const [revenueCOA, setRevenueCOA] = useState("");
  const [revenueCOAName, setRevenueCOAName] = useState("");
  const [expenseCOA, setExpenseCOA] = useState("");
  const [expenseCOAName, setExpenseCOAName] = useState("");

  const bankAccountsRight = gettingPermissionByFeature("BankAccount");

  useEffect(() => {
    if (openModal) {
      setVisible(true)
    }
  }, [openModal])

  useEffect(async () => {
    const currentlySelectedAccountId = localStorage.getItem(
      "currentlySelectedAccountId"
    );
    if (currentlySelectedAccountId && currentlySelectedAccountId !== "null") {
      setCurrentlyActive(localStorage.getItem("currentlySelectedAccountId"));
      localStorage.setItem("currentlySelectedAccountId", null);
      await gettingBankAccountDetails(
        null,
        localStorage.getItem("currentlySelectedAccountId")
      );
    } else {
      await gettingBankAccountDetails();
    }

    const { data } = await getCompanyStripeBalanceSettings();
    if (data) {
      const settings = data?.companyStripeBalanceSettings;
      setAutomaticallyBookTransaction(settings?.automaticallyBookTransactions);
      setRevenueCOAName(settings?.incomeCompanyCOAccountName);
      setExpenseCOAName(settings?.expenseCompanyCOAccountName);
      setRevenueCOA(settings?.incomeCompanyCOAccountId);
      setExpenseCOA(settings?.expenseCompanyCOAccountId);
    }
  }, []);

  useEffect(async () => {
    if (getAccountDetail) {
      await getIndividualBankDetail();
    } else {
      getAccountDetail = true;
    }
  }, [currentlyActive]);

  const getIndividualBankDetail = async (account) => {
    setAccountSwitchLoading(true);
    const { data } = await getIndividualAccountDetails({
      id: account || currentlyActive,
    });
    if (data) {
      const accountDetail = data?.accountDetail;
      if ((currentlyActive || account) === accountDetail?.id) {
        if (accountDetail?.type === "stripebalance") {
          setLinkedAccountTab(false);
          setEvolveBankTab(false);
          setStripeBalanceTab(true);
          setCashAccountTab(false);
          setStripePaymentAccount(data?.accountDetail);
        } else if (accountDetail?.type === "Financial") {
          setLinkedAccountTab(false);
          setEvolveBankTab(true);
          setStripeBalanceTab(false);
          setCashAccountTab(false);
          setMainBankDetails(data?.accountDetail);
        } else if (accountDetail?.type === "External") {
          setLinkedAccountTab(true);
          setEvolveBankTab(false);
          setStripeBalanceTab(false);
          setCashAccountTab(false);
          setLinkedAccountDetails(data?.accountDetail);
        } else {
          setLinkedAccountTab(false);
          setEvolveBankTab(false);
          setStripeBalanceTab(false);
          setCashAccountTab(true);
          setCashAccountDetails(data?.accountDetail);
        }
      }
    }
    setAccountSwitchLoading(false);
  };

  const gettingBankAccountDetails = async (
    isExternalAccountAdded,
    account,
    selectStripeAccount
  ) => {
    setLoading(true);
    const { data } = await getAccountDetails({}, isExternalAccountAdded);
    if (data) {
      setStripePaymentAccount(data?.paymentBalance?.accountDetail);
      const stripeDetails = data?.paymentBalance?.accountDetail;

      const connectStatus = getConnectStatus();
      connect = connectStatus?.status;

      if (connect === 1) {
        nav[0].title = stripeDetails?.name;
        nav[0].counter = stripeDetails?.balanceValue;
        nav[0].id = stripeDetails?.id;
      }

      nav[1].title = data?.bankAccount?.name;
      nav[1].counter = data?.bankAccount?.balanceValue;
      nav[1].id = data?.bankAccount?.id;
      nav[1].accountDigit = data?.bankAccount?.accountNumberLast4;


      getAccountDetail = false;
      if (data.defaultAccountCurrency)
        setDefCurrencyCode(data.defaultAccountCurrency);

      if ((!currentlyActive && !account) || selectStripeAccount) {
        setStripeBalanceTab(true);
        setEvolveBankTab(false);
        setLinkedAccountTab(false);
        setCashAccountTab(false);
        setCurrentlyActive(stripeDetails?.id);
      } else {
        getIndividualBankDetail(account);
      }

      setLinkedAccounts(data?.linkAccounts);
      setCashAccounts(data?.cashAccounts);

      if (connect !== 1) {
        if (nav[1]?.id) {
          setStripeBalanceTab(false);
          setEvolveBankTab(true);
          setLinkedAccountTab(false);
          setCashAccountTab(false);
          setCurrentlyActive(nav[1]?.id)
        } else if (data?.linkAccounts?.length > 0) {
          setStripeBalanceTab(false);
          setEvolveBankTab(false);
          setLinkedAccountTab(true);
          setCashAccountTab(false);
          setCurrentlyActive(data?.linkAccounts[0]?.id)
        } else if (data?.cashAccounts?.length > 0) {
          setStripeBalanceTab(false);
          setEvolveBankTab(false);
          setLinkedAccountTab(false);
          setCashAccountTab(true);
          setCurrentlyActive(data?.cashAccounts[0]?.id)
        }
      }
    }
    setLoading(false);
  };

  const disconnectLinkedAccount = async () => {
    setDeleteLoading(true);
    const { data } = await dislinkAccount({
      id: linkedAccountDetails?.id,
    });

    if (data) {
      toast.success(
        "External account disconnected successfully",
        toastConfiguration
      );
      await gettingBankAccountDetails(null, null, true);
    }
    setDeleteLoading(false);
  };

  const deleteCashAccountHandler = async () => {
    setDeleteLoading(true);
    const { data } = await deleteCashAccount({
      id: cashAccountDetails?.id,
    });

    if (data) {
      toast.success("Cash account deleted successfully", toastConfiguration);
      await gettingBankAccountDetails(null, null, true);
    }
    setDeleteLoading(false);
  };

  useLayoutEffect(() => {
    var carouselWrapper = document.getElementById("carouselWrap");
    setCarouselWidth(carouselWrapper.offsetWidth);
  });

  useEffect(() => {
    var carouselWrapper = document.getElementById("carouselWrap");
    const handleResize = () => {
      setCarouselWidth(carouselWrapper.offsetWidth);
    };
    if (carouselWrapper) {
      window.addEventListener("resize", handleResize);
    }
  });

  const responsive = {
    sizeOne: {
      breakpoint: { max: 8000, min: 0 },
      items: carouselWidth / 208,
    },
  };

  return (
    <div id="carouselWrap" ref={targetRef}>
      {/* {visible ?
        <AddModal visible={visible} setVisible={setVisible} onClose={() => {
          setVisible(false)
        }}
          gettingBankAccountDetails={gettingBankAccountDetails}
        />
        : null} */}
      {/* {deleteLoading ?
        <>
          <div style={{
            backgroundColor: 'lightgray',
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            opacity: 0.6,
            zIndex: 15,
            top: 0,
            left: 0
          }}>
          </div>
          <div style={{
            position: 'fixed',
            zIndex: 20,
            left: "50%",
            top: '50%',
          }}>
            <Spinner color="gray" size="64" />
          </div>
        </>
        :
        null
      } */}
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spinner loading={loading} size={40} color={"gray"} />
        </div>
      ) : (
        <div className={styles.accountsWrap}>
          {bankAccountsRight !== 2 && (
            <div className={styles.addNewBtn}>
              <button
                className="button"
                onClick={() => {
                  setVisible(true);
                }}
              >
                Add new account
              </button>
            </div>
          )}
          {/* <p className={styles.txt}>
            You can have various Accounts activated with your Great Week Account.
            By default we have activated Stripe Balance for you. Even if its
            activated, you might choose not to have it enabled by default for your
            Payments (Invoices and Subscriptions and Payment Links).
          </p>
          <AccountingEnabled
            setChangeAccountingStatus={setChangeAccountingStatus}
            changeAccountingStatus={changeAccountingStatus}
          /> */}
          <div
            className={cn(styles.nav, {
              [styles.chartShow]:
                stripeBalanceTab || evolveBankTab || linkedAccountTab,
            })}
          >
            <Carousel
              responsive={responsive}
              autoPlay={false}
              showDots={false}
              arrows={false}
              containerClass={styles.carouselContainer}
              partialVisible={true}
            >
              {nav[0]?.id && (<Item2
                className={cn(styles.item, {
                  [styles.activeItem0]: currentlyActive === nav[0]?.id,
                })}
                onActive={() => {
                  setStripeBalanceTab(true);
                  setEvolveBankTab(false);
                  setLinkedAccountTab(false);
                  setCashAccountTab(false);
                  setCurrentlyActive(nav[0]?.id);
                }}
                style={{
                  border: currentlyActive === nav[0]?.id ? 'unset' : '2px solid #6F767E',
                }}
                item={{
                  title: nav[0].title,
                  id: nav[0].id,
                  accountDigit: nav[0]?.accountDigit,
                  counter: nav[0].counter ?? "0",
                }}
                currency={currencyCode}
              />)}
              {nav[1].id && (
                <Item2
                  className={cn(styles.item, {
                    [styles.activeItem1]: currentlyActive === nav[1]?.id,
                  })}
                  onActive={() => {
                    setEvolveBankTab(true);
                    setStripeBalanceTab(false);
                    setLinkedAccountTab(false);
                    setCashAccountTab(false);
                    setCurrentlyActive(nav[1]?.id);
                  }}
                  style={{
                    border: currentlyActive === nav[1]?.id ? 'unset' : '2px solid #6F767E',
                  }}
                  item={{
                    title: nav[1].title,
                    id: nav[1].id,
                    accountDigit: nav[1]?.accountDigit,
                    counter: nav[1].counter ?? "0",
                  }}
                  currency={currencyCode}
                />
              )}
              {linkedAccounts?.map((item, index) => {
                return (
                  <div key={index}>
                    <Item2
                      className={cn(styles.item, {
                        [styles["activeItem" + index]]:
                          currentlyActive === item?.id,
                      })}
                      onActive={() => {
                        setLinkedAccountTab(true);
                        setEvolveBankTab(false);
                        setStripeBalanceTab(false);
                        setCashAccountTab(false);
                        setCurrentlyActive(item?.id);
                      }}
                      style={{
                        border: currentlyActive === item?.id ? 'unset' : '2px solid #6F767E',
                      }}
                      item={{
                        title: item?.name?.split(" ")[0],
                        accountDigit: item?.accountNumberLast4,
                        counter: item?.balanceValue || 0,
                        value: 0,
                        id: item?.id,
                      }}
                      currency={currencyCode}
                    />
                  </div>
                );
              })}

              {cashAccounts?.map((item, index) => {
                return (
                  <div key={index}>
                    <Item2
                      className={cn(styles.item, {
                        [styles["activeItem" + index]]:
                          currentlyActive === item?.id,
                      })}
                      style={{
                        border: currentlyActive === item?.id ? 'unset' : '2px solid #6F767E',
                      }}
                      onActive={() => {
                        setLinkedAccountTab(false);
                        setEvolveBankTab(false);
                        setStripeBalanceTab(false);
                        setCashAccountTab(true);
                        setCurrentlyActive(item?.id);
                      }}
                      item={{
                        title: item?.name?.split(" ")[0],
                        accountDigit: item?.accountNumberLast4,
                        counter: item?.balanceValue || 0,
                        value: 0,
                        id: item?.id,
                      }}
                      currency={currencyCode}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>

          {accountSwitchLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spinner size="44" color="gray" />
            </div>
          ) : (
            <>
              {(stripeBalanceTab && nav[0]?.id) && (
                <PayoutBank
                  automaticallyBookTransaction={automaticallyBookTransaction}
                  setAutomaticallyBookTransaction={setAutomaticallyBookTransaction}
                  setRevenueCOA={setRevenueCOA}
                  revenueCOAName={revenueCOAName}
                  setExpenseCOA={setExpenseCOA}
                  expenseCOAName={expenseCOAName}
                  expenseCoaId={expenseCOA}
                  revenueCoaId={revenueCOA}
                  payoutBankDetails={stipePaymentAccount?.payout}
                  fullDetail={stipePaymentAccount}
                  bankAccountsRight={bankAccountsRight}
                  getIndividualBankDetail={getIndividualBankDetail}
                />
              )}
              {evolveBankTab && <MainBank mainBankDetails={mainBankDetails} />}
              {linkedAccountTab && (
                <>
                  <LinkedAccount
                    linkedAccountDetails={linkedAccountDetails}
                    isLinked={true}
                    gettingBankAccountDetails={gettingBankAccountDetails}
                    bankAccountsRight={bankAccountsRight}
                  />
                  {bankAccountsRight !== 2 && (
                    <DeleteAccount
                      className={styles.linkedDisconnectWrap}
                      deleteLoading={deleteLoading}
                      title={"Disconnect your linked account"}
                      subTitle={`By disconnecting your account, you will not be able to access your financial data for reporting and tax purposes, create future charges and refunds, and respond to new disputes. In particular if you have your accounting managed by us. `}
                      buttonText={"Disconnect"}
                      onClick={() => disconnectLinkedAccount()}
                    />
                  )}
                </>
              )}
              {cashAccountTab && (
                <>
                  <LinkedAccount
                    cashAccountDetails={cashAccountDetails}
                    linkedAccountDetails={cashAccountDetails}
                    gettingBankAccountDetails={gettingBankAccountDetails}
                    bankAccountsRight={bankAccountsRight}
                  />
                  {bankAccountsRight !== 2 && (
                    <DeleteAccount
                      className={styles.linkedDisconnectWrap}
                      title={"Delete your cash account"}
                      subTitle={`By deleting your account, you will not be able to create payments for the account.`}
                      deleteLoading={deleteLoading}
                      buttonText={"Delete"}
                      onClick={async () => await deleteCashAccountHandler()}
                    />
                  )}
                </>
              )}

              {(!nav[0]?.id && !nav[1]?.id && cashAccounts?.length < 1 && linkedAccounts?.length < 1) && (
                <NoCustomer />
              )}
            </>
          )}
        </div>
      )}

      {/* New Account popup */}
      <AddNewAccount
        visible={visible}
        setLoading={setLoading}
        nav={nav}
        gettingBankAccountDetails={gettingBankAccountDetails}
        setVisible={() => setVisible(false)}
        setCurrentlyActive={setCurrentlyActive}
      />
    </div>
  );
};

export default Accounts;
