import React, { useState } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";
import Takeoff from "../Takeoff";

const Entry = ({ onConfirm, code }) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [valid, setValid] = useState(null);
  const [email, setEmail] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [password, setPassword] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");


  const search = window.location.search;
  const params = new URLSearchParams(search);
  const isUserExist = params.get("isUserExist");
  const [directSend, setDirectSend] = useState(isUserExist == 'true');

  const emailHandleChange = (event) => {
    setErrorMessage("");
    setEmail(event.target.value);
  }


  const passwordHandleChange = (event) => {
    setErrorMessage("");
    setPassword(event?.target?.value);
  }

  return (
    <div className={styles.entry}>
      <div className={styles.body}>
       {!directSend &&  <><div className={styles.emailWrapper}>
          <TextInput
            className={error ? styles.hasError : styles.field}
            name="Username"
            placeholder="First Name"
            required
            value={email}
            icon={"user"}
            onChange={emailHandleChange}
          />
        </div>
        <div className={styles.emailWrapper}>
          <TextInput
            className={error ? styles.hasError : styles.field}
            name="Username"
            placeholder="Last Name"
            required
            value={lastName}
            icon={"user"}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>
        <TextInput
          className={styles.field}
          name="password"
          type="password"
          placeholder="Password"
          onChange={passwordHandleChange}
          required
          icon="lock"
        />
        </>}
        <Takeoff
          className={cn(
            "button",
            error ? styles.disable : valid ? styles.button : styles.disable
          )}
          directSend={directSend}
          setDirectSend={setDirectSend}
          onClick={valid && onConfirm}
          data={valid}
          code={code}
          name={email}
          password={password}
          lastName={lastName}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />

        <div className={styles.terms}>
          <Icon name="checkbox" className={styles.termsIcon} size="24" />
          <div className={styles.termsText}>
            <span>By continuing, you agree to Great Week's </span>
            <a
              href="https://greatweek.com/terms/"
              target="_blank"
              className={styles.termsLink}
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            <span> and</span>
            <a
              href="https://greatweek.com/privacy-policy/"
              target="_blank"
              className={styles.termsLink}
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entry;
