import React, { useEffect, useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Image from "../Image";
import {
  CheckIfEnabledInStripeAccountType,
  getCompanyDataValue,
  getCurrentPageName,
  getCurrentUser,
  reverifyHandler,
} from "../../utils/utils";
import User from "../Header/User";
import Notifications from "./notifications";
import SaveSuccess from '../SaveSuccess';
import { useHistory } from "react-router-dom";
import PlanSubscription from "../../components/PlanSubscription";
import { getStripePlanDetail } from "../../utils/apiCallHanlder";

const initialNavigationConst = [
  {
    title: "Dashboard",
    icon: "dashboard",
    url: "/dashboard",
    stripeAccountTypes: []
  },
  {
    title: "Payments",
    icon: "dollar",
    url: "/",
    paths: ['subscriptions', 'paymentLinks', 'payments', 'quotes', 'quote'],
    paymentsLocation: true,
    stripeAccountTypes: ['custom', 'standard'],
    dropdownOptions: [
      {
        title: 'Invoices',
        url: '/payments',
        paths: ["payments"]
      },
      {
        title: 'Subscriptions',
        url: '/subscriptions',
        paths: ["subscriptions"]
      },
      {
        title: 'Payment Links',
        url: '/paymentLinks',
        paths: ["paymentLinks"]
      },
      {
        title: 'Quotes',
        url: '/quotes',
        paths: ["quotes"]
      }]
  },
  {
    title: "Finance",
    icon: "hybrid_view",
    url: "/",
    paths: ['capital', 'accountsandcards', 'cards', 'transactions', 'accounting'],
    stripeAccountTypes: ['custom', 'standard'],
    dropdownOptions: [
      {
        title: 'Capital',
        url: '/capital',
        paths: ["capital"]
      },
      {
        title: 'Cards',
        url: '/cards',
        paths: ["accountsandcards", "cards"]
      },
      {
        title: 'Accounting',
        url: '/transactions',
        paths: ["transactions", 'accounting']
      }
    ]
  },
  {
    title: "CRM",
    icon: "schedule",
    url: "/",
    paths: [
      'studio',
      'discounts',
      'taxrate',
      'products',
      'contacts',
      'projects',
      'lenderportal',
      'projects-download',
      'board',
      'crm'
    ],
    stripeAccountTypes: ['custom', 'standard'],
    dropdownOptions: [
      {
        title: 'Contacts',
        url: '/contacts',
        paths: ['contacts']
      },
      {
        title: 'Products',
        url: '/products',
        paths: ['products']
      },
      {
        title: 'Documents',
        url: '/studio/4',
        paths: ['/studio/4']
      },
      {
        title: 'Templates',
        url: '/studio/2',
        paths: ['/studio/2']
      },
      {
        title: 'Event',
        url: '/studio/3',
        paths: ['/studio/3']
      },
      {
        title: 'Task',
        url: '/crm/task',
        paths: ['/crm/task']
      },
      {
        title: 'Property',
        url: '/projects',
        paths: ['projects', 'lenderportal', 'projects-download']
      }
    ]
  },
];

let settingsNode = {
  title: "Settings",
  icon: "setting",
  url: "/settings/Profile",
  paths: ['settings'],
  stripeAccountTypes: ['custom', 'standard'],

};

let premiumFeature = ["Quotes", "Accounting", "Templates", "Event", "Task", "Property"];

let messagesNode = {
  title: "Messages",
  icon: "message",
  url: "/messages",
  paths: ['messages'],
  stripeAccountTypes: ['custom', 'standard'],
};

let messagesWithAINode = {
  title: "AI Co-Pilot",
  icon: "rocket",
  url: "/ai",
  paths: ['ai', 'ai/prompt', 'ai/document', 'ai/contract'],
};

const Sidebar = ({
  className,
  onClose,
  hasUnreadNotifications,
  setHasUnreadNotification,
  isFeatureChanged
}) => {
  const history = useHistory();
  const stripeAccountType = getCompanyDataValue('stripeAccountType')
  const navigation = initialNavigationConst.filter(item => CheckIfEnabledInStripeAccountType(item, stripeAccountType));

  const [visible, setVisible] = useState(false);
  const [isDashboardEnabled, setIsDashboardEnabled] = useState(null);
  const [isContactsEnabled, setIsContactsEnabled] = useState(null);
  const [isGetPaidEnabled, setIsGetPaidEnabled] = useState(null);
  const [isAccountingEnabled, setIsAccountingEnabled] = useState(null);
  const [isStudioEnalbed, setIsStudioEnabled] = useState(null);
  const [isPropertyEnabled, setIsPropertyEnabled] = useState(null);
  const [isCardsEnabled, setIsCardsEnabled] = useState(null);
  const [isToDoEnabled, setIsToDoEnabled] = useState(null);
  const [currentlySelectedTab, setCurrentlySelectedTab] = useState('Dashboard');

  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [showStripeConnectModal, setShowStripeConnectModal] = useState(false);

  const [showPlanModal, setShowPlanModal] = useState(false);
  const [clientReferenceId, setClientReferenceId] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [isLivePlan, setIsLivePlan] = useState(true);

  let user = null;

  useEffect(() => {
    const activatedFeatures = JSON.parse(localStorage.getItem("activatedFeatures") || "");
    setIsPropertyEnabled(activatedFeatures?.isPropertyEnabled);
    setIsStudioEnabled(activatedFeatures?.isStudioEnabled);
    setIsAccountingEnabled(activatedFeatures?.isAccountingEnabled);
    setIsCardsEnabled(activatedFeatures?.isCardsEnabled);
    setIsDashboardEnabled(activatedFeatures?.isDashboardEnabled)
    setIsContactsEnabled(activatedFeatures?.isContactsEnabled)
    setIsGetPaidEnabled(activatedFeatures?.isGetPaidEnabled)
    setIsToDoEnabled(activatedFeatures?.isTodoTaskEnabled)
  }, [isFeatureChanged])


  if (typeof window !== "undefined") {
    user = getCurrentUser();
  }

  const currentPage = getCurrentPageName();

  var path = window?.location?.pathname;
  var page = path?.split("/")[1];

  const CheckIfSelected = (pagePath, node) => {
    if (!node || !node.url || !pagePath) return false;
  
    // Check if the current path exactly matches the node's URL
    const isExactMatch = node.url.toLowerCase() === `/${pagePath.toLowerCase()}`;
  
    // Check if the current path matches any of the node's paths
    const isPathMatch = node.paths && node.paths.some(path => 
      path.toLowerCase() === pagePath.toLowerCase() || 
      window.location.pathname.toLowerCase().startsWith(path.toLowerCase())
    );
  
    // Check if any dropdown option matches the current path
    const isDropdownMatch = node.dropdownOptions && node.dropdownOptions.some(option => 
      CheckIfSelected(pagePath, option)
    );
  
    return isExactMatch || isPathMatch || isDropdownMatch;
  };

  const isOptionDisabled = (title) => {
    return (title === "Accounting" && (!isAccountingEnabled || !user?.hasAccessTo?.allowAccounting)) ||
      (title === "Property" && (!isPropertyEnabled || !user?.hasAccessTo?.allowProperty)) ||
      (title === "Dashboard" && (!isDashboardEnabled || !user?.hasAccessTo?.allowDashboard)) ||
      (title === "Payments" && !isGetPaidEnabled) ||
      (title === "Contacts" && (!isContactsEnabled || !user?.hasAccessTo?.allowContacts)) ||
      (title === "CRM" && !isStudioEnalbed) ||
      (title === "Cards" && (!isCardsEnabled || !user?.hasAccessTo?.allowCards)) ||
      (title === "Task" && (!isToDoEnabled || !user?.hasAccessTo?.allowTask)) ||
      (title === "Invoices" && !user?.hasAccessTo?.allowInvoices) ||
      (title === "Quotes" && !user?.hasAccessTo?.allowQuotes) ||
      (title === "Subscriptions" && !user?.hasAccessTo?.allowSubscription) ||
      (title === "Products" && !user?.hasAccessTo?.allowProducts) ||
      (title === "Documents" && !user?.hasAccessTo?.allowDocuments) ||
      (title === "Templates" && !user?.hasAccessTo?.allowTemplates) ||
      (title === "Event" && !user?.hasAccessTo?.allowEvents) ||
      (title === "Capital" && !user?.hasAccessTo?.allowCapital) ||
      (title === "Payment Links" && !user?.hasAccessTo?.allowPaymentLinks)
  }

  return (
    <>
      <SaveSuccess
        showSuccessModal={showStripeConnectModal}
        text={"Stripe should be connected before activating cards"}
        onClose={() => setShowStripeConnectModal(false)}
        onConfirm={() => {
          reverifyHandler(setIsStripeLoading);
        }}
        loading={isStripeLoading}
        isForStripe={true}
      />

      {/* Plan popup */}
      <PlanSubscription
        clientReferenceId={clientReferenceId}
        showPlanModal={showPlanModal}
        onClose={() => {
          setShowPlanModal(false);
        }}
        customerEmail={customerEmail}
        clientSecret={clientSecret}
        isLivePlan={isLivePlan}
      />

      <div
        className={cn(styles.sidebar, className, { [styles.active]: visible })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link className={styles.logo} to="/" onClick={onClose}>
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-dark.png"
            alt="Core"
          />
        </Link>
        <div className={styles.menu}>
          {navigation.map((x, index) => {
            let isSelected = CheckIfSelected(page, x);

            return isOptionDisabled(x.title) ?
              null : <>
                <div
                  className={cn(styles.item,
                    (isSelected) &&
                    styles.active
                  )}
                  activeClassName={styles.active}
                  key={index}
                  exact
                  onClick={() => {

                    if (currentlySelectedTab === x.title) setCurrentlySelectedTab(undefined);
                    else setCurrentlySelectedTab(x.title);

                    if (x?.dropdownOptions?.length > 0) return
                    if (currentPage.toUpperCase() === x.title.toUpperCase()) {
                      window.location.reload();
                    }
                    onClose();

                    history.push({
                      pathname: x.url,
                      state: { setNewInvoice: x.paymentsLocation },
                    });
                  }}
                >
                  {x.title === 'Task' ?
                    <Icon name={x.icon} size="20" viewBox="0 0 32 24" />
                    : x.title === "Capital" ?
                      <Icon name={x.icon} size="20" viewBox="0 0 34 35" />
                      :
                      <Icon name={x.icon} size="20" />
                  }
                  {x.title}
                  {x.dropdownOptions?.length > 0 &&
                    <div style={{ marginLeft: 6 }} className={styles.arrowIcon}>
                      <Icon name={x.title === currentlySelectedTab ? "arrow-up" : "arrow-down"} size="20" />
                    </div>
                  }
                </div>
                {(x.title === currentlySelectedTab) && x.dropdownOptions?.length > 0 && (
                  <div className={styles.dropdownOptions}>
                    {x?.dropdownOptions?.map((option, index) => {
                      const isSubOptionSelected = CheckIfSelected(page, option);
                      return !isOptionDisabled(option.title) && <div key={index}
                        className={cn(styles.dropdownItem, isSubOptionSelected && styles.active)}
                        onClick={async () => {
                          onClose();
                          if (premiumFeature.includes(option.title)) {

                            const { data } = await getStripePlanDetail();

                            if (data) {
                              const companyPlanId = data.planDetail?.planId;
                              if (companyPlanId == 1) {
                                setClientReferenceId(data.planDetail?.clientReferenceId);
                                setCustomerEmail(data.planDetail?.customerEmail);
                                setClientSecret(data.planDetail?.clientSecret);
                                setIsLivePlan(data.planDetail?.isLivePlan)
                                setShowPlanModal(true);
                                return;
                              }
                            }
                          }

                          history.push(option.url);
                        }}
                      >
                        {option.title}
                      </div>
                    })}
                  </div>
                )}
              </>
          })}
        </div>
        <div className={cn(styles.menu, styles.premiumMenu)}>
      </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>

        {/* <User
            className={styles.user}
            userNamePos={
              <div className={styles.footerUserWrapper}>
                <div className={styles.namePos}>
                  <h5>{user?.name}</h5>
                  <p>{user?.roleid == 1 ? 'Admin' : 'Staff'}</p>
                </div>
                <Icon name="arrow-down" size="24" />
              </div>
            }
          /> */}

        <User className={styles.user} />

        <div className={styles.foot}>

          {
            user?.hasAccessTo?.allowNotifications && 
            <Notifications
            className={styles.messages}
            onClick={onClose}
            hasUnreadNotifications={hasUnreadNotifications}
            setHasUnreadNotification={setHasUnreadNotification}
          />
          }
          
          {/* <Link activeClassName={styles.active}
            className={cn(styles.link,
              (CheckIfSelected(page, messagesNode)) &&
              styles.active
            )}
            onClick={async () => {
                const { data } = await getStripePlanDetail();
                
                if (data) {
                  const companyPlanId = data.planId;
                  const companyLinkedAccountCount = data.linkedAccountCount;
                  const accountId = data.stripeAccountId;

                  if (companyPlanId == 1 && companyLinkedAccountCount >= 1){
                    setStripeAccountId(accountId);
                    setShowSubscriptionModal(true);
                    return;
                  }
                }

              history.push('/messages');
            }}>
            <Icon name="messages" size="24" />
            Messages
          </Link> */}

          {user?.hasAccessTo?.allowAICoPilot && (
            <Link activeClassName={styles.active}
              className={cn(styles.link,
                (CheckIfSelected(page, messagesWithAINode)) &&
                styles.active
              )}
              onClick={async () => {
                onClose();
                const { data } = await getStripePlanDetail();

                if (data) {
                  const companyPlanId = data.planDetail?.planId;
                  if (companyPlanId == 1) {
                    setClientReferenceId(data.planDetail?.clientReferenceId);
                    setCustomerEmail(data.planDetail?.customerEmail);
                    setClientSecret(data.planDetail?.clientSecret);
                    setIsLivePlan(data.planDetail?.isLivePlan)
                    setShowPlanModal(true);
                    return;
                  }
                }
                history.push('/ai');
              }}>
              <Icon name="rocket" size="24" />
              AI Co-Pilot
            </Link>
          )}

          <NavLink activeClassName={styles.active} to="/settings/Profile"
            className={cn(styles.link,
              (CheckIfSelected(page, settingsNode)) &&
              styles.active
            )}
            onClick={onClose}
            >
            <Icon name="setting" size="24" />
            Settings
          </NavLink>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
