import React, { useState } from "react";
import styles from "../../../../../components/Search/Search.module.sass";
import cn from "classnames";
import Icon from "../../../../../components/Icon";
import Spinner from "../../../../../utils/spinner";
import { Redirect } from "react-router";
import SelectionModal from "../../../../../components/ContactSelectionModal/Modal";

let typingTimer;
let doneTypingInterval = 300;

const Search = ({
  className,
  detailsTxt,
  inputPlaceholder,
  addNewItem,
  addNewBtnTxt,
  setShowDetails,
  searchNames,
  selectedCustomerName,
  getCustomers,
  searchString,
  setSearchString,
  nameClickHandler,
  visible,
  setVisible,
  searchLoading,
  setSearchLoading,
  setSearchNames,
  addQuoteLoading
}) => {

  const [showModal, setShowModal] = useState(false);

  const closeButtonHandler = () => {
    setVisible(false);
    if (selectedCustomerName && selectedCustomerName !== "") {
      setShowDetails(true);
    }
  }

  const handleChange = async (event) => {
    setSearchLoading(true);
    await getCustomers(searchString);
    setSearchLoading(false);
  }

  return (
    <>
      <SelectionModal
        showModal={showModal}
        setShowModal={setShowModal}
        customerSelects={true}
        recipientSelects={false}
        action={addNewItem}
      />
      <div className={styles.detailsTxt}>{detailsTxt && detailsTxt}</div>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
      >
        <div className={styles.head}>
          <button className={styles.start}>
            <Icon name="search" size="24" />
          </button>
          <input
            className={styles.input}
            type="text"
            value={searchString}
            onKeyUp={() => {
              clearTimeout(typingTimer);
              typingTimer = setTimeout(handleChange, doneTypingInterval);
            }}
            onKeyDown={() => {
              clearTimeout(typingTimer);
            }}
            placeholder={selectedCustomerName ? selectedCustomerName : inputPlaceholder}
            onChange={(event) => setSearchString(event?.target?.value)}
            onClick={() => {
              setVisible(true)
              handleChange();
            }}
          />
          {visible &&
            <button className={styles.close} onClick={() => {
              setSearchString("");
              setSearchNames([]);
              closeButtonHandler();
            }}>
              <Icon name="close-circle" size="24" />
            </button>
          }
        </div>
        <div className={styles.body}>
          {searchLoading || addQuoteLoading ?
            <div style={{ display: 'flex', justifyContent: 'center', height: 70, alignItems: 'center' }}>
              <Spinner loading={searchLoading || addQuoteLoading} color={"blue"} size={"24"} />
            </div>
            :
            <>
              <button onClick={addNewItem} className={styles.addCustomerBtn}>
                <Icon name="plus-circle" size="24" />
                {searchString !== "" ? "Add " + searchString : addNewBtnTxt}
              </button>

              {searchNames.length > 0 ?
                <>
                  <div className={styles.detailsTxt}>{searchString !== "" ? "Search Results" : "Recent"}</div>
                  <div style={searchNames.length > 4 ? { height: 200, overflowY: 'auto' } : null}>
                    {searchNames.map((x, index) => (
                      <div key={index}>
                        <button onClick={() => {
                          nameClickHandler(x.name, x.id, x?.imageUrl, x.email, x.mobile)
                        }} className={styles.customerBtn}>
                          {x.name} &nbsp; &nbsp;
                          <span className={styles.email}>{x.email}</span>
                        </button>
                      </div>
                    ))}
                  </div>
                </> : null
              }

            </>
          }
        </div>

      </div>
    </>
  );
};

export default Search;
