import Card from '../Card';
import Modal from '../Modal';
import styles from './ImagePreview.module.sass'

const ImagePreview = ({
    visible,
    setVisible,
    imageUrl
}) => {
    return <Modal
        visible={visible}
        onClose={() => {
            setVisible(false);
        }}
        outerClassName={styles.outerModalPreview}
    >
        <Card
            className={styles.imagePreviewCard}
            title="Image Preview"
        >
            <img src={imageUrl} className={styles.previewImage} />
        </Card>
    </Modal>
}

export default ImagePreview