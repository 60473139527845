import React, { useState, useEffect } from "react";
import styles from "./ProductOverview.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Filters from "../../../components/Filters";
import Settings from "./Settings";
import Table from "./Table";
import Icon from "../../../components/Icon";
import EditNewProduct from "../EditNewProduct";
import MainContainer from '../index';

import { products } from "../../../mocks/products";
import { Redirect } from "react-router";
import { deleteBulkProducts, getProductsData } from "../../../utils/apiCallHanlder";
import Spinner from "../../../utils/spinner";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../utils/utils";

let lastId = "";
let page = 1;

const ProductOverview = ({ activeIndex }) => {
  const [visible, setVisible] = useState(true);
  const [actionAvailableProduct, setActionAvailableProduct] = useState(true);
  const [availableCheckCount, setAvailableCheckCount] = useState(0);
  const [actionArchivedProduct, setActionArchivedProduct] = useState(true);
  const [archivedCheckCount, setArchivedCheckCount] = useState(0);
  const [actionBtn, setActionBtn] = useState(true);
  const [showMainContainer, setShowMainContainer] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteIdArray, setDeleteIdArray] = useState([]);

  const [productsData, setProductsData] = useState([]);
  const [hasMore, setHasMore] = useState(null);
  const [loading, setIsLoading] = useState(true);
  const [currentActiveIndex, setCurrentActiveIndex] = useState(activeIndex || 1);

  // const productsAvailableIdLength = Object.keys(
  //   products.filter((x) => !x.isArchived)
  // ).length;

  // const productsArchivedIdLength = Object.keys(
  //   products.filter((x) => x.isArchived)
  // ).length;

  /* Available product handling */
  // const handleAvailableAll = (e) => {
  //   var ariaStatus = e.target.getAttribute("aria-Checked");
  //   if (ariaStatus === "true") {
  //     setActionAvailableProduct(true);
  //     setAvailableCheckCount(0);
  //   }
  //   if (ariaStatus === "false") {
  //     setActionAvailableProduct(false);
  //     setAvailableCheckCount(productsAvailableIdLength);
  //   }
  //   if (ariaStatus === "mixed") {
  //     setActionAvailableProduct(false);
  //     setAvailableCheckCount(productsAvailableIdLength);
  //   }
  // };

  // const handleAvailableProduct = (e) => {
  //   if (e.target.checked) {
  //     setAvailableCheckCount(availableCheckCount + 1);
  //   }
  //   if (!e.target.checked) {
  //     setAvailableCheckCount(availableCheckCount - 1);
  //   }
  // };

  /* Archived product handling */
  // const handleArchivedAll = (e) => {
  //   var ariaStatus = e.target.getAttribute("aria-Checked");
  //   if (ariaStatus === "true") {
  //     setActionArchivedProduct(true);
  //     setArchivedCheckCount(0);
  //   }
  //   if (ariaStatus === "false") {
  //     setActionArchivedProduct(false);
  //     setArchivedCheckCount(productsArchivedIdLength);
  //   }
  //   if (ariaStatus === "mixed") {
  //     setActionArchivedProduct(false);
  //     setArchivedCheckCount(productsArchivedIdLength);
  //   }
  // };

  // const handleArchivedProduct = (e) => {
  //   if (e.target.checked) {
  //     setArchivedCheckCount(archivedCheckCount + 1);
  //   }
  //   if (!e.target.checked) {
  //     setArchivedCheckCount(archivedCheckCount - 1);
  //   }
  // };

  const handleEditProduct = (id) => {
    setVisible(false);
    setId(id);
  };

  useEffect(() => {
    if (availableCheckCount === 0) {
      setActionAvailableProduct(true);
    } else {
      setActionAvailableProduct(false);
    }
    if (archivedCheckCount === 0) {
      setActionArchivedProduct(true);
    } else {
      setActionArchivedProduct(false);
    }
  });

  const actionBtnHandle = () => {
    setActionBtn(!actionBtn);
    setArchivedCheckCount(0);
    setAvailableCheckCount(0);
  };

  const getProductData = async (isActiveIndexChanged, sort, sortOrder, clearId) => {
    if (productsData.length === 0 || clearId) {
      lastId = "";
      page = 1;
    }

    if(lastId) {
      page = page + 1;
    }

    setIsLoading(true);
    const productsDataResponse = await getProductsData(30, page, false, sort || 'name', sortOrder || 'asc');
    const { data } = productsDataResponse;
    if (data) {
      setHasMore(data.isNextPageExist);
      lastId = data?.productList?.at(-1)?.id;
      !isActiveIndexChanged ?
        setProductsData(productsData.concat(data.productList))
        : setProductsData(data.productList)
    }
    setIsLoading(false);
    setCurrentActiveIndex(1);
  }

  return (
    <>
      {showMainContainer ? <MainContainer /> :
        visible ? (
          <>
            {/* {<div className={cn("h3", styles.pageTitle)}>Products</div>} */}
            <Card
              className={styles.card}
              title="Products"
              border
              /* classTitle={cn("title-blue", styles.title)} */
              classCardHead={cn(styles.head, { [styles.hidden]: show })}
              head={
                <>
                  {/* <Filters
                    className={styles.filters}
                    title="Showing 9 of 32 product"
                    iconHead={
                      <Icon
                        className={styles.filterBtn}
                        name="adjustments"
                        size="24"
                      />
                    }
                  >
                    <Settings isClick={() => setVisible(false)} />
                  </Filters>
                  <button className={styles.downloadBtn}>
                    <Icon name="download" size="24" />
                  </button> */}
                  {actionBtn ? (
                    availableCheckCount < 1 ? (
                      <button
                        onClick={() => {
                          setVisible(false);
                          setIsAdd(true);
                        }
                        }
                        className={cn("button", styles.addProductBtn)}
                      >
                        Add new
                      </button>
                    ) : (
                      <button
                        onClick={async () => {
                          setDeleteLoading(true);
                          const { data } = await deleteBulkProducts({
                            ids: deleteIdArray
                          })
                          if (data) {
                            lastId = "";
                            setProductsData([]);
                            setAvailableCheckCount(0);
                            await getProductData(true);
                          } else {
                            toast.error("Error in deleting product", toastConfiguration)
                          }
                          setDeleteLoading(false);
                        }}
                        className={cn(
                          "button-stroke-red",
                          styles.deleteMarkedBtn
                        )}
                        style={{ width: 150 }}
                      >
                        {deleteLoading ? <Spinner size="24" color="gray" /> :
                          "Delete Marked"
                        }
                      </button>
                    )
                  ) : archivedCheckCount < 1 ? (
                    <button
                      onClick={() => {
                        setVisible(false);
                        setIsAdd(true);
                      }
                      }
                      className={cn("button", styles.addProductBtn)}
                    >
                      Add new
                    </button>
                  ) : (
                    <button
                      onClick={async () => {
                        //setDeleteIdArray([])
                        //setVisible(false)
                      }}
                      className={cn("button-stroke-red", styles.deleteMarkedBtn)}
                      style={{ width: 150 }}
                    >
                      Delete Marked
                    </button>
                  )}
                </>
              }
            >
              <div className={cn(styles.row, { [styles.flex]: show })}>
                <Table
                  className={styles.table}
                  activeTable={show}
                  pro
                  setId={setId}
                  setActionAvailableProduct={setActionAvailableProduct}
                  setAvailableCheckCount={setAvailableCheckCount}
                  setArchivedCheckCount={setArchivedCheckCount}
                  archivedCheckCount={archivedCheckCount}
                  setActionArchivedProduct={setActionArchivedProduct}
                  availableCheckCount={availableCheckCount}
                  setCustomerVisible={setVisible}
                  deleteIdArray={deleteIdArray}
                  setDeleteIdArray={setDeleteIdArray}
                  setShowMainContainer={setShowMainContainer}
                  setActiveTable={setShow}
                  currentActiveIndex={activeIndex}
                  activeIndex={currentActiveIndex}
                  getProductData={getProductData}
                  setIsLoading={setIsLoading}
                  lastId={lastId}
                  setActiveIndex={setCurrentActiveIndex}
                  loading={loading}
                  hasMore={hasMore}
                  productsData={productsData}
                  setProductsData={setProductsData}
                  editProduct={handleEditProduct}
                  onNoProductClickHandler={() => {
                    setVisible(false);
                    setIsAdd(true);
                  }}
                  // changeAvailableProduct={handleAvailableProduct}
                  // changeAllAvailableProduct={handleAvailableAll}
                  // changeArchivedProduct={handleArchivedProduct}
                  // changeAllArchivedProduct={handleArchivedAll}
                  actionBtnHandle={actionBtnHandle}
                />
              </div>
            </Card>
          </>
        ) : (
          isAdd ? <EditNewProduct isAdd={true} /> :
            <Redirect to={`/products/${id}`} />
          // <EditNewProduct id={id} archived={archived} />
        )}
    </>
  );
};

export default ProductOverview;
