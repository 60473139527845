import { React, useState, useEffect } from "react";
import { getAllPropertyDocuments } from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { dateFormatterWithTime } from "../../../../utils/utils";
import styles from "./Documents.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import DocumentOverviewPopup from "../../../Accounting/Documents/DocumentOverviewPopup";
import { ToastContainer } from "react-toastify";
import NoCustomer from "../../../Customers/NoCustomer";
import Card from "../../../../components/Card";

let page = 1;

const Documents = ({ propertyId, largeNav, backBtn, propertyName }) => {
  const [documents, setDocuments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [showNewDocModal, setShowNewDocModal] = useState(false);
  const [currentlySelectedDocument, setCurrentlySelectedDocument] =
    useState(null);

  useEffect(async () => {
    await getDocumentList(false);
  }, []);

  const getDocumentList = async (doConcat) => {
    page = doConcat ? page + 1 : 1;
    !doConcat && setLoading(true);
    const { data } = await getAllPropertyDocuments({
      entityId: propertyId,
      pageSize: 30,
      page: page || 1,
    });
    if (data) {
      if (doConcat) {
        setDocuments(documents?.concat(data?.documents));
      } else {
        setDocuments(data?.documents);
      }

      setHasMore(data?.isNextPageExist);
    }
    !doConcat && setLoading(false);
  };

  return (
    <>
      <ToastContainer />
      <DocumentOverviewPopup
        showNewDocModal={showNewDocModal}
        setShowNewDocModal={setShowNewDocModal}
        currentlySelectedDocument={currentlySelectedDocument}
        setCurrentlySelectedDocument={setCurrentlySelectedDocument}
        isComingFromProperty={true}
        propertyId={propertyId}
        getDocumentList={getDocumentList}
      />
      <Card
        title="Property"
        head={
          <div className={styles.btnWrap}>
            {backBtn}
            <button
              className={cn("button", styles.saveButton)}
              onClick={() => {
                setCurrentlySelectedDocument(null);
                setShowNewDocModal(true);
              }}
            >
              Add Document
            </button>
          </div>
        }
        border
      >
        {largeNav}
        <div className={styles.table}>
          {loading ? (
            <div className={styles.spinner}>
              <Spinner loading={loading} size={50} color={"gray"} />
            </div>
          ) : documents?.length > 0 ? (
            <>
              <div className={styles.head}>
                <div className={cn(styles.col, styles.colName)}>Name</div>
                <div className={cn(styles.col, styles.colName)}>Created</div>
                <div className={cn(styles.col, styles.colName)}>Type</div>
              </div>
              {documents?.map((x, index) => (
                <div
                  className={styles.row}
                  key={index}
                  onClick={() => {
                    setCurrentlySelectedDocument(x);
                    setShowNewDocModal(true);
                  }}
                >
                  <div className={styles.col}>
                    <p className={styles.mblLabel}>Name</p>
                    <span>{x.displayName}</span>
                  </div>
                  <div className={styles.col}>
                    <p className={styles.mblLabel}>Created</p>
                    <span>{dateFormatterWithTime(x.uploadedOn)}</span>
                  </div>
                  <div className={styles.col}>
                    <p className={styles.mblLabel}>Type</p>
                    <span>{x.documentType}</span>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <NoCustomer />
          )}
        </div>
        {hasMore ? (
          <div className={styles.foot}>
            <button
              className={cn("button-stroke-red button-small", styles.button)}
              onClick={async () => {
                setNextPageLoading(true);
                await getDocumentList(true);
                setNextPageLoading(false);
              }}
              style={{ width: 200 }}
            >
              {nextPageLoading ? (
                <Spinner loading={nextPageLoading} size={20} color={"gray"} />
              ) : (
                <>
                  <span>{"See More Documents"}</span>
                  <Icon name="arrow-next" size="20"></Icon>
                </>
              )}
            </button>
          </div>
        ) : null}
      </Card>
    </>
  );
};

export default Documents;
