import styles from './tableHeader.module.sass';

const TableHeader = () => {
    return <div className={styles.tableHeaderContainer}>
        <span className={styles.columnName}>S.code</span>
        <span className={styles.columnName}>Product</span>
        <span className={styles.columnName}>Qty</span>
        <span className={styles.columnName}>Cost Price</span>
        <span className={styles.columnName}>Total</span>
        <span className={styles.columnName}>Disc %</span>
        <span className={styles.columnName}>Disc.</span>
        <span className={styles.columnName}>VAT</span>
        <span className={styles.columnName}>Sub Total</span>
        <span className={styles.columnName}>Sell Price</span>
        <span className={styles.plus}></span>
    </div>
}

export default TableHeader;