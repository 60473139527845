import React, { useState } from "react";
import styles from "../../../../components/Search/Search.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import Spinner from "../../../../utils/spinner";
import { useHistory } from "react-router";
import SelectionModal from "../../../../components/ContactSelectionModal/Modal";

let typingTimer;
let doneTypingInterval = 600;

const Search = ({
  className,
  detailsTxt,
  inputPlaceholder,
  addNewRecipient,
  addNewBtnTxt,
  searchNames,
  recipientList,
  setShowRecipient,
  setShowRecipientOverview,
  setSearchStr,
  searchStr,
  startLoading,
  searchRecipient,
  onSaveClickHandler
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const getSearchResults = () => {
    searchRecipient(searchStr, setLoading);
  }

  return (
    <>
      <SelectionModal
        showModal={showModal}
        setShowModal={setShowModal}
        customerSelects={false}
        recipientSelects={true}
        action={addNewRecipient}
      />
      <div className={styles.detailsTxt}>{detailsTxt && detailsTxt}</div>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
      >
        {startLoading ?
          <div style={{ display: 'flex', justifyContent: 'center', width: "100%" }}>
            <Spinner size="18" color="gray" />
          </div>
          :
          <div className={styles.head}>
            <button className={styles.start}>
              <Icon name="search" size="24" />
            </button>
            <input
              className={styles.input}
              type="text"
              value={searchStr}
              onKeyUp={() => {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(getSearchResults, doneTypingInterval);
              }}
              onKeyDown={() => {
                clearTimeout(typingTimer);
              }}
              onChange={(e) => {
                setSearchStr(e.target.value);
              }}
              placeholder={inputPlaceholder}
              onClick={() => setVisible(true)}
            />
            <button className={styles.close} onClick={() => setVisible(false)}>
              {loading ?
                <Spinner size="24" color="gray" />
                :
                <Icon name="close-circle" size="24" />
              }
            </button>
          </div>
        }
        <div className={styles.body}>
          <button onClick={addNewRecipient} className={styles.addRecipientBtn}>
            <Icon name="plus-circle" size="24" />
            {addNewBtnTxt && addNewBtnTxt}
          </button>
          <button onClick={() => {
            setShowRecipientOverview(true)
            history.push("/transactions/all");
          }} className={styles.seeAllBtn}>
            <Icon name="arrow-right" size="24" />
            <span>See all recipient</span>
          </button>
          {recipientList?.length > 0 ? <div className={styles.detailsTxt}>Saved</div> : null}
          {recipientList?.map((x, index) => (
            <div key={index}>
              <button onClick={() => onSaveClickHandler(x)} className={styles.recipientBtn}>
                {x.name} &nbsp; &nbsp;
                <span className={styles.email}>{x.email}</span>
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Search;
