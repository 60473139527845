import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Checkbox from "../../../../../../components/Checkbox";
import Icon from "../../../../../../components/Icon";
import CancelModal from "../../../NewQuote/Modals/CancelModal";
import {
  dateFormatter,
  toastConfiguration,
  toTitleCase,
} from "../../../../../../utils/utils";
import { useHistory } from "react-router";
import {
  acceptQuote,
  cancelQuote,
  cancelSubscription,
  duplicateQuote,
  finalizeQuote,
  reviseQuote,
} from "../../../../../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import Spinner from "../../../../../../utils/spinner";
import AlternativeAvatar from "../../../../../../components/AlternativeAvatar";

const Row = ({
  item,
  onChange,
  activeTable,
  activeId,
  className,
  editAction,
  singleCheck,
  editProduct,
  archiveStatus,
  setSubscriptionId,
  setVisibleAdd,
  needNewData,
  setNeedNewData,
}) => {
  const [visible, setVisible] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [invoiceNow, setInvoiceNow] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [showFinalizedModal, setShowFinalizedModal] = useState(false);

  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [reviseLoading, setReviseLoading] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);

  const history = useHistory();

  item.icon = "dots";
  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
      >
        <div
          className={styles.editOverlay}
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/quote/${item.id}`);
          }}
        ></div>
        {/* <div className={styles.col}>
          {singleCheck || (
            <Checkbox className={styles.checkbox} onChange={onChange} />
          )}
        </div> */}
        <div
          className={cn(styles.col, styles.firstChild)}
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/quote/${item.id}`);
          }}
        >
          <div
            className={styles.customer}
            style={{ display: "flex", alignItems: "center" }}
          >
            {item.customerImage ? (
              <div>
                <img
                  className={styles.image}
                  src={item.customerImage}
                  alt='product'
                />
              </div>
            ) : (
              <AlternativeAvatar
                name={item.customerName}
                className={"invoice"}
              />
            )}
            <div onClick={editProduct} className={cn(styles.name)}>
              <span>{item?.customerName}</span>
              <br />
              <span>{archiveStatus && archiveStatus}</span>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.amountTag}>Amount</div>
          <div className={styles.amount}>{item?.amount}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.statusTag}>Status</div>
          <div className={cn(styles.status)}>
            <span
              className={cn(styles.statusActive, styles[item?.status] ?? "")}
            >
              {toTitleCase(item?.status)}
            </span>
          </div>
        </div>
        <div className={styles.col}>
          <span className={styles.createdTag}>Created</span>
          <div className={styles.created}>{dateFormatter(item?.createdOn)}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.amountTag}>Due</div>
          <div className={styles.amount}>{dateFormatter(item?.dueDate)}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.amountTag}>Quote Number</div>
          <div className={styles.amount}>{item?.invoiceNumber}</div>
        </div>
        <div className={styles.col}>
          <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div
              className={cn(styles.dotsBtn, className, {
                [styles.active]: visible,
              })}
            >
              <div className={styles.head}>
                <button
                  className={cn(styles.btn)}
                  onClick={() => setVisible(!visible)}
                >
                  <Icon name={item.icon} />
                </button>
              </div>
              <div className={styles.actionBody}>
                {item.status === "draft" && (
                  <button
                    className={styles.actionItem}
                    onClick={() => setShowFinalizedModal(true)}
                  >
                    <Icon name='save' size='24' />
                    Finalize Quote
                  </button>
                )}

                {item?.status !== "canceled" && item.status !== "accepted" ? (
                  <button
                    onClick={() => setShowCancelModal(true)}
                    className={cn(styles.actionItem, styles.cancelBtn)}
                  >
                    <Icon name='trash' size='24' />
                    <span>Cancel Quote</span>
                  </button>
                ) : null}
                {item.status !== "canceled" && item.status !== "accepted" && (
                  <hr />
                )}
                <CancelModal
                  showCancelModal={showCancelModal}
                  setShowCancelModal={setShowCancelModal}
                  invoiceNow={invoiceNow}
                  setInvoiceNow={setInvoiceNow}
                  title={"Cancel Quote"}
                  cancelLoading={cancelLoading}
                  onConfirm={async () => {
                    setCancelLoading(true);
                    const { data, error, message } = await cancelQuote({
                      id: item?.id,
                    });
                    if (data) {
                      needNewData();
                    } else {
                      toast.error(
                        message || "Error in canceling quote",
                        toastConfiguration
                      );
                    }
                    setCancelLoading(false);
                  }}
                  hideCloseButton
                />
                <CancelModal
                  showCancelModal={showFinalizedModal}
                  setShowCancelModal={setShowFinalizedModal}
                  invoiceNow={invoiceNow}
                  setInvoiceNow={setInvoiceNow}
                  title={"Finalize Quote"}
                  cancelLoading={cancelLoading}
                  onConfirm={async () => {
                    setCancelLoading(true);
                    const { data, error } = await finalizeQuote({
                      id: item?.id,
                    });
                    if (data) {
                      needNewData();
                    } else {
                      toast.error(
                        error || "Error in finalizing quote",
                        toastConfiguration
                      );
                    }
                    setCancelLoading(false);
                  }}
                />
                {item.status == "open" && (
                  <button
                    className={styles.actionItem}
                    onClick={async () => {
                      setAcceptLoading(true);
                      const { data, message } = await acceptQuote({
                        id: item?.id,
                      });
                      if (data && data.invoiceId) {
                        history.push(`/payments/${data.invoiceId}`);
                        toast.success(
                          "Successfully accepted quote",
                          toastConfiguration
                        );
                      } else {
                        toast.error(message, toastConfiguration);
                      }
                      setAcceptLoading(false);
                    }}
                  >
                    <Icon name='multiselect' size='24' />
                    Accept Quote
                    {acceptLoading && (
                      <div
                        style={{
                          marginLeft: 8,
                          marginTop: 8,
                        }}
                      >
                        <Spinner size='24' color='gray' />
                      </div>
                    )}
                  </button>
                )}
                {item.status !== "open" && (
                  <button
                    className={styles.actionItem}
                    onClick={async () => {
                      setDuplicateLoading(true);
                      const { data, message, error } = await duplicateQuote({
                        id: item?.id,
                      });
                      if (data) {
                        history.push(`/quote/${data.quote.id}`);
                        //needNewData()
                      } else {
                        toast.error(
                          message || error || "Error in duplicating quote",
                          toastConfiguration
                        );
                      }
                      setDuplicateLoading(false);
                    }}
                  >
                    <Icon name='multiselect' size='24' />
                    Duplicate Quote
                    {duplicateLoading && (
                      <div
                        style={{
                          marginLeft: 8,
                          marginTop: 8,
                        }}
                      >
                        <Spinner size='24' color='gray' />
                      </div>
                    )}
                  </button>
                )}
                {item?.status == "open" && (
                  <button
                    className={styles.actionItem}
                    onClick={async () => {
                      setReviseLoading(true);
                      const { data, error, message } = await reviseQuote({
                        id: item?.id,
                      });
                      if (data) {
                        history.push(`/quote/${data.quote.id}`);
                        //needNewData()
                      } else {
                        toast.error(
                          message || "Error in revising quote",
                          toastConfiguration
                        );
                      }
                      setReviseLoading(false);
                    }}
                  >
                    <Icon name='repeat' size='24' />
                    Revise Quote
                    {reviseLoading && (
                      <div
                        style={{
                          marginLeft: 8,
                          marginTop: 8,
                        }}
                      >
                        <Spinner size='24' color='gray' />
                      </div>
                    )}
                  </button>
                )}
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </>
  );
};

export default Row;
