import React from "react";
import styles from "../Overview.module.sass";


const List = ({
    title,
    data
}) => {

    return (
        <div className={styles.listContainer}>
            <span className={styles.title}>{title}</span>
            {data?.map((item, index) => {
                return <div className={styles.listItem} key={index}>
                    <span>{item?.name}</span>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: 8 }}>{item?.isNegative ? `-$${item?.amountValue}` : `$${item?.amountValue}`}</div>
                        <img src={item?.flag === "up" ? "/arrowupgreen.svg" : "/arrowdownred.svg"} className={styles.arrowImg} />
                    </div>
                </div>
            })}
        </div>
    );
};

export default List;
