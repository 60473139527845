import React, { useState, useEffect } from "react";
import styles from "./Advanced.module.sass";
import Card from "../../../components/Card";
import cn from "classnames";
import Icon from "../../../components/Icon";
import { getReconcileAccounts, getReconcileTransactions, getTaxRate } from "../../../utils/apiCallHanlder";
import Table from './TaxTable';
import { toTitleCase } from "../../../utils/utils";


const conciledTypes = ["Tax Rates"];

const pageSize = 30;
let lastId = "";
let isFirstLoad = true;

const ChartOfAccounts = ({ className, setVisible }) => {
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);

  const [loading, setLoading] = useState(true);
  const [taxRates, setTaxRates] = useState([]);

  useEffect(async () => {
    await updateTaxRateList();
  }, [])

  const updateTaxRateList = async () => {
    setLoading(true);
    const { data } = await getTaxRate();
    if (data) {
      setTaxRates(data?.taxRates)
    }
    setLoading(false);
  }

  return (
    <Card
      className={cn(styles.card, className)}
      classCardHead={styles.head}
      head={
        <button
          className={cn("button", styles.addBtn)}
          onClick={() => setShowAccountInfo(true)}
        >
          Add new account
        </button>
      }
    >
      <div className={styles.nav}>
        <div className={styles.typeNav}>
          {conciledTypes.map((x, index) => (
            <div
              className={cn(styles.item, {
                [styles.active]: index === activeIndex - 1,
              })}
              key={index}
              onClick={() => {
                setActiveIndex(index + 1);
              }}
            >
              {x}
            </div>
          ))}
        </div>
      </div>

      <Table
        taxRates={taxRates}
        loading={loading}
        updateTaxRateListAfterAdding={updateTaxRateList}
      />
    </Card>
  );
};

export default ChartOfAccounts;
