import React, { useState, useEffect } from "react";
import styles from "./Pricing.module.sass";
import TextInput from "../../../../components/TextInput";
import cn from "classnames";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Checkbox from "../../../../components/Checkbox";
import Tooltip from "../../../../components/Tooltip";
import Card from "../../../../components/Card";
import Dropdown from "../../../../components/Dropdown_c";
import { getCurrencyFromLocalStorage } from "../../../../utils/utils";
// import { durationOption } from "../../EditNewCoupon";


const Pricing = ({
  className,
  productCurrency,
  selectCurrency,
  setSelectCurrency,
  // durationOption,
  durationInMonths,
  setDurationInMonths,
  selectDuration,
  setSelectDuration,
  couponType,
  setCouponType,
  userChoose,
  amountOff,
  setAmountOff,
  percentOff,
  setPercentOff,
  setValueHandler,
  archived,
}) => {

  return (
    <>
      <Card className={cn(styles.card, className)}>
        <div style={{ marginTop: 25 }} onChange={(event) => setValueHandler(event?.target?.value, setCouponType)}>
          <input type="radio" value="amountOff" name="couponType" defaultChecked={couponType === 'amountOff'} /> Amount Off
          <input style={{ marginLeft: 25 }} type="radio" value="percentOff" name="couponType" defaultChecked={couponType === 'percentOff'} /> Percent Off
        </div>
        {couponType === "amountOff" ?
          <div className={styles.row} style={archived ? { pointerEvents: 'none' } : null}>
            <div className={styles.col}>
              <div >
                <div className={styles.priceGroup}>
                  <TextInput
                    style={amountOff === "" || amountOff === null ?
                      { backgroundColor: '#FFBC9925', borderColor: "#FFBC9925", width: 280, marginTop: 10 }
                      : { width: 280, marginTop: 10 }}
                    name="amountOff"
                    value={amountOff}
                    onChange={(event) => setValueHandler(event?.target?.value, setAmountOff)}
                    required
                  />
                  <Dropdown
                    className={styles.dropdown}
                    classDropdownHead={styles.dropdownHead}
                    value={selectCurrency}
                    setValue={setSelectCurrency}
                    options={productCurrency}
                  />
                </div>
              </div>
            </div>
          </div> : ''}
        {couponType === "percentOff" ?
          <div className={styles.row}>
            <div className={styles.col} >
              <div className={styles.field} >
                <TextInput
                  style={percentOff === "" || percentOff === null ?
                    { backgroundColor: '#FFBC9925', borderColor: "#FFBC9925", width: 280, marginTop: 10 }
                    : { width: 280, marginTop: 10 }}
                  name="percentOff"
                  onChange={(event) => setValueHandler(event?.target?.value, setPercentOff)}
                  value={percentOff}
                  type="number"
                  required
                />
              </div>
            </div>
          </div> : ''}

      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Pricing;
