import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Item from "./Item";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown_account";
import DropdownSimple from "../../../components/Dropdown/index";
import Chart from "./Chart";
import Icon from "../../../components/Icon";
import DateRangeInput from "../../../components/DateRangeInput";
import Spinner from "../../../utils/spinner";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { GetDashboardInitialData } from "../../../utils/apiCallHanlder";
import { transactionDateFormatter } from "../../../utils/utils";
import LargeNav from "../../../components/LargeNav";

const intervals = ["Today", "Week", "Month", "3 Months", "Year"];
const todaysDate = new Date();

const intervalsValues = {
  Today: {
    end: todaysDate,
    start: todaysDate,
  },
  Week: {
    end: todaysDate,
    start: new Date(new Date().setDate(new Date().getDate() - 7)),
  },
  Month: {
    end: todaysDate,
    start: new Date(new Date().setDate(new Date().getDate() - 30)),
  },
  "3 Months": {
    end: todaysDate,
    start: new Date(new Date().setDate(new Date().getDate() - 90)),
  },
  Year: {
    end: todaysDate,
    start: new Date(new Date().setDate(new Date().getDate() - 365)),
  },
};

const Overview = ({
  className,
  accounts,
  transactions,
  loadingForDetailData,
  graph,
  setGraph,
  onDateRangeChanged,
  defCurrencyCode,
}) => {
  const targetRef = useRef();
  const [selectedAccountId, setSelectedAccountId] = useState(accounts[0]?.id);
  const [fetchingGraphData, setFetchingGraphData] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState([accounts[0]?.id]);
  const [activeDateTab, setActiveDateTab] = useState("This Year");
  const [dateQuickPickTitles, setDateQuickPickTitles] = useState([]);

  const allAccountsId = "All";
  const [carouselWidth, setCarouselWidth] = useState(0);

  useLayoutEffect(() => {
    var carouselWrapper = document.getElementById("carouselWrapper");
    setCarouselWidth(carouselWrapper.offsetWidth);
  });

  useEffect(() => {
    var carouselWrapper = document.getElementById("carouselWrapper");
    const handleResize = () => {
      setCarouselWidth(carouselWrapper.offsetWidth);
    };
    if (carouselWrapper) {
      window.addEventListener("resize", handleResize);
    }
  });

  const responsive = {
    sizeOne: {
      breakpoint: { max: 8000, min: 0 },
      items: carouselWidth / 208,
    },
  };

  const dateQuickPick = [
    // {
    //   title: "Week",
    //   from: function () {
    //     let todayDt = new Date();
    //     var first = todayDt.getDate() - todayDt.getDay() + 1;
    //     var last = first + 6;
    //     var firstday = new Date(todayDt.setDate(first));
    //     firstday.setHours(0, 0, 0, 0);
    //     return firstday;
    //   },
    //   to: function () {
    //     let todayDt = new Date();
    //     var first = todayDt.getDate() - todayDt.getDay() + 1;
    //     var last = first + 6;
    //     var lastday = new Date(todayDt.setDate(last));
    //     lastday.setHours(23, 59, 59, 999);
    //     return lastday;
    //   }
    // },
    {
      title: "All Time",
      from: function () {
        let todayDt = new Date("01/01/2020");
        return todayDt;
      },
      to: function () {
        let todayDt = new Date();
        todayDt.setHours(23, 59, 59, 999);
        return todayDt;
      },
    },
    {
      title: "This Quarter",
      from: function () {
        let todayDt = new Date();
        const quarter = Math.floor((todayDt.getMonth() / 3));
        return new Date(todayDt.getFullYear(), quarter * 3, 1);
      },
      to: function () {
        let todayDt = new Date();
        const quarter = Math.floor((todayDt.getMonth() / 3));
        const startFullQuarter = new Date(todayDt.getFullYear(), quarter * 3, 1);
        return new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
      },
    },
    {
      title: "Previous Quarter",
      from: function () {
        let todayDt = new Date();
        const quarter = Math.floor((todayDt.getMonth() / 3));
        return new Date(todayDt.getFullYear(), quarter * 3 - 3, 1)
      },
      to: function () {
        let todayDt = new Date();
        const quarter = Math.floor((todayDt.getMonth() / 3));
        const startFullQuarter = new Date(todayDt.getFullYear(), quarter * 3 - 3, 1);
        return new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
      },
    },
    {
      title: "This Year",
      from: function () {
        return new Date(new Date().getFullYear(), 0, 1);
      },
      to: function () {
        return new Date(new Date().getFullYear(), 11, 31);
      },
    },
    {
      title: "Previous Year",
      from: function () {
        return new Date(new Date().getFullYear() - 1, 0, 1);
      },
      to: function () {
        return new Date(new Date().getFullYear() - 1, 11, 31);
      },
    }
  ];

  useEffect(() => {
    let dtQuickPics = [];
    for (let index = 0; index < dateQuickPick.length; index++) {
      const element = dateQuickPick[index];
      dtQuickPics.push(element.title);
    }
    setDateQuickPickTitles(dtQuickPics);
  }, []);

  useEffect(() => {
    let isTakeAllTransactions = selectedAccounts.includes(allAccountsId);

    var balanceAtTheMoment = 0;
    for (let index = 0; index < accounts.length; ++index) {
      const accountInfo = accounts[index];
      if (selectedAccounts.includes(accountInfo.id)) {
        balanceAtTheMoment += accountInfo.displayBalance;
      }
    }

    // calculate pending Stripebalance transactions as already on balance
    for (let index = 0; index < transactions.length; index++) {
      const transaction = transactions[index];
      if (
        transaction.accountId === "stripebalance" &&
        transaction.status === "pending"
      ) {
        balanceAtTheMoment += transaction.net;
      }
    }

    var newGraph = [];
    let lastItem = {
      name: getGraphDateFormating(new Date()),
      balance: balanceAtTheMoment / 100,
    };

    var lastDate = "";
    var prevGraphItem = null;
    for (let index = 0; index < transactions.length; index++) {
      const transaction = transactions[index];
      if (!isTakeAllTransactions) {
        let isAccountIdSelected = selectedAccounts.includes(
          transaction.accountId
        );
        let isCardTransaction = transaction.transactionOriginType === "card";
        if (
          !isAccountIdSelected &&
          !(
            isCardTransaction &&
            selectedAccounts.includes(transaction.cardFinancialAccount)
          )
        ) {
          continue;
        }
      }
      let fee = transaction.fee ?? 0;
      let transDate = new Date(transaction.created);
      transDate.setDate(transDate.getDate() - 1);
      let transDateFormated = getGraphDateFormating(transDate);

      if (transDateFormated === lastDate) {
        if (transaction.amountValue > 0) {
          prevGraphItem.balance =
            prevGraphItem.balance - transaction.amountValue + fee;
        } else {
          prevGraphItem.balance += transaction.amountValue * -1;
        }
        balanceAtTheMoment = prevGraphItem.balance;
        continue;
      }
      lastDate = transDateFormated;
      var newGraphItem = {
        name: transDateFormated,
        balance:
          transaction.amountValue < 0
            ? balanceAtTheMoment + transaction.amountValue * -1
            : balanceAtTheMoment - transaction.amountValue + fee,
      };
      balanceAtTheMoment = newGraphItem.balance;
      newGraph.push(newGraphItem);
      prevGraphItem = newGraphItem;
    }
    newGraph.reverse();
    for (let index = 0; index < newGraph.length; index++) {
      const g = newGraph[index];
      if (g.balance != 0) g.balance = g.balance / 100;
    }
    newGraph.push(lastItem);

    let dateIdx = -1;
    for (let index = 0; index < dateQuickPick.length; index++) {
      const element = dateQuickPick[index];
      if (activeDateTab === element.title) {
        dateIdx = index;
        break;
      }
    }

    newGraph = fillMissedDates(
      newGraph,
      dateQuickPick[dateIdx].from(),
      dateQuickPick[dateIdx].to()
    );

    setGraph(newGraph);

    setFetchingGraphData(false);
  }, [selectedAccounts, transactions]);

  const fillMissedDates = (newGraph, startDt, endDt) => {
    let myGraph = [];
    var firstTransactionStarted = false;
    var lastFoundIdx = -1;
    var previousFoundIdx = -1;
    var lastValue = 0;

    for (var d = new Date(endDt); d > startDt; d.setDate(d.getDate() - 1)) {
      let formattedDate = getGraphDateFormating(d);
      lastFoundIdx = graphContainsDate(newGraph, formattedDate);
      lastValue =
        previousFoundIdx >= 0 ? newGraph[previousFoundIdx].balance : -1;

      if (!firstTransactionStarted) {
        firstTransactionStarted = lastFoundIdx >= 0;
      } else {
        if (lastFoundIdx < 0 && previousFoundIdx >= 0) {
          newGraph.splice(previousFoundIdx, 0, {
            name: formattedDate,
            balance: lastValue,
          });
        }
      }

      previousFoundIdx = lastFoundIdx >= 0 ? lastFoundIdx : previousFoundIdx;
    }

    for (var d2 = new Date(endDt); d2 > startDt; d2.setDate(d2.getDate() - 1)) {
      let formattedDate = getGraphDateFormating(d2);
      lastFoundIdx = graphContainsDate(newGraph, formattedDate);
      if (previousFoundIdx >= 0) {
        myGraph.push(newGraph[lastFoundIdx]);
      }
    }
    return myGraph.reverse();
  };

  const graphContainsDate = (newGraph, formattedDate) => {
    var idx = -1;
    var counter = 0;
    for (const element of newGraph) {
      if (element.name === formattedDate) {
        idx = counter;
        break;
      }
      counter++;
    }
    return idx;
  };

  const getGraphDateFormating = (dt) => {
    let monthPart = dt.toLocaleDateString({}, { month: "short" });
    let dayPart = dt.toLocaleDateString({}, { day: "2-digit" });
    let resStr = dayPart + "-" + monthPart;
    return resStr;
  };

  const dateRangeSelected = (selectedTitle) => {
    setFetchingGraphData(true);
    setActiveDateTab(selectedTitle.title);
    let from = selectedTitle.from();
    let to = selectedTitle.to();
    onDateRangeChanged(from, to, allAccountsId);
  };

  const getUpdatedDashBoardDataAccountChanged = async (start, end, id) => {
    //onAccountChanged(startDate, endDate, id);
  };

  const getUpdatedDashBoardDataDateChanged = async (start, end, id) => {
    onDateRangeChanged(start, end, selectedAccountId);
  };

  const switchActiveAccount = (switchAccId) => {
    setFetchingGraphData(true);
    let allAlreadySelected = selectedAccounts.includes(allAccountsId);
    var selectedNew = selectedAccounts.slice(0);
    if (switchAccId.id === allAccountsId) {
      if (!allAlreadySelected) selectedNew = [];
    } else {
      if (allAlreadySelected) {
        selectedNew = selectedAccounts.filter(function (item) {
          return item !== allAccountsId;
        });
      }
    }

    if (selectedNew.includes(switchAccId.id)) {
      setSelectedAccounts(
        selectedNew.filter(function (item) {
          return item !== switchAccId.id;
        })
      );
    } else {
      setSelectedAccounts([...selectedNew, ...[switchAccId.id]]);
    }
  };

  return (
    <div>
      <Card
        className={cn(styles.card, className)}
        title={"Cash on hand"}
        id='Overview'
        isDashboard={true}
        classCardHead={styles.cardHead}
        comingFromDashboard={true}
        border
      >
        <div className={styles.overview}>
          <div
            id='carouselWrapper'
            ref={targetRef}
            style={{ marginBottom: "24px" }}
          >
            <Carousel
              responsive={responsive}
              autoPlay={false}
              showDots={false}
              arrows={false}
              containerClass={styles.carouselContainer}
              partialVisible={true}
            >
              {accounts.map((x, index) => {
                return (
                  <Item
                    className={cn(
                      styles.item,
                      {
                        [styles.active]: selectedAccounts.includes(x.id),
                      },
                      {
                        [styles["activeItem" + index]]:
                          selectedAccounts.includes(x.id),
                      }
                    )}
                    style={{
                      border: selectedAccounts?.includes(x?.id) ? 'unset' : '2px solid #6F767E',
                    }}
                    onActive={() => {
                      switchActiveAccount(x);
                    }}
                    key={index}
                    item={{
                      title: x.name,
                      accountDigit: x?.accountNumberLast4,
                      counter: x.displayBalance ?? "0",
                    }}
                    currency={defCurrencyCode}
                    isActive={selectedAccounts.includes(x.id)}
                  />
                );
              })}
            </Carousel>
          </div>
          <div className={styles.body}>
            {loadingForDetailData ? (
              <div style={{ marginTop: 10, marginLeft: 10 }}>
                <Spinner size='24px' color='gray' />
              </div>
            ) : (
              <>
                <LargeNav
                  tabOptions={dateQuickPick}
                  activeTab={activeDateTab}
                  onClick={(x) => {
                    dateRangeSelected(x);
                  }}
                  value='title'
                  category='title'
                  onlyLarge
                />
              </>
            )}
            {fetchingGraphData ? (
              <div
                style={{
                  position: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spinner loading={fetchingGraphData} size='44px' color='gray' />
              </div>
            ) : (
              <Chart data={graph} />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Overview;
