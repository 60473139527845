import Icon from '../../../../../components/Icon';
import styles from '../../../../Customers/CustomersOverview/Table/Row/Row.module.sass';

const Row = ({
    item,
    deleteModal
}) => {
    return (
        <>
            <div
                className={styles.col}
            >
                <Icon name="drag" size="25" viewBox="0 0 25 25" />
            </div>
            <div className={styles.col} style={{ width: 300 }}>
                <span className={styles.mblCreate}>Name:</span>
                <div className={styles.user}>
                    <span>{item?.title}</span>
                </div>
            </div>
            <div className={styles.col}>
                <span className={styles.mblCreate}>Tasks:</span>
                <div className={styles.date}>{`${item?.tasks || 0}`}</div>
            </div>
            <div className={styles.col} style={{ textAlign: 'end' }} onClick={(e) => e.stopPropagation()}>
                {deleteModal}
            </div>
        </>
    )
}

export default Row;