import React from "react";
import classnames from "classnames";
import styles from "./AlternativeAvatar.module.sass";
import { getAlternativeIconText, getRandomNumberForColor } from "../../utils/utils";


const AlternativeAvatar = ({ name, className, hideBorderRadius }) => {
  return (
    <div
      className={classnames(styles[`avatar-avt-${getRandomNumberForColor(name)}`], {
        [styles['avatar-avt-big-icon']]: className?.split(' ').includes('big-icon'),
        [styles['avatar-avt-invoice']]: className?.split(' ').includes('invoice'),
        [styles['avatar-avt-customer-info']]: className?.split(' ').includes('customer-info'),
      })}
      style={{
        borderRadius: hideBorderRadius ? 'unset' : '50%',
        height: hideBorderRadius ? '40px' : '48px',
        width: hideBorderRadius ? '40px' : '48px'
      }}
    >
      <span>{getAlternativeIconText(name)}</span>
    </div>
  );
};

export default AlternativeAvatar;