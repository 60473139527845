import React from "react";
import Card from "../../../components/Card";
import cn from "classnames";
import styles from "./NoProduct.module.sass";
import Image from "../../../components/Image";

const NoProduct = ({onConfirm, noProductText}) => {
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Card className={styles.card}>
            <Image
              className={styles.pic}
              src="/images/icons/empty-black.png"
              srcDark="/images/icons/empty-white.png"
              alt="Core"
            />
            {/* <div className={styles.txtWrap}>
              {noProductText || <h3>You don't have any registered products</h3>}
              <p>No time for coffee, grow your business now</p>
              <button onClick={onConfirm} className={cn("button", styles.button)}>
                  Create a product
              </button>
            </div> */}
          </Card>
        </div>
      </div>
    </>
  );
};

export default NoProduct;
