import { useEffect } from "react";
import { handleLoginSignUpResponse, logoutHandler } from "../../utils/utils";
import { useHistory } from 'react-router-dom';
import { loginWithToken } from "../../utils/apiCallHanlder";

const Forward = () => {

  const history = useHistory();


  useEffect(async () => {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token");
    const doSignup = params.get("doSignup");
    const isGoogleSignUp = params.get("AuthType");

    if (token) {
      const { code, data, error } = await loginWithToken(token);
      if (code === 200 && data) {
        const customHistory = { push: (path) => { } };
        logoutHandler(customHistory);

        localStorage.setItem("stripeConnection", data.isConnected);
        localStorage.setItem("isPending", data.isPending);
        localStorage.setItem("isTestMode", data.istestmode);

        localStorage.setItem("connectStatus", JSON.stringify(data?.connectStatus));
        localStorage.setItem("currencySymbol", data.currencySymbol || "$");
        localStorage.setItem("currency", data.currency || 'USD');
        localStorage.setItem("isGoogleSignUp", isGoogleSignUp);
        handleLoginSignUpResponse(data, history, doSignup);
        return;
      }
    }
    history.push('/');
  }, [])

  return (
    <div>
    </div>
  );
};

export default Forward;
