import React, { useState } from "react";
import styles from "./NewSubscriptions.module.sass";
import Card from "../../../../components/Card";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import QuotesOverview from "../QuotesOverview";
import SubscriptionsCustomer from "./SubscriptionsCustomer";
import SubscriptionsSummary from "./SubscriptionsSummary";
import SaveSuccess from "./Modals/SaveSuccess";
import CancelModal from "./Modals/CancelModal";
import {
  base64toBlob,
  toastConfiguration,
  toTitleCase,
  trackIntercomEvent,
  transactionDateFormatter,
} from "../../../../utils/utils";
import { toast, ToastContainer } from "react-toastify";
import {
  acceptQuote,
  addSubscription,
  cancelQuote,
  cancelSubscription,
  getCustomer,
  GetPaymentMethods,
  getQuote,
  getSubscription,
  pdfQuote,
} from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { useHistory } from "react-router";
import { useEffect } from "react";
import QuotePreview from "./QuotePreview";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

let paymentMethodsToSend = ["ach_credit_transfer", "card", "us_bank_account"];

const NewSubscriptions = ({ id, customerId }) => {
  const [visible, setVisible] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showActionBtn, setShowActionBtn] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [loadingDownloadPdf, setLoadingDownloadPdf] = useState(false);
  const [loadingAcceptQuote, setLoadingAcceptQuote] = useState(false);
  const [showNewProductModal, setShowNewProductModal] = useState(false);

  const [selectedCustomerObject, setSelectedCustomerObject] = useState({});
  const [lines, setLines] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [activeCardPaymentType, setActiveCardPaymentType] = useState(true);
  const [activeAchPaymentType, setActiveAchPaymentType] = useState(true);
  const [billingCycle, setBillingCycle] = useState(new Date());
  const [memo, setMemo] = useState("");

  const [addSubscriptionLoading, setAddSubscriptionLoading] = useState(false);
  const [subscriptionResponse, setSubscriptionResponse] = useState({});

  const [subscription, setSubscription] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [startLoading, setStartLoading] = useState(true);

  const [todayDate, setTodayDate] = useState(true);
  const [dateSelector, setDateSelector] = useState(false);

  const [foreverDate, setForeverDate] = useState(true);
  const [endDateSelector, setEndDateSelector] = useState(false);

  const [invoiceNow, setInvoiceNow] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [dbTaxRates, setDbTaxRates] = useState([]);
  const [customerTaxExempt, setCustomerTaxExempt] = useState("");

  const [lineId, setLineId] = useState(0);

  const history = useHistory();

  const intervals = ["Today", "10 Days", "1 Month"];

  const [durations, setDurations] = useState(intervals[0]);

  useEffect(() => {
    if (durations === "Today") {
      setBillingCycle(new Date(startDate));
    } else if (durations === "10 Days") {
      setBillingCycle(
        new Date(new Date().setDate(new Date(startDate).getDate() + 10))
      );
    } else if (durations === "1 Month") {
      setBillingCycle(
        new Date(new Date().setDate(new Date(startDate).getDate() + 30))
      );
    }
  }, [startDate, durations]);

  useEffect(async () => {
    if (id) {
      await getSingleQuoteById();
    } else {
      setStartLoading(false);
    }
  }, [id]);

  const getSingleQuoteById = async () => {
    setStartLoading(true);
    const { data } = await getQuote(id);
    if (data) {
      const quoteRes = data?.quote;
      setSubscription(data?.quote);
      setDbTaxRates(quoteRes?.taxRates);
      setSelectedCustomerObject({
        id: quoteRes.customerId,
        name: quoteRes?.customerName,
        email: quoteRes?.customerEmail,
        imageUrl: quoteRes?.customerImage,
        phone: quoteRes?.customerPhone,
      });
      setCustomerTaxExempt(quoteRes?.customerTaxExempt);
      setShowSummary(true);
      setShowDetails(true);
      setShowActionBtn(true);
      setLines(quoteRes.lines);
      if (quoteRes?.lines?.length > 0) {
        setShowProduct(true);
      }
      setMemo(quoteRes?.description);
    }
    setStartLoading(false);
  };

  const schedulePaymentClickHandler = async () => {
    setAddSubscriptionLoading(true);

    //const paymentTypes = [];
    // if (activeAchPaymentType) {
    //   paymentTypes.push("ach_credit_transfer")
    // }
    // if (activeCardPaymentType) {
    //   paymentTypes.push("card")
    // }

    let doesHaveRecurring = false;
    let recurringTypes = [];
    for (let i = 0; i < lines?.length; i++) {
      if (lines[i].priceTypeValue === "recurring") {
        doesHaveRecurring = true;
        recurringTypes.push(lines[i]?.recurringType);
      }
    }

    const todaysDate = new Date();

    if (!doesHaveRecurring) {
      toast.error(
        "Atleast one product should have recurring type.",
        toastConfiguration
      );
      //} else if (lines.length > 1 && !recurringTypes.every((val, arr) => val === arr[0])) {
      //toast.error("Products with two different recurring type identified.", toastConfiguration);
    } else if (new Date(endDate).getTime() <= todaysDate.getTime() && endDate) {
      toast.error(
        "End date can't be less than today's date",
        toastConfiguration
      );
    } else if (paymentMethodsToSend?.length < 1) {
      toast.error("Select atleast one payment type", toastConfiguration);
    } else {
      const payload = {
        customerId: selectedCustomerObject?.id,
        customerImage: selectedCustomerObject?.imageUrl,
        customerMobile: selectedCustomerObject?.mobile,
        memo: memo,
        startDate: transactionDateFormatter(startDate),
        startBillingOn: transactionDateFormatter(billingCycle),
        paymentTypes: paymentMethodsToSend,
        lineItems: lines,
      };

      if (endDate) payload["endDate"] = transactionDateFormatter(endDate);

      const { data, error } = await addSubscription(payload);
      if (data) {
        setSubscriptionResponse(data?.subscription);
        setShowSuccessModal(true);
        trackIntercomEvent("subscription-created");
      } else {
        toast.error(
          error || "Error in creating subscription",
          toastConfiguration
        );
      }
    }
    setAddSubscriptionLoading(false);
  };
  const getDownloadPdfName = () => {
    if (subscription?.invoiceNumber) {
      return subscription?.invoiceNumber?.replaceAll("-", "_") + ".pdf";
    } else {
      return "quote.pdf";
    }
  };
  const printDocument = async (returnPdf) => {
    const input = document.getElementById("divToPrint");
    const card = document.getElementById("card-1");

    const text1 = document.getElementById("text-1");
    const text2 = document.getElementById("text-2");
    const text3 = document.getElementById("text-3");

    const image = document.getElementById("img-1");

    const prevContainerBgColor = input?.style?.background;
    const prevInnerContainerBgColor = card?.style?.background;
    const prevTextColor = text1?.style?.color;
    const prevImageSrc = image?.src;

    if (input && card) {
      input.style.size = "8.3in 11.7in";
      input.style.padding = "17mm 16mm 27mm 16mm";
      input.style.background = "#FFFFFF";
      card.style.background = "#FFFFFF";
      if (prevTextColor !== "#1A1D1F") {
        if (text1) text1.style.color = "#1A1D1F";

        if (text2) text2.style.color = "#1A1D1F";

        if (text3) text3.style.color = "#1A1D1F";
      }
      if (image) image.src = "/images/logo-dark.png";
    }

    const canvas = await html2canvas(input, {
      scale: 3,
    });
    if (canvas) {
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("p", "em", [60, 70]); //[595.28, 841.89]

      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, width, height, "", "FAST");
      if (returnPdf) {
        return pdf;
      } else {
        pdf.save(getDownloadPdfName());
      }
    }

    if (input) {
      input.style.size = "unset";
      input.style.padding = "unset";
      input.style.background = prevContainerBgColor;
      card.style.background = prevInnerContainerBgColor;
      if (prevTextColor !== "#1A1D1F") {
        if (text1) text1.style.color = prevTextColor;

        if (text2) text2.style.color = prevTextColor;

        if (text3) text3.style.color = prevTextColor;
      }
      if (prevImageSrc && image) image.src = prevImageSrc;
    }
  };

  const printDocumentHelper = async (fromServer) => {
    if (
      subscription?.status == "open" ||
      subscription?.status == "accepted" ||
      fromServer
    ) {
      const { data } = await pdfQuote({
        id: subscription.id,
      });
      if (data) {
        const blob = base64toBlob(data.attachment.data, "pdf");
        const blobUrl = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = (subscription?.invoiceNumber || "quote") + ".pdf";
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        toast.success("Successfully downloaded pdf", toastConfiguration);
      } else {
        toast.error("Error in dowloading pdf", toastConfiguration);
      }
    } else {
      var pdf = await printDocument(false);
      return pdf;
    }
  };

  const actionBtns = (showActionBtn ? (
    showNewProductModal ? null : (
      <>
        {subscription?.status !== "canceled" && (
          <>
            {subscription?.status == "open" ? (
              <button
                onClick={async () => {
                  setLoadingAcceptQuote(true);
                  const { data, message } = await acceptQuote({
                    id: subscription.id,
                  });
                  if (data && data.invoiceId) {
                    history.push(`/payments/${data.invoiceId}`);
                    toast.success(
                      "Successfully accepted quote",
                      toastConfiguration
                    );
                  } else {
                    toast.error(message, toastConfiguration);
                  }
                  setLoadingAcceptQuote(false);
                }}
                className={cn("button-stroke-red", styles.cancelBtn)}
              >
                {loadingAcceptQuote ? (
                  <Spinner size="24" color="gray" />
                ) : (
                  <span>Accept Quote</span>
                )}
              </button>
            ) : null}

            <button
              onClick={async () => {
                setLoadingDownloadPdf(true);
                await printDocumentHelper();
                setLoadingDownloadPdf(false);
              }}
              className={cn("button-stroke-red", styles.cancelBtn)}
            >
              {loadingDownloadPdf ? (
                <Spinner size="24" color="gray" />
              ) : (
                <span>Download Pdf</span>
              )}
            </button>

            {id && subscription.status !== "accepted" ? (
              <button
                onClick={async () => {
                  setCancelLoading(true);
                  const { data } = await cancelQuote({
                    id: subscription?.id,
                  });
                  if (data) {
                    await getSingleQuoteById();
                  } else {
                    toast.error("Error in canceling quote", toastConfiguration);
                  }
                  setCancelLoading(false);
                }}
                className={cn("button-stroke-red", styles.cancelBtn)}
              >
                <span>Cancel Quote</span>
              </button>
            ) : null}

            {/* <CancelModal
                showCancelModal={showCancelModal}
                setShowCancelModal={setShowCancelModal}
                invoiceNow={invoiceNow}
                setInvoiceNow={setInvoiceNow}
                cancelLoading={cancelLoading}
                title={"Cancel Quote"}
                onConfirm={async () => {
                  setCancelLoading(true);
                  const { data } = await cancelQuote({
                    id: subscription?.id
                  });
                  if (data) {
                    await getSingleQuoteById();
                  } else {
                    toast.error("Error in canceling quote", toastConfiguration);
                  }
                  setCancelLoading(false);
                }}
              /> */}
          </>
        )}
        <SaveSuccess
          showSuccessModal={showSuccessModal}
          setShowSuccessModal={setShowSuccessModal}
          subscriptionResponse={subscription}
          downloadPdfHandler={async (fromServer) => {
            printDocumentHelper(fromServer);
          }}
          onConfirm={() => {
            history.push("/quotes");
          }}
        />
      </>
    )
  ) : null);

  const backBtn = (
    <button
      style={{pointerEvents: "auto"}}
      onClick={() => {
        history.push("/quotes");
      }}
      className={cn("button-stroke-red", styles.cancelBtn)}
    >
      <span>Cancel</span>
    </button>
  );

  return (
    <>
      {visible ? (
        <>
          {startLoading ? (
            <div
              className={styles.spinner}
              style={{ height: window.outerHeight - 450 }}
            >
              <Spinner loading={startLoading} size={150} color={"gray"} />
            </div>
          ) : (
            <div
              className={styles.row}
              style={
                subscription?.status === "canceled" ||
                subscription.status === "open" ||
                subscription.status === "accepted"
                  ? { pointerEvents: "none" }
                  : {}
              }
            >
              <div
                className={showNewProductModal ? styles.fullWidth : styles.col}
              >
                <SubscriptionsCustomer
                  setShowActionBtn={setShowActionBtn}
                  dbTaxRates={dbTaxRates}
                  setShowSummary={setShowSummary}
                  selectedCustomerObject={selectedCustomerObject}
                  setSelectedCustomerObject={setSelectedCustomerObject}
                  lines={lines}
                  setLines={setLines}
                  startDate={startDate}
                  paymentMethods={paymentMethods}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  durations={durations}
                  setDurations={setDurations}
                  showDetails={showDetails}
                  paymentMethodsToSend={paymentMethodsToSend}
                  lineId={lineId}
                  setLineId={setLineId}
                  setShowDetails={setShowDetails}
                  activeCardPaymentType={activeCardPaymentType}
                  activeAchPaymentType={activeAchPaymentType}
                  setActiveAchPaymentType={setActiveAchPaymentType}
                  setActiveCardPaymentType={setActiveCardPaymentType}
                  intervals={intervals}
                  memo={memo}
                  subscription={subscription}
                  setSubscription={setSubscription}
                  setMemo={setMemo}
                  showProduct={showProduct}
                  setShowProduct={setShowProduct}
                  id={id}
                  schedulePaymentClickHandler={schedulePaymentClickHandler}
                  addSubscriptionLoading={addSubscriptionLoading}
                  todayDate={todayDate}
                  setTodayDate={setTodayDate}
                  dateSelector={dateSelector}
                  setDateSelector={setDateSelector}
                  foreverDate={foreverDate}
                  setForeverDate={setForeverDate}
                  customerTaxExempt={customerTaxExempt}
                  endDateSelector={endDateSelector}
                  setEndDateSelector={setEndDateSelector}
                  setShowSuccessModal={setShowSuccessModal}
                  showNewProductModal={showNewProductModal}
                  setShowNewProductModal={setShowNewProductModal}
                  actionBtns={actionBtns}
                  backBtn={backBtn}
                />
              </div>
              <div
                className={styles.colX}
                style={showNewProductModal ? { display: "none" } : {}}
              >
                {Object.keys(subscription).length > 0 && (
                  <QuotePreview
                    className={styles.preview}
                    invoiceObject={subscription}
                  />
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <QuotesOverview />
      )}
    </>
  );
};

export default NewSubscriptions;
