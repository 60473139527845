import React from "react";
import Card from "../../../../components/Card";
import Icon from "../../../../components/Icon";
import Modal from "../../../../components/Modal";
import styles from "./AccountInfo.module.sass";
import TextInput from "../../../../components/TextInput";

const AccountInfo = ({visible, setVisible}) => {
  return (
    <div>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        outerClassName={styles.modal}
        children={
          <Card
            /* classTitle="title-blue" */
            title="Add new account"
            className={styles.card}
            head={
              <button className="button"
              onClick={() => setVisible(false)}
              >
                Save
              </button>
            }
          >
            <div className={styles.row}>
              <div className={styles.col}>
                <TextInput 
                    label="Number"
                    placeholder="1078"
                />
              </div>
              <div className={styles.col}>
                <TextInput
                    label="Account Name"
                    placeholder="Checking Account - ABC Bank"
                 />
              </div>
              <div className={styles.col}>
                <TextInput 
                    label="Type"
                    placeholder="Expense"
                />
              </div>
              <div className={styles.col}>
                <TextInput
                    label="Details Type"
                    placeholder="Prepaid Expense"
                 />
              </div>
              <div className={styles.col}>
                <TextInput
                    label="Description"
                    placeholder="Cash Management"
                 />
              </div>
            </div>
          </Card>
        }
      />
    </div>
  );
};

export default AccountInfo;
