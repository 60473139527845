import React from "react";
import ReactTooltip from "react-tooltip";
import cn from "classnames";
import styles from "./Tooltip.module.sass";
import Icon from "../Icon";

const Tooltip = ({ className, title, icon, place  }) => {
  return (
    <>
      <a className={cn(styles.tooltip, className)} data-tip={title} data-event="click focus">
        <Icon name={icon} />
      </a>
      <ReactTooltip globalEventOff="click" multiline={true} className={styles.mainToolTip} place={place}/>
    </>
  );
};

export default Tooltip;
