import React, { useState } from "react";
import styles from "../../../../components/Search/Search.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import { searchCOAList } from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { useHistory } from "react-router";
import { useEffect } from "react";

let typingTimer;
let doneTypingInterval = 600;
let count = 0;

const Search = ({
  className,
  inputPlaceholder,
  showDetails,
  accountName
}) => {
  const [visible, setVisible] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(false);

  const [searchResponse, setSearchResponse] = useState([]);
  const [currentlySelectedName, setCurrentlySelectedName] = useState(accountName ? accountName : "");
  const router = useHistory();

  useEffect(() => {
    setCurrentlySelectedName(accountName);
  }, [accountName])

  // useEffect(async () => {
  //   await getSearchResult("");
  // }, [])

  const getSearchResult = async () => {
    setLoading(true);
    const { data } = await searchCOAList(searchString);
    if (data) {
      count = 0;
      const list = data?.list;
      for (let i = 0; i < list?.length; i++) {
        count = count + list[0]?.children?.length;
      }
      setSearchResponse(list);
    }
    setLoading(false);
  }

  return (
    <>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
      >
        <div className={styles.head}>
          <button className={styles.start}>
            <Icon name="search" size="24" />
          </button>
          <button className={styles.direction} onClick={() => setVisible(false)}>
            <Icon name="arrow-left" size="24" />
          </button>
          <input
            className={styles.input}
            type="text"
            placeholder={inputPlaceholder}
            onClick={async () => {
              setVisible(true)
              if(!searchString){
                await getSearchResult("")
              }
            }}
            value={searchString || currentlySelectedName}
            onChange={(e) => {
              setSearchString(e.target.value);
              setCurrentlySelectedName("");
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                getSearchResult();
              } else {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(getSearchResult, doneTypingInterval);
              }
            }}
            onKeyDown={() => {
              clearTimeout(typingTimer);
            }}
          />
          <button className={styles.close} onClick={() => {
            setVisible(false);
            setCurrentlySelectedName("");
            showDetails(null)
            setSearchString("");
            setSearchResponse([]);
          }}>
            {loading ?
              <Spinner loading={loading} size={24} color="gray" />
              :
              <Icon name="close-circle" size="24" />
            }
          </button>
        </div>
        <div className={styles.body}>
          {searchResponse?.length > 0 ?
            <div style={count > 2 ? { height: 260, overflowY: 'scroll' } : {}}>
              {searchResponse?.map((item, index) => {
                return <div key={index} >
                  <div className={styles.detailsTxt}>{item?.name}</div>
                  <div >
                    {item?.children.map((x, i) => (
                      <div key={i}>
                        <button onClick={() => {
                          setSearchString("")
                          setCurrentlySelectedName(x?.name);
                          showDetails(x)
                          setVisible(false);
                        }} className={styles.customerBtn}>
                          {x.name}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              })}
            </div>
            :
            searchString === "" ?
              <label className={styles.customerBtn} style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                Type to search account
              </label>
              :
              <label className={styles.customerBtn} style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                No data to show, search another name
              </label>
          }
        </div>
      </div>
    </>
  );
};

export default Search;
