/* global Stripe */
import React, { useEffect, useState } from "react";
import styles from "./LinkedAccounts.module.sass";
import Card from "../../../components/Card";
import cn from "classnames";
import Icon from "../../../components/Icon";
import { toast, ToastContainer } from 'react-toastify';
import { toastConfiguration } from "../../../utils/utils";
import { createLinkAccountSession, RefreshLinkAccountData, getCardDetails, GetLinkAccountData } from "../../../utils/apiCallHanlder";
import NewLinkedAccounts from "./NewLinkedAccounts";
import Spinner from "../../../utils/spinner";
import SingleAccount from "./singleAccount";


const LinkedAccounts = ({ className, setAddLinkedAccounts }) => {
  const [visible, setVisible] = useState(true);
  const [showLinkedAccounts, setShowLinkedAccounts] = useState(false);
  const [linkedAccountLoading, setLinkedAccountLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [linkAccounts, setLinkAccounts] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    const { data } = await getCardDetails({
      "onlyAccount": true
    });
    if (data) {
      setLinkAccounts(data?.linkAccounts);
    }

    setLoading(false);
  }, [])

  useEffect(async () => {
    for (let i = 0; i < linkAccounts?.length; i++) {
      if (linkAccounts[i].isBalanceRefreshInprogress) {
        await getLinkedAccountAfter30sec(linkAccounts[i]?.id);
      }
    }
  }, [linkAccounts]);

  const AddLinkedAccountHandler = async () => {
    setLinkedAccountLoading(true);
    const { data } = await createLinkAccountSession();

    if (data) {
      var stripe = Stripe(data?.stripe_pk, {
        stripeAccount: data.stripeAccountId
      });
      stripe.collectFinancialConnectionsAccounts({
        clientSecret: data?.session?.clientSecret
      })
        .then(async function (result) {
          //result.financialConnectionsSession.accounts[0].id
          if (result.error) {
            setLinkedAccountLoading(false);
            // Inform the customer that there was an error.
            toast.error(result.error.message || "Failed to add linked account", toastConfiguration);
            // Handle next step based on length of accounts array
          } else if (result.financialConnectionsSession.accounts.length === 0) {
            setLinkedAccountLoading(false);
            toast.error("No accounts were linked", toastConfiguration);
          } else {
            setLinkedAccountLoading(false);
            setFetchLoading(true);

            setTimeout(async () => {
              const { data } = await RefreshLinkAccountData({ id: result?.financialConnectionsSession?.accounts[0]?.id });
              if (data) {
                const res = await getCardDetails({
                  "onlyAccount": true
                });
                if (res.data) {
                  setLinkAccounts(res?.data?.linkAccounts);
                }
              }
              setFetchLoading(false);
            }, 15000)
          }
        }).catch(() => {
          setLinkedAccountLoading(false);
        })
    }
  }

  const refreshButtonHandler = async (x, setRefreshButtonLoading) => {
    const { data } = await RefreshLinkAccountData({ id: x?.id });
    if (data) {
      await getLinkedAccountAfter30sec(x?.id, setRefreshButtonLoading);
    }
  }

  const getLinkedAccountAfter30sec = async (id, setRefreshButtonLoading) => {
    setTimeout(async () => {
      const { data } = await GetLinkAccountData({
        id: id
      });

      if (data) {
        const { account } = data;
        const tempArray = linkAccounts;

        for (let j = 0; j < tempArray?.length; j++) {
          if (tempArray[j].id === account.id) {
            tempArray[j] = account;
          }
        }

        setLinkAccounts([...tempArray])
      }
      setRefreshButtonLoading && setRefreshButtonLoading(false);
    }, 30000);
  }

  return (
    <>
      {visible ? (
        <Card
          className={cn(styles.card, className)}
          title="Linked Accounts"
          /* classTitle="title-green" */
          head={
            <button
              //className={cn("button", styles.addBtn)}
              onClick={AddLinkedAccountHandler}
            >
              {linkedAccountLoading ?
                <Spinner size="24" color="gray" />
                :
                <>
                  <img src="/images/content/addCircle.svg" alt="plus-icon" />
                  {/* Add */}
                </>
              }
            </button>
          }
        >
          {fetchLoading || loading ?
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Spinner size="40" color="gray" loading={fetchLoading || loading} />
              {fetchLoading ?
                <>
                  <div style={{ fontSize: 14, marginTop: 20 }}>Fetching new linked account</div>
                  <div style={{ fontSize: 12, marginBottom: 20 }}>It will take just few seconds</div>
                </>
                : null}
            </div>
            :
            linkAccounts?.length > 0 ? (
              <div className={styles.linkedAccountWrap}>
                {linkAccounts.map((x, index) => (
                  <div key={index}>
                    <SingleAccount
                      x={x}
                      refreshButtonHandler={refreshButtonHandler}
                    />
                  </div>
                ))}
              </div>
            ) : null}
        </Card>
      ) : (
        <NewLinkedAccounts
          setVisible={setVisible}
          setAddLinkedAccounts={setAddLinkedAccounts}
          setShowLinkedAccounts={setShowLinkedAccounts}
        />
      )
      }
    </>
  );
};

export default LinkedAccounts;
