import React, { useState } from 'react';
import styles from './AccountsConnection.module.sass';
import TextInput from '../../../components/TextInput';
import { connectWithGoogleExternalInt, signUpWithUserDetails } from '../../../utils/apiCallHanlder';
import { getReturnUrl, handleLoginSignUpResponse, toastConfiguration } from '../../../utils/utils';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../../../utils/spinner';

const AccountConnection = ({ reffered, googleAccount, setCurrentStep, details, token, openingBusinessAccount }) => {
    const history = useHistory();

    const [code, setCode] = useState("");
    const [skipButtonClicked, setSkipButtonClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [syncLoading, setSyncLoading] = useState(false);

    const continueClickHandler = async (code) => {
        if (loading) return;

        setLoading(true);
        const payload = {
            ...details,
            onlyCompany: openingBusinessAccount ? true : false,
            myNewToken: token,
            refferedBy: code,
            returnUrl: "https://somedummyUrl.com"
        }
        const { data, error } = await signUpWithUserDetails(payload, openingBusinessAccount ? false : true);
        if (data) {
            localStorage.removeItem("fbclid");
            localStorage.removeItem("gclid");
            localStorage.setItem(
                "connectStatus",
                JSON.stringify(data?.connectStatus)
            );

            localStorage.setItem("stripeConnection", data.isConnected);
            localStorage.setItem("isPending", data.isPending);
            localStorage.setItem("isTestMode", data.istestmode);
            localStorage.setItem("currencySymbol", data.currencySymbol || "$");
            localStorage.setItem("currency", data.currency || "USD");
            localStorage.setItem("isAccountingEnabled", false);
            data["redirectURL"] = null;
            data["noRedirect"] = true;

            handleLoginSignUpResponse(data, history);
            setCurrentStep(4)
        } else {
            toast.error(error || "Error in signing up", toastConfiguration)
        }
        setLoading(false);
        setSkipButtonClicked(false);
    }

    const ConnectWithGmailHandler = async () => {
        setSyncLoading(true);
        const ReturnUrl = getReturnUrl("sign-up-details");

        const signInResponse = await connectWithGoogleExternalInt({ ReturnUrl });
        const { data } = signInResponse;
        if (data) {
            window.location = data.redirectURL;
        }
        setSyncLoading(false);
    };

    return <div className={styles.wrapper}>
        <ToastContainer />
        <div>
            {reffered ?
                <>
                    <h3 className={styles.heading}>Where you referred?</h3>
                    <h3 className={styles.subHeading} style={{ marginBottom: 24 }}>
                        If you got a promo code from one of our partners, please enter it below.
                    </h3>
                    <TextInput
                        label='Code'
                        name='code'
                        type='text'
                        value={code}
                        onChange={(event) => setCode(event?.target?.value)}
                        required
                    />
                    <div className={styles.buttonContainerForBank}>
                        <button className="button-stroke-red" onClick={async () => {
                            setSkipButtonClicked(true);
                            await continueClickHandler();
                        }}>
                            {(loading && skipButtonClicked) ?
                                <Spinner size={"24"} color="gray" />
                                :
                                "Skip"
                            }
                        </button>
                        <button className="button" onClick={async () => {
                            await continueClickHandler(code);
                        }}>
                            {(loading && !skipButtonClicked) ?
                                <Spinner size={"24"} color="white" />
                                :
                                "Continue"
                            }
                        </button>
                    </div>
                </>
                : googleAccount ?
                    <>
                        <h3 className={styles.heading}>Great, almost there!</h3>
                        <h3 className={styles.subHeading}>
                            Would you like to integrate your Google calendar and emails with the platform?
                            This enables enhanced CRM functionalities in Greatweek. Remember,
                            you can opt to set this up at a later time if you prefer.
                        </h3>
                        <div className={styles.buttonContainer}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button className={styles.googleButton} onClick={ConnectWithGmailHandler}></button>
                                {syncLoading && (
                                    <Spinner size="24" color="gray" />
                                )}
                            </div>
                            <button className={`button-stroke-red ${styles.skip}`} onClick={() => {
                                setCurrentStep(5)
                            }}>Skip</button>
                        </div>
                    </>
                    :
                    <>
                        <h3 className={styles.heading}>And finally...</h3>
                        <h3 className={styles.subHeading}>
                            For an optimized Greatweek experience, we recommend linking your bank account(s)
                            to our platform. This enhances bookkeeping management and also fast-tracks loan applications,
                            potentially securing you better rates.
                        </h3>
                        <div className={styles.buttonContainerForBank}>
                            <button className="button-stroke-red" onClick={() => {
                                setCurrentStep(6)
                            }}>Skip</button>
                            <button className="button" onClick={() => {
                                history.push({
                                    pathname: "/dashboard",
                                    state: { openLinkAccount: true }
                                });
                            }}>
                                Sync bank account
                            </button>
                        </div>
                    </>
            }
        </div>
    </div >
}

export default AccountConnection;