import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Checkbox from "../../../../../../components/Checkbox";
import Icon from "../../../../../../components/Icon";
import Tooltip from "../../../../../../components/Tooltip";
import CancelModal from "../../../NewSubscriptions/Modals/CancelModal";
import {
  dateFormatter,
  toastConfiguration,
  toTitleCase,
} from "../../../../../../utils/utils";
import { useHistory } from "react-router";
import { cancelSubscription } from "../../../../../../utils/apiCallHanlder";
import { toast } from "react-toastify";

const Row = ({
  item,
  onChange,
  activeTable,
  activeId,
  className,
  editAction,
  singleCheck,
  editProduct,
  archiveStatus,
  setSubscriptionId,
  setVisibleAdd,
  needNewData,
  setActiveType,
  setNeedNewData,
}) => {
  const [visible, setVisible] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [invoiceNow, setInvoiceNow] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const history = useHistory();

  item.icon = "dots";
  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
      >
        <div
          className={styles.editOverlay}
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/subscriptions/${item?.id}`);
            //setSubscriptionId(item?.id);
            //setVisibleAdd(false);
          }}
        ></div>
        {/* <div className={styles.col}>
          {singleCheck || (
            <Checkbox className={styles.checkbox} onChange={onChange} />
          )}
        </div> */}
        <div
          className={cn(styles.col, styles.firstChild)}
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/subscriptions/${item?.id}`);
          }}
        >
          <div className={styles.customer}>
            <div onClick={editProduct} className={cn(styles.name)}>
              <span>{item?.customerName}</span>
              <br />
              <span>{archiveStatus && archiveStatus}</span>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.amountTag}>Email</div>
          <div className={styles.amount}>
            {item.amount}
            {item?.customerEmail}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.amountTag}>Product</div>
          <div className={styles.amount}>{item?.productName}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.statusTag}>Status</div>
          <div className={cn(styles.status)}>
            <span
              className={cn(
                item.status === "canceled"
                  ? styles.statusCancelled
                  : styles.statusActive
              )}
            >
              {toTitleCase(item?.status)}
            </span>
            { item.status === "incomplete" && (
            <Tooltip
              className={styles.tooltip}
              title="This subscription will expire tomorrow unless the first payment is completed."
              icon="info"
              place="right"
            />
          )}
          </div>
        </div>
        <div className={styles.col}>
          <span className={styles.createdTag}>Created</span>
          <div className={styles.created}>{dateFormatter(item?.createdOn)}</div>
        </div>
        <div className={styles.col}>
          <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div
              className={cn(styles.dotsBtn, className, {
                [styles.active]: visible,
              })}
            >
              <div className={styles.head}>
                <button
                  className={cn(styles.btn)}
                  onClick={() => setVisible(!visible)}
                >
                  <Icon name={item.icon} />
                </button>
              </div>
              <div className={styles.actionBody}>
                <button
                  className={styles.actionItem}
                  onClick={() => {
                    history.push(`/subscriptions/${item.id}`);
                    // setSubscriptionId(item?.id);
                    // setVisibleAdd(false);
                  }}
                >
                  <Icon name='edit' size='24' />
                  {item?.status === "canceled"
                    ? "View Subscription"
                    : "Update Subscription"}
                </button>

                {item?.status !== "canceled" ? (
                  <button
                    onClick={() => setShowCancelModal(true)}
                    className={cn(styles.actionItem, styles.cancelBtn)}
                  >
                    <Icon name='trash' size='24' />
                    <span>Cancel Subscriptions</span>
                  </button>
                ) : null}
                <hr />
                <CancelModal
                  showCancelModal={showCancelModal}
                  setShowCancelModal={setShowCancelModal}
                  invoiceNow={invoiceNow}
                  setInvoiceNow={setInvoiceNow}
                  cancelLoading={cancelLoading}
                  onConfirm={async () => {
                    setCancelLoading(true);
                    const { data, error } = await cancelSubscription({
                      id: item?.id,
                      invoiceNow,
                    });
                    if (data) {
                      history.push("/subscriptions/tabs/2");
                      needNewData();
                      setActiveType("Canceled");
                    } else {
                      toast.error(
                        "Error in canceling subscription",
                        toastConfiguration
                      );
                    }
                    setCancelLoading(false);
                  }}
                />
                <button
                  className={styles.actionItem}
                  onClick={() => history.push(`/contacts/${item?.customerId}`)}
                >
                  <Icon name='profile-circle' size='24' />
                  View Customer
                </button>
                {/* <button
                  className={styles.actionItem}
                  onClick={() => setVisible(false)}
                >
                  <Icon name="sync" size="24" />
                  View Subscriptions
                </button> */}
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </>
  );
};

export default Row;
