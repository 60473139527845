export const subscriptions = [
    {
      id: 1,
      customer: "hello@gotham.com",
      amount: 19,
      billingType: "Month",
      product: "Monthly Growth",
      status: "Active",
      created: "May 9, 2022",
      icon: "dots",
      subscriptionsType: "Current",
    },
    {
      id: 2,
      customer: "hello@gotham.com",
      amount: 99,
      billingType: "Year",
      product: "Yearly Scale",
      status: "Active",
      created: "May 9, 2022",
      icon: "dots",
      subscriptionsType: "Scheduled",
    },
    {
      id: 3,
      customer: "hello@gotham.com",
      amount: 99,
      billingType: "Year",
      product: "Yearly Scale",
      status: "Cancelled",
      created: "May 9, 2022",
      icon: "dots",
      subscriptionsType: "Cancelled",
    },
    {
      id: 4,
      customer: "hello@gotham.com",
      amount: 99,
      billingType: "Year",
      product: "Yearly Scale",
      status: "Active",
      created: "May 9, 2022",
      icon: "dots",
      subscriptionsType: "Current",
    },
  ];
  