import React, { useState } from 'react';
import cn from "classnames";
import styles from "./ForgotPassword.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Entry from "./Entry";
import Image from "../../components/Image";
import { useParams } from 'react-router-dom';
import { resetPassword, resetPasswordWithCode } from "../../utils/apiCallHanlder";
import { toastConfiguration } from '../../utils/utils';
import { useHistory } from 'react-router-dom';
import {toast} from 'react-toastify';

const ForgotPassword = () => {
  const heightWindow = use100vh();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { id } = useParams();

  const confirmResetPassword = async (password, passwordConfirm) => {
    setLoading(true);
    if (password !== passwordConfirm) {
      setError("Password and confirm password doesn't match")
    } else if (id) {
      const { data, error } = await resetPasswordWithCode({
        password,
        code: id
      });

      if (data) {
        toast.success("Password updated successfully",toastConfiguration);
        history.push("/");
      } else {
        setError(error);
      }
    }
    setLoading(false);
  }

  return (
    <div className={styles.row}>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        <div className={styles.head}>
          <Link className={styles.logo} to="/">
            <Image
              className={styles.pic}
              src="/images/logo-dark.png"
              srcDark="/images/logo-light.png"
              alt="Core"
            />
          </Link>
          <div className={styles.info}>
            Don't have an account?{" "}
            <Link className={styles.link} to="/sign-up">
              Sign Up
            </Link>
          </div>
        </div>
        <div className={styles.wrap}>
          <div className={cn("h2", styles.title)}>Reset Password</div>
          <Entry onConfirm={confirmResetPassword} error={error} setError={setError} loading={loading} />
        </div>
      </div>
    </div >
  );
};

export default ForgotPassword;
