import Icon from '../../../components/Icon';
import styles from './tableAddedRow.module.sass';

const TableAddedRow = ({
    row,
    rows,
    setRows,
    isLastItem
}) => {
    return <div className={styles.tableRowContainer}>
        {Object.keys(row)?.map((item, index) => {
            return <span
                className={styles.columnName}
                key={index}
            >
                {row[item]}
            </span>
        })}
        <div className={styles.crossIcon} onClick={() => {
            const filteredRows = rows?.filter(item => item.sCode !== row.sCode);
            setRows([...filteredRows]);
        }}>
            <Icon name="cross" size="20" />
        </div>
        {!isLastItem && (
            <div className={styles.line}></div>
        )}
    </div>
}

export default TableAddedRow;