import React, { useState } from "react";

import styles from "./CreditCard.module.sass";
import Image from "../Image";
import Icon from "../Icon";
import cn from 'classnames';
import Spinner from "../../utils/spinner";
import { revealCardCvc } from "../../utils/apiCallHanlder";
import { toast, ToastContainer } from "react-toastify";
import { toastConfiguration } from "../../utils/utils";

const DefaultCard = ({ className, cardDetail, color }) => {
  const [showCardNumber, setShowCardNumber] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardCvc, setCardCvc] = useState("");
  const [revealNumberLoading, setRevealNumberLoading] = useState(false);

  const showCardNumberHandler = async (event) => {
    event.stopPropagation();
    if (cardDetail?.id) {
      setRevealNumberLoading(true);
      const { data } = await revealCardCvc({ id: cardDetail?.id });

      if (data) {
        setCardNumber(data?.card?.cardNumber?.replace(/\W/gi, '')?.replace(/(.{4})/g, '$1 '));
        setCardCvc(data?.card?.cvc);
        setShowCardNumber(true);
      }
      setRevealNumberLoading(false);
    }
  }
  return (
    <>
      <div className={cn(className, cardDetail?.status === "inactive" ? styles.blur : null)}
        style={color ? { backgroundColor: color } : {}}
      >
        <div className={styles.cardHead}>
          <Image
            className={styles.pic}
            src="/images/logo-light.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />

          <div className={styles.headRight}>
            {
              revealNumberLoading ?
                <Spinner size="20" color="gray" />
                :
                showCardNumber ? (
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      setCardCvc("");
                      setCardNumber("");
                      setShowCardNumber(false);
                    }}
                  >
                    <Icon name="eye" size="24"></Icon>
                  </button>
                ) : (
                  <button
                    onClick={(e) => showCardNumberHandler(e)}
                  >
                    <Icon name="hide" size="24"></Icon>
                  </button>
                )}
          </div>
        </div>

        <div className={styles.cardNumber}>
          {!showCardNumber ? (
            <>
              <span style={{ fontSize: 20 }}>**** **** **** </span>
              <span style={{ fontSize: 20 }}>{cardDetail?.cardNumberLast4}</span>
            </>
          ) : (
            <>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                  <span style={{ fontSize: 20 }}>{cardNumber}</span>
                </div>
                <ToastContainer />
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator?.clipboard?.writeText(cardNumber);
                    toast.success("Card number copied successfully.", toastConfiguration)
                  }}>
                  {showCardNumber ?
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#9b9b9b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                    : null}
                </div>
              </div>
            </>
          )}
        </div>

        <div className={styles.cardName}>{cardDetail?.name}</div>

        <div className={styles.cardFooter}>
          <div className={styles.startDate}>{cardDetail?.expirtyDate}</div>
          {showCardNumber ?
            <div className={styles.endDate}>{cardCvc}</div>
            :
            <div className={styles.endDate}>***</div>
          }
          {cardDetail?.cardBrand === "Visa" ?
            <Image
              className={styles.cardLogo}
              src="/images/content/visa.png"
              srcDark="/images/content/visa.png"
              alt="visa logo"
            /> : null}
        </div>
      </div>
    </>
  );
};

export default DefaultCard;
