import React, { useState } from "react";
import cn from "classnames";
import styles from "./ErrorModal.module.sass";
import Icon from "../Icon";
import Image from "../Image";

const AccountDeleteModal = ({ className, children, visible, onConfirm }) => {

  return (
    <div
      className={cn(styles.deleteModal, className, {
        [styles.active]: visible,
      })}
    >
      <div className={styles.body}>
        <div className={styles.top}>
          <button
            className={cn("close", styles.close)}
            onClick={onConfirm}
          >
            <Icon name="close" size="20" />
          </button>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.imgWrapper}>
            <Image
              className={styles.pic}
              src="/images/content/lock.png"
              srcDark="/images/content/lock.png"
              alt="delete"
            />
          </div>
          <div className={styles.title}>Your account Deleted Successfully</div>
          <button
            className={cn("button", styles.verifyBtn)}
            style={{ backgroundColor: '#490000', marginTop: 20, width: 150 }}
            onClick={onConfirm}
          >
            Done
          </button>
          <div className={styles.para}></div>
        </div>
        {children}
      </div>
      <div className={styles.overlay} onClick={onConfirm}></div>
    </div>
  );
};

export default AccountDeleteModal;
