import React, { useEffect, useState } from 'react';
import AddNewPaymentLink from './AddNew';
import PaymentLinkOverview from './PaymentLinksOverview';

const PaymentLinks = ({ currentPage, customerId, props }) => {

    const isNewHeader = props?.location?.state?.isNewHeader;

    const [showAddPaymentLink, setShowAddPaymentLink] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (isNewHeader || customerId) {
            setShowAddPaymentLink(true);
        }
        setLoaded(true);
    }, [isNewHeader, customerId])

    
    return (
        <div>
            {loaded && (
                showAddPaymentLink ?
                    <AddNewPaymentLink
                        showAddPaymentLink={showAddPaymentLink}
                        setShowAddPaymentLink={setShowAddPaymentLink}
                    /> :
                    <PaymentLinkOverview
                        showAddPaymentLink={showAddPaymentLink}
                        setShowAddPaymentLink={setShowAddPaymentLink}
                        currentPage={currentPage}
                    />
            )}
        </div>
    )
}

export default PaymentLinks;