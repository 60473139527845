import React, { useState, useReducer } from "react";
import styles from "./SingleEmail.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Icon from "../../../../components/Icon";
import DeleteModal from "../../../../components/DeleteModal";
import UpdateEmailForm from "../UpdateEmailForm";
import Modal from "../../../../components/Modal";
import Card from "../../../../components/Card";
import { useEffect } from "react";
import TextInput from "../../../../components/TextInput";
import {
  deleteEmailTemplate,
  updateEmailTemplate,
} from "../../../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import Spinner from "../../../../utils/spinner";
import { useHistory } from "react-router";

const SingleEmail = ({
  x,
  remove,
  update,
  setEmails,
  getEmailFunction,
  setSinglePageStatus,
}) => {
  const [showActionBtn, setShowActionBtn] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [value, setValue] = useState("");
  const [emailTemplate, setEmailTemplate] = useState(x.emailTemplate);
  const [defaultTemplate, setDefaultTemplate] = useState(x.defaultTemplate);

  const [updateEmailTemplateLoading, setUpdateEmailTemplateLoading] =
    useState(false);
  const [deleteEmailTemplateLoading, setDeleteEmailTemplateLoading] =
    useState(false);

  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      emailName: x.name,
      trixEditorValue: x.body,
    }
  );

  const history = useHistory();

  useEffect(() => {
    if (isUpdate) {
      setEmailTemplate(x?.emailTemplate);
      setUserInput({ subject: x?.subject });
    }
  }, [isUpdate]);

  const handleTrixInputChange = (e, newValue) => {
    setValue(newValue);
    setUserInput({ trixEditorValue: newValue });
  };

  const handleChange = (e) => {
    setUserInput({ [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdateEmailTemplateLoading(true);

    const { data } = await updateEmailTemplate({
      id: x?.id,
      name: userInput.emailName,
      subject: userInput.subject,
      body: userInput.trixEditorValue,
      automation: "",
      emailTemplate: emailTemplate,
    });
    if (data) {
      setEmails([]);
      toast.success("Successfully updated email template", toastConfiguration);
      await getEmailFunction(1);
    } else {
      toast.success("Error in updating email template", toastConfiguration);
    }

    setIsUpdate(false);
    setUpdateEmailTemplateLoading(false);
    setSinglePageStatus(false);
  };

  useEffect(() => {
    const message = document.getElementById("message");
    if (message) {
      message.innerHTML = x.trixEditorValue;
    }
  });

  return (
    <>
      {!isUpdate ? (
        <>
          <div className={styles.singleEmail} id={x.id}>
            <p
              onClick={() => {
                history.push(`/studio/2/${x.id}`)
              }}
            >
              {x.name}
            </p>
            <p
              onClick={() => {
                history.push(`/studio/2/${x.id}`)
              }}
            >{x.subject}</p>
            <button
              id={x.id}
              onClick={() => {
                setShowDeleteModal(true);
              }}
              className={cn(styles.deleteItem)}
            >
              <Icon name="trash" size="24" />
            </button>
            {/* <div className={styles.right}>
            <div className={styles.rightAction}>
              <OutsideClickHandler
                onOutsideClick={() => setShowActionBtn(false)}
              >
                <div
                  className={cn(styles.dotsBtn, {
                    [styles.active]: showActionBtn,
                  })}
                >
                  <div className={styles.head}>
                    <button
                      className={cn(styles.btn)}
                      onClick={() => setShowActionBtn(!showActionBtn)}
                    >
                      <Icon name="dots" size="24" />
                    </button>
                  </div>
                  <div className={styles.actionBody}>
                    <button
                      onClick={() => setIsUpdate(true)}
                      className={styles.actionItem}
                    >
                      <Icon name="edit" size="24" />
                      Edit
                    </button>
                    <button
                      onClick={() => setShowViewModal(true)}
                      className={styles.actionItem}
                    >
                      <Icon name="eye" size="24" />
                      view
                    </button> 
                    <button
                      id={x.id}
                      onClick={() => {
                        setShowDeleteModal(true);
                      }}
                      className={cn(styles.actionItem, styles.deleteItem)}
                    >
                      <Icon name="trash" size="24" />
                      Delete
                    </button>
                  </div>
                </div>
              </OutsideClickHandler>
              <DeleteModal
                visibleDelete={showDeleteModal}
                setVisibleDelete={setShowDeleteModal}
                button={true}
                deleteBtn={
                  <button
                    className="button"
                    style={{ width: 200 }}
                    id={x.id}
                    onClick={async (e) => {
                      setDeleteEmailTemplateLoading(true);
                      const { data } = await deleteEmailTemplate(x?.id)
                      if (data) {
                        setEmails([]);
                        toast.success("Successfully deleted email template", toastConfiguration);
                        await getEmailFunction(1);
                      } else {
                        toast.error("Error in deleting email template", toastConfiguration);
                      }
                      setShowActionBtn(false);
                      setShowDeleteModal(false);
                      setDeleteEmailTemplateLoading(false);
                    }}
                  >
                    {deleteEmailTemplateLoading ?
                      <Spinner size="24" color="white" />
                      :
                      "Yes I'm sure!"
                    }
                  </button>
                }
              />
            </div>
          </div> */}
          </div>
          <DeleteModal
            visibleDelete={showDeleteModal}
            setVisibleDelete={setShowDeleteModal}
            button={true}
            deleteBtn={
              <button
                className="button"
                style={{ width: 200 }}
                id={x.id}
                onClick={async (e) => {
                  setDeleteEmailTemplateLoading(true);
                  const { data } = await deleteEmailTemplate(x?.id);
                  if (data) {
                    setEmails([]);
                    toast.success(
                      "Successfully deleted email template",
                      toastConfiguration
                    );
                    await getEmailFunction(1);
                  } else {
                    toast.error(
                      "Error in deleting email template",
                      toastConfiguration
                    );
                  }
                  setShowActionBtn(false);
                  setShowDeleteModal(false);
                  setDeleteEmailTemplateLoading(false);
                }}
              >
                {deleteEmailTemplateLoading ? (
                  <Spinner size="24" color="white" />
                ) : (
                  "Yes I'm sure!"
                )}
              </button>
            }
          />
          {showViewModal ? (
            <Modal
              visible={showViewModal}
              onClose={() => setShowViewModal(false)}
              children={
                <Card
                  title="View Template"
                  /* classTitle="title-blue" */
                  className={styles.card}
                  children={
                    <div className={styles.viewWrap}>
                      <div className={styles.viewGroup}>
                        <div className={styles.label}>Subject</div>
                        <div className={styles.showText}>{x.subject}</div>
                      </div>
                      <div className={styles.viewGroup}>
                        <div className={styles.label}>Message</div>
                        <div id="message" className={styles.showText}></div>
                      </div>
                    </div>
                  }
                />
              }
            />
          ) : null}
        </>
      ) : (
        <UpdateEmailForm
          handleSubmit={handleSubmit}
          updateEmailTemplateLoading={updateEmailTemplateLoading}
          handleChange={handleChange}
          x={x}
          emailTemplate={emailTemplate}
          setEmailTemplate={setEmailTemplate}
          defaultTemplate={defaultTemplate}
          setDefaultTemplate={setDefaultTemplate}
          userInput={userInput}
          setIsUpdate={setIsUpdate}
          setUserInput={setUserInput}
          isUpdate={isUpdate}
          handleTrixInputChange={handleTrixInputChange}
          value={value}
          setValue={setValue}
          setSinglePageStatus={setSinglePageStatus}
        />
      )}
    </>
  );
};

export default SingleEmail;
