export const products = [
  {
    id: 1,
    product: "Awesome Product",
    image: "/images/content/product-pic-3.jpg",
    price: 3200,
    tax: "15%",
    taxType: "Excluded",
    type: "Recurring",
    icon: "dots",
    isArchived: false
  },
  {
    id: 2,
    product: "Awesome Product",
    image: "/images/content/product-pic-3.jpg",
    price: 3200,
    tax: "15%",
    taxType: "Included",
    type: "Recurring",
    icon: "dots",
    isArchived: false
  },
  {
    id: 3,
    product: "Awesome Product",
    price: 3200,
    tax: "15%",
    taxType: "Excluded",
    type: "Recurring",
    icon: "dots",
    isArchived: false
  },
  {
    id: 4,
    product: "Awesome Product",
    image: "/images/content/product-pic-1.jpg",
    price: 3200,
    tax: "15%",
    taxType: "Included",
    type: "Recurring",
    icon: "dots",
    isArchived: true
  },
  {
    id: 5,
    product: "Awesome Product",
    image: "/images/content/product-pic-1.jpg",
    price: 3200,
    tax: "15%",
    taxType: "Excluded",
    type: "Recurring",
    icon: "dots",
    isArchived: true
  },
  {
    id: 6,
    product: "Awesome Product",
    image: "/images/content/product-pic-1.jpg",
    price: 3200,
    tax: "15%",
    taxType: "Included",
    type: "Recurring",
    icon: "dots",
    isArchived: true
  },
];
