import React from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Checkbox from "../../../../components/Checkbox";
import Dropdown from "../../../../components/Dropdown";
import { useState } from "react";

const holderNames = ["Card Holder", "Nickname"]

const Table = ({
  rowContent,
  handleActiveCardAll,
  handleArchivedCardAll,
  name,
  cardRights,
  removeCheckbox,
  setShowCardHolderName,
  showCardHolderName
}) => {
  const [holderName, setHolderName] = useState(holderNames[0])
  return (
    <div className={cn(styles.wrapper)}>
      <div className={cn(styles.table)}>
        <div className={cn(styles.row)}>
          <div className={styles.col}>
            {!removeCheckbox && (
              cardRights !== 2 && (
                <Checkbox
                  className={styles.checkbox}
                  onChange={handleActiveCardAll || handleArchivedCardAll}
                  name={name}
                />
              )
            )}
          </div>

          {/* <div className={styles.col}>Nickname</div> */}
          <div className={styles.col}>Ending with</div>
          <div className={styles.col}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              options={holderNames}
              value={holderName}
              setValue={setHolderName}
              onChange={(value) => {
                if (value === 'Nickname') {
                  setShowCardHolderName(false)
                } else {
                  setShowCardHolderName(true)
                }
              }}
            />
          </div>
          <div className={styles.col}>Type</div>
          <div className={styles.col}>Status</div>
        </div>
        {rowContent}
      </div>
    </div>
  );
};

export default Table;
