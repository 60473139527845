import React, { useState, useReducer } from "react";
import styles from "./SingleEmbeddedForm.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Icon from "../../../../components/Icon";
import DeleteModal from "../../../../components/DeleteModal";
import UpdateEmbeddedForm from "../UpdateEmbeddedForm";
import Code from "../Code";
import { deleteEmbededForm, updateEmbeddedForm } from "../../../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import { id } from "date-fns/locale";
import Spinner from "../../../../utils/spinner";

const SingleEmbeddedForm = ({
  x,
  remove,
  update,
  setEmbeddedForms,
  getEmbededFormFunction,
  fb
}) => {
  const [showActionBtn, setShowActionBtn] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editEmbededFormLoading, setEditEmbededFormLoading] = useState(false);
  const [deleteEmbededFormLoading, setDeleteEmbededFormLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [value, setValue] = useState("");
  const [showCode, setShowCode] = useState(false)
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      embeddedFormName: x.name,
      formBuilderData: x.data,
    }
  );


  const handleChange = (e) => {
    setUserInput({ [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (parseInt(userInput?.embeddedFormName?.trim()?.length || 0) < 1) {
      toast.error("Name is required", toastConfiguration);
      return;
    }

    setEditEmbededFormLoading(true);
    const el = document?.getElementById("formDiv");
    el?.remove();

    const getDataBtn = document.querySelector(".get-data")
    if (getDataBtn !== null) {
      getDataBtn.click()
    }

    const formData = document.querySelector(".formData-json").innerHTML

    const getDataOverlay = document.querySelector(".form-builder-overlay")
    if (getDataOverlay !== null) {
      getDataOverlay.click()
    }

    const { data } = await updateEmbeddedForm({
      id: x?.id,
      name: userInput.embeddedFormName,
      data: formData,
      baseUrl: 'https://google.com'
    })

    if (data) {
      setEmbeddedForms([]);
      toast.success("Successfully updated embeded form", toastConfiguration);
      await getEmbededFormFunction(1);
    } else {
      toast.error("Error in updating embeded form", toastConfiguration);
    }

    document?.getElementById("formContainer").appendChild(el);
    setIsUpdate(false);
    setEditEmbededFormLoading(false);
  };

  return (
    <>
      <div className={styles.singleEmbeddedForm} id={x.id}>
        <p
          onClick={() => setIsUpdate(true)}
        >{x.name}</p>
        <button
          type="button"
          id={x.id}
          onClick={() => {
            setShowDeleteModal(true);
          }}
          className={cn(styles.actionItem, styles.deleteItem)}
        >
          <Icon name="trash" size="24" />
        </button>
        {/* <div className={styles.right}>
          <OutsideClickHandler onOutsideClick={() => setShowActionBtn(false)}>
            <div
              className={cn(styles.dotsBtn, {
                [styles.active]: showActionBtn,
              })}
            >
              <div className={styles.head}>
                <button
                  type="button"
                  className={cn(styles.btn)}
                  onClick={() => setShowActionBtn(!showActionBtn)}
                >
                  <Icon name="dots" size="24" />
                </button>
              </div>
              <div className={styles.actionBody}>
                <button
                  type="button"
                  onClick={() => setIsUpdate(true)}
                  className={styles.actionItem}
                >
                  <Icon name="edit" size="24" />
                  Edit
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    setShowActionBtn(false);
                    setShowCode(true)
                  }}
                  className={styles.actionItem}
                  id={x.id}
                >
                  <Icon name="code" size="24" />
                  Code
                </button>
                <button
                  type="button"
                  id={x.id}
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                  className={cn(styles.actionItem, styles.deleteItem)}
                >
                  <Icon name="trash" size="24" />
                  Delete
                </button>
              </div>
            </div>
          </OutsideClickHandler>
          <DeleteModal
            visibleDelete={showDeleteModal}
            setVisibleDelete={setShowDeleteModal}
            button={true}
            deleteBtn={
              <button
                className="button"
                style={{ width: 200 }}
                id={x.id}
                onClick={async (e) => {
                  setDeleteEmbededFormLoading(true);
                  const { data } = await deleteEmbededForm(x?.id)
                  if (data) {
                    setEmbeddedForms([]);
                    toast.success("Successfully deleted embeded form", toastConfiguration);
                    await getEmbededFormFunction(1);
                  } else {
                    toast.error("Error in deleting embeded form", toastConfiguration);
                  }
                  setShowActionBtn(false);
                  setShowDeleteModal(false);
                  setDeleteEmbededFormLoading(false);
                }}
              >
                {deleteEmbededFormLoading ?
                  <Spinner size="24" color="white" />
                  :
                  "Yes I'm sure!"
                }
              </button>
            }
          />
        </div> */}
      </div>
      <DeleteModal
        visibleDelete={showDeleteModal}
        setVisibleDelete={setShowDeleteModal}
        button={true}
        deleteBtn={
          <button
            className="button"
            style={{ width: 200 }}
            id={x.id}
            onClick={async (e) => {
              setDeleteEmbededFormLoading(true);
              const { data } = await deleteEmbededForm(x?.id)
              if (data) {
                setEmbeddedForms([]);
                toast.success("Successfully deleted embeded form", toastConfiguration);
                await getEmbededFormFunction(1);
              } else {
                toast.error("Error in deleting embeded form", toastConfiguration);
              }
              setShowActionBtn(false);
              setShowDeleteModal(false);
              setDeleteEmbededFormLoading(false);
            }}
          >
            {deleteEmbededFormLoading ?
              <Spinner size="24" color="white" />
              :
              "Yes I'm sure!"
            }
          </button>
        }
      />
      <UpdateEmbeddedForm
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        x={x}
        userInput={userInput}
        setIsUpdate={setIsUpdate}
        setUserInput={setUserInput}
        isUpdate={isUpdate}
        value={value}
        setValue={setValue}
        editEmbededFormLoading={editEmbededFormLoading}
      />

      {showCode ? (
        <Code
          x={x}
          visible={showCode}
          setVisible={setShowCode}
        />
      ) : null}
    </>
  );
};

export default SingleEmbeddedForm;
