import React from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import { TriStateContext } from "react-tri-state-checkbox";
import Checkbox from "../../../../../components/Checkbox";
import "react-tri-state-checkbox/styles/checkbox.css";
import { ToastContainer } from "react-toastify";
import NoProjects from "../../../NoProjects";
import Row from "../Row";
import { useHistory } from 'react-router-dom';
import Icon from "../../../../../components/Icon";
import AlternativeAvatar from "../../../../../components/AlternativeAvatar";
import TextInput from "../../../../../components/TextInput";
import { chatDateFormatter, getCurrentUser } from "../../../../../utils/utils";
import Spinner from "../../../../../utils/spinner";

const Table = ({
    className,
    checkCount,
    setCheckCount,
    setDeleteIdArray,
    deleteIdArray,
    lendersData,
    hasMore,
    id,
    chatClickHanlder
}) => {
    const history = useHistory();
    const user = getCurrentUser();
    const lendersId = lendersData?.map((x) => {
        return x.id;
    });

    const changeLendersAll = (e) => {
        var ariaStatus = e.target.checked;
        if (ariaStatus === true) {
            const checkboxes = document.getElementsByName("cus-checkbox");

            for (var i = 0, n = checkboxes?.length; i < n; i++) {
                checkboxes[i].checked = true;
            }

            deleteIdArray = [];

            for (let i = 0; i < lendersData.length; i++) {
                deleteIdArray.push(lendersData[i]?.id);
            }

            setDeleteIdArray([...deleteIdArray]);

            setCheckCount(lendersData?.length);
        }
        if (ariaStatus === false) {
            const checkboxes = document.getElementsByName("cus-checkbox");

            for (var i = 0, n = checkboxes.length; i < n; i++) {
                checkboxes[i].checked = false;
            }
            setCheckCount(0);
            setDeleteIdArray([]);
        }
        if (ariaStatus === "mixed") {
            setCheckCount(lendersData?.length);
        }
    };

    const changeLender = (e, id) => {
        if (e.target.checked) {
            if (checkCount + 1 === lendersData?.length) {
                const checkbox = document.getElementsByName("cus-all-checkbox");
                if (checkbox) {
                    checkbox[0].checked = true;
                }
            }

            setCheckCount(checkCount + 1);
            setDeleteIdArray([...deleteIdArray, id]);
        }
        if (!e.target.checked) {
            const checkbox = document.getElementsByName("cus-all-checkbox");
            if (checkbox) {
                checkbox[0].checked = false;
            }

            const index = deleteIdArray.indexOf(id);
            if (index > -1) {
                deleteIdArray.splice(index, 1);
            }
            setDeleteIdArray([...deleteIdArray]);

            setCheckCount(checkCount - 1);
        }
    };

    return (
        <TriStateContext controls={lendersId}>
            <ToastContainer />
            <>
                <div className={cn(styles.wrapper, className)}>
                    {lendersData?.length > 0 ? (
                        <div className={styles.chatContainer}>
                            <div className={cn(styles.table, {
                                [styles.tableNone]: false,
                            })} style={{
                                width: '100%',
                                height: 'auto'
                            }}>
                                <div className={cn(styles.row)}>

                                    <div className={styles.col}></div>
                                    <div className={styles.col}>Name</div>
                                    <div className={styles.col}>Type</div>
                                    <div className={styles.col}>Stage</div>
                                    <div className={styles.col}></div>
                                </div>

                                {lendersData?.map((x, index) => {
                                    return (
                                        <Row
                                            item={x}
                                            key={index}
                                            checkCount={checkCount}
                                            onChange={changeLender}
                                            deleteIdArray={deleteIdArray}
                                            editLender={() => {
                                                history.push({
                                                    pathname: `/lenderportal/${x?.lenderId}/${id}`,
                                                    state: { status: x?.statusName }
                                                })
                                            }}
                                            chatClickHanlder={chatClickHanlder}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    ) : (
                        null
                        // <NoProjects />
                    )}
                </div>
                {hasMore && (
                    <div className={styles.foot}>
                        <button
                            className={cn("button-stroke-red button-small", styles.button)}
                            onClick={async () => { }}
                            style={{ width: 200 }}
                        >
                            <>
                                <span>See More Lenders</span>
                                <Icon name='arrow-next' size='20'></Icon>
                            </>
                        </button>
                    </div>
                )}
            </>
        </TriStateContext>
    );
};

export default Table;
