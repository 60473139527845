import React, { useEffect, useState } from "react";
import styles from "./Tax.module.sass";
import { useHistory } from "react-router";
import TextInput from "../../../../components/TextInput";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Tooltip from "../../../../components/Tooltip";
import Switch from "../../../../components/Switch";
import Icon from "../../../../components/Icon";
import cn from "classnames";
import {
  gettingPermissionByFeature,
  toastConfiguration,
  trackIntercomEvent,
} from "../../../../utils/utils";
import Dropdown from "../../../../components/Dropdown_c";
import {
  getCompanyTextSettings,
  getTaxRateList,
  updateCompanyTextSettings,
} from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { toast } from "react-toastify";
import Table from "../../../TaxRate/TaxRateOverview/Table";
import Row from "../../../TaxRate/TaxRateOverview/Table/Row";
import ProductOverview from "../../../Products/ProductOverview";
import CouponOverview from "../../../TaxRate/TaxRateOverview";

let haveDropdownsData = false;
let taxcodes = [];
let taxCodesGroups = [];
let taxCodesByGroups = {};

const salesTaxes = [
  {
    display: "Yes - Tax is included in price",
    text: "Yes - Tax is included in price",
    value: "inclusive",
  },
  {
    display: "No - Tax is excluded in price",
    text: "No - Tax is excluded in price",
    value: "exclusive",
  },
];
let lastId = "";
const Tax = ({ setvisibletax }) => {
  const [autoTaxCalc, setAutoTaxCalc] = useState(true);
  const [includeTax, setIncludeTax] = useState(salesTaxes[0]?.value);
  const [searchString, setSearchString] = useState("");
  const [taxCode, setTaxCode] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    const { data } = await getCompanyTextSettings({});
    if (data) {
      const companyTaxSettings = data?.companyTaxSettings;
      setAutoTaxCalc(false);//companyTaxSettings.autoTax
      setIncludeTax(
        companyTaxSettings?.includedTaxInPrice === "no"
          ? salesTaxes[1]?.value
          : salesTaxes[0]?.value
      );
      Object.keys(taxCodesByGroups)?.map(key => {
        for (let i = 0; i < taxCodesByGroups[key]?.length; i++) {
          if (taxCodesByGroups[key][i]?.value === companyTaxSettings?.defaultTaxCategory) {
            setSearchString(taxCodesByGroups[key][i]?.display)
          }
        }
      })
      setTaxCode(companyTaxSettings?.defaultTaxCategory);
    }
    setLoading(false);
  }, []);

  if (!haveDropdownsData) {
    const productLookupdata = JSON.parse(localStorage.getItem("lookupData"));
    let data = null;
    if (productLookupdata) {
      data = productLookupdata?.data;
      taxcodes = data?.taxcodes;

      for (let i = 0; i < taxcodes?.length; i++) {
        if (taxCodesGroups.includes(taxcodes[i]?.group)) {
          taxCodesByGroups[taxcodes[i]?.group] = [
            ...taxCodesByGroups[taxcodes[i]?.group],
            taxcodes[i],
          ];
        } else {
          taxCodesGroups.push(taxcodes[i]?.group);
          taxCodesByGroups[taxcodes[i]?.group] = [taxcodes[i]];
        }
      }
      haveDropdownsData = true;
    }
  }

  const updateTaxSettings = async (p1, p2, p3) => {
    setLoading(true);
    const { data, error } = await updateCompanyTextSettings({
      autoTax: (p1 === true || p1 === false) ? p1 : autoTaxCalc,
      defaultTaxCategory: p2 || taxCode,
      includedTaxInPrice: (p3 || includeTax) === "inclusive" ? "yes" : "no",
    });
    if (data) {
      toast.success("Tax settings updated successfully", toastConfiguration);
    } else {
      toast.error(error || "Error in updating tax settings", toastConfiguration);
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 120
          }}
        >
          <Spinner size='48' color='gray' />
        </div>
      ) : (
        <div className={styles.row}>
          <div className={styles.col12}>
          <div className={styles.switchWrap} style={{pointerEvents: 'none'}}>
              <Switch
                value={autoTaxCalc}
                onChange={() => {
                  updateTaxSettings(!autoTaxCalc);
                  setAutoTaxCalc(!autoTaxCalc);
                  trackIntercomEvent("enable-auto-tax-calc", {
                    value: !autoTaxCalc,
                  });
                }}
              />
              <div className={styles.fieldLabel}>
                <span>Enable automatic tax calculation</span>
                <Tooltip
                  className={styles.tooltip}
                  title='This option is available if you wish for Great Week to handle your accounting.'
                  icon='info'
                  place='right'
                />
              </div>
            </div>
            {autoTaxCalc && (
              <span>
                When automatic tax calculation is enabled and you are getting
                paid through stripe a 0.5% fee will be deducted from the total
                settlement amount.
              </span>
            )}
          </div>
          <div className={styles.row}>
            {/* <div className={styles.col}>
            <div className={styles.field}>
              <TextInput
                label='Tax Residency'
                type='text'
                name='number'
                value='United State - Delaware'
                readonly='readonly'
                required
              />
            </div>
          </div> */}
            <div className={styles.col}>
              <div className={styles.field}>
                <div className={styles.fieldLabel}>
                  <span>Default tax category</span>
                </div>
                <Dropdown
                  className={cn(styles.dropdown, styles.userChooseDrop)}
                  classDropdownHead={styles.dropdownHead}
                  value={taxCode}
                  inputStyles={{ height: 40 }}
                  showSearch={"true"}
                  setValue={setTaxCode}
                  options={taxcodes}
                  taxcodes={taxcodes}
                  taxCodesByGroups={taxCodesByGroups}
                  autoFocus={true}
                  hideDropdown={true}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  onChange={(value) => {
                    updateTaxSettings(null, value);
                  }}
                />
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.field}>
                <div className={styles.fieldLabel}>
                  <span>Include tax in price</span>
                </div>
                <Dropdown
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={includeTax}
                  setValue={setIncludeTax}
                  options={salesTaxes}
                  onChange={(value) => {
                    updateTaxSettings(null, null, value);
                  }}
                  small
                />
              </div>
            </div>

          </div>
          {!autoTaxCalc && (
            <span
              className={styles.addTaxRateText}
              onClick={() => {
                setvisibletax(true);
              }}
            >
              Add tax rates
            </span>
          )}
        </div>
      )}
      {!autoTaxCalc && (
        <CouponOverview activeIndex={2} hideExtras={true} />
      )}
      <TooltipGlodal />
    </>
  );
};

export default Tax;
