import React, { useEffect, useState } from "react";
import Checkbox from "../../../../../components/Checkbox";
import cn from "classnames";
import styles from "./PreviewFields.module.sass";
import Spinner from "../../../../../utils/spinner";

const PreviewFields = ({ x, sortLoading, currentSortedfieldId, updateChecklistCheckboxHandler }) => {
  const [completeField, setCompleteField] = useState(x?.isChecked ? true : false);

  return (
    <div className={styles.wrap}>
      <Checkbox
        value={completeField}
        onChange={(e) => {
          if (e.target.checked) {
            setCompleteField(true);
            updateChecklistCheckboxHandler(true);
          } else {
            setCompleteField(false);
            updateChecklistCheckboxHandler(false);
          }
        }}
      />
      <p className={cn({ [styles.markComplete]: completeField }, styles.text)}>
        {x.fieldName}
      </p>
      {(sortLoading && currentSortedfieldId === x?.checkListFieldId) && (
        <Spinner size={'12'} color="gray" />
      )}
    </div>
  );
};

export default PreviewFields;
