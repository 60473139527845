import React, { useState } from "react";
import CouponOverview from "./TaxRateOverview";
import { useParams, withRouter } from "react-router";
import EditNewCoupon from "./EditNewTaxRate";
import ErrorModal from '../../components/ErrorModal';

const TaxRate = (props) => {

  const isNewHeader = props?.location?.state?.isNewHeader;

  const [isStripedVerified, setIsStripedVerified] = useState(true);

  const { id } = useParams();

  return (
    <>
      {!isStripedVerified ?
        <ErrorModal visibility={true} />
        :
        id ? <EditNewCoupon id={id} />
          : isNewHeader ?
            <EditNewCoupon isAdd={true} /> :
            <CouponOverview activeIndex={props?.location?.state?.activeIndex} />
      }
    </>
  );
};

export default withRouter(TaxRate);
