import React from 'react';
import Checkbox from '../Checkbox';
import Modal from '../Modal';

const SelectionModal = ({
  showModal,
  setShowModal,
  action,
  customerSelects,
  recipientSelects
}) => {
  return <Modal
    visible={showModal}
    onClose={() => setShowModal(false)}
    children={
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        marginTop: 24
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 16,
          pointerEvents: 'none'
        }}>
          <Checkbox
            value={customerSelects}
          />
          <span>Customer</span>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 16,
          pointerEvents: 'none'
        }}>
          <Checkbox
            value={recipientSelects}
          />
          <span>Recipient</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
          <button onClick={() => action()} className="button">
            Done
          </button>
        </div>
      </div>
    }
  />
}

export default SelectionModal;