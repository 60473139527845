import React, { useState, useEffect } from "react";
import styles from "./NewInvoice.module.sass";
import Card from "../../../../components/Card";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import InvoiceOverview from "../InvoiceOverview";
import InvoiceCustomer from "./InvoiceCustomer";
import InvoicePreview from "./InvoicePreview";
import DeleteModal from "../../../../components/DeleteModal";
import SaveSuccess from "./Modals/SaveSuccess";
import {
  getInvoice,
  markManuallyInvoiceAsPaid,
  pageLoadTransactions,
  removeDraftInvoice,
  sendInvoice,
  sendInvoiceManually,
  uploadFile,
} from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { Link } from "react-router-dom";
import { Router, useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import {
  toastConfiguration,
  transactionDateFormatter,
  trackIntercomEvent,
  getConnectStatus,
} from "../../../../utils/utils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { toTitleCase } from "../../../../utils/utils";
import Modal from "../../../../components/Modal";
import Transactions from "../../../../components/Transactions";
import DateInput from "../../../../components/DateInput";
import EmailPopup from "../../../../components/Email/EmailPopup";

let connect = 1;

const NewInvoice = ({ className, invoiceId, setVisibleOverview }) => {
  const [visible, setVisible] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showActionBtn, setShowActionBtn] = useState(false);
  const [updateInvoice, setUpdateInvoice] = useState(false);

  const [startDataLoading, setStartDataLoading] = useState(true);
  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [selectedCustomerEmail, setSelectedCustomerEmail] = useState("");
  const [selectedCustomerPhone, setSelectedCustomerPhone] = useState("");

  const [customerImage, setCustomerImage] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [invoiceObject, setInvoiceObject] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [showCopiedText, setShowCopiedText] = useState(false);
  const [lines, setLines] = useState(invoiceObject?.lines);
  const [invoiceLoading, setInvoiceLoading] = useState(true);

  const [isManual, setIsManual] = useState(false);
  const [isThroughStripe, setIsThroughStripe] = useState(false);

  const [showPaidModal, setShowPaidModal] = useState(false);
  const [showTranModal, setShowTranModal] = useState(false);
  const [transactionPayLoading, setTransactionPayLoading] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [accountDetailPayload, setAccountDetailPayload] = useState({});
  const [isNextPageExist, setIsNextPageExist] = useState(false);
  const [lastId, setLastId] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [markAsPaidLoading, setMarkAsPaidLoading] = useState(false);

  const [customerTaxExempt, setCustomerTaxExempt] = useState("");

  const [dbTaxRates, setDbTaxRates] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showNewProductModal, setShowNewProductModal] = useState(false);

  const history = useHistory();

  useEffect(async () => {
    const connectStatus = getConnectStatus();
    connect = connectStatus?.status;

    if (invoiceId === "create" || !invoiceId) {
      setSelectedCustomerName("");
      setSelectedCustomerEmail("");
      setSelectedCustomerPhone("");
      setCustomerImage("");
      setVisible(true);
      setShowSuccessModal(false);
      setShowActionBtn(false);
      setUpdateInvoice(false);

      setStartDataLoading(true);
      setSelectedCustomerName("");
      setShowDetail(false);
      setInvoiceStatus(false);
      setInvoiceObject({});
      setDeleteLoading(false);
      setSendLoading(false);
      setStatus("");
      setShowCopiedText(false);
      setLines(invoiceObject?.lines);
    } else {
      setStartDataLoading(true);
      const { data } = await getInvoice(invoiceId);
      const invoice = data?.invoice;
      if (invoice.isPaymentManual) {
        setIsManual(true);
      } else {
        setIsThroughStripe(true);
      }
      setCustomerTaxExempt(invoice?.customerTaxExempt);
      setDbTaxRates(invoice?.taxRates);
      setSelectedCustomerName(invoice?.customerName);
      setSelectedCustomerEmail(invoice?.customerEmail);
      setSelectedCustomerPhone(invoice?.customerPhone);
      setCustomerImage(invoice?.customerImage);
      setInvoiceStatus(invoice?.status);
      setInvoiceObject(invoice);
      setStatus(invoice?.status);
      setShowDetail(true);
    }
    setInvoiceLoading(false);
  }, [invoiceId]);

  const emailModalCloseHandler = () => setShowEmailModal(false);

  const deleteHandler = async () => {
    setDeleteLoading(true);

    const { data } = await removeDraftInvoice({ invoiceId });
    if (data) {
      toast.success("Invoice deleted Successfully", toastConfiguration);

      setTimeout(() => {
        history.push("/payments/tabs/1");
        setDeleteLoading(false);
      }, 2000);
    } else {
      toast.error("Error in deleting Invoice", toastConfiguration);
      setDeleteLoading(false);
    }
  };

  const printDocument = async (returnPdf) => {
    const input = document.getElementById("divToPrint");
    const card = document.getElementById("card-1");

    const text1 = document.getElementById("text-1");
    const text2 = document.getElementById("text-2");
    const text3 = document.getElementById("text-3");

    const image = document.getElementById("img-1");

    const prevContainerBgColor = input?.style?.background;
    const prevInnerContainerBgColor = card?.style?.background;
    const prevTextColor = text1?.style?.color;
    const prevImageSrc = image?.src;

    if (input && card) {
      input.style.size = "8.3in 11.7in";
      input.style.padding = "17mm 16mm 27mm 16mm";
      input.style.background = "#FFFFFF";
      card.style.background = "#FFFFFF";
      if (prevTextColor !== "#1A1D1F") {
        text1.style.color = "#1A1D1F";
        text2.style.color = "#1A1D1F";
        text3.style.color = "#1A1D1F";
      }

      if (image) image.src = "/images/logo-dark.png";
    }

    const canvas = await html2canvas(input, {
      scale: 3,
    });
    if (canvas) {
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("p", "em", [60, 70]); //[595.28, 841.89]

      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, width, height, "", "FAST");
      if (returnPdf) {
        return pdf;
      } else {
        if (invoiceObject?.invoiceNumber) {
          pdf.save(invoiceObject?.invoiceNumber?.replaceAll("-", "_") + ".pdf");
        } else {
          pdf.save(invoiceId?.replaceAll("-", "_") + ".pdf");
        }
      }
    }

    if (input) {
      input.style.size = "unset";
      input.style.padding = "unset";
      input.style.background = prevContainerBgColor;
      card.style.background = prevInnerContainerBgColor;
      if (prevTextColor !== "#1A1D1F") {
        text1.style.color = prevTextColor;
        text2.style.color = prevTextColor;
        text3.style.color = prevTextColor;
      }
      if (prevImageSrc && image) image.src = prevImageSrc;
    }
  };

  const printDocumentHelper = async (returnPdf) => {
    var pdf = await printDocument(returnPdf);
    return pdf;
  };

  const sendInvoiceHandler = async () => {
    if (sendLoading) {
      return;
    }

    let data;
    let error;
    if (isManual) {
      setSendLoading(true);

      const pdf = await printDocument(true);

      var file_name = "Invoice.pdf";
      if (invoiceObject.invoiceNumber) {
        file_name = invoiceObject.invoiceNumber.replaceAll("-", "_") + ".pdf";
      }
      var file_object = new File([pdf.output("blob")], file_name, {
        type: "application/pdf",
      });

      const formData = new FormData();
      formData.append("", file_object);
      const uploadRes = await uploadFile(formData);

      const manualRecieptResponse = await sendInvoiceManually({
        invoiceId,
        fileUrl: uploadRes?.data?.file?.url,
      });

      data = manualRecieptResponse?.data;
      error = manualRecieptResponse?.error;

      trackIntercomEvent("invoice-manual-created", { invoiceId: invoiceId });

      setSendLoading(false);
      if (data) {
        const invoice = data?.invoice;
        setInvoiceObject(invoice);
        toast.success("Invoice sent Successfully", toastConfiguration);
        setShowEmailModal(true);
      } else if (error) {
        toast.error(error, toastConfiguration);
      } else {
        toast.error("Error while sending invoice", toastConfiguration);
      }
    } else {
      setShowEmailModal(true);
    }
  };

  const postInvoiceSendHandler = (responseInvoiceObject, recipient) => {
    setInvoiceObject(responseInvoiceObject);
    setSelectedCustomerEmail(recipient);
    setShowSuccessModal(true);
    trackIntercomEvent("invoice-nonmanual-created", { invoiceId: invoiceId });
  };

  const payThroughTrans = async (x) => {
    setShowTranModal(false);
    setTransactionPayLoading(true);

    const { data } = await markManuallyInvoiceAsPaid({
      invoiceId: invoiceObject?.id,
      paidType: "transaction",
      paidTransactionId: x?.id,
      paidAccountId: x?.accountId,
      paidAccountType: x?.accountType,
    });

    if (data) {
      toast.success("Invoice marked as paid successfully", toastConfiguration);
      history.push("/payments/tabs/6");
    } else {
      toast.error("Error in marking invoice as paid", toastConfiguration);
    }

    setTransactionPayLoading(false);
  };

  const markAsPaidHandler = async (date, paidType) => {
    setMarkAsPaidLoading(true);

    const { data } = await markManuallyInvoiceAsPaid({
      invoiceId: invoiceObject?.id,
      paidType: paidType,
      paidDate: transactionDateFormatter(date),
    });

    if (data) {
      toast.success("Invoice marked as paid successfully", toastConfiguration);
      history.push("/payments/tabs/6");
    } else {
      toast.error("Error in marking invoice as paid", toastConfiguration);
    }

    setMarkAsPaidLoading(false);
  };

  return (
    <>
      {visible ? (
        !showEmailModal ? (
          <>
            {markAsPaidLoading || transactionPayLoading ? (
              <>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    zIndex: 70,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "lightgray",
                    opacity: 0.6,
                  }}
                ></div>
                <div
                  style={{
                    position: "fixed",
                    left: "calc(50% - 20px)",
                    top: "calc(50% - 20px)",
                    zIndex: 100,
                  }}
                >
                  <Spinner size={40} color={"gray"} />
                </div>
              </>
            ) : null}
            <ToastContainer />
            <div
              className={styles.row}
              style={
                status !== "draft" && invoiceId && status !== "create"
                  ? { pointerEvents: "none" }
                  : { pointerEvents: "auto" }
              }
            >
              {!invoiceLoading ? (
                <>
                  <div
                    className={
                      showNewProductModal ? styles.fullWidth : styles.col
                    }
                    style={
                      status === "draft" || !invoiceId
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <InvoiceCustomer
                      setShowActionBtn={setShowActionBtn}
                      setUpdateInvoice={setUpdateInvoice}
                      updateInvoice={updateInvoice}
                      setVisibleOverview={setVisible}
                      invoiceId={invoiceId}
                      setInvoiceObject={setInvoiceObject}
                      startDataLoading={startDataLoading}
                      selectedCustomerName={selectedCustomerName}
                      selectedCustomerEmail={selectedCustomerEmail}
                      customerImage={customerImage}
                      setCustomerImage={setCustomerImage}
                      dbTaxRates={dbTaxRates}
                      showDetail={showDetail}
                      invoiceStatus={invoiceStatus}
                      lines={lines}
                      setLines={setLines}
                      customerTaxExempt={customerTaxExempt}
                      setStartDataLoading={setStartDataLoading}
                      setSelectedCustomerName={setSelectedCustomerName}
                      setShowDetail={setShowDetail}
                      invoiceObject={invoiceObject}
                      isManual={isManual}
                      isThroughStripe={isThroughStripe}
                      setIsManual={setIsManual}
                      setIsThroughStripe={setIsThroughStripe}
                      selectedCustomerPhone={selectedCustomerPhone}
                      connect={connect}
                      showNewProductModal={showNewProductModal}
                      setShowNewProductModal={setShowNewProductModal}
                      sendButton={
                        invoiceObject?.status !== "paid" &&
                        invoiceObject?.status !== "open" && (
                          <button
                            onClick={sendInvoiceHandler}
                            className={cn("button", styles.sendBtn)}
                            style={{
                              background: "#FF4900",
                            }}
                          >
                            {sendLoading ? (
                              <Spinner
                                loading={sendLoading}
                                size="24"
                                color="white"
                              />
                            ) : (
                              <>
                                <span>Send</span>
                              </>
                            )}
                          </button>
                        )
                      }
                      downloadButton={
                        invoiceObject?.status === "draft" && (
                          <button
                            onClick={() => printDocumentHelper()}
                            className={cn("button-stroke-red", styles.button)}
                            style={{ marginLeft: 8 }}
                          >
                            {/* <Icon name='download' size='24' /> */}
                            <span>Download Pdf</span>
                          </button>
                        )
                      }
                    />
                  </div>

                  <div
                    className={styles.col2}
                    style={showNewProductModal ? { display: "none" } : {}}
                  >
                    {!updateInvoice ? (
                      Object.keys(invoiceObject).length > 0 && (
                        <InvoicePreview
                          className={styles.preview}
                          printDocument={printDocument}
                          title
                          qty
                          unitPrice
                          tax
                          taxType
                          amount
                          invoiceObject={invoiceObject}
                        />
                      )
                    ) : (
                      <InvoicePreview invoiceObject={invoiceObject} />
                    )}
                  </div>
                </>
              ) : (
                <div className={styles.spinner}>
                  <Spinner loading={invoiceLoading} size={40} color={"gray"} />
                </div>
              )}
            </div>

            <SaveSuccess
              showSuccessModal={showSuccessModal}
              setShowSuccessModal={setShowSuccessModal}
              selectedCustomerEmail={selectedCustomerEmail}
              invoiceObject={invoiceObject}
              onConfirm={() => {
                setVisible(false);
                setShowSuccessModal(false);
                history.push("/payments/tabs/4");
              }}
            />

            {false ? (
              <div className={styles.row}>
                <Card className={styles.actionCard}>
                  {invoiceObject?.status !== "draft" && (
                    <button
                      onClick={() => history.push("/payments/tabs/1")}
                      className={cn("button", styles.button)}
                    >
                      {/* <Icon name='save' size='24' /> */}
                      <span>Save</span>
                    </button>
                  )}
                  {status !== "draft" && invoiceId ? null : (
                    <>
                      <DeleteModal
                        className={styles.deleteBtn}
                        deleteBtn={
                          <button
                            className="button"
                            onClick={deleteHandler}
                            style={{ width: 150 }}
                          >
                            {deleteLoading ? (
                              <Spinner
                                loading={deleteLoading}
                                size="24"
                                color="white"
                              />
                            ) : (
                              "Yes I'm sure!"
                            )}
                          </button>
                        }
                      />

                      {/* <button
                      onClick={() => history.push("/payments/tabs/3")}
                      className={cn("button-stroke", styles.button)}
                    >
                      <Icon name="save" size="24" />
                      <span>Save Draft</span>
                    </button> */}
                    </>
                  )}
                  {invoiceObject?.status !== "draft" ? (
                    !isManual ? ( //invoiceObject?.status !== "draft"
                      <a
                        // onClick={() => setVisible(false)}
                        className={cn("button-stroke-red", styles.button)}
                        href={invoiceObject?.invociePdf}
                        target="_blank"
                        download
                      >
                        {/* <Icon name='download' size='24' /> */}
                        <span>Download Pdf</span>
                      </a>
                    ) : (
                      <button
                        onClick={() => printDocumentHelper()}
                        className={cn("button-stroke-red", styles.button)}
                      >
                        {/* <Icon name='download' size='24' /> */}
                        <span>Download Pdf</span>
                      </button>
                    )
                  ) : null}

                  {invoiceObject?.status !== "draft" &&
                    invoiceObject?.status !== "paid" &&
                    invoiceObject?.hostedInvoiceUrl && (
                      <div>
                        <button
                          onClick={() => {
                            navigator?.clipboard?.writeText(
                              invoiceObject?.hostedInvoiceUrl
                            );
                            toast.success(
                              "Link copied successfully",
                              toastConfiguration
                            );
                          }}
                          className={cn("button-stroke-red", styles.button)}
                        >
                          {/* <Icon name='copy' size='24' /> */}
                          <span>{"Copy Payment Link"} </span>
                        </button>
                      </div>
                    )}

                  {/* {invoiceObject?.status !== "paid" && invoiceObject?.status !== "open" && (
                  <button
                    onClick={sendInvoiceHandler}
                    className={cn("button", styles.sendBtn)}
                    style={{ width: 150 }}
                  >
                    {sendLoading ?
                      <Spinner loading={sendLoading} size="24" color="white" />
                      :
                      <>
                        <Icon name="rocket" size="24" />
                        <span>Send</span>
                      </>
                    }
                  </button>
                )} */}
                  {invoiceObject.isPaymentManual &&
                  invoiceObject.status === "open" ? (
                    <button
                      className={cn("button", styles.sendBtn)}
                      onClick={() => setShowPaidModal(true)}
                    >
                      <Icon name="multiSelect" size="24" />
                      Mark as Paid
                    </button>
                  ) : null}
                  <SaveSuccess
                    showSuccessModal={showSuccessModal}
                    setShowSuccessModal={setShowSuccessModal}
                    selectedCustomerEmail={selectedCustomerEmail}
                    invoiceObject={invoiceObject}
                    onConfirm={() => {
                      setVisible(false);
                      setShowSuccessModal(false);
                      history.push("/payments/tabs/4");
                    }}
                  />

                  {/* Paid Modal */}
                  <Modal
                    onClose={() => setShowPaidModal(false)}
                    visible={showPaidModal}
                    children={
                      <>
                        <Card
                          className={styles.paidModalCard}
                          classCardHead={styles.head}
                          title="How did you get paid?"
                          /* classTitle={cn("title-blue", styles.title)} */
                        >
                          <p>
                            Choose how you wish to register this transaction
                          </p>
                          <button
                            onClick={() => {
                              setShowPaidModal(false);
                              const dateInputId =
                                document.getElementById("dateInputId");
                              dateInputId.focus();
                            }}
                            className={cn("button-stroke", styles.cashBtn)}
                          >
                            <Icon name="notes" size="24" />
                            Cash
                          </button>
                          <button
                            onClick={async () => {
                              setLoading(true);
                              const { data } = await pageLoadTransactions({
                                FromDate: transactionDateFormatter(
                                  new Date(
                                    new Date().setDate(
                                      new Date().getDate() - 30
                                    )
                                  )
                                ),
                                ToDate: transactionDateFormatter(new Date()),
                              });
                              if (data?.accounts) {
                                setAccounts(data?.accounts);
                                setAccountDetailPayload(
                                  data?.accountDetailPayload
                                );
                                setIsNextPageExist(data?.isNextPageExist);
                                setLastId(data?.transactions?.at(-1)?.id);
                                setTransactions(data?.transactions);
                                setShowPaidModal(false);
                                setShowTranModal(true);
                              } else {
                              }
                              setLoading(false);
                            }}
                            className={cn(
                              "button-stroke",
                              styles.chooseTranBtn
                            )}
                          >
                            {loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Spinner size="24" color="gray" />
                              </div>
                            ) : (
                              <>
                                <Icon name="justify" size="24" />
                                Choose transaction
                              </>
                            )}
                          </button>
                        </Card>
                      </>
                    }
                  />

                  {/* Transaction Modal */}
                  <Modal
                    outerClassName={styles.transactionsWrap}
                    visible={showTranModal}
                    onClose={() => {
                      setShowTranModal(false);
                      setShowPaidModal(true);
                    }}
                    children={
                      <>
                        <Transactions
                          transactions={transactions}
                          accountDetailPayload={accountDetailPayload}
                          setAccountDetailPayload={setAccountDetailPayload}
                          accounts={accounts}
                          lastId={lastId}
                          isNextPageExist={isNextPageExist}
                          setLastId={setLastId}
                          setTransactions={setTransactions}
                          setIsNextPageExist={setIsNextPageExist}
                          className={styles.transaction}
                          payThroughTrans={payThroughTrans}
                          isComingFromModal={true}
                        />
                      </>
                    }
                  />
                  {/* DateInput Modal */}

                  <DateInput
                    className={styles.dateInput}
                    isWithPortal={true}
                    portalClassInput={styles.input}
                    classInputValue={styles.inputValue}
                    dateInputId="dateInputId"
                    invoiceId={invoiceObject?.id}
                    markAsPaidLoading={markAsPaidLoading}
                    markAsPaidHandler={markAsPaidHandler}
                  />
                </Card>
              </div>
            ) : null}
          </>
        ) : (
          <EmailPopup
            showModal={showEmailModal}
            setShowEmailModal={setShowEmailModal}
            closeModalHandler={emailModalCloseHandler}
            isInvoice={true}
            id={invoiceId}
            postSendHandler={postInvoiceSendHandler}
          />
        )
      ) : (
        <InvoiceOverview />
      )}
    </>
  );
};

export default NewInvoice;
