import React from "react";
import { Link, useLocation } from "react-router-dom";
import Card from "../../../components/Card";
import styles from "./MoneyIn.module.sass";
import cn from "classnames";
import { useEffect, useState } from "react";
import AmountInCents from "../../../components/AmountInCents";
import Icon from "../../../components/Icon";
import { useHistory } from "react-router";
import Spinner from "../../../utils/spinner";

const MoneyIn = ({ className, lastMonthTransactions, linkExistingBankAccountHandler }) => {
  const [isMoreTransactions, setIsMoreTransactions] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);
  const [transactionsToShow, setTransactionsToShow] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setTransactionsToShow(prepareMoneyIn(lastMonthTransactions));
  }, [lastMonthTransactions]);

  const handleSingleTransaction = (x) => {
    history.push({
      pathname: `/transactions`,
      state: {
        isFetchTransactionData: false,
        transactionPayload: x,
        showTransactionPopupFromPayload: true,
        // transactionPayload: x,
        // accountType: x.accountType,
        // accountId: x.accountId,
        // status: x.status,
        // amount: x.amount,
        // desc: x.description
      },
    });
  };

  const prepareMoneyIn = (transactions) => {
    var inTransactions = [];
    for (let index = 0; index < transactions.length; ++index) {
      let transaction = transactions[index];
      if (transaction.amountValue > 0) {
        inTransactions.push(transaction);
        if (inTransactions.length >= 5) {
          setIsMoreTransactions(true);
          break;
        }
      }
    }
    return inTransactions;
  };

  const formatDisplayDate = (dt) => {
    let val = new Date(dt).toLocaleDateString(
      {},
      { timeZone: "UTC", month: "long", day: "2-digit", year: "numeric" }
    );
    return val;
  };

  const cutLongDescription = (desc) => {
    if (desc && desc.length > 20) {
      return desc.substring(0, 20) + "...";
    }
    return desc;
  };

  return (
    <div className={styles.moneyIn}>
      <Card
        border
        className={cn(transactionsToShow?.length < 1 ? styles.emptyCard : styles.card, className)}
        title='Money in'
        /* classTitle="title-green" */
        classCardHead={styles.cardHead}
      >
        {/* <h1 className={styles.totalAmount}>$4884.<span>00</span></h1> */}
        <ul className={styles.invWrap}>
          {transactionsToShow?.length < 1 && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span >
                No transactions
              </span>
              <button
                onClick={() => linkExistingBankAccountHandler(setLinkLoading)}
                className={cn("button", styles.addProductBtn)}
                style={{ width: 210, position: 'absolute', bottom: 20 }}
              >
                {linkLoading ?
                  <Spinner size="24" color="white" />
                  :
                  "Sync a bank account"
                }
              </button>
            </div>
          )}
          {transactionsToShow.map((x, index) => (
            <div
              key={index}
              onClick={() => {
                handleSingleTransaction(x);
              }}
              style={{ cursor: "pointer" }}
            >
              <li>
                <div className={styles.left}>
                  <h3>
                    {x.invoiceNumber
                      ? x.invoiceNumber
                      : cutLongDescription(x.description)}
                  </h3>
                  <p>{formatDisplayDate(x.created)}</p>
                </div>
                <div className={styles.right}>
                  <h3>
                    <AmountInCents
                      amountInCents={x.amountValue}
                      subtitleEmValue={"0.5em"}
                      currency={x.currency}
                      isReversed={true}
                    />
                  </h3>
                  <p>{x.accountType}</p>
                </div>
              </li>
            </div>
          ))}
        </ul>
        <br />
      </Card>
    </div>
  );
};

export default MoneyIn;
