import React, { useState } from 'react';
import styles from './FocusDetails.module.sass';
import Dropdown from '../../../components/Dropdown';

const tabs = [
    "Choose",
    "Digital Goods",    
    "Consulting Services",
    "Ecommerce",
    "Real Estate",
    "Other"
]

const FocusDetails = ({ setCurrentStep, setDetails, details }) => {
    const [focus, setFocus] = useState(tabs[0]);

    return <div className={styles.wrapper}>
        <div>
            <h3 className={styles.heading}>Nice to meet you {details?.user?.name} 👋</h3>
            <h3 className={styles.subHeading}>What best describes your focus and passion?</h3>
            <Dropdown
                bodyStyles={{ width: '100%' }}
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={focus}
                setValue={setFocus}
                options={tabs}
                onChange={(value) => {
                    setDetails({
                        ...details,
                        company: {
                            ...details?.company,
                            businessAboutType: value
                        }
                    })
                    setCurrentStep(2)
                }}
                small
            />
        </div>
    </div>
}

export default FocusDetails;