import React, { useState, useReducer } from "react";
import styles from "./SingleContract.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Icon from "../../../../components/Icon";
import DeleteModal from "../../../../components/DeleteModal";
import UpdateContractForm from '../UpdateContractForm'
import Modal from "../../../../components/Modal";
import Card from "../../../../components/Card";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import { deleteContractStudio, updateContract } from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";

const SingleContract = ({
  x,
  setX,
  remove,
  update,
  getContractFunction,
  setContracts,
  isUpdate,
  setIsUpdate,
  setUpdateContractLoading,
}) => {
  const [showActionBtn, setShowActionBtn] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);

  const [deleteContractLoading, setDeleteContractLoading] = useState(false);

  

  useEffect(() => {
    const viewWrap = document.getElementById("viewWrap")
    if (viewWrap) {
      viewWrap.innerHTML = x.trixEditorValue
    }
  })

  return (
    <>
      <div className={styles.singleContract} id={x?.id}>
        <p
          onClick={() => {
            setX(x);
           
          }}
        >
          {x.name}
        </p>
        <button
          id={x.id}
          onClick={() => {
            setShowDeleteModal(true);
          }}
          className={cn(styles.deleteItem)}
        >
          <Icon name="trash" size="24" />
        </button>
      </div>

      <DeleteModal
        visibleDelete={showDeleteModal}
        setVisibleDelete={setShowDeleteModal}
        button={true}
        deleteBtn={
          <button
            className="button"
            style={{ width: 200 }}
            id={x.id}
            onClick={async (e) => {
              setDeleteContractLoading(true);
              const { data } = await deleteContractStudio(x?.id)
              if (data) {
                setContracts([]);
                toast.success("Successfully deleted contract", toastConfiguration);
                await getContractFunction(1);
              } else {
                toast.error("Error in deleting contract", toastConfiguration);
              }
              setShowActionBtn(false);
              setShowDeleteModal(false);
              setDeleteContractLoading(false);
            }}
          >
            {deleteContractLoading ?
              <Spinner size="24" color="white" />
              :
              "Yes I'm sure!"
            }
          </button>
        }
      />
      {showViewModal ? (
        <Modal
          visible={showViewModal}
          onClose={() => setShowViewModal(false)}
          children={
            <Card
              title="View Template"
              /* classTitle="title-blue" */
              className={styles.card}
              children={
                <div id="viewWrap" className={styles.viewWrap}>
                </div>
              }
            />
          }
        />
      ) : null}
    </>

  );
};

export default SingleContract;
