import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../../components/Checkbox";
import { dateFormatter } from "../../../../../utils/utils";
import Image from "../../../../../components/Image";
import AlternativeAvatar from "../../../../../components/AlternativeAvatar";
import Icon from "../../../../../components/Icon";
import Spinner from "../../../../../utils/spinner";

const Row = ({
    item,
    onChange,
    activeTable,
    activeId,
    editLender,
    checkCount,
    deleteIdArray,
    chatClickHanlder,
}) => {
    item.icons = "dots";

    return (
        <>
            <div
                className={cn(
                    styles.row,
                    { [styles.selected]: activeId === item?.id },
                    { [styles.active]: activeTable }
                )}
                onClick={() => {
                    editLender()
                }}
            >
                <div className={styles.col}>
                    <div
                        className={cn(
                            styles.userImage,
                            checkCount < 1 ? [styles.showUserImg] : [styles.hideUserImg]
                        )}
                    >
                        {item?.lenderLogoImageUrl ? (
                            <Image
                                className={styles.avatar}
                                src={item.lenderLogoImageUrl}
                                srcDark={item.lenderLogoImageUrl}
                            />
                        ) : (
                            <AlternativeAvatar name={item?.lenderName} />
                        )}
                    </div>
                </div>
                {/* <div className={styles.editOverlay}></div> */}
                <div className={styles.col}>
                    <span className={styles.mblCreate}>Name:</span>
                    <div className={styles.user}>
                        <span>{item?.lenderName}</span>
                    </div>
                </div>
                <div className={styles.col}>
                    <span className={styles.mblCreate}>Type:</span>
                    <div className={styles.date}>{item?.lenderTypeName}</div>
                </div>
                <div className={styles.col}>
                    <span className={styles.mblCreate}>Type:</span>
                    <div className={styles.stage} style={{
                        backgroundColor: !item?.status ? 'unset' : item?.status?.toLowerCase() === 'apply' ? '#FF4900' :
                            item?.status?.toLowerCase() === 'offer_recieved' ? '#FFA96B' :
                                '#464542',
                        color: !item?.status ? 'black' : 'white'
                    }}>{item?.statusName || "-"}</div>
                </div>
                <div className={styles.col} onClick={(e) => {
                    e.stopPropagation();
                    chatClickHanlder(item)
                }}>
                    <span className={styles.mblCreate}>Chat:</span>
                    <Icon name="chat" size="24" viewBox="0 0 24 24" />
                </div>
            </div>
        </>
    );
};

export default Row;
