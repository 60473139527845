import React, { useEffect, useState } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Row from "./Row";
import DeleteModal from "../../../../components/DeleteModal";
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import { Checkbox, TriStateCheckbox, TriStateContext } from "react-tri-state-checkbox";
import "react-tri-state-checkbox/styles/checkbox.css";

import { accountsLedger } from "../../../../mocks/accountsLedger";
import { getCOAList } from "../../../../utils/apiCallHanlder";
import { useHistory } from "react-router";
import Spinner from "../../../../utils/spinner";
import LargeNav from "../../../../components/LargeNav";
import Dropdown from "../../../../components/Dropdown";
import Search from "../Search";
import ExportAllGledgerPopup from "../../../../components/ExportAllGledgerPopup";
import ExportAllCoaAccountsPopup from "../../../../components/ExportAllCoaAccountsPopup";

const pageSize = 30;
let lastId = "";
let isFirstLoad = true;

const Table = ({
  className,
  editAction,
  changeLedger,
  changeAllLedger,
  editProduct,
  needNewData,
  setNeedNewData,
  COAList,
  setCOAList,
  setShowAccountInfo,
  liabilitiesAccounts,
  assetsAccounts,
  setVisible,
  searchString,
  setSearchString,
  visibleExportCOAPopup,
  setVisibleExportCOAPopup
}) => {
  const [isNextPageExist, setIsNextPageExist] = useState(false);
  const [types, setTypes] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1);
  const [visibleExportPopup, setVisibleExportPopup] = useState(false);

  const [currentlySelectedItem, setCurrentlySelctedItem] = useState({
    id: 0,
    name: "All",
    uniqueId: 0,
  });
  const [loading, setLoading] = useState(true);
  const [dropdownTypes, setDropdownTypes] = useState([]);
  const [singleType, setSingleType] = useState("All");

  const ledgerId = accountsLedger.map((x) => x.id);

  useEffect(async () => {
    if (COAList?.length < 1) {
      lastId = "";
    }

    await getCOAListByStatus();
    setLoading(false);
  }, [needNewData]);

  const getCOAListByStatus = async () => {
    setLoading(true);
    const { data } = await getCOAList(
      currentlySelectedItem?.uniqueId !== "Archived"
        ? {
          isFirstLoad,
          Coa1Id: currentlySelectedItem?.uniqueId,
        }
        : {
          isFirstLoad,
          isArchived: true,
        },
      pageSize,
      lastId
    );
    if (data) {
      setIsNextPageExist(data?.isNextPageExist);
      setCOAList(data?.list);
      const typesList = [
        { id: 0, name: "All", uniqueId: 0 },
        ...data?.type,
        { id: "Archived", name: "Archived", uniqueId: "Archived" },
      ];
      setDropdownTypes(typesList.map((x) => x.name));
      setTypes(typesList);
      setCurrentlySelctedItem(typesList[activeIndex - 1]);
      lastId = data?.invoiceList?.at(-1)?.id;
      isFirstLoad = false;
    }
  };

  return (
    <>
      <ExportAllGledgerPopup
        visible={visibleExportPopup}
        coa1Id={currentlySelectedItem?.uniqueId}
        setVisible={setVisibleExportPopup}></ExportAllGledgerPopup>

      <ExportAllCoaAccountsPopup
        visible={visibleExportCOAPopup}
        coa1Id={currentlySelectedItem?.uniqueId}
        setVisible={setVisibleExportCOAPopup}></ExportAllCoaAccountsPopup>
      <Card className={styles.card}>
        <TriStateContext controls={ledgerId}>
          <div className={cn(styles.wrapper, className)}>
            <div className={styles.nav} style={{ marginTop: -24 }}>
              <LargeNav
                tabOptions={types}
                activeTab={activeIndex - 1}
                category="name"
                onClick={(x, index) => {
                  lastId = "";
                  setCurrentlySelctedItem(x);
                  setActiveIndex(index + 1);
                  setCOAList([]);
                  setNeedNewData(!needNewData);
                }}
              />
              <Dropdown
                className={styles.dropdown}
                options={dropdownTypes}
                value={singleType}
                setValue={setSingleType}
                handleChange={(x) => {
                  lastId = "";
                  setCurrentlySelctedItem(x);
                  setCOAList([]);
                  setNeedNewData(!needNewData);
                }}
                handleChangeIndex={(index) => {
                  setActiveIndex(index + 1);
                }}
              />
            </div>
            <div className={styles.dateParent}>
              <div>
                <Search
                  className={styles.search}
                  inputPlaceholder="Search"
                  addNewBtnTxt="Create new Account"
                  addNewItem={() => setShowAccountInfo(true)}
                  liabilitiesAccounts={liabilitiesAccounts}
                  assetsAccounts={assetsAccounts}
                  showDetails={() => setVisible(false)}
                  searchString={searchString}
                  setSearchString={setSearchString}
                />
              </div>
              <div className={styles.child2}>
                <button
                  className={"button-stroke-red"}
                  style={{ marginRight: 12 }}
                  onClick={() => {
                    setVisibleExportPopup(true);
                  }}>
                  Export Ledger
                </button>
              </div>
            </div>
            {loading && COAList?.length < 1 ? (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 48 }}>
                <Spinner loading={loading} size={50} color={"gray"} />
              </div>
            ) :
              <div className={cn(styles.table)} style={{ position: "relative" }}>
                <div className={cn(styles.row)}>
                  <div className={styles.editOverlayHead}></div>
                  {/* <div className={styles.col}>
                  <TriStateCheckbox
                    onClick={changeAllLedger}
                    className={styles.triHead}
                  ></TriStateCheckbox>
                </div> */}
                  {/* <div className={styles.col}>#</div> */}
                  <div className={styles.col}>Account Name</div>
                  <div className={styles.col}>Type</div>
                  <div className={cn(styles.col, styles.typeNumber)}>Debit</div>
                  <div className={cn(styles.col, styles.typeNumber)}>Credit</div>
                  <div className={cn(styles.col, styles.typeNumber)}>Balance</div>
                  <div className={styles.col}></div>
                </div>
                {COAList.map((x, index) => (
                  <Row
                    item={x}
                    key={index}
                    singleCheck={
                      <Checkbox
                        onClick={changeLedger}
                        className={cn(x.image ? styles.triCheckboxImg : styles.triCheckbox)}
                        id={x.id}
                      />
                    }
                    editAction={editAction && editAction}
                    toggleArchivedText="Archived Product"
                    editProduct={editProduct}
                  />
                ))
                }
                {isNextPageExist ? (
                  <div className={styles.foot}>
                    <button
                      className={cn("button-stroke-red button-small", styles.button)}
                      onClick={() => getCOAListByStatus()}
                      style={{ width: 200 }}
                    >
                      {loading ? (
                        <Spinner loading={loading} size={20} color={"gray"} />
                      ) : (
                        <>
                          <span>See More</span>
                          <Icon name="arrow-next" size="20"></Icon>
                        </>
                      )}
                    </button>
                  </div>
                ) : null}
              </div>
            }
          </div>
        </TriStateContext>
      </Card>
    </>
  );
};

export default Table;
