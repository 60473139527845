import React, { useEffect, useState } from "react";
import Card from "../../../../components/Card";
import Dropdown from "../../../../components/Dropdown";
import styles from "./Payment.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import Notifications from "./Notifications";
import { ToastContainer, toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import {
  getPaymentSettings,
  updatePaymentsSetting,
} from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import BankAccount from "./BankAccount";
import PaymentMethod from "./PaymentMethod";
import PayoutSchedule from "./PayoutSchedule";

const Payment = ({ className, openModal, bankAccountsRight }) => {
  const [startLoading, setStartLoading] = useState(true);

  const [settings, setSettings] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [payoutDetail, setPayoutDetail] = useState({});
  const [cardPaymentArray, setCardPaymentArray] = useState([]);
  const [canAddPayoutBank, setCanAddPayoutBank] = useState(false);
  const [getNewRecords, setGetNewRecords] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setErrorMessage] = useState("");

  useEffect(async () => {
    setStartLoading(true);
    const { data } = await getPaymentSettings();
    if (data) {
      setCardPaymentArray(data?.paymentMethods);
      setSettings(data?.defaultPaymentMethods);
      setPayoutDetail(data?.payoutDetail);
      setBankAccounts(data?.bankAccounts);
      setCanAddPayoutBank(data?.canAddPayoutBankAccount);
    }
    setStartLoading(false);
  }, [getNewRecords]);

  return (
    <>
      {/* <ToastContainer />

      <BankAccount
        bankAccounts={bankAccounts}
        startLoading={startLoading}
        openModal={openModal}
        canAddPayoutBank={canAddPayoutBank}
        getNewRecords={getNewRecords}
        setGetNewRecords={setGetNewRecords}
      /> */}
      <PaymentMethod
        startLoading={startLoading}
        settings={settings}
        setErrorMessage={setErrorMessage}
        cardPaymentArray={cardPaymentArray}
        setCardPaymentArray={setCardPaymentArray}
        isLoading={isLoading}
        bankAccountsRight={bankAccountsRight}
      />
      {/* <PayoutSchedule payoutDetail={payoutDetail} startLoading={startLoading} /> */}

      {/* <div style={{ marginTop: 20 }}>
        <div className={styles.closeTitle}>Payout Schedule</div>
        <div className={styles.container}>
          <div className={styles.closeSubtitle}>
            Set a schedule to automatically recieve payouts to your bank account
          </div>
        </div>
        <div style={{ marginTop: 20, width: "200px" }}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={frequency}
            setValue={setFrequency}
            options={frequencies}
          />
        </div>
        <div className={styles.closeTitle} style={{ marginTop: 30 }}>
          Payment Methods
        </div>
        <div className={styles.container}>
          <div className={styles.closeSubtitle}>
            Payments will go directly to your bank account. Various payment
            methods have different settling times
          </div>
        </div>
      </div>
      <div style={{ marginTop: 30 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.text}>Payment type</div>
          <div className={styles.text}>Status</div>
        </div>
        <hr style={{ color: "gray", opacity: 0.4, marginTop: 20 }} />
        <div style={{ marginTop: 20 }}>
          <div className={styles.list}>
            {startLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 150,
                }}
              >
                <Spinner loading={startLoading} size="50" color={"gray"} />
              </div>
            ) : (
              settings.map((x, index) => (
                <div key={index}>
                  <Notifications
                    x={x}
                    setErrorMessage={setErrorMessage}
                    settings={settings}
                    cardPaymentArray={cardPaymentArray}
                    setCardPaymentArray={setCardPaymentArray}
                    showBanks={true}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>

         <Card className={styles.actionCard}>
        {/* <button className={cn("button-stroke", styles.button)}>
          <Icon name="arrow-left" size="24" />
          <span>Cancel</span>
        </button> */}
      {/* <button
          onClick={savePaymentHandler}
          className={cn("button", styles.button)}
        >
          {isLoading ? (
            <Spinner loading={isLoading} size="24" color="white" />
          ) : (
            "Save"
          )}
        </button>
      </Card>
      <span style={{ color: "red", marginLeft: 25, position: "absolute" }}>{error}</span> */}

      {/* <hr style={{ color: "gray", opacity: 0.4, marginTop: 30 }} />  */}
    </>
  );
};

export default Payment;
