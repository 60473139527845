import React, { useState, useEffect } from "react";
import styles from "./InvoiceOverview.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Table from "./Table";
import Icon from "../../../../components/Icon";
import NewInvoice from "../NewInvoice";
import DeleteModal from "../../../../components/DeleteModal";
import { useHistory } from "react-router";

import { invoices } from "../../../../mocks/invoices";
import PaymentsHeader from "../../PaymentsHeader";

const ProductOverview = ({
  activeIndex,
  currentPage
}) => {
  const [visible, setVisible] = useState(true);
  const [allInvoicesCheckCount, setAllInvoicesCheckCount] = useState(0);
  const [allInvoicesAction, setAllInvoicesAction] = useState(true);
  const [scheduledInvoicesCheckCount, setScheduledInvoicesCheckCount] =
    useState(0);
  const [scheduledInvoicesAction, setScheduledInvoicesAction] = useState(true);
  const [draftInvoicesCheckCount, setDraftInvoicesCheckCount] = useState(0);
  const [draftInvoicesAction, setDraftInvoicesAction] = useState(true);
  const [outstandingInvoicesCheckCount, setOutstandingInvoicesCheckCount] =
    useState(0);
  const [outstandingInvoicesAction, setOutstandingInvoicesAction] =
    useState(true);
  const [pastDueInvoicesCheckCount, setPastDueInvoicesCheckCount] = useState(0);
  const [pastDueInvoicesAction, setPastDueInvoicesAction] = useState(true);
  const [paidInvoicesCheckCount, setPaidInvoicesCheckCount] = useState(0);
  const [paidInvoicesAction, setPaidInvoicesAction] = useState(true);
  const [actionBtnValue, setActionBtnValue] = useState(1);
  const [show, setShow] = useState(false);

  const history = useHistory();

  /* All Invoice handling */
  const allInvoiceLength = Object.keys(invoices).length;
  const handleAllInvoice = (e) => {
    var ariaStatus = e.target.getAttribute("aria-Checked");
    if (ariaStatus === "true") {
      setAllInvoicesAction(true);
      setAllInvoicesCheckCount(0);
    }
    if (ariaStatus === "false") {
      setAllInvoicesAction(false);
      setAllInvoicesCheckCount(allInvoiceLength);
    }
    if (ariaStatus === "mixed") {
      setAllInvoicesAction(false);
      setAllInvoicesCheckCount(allInvoiceLength);
    }
  };

  const handleAllInvoicesSingle = (e) => {
    if (e.target.checked) {
      setAllInvoicesCheckCount(allInvoicesCheckCount + 1);
    }
    if (!e.target.checked) {
      setAllInvoicesCheckCount(allInvoicesCheckCount - 1);
    }
  };

  /* Scheduled Invoice handling */
  const scheduledInvoicesLength = Object.keys(
    invoices.filter((x) => x.invoiceType === "Scheduled")
  ).length;

  const handleScheduledInvoice = (e) => {
    var ariaStatus = e.target.getAttribute("aria-Checked");
    if (ariaStatus === "true") {
      setScheduledInvoicesAction(true);
      setScheduledInvoicesCheckCount(0);
    }
    if (ariaStatus === "false") {
      setScheduledInvoicesAction(false);
      setScheduledInvoicesCheckCount(scheduledInvoicesLength);
    }
    if (ariaStatus === "mixed") {
      setScheduledInvoicesAction(false);
      setScheduledInvoicesCheckCount(scheduledInvoicesLength);
    }
  };

  const handleScheduledInvoicesSingle = (e) => {
    if (e.target.checked) {
      setScheduledInvoicesCheckCount(scheduledInvoicesCheckCount + 1);
    }
    if (!e.target.checked) {
      setScheduledInvoicesCheckCount(scheduledInvoicesCheckCount - 1);
    }
  };

  /* Draft Invoice handling */
  const draftInvoicesLength = Object.keys(
    invoices.filter((x) => x.invoiceType === "Draft")
  ).length;

  const handleDraftInvoice = (e) => {
    var ariaStatus = e.target.getAttribute("aria-Checked");
    if (ariaStatus === "true") {
      setDraftInvoicesAction(true);
      setDraftInvoicesCheckCount(0);
    }
    if (ariaStatus === "false") {
      setDraftInvoicesAction(false);
      setDraftInvoicesCheckCount(draftInvoicesLength);
    }
    if (ariaStatus === "mixed") {
      setDraftInvoicesAction(false);
      setDraftInvoicesCheckCount(draftInvoicesLength);
    }
  };

  const handleDraftInvoicesSingle = (e) => {
    if (e.target.checked) {
      setDraftInvoicesCheckCount(draftInvoicesCheckCount + 1);
    }
    if (!e.target.checked) {
      setDraftInvoicesCheckCount(draftInvoicesCheckCount - 1);
    }
  };

  /* Outstanding Invoice handling */
  const outstandingInvoicesLength = Object.keys(
    invoices.filter((x) => x.invoiceType === "Outstanding")
  ).length;

  const handleOutstandingInvoice = (e) => {
    var ariaStatus = e.target.getAttribute("aria-Checked");
    if (ariaStatus === "true") {
      setOutstandingInvoicesAction(true);
      setOutstandingInvoicesCheckCount(0);
    }
    if (ariaStatus === "false") {
      setOutstandingInvoicesAction(false);
      setOutstandingInvoicesCheckCount(outstandingInvoicesLength);
    }
    if (ariaStatus === "mixed") {
      setOutstandingInvoicesAction(false);
      setOutstandingInvoicesCheckCount(outstandingInvoicesLength);
    }
  };

  const handleOutstandingInvoicesSingle = (e) => {
    if (e.target.checked) {
      setOutstandingInvoicesCheckCount(outstandingInvoicesCheckCount + 1);
    }
    if (!e.target.checked) {
      setOutstandingInvoicesCheckCount(outstandingInvoicesCheckCount - 1);
    }
  };

  /* Past Due Invoice handling */
  const pastDueInvoicesLength = Object.keys(
    invoices.filter((x) => x.invoiceType === "Past Due")
  ).length;

  const handlePastDueInvoice = (e) => {
    var ariaStatus = e.target.getAttribute("aria-Checked");
    if (ariaStatus === "true") {
      setPastDueInvoicesAction(true);
      setPastDueInvoicesCheckCount(0);
    }
    if (ariaStatus === "false") {
      setPastDueInvoicesAction(false);
      setPastDueInvoicesCheckCount(pastDueInvoicesLength);
    }
    if (ariaStatus === "mixed") {
      setPastDueInvoicesAction(false);
      setPastDueInvoicesCheckCount(pastDueInvoicesLength);
    }
  };

  const handlePastDueInvoicesSingle = (e) => {
    if (e.target.checked) {
      setPastDueInvoicesCheckCount(pastDueInvoicesCheckCount + 1);
    }
    if (!e.target.checked) {
      setPastDueInvoicesCheckCount(pastDueInvoicesCheckCount - 1);
    }
  };

  /* Paid Invoice handling */
  const paidInvoicesLength = Object.keys(
    invoices.filter((x) => x.invoiceType === "Paid")
  ).length;

  const handlePaidInvoice = (e) => {
    var ariaStatus = e.target.getAttribute("aria-Checked");
    if (ariaStatus === "true") {
      setPaidInvoicesAction(true);
      setPaidInvoicesCheckCount(0);
    }
    if (ariaStatus === "false") {
      setPaidInvoicesAction(false);
      setPaidInvoicesCheckCount(paidInvoicesLength);
    }
    if (ariaStatus === "mixed") {
      setPaidInvoicesAction(false);
      setPaidInvoicesCheckCount(paidInvoicesLength);
    }
  };

  const handlePaidInvoicesSingle = (e) => {
    if (e.target.checked) {
      setPaidInvoicesCheckCount(paidInvoicesCheckCount + 1);
    }
    if (!e.target.checked) {
      setPaidInvoicesCheckCount(paidInvoicesCheckCount - 1);
    }
  };

  const handleEditProduct = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (allInvoicesCheckCount === 0) {
      setAllInvoicesAction(true);
    } else {
      setAllInvoicesAction(false);
    }

    if (scheduledInvoicesCheckCount === 0) {
      setScheduledInvoicesAction(true);
    } else {
      setScheduledInvoicesAction(false);
    }

    if (draftInvoicesCheckCount === 0) {
      setDraftInvoicesAction(true);
    } else {
      setDraftInvoicesAction(false);
    }

    if (outstandingInvoicesCheckCount === 0) {
      setOutstandingInvoicesAction(true);
    } else {
      setOutstandingInvoicesAction(false);
    }

    if (pastDueInvoicesCheckCount === 0) {
      setPastDueInvoicesAction(true);
    } else {
      setPastDueInvoicesAction(false);
    }

    if (paidInvoicesCheckCount === 0) {
      setPaidInvoicesAction(true);
    } else {
      setPaidInvoicesAction(false);
    }
  }, [
    allInvoicesCheckCount,
    scheduledInvoicesCheckCount,
    draftInvoicesCheckCount,
    outstandingInvoicesCheckCount,
    pastDueInvoicesCheckCount,
    paidInvoicesCheckCount,
  ]);

  return (
    <>
      {visible ? (
        <>
          <Card
            className={styles.card}
            title="Invoices"
            border
            /* classTitle={cn("title-blue", styles.title)} */
            classCardHead={cn(styles.head, { [styles.hidden]: show })}
            head={
              <>
                {actionBtnValue === 1 ? (
                  allInvoicesAction ? (
                    <button
                      onClick={() => setVisible(false)}
                      className={cn("button", styles.addProductBtn)}
                    >
                      {/* <Icon name="invoice" size="20" viewBox="0 0 24 24"/> */}
                      Send new invoice
                    </button>
                  ) : (
                    <DeleteModal
                      icon={
                        <Icon
                          className={styles.btnIcon}
                          fill="#FF4900"
                          name="trash"
                          size="20"
                        />
                      }
                      className={cn(styles.deleteBtn, styles.deleteMarkedBtn)}
                      btnClass={cn(styles.btnClass)}
                    />
                  )
                ) : null}
                {actionBtnValue === 2 ? (
                  scheduledInvoicesAction ? (
                    <button
                      onClick={() => setVisible(false)}
                      className={cn("button", styles.addProductBtn)}
                    >
                      Add new
                    </button>
                  ) : (
                    <DeleteModal
                      icon={
                        <Icon
                          className={styles.btnIcon}
                          fill="#FF4900"
                          name="trash"
                          size="20"
                        />
                      }
                      className={cn(styles.deleteBtn, styles.deleteMarkedBtn)}
                      btnClass={cn(styles.btnClass)}
                    />
                  )
                ) : null}
                {actionBtnValue === 3 ? (
                  draftInvoicesAction ? (
                    <button
                      onClick={() => setVisible(false)}
                      className={cn("button", styles.addProductBtn)}
                    >
                      Add new
                    </button>
                  ) : (
                    <DeleteModal
                      icon={
                        <Icon
                          className={styles.btnIcon}
                          fill="#FF4900"
                          name="trash"
                          size="20"
                        />
                      }
                      className={cn(styles.deleteBtn, styles.deleteMarkedBtn)}
                      btnClass={cn(styles.btnClass)}
                    />
                  )
                ) : null}
                {actionBtnValue === 4 ? (
                  outstandingInvoicesAction ? (
                    <button
                      onClick={() => setVisible(false)}
                      className={cn("button", styles.addProductBtn)}
                    >
                      Add new
                    </button>
                  ) : (
                    <DeleteModal
                      icon={
                        <Icon
                          className={styles.btnIcon}
                          fill="#FF4900"
                          name="trash"
                          size="20"
                        />
                      }
                      className={cn(styles.deleteBtn, styles.deleteMarkedBtn)}
                      btnClass={cn(styles.btnClass)}
                    />
                  )
                ) : null}
                {actionBtnValue === 5 ? (
                  pastDueInvoicesAction ? (
                    <button
                      onClick={() => setVisible(false)}
                      className={cn("button", styles.addProductBtn)}
                    >
                      Add new
                    </button>
                  ) : (
                    <DeleteModal
                      icon={
                        <Icon
                          className={styles.btnIcon}
                          fill="#FF4900"
                          name="trash"
                          size="20"
                        />
                      }
                      className={cn(styles.deleteBtn, styles.deleteMarkedBtn)}
                      btnClass={cn(styles.btnClass)}
                    />
                  )
                ) : null}
                {actionBtnValue === 6 ? (
                  paidInvoicesAction ? (
                    <button
                      onClick={() => setVisible(false)}
                      className={cn("button", styles.addProductBtn)}
                    >
                      Add new
                    </button>
                  ) : (
                    <DeleteModal
                      icon={
                        <Icon
                          className={styles.btnIcon}
                          fill="#FF4900"
                          name="trash"
                          size="20"
                        />
                      }
                      className={cn(styles.deleteBtn, styles.deleteMarkedBtn)}
                      btnClass={cn(styles.btnClass)}
                    />
                  )
                ) : null}
              </>
            }
          >
            <div style={{ marginTop: 20 }}>
            </div>
            <div className={cn(styles.row, { [styles.flex]: show })}>
              <Table
                className={styles.table}
                activeTable={show}
                setActiveTable={setShow}
                activeIndexx={activeIndex}
                editInvoice={() => setVisible(false)}
                handleAllInvoice={handleAllInvoice}
                handleAllInvoicesSingle={handleAllInvoicesSingle}
                handleScheduledInvoice={handleScheduledInvoice}
                handleScheduledInvoicesSingle={handleScheduledInvoicesSingle}
                handleDraftInvoice={handleDraftInvoice}
                handleDraftInvoicesSingle={handleDraftInvoicesSingle}
                handleOutstandingInvoice={handleOutstandingInvoice}
                handleOutstandingInvoicesSingle={
                  handleOutstandingInvoicesSingle
                }
                handlePastDueInvoice={handlePastDueInvoice}
                handlePastDueInvoicesSingle={handlePastDueInvoicesSingle}
                handlePaidInvoice={handlePaidInvoice}
                handlePaidInvoicesSingle={handlePaidInvoicesSingle}
                editProduct={handleEditProduct}
                actionBtnHandle={(e) => setActionBtnValue(e)}
              />
            </div>
          </Card>
        </>
      ) : (
        <NewInvoice setVisibleOverview={setVisible}/>
      )}
    </>
  );
};

export default ProductOverview;
