import { use100vh } from "react-div-100vh";
import { useHistory } from "react-router-dom";
import withUserAuth from "../../utils/withUserAuth";
import { useEffect } from "react";
import { externalSignIn } from "../../utils/apiCallHanlder";
import { getReturnUrl } from "../../utils/utils";
import Spinner from "../../utils/spinner";

const SignInGoogle = () => {
  const heightWindow = use100vh();
  const history = useHistory();

  useEffect(async () => {
    const deviceId = "1";

    const ReturnUrl = getReturnUrl('forward');
    const FailureURL = getReturnUrl('')
    const NewUserURL = getReturnUrl("sign-up-details");

    const signInResponse = await externalSignIn({ ReturnUrl, FailureURL, NewUserURL });
    const { data } = signInResponse;
    if (data) {
      window.location = data.redirectURL;
    } else {
      history.push('/');
    }
  }, []);

  return (
    <div style={{ position: 'fixed', width: '100%', height: '100%', left: 0, top: 0 }}>
      <div style={{ position: 'relative', left: '50%', top: '50%' }}>
        <Spinner size="34" color="gray"></Spinner>
      </div>
    </div>
  );
};

export default withUserAuth(SignInGoogle);
