import React from "react";
import styles from "./Portal.module.sass";
import Card from "../../components/Card";
import Icon from "../../components/Icon";
import Image from "../../components/Image";
import cn from "classnames";
import { useState, useLayoutEffect } from "react";
import Carousel from "react-multi-carousel";
import { Link, NavLink } from "react-router-dom";
import {
  directContractGetDetail,
  portalCustomerLogin,
  portalCustomerGetProfile,
  portalCustomerGetInvoices,
  portalCustomerGetContracts,
  portalCustomerGetSignedContracts,
  portalCustomerGetQuestionnaires,
  portalCustomerGetDocuments,
  portalCustomerGetStripePortalLink
} from "../../utils/apiCallHanlder";
import {
  handleCustomerPortalLogin,
  handleCustomerPortalProfile,
  isPortalUserLoggedIn,
  toastConfiguration,
  dateFormatter,
  getBaseURLOfCurrentApp,
  getCurrentUser,
} from "../../utils/utils";
import { toast, ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { useHistory } from "react-router";
import Spinner from "../../utils/spinner";
import { useRef } from "react";

let user = null;

const portalCards = [
  {
    userImg:
      "https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    todoName: "My Profile",
    actionName: "Update",
  },
  {
    iconName: "receipt",
    todoName: "Billing",
    actionName: "Pay invoice",
  },
  {
    iconName: "document",
    todoName: "Contracts",
    actionName: "Sign now",
  },
  {
    iconName: "help",
    todoName: "Questionnaires",
    actionName: "Answer now",
  },
  {
    iconName: "inbox",
    todoName: "Documents",
    actionName: "View",
  },
];

const Portal = () => {
  const history = useHistory();
  const divSignature = useRef();
  user = getCurrentUser();

  const [completedToDoCardData, setCompletedToDoCardData] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState("#FFFDF9");
  const [textColor, setTextColor] = useState("#000");
  const [welcomeText, setWelcomeText] = useState("");

  const [featuredProductsCarouselWidth, setFeaturedProductsCarouselWidth] =
    useState(0);
  const [itemsCarouselWidth, setItemsCarouselWidth] =
    useState(0);

  useLayoutEffect(() => {
    const featuredProductsCarouselWrapper = document.getElementById(
      "featuredProductsCarousel"
    );
    setFeaturedProductsCarouselWidth(
      featuredProductsCarouselWrapper?.offsetWidth || 0
    );

    const itemsCarouselWrapper = document.getElementById(
      "itemsCarousel"
    );
    setItemsCarouselWidth(
      itemsCarouselWrapper?.offsetWidth || 0
    );
  });

  const featuredProductsResponsive = {
    sizeOne: {
      breakpoint: { max: 8000, min: 0 },
      items: featuredProductsCarouselWidth / 216,
    },
  };

  const itemsResponsive = {
    sizeOne: {
      breakpoint: { max: 8000, min: 0 },
      items: itemsCarouselWidth / 216,
    },
  };


  //

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const code = params.get("code");
  const email = params.get("email");
  //
  const [loading, setLoading] = useState(true);
  const [portalSetup, setPortalSetup] = useState({});
  const [loadItemsData, setLoadItemsData] = useState(false);
  const [showItemsData, setShowItemsData] = useState(false);
  const [itemsData, setItemsData] = useState({});
  const [iconName, setIconName] = useState("");
  const [todoName, setTodoName] = useState("");
  const [actionName, setActionName] = useState("");
  const [lblDate, setlblDate] = useState("");
  const [doneItemsData, setDoneItemsData] = useState({});
  const [contractCount, setContractCount] = useState(0);
  const [signedContractCount, setSignedContractCount] = useState(0);
  const [questionnaireCount, setQuestionnaireCount] = useState(0);
  const [documentCount, setDocumentCount] = useState(0);
  const [invoiceCount, setInvoiceCount] = useState(0);

  useEffect(async () => {
    if (code) {
      const { data, error, message } = await portalCustomerLogin(
        {},
        code,
        email
      );
      setLoading(false);
      if (data) {
        handleCustomerPortalLogin(data);
        setPortalSetup(data.portalSetup);
        divSignature.current.innerHTML = data.portalSetup.signature;
        setBackgroundColor(data.portalSetup.backgroundColor);
        setTextColor(data.portalSetup.textColor);
        setInvoiceCount(data.invoices);
        setContractCount(data.contracts);
        setSignedContractCount(data.signedContracts);
        setQuestionnaireCount(data.questionnaires);
        setDocumentCount(data.documents);
        setShowItemsData(false);
      } else {
        toast.error(error, toastConfiguration);
        alert(error);
        history.push("/");
      }
    } else {
      if (isPortalUserLoggedIn()) {
        const { data, error, message } = await portalCustomerGetProfile({});
        setLoading(false);
        if (data) {
          handleCustomerPortalProfile(data);
          setPortalSetup(data.portalSetup);
          setBackgroundColor(data.portalSetup.backgroundColor);
          setTextColor(data.portalSetup.textColor);
          divSignature.current.innerHTML = data.portalSetup.signature;
          setShowItemsData(false);
        } else {
          toast.error(error, toastConfiguration);
          alert(error);
          history.push("/");
        }
      } else {
        alert("Login code not found");

        toast.error("Login code not found", toastConfiguration);
        history.push("/");
      }
    }
  }, []);

  const GoBackHandler = async () => {
    setIconName("");
    setTodoName("");
    setActionName("");
    setlblDate("");
    setShowItemsData(false);
    setItemsData({});
    setDoneItemsData({});
  };

  const GotoItemsHandler = async (item) => {
    setIconName(item.iconName);
    setTodoName(item.todoName);
    setActionName(item.actionName);

    switch (item.todoName) {
      case "Billing":
        setlblDate("Due");
        LoadInvoices();
        setShowItemsData(true);
        break;
      case "Contracts":
        setlblDate("View");
        LoadContracts();
        LoadSignedContracts();
        setShowItemsData(true);
        break;
      case "Questionnaires":
        LoadQuestionnaires();
        setShowItemsData(true);
        break;
      case "Documents":
        LoadDocuments();
        setShowItemsData(true);
        break;
      case "My Profile":
        GetStripePortalLink();
        break;
    }
  };

  const GetStripePortalLink = async () => {
    let returnUrl = getBaseURLOfCurrentApp() + `/portal?email=${email}&code=${code}`;
    const { data } = await portalCustomerGetStripePortalLink({ returnUrl: returnUrl });
    if (data && data.link) {
      window.location = data.link;
    }
  };

  const LoadInvoices = async () => {
    setLoadItemsData(true);
    const { data, error, message } = await portalCustomerGetInvoices({});
    setLoadItemsData(false);

    if (data) {
      setItemsData(data.invoices || []);
    } else {
      toast.error(error, toastConfiguration);
    }
  };

  const LoadContracts = async () => {
    setLoadItemsData(true);
    const { data, error, message } = await portalCustomerGetContracts({});
    setLoadItemsData(false);

    if (data) {
      setItemsData(data.contracts || []);
    } else {
      toast.error(error, toastConfiguration);
    }
  };

  const LoadSignedContracts = async () => {
    setLoadItemsData(true);
    const { data, error, message } = await portalCustomerGetSignedContracts({});
    setLoadItemsData(false);

    if (data) {
      setDoneItemsData(data.signedContracts || []);
    } else {
      toast.error(error, toastConfiguration);
    }
  };

  const LoadQuestionnaires = async () => {
    setLoadItemsData(true);
    const { data, error, message } = await portalCustomerGetQuestionnaires({});
    setLoadItemsData(false);

    if (data) {
      setItemsData(data.questionnaires || []);
    } else {
      toast.error(error, toastConfiguration);
    }
  };

  const LoadDocuments = async () => {
    setLoadItemsData(true);
    const { data, error, message } = await portalCustomerGetDocuments({});
    setLoadItemsData(false);

    if (data) {
      setItemsData(data.documents || []);
    } else {
      toast.error(error, toastConfiguration);
    }
  };

  const divWelcomeText = useRef();

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#f4f4f4",
            zIndex: 99,
          }}
        >
          <Spinner loading={loading} color={"gray"} size={50} />
        </div>
      ) : null}
      <ToastContainer></ToastContainer>
      <div className={styles.portal}>
        <Card className={styles.card} border>
          <div className={styles.portalWrap}>
            <div className={styles.portalPreview}>
              {portalSetup?.headerImageUrl ? (
                <Image
                  className={styles.cover}
                  src={portalSetup?.headerImageUrl}
                  srcDark={portalSetup?.headerImageUrl}
                />
              ) : (
                <div className={styles.cover}></div>
              )}

              <div className={styles.logoWrap}>
                <Image
                  src={portalSetup?.businessLogoUrl}
                  srcDark={portalSetup?.businessLogoUrl}
                  className={styles.logoImg}
                />
                {/* <p className={styles.name}>{portalSetup?.headerName}</p> */}
              </div>

              <div className={portalSetup?.welcomeText && styles.welcomeText}>
                <div
                  ref={divWelcomeText}
                  dangerouslySetInnerHTML={{ __html: portalSetup?.welcomeText }}
                ></div>
              </div>

              {!showItemsData &&
                <div className={styles.dataGroup}>
                  <div className={styles.portalCardWrap}>
                    {portalCards.map((x, index) => (
                      <div key={index}>
                        {((x.todoName == "Contracts" && (contractCount > 0 || signedContractCount > 0)) ||
                          (x.todoName == "Questionnaires" && questionnaireCount > 0) ||
                          (x.todoName == "Documents" && documentCount > 0) ||
                          (x.todoName == "Billing" && invoiceCount > 0) || x.todoName == "My Profile") &&
                          <div className={styles.portalCard}>
                            {x.userImg ? (
                              <Image
                                src={
                                  user?.imageurl
                                    ? user?.imageurl
                                    : "/images/content/placeholderUser.svg"
                                }
                                srcDark={
                                  user?.imageurl
                                    ? user?.imageurl
                                    : "/images/content/placeholderUser.svg"
                                }
                                className={styles.userImg}
                              />
                            ) : (
                              <Icon name={x.iconName} size="50" />
                            )}
                            <div className={styles.right}>
                              <div className={styles.cardTitle}>{x.todoName}</div>
                              <Link className={styles.action} onClick={() => GotoItemsHandler(x)}>
                                {(x.todoName == "Contracts" && contractCount <= 0) ?
                                  <span>View</span> :
                                  <span>{x.actionName}</span>}
                                <Icon name="arrow-right" size="14" />
                              </Link>
                            </div>
                          </div>
                        }

                      </div>

                    ))}
                  </div>
                </div>
              }

              {loadItemsData ? (
                <div style={{ position: 'relative', top: 30, left: "45%" }}>
                  <Spinner color={"gray"} loading={loadItemsData} size={50} />
                </div>
              ) : (
                <>
                  {(showItemsData && itemsData?.length > 0) &&
                    <div className={styles.dataGroup}>
                      <div className={styles.title}>
                        <Link className={styles.blackClr} onClick={GoBackHandler}>
                          <Icon name="arrow_backward" size="18" />
                          <span>Back to Portal</span>
                        </Link>
                      </div>

                      <div className={styles.label}>{todoName}</div>
                      <div id="itemsCarousel">
                        <Carousel
                          responsive={itemsResponsive}
                          autoPlay={false}
                          showDots={false}
                          arrows={false}
                          containerClass={styles.carouselContainer}
                          partialVisible={true}
                        >
                          {itemsData?.map((x, index) => (
                            <div key={index} className={styles.featuredCard}>
                              <Icon name={iconName} size="50" />
                              <div className={styles.right}>
                                <span className={styles.productName}>{x.description}</span>
                                <span className={styles.productDescription && styles.status}>
                                  {x.status}
                                </span>
                                {todoName == "Documents" &&
                                  <p className={styles.productDescription && styles.status}>{x.type}</p>
                                }
                                <p className={styles.productDescription}>{dateFormatter(x.createdOn)}</p>
                                {(todoName == "Billing" || todoName == "Contracts") &&
                                  <p className={styles.productDescription}>{lblDate}: {dateFormatter(x.date) || 'N/A'}</p>
                                }
                                {(x.linkUrl != undefined && x.linkUrl != null && x.linkUrl.length > 0) &&
                                  <a target={"_blank"} href={x.linkUrl} className={styles.action}>
                                    <span>{actionName}</span>
                                    <Icon name="arrow-right" size="14" />
                                  </a>
                                }
                                {((todoName == "Billing" || todoName == "Documents") && x.pdf != undefined && x.pdf != null && x.pdf.length > 0) &&
                                  <a placeholder="Download attachment" target={"_blank"} href={x.pdf} className={styles.blackClr}>
                                    <span className={styles.blackClr}>Download</span>
                                    <Icon name="download" size="18" />
                                  </a>
                                }
                              </div>
                            </div>
                          ))}
                        </Carousel>
                      </div>
                    </div>
                  }

                  {(todoName == "Contracts" && showItemsData && doneItemsData?.length > 0) &&
                    <div className={styles.dataGroup}>
                      {contractCount <= 0 &&
                        <div className={styles.title}>
                          <Link className={styles.blackClr} onClick={GoBackHandler}>
                            <Icon name="arrow_backward" size="18" />
                            <span>Back to Portal</span>
                          </Link>
                        </div>
                      }
                      <div className={styles.label}>Signed Contracts</div>
                      <div id="itemsCarousel">
                        <Carousel
                          responsive={itemsResponsive}
                          autoPlay={false}
                          showDots={false}
                          arrows={false}
                          containerClass={styles.carouselContainer}
                          partialVisible={true}
                        >
                          {doneItemsData?.map((x, index) => (
                            <div key={index} className={styles.featuredCard}>
                              <Icon name={iconName} size="40" />
                              <div className={styles.right}>
                                <span className={styles.productName}>{x.description}</span>
                                <p className={styles.productDescription}>{dateFormatter(x.createdOn)}</p>
                                {(todoName == "Contracts") &&
                                  <p className={styles.productDescription}>View: {dateFormatter(x.date)}</p>
                                }
                                {(todoName == "Contracts") &&
                                  <p className={styles.productDescription}>Signed: {dateFormatter(x.date)}</p>
                                }
                                {(x.linkUrl != undefined && x.linkUrl != null && x.linkUrl.length > 0) &&
                                  <a target={"_blank"} href={x.linkUrl} className={styles.action}>
                                    <span>View</span>
                                    <Icon name="arrow-right" size="14" />
                                  </a>
                                }
                              </div>
                            </div>
                          ))}
                        </Carousel>
                      </div>
                    </div>
                  }
                </>
              )}

              {portalSetup?.featureProducts?.length > 0 ? (
                <div className={styles.dataGroup}>
                  <div className={styles.label}>Featured products</div>
                  <div id="featuredProductsCarousel">
                    <Carousel
                      responsive={featuredProductsResponsive}
                      autoPlay={false}
                      showDots={false}
                      arrows={false}
                      containerClass={styles.carouselContainer}
                      partialVisible={true}
                    >
                      {portalSetup?.featureProducts.map((x, index) => (
                        <div key={index} className={styles.featuredCard}>
                          <Image
                            src={x.imageUrl}
                            className={styles.productImg}
                          />
                          <div className={styles.right}>
                            <div className={styles.productName}>{x.name}</div>
                            <p className={styles.productDescription}>
                              {x.description}
                            </p>
                            <p className={styles.price}>${x.price}</p>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </div>
              ) : null}
              <div
                className={
                  portalSetup?.signature &&
                  cn(styles.dataGroup, styles.signatureWrap)
                }
              >
                <div
                  ref={divSignature}
                  className={styles.signatureDetails}
                  dangerouslySetInnerHTML={{ __html: portalSetup?.signature }}
                ></div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Portal;
