import { withRouter } from "react-router";
import React, { useEffect, useState } from 'react';
import styles from "./Solar.module.sass";
import Card from "../../components/Card";
import { ToastContainer, toast } from "react-toastify";
import { createPalette, normalize, panelsPalette, rgbToColor, toastConfiguration } from "../../utils/utils";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import Icon from "../../components/Icon";
import InputSlider from "react-input-slider";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';


const google = window.google;
const containerStyle = {
  width: "100%",
  height: "680px",
};
const sliderStyles = {
  track: {
    marginTop: 18,
    backgroundColor: '#3788d8',
    width: '100%'
  },
  active: {
    backgroundColor: '#3788d8'
  },
}

const Solar = ({ backBtn, largeNav, address }) => {
  const [mapCenter, setMapCenter] = useState('');
  const [panelCount, setPanelCount] = useState(0);
  const [content, setContent] = useState([]);
  const [energy, setEnergy] = useState(0);
  const [solarPanels, setSolarPanels] = useState([]);

  const onLoad = React.useCallback(function callback(map) {
    if (mapCenter) {
      const bounds = new window.google.maps.LatLngBounds(mapCenter);
      bounds?.extend(mapCenter);
      map.fitBounds(bounds);
    }
  }, []);

  useEffect(() => {
    if (address) {
      const googleKey = 'AIzaSyCTpbuCUVv_C2RRr09oRimIlrjGzcP9C-0';

      const loadGoogleMaps = async () => {
        const geocoder = new google.maps.Geocoder();
        const geocoderResponse = await geocoder.geocode({
          address,
        });
        const geocoderResult = geocoderResponse?.results[0];

        if (geocoderResult) {
          const location = geocoderResult?.geometry?.location;
          setMapCenter(location);
          findClosestBuilding(geocoderResult?.geometry?.location, googleKey)
        }
      };

      loadGoogleMaps();
    }
  }, [address]);

  const findClosestBuilding = async (location, key) => {
    const args = {
      'location.latitude': location.lat().toFixed(5),
      'location.longitude': location.lng().toFixed(5),
    };

    const params = new URLSearchParams({ ...args, key });

    return fetch(`https://solar.googleapis.com/v1/buildingInsights:findClosest?${params}`).then(
      async (response) => {
        const content = await response.json();
        if (response.status != 200) {
          toast.error(content || "Geo API Error", toastConfiguration);
          throw content;
        }

        setPanelCount(Math.round((content?.solarPotential?.maxArrayPanelsCount || 0) / 2) || 0)
        setContent(content?.solarPotential)
        showSolarPotential(content?.solarPotential)
      },
    );
  }

  useEffect(() => {
    if (content && panelCount) {
      const item = content?.solarPanelConfigs?.reduce((closest, item) => {
        if (item.panelsCount === panelCount) {
          return item;
        }

        if (item?.panelsCount < panelCount && item.panelsCount > (closest ? closest?.panelsCount : -Infinity)) {
          return item;
        }
        return closest;
      }, null);

      if (item) {
        setEnergy(item?.yearlyEnergyDcKwh)
      }
    }
  }, [content, panelCount])

  const showSolarPotential = async (potential) => {
    if (!potential) return;
    const palette = createPalette(panelsPalette).map(rgbToColor);
    const minEnergy = potential.solarPanels.slice(-1)[0].yearlyEnergyDcKwh;
    const maxEnergy = potential.solarPanels[0].yearlyEnergyDcKwh;
    const newSolarPanels = potential.solarPanels.map((panel) => {
      const [w, h] = [potential.panelWidthMeters / 2, potential.panelHeightMeters / 2];
      const points = [
        { x: +w, y: +h },
        { x: +w, y: -h },
        { x: -w, y: -h },
        { x: -w, y: +h },
        { x: +w, y: +h },
      ];
      const orientation = panel.orientation == 'PORTRAIT' ? 90 : 0;
      const azimuth = potential.roofSegmentStats[panel.segmentIndex].azimuthDegrees;
      const colorIndex = Math.round(normalize(panel.yearlyEnergyDcKwh, maxEnergy, minEnergy) * 255);
      return new google.maps.Polygon({
        paths: points.map(({ x, y }) =>
          new window.google.maps.geometry.spherical.computeOffset(
            { lat: panel.center.latitude, lng: panel.center.longitude },
            Math.sqrt(x * x + y * y),
            Math.atan2(y, x) * (180 / Math.PI) + orientation + azimuth,
          ),
        ),
        strokeColor: '#B0BEC5',
        strokeOpacity: 0.9,
        strokeWeight: 1,
        fillColor: palette[colorIndex],
        fillOpacity: 0.9,
      });
    });

    setSolarPanels(newSolarPanels);
  }


  return (
    <>
      <ToastContainer />
      <Card
        className={styles.card}
        title="Property"
        border
        head={backBtn}
      >
        {largeNav}
        <div className={styles.mapContainer}>
          <div className={styles.installationWrapper}>
            <span className={styles.installTitle}>Typical Installation</span>
            <div className={styles.line}></div>
            <div className={styles.installDetailWrapper}>
              <div className={styles.innerDetailWrapper}>
                <i class="material-icons" style={{ color: '#3788d8' }}>wb_sunny</i>
                <span className={styles.installSubTitle}>Sunshine hours/year</span>
              </div>
              <span className={styles.installSubTitle}>{(content?.maxSunshineHoursPerYear || 0.0)?.toFixed(2)}</span>
            </div>
            <div className={styles.installDetailWrapper}>
              <div className={styles.innerDetailWrapper}>
                <i class="material-icons" style={{ color: '#3788d8' }}>square_foot</i>
                <span className={styles.installSubTitle}>Area meter<sup>2</sup></span>
              </div>
              <span className={styles.installSubTitle}>{(content?.wholeRoofStats?.areaMeters2 || 0.0)?.toFixed(2)}</span>
            </div>
            <div className={styles.installDetailWrapper}>
              <div className={styles.innerDetailWrapper}>
                <i class="material-icons" style={{ color: '#3788d8' }}>solar_power</i>
                <span className={styles.installSubTitle}>Max panels count</span>
              </div>
              <span className={styles.installSubTitle}>{content?.maxArrayPanelsCount}</span>
            </div>
          </div>
          <div className={styles.progressWrapper}>
            <div className={styles.singleItem}>
              <span className={styles.installTitle}>Panels Count</span>
              <div className={styles.circularBar}>
                <CircularProgressbar
                  value={panelCount}
                  maxValue={content?.maxArrayPanelsCount}
                  circleRatio={0.7}
                />
                <div className={styles.progressIcon}>
                  <i class="material-icons" style={{ color: '#3788d8' }}>solar_power</i>
                </div>
              </div>
              <span className={styles.installSubTitle}><span className={styles.blue}>{panelCount}</span>/{content?.maxArrayPanelsCount}</span>
            </div>
            <div className={styles.verticleLine}></div>
            <div className={styles.singleItem}>
              <span className={styles.installTitle}>Yearly Energy</span>
              <div className={styles.circularBar}>
                <CircularProgressbar
                  value={energy}
                  maxValue={content?.solarPanelConfigs?.[content?.solarPanelConfigs?.length - 1]?.yearlyEnergyDcKwh}
                  circleRatio={0.7}
                  styles={buildStyles({
                    pathColor: `#17B169`
                  })}
                />
                <div className={styles.progressIcon}>
                  <i class="material-icons" style={{ color: '#17B169' }}>eco</i>
                </div>
              </div>
              <span className={styles.installSubTitle}><span className={styles.green}>{energy}</span> kwh</span>
            </div>
          </div>
          <div className={styles.panelWrapper}>
            <span className={styles.installSubTitle}>Panels Count</span>
            <div className={styles.panel}>
              <InputSlider
                axis="x"
                xmin={content?.solarPanelConfigs?.length > 0 ? content?.solarPanelConfigs[0]?.panelsCount : 1}
                xmax={content?.maxArrayPanelsCount}
                styles={sliderStyles}
                x={panelCount}
                onChange={({ x }) => setPanelCount(x)}
              />
            </div>
            <span className={styles.installSubTitle}>{panelCount}</span>
          </div>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={20}
            onLoad={onLoad}
            options={{
              mapTypeId: "satellite",
              disableDefaultUI: true,
              fullscreenControl: false,
              zoomControl: true,
              tilt: 0
            }}
          >
            {solarPanels?.slice(0, panelCount).map((panel, index) => {
              const path = panel.latLngs?.Eg[0]?.Eg?.map(coord => ({
                lat: coord.lat(),
                lng: coord.lng()
              })) || [];

              return <Polygon
                key={index}
                path={path}
                options={{
                  strokeColor: panel.strokeColor,
                  strokeOpacity: panel.strokeOpacity,
                  strokeWeight: panel.strokeWeight,
                  fillColor: panel.fillColor,
                  fillOpacity: panel.fillOpacity,
                  visible: panel.visible,
                }}
              />
            })}
          </GoogleMap>
        </div>
      </Card>
    </>
  );
};

export default withRouter(Solar);
