import React, { useState } from "react";
import cn from "classnames";
import styles from "./Notifications.module.sass";
import Item from "../../../Item";
import Icon from "../../../../../components/Icon";
import Switch from "../../../../../components/Switch";
import Spinner from "../../../../../utils/spinner";
import { updatePaymentsSetting } from "../../../../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../../../utils/utils";
import { useEffect } from "react";

let isFirstLoading = true;

const Notifications = ({
  className,
  settings,
  showBanks,
  setCardPaymentArray,
  cardPaymentArray,
  setErrorMessage,
  x
}) => {

  const [value, setValue] = useState(cardPaymentArray.includes(x.value) ? x.value : '');
  const [isLoading, setIsLoading] = useState(false);

  const saveData = async (arrayToSend, valueToSet) => {
    setIsLoading(true);
    const res = await updatePaymentsSetting({
      paymentMethods: arrayToSend,
    });
    const { data, error } = res;
    if (data) {
      setValue(valueToSet);
      toast.success(
        "Payment Information Saved Successfully",
        toastConfiguration
      );
    } else {
      toast.error("Error in updating payment information", toastConfiguration);
    }
    setIsLoading(false);
  }

  const handleChange = async () => {
    setErrorMessage("");
    if (!value) {
      if (cardPaymentArray.includes(x.value)) {
        setCardPaymentArray([...cardPaymentArray]);
        await saveData([...cardPaymentArray], x.value);
      } else {
        setCardPaymentArray([...cardPaymentArray, x.value])
        await saveData([...cardPaymentArray, x.value], x.value);
      }
    } else {
      const index = cardPaymentArray?.indexOf(x?.value);
      if (index !== -1) {
        cardPaymentArray?.splice(index, 1);
        setCardPaymentArray([...cardPaymentArray]);
        await saveData([...cardPaymentArray], '');
      }
    }
  };

  return (

    <div className={styles.line}>
      {showBanks ?
        <>
          {/* <div style={{ width: '5%' }} className={styles.icon}>
            <Icon name="home" size="34" />
          </div> */}
          <div className={styles.titleX} style={{ display: "flex", width: '25%', paddingRight: "2.5%" }}>
            <Switch
              className={styles.switch}
              value={value}
              onChange={() => handleChange(x.value)}
            />
            <span>{x.display}</span>
          </div>
        </>
        : null}
      <div className={styles.title} style={showBanks ? { width: '75%', paddingLeft: "16px" } : null}>
        {x.description}{" "}
      </div>

      <div style={{ marginLeft: 10, visibility: isLoading ? 'visible' : 'hidden' }}>
        <Spinner color="gray" size="24" />
      </div>
    </div>

  );
};

export default Notifications;
