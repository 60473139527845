import React from "react";
import { useState } from "react";
import Icon from "../../../../components/Icon";
import styles from "./SingleField.module.sass";
import DeleteModal from "../../../../components/DeleteModal";
import Modal from "../../../../components/Modal";
import { useReducer } from "react";
import Card from "../../../../components/Card";
import TextInput from "../../../../components/TextInput";
import cn from "classnames";
import Spinner from "../../../../utils/spinner";
import { addCheckListField } from "../../../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import PreviewFields from "../SingleChecklist/PreviewFields";

const SingleField = ({
  x,
  remove,
  updateFields,
  deleteFieldLoading,
  setChecklists,
  getCheckListFunction,
  setShowFieldPopup,
  sortLoading,
  currentSortedfieldId
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isUpdateField, setIsUpdateField] = useState(false);
  const [updatedCheckListFieldLoading, setUpdatedCheckListFieldLoading] =
    useState(false);
  const [inputField, setInputField] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { fieldName: x.fieldName }
  );
  const handleChange = (e) => {
    setInputField({ [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputField?.fieldName || inputField?.fieldName.trim() === "") {
      toast.error("Please enter field name", toastConfiguration);
      return;
    }

    setUpdatedCheckListFieldLoading(true);
    const { data } = await addCheckListField({
      id: x?.checkListFieldId,
      checkListId: x?.checkListId,
      fieldName: inputField.fieldName,
      description: "",
      fieldType: "",
      fieldSequence: x?.fieldSequence,
      isChecked: x?.isChecked
    });
    if (data) {
      x.fieldName = inputField.fieldName;
    }

    setUpdatedCheckListFieldLoading(false);
    setIsUpdateField(false);
  };

  const updateChecklistCheckboxHandler = async (value) => {
    const { data } = await addCheckListField({
      id: x?.checkListFieldId,
      checkListId: x?.checkListId,
      fieldName: x?.fieldName,
      description: "",
      fieldType: "",
      fieldSequence: x?.fieldSequence,
      isChecked: value
    });
    if (data) {
      x.isChecked = value;
    }
  }

  return (
    <>

      {!isUpdateField ? (
        <div draggable>
          <div className={styles.field} id={x.id}>
            <div className={styles.left}>
              <PreviewFields
                x={x}
                sortLoading={sortLoading}
                currentSortedfieldId={currentSortedfieldId}
                updateChecklistCheckboxHandler={updateChecklistCheckboxHandler}
              />
            </div>
            <div className={styles.right}>
              <Icon name="justify" size="24" />
              <button type="button" onClick={() => {
                setInputField({ ['fieldName']: x.fieldName });
                setIsUpdateField(true);
              }}>
                <Icon name="note" size="24" />
              </button>
              <button
                id={x.id}
                onClick={() => {
                  setShowDeleteModal(true);
                }}
                type="button"
                className={styles.deleteBtn}
              >
                <Icon name="trash" size="24" />
              </button>
            </div>
          </div>
          <DeleteModal
            visibleDelete={showDeleteModal}
            setVisibleDelete={setShowDeleteModal}
            button={true}
            className={cn({ [styles.deleteModal]: !showDeleteModal })}
            deleteBtn={
              <button
                style={{ width: 200 }}
                className="button"
                id={x.checkListFieldId}
                type="button"
                onClick={async (e) => {
                  await remove(e.target.id);
                  setShowDeleteModal(false);
                }}
              >
                {deleteFieldLoading ? (
                  <Spinner size="24" color="white" />
                ) : (
                  "Yes I'm sure!"
                )}
              </button>
            }
          />
        </div>
      ) : (
        <Card
          className={styles.card}
        >
          <form
            onSubmit={handleSubmit} noValidate
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              flexWrap: 'wrap',
              justifyContent: 'space-between'
            }}
          >
            <TextInput
              className={styles.input}
              onChange={handleChange}
              name="fieldName"
              id="fieldName"
              type="text"
              value={inputField.fieldName}
              required
            />
            <div className={styles.newFieldBtn}>
              <button className="button" style={{ width: 100, marginRight: 10 }}>
                {updatedCheckListFieldLoading ? (
                  <Spinner size="24" color="white" />
                ) : (
                  <>
                    Edit
                  </>
                )}
              </button>

              <button
                type="button"
                className={`button-stroke ${styles.buttonClose}`}
                onClick={() => setIsUpdateField(false)}
                style={{ width: 50, padding: 0, paddingLeft: 3 }}
              >
                <Icon name="cross" size="24" />
              </button>
            </div>
          </form>
        </Card>
      )}
    </>
  );
};

export default SingleField;
