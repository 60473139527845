import React, { useReducer, useState } from "react";
import styles from "./NewFieldForm.module.sass";
import Modal from "../../../../components/Modal";
import uuid from "react-uuid";
import TextInput from "../../../../components/TextInput";
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import { addCheckListField } from "../../../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import Spinner from "../../../../utils/spinner";

const NewFieldForm = ({
  setShowFieldPopup,
  visible,
  setVisible,
  CreateField,
  x,
  getCheckListFunction,
  setChecklists,
}) => {
  const [addLoading, setAddLoading] = useState(false);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      fieldName: "",
    }
  );
  const handleChange = (e) => {
    setUserInput({ [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userInput?.fieldName || userInput?.fieldName.trim() === "") {
      toast.error("Please enter field name", toastConfiguration);
      return;
    }

    setAddLoading(true);
    const { data } = await addCheckListField({
      checkListId: x?.checkListId,
      fieldName: userInput.fieldName,
      fieldSequence: x?.fields?.length + 1,
      fieldType: "",
    });
    if (data) {
      const newChecklist = data?.checklistField;
      newChecklist.checkListFieldId = newChecklist?.id;

      x.fields = [...(x.fields || []), newChecklist]
      setShowFieldPopup(false);
    }

    setUserInput({ fieldName: "" });
    /* setVisible(false); */
    setAddLoading(false);
  };
  return (
    <Card
      className={styles.card}
    >
      <form onSubmit={handleSubmit} noValidate
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', flexWrap: 'wrap', justifyContent: 'space-between' }}
      >
        <TextInput
          className={styles.input}
          label="Field Name"
          onChange={handleChange}
          name="fieldName"
          id="fieldName"
          type="text"
          value={userInput.fieldName}
          required
        />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <button className="button" style={{ width: 100, marginRight: 8 }}>
            {addLoading ? (
              <Spinner size="24" color="white" />
            ) : (
              <>
                Save
              </>
            )}
          </button>
          <button
            type="button"
            className="button-stroke" style={{ width: 50, padding: 0, paddingLeft: 3 }}
            onClick={() => {
              setShowFieldPopup(false)
            }}
          >
            <Icon name="cross" size="24" />
          </button>
        </div>
      </form>
    </Card>
  );
};

export default NewFieldForm;
