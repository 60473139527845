import React, { useState } from "react";
import cn from "classnames";
import styles from "./CompleteProfile.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Image from "../../components/Image";
import AccountType from "./AccountType";
import Services from "./Services";
import { completeProfile } from "../../utils/apiCallHanlder";
import { handleLoginSignUpResponse, getStripeReturnURL, logoutHandler } from "../../utils/utils";
import withUserAuth from "../../utils/withUserAuth";
import Spinner from '../../utils/spinner';
import { useHistory } from "react-router-dom";
import { NAME_FIELD_ERROR } from "../../utils/appConstanst";

const countries = ["USA", "UK", "Spain"];

const CompleteProfile = () => {
  const heightWindow = use100vh();

  const [selectCountries, setSelectCountries] = useState(countries[0]);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [nameError, setNameError] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const servicesFilterHandler = (filter) => {
    setErrorMessage("");
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== filter));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, filter]);
    }
  };

  const nameSetHandler = (event) => {
    const value = event?.target?.value;
    value.length === 0 ? setNameError(true) : setNameError(false);

    setErrorMessage("");
    setName(value);
  }

  const lastNameSetHandler = (event) => {
    setErrorMessage("");
    setLastName(event?.target?.value);
  }

  const profileCompleteHandler = async () => {
    if (name === "") {
      setErrorMessage("Please Fill out Name Field");
    } else {

      setLoading(true);
      let banking = false;
      let accounting = false;
      let payment = false;

      selectedFilters.map(item => {
        if (item === "Banking") {
          banking = true;
        } else if (item === "Accounting") {
          accounting = true;
        } else {
          payment = true;
        }
      })

      const payload = {
        StoreType: activeIndex,
        Name: name,
        LastName: activeIndex === 1 ? lastName : "",
        Country: selectCountries,
        Banking: banking,
        Payment: payment,
        Accounting: accounting,
        returnURL: getStripeReturnURL()
      }

      const profileResponse = await completeProfile(payload);
      const { data, code } = profileResponse;


      if (code === 200) {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        handleLoginSignUpResponse(data, "");
      } else {
        setLoading(false);
        setErrorMessage(profileResponse.error);
      }
    }
  }

  return (
    <div className={styles.row}>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        <div className={styles.head}>
          <Link className={styles.logo} to="/complete-profile">
            <Image
              className={styles.pic}
              src="/images/logo-dark.png"
              srcDark="/images/logo-light.png"
              alt="Core"
            />
          </Link>
          <div className={styles.info} onClick={() => logoutHandler(history)}>
            Log out
          </div>
        </div>
        <div className={styles.wrap} style={{ paddingBottom: 30 }}>
          <div className={cn("h2", styles.title)}>Complete your profile</div>
          <div className={styles.typeWrap}>
            <div className={styles.left}>
              <AccountType
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                nameSetHandler={nameSetHandler}
                lastNameSetHandler={lastNameSetHandler}
                setSelectCountries={setSelectCountries}
                selectCountries={selectCountries}
                errorMessage={errorMessage}
                nameError={nameError}
              />
            </div>
            <div className={styles.right}>
              <Services
                servicesFilterHandler={servicesFilterHandler}
                selectedFilters={selectedFilters}
              />
            </div>
          </div>

          {errorMessage !== "" && errorMessage !== NAME_FIELD_ERROR ?
            <div className={styles.errorContainer}><span className={styles.errorMessage}>{errorMessage}</span></div>
            : null}

          <button className={cn("button", styles.button)} onClick={profileCompleteHandler}>
            {loading ? <Spinner loading={loading} size={30} /> : <span className={styles.link}>  Save & Continue </span>}
          </button>
          <Link className={styles.skipLink} to="/dashboard"> Skip this for now </Link>
        </div>
      </div>
    </div>
  );
};

export default withUserAuth(CompleteProfile);
