import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../components/Checkbox";
import { dateFormatter } from "../../../../utils/utils";
import Image from "../../../../components/Image";
import AlternativeAvatar from "../../../../components/AlternativeAvatar";

const Row = ({
    item,
    onChange,
    activeTable,
    activeId,
    editProperty,
    checkCount,
    deleteIdArray,
}) => {
    item.icons = "dots";

    return (
        <>
            <div
                className={cn(
                    styles.row,
                    { [styles.selected]: activeId === item?.propertyId },
                    { [styles.active]: activeTable }
                )}
            >
                <div className={styles.col}>
                    <div className={styles.checkboxCol}>
                        <Checkbox
                            className={styles.checkbox}
                            onChange={(e) => onChange(e, item.propertyId)}
                            name={"cus-checkbox"}
                            value={deleteIdArray?.includes(item.propertyId) ? true : false}
                        />
                        <div
                            className={cn(
                                styles.userImage,
                                checkCount < 1 ? [styles.showUserImg] : [styles.hideUserImg]
                            )}
                        >
                            {item?.imageUrl ? (
                                <Image
                                    className={styles.avatar}
                                    src={item.imageUrl}
                                    srcDark={item.imageUrl}
                                />
                            ) : (
                                <AlternativeAvatar name={item?.name} hideBorderRadius={true} />
                            )}
                        </div>
                    </div>
                </div>
                <div
                    onClick={() => editProperty(item.id)}
                    className={styles.editOverlay}
                ></div>
                <div className={styles.col}>
                    <span className={styles.typeTag}>Address:</span>
                    <div className={styles.date}>{item?.address || "-"}</div>
                </div>
                <div className={styles.col}>
                    <span className={styles.typeTag}>Name:</span>
                    <div className={styles.user}>
                        <span>{item?.name}</span>
                    </div>
                </div>
                <div className={styles.col}>
                    <span className={styles.typeTag}>Type:</span>
                    <div className={styles.created}>{item?.propertyType || "-"}</div>
                </div>
                <div className={styles.col}>
                    <span className={styles.typeTag}>Status:</span>
                    <div className={styles.created}>{item?.status || "-"}</div>
                </div>
            </div>
        </>
    );
};

export default Row;
