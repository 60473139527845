import React, { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { toastConfiguration, reverifyHandler, stripeConnectionStatus } from "../../utils/utils";
import Invoices from "./Invoices";
import PaymentsHeader from "./PaymentsHeader";
import Subscriptions from './Subscriptions'
import PaymentLink from './PaymentLink'
import Quotes from "./Quotes";
import cn from "classnames";
import styles from "./Payments.module.sass";
import Icon from "../../components/Icon";
import Image from "../../components/Image";
import Spinner from "../../utils/spinner";
import { Link } from "react-router-dom";
import { getIdentityVerifySession } from "../../utils/apiCallHanlder";
import VerifyIdentityModal from "../../components/VerifyIdentityModal";

let currentPage = 1;
let connect = 1
const Payments = (props) => {
  const { id, activeIndex, subId } = useParams();
  const [loading, setLoading] = useState(true);

  const customerId = props.location?.state?.customerId;
  if (typeof window !== "undefined") {
    var path = window?.location?.pathname;
    var page = path?.split("/")[1];

    if (page === "payments") {
      currentPage = 1;
    } else if (page === "subscriptions") {
      currentPage = 2;
    } else if (page === "paymentLinks") {
      currentPage = 3;
    } else if ((page === "quotes" || page === "quote") || connect !== 1) {
      currentPage = 4
    }
  }

  useEffect(() => {
    const connectStatus = JSON.parse(localStorage.getItem("connectStatus"));
    connect = connectStatus.status;
    setLoading(false);
  }, []);

  return (
    <>
      {!loading && (
        currentPage === 1 ? (
          <Invoices
            invoiceId={id}
            activeIndex={activeIndex}
            currentPage={currentPage}
          />
        ) : currentPage === 2 ? (
          <Subscriptions
            activeIndex={activeIndex}
            subId={subId}
            customerId={customerId}
            currentPage={currentPage}
          />
        ) : currentPage === 3 ? (
          <PaymentLink
            customerId={customerId}
            currentPage={currentPage}
            props={props}
          />
        ) : currentPage === 4 ? (
          <Quotes
            subId={subId}
            customerId={customerId}
            currentPage={currentPage}
          />
        ) : null
      )}
    </>
  );
};

export default withRouter(Payments);