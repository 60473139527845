import React, { useEffect, useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Image from "../../../../components/Image";
import AlternativeAvatar from "../../../../components/AlternativeAvatar";
import Icon from "../../../../components/Icon";
import Spinner from "../../../../utils/spinner";
import { makeAValidUrl } from "../../../../utils/utils";

const Row = ({
    item,
    activeTable,
    activeId,
    addLenderToPipeline
}) => {
    const [loading, setLoading] = useState(false);

    item.icons = "dots";

    return (
        <>
            <div
                className={cn(
                    styles.row,
                    { [styles.selected]: activeId === item.id },
                    { [styles.active]: activeTable }
                )}
            >
                <div className={styles.col}>
                    <div className={styles.checkboxCol}>
                        <div className={styles.userImage}>
                            {item.imageUrl ? (
                                <Image
                                    className={styles.avatar}
                                    src={item.imageUrl}
                                    srcDark={item.imageUrl}
                                />
                            ) : (
                                <AlternativeAvatar name={item.lenderName} />
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <span className={styles.mblCreate}>Name:</span>
                    <div className={styles.user}>
                        <span>{item.lenderName}</span>
                    </div>
                </div>
                <div className={styles.col} style={{ cursor: 'pointer' }} onClick={() => {
                    window.open(makeAValidUrl(item.website), '_blank');
                }}>
                    <span className={styles.mblCreate}>Links:</span>
                    <div className={styles.date} style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon name='world' size="20" viewBox="0 0 20 20" fill="#464542" />
                    </div>
                </div>
                <div className={styles.col}>
                    <span className={styles.mblCreate}>Stage:</span>
                    <div className={styles.created}>{item.email}</div>
                </div>
                <div className={styles.col}>
                    <span className={styles.mblCreate}>Focus:</span>
                    <div className={styles.created}>{item.indutries}</div>
                </div>
                <div className={styles.col} onClick={async () => {
                    setLoading(true);
                    const data = await addLenderToPipeline(item.lenderId)
                    setLoading(false);
                }}>
                    <span className={styles.mblCreate}></span>
                    <div className={cn(styles.created, styles.addPipeline)} style={{ minWidth: 120 }}>
                        {loading ?
                            <Spinner size={24} color={'white'} />
                            :
                            "Add to pipeline"
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Row;
