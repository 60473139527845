import React from "react";
import Card from "../../../../../components/Card";
import cn from "classnames";
import styles from "./LinkedAccount.module.sass";
import Modal from "../../../../../components/Modal";
import { useState } from "react";
import TextInput from "../../../../../components/TextInput";
import Icon from "../../../../../components/Icon";
import CashAccount from "../CashAccount";
import DeleteModal from "../../../../../components/DeleteModal";
import { deleteCashAccount } from "../../../../../utils/apiCallHanlder";

const mainBankAccounts = [
  {
    accName: "Evolve Bank Trust",
    accNum: "****6769",
    routingNum: "****0001",
    currency: "USD",
    flag: "./images/flag/us.png",
  },
];

const LinkedAccount = ({ linkedAccountDetails, gettingBankAccountDetails, isLinked, bankAccountsRight }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [showMainDetails, setShowMainDetails] = useState(false);
  const [changeMoreBtn, setChangeMoreBtn] = useState(false);

  const [openCashAccount, setOpenCashAccount] = useState(false);
  return (
    <>
      <Card className={cn(styles.cardWrap)}>
        <CashAccount
          showCashAccountModal={openCashAccount}
          isLinked={isLinked}
          setShowCashAccountModal={setOpenCashAccount}
          gettingBankAccountDetails={gettingBankAccountDetails}
          id={linkedAccountDetails?.id}
          accountNameX={linkedAccountDetails?.name}
          bankNameX={linkedAccountDetails?.bankName}
          detail1X={linkedAccountDetails?.detail1}
          detail2X={linkedAccountDetails?.detail2}
          currencyX={linkedAccountDetails?.currency}
          detail3X={linkedAccountDetails?.detail3}
          detail4X={linkedAccountDetails?.detail4}
          isInternationalX={linkedAccountDetails?.isInternational}
        />
        <Card
          className={cn(styles.card)}
          classCardHead={styles.head}
        /* title={
          <>
            <span>Details</span>
          </>
        }
        classTitle={cn("title-blue", styles.title)}
        head={
          cashAccountDetails ?
            <div className={styles.headRight}>
              <div className={styles.headBtns}>
                <button
                  className={cn("button-stroke button-small", styles.button)}
                  onClick={() => {
                    setOpenCashAccount(true)
                  }}
                >
                  <Icon size="20" name="edit" />
                  Edit
                </button>
              </div>
            </div>
            :
            null
        } */
        >
          <div className={styles.wrapper}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>Bank Account name</div>
                <div className={styles.col}>Account Number</div>
                <div className={styles.col}>Routing Number</div>
                {/* <div className={styles.col}>Account Type</div>
                <div className={styles.col}>Currency</div>
                <div className={styles.col}>Country</div> */}
                <div className={styles.col}></div>
              </div>
              {/* {mainBankAccounts.map((x, index) => ( */}
              <div className={styles.row}>
                <div className={styles.col}>
                  <p>Bank Account name</p>
                  <p>{linkedAccountDetails?.name}</p>
                </div>
                <div className={styles.col}>
                  <p>Account Number</p>
                  <p>
                    {linkedAccountDetails?.accountNumber ? "****" + linkedAccountDetails?.accountNumber?.substr(linkedAccountDetails?.accountNumber.length - 4) : null}
                  </p>
                </div>
                <div className={styles.col}>
                  <p>Routing Number</p>
                  <p>
                    {linkedAccountDetails?.accountRoutingNumber ? "****" + linkedAccountDetails?.accountRoutingNumber?.substr(linkedAccountDetails?.accountRoutingNumber.length - 4) : null}
                  </p>
                </div>
                {/* <div className={styles.col}>
                  <p>Account Type</p>
                  <p>
                    {linkedAccountDetails?.accountType}
                  </p>
                </div>
                <div className={styles.col}>
                  <p>Currency</p>
                  <p>{linkedAccountDetails?.currency}</p>{" "}
                </div>
                <div className={styles.col}>
                  <p>Country</p>
                  <p>{linkedAccountDetails?.country?.toUpperCase()}</p>{" "}
                </div> */}
                <div className={styles.col}>
                  <p></p>
                  {bankAccountsRight !== 2 && (
                    <div className={styles.headRight}>
                      <div className={styles.headBtns}>
                        <button
                          className={cn("button-stroke button-small", styles.button)}
                          onClick={() => {
                            setOpenCashAccount(true)
                          }}
                        >
                          <Icon size="20" name="edit" />
                          Edit
                        </button>
                        {/* {cashAccountDetails && (
                        <div style={{ marginTop: 7 }}>
                          <DeleteModal
                            icon={<Icon name="trash" size="24" fill="#FF4900" />}
                            btnTxt=" "
                            btnClass=" "
                            deleteFunction={deleteCashAccount}
                            id={linkedAccountDetails?.id}
                            onConfirm={async () => {
                              await gettingBankAccountDetails();
                            }}
                          />
                        </div>
                      )} */}
                      </div>
                    </div>
                  )}
                </div>
                <div>

                </div>
              </div>
              {/* ))} */}
            </div>
          </div>
        </Card>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <h3>Your Main Bank Account</h3>
        <p>Your Payment Balance will go to this account</p>
        <TextInput label="Account Holder Name" className={styles.input} />
        <TextInput label="Routing Number" className={styles.input} />
        <TextInput label="Account Number" className={styles.input} />
        <button
          className={cn("button", styles.button)}
          onClick={() => setVisibleModal(false)}
        >
          Save
        </button>
        <p>Your Bank Account must be a checking account</p>
      </Modal>
    </>
  );
};

export default LinkedAccount;
