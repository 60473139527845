import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Image from "../../../../components/Image";
import { deleteQuestionnaire, deleteSingleQuestionnaire, getContactQuestionnairesList } from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { dateFormatterWithTime, toastConfiguration, toTitleCase } from "../../../../utils/utils";
import styles from "./Questioners.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import { useHistory } from "react-router";
import contactQuestioners from '../../../../mocks/questioners.json';
import { dateFormatter } from "../../../../utils/utils";
import AlternativeAvatar from "../../../../components/AlternativeAvatar";

let page = 0;

const Questioners = ({
  id,
  name,
  email,
  mobileCode,
  mobile,
  imageURL,
  setTemplateName,
  setLink,
  setCreatedOn,
  setAnsweredOn,
  onClick,
  getUpdatedQuestionnaire,
}) => {

  let pageSize = 100;
  const [questionerList, setQuestionerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isNextPageExist, setIsNextPageExist] = useState(false);
  const [newDataLoading, setNewDataLoading] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const history = useHistory();

  useEffect(async () => {
    page = 0;
    await getContactPaymentHandler();
  }, [getUpdatedQuestionnaire]);

  const getContactPaymentHandler = async (loader) => {
    if (loader)
      loader(true)
    else
      setLoading(true);
    page = page + 1;
    const { data, error } = await getContactQuestionnairesList({
      page: page || 1,
      pageSize,
      contactId: id,
      email: email,
    });
    if (data?.questionnaires) {
      setQuestionerList(data?.questionnaires || []);
    } else {
      toast.error(error, toastConfiguration);
    }

    setIsNextPageExist(data?.isNextPageExist || false);


    if (loader)
      loader(false)
    else
      setLoading(false);
  };

  const editClickHandler = (item) => {
    if (item.paymentType == "Invoice")
      history.push(`/payments/${item.id}`);
    if (item.paymentType == "Subscription")
      history.push(`/subscriptions/${item.id}`);

  };

  const handleSingleQuestionersDetails = (x) => {
    setTemplateName(x.templateName)
    setCreatedOn(x.createdOn)
    setLink(x.link)
    setAnsweredOn(x.viewedOn)
    onClick()
  }


  return (
    <>
      <ToastContainer></ToastContainer>
      <div className={styles.infoWrapper}>
        {imageURL ?
          <Image
            src={imageURL || "/images/content/avatar.jpg"}
            srcDark={imageURL || "/images/content/avatar.jpg"}
            className={styles.pic}
          />
          :
          <AlternativeAvatar name={name} className={'small-icon'} />
        }
        <div className={styles.infoWrap}>
          <div className={styles.info}>
            <p className={styles.label}>Name</p>
            <p className={styles.txt}>{name}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.label}>Email</p>
            <p className={styles.txt}>{email}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.label}>Mobile</p>
            <p className={styles.txt}>
              {mobileCode} {mobile}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.table}>
        {loading ? (
          <div className={styles.spinner}>
            <Spinner loading={loading} size={50} color={"gray"} />
          </div>
        ) : (<>
          <div className={styles.head}>
            <div className={styles.col}>Template</div>
            <div className={styles.col}>Link</div>
            <div className={styles.col}>Sent</div>
            <div className={styles.col}>Answered</div>
            <div className={styles.col}></div>
          </div>

          <>
            {questionerList.map((x, index) => (
              <div className={styles.row} key={index} onClick={() => {
                history.push(`/contacts/questionnaire/${id}/${x?.id}`)
              }}>
                <div className={styles.col}>
                  <p className={styles.mblLabel}>Template</p>
                  <span>{x.templateName}</span>
                </div>
                <div className={styles.col}>
                  <p className={styles.mblLabel}>Link for customer</p>
                  <div className={styles.linkWrap}>
                    <span style={{ wordBreak: 'break-all', paddingRight: 16 }}>{x?.link}</span>
                    <button onClick={(e) => {
                      e.stopPropagation();
                      navigator?.clipboard?.writeText(x?.link);
                      toast.success("link copied successfully.", toastConfiguration)
                    }}>
                      <Icon name="multiselect" size="24" />
                    </button>
                  </div>
                </div>
                <div className={styles.col} style={{ width: 150 }}>
                  <p className={styles.mblLabel}>Sent</p>
                  <span>{dateFormatter(x.createdOn)}</span>
                </div>
                <div className={styles.col}>
                  <p className={styles.mblLabel}>Answered</p>
                  {x.answeredOn ? (
                    <span className={styles.success}>Yes</span>
                  ) : (
                    <span className={styles.danger}>No</span>
                  )}
                </div>
                <div className={styles.col}>
                  <p className={styles.mblLabel}></p>
                  {/* className={cn(styles[x.status] || styles.draft)} */}
                  {/* <span >{toTitleCase(x.status)}</span> */}
                  <button className={styles.deleteBtn} onClick={async (e) => {
                    e.stopPropagation();
                    setDeleteLoading(true);
                    setDeleteId(x?.id);
                    const { data } = await deleteSingleQuestionnaire(x.id, {});
                    if (data) {
                      page = 0;
                      await getContactPaymentHandler();
                      toast.success("Successfully deleted questionnaire", toastConfiguration);
                    } else {
                      toast.error("Error in deleting questionnaire", toastConfiguration);
                    }
                    setDeleteLoading(false);
                  }}>
                    {deleteLoading && deleteId === x?.id ?
                      <Spinner size="24" color="gray" />
                      :
                      <Icon name="trash" size="24" />
                    }
                  </button>
                </div>
              </div>
            ))}
          </>
        </>)}
        {isNextPageExist ?
          <div className={styles.foot}>
            <button className={cn("button-stroke-red button-small", styles.button)}
              onClick={() => getContactPaymentHandler(setNewDataLoading)}
              style={{ width: "250px" }}
            >
              {newDataLoading ?
                <Spinner size="24px" color="gray" loading={newDataLoading} />
                :
                (
                  <>
                    <span>See More Questionnaire</span>
                    <Icon name="arrow-next" size="20"></Icon>
                  </>
                )

              }
            </button>
          </div>
          : null}

      </div>
    </>
  );
};

export default Questioners;
