import React, { useEffect, useState } from "react";
import styles from "./AddEditEvent.module.sass";
import Modal from "../../../../../components/Modal";
import Icon from "../../../../../components/Icon";
import Card from "../../../../../components/Card";
import TextInput from "../../../../../components/TextInput";
import DatePicker from "react-datepicker";
import DateInput from "../../../../../components/DateInput";
import cn from "classnames";
import Spinner from "../../../../../utils/spinner";
import DeleteModal from "../../../../../components/DeleteModal";
import {
  createEvent,
  updateEvent,
  removeEvent,
} from "../../../../../utils/apiCallHanlder";
import { toastConfiguration } from "../../../../../utils/utils";
import { toast } from "react-toastify";

const AddEditEvent = ({
  showNewEventModal,
  setShowNewEventModal,
  editEvent,
  forceRefreshEvents,
  googleSync,
  id,
  customerName,
  customerImageUrl,
}) => {
  let defColor = "#5300eb";
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  //   const [customerId, setCustomerId] = useState(null);
  let date = new Date();
  date = Date.parse(date);
  const [startDate, setStartDate] = useState(date);
  const [startTime, setStartTime] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [endTime, setEndTime] = useState(
    new Date(new Date().getTime() + 60 * 60 * 1000)
  );
  const [color, setColor] = useState(defColor);
  const [showActionBtn, setShowActionBtn] = useState(false);

  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const handleColorChangeComplete = (color) => {
    setColor(color.hex);
  };

  const dateFormatter = (date) => {
    if (date) {
      const tempDate = new Date(date);
      return new Date(
        tempDate?.setMilliseconds(0 - tempDate?.getTimezoneOffset() * 60 * 1000)
      );
    } else {
      return new Date();
    }
  };

  useEffect(() => {
    if (editEvent) {
      setTitle(editEvent.title);
      setDescription(editEvent.description);
      setStartDate(Date.parse(editEvent.start));
      setEndDate(Date.parse(editEvent.end));
      setStartTime(Date.parse(editEvent.start));
      setEndTime(Date.parse(editEvent.end));
      setColor(editEvent.color);
    } else {
      setTitle("");
      setDescription("");
      setColor(defColor);
    }
  }, [editEvent]);

  const combineDateAndTime = (dateStamp, timeStamp) => {
    let time = new Date(timeStamp);
    let date = new Date(dateStamp);
    const timeString = time.getHours() + ":" + time.getMinutes() + ":00";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var dateString = "" + year + "-" + month + "-" + day;
    var combined = new Date(dateString + " " + timeString);

    return combined;
  };

  const update = async () => {
    const start = combineDateAndTime(startDate, startTime);
    const end = combineDateAndTime(endDate, endTime);

    if (title?.trim() === "") {
      toast.error("Event title is required", toastConfiguration);
      return;
    }

    setUpdateLoading(true);
    const { data } = await updateEvent({
      calendarEventId: editEvent.calendarEventId,
      googleEventId: editEvent.googleCaledarEventId,
      title: title,
      description: customerName,
      color: color,
      start: start,
      end: end,
      counterPartyId: id,
      isGoogleConnected: googleSync,
    });

    if (data) {
      setShowNewEventModal(false);
      toast.success("Successfully updated event", toastConfiguration);
      forceRefreshEvents && (await forceRefreshEvents());
    } else {
      setShowNewEventModal(false);
      toast.error("Error in updating event", toastConfiguration);
    }
    setUpdateLoading(false);
  };

  const save = async () => {
    if (title?.trim() === "") {
      toast.error("Event title is required", toastConfiguration);
      return;
    }

    setLoading(true);
    const { data } = await createEvent({
      title: title,
      description: customerName,
      color: color,
      start: combineDateAndTime(startDate, startTime),
      end: combineDateAndTime(endDate, endTime),
      counterPartyId: id,
    });

    if (data) {
      setShowNewEventModal(false);
      toast.success("Successfully added event", toastConfiguration);
      forceRefreshEvents && (await forceRefreshEvents());
    } else {
      setShowNewEventModal(false);
      toast.error("Error in adding event", toastConfiguration);
    }
    setLoading(false);
  };

  const remove = async () => {
    if (!title) {
      return;
    }

    setLoading(true);
    const { data } = await removeEvent({
      calendarEventId: editEvent.calendarEventId,
      googleEventId: editEvent.googleCaledarEventId,
    });
    if (data) {
      toast.success("Successfully removed event", toastConfiguration);
      forceRefreshEvents && forceRefreshEvents();
    } else {
      toast.error("Error in removing calendar event", toastConfiguration);
    }
    setLoading(false);
    setShowNewEventModal(false);
  };

  return (
    <>
      <Modal
        visible={showNewEventModal}
        onClose={() => {
          setShowNewEventModal(false);
        }}
        outerClassName={styles.outer}
      >
        <Card
          className={styles.card}
          title='Event' /* classTitle={cn("title-blue", styles.title)} */
        >
          <div className={styles.row}>
            <div className={styles.field}>
              <label>Title</label>
              <TextInput
                onChange={(event) => setTitle(event?.target?.value)}
                name='title'
                type='text'
                value={title}
                placeholder=''
                required
              />
            </div>
            <div className={styles.field}>
              <label>Start</label>
              <div className={styles.fieldWrap}>
                <DatePicker
                  //isWithPortal={false}
                  selected={startDate}
                  minDate={new Date()}
                  classInput={styles.inputValueFuckThisDatePicker}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  dateFormat='MMM d, yyyy'
                ></DatePicker>
                <DatePicker
                  showTimeSelect
                  showTimeSelectOnly
                  //isWithPortal={false}
                  selected={startTime}
                  classInput={styles.inputValueFuckThisDatePicker}
                  timeIntervals={15}
                  timeCaption='Time'
                  onChange={(time) => {
                    setStartTime(time);
                  }}
                  dateFormat='HH:mm'
                ></DatePicker>
              </div>
            </div>
            <div className={styles.field}>
              <label>End</label>
              <div className={styles.fieldWrap}>
                <DatePicker
                  //isWithPortal={false}
                  selected={endDate}
                  minDate={startDate}
                  classInput={styles.inputValueFuckThisDatePicker}
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                  dateFormat='MMM d, yyyy'
                ></DatePicker>
                <DatePicker
                  showTimeSelect
                  showTimeSelectOnly
                  //isWithPortal={false}
                  selected={endTime}
                  timeIntervals={15}
                  timeCaption='Time'
                  classInput={styles.inputValueFuckThisDatePicker}
                  onChange={(time) => {
                    setEndTime(time);
                  }}
                  dateFormat='HH:mm'
                ></DatePicker>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div
              className={cn(styles.customerHead, styles.field)}
              style={{ marginBottom: 32 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 8,
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={customerImageUrl || "/images/content/avatar.jpg"}
                    alt='customer-image'
                    className={styles.customerImage}
                  />
                  <h3 className={styles.customerName} style={{ marginLeft: 8 }}>
                    {customerName}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            {editEvent ? (
              <>
                <button
                  onClick={() => remove()}
                  className={cn("button-stroke-red", styles.button)}
                  style={{ width: 150, display: "inline" }}
                >
                  {loading ? (
                    <Spinner loading={loading} size={15} />
                  ) : (
                    <>
                      <Icon name='trash' size='20' />
                      Delete
                    </>
                  )}
                </button>
                <button
                  onClick={async () => await update()}
                  className={cn("button", styles.button)}
                  style={{ marginLeft: 12, display: "inline" }}
                >
                  {updateLoading ? (
                    <Spinner loading={updateLoading} size={15} />
                  ) : (
                    <>Save</>
                  )}
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => setShowNewEventModal(false)}
                  style={{ marginRight: 20, display: "inline" }}
                  className={cn("button-stroke", styles.button)}
                >
                  <Icon name='arrow-left' size='24' />
                  <span>Close</span>
                </button>
                <button
                  onClick={() => save()}
                  className={cn("button", styles.button)}
                  style={{ display: "inline" }}
                >
                  {loading ? (
                    <Spinner loading={loading} size={15} />
                  ) : (
                    <>Save</>
                  )}
                </button>
              </>
            )}
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default AddEditEvent;
