import React, { useMemo } from "react";
import Image from "../../../components/Image";
import Card from "../../../components/Card";
import cn from "classnames";
import styles from "./PayoutBank.module.sass";
import Modal from "../../../components/Modal";
import { useState } from "react";
import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";
import Select from "react-select";
import Tooltip from "../../../components/Tooltip";
import Amount from "../../../components/Amount";
import Dropdown from "../../../components/Dropdown";
import { getCurrencyFromLocalStorage, toastConfiguration, trackIntercomEvent } from "../../../utils/utils";
import { AddPayoutBankAccount, UpdatePayoutSchedule, bookTransaction, updateCompanyStripeBalanceSettings } from "../../../utils/apiCallHanlder";
import { toast, ToastContainer } from "react-toastify";
import ValueDropdown from "../../../components/Dropdown_c";
import Spinner from "../../../utils/spinner";
import Payment from "../../Settings/Tabs/Payment";
import countryList from "react-select-country-list";
import Switch from "../../../components/Switch";
import Search from "../../Accounting/Reconcile/SearchAccounts";

const payoutBankAccounts = [
  {
    accName: "Stripe Test Account",
    accNum: "****6769",
    routingNum: "****0001",
    currency: "USD",
    flag: "./images/flag/us.png",
  },
];

const period = [{
  display: "Day",
  value: "daily",
  text: "Day"
},
{
  display: "Week",
  value: "weekly",
  text: "Week"
}, {
  display: 'Month',
  value: 'monthly',
  text: "Month"
}];

const periodDate = {
  daily: [],
  weekly: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday"
  ],
  monthly: [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
    "13th",
    "14th",
    "15th",
    "16th",
    "17th",
    "18th",
    "19th",
    "20th",
    "21st",
    "22nd",
    "23rd",
    "24th",
    "25th",
    "26th",
    "27th",
    "28th",
    "29th",
    "30th",
    "31st",
  ],
  manual: [],
};

const PayoutBank = ({
  payoutBankDetails,
  fullDetail,
  bankAccountsRight,
  getIndividualBankDetail,
  automaticallyBookTransaction,
  setAutomaticallyBookTransaction,
  setRevenueCOA,
  revenueCOAName,
  setExpenseCOA,
  expenseCOAName,
  expenseCoaId,
  revenueCoaId
}) => {
  const intervalOn = fullDetail?.payoutDetail?.intervalOn;
  let options = useMemo(() => countryList().getData(), []);

  const [visibleModal, setVisibleModal] = useState(false);
  const [showPayoutDetails, setShowPayoutDetails] = useState(false);
  const [changeMoreBtn, setChangeMoreBtn] = useState(false);
  const [schedulePeriod, setSchedulePeriod] = useState(fullDetail?.payoutDetail?.interval);
  const [schedulePeriodDate, setSchedulePeriodDate] = useState(
    fullDetail?.payoutDetail?.interval === "monthly" ?
      intervalOn + (intervalOn > 0 ? ['th', 'st', 'nd', 'rd']
      [(intervalOn > 3 && intervalOn < 21) || intervalOn % 10 > 3 ? 0 : intervalOn % 10]
        : '')
      :
      intervalOn?.charAt(0).toUpperCase() +
      intervalOn?.substr(1).toLowerCase()
  );
  const [interval, setInterval] = useState('');
  const [payoutLoading, setPayoutLoading] = useState(false);

  const [accountHolderName, setAccountHolderName] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [addPayoutBankLoading, setAddPayoutBankLoading] = useState(false);
  const [displayCountry, setDisplayCountry] = useState({
    value: "US",
    label: "United States",
  });
  const [country, setCountry] = useState("US");
  const [iban, setIban] = useState("");

  const [accountingUpdateLoading, setAccountingUpdateLoading] = useState(false);

  const currency = getCurrencyFromLocalStorage();

  const updatePayoutSchedule = async (intervalOn, interval) => {
    setPayoutLoading(true)
    const { data, error } = await UpdatePayoutSchedule({
      interval,
      intervalOn
    })
    if (data) {
      toast.success("Payout schedule changed successfully", toastConfiguration);
    } else {
      toast.error(error, toastConfiguration);
    }
    setPayoutLoading(false)
  }

  const updateAccountingSettingsHandler = async () => {
    setAccountingUpdateLoading(true);
    const { data, error } = await updateCompanyStripeBalanceSettings({
      automaticallyBookTransactions: automaticallyBookTransaction,
      incomeCompanyCOAccountId: revenueCoaId,
      expenseCompanyCOAccountId: expenseCoaId
    })

    if (data) {
      toast.success("Accounting settings changed successfully", toastConfiguration);
    } else {
      toast.error(error || "Error in updating accouting settings", toastConfiguration);
    }

    setAccountingUpdateLoading(false);
  }


  return (
    <>
      <Card className={cn(styles.cardWrap)}>
        <ToastContainer />

        <Card
          className={cn(styles.card)}
          classCardHead={styles.head}
          title={<span>Accounting settings</span>}
          head={<button
            className={cn("button", styles.button)}
            onClick={async () => updateAccountingSettingsHandler()}
          >
            {accountingUpdateLoading ?
              <Spinner size='24' color="white" />
              :
              "Save"
            }
          </button>}
        >
          <div className={styles.switchContainer}>
            <Switch
              value={automaticallyBookTransaction}
              onChange={() => setAutomaticallyBookTransaction(!automaticallyBookTransaction)}
            />
            <span className={styles.title} style={{ marginLeft: 16 }}>
              Automatically book transactions
            </span>
          </div>
          <div className={styles.flexRow}>
            <div>
              <div className={styles.fieldLabel}>
                Default Account name for Revenue
              </div>
              <Search
                className={styles.search}
                inputPlaceholder='Choose the account'
                showDetails={(x) => setRevenueCOA(x?.id)}
                accountName={revenueCOAName}
              />
            </div>
            <div>
              <div className={styles.fieldLabel}>
                Default Account name for Expense
              </div>
              <Search
                className={styles.search}
                inputPlaceholder='Choose the account'
                showDetails={(x) => setExpenseCOA(x?.id)}
                accountName={expenseCOAName}
              />
            </div>
          </div>
        </Card>

        <Card
          className={cn(styles.card)}
          classCardHead={styles.head}
          title={
            <>
              <span>Payout Bank Account</span>
              {/* <Tooltip
                className={styles.tooltip}
                title="tooltip"
                icon="info"
                place="right"
              /> */}
            </>
          }
        /* classTitle={cn("title-yellow", styles.title)} */
        /* head={
          <div className={styles.headRight}>
            <div className={styles.headBtns}>
              <button
                className={cn("button-stroke button-small", styles.button)}
                onClick={() => { 
                  setVisibleModal(true);
                  trackIntercomEvent('payoutbank-edit-attempt');
                }}
              >
                <Icon name="note" size="16" />
                Edit
              </button>
              <button
                className={cn("button-stroke button-small", styles.button)}
                onClick={() => {
                  setShowPayoutDetails(!showPayoutDetails);
                  setChangeMoreBtn(!changeMoreBtn);
                }}
              >
                {changeMoreBtn ? (
                  <>
                    <Icon name="arrow-up" size="16" />
                    Hide details
                  </>
                ) : (
                  <>
                    <Icon name="arrow-down" size="16" />
                    More details
                  </>
                )}
              </button>
            </div>
          </div>
        } */
        >
          <div className={styles.wrapper}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>Bank Account name</div>
                <div className={styles.col}>Account number</div>
                <div className={styles.col}>Routing Number</div>
                {/* <div className={styles.col}>Currency</div>
                <div className={styles.col}>Country</div> */}
                <div className={styles.col}></div>
              </div>
              {/* {payoutBankAccounts.map((x, index) => ( */}
              <div className={styles.row}>
                <div className={styles.col}>
                  <p>Bank Account name</p>
                  <p>{payoutBankDetails?.bankName}</p>
                </div>
                <div className={styles.col}>
                  <p>Account Number</p>
                  <p>****{payoutBankDetails?.accountNumber}</p>{" "}
                </div>
                <div className={styles.col}>
                  <p>Routing Number</p>
                  <p>****{payoutBankDetails?.routingNumber?.substr(payoutBankDetails?.routingNumber.length - 4)}
                  </p>
                </div>
                {/* <div className={styles.col}>
                  <p>Currency</p>
                  <p>{payoutBankDetails?.currency}</p>{" "}
                </div>
                <div className={styles.col}>
                  <p>Country</p>
                  <p >{payoutBankDetails?.country}</p>{" "}
                </div> */}
                {bankAccountsRight !== 2 && (
                  <div className={styles.col} style={{ width: "150px", }}>
                    <p></p>
                    <div className={styles.headBtns}>
                      <button
                        className={cn("button-stroke button-small", styles.button)}
                        onClick={() => {
                          setAddPayoutBankLoading(false);
                          setIban("");
                          setCountry("US");
                          setAccountHolderName("");
                          setRoutingNumber("");
                          setAccountNumber("");
                          setVisibleModal(true);
                          trackIntercomEvent('payoutbank-edit-attempt');
                        }}
                      >
                        <Icon name="note" size="16" />
                        Edit
                      </button>
                    </div>
                  </div>
                )}
                <div>

                </div>
              </div>
              {/* ))} */}
            </div>
          </div>
        </Card>
        {/* {showPayoutDetails ? (
          <> */}
        <Card
          className={cn(styles.card)}
          classCardHead={styles.head}
          title={
            <>
              <span>Funds on your account</span>
              {/* <Tooltip
                    className={styles.tooltip}
                    title="tooltip"
                    icon="info"
                    place="right"
                  /> */}
            </>
          }
        /* classTitle={cn("title-red", styles.title)} */
        >
          <div className={styles.fundWrap}>
            <div className={styles.flex}>
              <p>Available to pay out to your bank account</p>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                {currency}
                <Amount
                  amount={fullDetail?.fundsAvailable / 100}
                  subtitleEmValue={"0.7em"}
                />
              </div>
            </div>
            <div className={styles.flex}>
              <p>Currently on the way to your bank account</p>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                {currency}
                <Amount
                  amount={fullDetail?.fundsOnWayToBank / 100}
                  subtitleEmValue={"0.7em"}
                />
              </div>
            </div>
            <div className={styles.flex}>
              <p>Will be available soon</p>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                {currency}
                <Amount
                  amount={fullDetail?.fundsFuturePayout / 100}
                  subtitleEmValue={"0.7em"}
                />
              </div>
            </div>
            <div className={styles.flex}>
              <p>Total</p>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                {currency}
                <Amount
                  amount={fullDetail?.fundsTotal / 100}
                  subtitleEmValue={"0.7em"}
                />
              </div>
            </div>
          </div>
        </Card>
        <Card
          className={cn(styles.card)}
          classCardHead={styles.head}
          title={
            <>
              <span>Payout Schedule</span>
              {/* <Tooltip
                    className={styles.tooltip}
                    title="tooltip"
                    icon="info"
                    place="right"
                  /> */}
            </>
          }
        /* classTitle={cn("title-green", styles.title)} */
        >
          <div className={styles.scheduleWrap} style={
            bankAccountsRight === 2 ? { pointerEvents: 'none' } : {}
          }>
            <p>Automatic every</p>
            <ValueDropdown
              value={schedulePeriod}
              onChange={(value) => {
                setSchedulePeriodDate(null);
                let valueToSend = null;
                if (schedulePeriod === "weekly") {
                  if (periodDate[value]) {
                    valueToSend = periodDate[value][0]?.toLowerCase();
                  }
                } else if (schedulePeriod === "monthly") {
                  if (periodDate[value]) {
                    valueToSend = periodDate[value][0]?.replace(/\D/g, "")
                  }
                }
                updatePayoutSchedule(valueToSend, value)
              }}
              setValue={setSchedulePeriod}
              options={period}
            />
            {schedulePeriod !== "daily" ?
              <>
                <p>on the</p>
                <Dropdown
                  value={schedulePeriodDate || periodDate[schedulePeriod][0]}
                  onChange={(value) => {
                    let valueToSend = null;
                    if (schedulePeriod === "weekly") {
                      valueToSend = value?.toLowerCase()
                      setInterval(valueToSend);
                    } else if (schedulePeriod === "monthly") {
                      valueToSend = value?.replace(/\D/g, "");
                      setInterval(valueToSend);
                    }
                    updatePayoutSchedule(valueToSend, schedulePeriod)
                  }}
                  setValue={setSchedulePeriodDate}
                  options={periodDate[schedulePeriod]}
                />
              </>
              :
              null
            }
            {payoutLoading ?
              <Spinner size={24} color="gray" />
              :
              null
            }
          </div>
        </Card>
        <Payment bankAccountsRight={bankAccountsRight} />
        {/* <Card
              classTitle="title-yellow"
              title={
                <>
                  <span>Payment Method</span>
                </>
              }
              className={styles.card}
              classCardHead={styles.cardHead}
            >
              <div className={styles.methodWrap}>
                <div className={styles.methodHead}>
                  <h3>Type</h3>
                  <h3>Description</h3>
                </div>
                <div className={styles.flex}>
                  <h4>Cards</h4>
                  <p>With card payments, your clients have a simplified way to pay you. There is an extra charge to accept card payments of 3.4% + $30c</p>
                </div>
                <div className={styles.flex}>
                  <h4>US ACH Direct Debit</h4>
                  <p>Popular for high-value, low-churn payments. Customers give permission for their bank account to be debited. With this payment type, you can save significantly on accepting payments, compared to cards. </p>
                </div>
              </div>
            </Card> */}
        {/* </>
        ) : null} */}
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <h3>Your payout Bank Account</h3>
        <p>Your Payment Balance will go to this account</p>
        <div className={styles.fieldLabel}>Country</div>
        <Select
          options={options}
          className={cn(styles.dropdown, "drop")}
          value={displayCountry}
          onChange={(item) => {
            setIban("");
            setRoutingNumber("");
            setAccountNumber("");
            setCountry(item?.value);
            setDisplayCountry(item);
          }}
        />
        <TextInput
          label="Account Holder Name"
          className={styles.input}
          value={accountHolderName}
          onChange={(e) => setAccountHolderName(e.target.value)}
        />
        {country === "US" ?
          <>
            <TextInput
              label="Routing Number"
              className={styles.input}
              value={routingNumber}
              onChange={(e) => setRoutingNumber(e.target.value)}
            />
            <TextInput
              label="Account Number"
              className={styles.input}
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
          </>
          :
          <TextInput
            label="IBan"
            className={styles.input}
            value={iban}
            onChange={(e) => setIban(e.target.value)}
          />
        }
        <button
          className={cn("button", styles.button)}
          onClick={async () => {
            const payload = country === "US" ? {
              accountHolderName: accountHolderName,
              accountNumber: accountNumber,
              routingNumber: routingNumber,
              country: country
            } : {
              accountHolderName: accountHolderName,
              iban: iban,
              country: country
            }

            let isPayloadAvailable = true;
            Object.keys(payload)?.map(item => {
              if (!payload[item]) {
                isPayloadAvailable = false;
              }
            })

            if (!isPayloadAvailable) {
              toast.error("Please fill out all input fields", toastConfiguration);
              return;
            }

            setAddPayoutBankLoading(true);
            const { data, error } = await AddPayoutBankAccount(payload)
            if (data) {
              setVisibleModal(false);
              toast.success("Payout account added successfully", toastConfiguration);
              getIndividualBankDetail && getIndividualBankDetail("stripebalance")
            } else {
              toast.error(error || "Error in adding payout bank", toastConfiguration);
            }
            setAddPayoutBankLoading(false);
          }}
        >
          {addPayoutBankLoading ?
            <Spinner size='24' color="white" />
            :
            "Save"
          }
        </button>
        <p>Your Bank Account must be a checking account</p>
      </Modal>
    </>
  );
};

export default PayoutBank;
