import React, { useRef, useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Checkbox from "../../../../../../components/Checkbox";
import Icon from "../../../../../../components/Icon";
import {
  dateFormatter,
  dateFormatterWithTimeZone,
  toTitleCase,
  transactionDateFormatter,
} from "../../../../../../utils/utils";
import {
  duplicateInvoices,
  markManuallyInvoiceAsPaid,
} from "../../../../../../utils/apiCallHanlder";
import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { toastConfiguration } from "../../../../../../utils/utils";
import Spinner from "../../../../../../utils/spinner";
import Modal from "../../../../../../components/Modal";
import Card from "../../../../../../components/Card";
import DateInput from "../../../../../../components/DateInput";
import { pageLoadTransactions } from "../../../../../../utils/apiCallHanlder";
import Transactions from "../../../../../../components/Transactions";
import Amount from "../../../../../../components/Amount";
import AlternativeAvatar from "../../../../../../components/AlternativeAvatar";

const Row = ({
  item,
  onChange,
  setInvoiceData,
  activeTable,
  activeId,
  className,
  editAction,
  deleteAction,
  setNeedNewData,
  newData,
  singleCheck,
  editInvoice,
  activeIndex,
  archiveStatus,
  setActiveType,
  deleteHandler
}) => {
  const [visible, setVisible] = useState(false);
  const [showPaidModal, setShowPaidModal] = useState(false);
  const [showTranModal, setShowTranModal] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [duplicateLoading, setDuplicatedLoading] = useState(false);
  const [markAsPaidLoading, setMarkAsPaidLoading] = useState(false);

  const [voidingLoading, setVoidingLoading] = useState(false);
  const [transactionPayLoading, setTransactionPayLoading] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [accountDetailPayload, setAccountDetailPayload] = useState({});
  const [isNextPageExist, setIsNextPageExist] = useState(false);
  const [lastId, setLastId] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const duplicatedClickHandler = async () => {
    setDuplicatedLoading(true);
    const { data } = await duplicateInvoices({ invoiceId: item.id });
    if (data) {
      //setVisible(false);
      toast.success("Invoice duplicated successfully", toastConfiguration);
      history.push(`/payments/tabs/${activeIndex}`);
      setInvoiceData([]);
      setNeedNewData(!newData);
    } else {
      toast.error("Error in duplicating Invoice", toastConfiguration);
    }
    setDuplicatedLoading(false);
  };

  const editClickHandler = (id) => {
    history.push(`/payments/${id}`);
  };

  const markAsPaidHandler = async (date, paidType) => {
    setMarkAsPaidLoading(true);

    const { data, error } = await markManuallyInvoiceAsPaid({
      invoiceId: item?.id,
      paidType: paidType,
      paidDate: transactionDateFormatter(date),
    });

    if (data) {
      toast.success("Invoice marked as paid successfully", toastConfiguration);
      setActiveType('All Invoices');
      history.push(`/payments/tabs/1`);
      setInvoiceData([]);
      setNeedNewData(!newData);
    } else {
      toast.error(error || "error in marking invoice paid", toastConfiguration);
    }

    setMarkAsPaidLoading(false);
  };

  const payThroughTrans = async (x) => {
    setShowTranModal(false);
    setTransactionPayLoading(true);

    const { data } = await markManuallyInvoiceAsPaid({
      invoiceId: item?.id,
      paidType: "transaction",
      paidTransactionId: x?.id,
      paidAccountId: x?.accountId,
      paidAccountType: x?.accountType,
    });

    if (data) {
      toast.success("Invoice marked as paid successfully", toastConfiguration);
      setActiveType('All Invoices');
      history.push(`/payments/tabs/1`);
      setInvoiceData([]);
      setNeedNewData(!newData);
    }

    setTransactionPayLoading(false);
  };

  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
      >
        <div
          className={styles.editOverlay}
          onClick={() => editClickHandler(item.id)}
        ></div>
        {/* <div className={styles.col}>
          {singleCheck || (
            <Checkbox className={styles.checkbox} onChange={onChange} />
          )}
        </div> */}
        <div
          className={cn(styles.col, styles.firstChild)}
          onClick={() => editClickHandler(item.id)}
          style={{ cursor: "pointer" }}
        >
          <div className={styles.amountTag}>Customer</div>
          <div className={styles.customer}>
            <div className={cn(styles.name)}>
              {item.customerImage ? (
                <div>
                  <img
                    className={styles.image}
                    src={item.customerImage}
                    alt='product'
                  />
                </div>
              ) : (
                <AlternativeAvatar
                  name={item.customerName}
                  className={"invoice"}
                />
              )}
              <div style={{ width: "24ch", wordBreak: "break-word" }}>
                <span>{item.customerName}</span>
                <br />
                <span>{archiveStatus && archiveStatus}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.amountTag}>Amount</div>
          <div className={styles.amount}>
            <Amount amount={item.amount} subtitleEmValue={"0.7em"} />
          </div>
        </div>
        <div className={styles.col} style={{ width: 150 }}>
          <div className={styles.statusTag}>Status</div>
          <div className={cn(styles.status)}>
            <span className={cn(styles[item.status] || styles.draft)}>
              {toTitleCase(item.status)}
            </span>
          </div>
        </div>
        <div className={styles.col}>
          <span className={styles.createdTag}>Created</span>
          <div className={styles.created}>{dateFormatter(item.createdOn)}</div>
        </div>
        <div className={styles.col}>
          <span className={styles.dueTag}>Due</span>
          <div className={styles.due}>{dateFormatter(item.dueDate)}</div>
        </div>
        <div className={styles.col}>
          <span className={styles.invoiceNoTag}>ID</span>
          <div className={styles.invoiceNo}>{item.invoiceNumber} &nbsp;
            {item.isPaymentManual && <span className={cn(styles['paid'])}>
              Manual
            </span>}
          </div>
        </div>

        <div className={styles.col}>
          {markAsPaidLoading || transactionPayLoading ? (
            <Spinner size='24' color='gray' />
          ) : (
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
              <div
                className={cn(styles.dotsBtn, className, {
                  [styles.active]: visible,
                })}
              >
                <div className={styles.head}>
                  <button
                    className={cn(styles.btn)}
                    onClick={() => setVisible(!visible)}
                  >
                    <Icon name={"dots"} />
                  </button>
                </div>
                <div className={styles.actionBody}>
                  <button
                    className={styles.actionItem}
                    onClick={() => editClickHandler(item.id)}
                  >
                    {item.status != "draft" && (
                      <span>
                        <Icon name='edit' size='24' /> View
                      </span>
                    )}
                    {item.status == "draft" && (
                      <span>
                        {" "}
                        <Icon name='edit' size='24' /> Edit
                      </span>
                    )}
                  </button>
                  {item?.invociePdf && (
                    <a
                      className={styles.actionItem}
                      onClick={() => setVisible(false)}
                      href={item?.invociePdf}
                      target='blank'
                      download
                    >
                      <Icon name='download' size='24' />
                      Download PDF
                    </a>
                  )}
                  {item.isPaymentManual && item.status === "open" ? (
                    <button
                      className={styles.actionItem}
                      onClick={() => setShowPaidModal(true)}
                      style={{ position: "relative" }}
                    >
                      <Icon name='multiSelect' size='24' />
                      {/* {markAsPaidLoading ?
                      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Spinner size="18" loading={markAsPaidLoading} color="gray" />
                      </div>
                      : */}
                      Mark as Paid
                      {/* } */}
                    </button>
                  ) : null}

                  {item.status === "open" ? (
                    <button
                      className={styles.actionItem}
                      onClick={async () => {
                        setVoidingLoading(true);
                        await deleteHandler(item.id, true, item.isPaymentManual)
                        setVoidingLoading(false);
                      }}
                      style={{ position: "relative" }}
                    >
                      <Icon name='trash' size='24' />
                      Mark as Void
                      <div
                      style={{
                        width: "100%",
                        position: "absolute",
                        top: 14,
                        left: 80,
                      }}
                    >
                      <Spinner
                        loading={voidingLoading}
                        size={"20"}
                        color={"gray"}
                      />
                    </div>
                    </button>
                  ) : null}

                  {
                    item.isSubscription === true ? null : (
                      <button
                        className={styles.actionItem}
                        onClick={duplicatedClickHandler}
                        style={{ position: "relative" }}
                      >
                        <Icon name='multiSelect' size='24' />
                        Duplicate
                        <div
                          style={{
                            width: "100%",
                            position: "absolute",
                            top: 14,
                            left: 80,
                          }}
                        >
                          <Spinner
                            loading={duplicateLoading}
                            size={"20"}
                            color={"gray"}
                          />
                        </div>
                      </button>
                    )
                  }
                  
                  {/* <button
                  className={styles.actionItem}
                  onClick={() => setShowPaidModal(true)}
                >
                  <Icon name="dollar" size="24" />
                  Mark as paid
                </button> */}
                  {item.status === "draft"
                    ? deleteAction && deleteAction
                    : null}
                </div>
              </div>

              {/* Paid Modal */}
              <Modal
                onClose={() => setShowPaidModal(false)}
                visible={showPaidModal}
                children={
                  <>
                    <Card
                      className={styles.paidModalCard}
                      classCardHead={styles.head}
                      title='How did you get paid?'
                    /* classTitle={cn("title-blue", styles.title)} */
                    >
                      <p>Choose how you wish to register this transaction</p>
                      <button
                        onClick={() => {
                          setShowPaidModal(false);
                          const dateInputId = document.getElementById(
                            `dateInputId-${item.id}`
                          );
                          dateInputId.focus();
                        }}
                        className={cn("button-stroke", styles.cashBtn)}
                      >
                        <Icon name='notes' size='24' />
                        Cash
                      </button>
                      <button
                        onClick={async () => {
                          setLoading(true);
                          const { data } = await pageLoadTransactions({
                            FromDate: transactionDateFormatter(
                              new Date(
                                new Date().setDate(new Date().getDate() - 30)
                              )
                            ),
                            ToDate: transactionDateFormatter(new Date()),
                          });
                          if (data) {
                            setAccounts(data.accounts);
                            setAccountDetailPayload(data.accountDetailPayload);
                            setIsNextPageExist(data?.isNextPageExist);
                            setLastId(data?.transactions?.at(-1)?.id);
                            setTransactions(data.transactions);
                            setShowPaidModal(false);
                            setShowTranModal(true);
                          }
                          setLoading(false);
                        }}
                        className={cn("button-stroke", styles.chooseTranBtn)}
                      >
                        {loading ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Spinner size='24' color='gray' />
                          </div>
                        ) : (
                          <>
                            <Icon name='justify' size='24' />
                            Choose transaction
                          </>
                        )}
                      </button>
                    </Card>
                  </>
                }
              />

              {/* Transaction Modal */}
              <Modal
                outerClassName={styles.transactionsWrap}
                visible={showTranModal}
                onClose={() => {
                  setShowTranModal(false);
                  setShowPaidModal(true);
                }}
                children={
                  <>
                    <Transactions
                      transactions={transactions}
                      accountDetailPayload={accountDetailPayload}
                      setAccountDetailPayload={setAccountDetailPayload}
                      accounts={accounts}
                      lastId={lastId}
                      isNextPageExist={isNextPageExist}
                      setLastId={setLastId}
                      setTransactions={setTransactions}
                      setIsNextPageExist={setIsNextPageExist}
                      className={styles.transaction}
                      payThroughTrans={payThroughTrans}
                      isComingFromModal={true}
                    />
                  </>
                }
              />
              {/* DateInput Modal */}

              <DateInput
                className={styles.dateInput}
                isWithPortal={true}
                portalClassInput={styles.input}
                classInputValue={styles.inputValue}
                dateInputId={`dateInputId-${item.id}`}
                invoiceId={item.id}
                markAsPaidLoading={markAsPaidLoading}
                markAsPaidHandler={markAsPaidHandler}
              />
            </OutsideClickHandler>
          )}
        </div>
      </div>
    </>
  );
};

export default Row;
