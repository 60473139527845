import React from 'react';

const Amount = ({ amount, subtitleEmValue, showMinus, alignItemsEnd }) => {

    const splittedString = amount?.toString().split(".");
    let mainValue = null;
    let subValue = null;

    if (splittedString) {
        mainValue = splittedString[0];
        subValue = splittedString[1];
    }

    return (
        <div style={{ display: 'flex', alignItems: alignItemsEnd ? "flex-end" : "stretch" }}>
            <div style={{ fontSize: "1em", lineHeight: "1em" }}>
                {showMinus && "-"}
                {mainValue}
            </div>
            <div style={{fontSize: subtitleEmValue, marginLeft: 2, lineHeight: "16px", paddingBottom: "3px"}}>
                {subValue ?
                    subValue?.length < 2 ?
                        "." + subValue + "0" :
                        "." + subValue :
                    ".00"
                }
            </div>
        </div >
    )
}

export default Amount;