import React from "react";
import styles from "./NoChecklist.module.sass";
import Image from "../../../../components/Image";
import cn from "classnames";

const NoChecklist = ({ onClick }) => {
  return (
    <div>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.noChecklist}>
            <Image
              className={styles.pic}
              src="/images/icons/empty-black.png"
              srcDark="/images/icons/empty-white.png"
              alt="Core"
            />
            {/* <div className={styles.txtWrap}>
              <h3>You don't have any checklist</h3>
              <p>No time for coffee, grow your business now</p>
              <button className={cn("button", styles.button)} onClick={onClick}>
                Create new Checklist
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoChecklist;
