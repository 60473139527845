import React, { useState } from "react";
import Card from "../../../components/Card";
import cn from "classnames";
import styles from "./AccountType.module.sass";
import PersonalInformation from "./PersonalInformation";
import CompanyInformation from "./CompanyInformation";

const accountTypes = [
  {
    title: "Sole Proprietor",
  },
  {
    title: "Company",
  },
];

const AccountType = ({ className,
  nameSetHandler,
  lastNameSetHandler,
  setSelectCountries,
  selectCountries,
  activeIndex,
  setActiveIndex,
  errorMessage,
  nameError
}) => {


  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Account Type"
        /* classTitle="title-blue" */
      >
        <div className={styles.typeNav}>
          {accountTypes.map((x, index) => (
            <div
              className={cn(styles.item, {
                [styles.active]: index + 1 === activeIndex,
              })}
              key={index}
              onClick={() => setActiveIndex(index + 1)}
            >
              {x.title}
            </div>
          ))}
        </div>
      </Card>

      <div className={styles.information}>
        {activeIndex === 1 &&
          <PersonalInformation
            nameSetHandler={nameSetHandler}
            lastNameSetHandler={lastNameSetHandler}
            setSelectCountries={setSelectCountries}
            selectCountries={selectCountries}
            errorMessage={errorMessage}
            nameError={nameError}
          />}
        {activeIndex === 2 &&
          <CompanyInformation
            nameSetHandler={nameSetHandler}
            setSelectCountries={setSelectCountries}
            selectCountries={selectCountries}
            errorMessage={errorMessage}
            nameError={nameError}
          />}
      </div>
    </>
  );
};

export default AccountType;
