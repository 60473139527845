import React, { useState, useEffect, useRef } from "react";
import { useParams, withRouter, useLocation, useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  toastConfiguration,
  reverifyHandler,
  stripeConnectionStatus,
  getCurrentUser,
} from "../../utils/utils";
import cn from "classnames";
import styles from "./MessagesWithAI.module.sass";
import Icon from "../../components/Icon";
import Image from "../../components/Image";
import Spinner from "../../utils/spinner";
import Card from "../../components/Card";
import Panel from "../MessageCenter/Messages/Panel";
import Message from "../MessageCenter/Messages/Message";
import Send from "../MessageCenter/Messages/Send";
import {
  createConversationWithAI,
  replyToConversationWithAI,
  getPredefinedPrompts,
} from "../../utils/apiCallHanlder";
import Prompts from "./prompts";
import Documents from "./Documents";
import NewContractForm from "../Studio/Contract/NewContractForm";
import Contracts from "./Contracts";

const MessagesWithAI = ({ }) => {
  const user = getCurrentUser();
  const { pathname } = useLocation();
  const history = useHistory();
  
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [visible, setVisible] = useState(true);
  const [currentConversationId, setCurrentConversationId] = useState(-1);
  const [isSendDisabled, setSendDisabled] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const path = pathname.split('/')[2];
    switch (path) {
      case 'prompt':
        setActiveTab(1);
        break;
      case 'document':
        setActiveTab(2);
        break;
      case 'contract':
        setActiveTab(3);
        break;
      default:
        setActiveTab(0);
    }
  }, [pathname]);

  const options = [
    {
      name: 'Prompt',
      onClick: () => history.push('/ai/prompt'),
      icon: 'code'
    },
    {
      name: 'Document',
      onClick: () => history.push('/ai/document'),
      icon: 'document'
    },
    {
      name: 'Contract',
      onClick: () => history.push('/ai/contract'),
      icon: 'note'
    },
  ]

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  const newMessageSend = async (newMessage) => {
    if (newMessage && newMessage.text !== "") {
      setSendDisabled(true);
      if (currentConversationId === -1) {
        setMessages([
          {
            sender: "user",
            body: newMessage.text,
            timestamp: Math.floor(Date.now() / 1000),
          },
        ]);

        const { data, error, message } = await createConversationWithAI(newMessage.text);
        if (data) {
          setCurrentConversationId(data.newConversation.conversation_id);
          setMessages([
            {
              sender: "user",
              body: newMessage.text,
              timestamp: Math.floor(Date.now() / 1000),
            },
            {
              sender: "admin",
              body: data.newConversation.response,
              timestamp: Math.floor(Date.now() / 1000),
            },
          ]);
        } else {
          toast.error(error, toastConfiguration);
        }

      } else {
        messages.push({
          sender: "user",
          body: newMessage.text,
          timestamp: Math.floor(Date.now() / 1000),
        });

        const { data } = await replyToConversationWithAI(
          currentConversationId,
          newMessage.text
        );
        if (data && data.newReplyConversation) {
          messages.push({
            sender: "admin",
            body: data.newReplyConversation.reply,
            timestamp: Math.floor(Date.now() / 1000),
          });
          setMessages(messages.slice(0));
        }
      }
      setSendDisabled(false);
    }
  };

  const clickPredefinedPrompt = (text) => {
    newMessageSend({ text });
  }

  return (
    <>
      <ToastContainer />
      {activeTab === 1 && <Prompts setActiveTab={setActiveTab} clickPredefinedPrompt={clickPredefinedPrompt}/>}
      {activeTab === 2 && <Documents setActiveTab={setActiveTab} />}
      {activeTab === 3 && <Contracts setActiveTab={setActiveTab} />}
      {activeTab === 0 && (
        <Card className={styles.card} title="AI Co-Pilot" border >
          <ToastContainer />
          {loading ? (
            <div className={styles.spinner}>
              <Spinner size="48" color="gray" />
            </div>
          ) : (
            <>
              <div
                className={cn(styles.messages, styles.messages, {
                  [styles.show]: visible,
                })}
              >
                <div className={cn(styles.wrapper)}>
                  {messages?.length < 1 && (
                    <div className={styles.emptyContainer}>
                      <img alt="g-logo" src="/gwLogo.png" className={styles.emptyLogo} />
                      <span className={styles.emptyMessage}>How can I help you today?</span>
                    </div>
                  )}
                  <div className={styles.list}>
                    {messages.map((x, index) => (
                      <Message item={x} key={index} imageUrl={user?.imageurl}/>
                    ))}
                    <AlwaysScrollToBottom />
                  </div>
                  <div className={styles.options}>
                    {options?.map((item, index) => {
                      return <div key={index} className={styles.optionsContainer} onClick={item.onClick}>
                        <Icon name={item.icon} size="24" fill="#464542" />
                        <span className={styles.optionLabel}>{item.name}</span>
                      </div>
                    })}
                  </div>

                  <Send
                    className={styles.sendWrap}
                    newMessageSend={newMessageSend}
                    disabled={isSendDisabled}
                  />
                </div>
              </div>
            </>
          )}
        </Card>
      )}
    </>
  );
};

export default MessagesWithAI;
