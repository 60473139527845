import React from "react";
import styles from "./SaveSuccess.module.sass";
import Modal from "../../../../../../components/Modal";
import Icon from "../../../../../../components/Icon";
import { useHistory } from "react-router";
import Image from "../../../../../../components/Image";
import { ToastContainer } from 'react-toastify';

const SaveSuccess = ({ showSuccessModal, setShowSuccessModal, onConfirm, selectedCustomerEmail, invoiceObject }) => {
  const history = useHistory();
  return (
    <>
      <Modal
        visible={showSuccessModal}
        onClose={onConfirm}
        outerClassName={styles.outer}
      >
        <div className={styles.wrap}>
          <img
            className={styles.pic}
            src="/SuccessTick.png"
            style={{ width: 126.5, height: 126.5 }}
            srcDark="/images/content/takeoff.png"
            alt="delete"
          />
          <div className={styles.email}>
            Success!
          </div>
          <div className={styles.emailText}>
            Invoice has been sent to {selectedCustomerEmail}
          </div>
          <button onClick={onConfirm} className="button">
            Done
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SaveSuccess;
