import React from "react";
import styles from "./Pricing.module.sass";
import TextInput from "../../../../components/TextInput";
import cn from "classnames";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Card from "../../../../components/Card";
import Dropdown from "../../../../components/Dropdown_c";
import { useEffect } from "react";

const Pricing = ({
  className,
  productCurrency,
  selectCurrency,
  setSelectCurrency,
  userChoose,
  productPrice,
  setProductPrice,
  setValueHandler,
  priceError,
  archived,
  cost,
  margin,
  setCost,
  setMargin
}) => {

  useEffect(() => {
    const productPriceNum = parseInt(productPrice || "0");
    const costPriceNum = parseInt(cost || "0");

    if (productPriceNum && costPriceNum) {
      setMargin((((productPriceNum - costPriceNum) / productPriceNum) * 100))
    } else {
      setMargin(null);
    }
  }, [productPrice, cost])

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title='Pricing'
      >
        {!userChoose ? (
          <div
            className={styles.row}
            style={archived ? { pointerEvents: "none" } : null}
          >
            <div className={styles.col}>
              <div>
                <div className={styles.priceGroup}>
                  <TextInput
                    style={
                      productPrice === "" || productPrice === null
                        ? {
                          backgroundColor: "#FFBC9925",
                          borderColor: "#FFBC9925",
                          width: 150,
                        }
                        : { width: 150 }
                    }
                    label='Price'
                    //currency={currency}
                    name='price'
                    value={productPrice}
                    onChange={(event) =>
                      setValueHandler(event?.target?.value, setProductPrice)
                    }
                    required
                  />
                  <Dropdown
                    className={styles.dropdown}
                    classDropdownHead={styles.dropdownHead}
                    value={selectCurrency}
                    setValue={setSelectCurrency}
                    options={productCurrency}
                  />
                </div>
                {priceError !== "" ? (
                  <div className={styles.errorContainer}>
                    <span className={styles.errorMessage}>{priceError}</span>
                  </div>
                ) : null}
                {/* <div className={styles.includeTax}>
                  <Checkbox
                    onChange={() => setIncludeTax(!includeTax)}
                    value={includeTax}
                  />
                  <span className={styles.txt}>Include Tax in Price</span>
                   <Tooltip
                    className={styles.tooltip}
                    title="This will be used for calculating automatic tax. Defaults to the default product tax category from your tax settings."
                    icon="info"
                    place="right"
                  /> 
                </div> */}
              </div>
            </div>
            <div className={styles.colCost}>
              <div className={styles.costMarginWrapper}>
                <TextInput
                  style={{ width: 158 }}
                  label='Cost'
                  name='cost'
                  type="number"
                  value={cost}
                  onChange={(event) => {
                    setValueHandler(event?.target?.value, setCost);
                  }}
                  required
                  percentage={true}
                />
                <TextInput
                  style={{ width: 158, pointerEvents: 'none' }}
                  label='Margin'
                  name='margin'
                  text='%'
                  value={(margin || 0)?.toFixed(2)}
                  required
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Pricing;
