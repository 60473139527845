import React, { useState } from "react";
import cn from "classnames";
import styles from "../../../../components/Search/Search.module.sass";
import Icon from "../../../../components/Icon";
import Spinner from "../../../../utils/spinner";


let typingTimer;
let doneTypingInterval = 600;

const Search = ({ className, getSearchResult, searchString, setSearchString, resetSearch }) => {
    return (
        <>
            <div
                className={cn(styles.search, className, { [styles.active]: false })}
            >
                <div className={styles.head}>
                    <button className={styles.start}>
                        <Icon name='search' size='24' />
                    </button>
                    <input
                        className={styles.input}
                        type='text'
                        placeholder='Search'
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") {
                                getSearchResult();
                            } else {
                                clearTimeout(typingTimer);
                                typingTimer = setTimeout(getSearchResult, doneTypingInterval);
                            }
                        }}
                        onKeyDown={() => {
                            clearTimeout(typingTimer);
                        }}
                    />
                    <button
                        className={styles.close}
                        onClick={() => {
                            resetSearch();
                        }}
                    >
                        <Icon name='close-circle' size='24' />
                    </button>
                </div>
            </div>
        </>
    );
};

export default Search;
