import React, { useState, useEffect, createRef } from "react";
import Card from "../../../../components/Card";
import styles from "./UpdateQuestionnaireForm.module.sass";
import TextInput from "../../../../components/TextInput";
import Modal from "../../../../components/Modal";
import $ from "jquery";
import Spinner from "../../../../utils/spinner";
import Icon from "../../../../components/Icon";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const UpdateQuestionnaireForm = ({
  handleSubmit,
  handleChange,
  userInput,
  setIsUpdate,
  setUserInput,
  x,
  isUpdate,
  updateQuestionnaireLoading
}) => {

  const fb = createRef();

  const formData = x?.data

  useEffect(() => {
    $(fb.current).formBuilder({ formData });
  }, [isUpdate]);

  return (
    <Modal
      visible={isUpdate}
      outerClassName={styles.outerWrap}
      onClose={() => {
        setIsUpdate(false);
        setUserInput({
          questionnaireName: x.name,
          formBuilderData: x.data,
        });
      }}
      children={
        <Card
          /* classTitle="title-blue" */
          title="Questionnaire"
          className={styles.card}
        >
          <form onSubmit={handleSubmit} noValidate>
            <TextInput
              className={styles.input}
              label="Name"
              placeholder="Enter the name for this questionnaire"
              onChange={handleChange}
              name="questionnaireName"
              id="questionnaireName"
              type="text"
              value={userInput.questionnaireName}
              required
            />
            <div className={styles.questionnaireForm}>
              <div id="fbEditor" ref={fb} />
            </div>
            <div className={styles.newQuestionnaireBtn}>
              {/* <button
                onClick={() => {
                  setIsUpdate(false);
                  setUserInput({ questionnaireName: x.name });
                }}
                className="button-stroke"
              >
                Cancel
              </button> */}
              <button className="button">
                {updateQuestionnaireLoading ?
                  <Spinner size="24" color="white" />
                  :
                  <>
                    Save
                  </>
                }
              </button>
            </div>
          </form>
        </Card>
      }
    />
  );
};

export default UpdateQuestionnaireForm;
