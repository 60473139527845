import React from "react";
import cn from "classnames";
import styles from "./Card.module.sass";

const Card = ({
  className,
  title,
  classTitle,
  isDashboard,
  classCardHead,
  head,
  children,
  comingFromBankAccount,
  comingFromDashboard,
  border,
  id,
  billingCard,
  dangerTitle
}) => {
  const cardStyle = isDashboard
    ? {}
    : comingFromBankAccount
      ? { width: "100%" }
      : null;
  return (
    <div
      className={cn(billingCard ? styles.card11 : styles.card, className, {
        [styles.border]: border,
      })}
      id={id && id}
    >
      {(title || dangerTitle) && (
        <div
          className={cn(styles.head, classCardHead)}
          style={
            comingFromDashboard
              ? {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }
              : {}
          }
        >
          {dangerTitle ?
            <div className={cn(classTitle, styles.title)} style={cardStyle} dangerouslySetInnerHTML={{__html: dangerTitle}} />
            :
            <div className={cn(classTitle, styles.title)} style={cardStyle}>
              {title}
            </div>
          }
          {head && head}
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
