import React, { useEffect, useState } from "react";
import styles from "../../../../components/Search/Search.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import Spinner from "../../../../utils/spinner";
import DocumentOverviewPopup from "../../../Accounting/Documents/DocumentOverviewPopup";

let typingTimer;
let doneTypingInterval = 300;

const Search = ({
  className,
  detailsTxt,
  inputPlaceholder,
  addNewBtnTxt,
  searchNames,
  selectedCustomerName,
  searchString,
  setSearchString,
  selectCustomerLoading,
  nameClickHandler,
  visible,
  setVisible,
  setSearchNames,
  hideAdd,
  setSelectedCustomerName,
  setCustomerId,
  getDocuments,
  propertyId
}) => {

  const [searchLoading, setSearchLoading] = useState(false);
  const [showNewDocModal, setShowNewDocModal] = useState(false);

  const closeButtonHandler = () => {
    setVisible(false);
  }

  const handleChange = async (event) => {
    setSearchLoading(true);
    await getDocuments(searchString);
    setSearchLoading(false);
  }

  const onDocumentSaveHandler = (x) => {
    setSelectedCustomerName && setSelectedCustomerName("");
    setCustomerId && setCustomerId(null);
    setSearchString("");
    closeButtonHandler();
    nameClickHandler(x?.displayName, x?.documentFileId, x?.attachmentLink, x)
  }

  return (
    <>
      <DocumentOverviewPopup
        showNewDocModal={showNewDocModal}
        setShowNewDocModal={setShowNewDocModal}
        isComingFromProperty={true}
        propertyId={propertyId}
        preSetName={searchString}
        onDocumentSaveHandler={onDocumentSaveHandler}
      />
      <div className={styles.detailsTxt}>{detailsTxt && detailsTxt}</div>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
      >
        <div className={styles.head}>
          <button className={styles.start}>
            <Icon name="search" size="24" />
          </button>
          <button
            className={styles.direction}
            onClick={() => setVisible(false)}
          >
            <Icon name='arrow-left' size='24' />
          </button>
          <input
            className={styles.input}
            type="text"
            value={searchString}
            onKeyUp={() => {
              clearTimeout(typingTimer);
              typingTimer = setTimeout(handleChange, doneTypingInterval);
            }}
            onKeyDown={() => {
              clearTimeout(typingTimer);
            }}
            placeholder={selectedCustomerName ? selectedCustomerName : inputPlaceholder}
            onChange={(event) => setSearchString(event?.target?.value)}
            onClick={() => {
              setVisible(true)
              handleChange();
            }}
          />
          <button className={styles.close} onClick={() => {
            setSelectedCustomerName && setSelectedCustomerName("");
            setCustomerId && setCustomerId(null);
            setSearchString("");
            //setSearchNames([]);
            closeButtonHandler();
          }}>
            {selectCustomerLoading ?
              <Spinner loading={selectCustomerLoading} size={"24"} color={"blue"} />
              :
              visible &&
              <Icon name="close-circle" size="24" />
            }
          </button>
        </div>
        <div className={styles.body}>
          {searchLoading ?
            <div style={{ display: 'flex', justifyContent: 'center', height: 70, alignItems: 'center' }}>
              <Spinner loading={searchLoading} color={"blue"} size={"24"} />
            </div>
            :
            <>
              {hideAdd ? null :
                <button onClick={() => setShowNewDocModal(true)} className={styles.addCustomerBtn}>
                  <Icon name="plus-circle" size="24" />
                  {searchString !== "" ? "Add " + searchString : addNewBtnTxt}
                </button>
              }

              {searchNames.length > 0 ?
                <>
                  <div className={styles.detailsTxt}>Search Results</div>
                  <div style={searchNames.length > 4 ? { height: 200, overflowY: 'auto' } : null}>
                    {searchNames?.map((x, index) => (
                      <div key={index}>
                        <button onClick={() => {
                          nameClickHandler(x?.displayName, x?.documentFileId, x?.attachmentLink, x)
                        }} className={styles.customerBtn} style={{ height: 'unset' }}>
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: 8
                          }}>
                            {x?.displayName}
                          </div>
                        </button>
                      </div>
                    ))}
                  </div>
                </> : null
              }

            </>
          }
        </div>

      </div>
    </>
  );
};

export default Search;
