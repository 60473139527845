import React, { useState } from "react";
import styles from "./Details.module.sass";
import TextInput from "../../../../components/TextInput";
import cn from "classnames";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Card from "../../../../components/Card";
import Tooltip from "../../../../components/Tooltip";
import Image from "../../../../components/Image";
import Icon from "../../../../components/Icon";
import Dropdown from "../../../../components/Dropdown_c";
import { uploadProductImage } from "../../../../utils/apiCallHanlder";
import { CouponRedeemDateFormatter } from "../../../../utils/utils";
import { useEffect } from "react";
import DateInput from "../../../../components/DateInput";
import Checkbox from "../../../../components/Checkbox";

const Details = ({
  className,
  name,
  setName,
  percentOff,
  setPercentOff,
  maxRedemptions,
  date,
  setDate,
  setMaxRedemptions,
  redeemBy,
  setRedeemBy,
  selectDuration,
  setSelectDuration,
  durationInMonths,
  setDurationInMonths,
  redeemByCheckBox,
  setRedeemByCheckBox,
  maxRedemptionCheckBox,
  setMaxRedemptionCheckBox,
  time,
  setTime,
  duration,
  setDuration,
  setValueHandler,
  nameError,
  setNameError,
  archived,
  isAdd,
  comingFromInvoice,
  durationOption,
  backBtn,
}) => {
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={isAdd ? "Create new Discount" : "Details"}
        classCardHead={styles.cardHead}
        head={backBtn}
      >
        <div style={{ pointerEvents: !isAdd ? "none" : "unset" }}>
          <div className={styles.row}>
            <div
              className={styles.col}
              style={comingFromInvoice && { marginTop: "unset" }}
            >
              <div
                className={styles.field}
                style={{
                  pointerEvents: archived ? "none" : !isAdd ? "auto" : "unset",
                }}
              >
                <TextInput
                  style={
                    name === ""
                      ? {
                          backgroundColor: "#FFBC9925",
                          borderColor: "#FFBC9925",
                        }
                      : null
                  }
                  label="Name"
                  name="Name"
                  onChange={(event) => {
                    setValueHandler(event?.target?.value, setName);
                  }}
                  value={name}
                  type="text"
                  required
                />
                {nameError !== "" ? (
                  <div className={styles.errorContainer}>
                    <span className={styles.errorMessage}>{nameError}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div
              className={styles.col}
              style={comingFromInvoice && { marginTop: "unset" }}
            >
              {" "}
              <div
                className={styles.field}
                style={
                  archived
                    ? { pointerEvents: "none" }
                    : {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }
                }
              >
                <div>
                  {" "}
                  <div className={styles.fieldLabel}>Duration</div>
                  <Dropdown
                    name="durationSelect"
                    placeholder="Please select duration"
                    className={styles.dropdown}
                    value={selectDuration}
                    setValue={setSelectDuration}
                    options={durationOption}
                  />
                </div>

                {selectDuration === "repeating" ? (
                  <div style={{ position: "relative", marginLeft: "25px" }}>
                    <div className={styles.fieldLabel}>Number of Months</div>
                    <TextInput
                      type="number"
                      style={
                        durationInMonths === "" || durationInMonths === null
                          ? {
                              backgroundColor: "#FFBC9925",
                              borderColor: "#FFBC9925",
                              width: 150,
                            }
                          : { width: 150, paddingRight: "65px" }
                      }
                      name="durationInMonths"
                      value={durationInMonths}
                      text={"months"}
                      onChange={(event) => {
                        setValueHandler(
                          event?.target?.value,
                          setDurationInMonths
                        );
                      }}
                      required
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "25px" }}>
            <p className={styles.fieldLabel}>Redemption Limits</p>
            <div
              style={{ marginTop: 8, display: "flex", flexDirection: "column" }}
            >
              <Checkbox
                content={
                  "Limit the data range when customers can redeem this coupon"
                }
                value={redeemByCheckBox}
                onChange={() => {
                  setRedeemByCheckBox(!redeemByCheckBox);
                }}
              />
              <div>
                {redeemByCheckBox ? (
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "400px",
                          marginBottom: "15px",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{ width: "90px", marginBottom: 0 }}
                          className={styles.fieldLabel}
                        >
                          Radeem by:
                        </p>
                        <DateInput
                          className={styles.dateInput}
                          isWithPortal={true}
                          redeemText={"Redeem By"}
                          hideText={true}
                          allowFuture={true}
                          portalClassInput={styles.input}
                          classInputValue={styles.inputValue}
                          dateInputId="dateInputId"
                          componentStartDate={date}
                          setComponentStartDate={setDate}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <Checkbox
                content={
                  "Limit the total number of times this coupon can be redeemed"
                }
                value={maxRedemptionCheckBox}
                onChange={() => {
                  setMaxRedemptionCheckBox(!maxRedemptionCheckBox);
                }}
              />
            </div>

            <div>
              {maxRedemptionCheckBox ? (
                <div>
                  <div
                    style={{
                      marginTop: 24,
                      width: 280,
                    }}
                  >
                    <TextInput
                      style={
                        name === ""
                          ? {
                              backgroundColor: "#FFBC9925",
                              borderColor: "#FFBC9925",
                            }
                          : null
                      }
                      text={"time"}
                      name="maxRedemptions"
                      onChange={(event) => {
                        if (isAdd !== undefined) {
                          setValueHandler(
                            event?.target?.value,
                            setMaxRedemptions
                          );
                        }
                      }}
                      value={maxRedemptions}
                      type="number"
                      required
                    />
                  </div>
                  <p style={{ marginTop: "8px" }} className={styles.fieldLabel}>
                    This limit applies across customers so it won't prevent a
                    single customer from redeeming multiple times
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Card>
      {/* <TooltipGlodal /> */}
    </>
  );
};

export default Details;
