import React, { useEffect, useState } from 'react';
import Icon from '../../components/Icon';
import Image from '../../components/Image';
import Theme from "../../components/Theme";
import { Link, useHistory } from 'react-router-dom';
import styles from './SignupDetails.module.sass';
import ProfileDetails from './ProfileDetails';
import FocusDetails from './FocusDetails';
import BusinessDetails from './BusinessDetails';
import AccountConnection from './accountsConnection';
import SaveSuccess from './../../components/SaveSuccess'
import { withRouter } from 'react-router-dom';

const SignUpDetails = (props) => {
    const history = useHistory();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tokenId = params.get("token") || params.get("tokenid") || params.get("myNewToken");

    const token= props.location?.state?.token || tokenId;
    const email = props.location?.state?.email || params.get("email");
    const openingBusinessAccount = props.location?.state?.openingBusinessAccount;

    const [currentStep, setCurrentStep] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [details, setDetails] = useState({
        user: {
            email: email
        }
    });

    // useEffect(() => {
    //     const confirmUnload = (e) => {
    //         e.returnValue = 'Data you entered may not be saved. Are you sure you want to leave the page?';
    //     };
    //     window.addEventListener('beforeunload', confirmUnload);
    //     return () => {
    //         window.removeEventListener('beforeunload', confirmUnload);
    //     };
    // }, []);

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const isGoogleSignUp = params.get("AuthType") || params.get("authtype") || params.get("authType");

        if (isGoogleSignUp) {
            setCurrentStep(0);
            setIsLoaded(true);
        } else if (openingBusinessAccount) {
            setCurrentStep(2);
            setIsLoaded(true);
        } else if (!token) {
            history.push("/dashboard");
        } else {
            setIsLoaded(true);
        }
    }, [])

    return <>
        {isLoaded && (
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.head}>
                        <div className={styles.logo}>
                            <Link
                                to={{ pathname: "https://greatweek.com" }}
                                target='_blank'
                            >
                                <Image
                                    className={styles.pic}
                                    src='/images/logo-dark.png'
                                    srcDark='/images/logo-light.png'
                                    alt='logo'
                                />
                            </Link>
                            <h2 className={styles.subHeading}>
                                We help you automate your businesses, creating massive savings and fuelling growth.
                            </h2>
                        </div>
                    </div>
                </div>

                {openingBusinessAccount && <span className={styles.backButton} onClick={() => history.goBack()}>Back</span>}
                {currentStep === 0 &&
                    <ProfileDetails
                        setCurrentStep={setCurrentStep}
                        setDetails={setDetails}
                        details={details}
                        token={token}
                    />
                }
                {currentStep === 1 &&
                    <FocusDetails
                        setCurrentStep={setCurrentStep}
                        setDetails={setDetails}
                        details={details}
                    />
                }
                {currentStep === 2 &&
                    <BusinessDetails
                        setCurrentStep={setCurrentStep}
                        setDetails={setDetails}
                        details={details}
                        token={token}
                    />
                }
                {currentStep === 3 &&
                    <AccountConnection
                        reffered
                        setCurrentStep={setCurrentStep}
                        details={details}
                        token={token}
                        openingBusinessAccount={openingBusinessAccount}
                    />
                }
                {currentStep === 4 &&
                    <AccountConnection
                        googleAccount
                        setCurrentStep={setCurrentStep}
                        details={details}
                    />
                }
                {currentStep === 5 &&
                    <AccountConnection
                        setCurrentStep={setCurrentStep}
                        details={details}
                    />
                }
                {currentStep === 6 &&
                    <SaveSuccess
                        showSuccessModal={true}
                        text={"You are all set. lets start building your property empire"}
                        onClose={() => history.push("/dashboard")}
                        onConfirm={() => history.push("/dashboard")}
                    />
                }

                <div className={styles.themeWrap}>
                    {/* <a
                        className={styles.link}
                        href='https://help.greatweek.com/'
                        target='blank'
                    >
                        <Icon name='help' size='18' />
                        <span>Help Center</span>
                    </a> */}
                    <Theme className={styles.theme} visibleSidebar={true} />
                </div>
            </div>
        )}
    </>
}

export default withRouter(SignUpDetails);