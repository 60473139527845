export const productStatus = [
    {
      title: "All",
    },
    {
      title: "Discounts",
      path: "/discounts"
    },
    // {
    //   title: "Tax Rates",
    // },
  ];