import React, { useState } from "react";
import styles from "./Checklist.module.sass";
import NoChecklist from "./NoChecklist";
import NewChecklistForm from "./NewChecklistForm";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import SingleChecklist from "./SingleChecklist";
import { useEffect } from "react";
import { getAllCheckList } from "../../../utils/apiCallHanlder";
import Spinner from "../../../utils/spinner";
import cn from "classnames";
import { ToastContainer } from "react-toastify";

let page = 1;

const Checklist = () => {
  const [showChecklist, setShowChecklist] = useState(false);
  const [visible, setVisible] = useState(false);

  const [checklists, setChecklists] = useState([]);
  const [startLoading, setStartLoading] = useState(true);
  const [isNextPageExists, setIsNextPageExists] = useState(false);
  const [currentChecklistId, setCurrentChecklistId] = useState(null);
  const [targetId, setTargetId] = useState(null);

  useEffect(async () => {
    await getCheckListFunction(1);
  }, []);

  const getCheckListFunction = async (currentPage) => {
    setStartLoading(true);
    const { data } = await getAllCheckList({
      pageSize: 30,
      page: currentPage,
    });
    if (data) {
      setIsNextPageExists(data?.isNextPageExist);
      if (currentPage === 1) {
        setChecklists(data?.checklists);
      } else {
        setChecklists(checklists.concat(data?.checklists));
      }
    }
    setStartLoading(false);
  };

  const create = (newChecklist) => {
    setChecklists([...checklists, newChecklist]);
  };

  const remove = (id) => {
    setChecklists(checklists.filter((checklist) => checklist.id !== id));
  };

  const update = (updateChecklist) => {
    const updateChecklists = checklists.map((checklist) => {
      if (checklist.id === updateChecklist.id) {
        return { ...checklist, checklistName: updateChecklist.checklistName };
      }
      return checklist;
    });
    setChecklists(updateChecklists);
  };

  useEffect(() => {
    checklists.length === 0 ? setShowChecklist(false) : setShowChecklist(true);
  }, [checklists]);

  return (
    <div className={styles.checklist}>
      <button
        className={cn("button", styles.addNewBtn)}
        onClick={() => setVisible(true)}
      >
        Create new checklist
      </button>
      <ToastContainer />
      {startLoading && checklists?.length < 1 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner size='64' color='gray' />
        </div>
      ) : (
        <>
          {!showChecklist ? (
            <NoChecklist onClick={() => setVisible(true)} />
          ) : (
            <Card
              className={styles.card}
              /* classTitle="title-blue"
              title="Checklist"
              head={
                <button className="button" onClick={() => setVisible(true)}>
                  Create new
                </button>
              } */
            >
              <div className={styles.checklistWrap}>
                <div className={styles.checklistHead}>
                  <p>Name</p>
                  <p>Fields</p>
                  <p></p>
                </div>
                {checklists.map((x, index) => (
                  <SingleChecklist
                    x={x}
                    remove={remove}
                    update={update}
                    key={index}
                    getCheckListFunction={getCheckListFunction}
                    setChecklists={setChecklists}
                    currentChecklistId={currentChecklistId}
                    setCurrentChecklistId={setCurrentChecklistId}
                    setTargetId={setTargetId}
                    targetId={targetId}
                  />
                ))}
                {isNextPageExists ? (
                  <div className={styles.foot}>
                    <button
                      className={cn(
                        "button-stroke-red button-small",
                        styles.button
                      )}
                      style={{ width: 250 }}
                      onClick={async () => {
                        if (!startLoading) {
                          page = page + 1;
                          await getCheckListFunction(page);
                        }
                      }}
                    >
                      {startLoading ? (
                        <Spinner size='24' color='gray' />
                      ) : (
                        <>
                          <span>See More Checklists</span>
                          <Icon name='arrow-next' size='20'></Icon>
                        </>
                      )}
                    </button>
                  </div>
                ) : null}
              </div>
            </Card>
          )}
          <NewChecklistForm
            visible={visible}
            setVisible={setVisible}
            CreateChecklist={create}
            getCheckListFunction={getCheckListFunction}
            setChecklists={setChecklists}
            setCurrentChecklistId={setCurrentChecklistId}
            setTargetId={setTargetId}
          />
        </>
      )}
    </div>
  );
};

export default Checklist;
