import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import styles from "./Transactions.module.sass";
import cn from "classnames";
import AllTransactions from "../../components/Transactions";
import Card from "../../components/Card";
import Icon from "../../components/Icon";
import AddNewTransactions from "./AddNewTransactions";
import TransactionSend from "./TransactionSend";
import {
  getNextPageTransaction,
  pageLoadTransactions,
} from "../../utils/apiCallHanlder";
import { getConnectStatus, transactionDateFormatter } from "../../utils/utils";
import Spinner from "../../utils/spinner";
import MoneyFlowExplainer from "../../components/MoneyFlowExplainer";
import { useHistory, useParams, withRouter } from "react-router";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Item from "../Home/Overview/Item";
import PayoutBank from "./PayoutBank";
import Dropdown from "../../components/Dropdown_c";
import MainBank from "./MainBank";
import AmountInCents from "../../components/AmountInCents";
import TransactionPopup from "../Accounting/Reconcile/TransactionPopup";
import Modal from "../../components/Modal";
//import TransactionPopup from './TransactionPopup';

const nav = [
  {
    title: "All Accounts",
    counter: "$4470.00",
    value: -37.8,
  },
  {
    title: "Payment Balance",
    counter: "$4070.00",
    value: 37.8,
  },
  {
    title: "Bank Account 9890",
    counter: "$00.00",
    value: 47.8,
  },
  {
    title: "External Account",
    counter: "$300.00",
    value: -37.8,
  },
  {
    title: "Cash",
    counter: "$100.00",
    value: 37.8,
  },
];

const navTitle = [];
// const navBalance = [];
// {
//   nav.map((x) => {
//     navTitle.push(x.title);
//     navBalance.push(x.counter);
//   });
// }
let connect = 1;

const Transactions = (props) => {
  const { id } = useParams();

  const isComingFromCustomer = props.location?.state?.isComingFromCustomer;
  const recipientId = props.location?.state?.recipientId;

  let showTransactionPopupFromPayload =
    props.location?.state?.showTransactionPopupFromPayload;
  const transactionPayloadFromState = props.location?.state?.transactionPayload;
  const recipient = props.location?.state?.recipient;

  const [activeAllAccount, setActiveAllAccount] = useState(false);
  const [activePaymentBalance, setActivePaymentBalance] = useState(false);
  const [activeBank, setActiveBank] = useState(false);
  const [activeExternalAccount, setActiveExternalAccount] = useState(false);
  const [activeCash, setActiveCash] = useState(false);
  const [accountsHead, setAccountsHead] = useState(navTitle[0]);
  const [carouselWidth, setCarouselWidth] = useState(0);

  const responsive = {
    sizeOne: {
      breakpoint: { max: 8000, min: 0 },
      items: carouselWidth / 208,
    },
  };

  const [showDetails, setShowDetails] = useState(false);
  const [showTransactionSend, setShowTransactionSend] = useState(
    id ? true : false
  );
  const [showAddNewTransactions, setShowAddNewTransactions] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [accountDetailPayload, setAccountDetailPayload] = useState({});
  const [isNextPageExist, setIsNextPageExist] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastId, setLastId] = useState(null);

  const [currencyCode, setDefCurrencyCode] = useState("USD");
  const [transactionAccounts, setTransactionAccounts] = useState([]);
  const [payoutBankDetails, setPayoutBankDetails] = useState(null);
  const [mainBankDetails, setMainBankDetails] = useState(null);

  const [visible, setVisible] = useState(false);
  const [nextPageInfo, setNextPageInfo] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [SelectedAccountId, setSelectAccountId] = useState(null);

  const [showTransactionPopup, setShowTransactionPopup] = useState(false);
  const [currentTransactionObject, setCurrentTransactionObject] = useState([]);

  const [currentlySelected, setCurrentlySelected] = useState(null);
  const [currentlySelectedType, setCurrentlySelectedType] = useState("");
  const [tabSwitchLoading, setTabSwitchLoading] = useState(false);

  const [loadingForDetailData, setLoadingForDetailData] = useState(false);

  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());

  const [width, setWidth] = useState("unset");
  const [connectLoading, setConnectLoading] = useState(true);

  const history = useHistory();

  useEffect(async () => {
    const connectStatus = getConnectStatus();
    connect = connectStatus?.status;

    if (showTransactionPopupFromPayload && transactionPayloadFromState) {
      showTransactionPopupFromPayload = false;
      setCurrentTransactionObject(transactionPayloadFromState);
      setShowTransactionPopup(true);
    }
    if (!id) {
      setWidth("unset");
      resetStates();
      setLoading(true);
      const response = await loadInitialDataForTransactions(true);
      setLoading(false);
      document.getElementById("checkX")?.clientWidth >
        document.getElementById("transactionCard")?.parentElement?.clientWidth
        ? setWidth("unset")
        : setWidth("max-content");

      if (!response?.skipNextCall) {
        setLoadingForDetailData(true);
        await loadInitialDataForTransactions(false);
        setLoadingForDetailData(false);
        setWidth("max-content");
      } else {
        setWidth("max-content");
      }

      document.getElementById("checkX")?.clientWidth >
        document.getElementById("transactionCard")?.parentElement?.clientWidth
        ? setWidth("unset")
        : setWidth("max-content");
    }
  }, [id, transactionPayloadFromState]);

  const loadInitialDataForTransactions = async (isFirstLoad) => {
    const { data } = await pageLoadTransactions({
      FromDate: transactionDateFormatter(
        new Date(new Date().setDate(new Date().getDate() - 30))
      ),
      ToDate: transactionDateFormatter(new Date()),
      isFirstLoad,
      PageSize: 50,
    });
    if (data) {
      const id = data?.accountDetail?.id ? data?.accountDetail?.id : "All";
      if (isFirstLoad || id === SelectedAccountId) {
        let filteredAccounts = [];
        if (connect !== 1) {
          filteredAccounts = data?.accounts?.filter(item => item?.id !== "stripebalance");
          setAccounts(filteredAccounts);
          setTransactionAccounts(
            prepareAccountForTransaction(filteredAccounts, data.defaultAccountCurrency)
          );
        } else {
          setAccounts(data?.accounts);
          setTransactionAccounts(
            prepareAccountForTransaction(
              data.accounts,
              data.defaultAccountCurrency
            )
          );
        }




        if (isFirstLoad) {
          setCurrentlySelected(data.accounts[0]?.name);
          setSelectAccountId(data.accounts[0]?.id);
          setCurrentlySelectedType(data.accounts[0]?.type);
        }
        setDefCurrencyCode(data.defaultAccountCurrency);
        setAccountDetailPayload(data.accountDetailPayload);
        setTransactions(data.transactions);
        setIsNextPageExist(data?.isNextPageExist);
        setNextPageInfo(data.nextPageInfo);
      }
    }
  };

  const getAccountSwitchTransactions = async (accountId) => {
    resetStates();
    setTabSwitchLoading(true);
    const { data } = await getNextPageTransaction({
      FromDate: transactionDateFormatter(startDate),
      ToDate: transactionDateFormatter(endDate),
      SelectedAccountId: accountId,
      NextPageInfo: null,
      PageSize: 50,
    });

    if (data) {
      const id = data?.accountDetail?.id ? data?.accountDetail?.id : "All";
      if (id === accountId) {
        setPayoutBankDetails(data?.accountDetail);
        setMainBankDetails(data?.accountDetail);
        setDefCurrencyCode(data.defaultAccountCurrency);
        setAccountDetailPayload(data.accountDetailPayload);
        setTransactions(data.transactions);
        setIsNextPageExist(data?.isNextPageExist);
        setNextPageInfo(data.nextPageInfo);
      }
    }
    setTabSwitchLoading(false);
  };

  const resetStates = () => {
    setShowTransactionSend(false);
    setShowAddNewTransactions(false);
  };

  const prepareAccountForTransaction = (accountsFromApi, defCurrency) => {
    if (!accountsFromApi || !defCurrency) return accountsFromApi;

    defCurrency = defCurrency.toLowerCase();

    if (accountsFromApi?.length === 2) {
      accountsFromApi.shift();
    }

    for (let index = 0; index < accountsFromApi.length; ++index) {
      navTitle.push({
        display: accountsFromApi[index]?.type,
        value: accountsFromApi[index]?.id,
        text: accountsFromApi[index]?.name,
      });

      const accountInfo = accountsFromApi[index];

      if (accountInfo.balances && accountInfo.balances[defCurrency]) {
        accountInfo.displayBalance = accountInfo.balances[defCurrency];
      } else {
        accountInfo.displayBalance = 0;
      }
    }

    setAccountsHead(navTitle[0]?.value);
    return accountsFromApi;
  };

  useEffect(
    () =>
      accountsHead === "All Accounts"
        ? (setActiveAllAccount(true),
          setActivePaymentBalance(false),
          setActiveBank(false),
          setActiveExternalAccount(false),
          setActiveCash(false))
        : accountsHead === "Payment Balance"
          ? (setActiveAllAccount(false),
            setActivePaymentBalance(true),
            setActiveBank(false),
            setActiveExternalAccount(false),
            setActiveCash(false))
          : accountsHead === "Bank Account 9890"
            ? (setActiveAllAccount(false),
              setActivePaymentBalance(false),
              setActiveBank(true),
              setActiveExternalAccount(false),
              setActiveCash(false))
            : accountsHead === "External Account"
              ? (setActiveAllAccount(false),
                setActivePaymentBalance(false),
                setActiveBank(false),
                setActiveExternalAccount(true),
                setActiveCash(false))
              : accountsHead === "Cash"
                ? (setActiveAllAccount(false),
                  setActivePaymentBalance(false),
                  setActiveBank(false),
                  setActiveExternalAccount(false),
                  setActiveCash(true))
                : null,
    [accountsHead]
  );

  const modalCloseHandler = () => {
    setVisible(false);
  };

  return (
    <>
      <>
        <Modal
          visible={showAddNewTransactions}
          onClose={() => { setShowAddNewTransactions(false) }}
          children={<AddNewTransactions
            setShowAddNewTransactions={setShowAddNewTransactions}
            getAccountSwitchTransactions={getAccountSwitchTransactions}
          />}
          outerClassName={styles.outer}
        />
        {loading ? (
          <div className={styles.spinner}>
            <Spinner loading={loading} color='gray' size={50} />
          </div>
        ) : !showDetails && !showTransactionSend && !showAddNewTransactions ? (
          <>
            {visible ? (
              <MoneyFlowExplainer visible={visible} onClose={modalCloseHandler} />
            ) : null}

            <>
              <AllTransactions
                setShowDetails={setShowDetails}
                setShowTransactionPopup={setShowTransactionPopup}
                setCurrentTransactionObject={setCurrentTransactionObject}
                connect={connect}
                boxComponent={
                  <Carousel
                    responsive={responsive}
                    autoPlay={false}
                    showDots={false}
                    arrows={false}
                    containerClass={styles.carouselContainer}
                    partialVisible={true}
                  >
                    {transactionAccounts.map((x, index) => (
                      <Item
                        className={cn(
                          styles.item,
                          {
                            [styles.active]: SelectedAccountId === x?.id,
                          },
                          {
                            [styles["activeItem" + index]]:
                              SelectedAccountId === x?.id,
                          }
                        )}
                        style={{
                          border: SelectedAccountId === x?.id ? 'unset' : '2px solid #6F767E'
                        }}
                        onActive={async () => {
                          if (x.type === "stripebalance") {
                            setActiveBank(false);
                            setActivePaymentBalance(true);
                          } else if (x.type === "Financial") {
                            setActivePaymentBalance(false);
                            setActiveBank(true);
                          } else {
                            setActiveBank(false);
                            setActivePaymentBalance(false);
                          }
                          setNextPageInfo(null);
                          setCurrentlySelected(x.name);
                          setSelectAccountId(x.id);
                          setCurrentlySelectedType(x?.type);
                          await getAccountSwitchTransactions(x.id);
                        }}
                        key={index}
                        item={{
                          title: x.name === "All" ? "All Accounts" : x.name,
                          accountDigit: x?.accountNumberLast4,
                          counter: x.displayBalance ?? "0",
                        }}
                        currency={currencyCode}
                      />
                    ))}
                  </Carousel>
                }
                currentlySelectedAccountId={SelectedAccountId}
                transactions={transactions}
                accountDetailPayload={accountDetailPayload}
                setAccountDetailPayload={setAccountDetailPayload}
                accounts={accounts}
                lastId={lastId}
                setShowTransactionSend={setShowTransactionSend}
                comingFromTransactions={true}
                loadingForDetailData={loadingForDetailData}
                isNextPageExist={isNextPageExist}
                setLastId={setLastId}
                setTransactions={setTransactions}
                nextPageInfo={nextPageInfo}
                setNextPageInfo={setNextPageInfo}
                setShowAddNewTransactions={setShowAddNewTransactions}
                currentlySelectedType={currentlySelectedType?.toLowerCase()}
                setIsNextPageExist={setIsNextPageExist}
                tabSwitchLoading={tabSwitchLoading}
                setCarouselWidth={setCarouselWidth}
                setStartDateTransaction={setStartDate}
                setEndDateTransaction={setEndDate}
                startDateTransaction={startDate}
                endDateTransaction={endDate}
                showLinkButton={false}
              />
            </>
            {activeCash ? (
              <Card className={styles.actionCard}>
                <button
                  onClick={() => {
                    setShowAddNewTransactions(true);
                  }}
                  className={cn("button", styles.button)}
                >
                  <span>Add new transaction</span>
                </button>
              </Card>
            ) : null}
          </>
        ) : null}
        {!showDetails && showTransactionSend && !showAddNewTransactions ? (
          <TransactionSend
            setShowTransactionSend={setShowTransactionSend}
            id={id}
            isComingFromCustomer={isComingFromCustomer}
            recipientId={recipientId}
            recipient={recipient}
          />
        ) : null}
        <TransactionPopup
          showTransactionPopup={showTransactionPopup}
          setShowTransactionPopup={setShowTransactionPopup}
          currentTransactionObject={currentTransactionObject}
          setCurrentTransactionObject={setCurrentTransactionObject}
          getAccountSwitchTransactions={getAccountSwitchTransactions}
        />
      </>
    </>
  );
};

export default withRouter(Transactions);
