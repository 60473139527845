import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import styles from "./Messages.module.sass";
import Panel from "./Panel";
import Message from "./Message";
import Send from "./Send";

const Messages = ({
  className,
  panelTitle,
  visible,
  setVisible,
  actions,
  parameters,
  messages,
  newMessageSend,
}) => {
  const [selectedImages, setSelectedImages] = useState([])

  const handleDeleteImage = (index) => {
    setSelectedImages((items) => items.filter((_, i) => i !== index))
  }

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  return (
    <div className={cn(className, styles.messages, { [styles.show]: visible })}>
      <Panel
        panelTitle={panelTitle}
        actions={actions}
        parameters={parameters}
        setVisible={setVisible}
        selectedImages={selectedImages}
        deleteImage={handleDeleteImage}
      />
      <div className={cn(styles.wrapper, {[styles.withImageWrap]: selectedImages.length >= 1})}>
        {/* <button className={cn("button-stroke button-small", styles.button)}>
          Load conversation
        </button> */}
        <div className={styles.list}>
          {messages.map((x, index) => (
            <Message item={x} key={index} />
          ))}
          <AlwaysScrollToBottom />
        </div>
        <Send  className={styles.sendWrap} setSelectedImages={setSelectedImages} selectedImages={selectedImages} newMessageSend={newMessageSend} />
      </div>
    </div>
  );
};

export default Messages;
