import React from "react";
import Modal from "../../../../components/Modal";
import styles from "./NewEmbeddedForm.module.sass";
import TextInput from "../../../../components/TextInput";
import Spinner from "../../../../utils/spinner";
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import Code from "../Code";

const NewEmbeddedForm = ({
  handleSubmit,
  handleChange,
  userInput,
  createEmbededFormLoading,
  visible,
  onClose,
  fb,
}) => {
  return (
    <Modal
      visible={visible}
      onClose={onClose}
      outerClassName={styles.modalOuter}
      children={
        <Card
          className={styles.card}
          /* classTitle={"title-blue"} */
          title={"Embedded form"}
        >
          <form onSubmit={handleSubmit} id='formContainer' noValidate>
            <div className={styles.top}>
              <TextInput
                label={"Name of form"}
                className={styles.input}
                onChange={handleChange}
                name='embeddedFormName'
                id='embeddedFormName'
                type='text'
                value={userInput.embeddedFormName}
                required
              />
              <button className="button">
                {createEmbededFormLoading ? (
                  <Spinner size='24' color='white' />
                ) : (
                  <>
                    Save
                  </>
                )}
              </button>
            </div>
          </form>
          {/* <div className={styles.codeWrap}>   
            <Code  userInput={userInput}/>
          </div> */}
          <div className={styles.bottom} id='formDiv'>
            <div className={styles.embeddedForm}>
              <div id='fbEditor' ref={fb} />
            </div>
          </div>
        </Card>
      }
    />
  );
};

export default NewEmbeddedForm;
