import React, { useState } from "react";
import Card from "../../../../components/Card";
import cn from "classnames";
import styles from "./NoCustomer.module.sass";
import Image from "../../../../components/Image";
// import AddNewCustomer from '../EditNewCustomer';
import { useParams } from "react-router";

const NoCustomer = ({ onConfirm, id }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      {!visible ?
        <div className={styles.row} style={{ padding: 24 }}>
          <div className={styles.col}>
            <Image
              className={styles.pic}
              src="/images/icons/empty-black.png"
              srcDark="/images/icons/empty-white.png"
              alt="Core"
            />
          </div>
        </div>
        :
        null
        // <AddNewCustomer isAdd={true} id={id} />
      }

    </>
  );
};

export default NoCustomer;
