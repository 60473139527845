export const customers = [
  {
    id: "id0",
    user: "Cotham Limited LLC",
    email: "hello@gautom.com",
    amount: 3200,
    created: "Jul 22, 2022",
    comments: 8,
    icons: "dots",
  },
  {
    id: "id1",
    user: "Cotham Limited LLC",
    email: "hello@gautom.com",
    amount: 3200,
    created: "Jul 22, 2022",
    comments: 8,
    icons: "dots",
  },
  {
    id: "id2",
    user: "Cotham Limited LLC",
    email: "hello@gautom.com",
    amount: 3200,
    created: "Jul 22, 2022",
    comments: 8,
    icons: "dots",
  },
  {
    id: "id3",
    user: "Cotham Limited LLC",
    email: "hello@gautom.com",
    amount: 3200,
    created: "Jul 22, 2022",
    comments: 8,
    icons: "dots",
  },
];
