import Card from "../../../components/Card";
import LargeNav from "../../../components/LargeNav";
import cn from "classnames";
import styles from "./EditNewProject.module.sass";
import { useEffect, useState } from "react";
import Details from "./Details";
import Lenders from "./Lenders";
import {
  getSingleProperty,
  propertyLendersList,
} from "../../../utils/apiCallHanlder";
import Spinner from "../../../utils/spinner";
import Documents from "./Documents";
import Messages from "./Messages";
import Solar from "../../../pages/Solar";
import { useHistory } from "react-router";

const ChatAPI = require("twilio-chat");
const tabs = ["Details", "Solar", "Financing"];
let client = null;

const EditNewProject = ({
  id,
  getPropertiesData,
  setShowAddProject,
  showLendersTab,
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [showDetails, setShowDetails] = useState(showLendersTab ? false : true);
  const [showFinancing, setShowFinancing] = useState(false);

  const [showLenders, setShowLenders] = useState(showLendersTab ? true : false);
  const [showSolar, setShowSolar] = useState(showLendersTab ? true : false);
  const [showDocuments, setShowDocuments] = useState(false);
  const history = useHistory();
  const [checkCount, setCheckCount] = useState(0);
  const [propertyName, setPropertyName] = useState(null);
  const [deleteIdArray, setDeleteIdArray] = useState([]);
  const [retrievalLoading, setRetrievalLoading] = useState(id ? true : false);
  const [lendersLoading, setLendersLoading] = useState(id ? true : false);
  const [property, setProperty] = useState(null);
  const [propertyLenders, setPropertyLenders] = useState([]);

  const [matchedLendersCount, setMatchedLendersCount] = useState(0);
  const [showAllLenders, setShowAllLenders] = useState(false);
  const [channelToCreate, setChannelToCreate] = useState(null);

  useEffect(async () => {
    const chatApiToken = localStorage.getItem("chatAPiToken");
    client = new ChatAPI.Client(chatApiToken, { logLevel: "info" });

    if (id) {
      setRetrievalLoading(true);
      const { data } = await getSingleProperty({
        propertyId: id,
      });
      if (data) {
        setProperty(data?.property);
        await getLendersData(false);
      }
      setRetrievalLoading(false);
    }
  }, [id]);

  const getLendersData = async (showAll) => {
    setLendersLoading(true);
    const lenders = await propertyLendersList({
      propertyId: id,
      showAll: showAll || false,
    });
    if (lenders?.data) {
      setMatchedLendersCount(lenders?.data?.matchedCount);
      setPropertyLenders(lenders?.data?.propertyLenders);
    }
    setLendersLoading(false);
  };


  const largeNav = <LargeNav
    tabOptions={id ? tabs : tabs?.slice(0, 3)}
    activeTab={tabs?.indexOf(selectedTab)}
    onClick={async (x) => {
      setChannelToCreate(null);
      setSelectedTab(x);

      if (x === tabs[0]) {
        setShowDetails(true);
        setShowSolar(false);
        setShowLenders(false);
        setShowDocuments(false);
        setShowFinancing(false)
        return;
      }
      if (x === tabs[1]) {
        setShowDetails(false);
        setShowSolar(true);
        setShowLenders(false);
        setShowDocuments(false);
        setShowFinancing(false)
        return;
      }

      if (x === tabs[2]) {
        setShowDetails(false);
        setShowSolar(false);
        setShowLenders(false);
        setShowDocuments(false);
        setShowFinancing(true)
        return;
      }

      if (x === tabs[3]) {
        setShowDetails(false);
        setShowSolar(false);
        setShowLenders(true);
        setShowDocuments(false);
        setShowFinancing(false)
        return;
      }
      if (x === tabs[4]) {
        setShowDetails(false);
        setShowSolar(false);
        setShowLenders(false);
        setShowDocuments(true);
        setShowFinancing(false)
        return;
      }

      setShowDetails(false);
      setShowLenders(false);
      setShowSolar(false);
      setShowDocuments(false);
      setShowFinancing(false)
    }}
    onlyLarge
  />

  const backBtn = (
    <div
      className={cn("button-stroke-red", styles.backBtn)}
      onClick={() => {
        history.push("/projects");
        setShowAddProject(false);
        getPropertiesData(false);
      }}
    >
      Cancel
    </div>
  );

  return (
    <>
      {retrievalLoading ? (
        <div className={styles.spinner}>
          <Spinner size="48" color="gray" />
        </div>
      ) : (
        <>
          {showDetails && (
            <Details
              getPropertiesData={getPropertiesData}
              setShowAddProject={setShowAddProject}
              property={property}
              id={id}
              onSaveHandler={() => {
                history.push("/projects");
                //setSelectedTab(tabs[3]);
                //setShowDetails(false);
                //setShowLenders(true);
              }}
              setProperty={setProperty}
              setName={setPropertyName}
              name={propertyName}
              matchedLendersCount={matchedLendersCount}
              propertyName={propertyName}
              largeNav={largeNav}
              backBtn={backBtn}
            />
          )}

          {showFinancing && (
            <Details
              getPropertiesData={getPropertiesData}
              setShowAddProject={setShowAddProject}
              property={property}
              id={id}
              onSaveHandler={() => {
                history.push("/projects");
                //setSelectedTab(tabs[3]);
                //setShowDetails(false);
                //setShowLenders(true);
              }}
              setProperty={setProperty}
              setName={setPropertyName}
              name={propertyName}
              matchedLendersCount={matchedLendersCount}
              propertyName={propertyName}
              largeNav={largeNav}
              backBtn={backBtn}
              showFinancing={showFinancing}
            />
          )}

          {showLenders && (
            <Lenders
              checkCount={checkCount}
              setCheckCount={setCheckCount}
              deleteIdArray={deleteIdArray}
              setDeleteIdArray={setDeleteIdArray}
              lendersLoading={lendersLoading}
              propertyLenders={propertyLenders}
              id={id}
              property={property}
              getLendersData={getLendersData}
              setShowAllLenders={setShowAllLenders}
              showAllLenders={showAllLenders}
              matchedLendersCount={matchedLendersCount}
              setProperty={setProperty}
              setSelectedTab={setSelectedTab}
              setShowLenders={setShowLenders}
              setChannelToCreate={setChannelToCreate}
              backBtn={backBtn}
              largeNav={largeNav}
              propertyName={propertyName}
            />
          )}
          {showSolar && (
            <Solar
              backBtn={backBtn}
              largeNav={largeNav}
              address={property?.address}
            />
          )}
          {showDocuments && (
            <Documents
              propertyId={id}
              backBtn={backBtn}
              largeNav={largeNav}
              propertyName={propertyName}
            />
          )}
          {selectedTab === tabs[5] && (
            <Messages
              client={client}
              id={id}
              channelToCreate={channelToCreate}
              backBtn={backBtn}
              largeNav={largeNav}
              propertyName={propertyName}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditNewProject;
