import React, { useState, useEffect } from "react";
import styles from "./MessageCenter.module.sass";
import cn from "classnames";
import ConversationList from "./Users";
import Messages from "./Messages";
import {
  ensureIntercomContact,
  createConversation,
  replyToConversation,
  retrieveConversation,
} from "../../utils/apiCallHanlder";
import Spinner from "../../utils/spinner";
import useInterval from "../../utils/useInterval";

const navigation = [
  {
    title: "Open",
    icon: "mail",
    type: "open",
  },
  {
    title: "Closed",
    icon: "inbox",
    type: "closed",
  },
];

const actions = [
  // {
  //   title: "Close conversion",
  //   icon: "inbox",
  //   action: () => console.log("closed"),
  // }
];

// example of conversation item?
// const users = [
//   {
//     id: 0,
//     title: "Attach a receipt",
//     time: "1 day",
//     content: "Hi Bruce, we are missing a recipet for transaction ID #1919191",
//     new: true,
//     online: false,
//   },
// ];

const MessageCenter = () => {
  const [startLoading, setStartLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [messages, setMessages] = useState([]);
  const [currentConversationId, setCurrentConversationId] = useState(-1);
  const [intercomUserId, setintercomUserId] = useState("");
  const [panelTitle, setPanelTitle] = useState("");
  const [conversationDisplayParameters, setConversationDisplayParameters] =
    useState([]);
  const [isConversationListSignalFlag, setIsConversationListSignalFlag] =
    useState(false);
  const [messageRefreshDelay, setMessageRefreshDelay] = useState(15000);

  const handleSubmit = (e) => {
    alert();
  };

  useEffect(async () => {
    setStartLoading(false);
    const { data } = await ensureIntercomContact();
    if (data && data.contactId) {
      setintercomUserId(data.contactId);
    }
  }, []);

  useInterval(async () => {
    if (currentConversationId > 0) {
      let latestMessage = messages.reduce((a, b) =>
        a.timestamp > b.timestamp ? a : b
      );
      if (
        latestMessage &&
        messageRefreshDelay !== 10000 &&
        subtractDatesGetMinutesDiff(latestMessage.timestamp) < 5
      ) {
        setMessageRefreshDelay(10000);
        return;
      } else if (
        latestMessage &&
        messageRefreshDelay !== 30000 &&
        subtractDatesGetMinutesDiff(latestMessage.timestamp) > 5
      ) {
        setMessageRefreshDelay(30000);
        return;
      }
      const { data } = await retrieveConversation(
        intercomUserId,
        currentConversationId
      );
      if (data && data.conversation && data.conversation.conversation_parts) {
        reloadMessagesFromConversation(data.conversation);
      }
    }
  }, messageRefreshDelay);

  function subtractDatesGetMinutesDiff(intercomTime) {
    var date = new Date().getTime();
    var mysqlDate = new Date(intercomTime * 1000);
    var getMYSQLDate = mysqlDate.getTime();

    var SubtractDates = Math.floor((date - getMYSQLDate) / 1000);
    return SubtractDates / 60;
  }

  const newConversation = (e) => {
    setCurrentConversationId(-1);
    setPanelTitle("New conversation");
    setConversationDisplayParameters([
      {
        title: "Send new message to start new conversation",
        content: "",
      },
    ]);
    // clear messages (show some placeholder text)
    setMessages([]);
  };

  const conversationActivated = async (conversationId, title) => {
    setCurrentConversationId(conversationId);
    setPanelTitle(title);
    setConversationDisplayParameters([
      {
        title: "Conversation ID",
        content: conversationId,
      },
    ]);
    // load messages into message list
    const { data } = await retrieveConversation(intercomUserId, conversationId);
    if (data && data.conversation && data.conversation.conversation_parts) {
      reloadMessagesFromConversation(data.conversation);
    }
  };

  const reloadMessagesFromConversation = (conversation) => {
    var loadedMessages = [];
    loadedMessages[0] = {
      sender: conversation.source.author.type,
      body: conversation.source.body,
      timestamp: conversation.created_at,
    };
    var i = 1;
    conversation.conversation_parts
      .filter((v) => v.body != null && v.body !== "")
      .forEach((conversationItem) => {
        i++;
        loadedMessages[i] = {
          sender: conversationItem.author.type,
          body: conversationItem.body,
          timestamp: conversationItem.created_at,
        };
      });
    setMessages(loadedMessages);
  };

  const newMessageSend = async (newMessage) => {
    if (newMessage && newMessage.text !== "") {
      if (currentConversationId === -1) {
        const { data } = await createConversation(
          intercomUserId,
          newMessage.text
        );
        setCurrentConversationId(data.newConversation.conversation_id);
        setPanelTitle("");
        setConversationDisplayParameters([
          {
            title: "Conversation ID",
            content: data.newConversation.conversation_id,
          },
        ]);
        setMessages([
          {
            sender: "user",
            body: newMessage.text,
            timestamp: Math.floor(Date.now() / 1000),
          },
        ]);
        setIsConversationListSignalFlag(!isConversationListSignalFlag); // force conversation list to refresh
      } else {
        const { data } = await replyToConversation(
          intercomUserId,
          currentConversationId,
          newMessage.text
        );
        if (data && data.newReplyConversation) {
          // messages.splice(0, 0, {
          //     sender: 'user',
          //     body: newMessage.text,
          //     timestamp: Math.floor(Date.now() / 1000)
          // });
          messages.push({
            sender: "user",
            body: newMessage.text,
            timestamp: Math.floor(Date.now() / 1000),
          });
          setMessages(messages.slice(0));
        }
      }
    }
  };

  return (
    <>
      {!startLoading && intercomUserId ? (
        <div className={cn(styles.wrapper, { [styles.active]: visible })}>
          <ConversationList
            className={styles.users}
            navigation={navigation}
            setVisible={setVisible}
            search={search}
            setSearch={setSearch}
            onSubmit={() => handleSubmit()}
            intercomUserId={intercomUserId}
            newConversation={newConversation}
            conversationActivated={conversationActivated}
            isSignalFlag={isConversationListSignalFlag}
            currentMessangerConversationId={currentConversationId}
          />
          <Messages
            className={styles.messages}
            panelTitle={panelTitle}
            visible={visible}
            setVisible={setVisible}
            actions={actions}
            parameters={conversationDisplayParameters}
            messages={messages}
            newMessageSend={newMessageSend}
          />
        </div>
      ) : (
        <div
          className={styles.spinner}
          style={{ height: window.outerHeight - 250 }}
        >
          <Spinner loading={startLoading} size={150} color={"gray"} />
        </div>
      )}
    </>
  );
};

export default MessageCenter;
