import React, { useState } from "react";
import cn from "classnames";
import styles from "./InviteTeam.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Entry from "./Entry";
import Takeoff from "./Takeoff";
import Image from "../../components/Image";
import { useParams } from "react-router";

const InviteTeam = () => {
  const [visible, setVisible] = useState(true);
  const heightWindow = use100vh();
  const { id } = useParams();

  return (
    <div className={styles.row}>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        <div className={styles.head}>
          <Link className={styles.logo} to="/">
            <Image
              className={styles.pic}
              src="/images/logo-dark.png"
              srcDark="/images/logo-light.png"
              alt="logo"
            />
          </Link>
        </div>
        <div className={styles.wrap}>
          <div className={cn("h2", styles.title)}>Complete Invite</div>
          {visible ? <Entry code={id} onConfirm={() => setVisible(false)} /> : <Takeoff />}
        </div>
      </div>
    </div>
  );
};

export default InviteTeam;
