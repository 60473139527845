import React, { useEffect, useState } from "react";
import styles from "./TransactionsDetails.module.sass";
import Icon from "../../components/Icon";
import Card from "../../components/Card";
import cn from "classnames";
import TextInput from "../../components/TextInput";
import ModalImage from "react-modal-image";
import { useHistory, useParams } from "react-router";
import {
  getExtraTransactionData,
  AddTransactionAttachment,
  deleteTransactionAttachment,
  getIndividualTransaction,
  updateTransactionData,
  uploadFile,
  uploadProductImage,
} from "../../utils/apiCallHanlder";
import Spinner from "../../utils/spinner";
import Dropdown from "../../components/DropdownCategory";
import OutsideClickHandler from "react-outside-click-handler";
import {
  dateFormatter,
  dateFormatterWithTimeZone,
  GetFileNameFromPath,
  toastConfiguration,
  toTitleCase,
  formatCentsAmount,
  isEmptyString,
} from "../../utils/utils";
import { toast, ToastContainer } from "react-toastify";
import Transactions from "../../components/LedgerTransactions";

let typingTimer;
let doneTypingInterval = 300;
const categories = {
  Software: [
    {
      text: "IT",
      value: "IT",
      display: "IT",
    },
    {
      text: "Software",
      value: "Software",
      display: "Software",
    },
    {
      text: "Saas",
      value: "Saas",
      display: "Saas",
    },
  ],
  Travel: [
    {
      text: "Hotel",
      value: "Hotel",
      display: "Hotel",
    },
    {
      text: "Taxi",
      value: "Taxi",
      display: "Taxi",
    },
    {
      text: "Train",
      value: "Train",
      display: "Train",
    },
  ],
  Entertainment: [
    {
      text: "Resturant",
      value: "Resturant",
      display: "Resturant",
    },
    {
      text: "Gift",
      value: "Gift",
      display: "Gift",
    },
  ],
  Others: [
    {
      text: "Other",
      value: "Other",
      display: "Other",
    },
  ],
};

const TransactionsDetails = (props) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [showDetails, setShowDetails] = useState(true);

  const [transactionAttachments, setTransactionAttachments] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [categoryName, setCategoryName] = useState(categories[0]?.value);
  const [notes, setNotes] = useState("");

  const [categoryLoading, setCategoryLoading] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [notesLoading, setNotesLoading] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [fileNameObject, setFileNameObject] = useState({});

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [gLedger, setGLedger] = useState([]);
  const [accountingStatus, setAccountingStatus] = useState("");
  const [accountingStatusOn, setAccountingStatusOn] = useState(null);
  const [isNotSavedChangesExists, setIsNotSavedChangesExists] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  const accountId = props?.location?.state?.accountId;
  const accountType = props?.location?.state?.accountType;
  const transactionDetail =
    props?.location?.state?.transactionDetail ||
    props?.location?.state?.accountType;
  const status = props?.location?.state?.status;
  const amount = props?.location?.state?.amount;
  const desc = props?.location?.state?.desc;

  const [isFetchTransactionData, setIsFetchTransactionData] = useState(
    typeof props?.location?.state?.isFetchTransactionData === "undefined"
      ? true
      : props?.location?.state?.isFetchTransactionData
  );
  const [transactionPayload, setTransactionPayload] = useState(
    typeof props?.location?.state?.transactionPayload === "undefined"
      ? {}
      : props?.location?.state?.transactionPayload
  );

  const imageWRTExtension = {
    common: "/images/content/image.png",
    xlsx: "/images/content/xlsx.png",
    xls: "/images/content/xlsx.png",
    pdf: "/images/content/pdf.png",
  };

  const detailsOverview = [
    {
      companyName: transactionPayload?.description,
      showOnlyForType: "all",
    },
    {
      title: "Date",
      text: dateFormatterWithTimeZone(transactionPayload?.created),
      showOnlyForType: "all",
    },
    {
      title: "Amount",
      text:
        typeof transactionPayload?.currency !== "undefined"
          ? formatCentsAmount(
              transactionPayload?.amountValue,
              transactionPayload?.currency
            )
          : transactionPayload?.amountValue,
      showOnlyForType: "all",
    },
    {
      title: "Account",
      text: transactionPayload?.accountType,
      showOnlyForType: "all",
    },
    {
      title: "Payment Type",
      text: transactionPayload?.transactionDetail || transactionDetail,
      showOnlyForType: "all",
    },
    {
      title: "Fee",
      text:
        typeof transactionPayload?.currency !== "undefined"
          ? formatCentsAmount(
              transactionPayload?.fee,
              transactionPayload?.currency
            )
          : transactionPayload?.fee,
      showOnlyForType: "balance",
    },
    {
      title: "Net",
      text:
        typeof transactionPayload?.currency !== "undefined"
          ? formatCentsAmount(
              transactionPayload?.net,
              transactionPayload?.currency
            )
          : transactionPayload?.net,
      showOnlyForType: "balance",
    },
    {
      title: "Authorization Method",
      text: toTitleCase(transactionPayload?.authorizationMethod),
      showOnlyForType: "all",
    },
    {
      title: "Status",
      text: toTitleCase(transactionPayload?.status),
      showOnlyForType: "all",
    },
    {
      title: "Treasury Issuing Authorization Status",
      text: toTitleCase(transactionPayload?.cardTreasuryIssuingAuthStatus),
      showOnlyForType: "all",
    },
    {
      title: "Treasury Received Debit Status",
      text: toTitleCase(transactionPayload?.cardTreasuryReceivedDebitStatus),
      showOnlyForType: "all",
    },
    {
      title: "Statement Descriptor",
      text: transactionPayload?.statementDescriptor,
      showOnlyForType: "balance",
    },

    {
      title: "Merchant Country",
      text: transactionPayload?.merchantCountry,
      showOnlyForType: "all",
    },
    {
      title: "Merchant Currency",
      text: transactionPayload?.merchantCurrency?.toUpperCase(),
      showOnlyForType: "all",
    },
    {
      title: "Currency",
      text: transactionPayload?.currency?.toUpperCase(),
      showOnlyForType: "all",
    },
    {
      title: "Connections - Invoice",
      text: transactionPayload?.invoiceNumber,
      showOnlyForType: "balance",
    },
    {
      title: "Connections - Invoice Id",
      text: transactionPayload?.invoiceId,
      showOnlyForType: "balance",
    },
    {
      title: "Transaction ID",
      text: transactionPayload?.id,
      showOnlyForType: "all",
    },
  ];

  useEffect(() => {
    getTransaction();
  }, []);

  const getTransaction = async () => {
    setInitialLoading(true);

    if (isFetchTransactionData && accountId && accountType) {
      const { data } = await getIndividualTransaction({
        id,
        accountId,
        accountType,
      });

      if (data) {
        setTransactionAttachments(data.attachments);
        setCategoryName(data.transaction?.transactionCategoryName);
        setNotes(data.transaction?.notes);
      }
    } else {
      const { data } = await getExtraTransactionData({
        id,
        transactionPayload,
      });
      if (data) {
        setAccountingStatus(data?.accountingStatus);
        setAccountingStatusOn(dateFormatter(data?.accountingStatusOn));
        setGLedger(data?.accounting?.gLedger);
        setTransactionAttachments(data.attachments);
        setCategoryName(data.transactionCategoryName);
        setNotes(data.notes);
        if (data.transactionPayload) {
          setTransactionPayload(data.transactionPayload);
        }
      }
    }
    setInitialLoading(false);
  };

  const saveUpdates = async () => {
    if (saveLoading) {
      return;
    }
    setSaveLoading(true);
    const { data } = await updateTransactionData({
      id,
      accountId,
      accountType,
      notes: notes,
      transactionCategoryName: categoryName,
    });

    if (data) {
      toast.success(
        "Transactional detail saved successfully",
        toastConfiguration
      );
    } else {
      toast.error("Error in saving Transactional detail", toastConfiguration);
    }

    setSaveLoading(false);
    setIsNotSavedChangesExists(false);
  };

  const onNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const imageUploadHandler = async (event) => {
    setImageUploadLoading(true);
    setSelectedImage(event.target.files[0]);
    const formData = new FormData();
    formData.append("", event.target.files[0]);
    const { data, error } = await uploadFile(formData);

    if (data) {
      const imageUrl = data?.file?.url;
      const res = await AddTransactionAttachment({
        id,
        accountId,
        accountType,
        attachmentLink: imageUrl,
        attachmentType: "File",
      });

      if (res.data) {
        transactionAttachments.push(res.data?.attachmentLink);
        setTransactionAttachments(transactionAttachments);
        toast.success("Attachment Linked successfully", toastConfiguration);
      } else {
        toast.error("Error in linking attachment", toastConfiguration);
      }

      setImageUploadLoading(false);
    }
    else
    {
      toast.error(error || "Error in uploading file", toastConfiguration);
    }
  };

  const deleteImageHandler = async (e, attachmentLink) => {
    setDeleteLoading(true);
    e.stopPropagation();
    const { data } = await deleteTransactionAttachment({
      id,
      attachmentLink,
    });
    if (data) {
      setTransactionAttachments(
        transactionAttachments.filter(function (item) {
          return item !== attachmentLink;
        })
      );
      toast.success("Attachment deleted Successfully", toastConfiguration);
    } else {
      toast.error("Error in deleting attachment", toastConfiguration);
    }
    setDeleteLoading(false);
  };

  return (
    <>
      {initialLoading ? (
        <div style={{ position: "absolute", left: "50%", top: "50%" }}>
          <ToastContainer />
          <Spinner size='64px' color='gray' loading={initialLoading} />
        </div>
      ) : (
        <>
          <div className={styles.title}>
            <div
              className={styles.backBtn}
              onClick={() => history.push("/transactions")}
            >
              Transactions <Icon name='arrow-next' size='14' />
            </div>
            <p>Transactions Details</p>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <Card
                className={styles.card}
                title='Overview'
                /* classTitle="title-blue" */
              >
                {detailsOverview.map((x, index) =>
                  (x.showOnlyForType == "all" ||
                    x.showOnlyForType ==
                      transactionPayload?.transactionOriginType) &&
                  (!isEmptyString(x.text) || !isEmptyString(x.companyName)) ? (
                    <>
                      <div
                        key={index}
                        className={cn(
                          { [styles.noBorder]: x.companyName },
                          styles.singleInfo
                        )}
                      >
                        <div
                          className={cn(
                            { [styles.companyName]: x.companyName },
                            styles.overviewTitle
                          )}
                        >
                          {x.companyName ? x.companyName : x.title}
                        </div>
                        <div className={styles.text}>{x.text}</div>
                      </div>
                    </>
                  ) : null
                )}
              </Card>
            </div>
            <div className={styles.col}>
              <Card
                className={styles.card}
                title='Additional info'
                /* classTitle="title-yellow" */
              >
                <div className={styles.field}>
                  <div className={styles.fieldLabel}>
                    <span>Document</span>
                  </div>
                  <div className={styles.inputWrap}>
                    {imageUploadLoading ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "70%" }}>
                          {selectedImage?.name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "30%",
                          }}
                        >
                          <Spinner
                            size='24px'
                            color='gray'
                            loading={imageUploadLoading}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className={styles.imgInput}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {transactionAttachments.map((x, index) => {
                            const fileNameObj = GetFileNameFromPath(x, true);
                            return (
                              <div
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setShowPreviewModal(true);
                                  setPreviewImage(x);
                                  setFileNameObject(fileNameObj);
                                  // const ex = fileNameObj.extension;
                                  // if (ex === "png" || ex === "jpg" || ex === "jpeg") {
                                  //   setPreviewImage(x);
                                  // } else {
                                  //   toast.error("No preview available", toastConfiguration);
                                  // }
                                }}
                                style={{ marginLeft: 10, marginTop: 10 }}
                              >
                                <div className={styles.selectedImageWrap}>
                                  <img
                                    className={styles.selectedImage}
                                    style={{ cursor: "pointer" }}
                                    src={
                                      fileNameObj.extension === "png" ||
                                      fileNameObj.extension === "jpg" ||
                                      fileNameObj.extension === "jpeg"
                                        ? x
                                        : imageWRTExtension[
                                            fileNameObj.extension
                                          ] || imageWRTExtension["common"]
                                    }
                                    //large={previewImage}
                                    hideZoom
                                  />
                                  <div className={styles.action}>
                                    {deleteLoading ? (
                                      <>
                                        <div className={styles.modal}>
                                          <div
                                            style={{
                                              position: "fixed",
                                              left: "50%",
                                              top: "45%",
                                            }}
                                          >
                                            <Spinner size='48' color='gray' />
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <button
                                        onClick={(e) =>
                                          deleteImageHandler(e, x)
                                        }
                                      >
                                        <Icon name='close' size='16' />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          <div style={{ marginLeft: 10, marginTop: 10 }}>
                            <label
                              className={styles.inputLabel}
                              htmlFor='fileInput'
                            >
                              <Icon name='add' />
                            </label>
                            <input
                              type='file'
                              className={styles.input}
                              name='myImage'
                              id='fileInput'
                              //accept="image/png, image/gif, image/jpeg"
                              onChange={(event) => imageUploadHandler(event)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.field}>
                  <Dropdown
                    label='Category'
                    className={cn(styles.dropdown, styles.userChooseDrop)}
                    classDropdownHead={styles.dropdownHead}
                    value={categoryName}
                    showSearch={"true"}
                    setValue={setCategoryName}
                    options={categories}
                    taxcodes={categories}
                    loading={categoryLoading}
                    onChange={() => {
                      setIsNotSavedChangesExists(true);
                    }}
                    //updateTransaction={updateTransaction}
                  />
                </div>

                <div className={styles.field}>
                  <TextInput
                    label='Notes'
                    descriptionLoading={notesLoading}
                    onChange={onNotesChange}
                    value={notes}
                    placeholder='Notes'
                    name='notes'
                    type='text'
                    onKeyUp={() => {
                      setIsNotSavedChangesExists(true);
                      //clearTimeout(typingTimer);
                      //typingTimer = setTimeout(() => updateTransaction("notes"), doneTypingInterval);
                    }}
                    required
                  />
                </div>
                <div>
                  <div className={styles.bottomContainer}>
                    <label>Accounting Status</label>
                    {accountingStatus === "Unreconciled" ? null : (
                      <label>Booked Date</label>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 4,
                    }}
                  >
                    <label
                      className={styles.status}
                      style={{
                        color:
                          accountingStatus === "Unreconciled"
                            ? "#FF4900"
                            : "#490000",
                      }}
                    >
                      {accountingStatus}
                    </label>
                    {accountingStatus === "Unreconciled" ? null : (
                      <label className={styles.bookedDate}>
                        {accountingStatusOn}
                      </label>
                    )}
                  </div>
                </div>
                {isNotSavedChangesExists ? (
                  <div className={styles.field}>
                    <button
                      onClick={() => saveUpdates()}
                      className={cn("button-save", styles.button)}
                    >
                      {saveLoading ? (
                        <Spinner size='24' color='white' />
                      ) : (
                        <>
                          <span>Save</span>
                        </>
                      )}
                    </button>
                  </div>
                ) : null}
              </Card>
            </div>

            <ToastContainer />
            {gLedger?.length > 0 ? (
              <Transactions
                transactions={gLedger}
                headerTitle='Transaction Accounting'
                isNextPageExist={false}
                className={cn("button-save", styles.transactions)}
                loading={initialLoading}
                setLoading={setInitialLoading}
                showDate={false}
              />
            ) : null}

            <div className={styles.col12}>
              <Card className={styles.actionCard}>
                <button
                  onClick={() => history.goBack()}
                  className={cn("button-stroke", styles.button)}
                >
                  <Icon name='arrow-left' size='24' />
                  <span>Cancel</span>
                </button>
              </Card>
            </div>

            {showPreviewModal ? (
              <div id='modal' className={styles.modal}>
                <div className={cn(styles.outer, props.outerClassName)}>
                  <Card
                    className={cn(styles.card, props.className)}
                    classCardHead={styles.head}
                    title='Image preview'
                    /* classTitle={cn("title-green", styles.title)} */
                  >
                    <OutsideClickHandler
                      onOutsideClick={() => setShowPreviewModal(false)}
                    >
                      <button
                        className={styles.close}
                        onClick={() => setShowPreviewModal(false)}
                      >
                        <Icon name='close' size='20' />
                      </button>

                      <div
                        className={styles.selectedImageWrap}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          className={styles.selectedImage}
                          src={
                            fileNameObject.extension === "png" ||
                            fileNameObject.extension === "jpg" ||
                            fileNameObject.extension === "jpeg"
                              ? previewImage
                              : imageWRTExtension[fileNameObject.extension] ||
                                imageWRTExtension["common"]
                          }
                          hideZoom
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 30,
                        }}
                      >
                        <a
                          download='transaction_attachment'
                          href={previewImage}
                          className={cn("button-save", styles.button)}
                        >
                          <Icon name='download' size='24' />
                          <span>Download</span>
                        </a>
                      </div>
                    </OutsideClickHandler>
                  </Card>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default TransactionsDetails;
