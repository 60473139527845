import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import { getCurrentUser, logoutHandler, switchCompanyHandler } from "../../../utils/utils";
import styles from "./User.module.sass";
import { useHistory } from "react-router-dom";
import Icon from "../../Icon";
import Modal from "../../Modal";
import Image from "../../Image";
import { useEffect } from "react";

let user = null;
let company = null;

const companyDetails = [
  {
    logo: "https://images.unsplash.com/photo-1553835973-dec43bfddbeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    name: "Company Name LLC",
    position: "Admin"
  },
  {
    logo: "https://images.unsplash.com/photo-1602934445884-da0fa1c9d3b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=958&q=80",
    name: "Company 2 LLC",
    position: "staff"
  },
]

const User = ({ className, userNamePos, connectStatus, forLogoutOnly, changeBuisnessLogo }) => {
  const [visible, setVisible] = useState(false);
  const [businessLogo, setBusinessLogo] = useState("");
  const [showCompanies, setShowCompanies] = useState(false);
  const history = useHistory();

  user = getCurrentUser();
  company = JSON.parse(localStorage.getItem("company"));

  let items = [];
  if (forLogoutOnly) {
    items = [
      {
        menu: [
          {
            title: "Log out",
          },
        ],
      },
    ];
  } else {
    items = [
      {
        menu: [
          {
            title: "Switch Company",
            icon: "repeat",
            switchBusiness: true,
          },
        ],
      },
      {
        menu: [
          {
            title: "My Profile",
            url: "/settings/Profile",
            isSetting: true,
            icon: "profile-circle"
          },
        ],
      },
      {
        menu: [
          {
            title: "Log out",
            icon: "arrow-right"
          },
        ],
      },
    ];
  }

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
        <div
          className={cn(styles.user, className, { [styles.active]: visible })}
        >
          <button className={styles.head} onClick={() => setVisible(!visible)}>
            <img
              src={user?.imageurl}
              alt="Avatar"
            />
            {company?.name ?
              <>
                <span className={cn(styles.item, styles.itemP)}>{company?.name}
                <span className={styles.item2}>{user?.name} {user?.surname}</span>
                </span>
               
                <Icon name={
                  visible ? "arrow-up" : "arrow-down"
                } size="24" />
              </>
              :
              <>
                  {(user?.name || user?.surname) ? <>
                  <span className={cn(styles.item, styles.itemP, styles.item1)}>
                  <span className={styles.item2}>{user?.name} {user?.surname}</span>
                  </span>
                  </> : null}
                  <Icon name={
                    visible ? "arrow-up" : "arrow-down"
                  } size="24" />
              </>
            }
            {userNamePos && userNamePos}
          </button>
          <div className={styles.body}>
            {items.map((item, index) => (
              <div className={styles.menu} key={index}>
                {item.menu.map((x, index) =>
                  x.url ? (
                    x.isSetting ? (
                      <NavLink
                        className={cn(styles.item, { [styles.color]: x.color })}
                        to={x.url}
                        key={index}
                        onClick={() => {
                          history.push(x.url);
                          setVisible(false);
                        }}
                      >
                        {x.icon && <Icon name={x.icon} size="24" />}
                        {x.title}
                      </NavLink>
                    ) : (
                      <NavLink
                        className={cn(styles.item, { [styles.color]: x.color })}
                        // activeClassName={styles.active}
                        to={x.url}
                        onClick={() => {
                          setVisible(false);
                        }}
                        key={index}
                      >
                        {x.icon && <Icon name={x.icon} size="24" />}
                        {x.title}
                      </NavLink>
                    )
                  ) : x.title === "Switch Company" && (connectStatus === 2 || connectStatus === 3) ?
                    null
                    : (
                      <button
                        className={styles.item}
                        onClick={() => {
                          if (x.title === "Log out") {
                            logoutHandler(history);
                          }
                          if (x.switchBusiness) {
                            switchCompanyHandler(history);
                          }
                          setVisible(false);
                        }}
                        key={index}
                      >
                        {x.icon && <Icon name={x.icon} size="24" />}
                        {x.switchBusiness ? "Change Company" : x.title}
                      </button>
                    )
                )}
              </div>
            ))}
          </div>
        </div>
      </OutsideClickHandler>
      <Modal
        visible={showCompanies}
        onClose={() => setShowCompanies(false)}
        children={
          <div className={styles.chooseCompanyWrap}>
            <div className={styles.brandLogo}>
              <Image
                src={"/images/logo-dark.png"}
                srcDark={"/images/logo-light.png"}
              />
            </div>
            <h3>Hey “First Name” 👋</h3>
            <div className={styles.companyWrap}>
              <p className={styles.label}>Choose a company</p>
              {companyDetails.map((x, index) => (
                <div className={styles.company}>
                  <img src={x.logo} alt="logo" />
                  <div className={styles.right}>
                    <div className={styles.name}>{x.name}</div>
                    <div className={styles.position}>{x.position}</div>
                  </div>
                </div>
              ))}
              <button className={styles.addNewBtn}>
                <Icon name="add" size="32" />
                Signup a new company
              </button>
            </div>
          </div>
        }
      />
    </>
  );
};

export default User;
