import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Checkbox from "../../../../../components/Checkbox";
import Icon from "../../../../../components/Icon";
import Amount from "../../../../../components/Amount";
import { dateFormatter } from "../../../../../utils/utils";

const Row = ({
  settings,
  item,
  onChange,
  activeTable,
  activeId,
  className,
  editAction,
  deleteAction,
  singleCheck,
  name,
  editProduct,
  hideExtras,
  archiveAction,
  isArchived,
  setId,
  setVisibleModal,
  setCustomerVisible,
  availableCheckCount,
  archivedCheckCount,
}) => {
  const [visible, setVisible] = useState(false);
  item.icon = "dots";

  return (
    <>
      <div
        className={cn(
          styles.row,
          {
            [styles.selected]: activeId === item.id,
          },
          {
            [styles.active]: activeTable,
          }
        )}
      >
        <div
          className={styles.editOverlay}
          onClick={() => {
            editProduct(item);
          }}
        >
          {" "}
        </div>{" "}
        {false && !hideExtras &&  !settings && (
          <div className={styles.col}>
            {" "}
            {!isArchived &&
              (singleCheck || (
                <Checkbox
                  name={name}
                  className={
                    styles.checkbox
                  }
                  onChange={(e) => onChange(e, item.id)}
                />
              ))}{" "}
            <div
              className={cn(
                styles.userImage,
                isArchived
                  ? archivedCheckCount < 1
                    ? [styles.showUserImg]
                    : [styles.hideUserImg]
                  : availableCheckCount < 1
                    ? [styles.showUserImg]
                    : [styles.hideUserImg]
              )}
            >
              <img
                className={styles.image}
                src={
                  item.imageUrl ? item.imageUrl : "/images/content/image.png"
                }
                alt='product'
              />
            </div>{" "}
          </div>
        )}
        <div className={styles.col}>
          <div className={styles.priceTag}> Name </div>{" "}
          <div className={styles.product} onClick={() => editProduct(item)}>
            <div onClick={() => editProduct(item)}>
              <span>
                {" "}
                {item.name}{" "}
                {isArchived ? (
                  <>
                    <br />
                    <span className={styles.archivedText}> Archived </span>{" "}
                  </>
                ) : null}{" "}
              </span>{" "}
              <br />
            </div>{" "}
          </div>{" "}
        </div>{" "}
        {/* <div className={styles.col}>
                                                                      <div className={styles.taxTag}>Tax Type</div>
                                                                      <div className={cn(styles.tax)}>
                                                                        {item.taxType}
                                                                      </div>
                                                                    </div> */}{" "}
        <div className={styles.col}>
          <div className={styles.taxTag}> Rate </div>{" "}
          <div
            className={cn(styles.tax)}
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <div style={{ width: 40 }}>
              {" "}
              {`${item.percentage ? item.percentage : 0}%`}{" "}
            </div>{" "}
            {/* <span className={item.inclusive ? styles.included : styles.excluded}>
                                                                          {`${item.inclusive ? 'Inclusive' : 'Exclusive'}`}</span> */}{" "}
          </div>{" "}
        </div>{" "}
        <div className={styles.col}>
          <span className={styles.taxTag}> Status </span>{" "}
          <div
            className={item.active ? styles.activeText : styles.archivedText}
          >
            {" "}
            {item.active ? "Active" : "Archived"}{" "}
          </div>{" "}
        </div>{" "}
        <div className={styles.col}>
          <span className={styles.taxTag}> Included </span>{" "}
          <div
          //className={item.active ? styles.activeText : styles.archivedText}
          >
            {" "}
            {item.inclusive ? "Yes" : "No"}{" "}
          </div>{" "}
        </div>{" "}
        <div className={styles.col}>
          <span className={styles.taxTag}> Valid </span>{" "}
          <div className={styles.tax}> {dateFormatter(item.createdOn)} </div>{" "}
        </div>{" "}
        {!settings && !hideExtras && item.active && (
          <div className={styles.col}>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
              <div
                className={cn(styles.dotsBtn, className, {
                  [styles.active]: visible,
                })}
              >
                <div className={styles.head}>
                  <button
                    className={cn(styles.btn)}
                    onClick={() => setVisible(!visible)}
                  >
                    <Icon name={item.icon} />{" "}
                  </button>{" "}
                </div>{" "}
                <div className={styles.actionBody}>
                  {" "}
                  {!item.active ? (
                    // <button
                    //   className={styles.actionItem}
                    //   onClick={() => {
                    //     setId(item);
                    //     setVisibleModal(true);
                    //   }}
                    // >
                    //   <Icon name='inbox' size='24' />
                    //   Details{" "}
                    // </button>
                    <></>
                  ) : (
                    <>
                      {/* <button
                        className={styles.actionItem}
                        onClick={() => {
                          setId(item);
                          setVisibleModal(true);
                        }}
                      >
                        <Icon name='edit' size='24' />
                        Edit{" "}
                      </button>
                      {" "} */}
                      {deleteAction && deleteAction}{" "}
                    </>
                  )}{" "}
                </div>{" "}
              </div>{" "}
            </OutsideClickHandler>{" "}
          </div>
        )}
      </div>{" "}
    </>
  );
};

export default Row;
