import React, { createRef, useEffect, useRef } from "react";
import styles from "./EmbededForm.module.sass";
import $ from "jquery";
import Icon from "../../../components/Icon";
import { directQuestionnaireGetDetail, directQuestionnaireSubmit, embededFormGetDetail, sendEmbededForm } from "../../../utils/apiCallHanlder";
import { toast, ToastContainer } from "react-toastify";
import { toastConfiguration } from "../../../utils/utils";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import cn from "classnames";
import Spinner from "../../../utils/spinner";
import Modal from "../../../components/Modal";
import Card from "../../../components/Card";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");
require('formBuilder/dist/form-render.min.js')
let formRender = null;

const QuestionnaireDisplay = () => {

  const history = useHistory();

  const [contactQuestionnaire, setContactQuestionnaire] = useState(null)
  const [dataForm, setDataForm] = useState(null)
  const [contactSignatures, setContactSignatures] = useState([])
  const [showSignHere, setShowSignHere] = useState(false)
  const [signature, setSignature] = useState("")
  const [loading, setLoading] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)
  const [showText, setShowText] = useState(false)
  const [text, setText] = useState("")
  const [showPopup, setShowPopup] = useState(false);
  const [buttonClicked, setButtonClicked] = useState("");
  const fb = useRef();

  const { id } = useParams();
  const tokenId = id;

  useEffect(async () => {
    if (tokenId) {
      const { code, data, error, message } = await embededFormGetDetail(tokenId, {});
      if (code === 200 && data) {
        setContactQuestionnaire(data?.form);
        let _dataForm = data?.form?.data;
        if (_dataForm)
          _dataForm = JSON.parse(_dataForm);

        let options2 = {
          formData: _dataForm,
          dataType: 'json'
        }
        $(fb.current).formRender(options2)
        setDataForm(_dataForm);
      }
      else {
        toast.error(error, toastConfiguration)
        alert(error);
        history.push('/');
      }

    }
    else {
      alert('Embeded Form  token not found');
      toast.error('Embeded Form token not found', toastConfiguration)
      history.push('/');
    }
    setLoading(false);
  }, [])

  const onSubmitHanlder = async () => {
    var response = window.JSON.stringify($(fb.current).formRender("userData"));

    const formValues = JSON.parse(response);

    for (let i = 0; i < formValues?.length; i++) {
      if (formValues[i]?.label === "Email") {
        if (!/\S+@\S+\.\S+/.test(formValues[i]?.userData[0])) {
          setShowPopup(false);
          toast.error("please provide valid email address", toastConfiguration)
          return;
        }
      }
    }

    setSaveLoading(true);

    const { data, error } = await sendEmbededForm(tokenId, {
      response
    });
    if (data) {
      setShowText(true);
      setText(" for reaching out! We will get back to you as soon as possible.");
      toast.success("Your answer has been submit.", toastConfiguration);
      setShowPopup(false);
      window.scrollTo(0, 0);
    } else {
      toast.error(error || "Error in sending answer", toastConfiguration);
      setShowPopup(false);
    }
    setSaveLoading(false);
  };

  return (
    <>
      <ToastContainer></ToastContainer>

      <Modal
        visible={showPopup}
        outerClassName={styles.outerModal}
        onClose={() => setShowPopup(false)}
        children={
          <div style={{
            marginTop: 64
          }}>
            <div style={{
              backgroundColor: 'rgb(210 249 210)',
              padding: 12,
              borderRadius: 8,
            }}>
              <span style={{
                color: 'green'
              }}>Are you sure that you want to submit this form!</span>
            </div>
            <div style={{
              marginTop: 24,
              display: 'flex',
              justifyContent: 'center'
            }}>
              {/* <button
                className={cn("button-stroke", styles.addBtn)}
                onClick={() => {
                  setButtonClicked('dismiss');
                  onSubmitHanlder(false)
                }}
                style={{ width: '49%' }}
              >
                {saveLoading && buttonClicked === "dismiss" ?
                  <Spinner size="24" color="gray" />
                  :
                  <>
                    Dismiss
                  </>
                }
              </button> */}
              <button
                className={cn("button-stroke", styles.addBtn)}
                onClick={async () => {
                  setButtonClicked('complete');
                  await onSubmitHanlder();
                }}
                style={{ width: '49%' }}
              >
                {saveLoading && buttonClicked === "complete" ?
                  <Spinner size="24" color="gray" />
                  :
                  <>
                    Submit
                  </>
                }
              </button>
            </div>
          </div>
        }
      />

      {loading ?
        <div style={{
          position: 'absolute',
          left: 'calc(50% - 36px)',
          top: 'calc(50% - 36px'
        }}>
          <Spinner size={64} color="gray" />
        </div>
        :
        showText &&
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '10%'
        }}>
          <div style={{
            width: "max-content",
            backgroundColor: 'rgb(210 249 210)',
            display: 'flex',
            alignItems: 'center',
            minHeight: 100,
            paddingLeft: 36,
            paddingRight: 36,
            borderRadius: 8,
            wordBreak: 'break-all'
          }}>
            <span style={{ color: 'green' }}>
              <b>&#x2713; Thankyou</b>{text}
            </span>

          </div>
        </div>
      }
      <div style={{
        visibility: showText || loading ? 'hidden' : 'visible',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 36,
        width: "100%"
      }}>
        <Card
          className={styles.card}
          title={"Embeded Form"}
          /* classTitle={cn("title-blue", styles.title)} */
        >
          <form onSubmit={(e) => {
            e.preventDefault();
            setShowPopup(true)
          }}>
            <div className={styles.customerHtmlQuestionerWrap}>
              <div id="fbEditor" ref={fb} />
            </div>
          </form>
        </Card>
        {/* <div className={styles.customerHtmlQuestionerWrap}>
          <button className={cn("button", styles.signedBtn)}
            onClick={() => setShowPopup(true)}
            style={{ width: 130 }}
          >
            <Icon name="edit" size="24" />
            Submit
          </button>
        </div> */}
      </div>

    </>
  );
};

export default QuestionnaireDisplay;
