import React from "react";
import cn from "classnames";
import styles from "./IdentityVerify.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Image from "../../components/Image";
import withUserAuth from "../../utils/withUserAuth";

const IdentityVerify = () => {
  const heightWindow = use100vh();

  return (
    <div className={styles.row}>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        <div className={styles.head}>
          <Link className={styles.logo} to="/identity-verify">
            <Image
              className={styles.pic}
              src="/images/logo-dark.png"
              srcDark="/images/logo-light.png"
              alt="Core"
            />
          </Link>
          <div className={styles.info}>
            <Link className={styles.link} to="/">
              Log out
            </Link>
          </div>
        </div>
        <div className={styles.wrap}>
          <div className={cn("h2", styles.title)}>Verify your identity</div>
          <div className={styles.txt}> We’ll ask a few questions to verify your identity for bankin.g services</div>
          <button className={cn("button", styles.button)}>
            <Link className={styles.link} to="/"> Verify your Identity </Link>
          </button>
          <Link className={styles.skipLink} to="/"> Skip this for now </Link>
        </div>
      </div>
    </div>
  );
};

export default withUserAuth(IdentityVerify);
