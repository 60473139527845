import Geocode from "react-geocode";
let typingTimeout;
export const FetchAddress = (
  address,
  name,
  setCity,
  setCountry,
  setZip,
  setState,
  setShippingCity,
  setShippingCountry,
  setShippingState,
  setShippingZip
) => {
  Geocode.setApiKey("AIzaSyCTpbuCUVv_C2RRr09oRimIlrjGzcP9C-0");

  typingTimeout = setTimeout(() => {
    if (address.length >= 4) {
      Geocode.fromAddress(address).then(
        async (response) => {
          const result = response.results[0];
          const addressComponents = result.address_components;

          let city = "";
          let state0 = "";
          let zipcode = "";
          let country = "";
          for (let i = 0; i < addressComponents.length; i++) {
            const component = addressComponents[i];

            if (component.types.includes("locality")) {
              city = component.long_name;
            }

            if (component.types.includes("administrative_area_level_1")) {
              state0 = component.short_name;
            }

            if (component.types.includes("country")) {
              country = component.short_name;
            }

            if (
              component.types.includes("postal_code") ||
              component.types.includes("plus_code")
            ) {
              zipcode = component.long_name;
            }
          }
          if (!state0 || !zipcode || !city) {
            const { lat, lng } = response.results[0].geometry.location;

            await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCTpbuCUVv_C2RRr09oRimIlrjGzcP9C-0`
            )
              .then((response) => response.json())
              .then(async (data) => {
                if (!state0) {
                  state0 = await data.results[0].address_components.find(
                    (component) =>
                      component.types.includes("administrative_area_level_1")
                  )?.long_name;
                }

                if (!city) {
                  city = await data.results[0].address_components.find(
                    (component) => component.types.includes("locality")
                  )?.long_name;
                }

                if (!zipcode) {
                  zipcode = data.results[0].address_components.find(
                    (component) =>
                      component.types.includes("postal_code") ||
                      component.types.includes("plus_code")
                  )?.long_name;
                }

                if (name === "address") {
                  setShippingCity(city);
                  setShippingCountry(country);
                  setShippingState(state0);
                  setShippingZip(zipcode);
                } else if (name === "addressLine1") {
                  setCity(city);
                  setCountry(country);
                  setState(state0);
                  setZip(zipcode);
                } else if (name === "cardaddress") {
                  setCity(city);
                  setCountry(country);
                  setState(state0);
                  setZip(zipcode);
                }
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            // setStreetNumber(streetNumber);
            // setStreetName(streetName);
            if (name === "address") {
              setShippingCity(city);
              setShippingCountry(country);
              setShippingState(state0);
              setShippingZip(zipcode);
            } else if (name === "addressLine1") {
              setCity(city);
              setCountry(country);
              setState(state0);
              setZip(zipcode);
            } else if (name === "cardaddress") {
              setCity(city);
              setCountry(country);
              setState(state0);
              setZip(zipcode);
            }
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, 200);
};
