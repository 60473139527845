import React, { useState } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import Spinner from "../../../utils/spinner";
import { useHistory } from "react-router";

const Entry = ({ onConfirm, error, setError, loading }) => {
  const [message, setMessage] = useState("");
  const [messageConfirm, setMessageConfirm] = useState("");

  const history = useHistory();

  const handleChange = (event) => {
    setError("");
    setMessage(event.target.value);
  };

  return (
    <div className={styles.entry}>
      <div className={styles.body}>
        <div className={styles.emailWrapper}>
          <TextInput
            className={styles.field}
            name="email"
            type="password"
            placeholder="Password"
            required
            value={message}
            onChange={handleChange}
          />
        </div>
        <div className={styles.emailWrapper}>
          <TextInput
            className={styles.field}
            name="email"
            type="password"
            placeholder="Confirm Password"
            required
            value={messageConfirm}
            onChange={(e) => {
              setError("");
              setMessageConfirm(e.target.value)
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <button
            className={cn(
              "button"
            )}
            style={{ width: '100%' }}
            onClick={() => {
              setError("");
              onConfirm(message, messageConfirm);
            }}
          >
            {loading ?
              <Spinner loading={loading} size="18" color={"white"} />
              :
              "Update"
            }
          </button>
          <button
            className={cn(
              "button"
            )}
            style={{ width: '100%', marginTop: 10 }}
            onClick={() => history.push("/")}
          >
            Login
          </button>
        </div>
        {
          error ?
            <div className={styles.errorContainer}>
              <span className={styles.errorMessage}>
                {error}
              </span>
            </div>
            : null
        }
      </div>
    </div>
  );
};

export default Entry;
