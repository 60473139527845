import cn from "classnames";
import styles from "./ForgotPassword.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Entry from "./Entry";
import Modal from "../../components/Modal";
import Icon from "../../components/Icon";
import Image from "../../components/Image";
import { resetPassword } from "../../utils/apiCallHanlder";
import { getResetPasswrodReturnUrl, logoutHandler } from "../../utils/utils";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const ForgotPassword = () => {
  const heightWindow = use100vh();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const history = useHistory();

  const confirmResetPassword = async (email) => {
    setLoading(true);
    const { data, error } = await resetPassword({
      email,
      returnUrl: getResetPasswrodReturnUrl()
    })
    if (data) {
      logoutHandler(null);
      setSuccessMessage("Email has been sent to the provided email address.")
    } else {
      setError(error);
    }
    setLoading(false);
  }

  return (
    <div className={styles.row}>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        <div className={styles.head}>
          <Link className={styles.logo} to={{ pathname: "https://greatweek.com" }} target="_blank">
            <Image
              className={styles.pic}
              src="/images/logo-dark.png"
              srcDark="/images/logo-light.png"
              alt="Core"
            />
          </Link>
          <div className={styles.info}>
            <Link className={styles.link} to="/">
              Log In
            </Link>
          </div>
        </div>
        <div className={styles.wrap} style={{ alignItems: !successMessage ? 'unset' : 'center' }}>
          {successMessage ?
            <>
              <Modal
                visible={successMessage}
                onClose={() => setSuccessMessage(false)}
                outerClassName={styles.outer}
              >
                <div className={styles.wrap}>
                  <div className={styles.imgWrap}>
                    <Image
                      className={styles.pic}
                      src="/images/content/send-pic.png"
                      srcDark="/images/content/send-pic.png"
                      alt="delete"
                    />
                  </div>
                  {/* <div className={styles.title}>Success!</div> */}
                  {/* <div className={styles.invName}>Reset Password</div> */}
                  {/* <div className={styles.email}>
                    Link has been sent to your <span>Email</span>
                  </div> */}
                  <div className={styles.email}>
                    We’ve sent an email to your account, with link to reset the password.
                  </div>
                  <div >
                    <button className={cn("button", styles.backLoginBtn)} onClick={() => history.push("/")}>
                      Back to Login
                    </button>
                    {/* <button onClick={() => setSuccessMessage(false)} className="button" style={{ marginLeft: 20 }}>
                      Resend Email
                    </button> */}
                  </div>
                </div>
              </Modal>
            </>
            :
            <>
              <div className={cn("h2", styles.title)} style={{ marginLeft: -2 }}>Reset Password</div>
              <Entry onConfirm={confirmResetPassword} error={error} setError={setError} loading={loading} />
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
