import React from 'react';

import styles from './LoadPaidOut.module.sass';
import offerStyles from '../OfferRecieved/OfferRecieved.module.sass'
import { formatDateCapital } from '../../../../utils/utils';

const paymentPlans = [
    {
        name: 'Day 1',
        graph1Height: 2,
        graph2Height: 130,
        amount: 120
    },
    {
        name: 'Day 2',
        graph1Height: 2.5,
        graph2Height: 170,
        amount: 186
    },
    {
        name: 'Day 3',
        graph1Height: 1.5,
        graph2Height: 90,
        amount: 95
    },
    {
        name: 'Day 3',
        graph1Height: 1.5,
        graph2Height: 90,
        amount: 95
    },
    {
        name: 'Day 3',
        graph1Height: 2.5,
        graph2Height: 120,
        amount: 120
    },
    {
        name: 'Day 5',
        graph1Height: 2.5,
        graph2Height: 120,
        amount: 120
    }
]

const LoadPaidOut = ({ applicationData }) => {
    return <div className={styles.contentContainer}>
        <span className={styles.disclaimer}>
            {`Your balance is $${applicationData?.loanPaidDetail?.balance} and is due 
            ${formatDateCapital(new Date(applicationData?.loanPaidDetail?.dueDate))}.
            A daily repayment fee is deducted from your daily sales.`}
        </span>
        <div className={offerStyles.offerSelectionContainer} style={{ marginTop: 24 }}>
            <div
                className={offerStyles.selectedOffer}
            >
                <span>${applicationData?.loanPaidDetail?.offerDetail?.amount}</span>
                <span>${applicationData?.loanPaidDetail?.offerDetail?.fee} loan fee</span>
                <span>{applicationData?.loanPaidDetail?.offerDetail?.repaymentRate}% repayment rate</span>
            </div>
        </div>
        <div className={offerStyles.paymentPlanContainer}>
            {paymentPlans?.map((item, index) => {
                const selected = { repaymentRate: applicationData?.loanPaidDetail?.offerDetail?.repaymentRate }
                return <div className={offerStyles.paymentPlan} key={index}>
                    <span className={offerStyles.daySale}>{index === 4 ? 'No payment' : `$${item.amount}`}</span>

                    {index !== 4 && (
                        <div>
                            <div className={offerStyles.graph1} style={{ height: item.graph1Height * selected?.repaymentRate }}></div>
                            <div className={offerStyles.graph2} style={{ height: item.graph2Height }}></div>
                        </div>
                    )}

                    <div className={offerStyles.flexColumn}>
                        <span className={offerStyles.day}>Day {index + 1}</span>
                    </div>
                </div>
            })}
        </div>
    </div>
}

export default LoadPaidOut;