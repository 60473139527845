import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Card from "../../../../../components/Card";
import Item from '../../../../Home/Overview/Item';
import cn from 'classnames';
import styles from './ProofAndFunds.module.sass';
import Carousel from "react-multi-carousel";
import Chart from "../../../../Home/Overview/Chart";

const ProofAndFunds = ({ accounts, transactions, filteredAccounts, selectedAccounts, setSelectedAccounts }) => {
    const targetRef = useRef();
    const [carouselWidth, setCarouselWidth] = useState(null);
    const [graph, setGraph] = useState(null);

    useLayoutEffect(() => {
        var carouselWrapper = document.getElementById("carouselWrapper");
        setCarouselWidth(carouselWrapper.offsetWidth);
    });

    useEffect(() => {
        var carouselWrapper = document.getElementById("carouselWrapper");
        const handleResize = () => {
            setCarouselWidth(carouselWrapper.offsetWidth);
        };
        if (carouselWrapper) {
            window.addEventListener("resize", handleResize);
        }
    });

    const getGraphDateFormating = (dt) => {
        let monthPart = dt.toLocaleDateString({}, { month: "short" });
        let dayPart = dt.toLocaleDateString({}, { day: "2-digit" });
        let resStr = dayPart + "-" + monthPart;
        return resStr;
    };

    useEffect(() => {
        let isTakeAllTransactions = selectedAccounts?.includes("All");

        var balanceAtTheMoment = 0;
        for (let index = 0; index < accounts.length; ++index) {
            const accountInfo = accounts[index];
            if (selectedAccounts.includes(accountInfo.id)) {
                balanceAtTheMoment += accountInfo.displayBalance;
            }
        }

        // calculate pending Stripebalance transactions as already on balance
        for (let index = 0; index < transactions.length; index++) {
            const transaction = transactions[index];
            if (
                transaction.accountId === "stripebalance" &&
                transaction.status === "pending"
            ) {
                balanceAtTheMoment += transaction.net;
            }
        }

        var newGraph = [];
        let lastItem = {
            name: getGraphDateFormating(new Date()),
            balance: balanceAtTheMoment / 100,
        };

        var lastDate = "";
        var prevGraphItem = null;
        for (let index = 0; index < transactions.length; index++) {
            const transaction = transactions[index];
            if (!isTakeAllTransactions) {
                let isAccountIdSelected = selectedAccounts.includes(
                    transaction.accountId
                );
                let isCardTransaction = transaction.transactionOriginType === "card";
                if (
                    !isAccountIdSelected &&
                    !(
                        isCardTransaction &&
                        selectedAccounts.includes(transaction.cardFinancialAccount)
                    )
                ) {
                    continue;
                }
            }
            let fee = transaction.fee ?? 0;
            let transDate = new Date(transaction.created);
            transDate.setDate(transDate.getDate() - 1);
            let transDateFormated = getGraphDateFormating(transDate);

            if (transDateFormated === lastDate) {
                if (transaction.amountValue > 0) {
                    prevGraphItem.balance =
                        prevGraphItem.balance - transaction.amountValue + fee;
                } else {
                    prevGraphItem.balance += transaction.amountValue * -1;
                }
                balanceAtTheMoment = prevGraphItem.balance;
                continue;
            }
            lastDate = transDateFormated;
            var newGraphItem = {
                name: transDateFormated,
                balance:
                    transaction.amountValue < 0
                        ? balanceAtTheMoment + transaction.amountValue * -1
                        : balanceAtTheMoment - transaction.amountValue + fee,
            };
            balanceAtTheMoment = newGraphItem.balance;
            newGraph.push(newGraphItem);
            prevGraphItem = newGraphItem;
        }
        newGraph.reverse();
        for (let index = 0; index < newGraph.length; index++) {
            const g = newGraph[index];
            if (g.balance != 0) g.balance = g.balance / 100;
        }

        newGraph?.push(lastItem);
        setGraph(newGraph);
    }, [selectedAccounts, transactions]);

    const responsive = {
        sizeOne: {
            breakpoint: { max: 8000, min: 0 },
            items: carouselWidth / 208,
        },
    };

    return <Card
        title={"Proof of funds"}
        className={styles.card}
        id="printAbleDiv"
    >
        <div
            id='carouselWrapper'
            ref={targetRef}
            style={{ marginBottom: "24px" }}
        >
            <Carousel
                responsive={responsive}
                autoPlay={false}
                showDots={false}
                arrows={false}
                containerClass={styles.carouselContainer}
                partialVisible={true}
            >
                {accounts?.map((x, index) => {
                    return filteredAccounts?.includes(x?.id) && (
                        <Item
                            disableClick={true}
                            className={cn(
                                styles.item,
                                {
                                    [styles.active]: selectedAccounts.includes(x.id),
                                },
                                {
                                    [styles["activeItem" + (index > 7 ? 8 : index)]]:
                                        selectedAccounts.includes(x.id),
                                }
                            )}
                            style={{
                                border: selectedAccounts?.includes(x.id) ? 'unset' : '2px solid #6F767E',
                            }}
                            onActive={() => {
                                if (selectedAccounts?.includes(x?.id)) {
                                    const filteredAccounts = selectedAccounts?.filter(item => item !== x?.id);
                                    setSelectedAccounts([...filteredAccounts]);
                                } else {
                                    setSelectedAccounts([...selectedAccounts, x?.id]);
                                }
                            }}
                            key={index}
                            item={{
                                title: x.name,
                                accountDigit: x?.accountNumberLast4,
                                counter: x.displayBalance ?? "0",
                            }}
                            currency={"USD"}
                        />
                    );
                })}
            </Carousel>
        </div>
        <Chart data={graph} />
    </Card>
}

export default ProofAndFunds;