import React, { useEffect, useState } from "react";
import cn from "classnames";
import Icon from "../../../../../../components/Icon";
import TextInput from "../../../../../../components/TextInput";
import Dropdown from "../../../../../../components/Dropdown_c";
import styles from "./DetailsCustomer.module.sass";
import {
  removeLineFromInvoice,
  updateLineOfInvoice,
} from "../../../../../../utils/apiCallHanlder";
import Spinner from "../../../../../../utils/spinner";
import {
  formatStripeAmount,
  toastConfiguration,
} from "../../../../../../utils/utils";
import Amount from "../../../../../../components/Amount";
import { toast } from "react-toastify";

const salesTaxes = [
  { display: "Include", text: "Include", value: "inclusive" },
  { display: "Exclude", text: "Exclude", value: "exclusive" },
];

let typingTimer;
let doneTypingInterval = 600;

const Lines = ({
  x,
  index,
  invoiceId,
  setInvoiceObject,
  updateInvoicee,
  customerId,
  productSelectHandler,
  setShowActionBtn,
  setUpdateInvoice,
  setShowProduct,
  setLines,
  currencySymbol,
}) => {
  const [qty, setQty] = useState(x.quantity);
  const [salesTax, setSalesTax] = useState(
    x.taxBehavior === "unspecified" ? null : x.taxBehavior
  );
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateQuantityLoading, setUpdateQuantityLoading] = useState(false);
  const [updateTaxLoading, setUpdateTaxLoading] = useState(false);
  const [isAmended, setIsAmended] = useState(true);
  const [updateQunaityTo1, setUpdateQuantityTo1] = useState(null);

  useEffect(() => {
    if (qty === 1 && updateQunaityTo1 !== null) {
      updateQuantity();
    }
  }, [updateQunaityTo1]);

  useEffect(() => {
    setQty(x.quantity);
  }, [x.quantity]);

  useEffect(() => {
    setSalesTax(x.taxBehavior);
  }, [x.taxBehavior]);

  const updateQuantity = async (event) => {
    if (qty !== "0" && qty !== "") {
      setUpdateQuantityLoading(true);
      const updateLinePayload = {
        customerId: customerId,
        invoiceId: invoiceId,
        lineId: x.lineId,
        productId: x.productId,
        taxBehavior: salesTax,
        priceValue: x.priceValue,
        quantity: parseInt(qty || "0"),
        tax: x.tax,
        description: x.description,
      };

      const { data, error } = await updateLineOfInvoice(updateLinePayload);
      if (data) {
        setInvoiceObject(data?.invoice);
        //setUpdateInvoice(!updateInvoicee);
      } else {
        if (error && error.startsWith("Amount must be no more")) {
          setQty(1);
          x.quantity = 1;
          setUpdateQuantityTo1(!updateQunaityTo1);
        }

        toast.error(
          error || "Error in updating product price",
          toastConfiguration
        );
      }
      setUpdateQuantityLoading(false);
    }
  };

  const updateSalesTax = async (value) => {
    setUpdateTaxLoading(true);
    const updateLinePayload = {
      customerId: customerId,
      invoiceId: invoiceId,
      lineId: x.lineId,
      productId: x.productId,
      taxBehavior: value,
      priceValue: x.priceValue,
      quantity: parseInt(qty || "0"),
      tax: x.tax,
      description: x.description,
    };
    try {
      const { data, error } = await updateLineOfInvoice(updateLinePayload);
      //   setValue('')
      if (data) {
        setInvoiceObject(data?.invoice);
        toast.success("updated", toastConfiguration);
      } else {
        setSalesTax("exclusive");
        toast.error(error, toastConfiguration);
      }

      setUpdateTaxLoading(false);
    } catch (error) {
      setUpdateTaxLoading(false);
    }
  };

  const deleteButtonHandler = async (e) => {
    e.stopPropagation();
    setDeleteLoading(true);
    const deletePayload = {
      customerId: customerId,
      invoiceId: invoiceId,
      lineId: x.lineId,
    };
    const { data } = await removeLineFromInvoice(deletePayload);
    if (data) {
      setLines(data?.invoice?.lines);
      setShowProduct(false);
      setInvoiceObject(data?.invoice);
      //setUpdateInvoice(!updateInvoicee);
    }
    setDeleteLoading(false);
  };

  return (
    <div key={index} className={styles.productWrap}>
      {deleteLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 50,
            marginTop: 20,
          }}
        >
          <Spinner loading={deleteLoading} size={"40"} color={"blue"} />
        </div>
      ) : (
        <>
          {isAmended ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setIsAmended(false)}
            >
              <div className={styles.name} style={{ width: "70%" }}>
                <p>{x.name}</p>
                <p style={{ display: "flex", alignItems: "center" }}>
                  {currencySymbol}
                  <Amount
                    amount={formatStripeAmount(
                      x.amount,
                      updateInvoicee?.currencySymbol
                    )}
                    subtitleEmValue={"0.8em"}
                  />
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 10,
                  marginRight: 10,
                  width: "130px",
                }}
              >
                <div style={{ width: 98 }}>
                  <TextInput
                    className={styles.field}
                    name='description'
                    value={qty}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(event) => {
                      event.stopPropagation();
                      setQty(event.target.value);
                    }}
                    type='text'
                    onKeyUp={() => {
                      clearTimeout(typingTimer);
                      typingTimer = setTimeout(
                        updateQuantity,
                        doneTypingInterval
                      );
                    }}
                    onKeyDown={() => {
                      clearTimeout(typingTimer);
                    }}
                    required
                    currency={
                      updateQuantityLoading ? (
                        <Spinner size='12' color='gray' />
                      ) : (
                        "x"
                      )
                    }
                    currencyBoxWidth={29}
                  />
                </div>
                <div style={{ marginLeft: 10 }}>
                  <button onClick={deleteButtonHandler}>
                    <Icon name='trash' size='20' fill='#FF4900' />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                className={styles.head}
                onClick={() => setIsAmended(true)}
                style={{
                  cursor: "pointer",
                }}
              >
                <div className={cn(styles.left, styles.name)}>{x.name}</div>
                <div className={styles.right}>
                  <button
                    onClick={deleteButtonHandler}
                    className={styles.deleteBtn}
                  >
                    <Icon name='trash' size='20' fill='#FF4900' />
                  </button>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.left}>
                  <div style={{ display: "flex" }}>
                    <span className={styles.title}>Price</span>
                    {currencySymbol}
                    <Amount
                      amount={formatStripeAmount(
                        x.priceValue,
                        updateInvoicee?.currencySymbol
                      )}
                      subtitleEmValue={"0.8em"}
                    />
                  </div>
                  <div className={styles.qtyWrap}>
                    <span className={styles.title}>Qty</span>
                    <TextInput
                      className={styles.field}
                      name='description'
                      value={qty}
                      updateQuantityLoading={updateQuantityLoading}
                      onChange={(event) => {
                        setQty(event.target.value);
                      }}
                      placeholder='Quantity'
                      type='number'
                      onKeyUp={() => {
                        clearTimeout(typingTimer);
                        typingTimer = setTimeout(
                          updateQuantity,
                          doneTypingInterval
                        );
                      }}
                      onKeyDown={() => {
                        clearTimeout(typingTimer);
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className={styles.row} style={{ justifyContent: "normal" }}>
                <span className={styles.title}>Total</span>
                {currencySymbol}
                <Amount
                  amount={formatStripeAmount(
                    x.amount,
                    updateInvoicee?.currencySymbol
                  )}
                  subtitleEmValue={"0.8em"}
                />
              </div>
              <div className={styles.row}>
                <div className={styles.left}>
                  <span className={styles.title}>Tax category</span>
                  {x.taxCodeName}
                </div>
                <div className={styles.right}>
                  <span className={styles.title}>Tax</span>
                  {x.tax}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.left}>
                  <div className={styles.salesWrap}>
                    <span className={styles.title}>Sales tax</span>
                    <Dropdown
                      className={styles.dropdown}
                      classDropdownHead={styles.dropdownHead}
                      onChange={updateSalesTax}
                      value={salesTax}
                      updateTaxLoading={updateTaxLoading}
                      setValue={setSalesTax}
                      options={salesTaxes}
                      small
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Lines;
