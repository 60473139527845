import React, { useState } from "react";
import cn from "classnames";
import styles from "./takeoff.module.sass";
import Icon from "../../../components/Icon";
import Image from "../../../components/Image";
import { Link, Router, useHistory } from "react-router-dom";
import { completeSignUpV2, signupUser } from "../../../utils/apiCallHanlder";
import { localStorageItem, toastConfiguration } from "../../../utils/utils";
import { handleLoginSignUpResponse } from "../../../utils/utils";
import Spinner from "../../../utils/spinner";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
const Takeoff = ({
  className,
  data,
  payloadData,
  errorMessage,
  setErrorMessage,
  email,
  password,
  setError,
  error,
  children,
  onBoardDetails,
  // toast,
  // toastConfiguration

}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const continueButtonClickHandler = async () => {

    const fbclid = localStorage.getItem("fbclid") || "";
    const gclid = localStorage.getItem("gclid") || "";

    if (!payloadData?.country) {
      setErrorMessage("Please Choose Country");
    } else {
      setLoading(true);
      const signupResponse = await completeSignUpV2(
        {
          email: payloadData.email,
          meet: payloadData.meet,
          moreInfo: payloadData.moreInfo,
          istestmode: payloadData.istestmode,
          country: payloadData.country,
          returnURL: payloadData.returnURL,
          signUpToken: payloadData.signUpToken,
          fbclid,
          gclid,
        }
      );
      const { data, code, error } = signupResponse;

      setLoading(false);
      if (code === 200 && data) {

        localStorage.removeItem("fbclid");
        localStorage.removeItem("gclid");

        localStorage.setItem(
          "connectStatus",
          JSON.stringify(data?.connectStatus)
        );
        localStorage.setItem("stripeConnection", data.isConnected);
        localStorage.setItem("isPending", data.isPending);
        localStorage.setItem("isTestMode", data.istestmode);
        localStorage.setItem("currencySymbol", data.currencySymbol || "$");
        localStorage.setItem("currency", data.currency || "USD");
        localStorage.setItem("isAccountingEnabled", false);
        if (payloadData.moreInfo == "no") {
          data["redirectURL"] = null;
        }
        handleLoginSignUpResponse(data, history);
      } else {
        toast && toast.error(error, toastConfiguration);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div
        className={cn(styles.takeoff, className, { [styles.active]: visible })}
      >
        <button
          className={cn("button-small", styles.head)}
          onClick={continueButtonClickHandler}
        >
          {children || (
            <>

              Continue
            </>
          )}

          {loading ? (<div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
            <Spinner color="#0063F3" loading={true} size={30} />
          </div>) : null}
        </button>
      </div>

      {errorMessage !== "" ? (
        <div className={styles.errorContainer}>
          <span className={styles.errorMessage}>{errorMessage}</span>
        </div>
      ) : null}

      <div className={styles.body}>
        <div className={styles.top}>
          <button
            className={cn("close", styles.close)}
            onClick={() => setVisible(false)}
          >
            <Icon name="close" size="20" />
          </button>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.imgWrapper}>
            <Image
              className={styles.pic}
              src="/images/content/takeoff.png"
              srcDark="/images/content/takeoff.png"
              alt="Core"
            />
          </div>
          <div className={styles.title}>
            9...8...7... get ready for takeoff!
          </div>
          <div className={styles.para}>
            Please check your email to verify your account
          </div>
          <Link className={styles.link} to="/">
            Sign in
          </Link>
        </div>
      </div>
      <div className={styles.overlay} onClick={() => setVisible(false)}></div>
    </>
  );
};

export default Takeoff;
