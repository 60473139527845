import React from "react";
import cn from "classnames";
import styles from "./Row.module.sass";
import Image from "../../../../components/Image";
import Checkbox from "../../../../components/Checkbox";
import { toTitleCase } from "../../../../utils/utils";

const Row = ({ cardData, onClick, singleCheck, name, onChange, activeCardCheckCount, archivedCardCheckCount, cardRights, removeCheckbox, showCardHolderName }) => {
  return (
    <div
      className={cn(
        styles.row
        /* { [styles.selected]: activeId === item.id },
        { [styles.active]: activeTable } */
      )}
    >

      <div className={styles.col}>
        {/* {!isArchived && (
          singleCheck || (
            <Checkbox name={name} className={ item.imageUrl ? styles.checkboxImg : styles.checkbox} onChange={(e) => onChange(e, item.id)} />
          )
        )}
        <div className={cn(styles.userImage, isArchived ? (archivedCheckCount < 1 ? [styles.showUserImg] : [styles.hideUserImg]) : (availableCheckCount < 1 ? [styles.showUserImg] : [styles.hideUserImg]))} >
          <img className={styles.image} src={item.imageUrl ? item.imageUrl : "/images/content/image.png"} alt="product" />
        </div> */}
        {!removeCheckbox && (
          cardRights !== 2 ?
            singleCheck || (
              <Checkbox
                name={name}
                className={styles.checkbox}
                onChange={(e) => onChange(e, cardData.id)}
              />
            ) : null
        )}

        <div style={{ backgroundColor: cardData.cardColor }} className={cn(styles.cardColor, { [styles.hide]: activeCardCheckCount > 0 || archivedCardCheckCount > 0 })}></div>
      </div>
      {/* <div className={styles.col} onClick={() => onClick()}>
        <div className={styles.mobileTag}>Nickname</div>
        <div className={styles.tag}>{cardData.name}</div>
      </div> */}
      <div className={styles.col} onClick={() => onClick()}>
        <div className={styles.mobileTag}>Ending with</div>
        <div className={styles.tag}>{cardData.cardNumberLast4}</div>
      </div>
      <div className={styles.col} onClick={() => onClick()}>
        <div className={styles.mobileTag}>
          {showCardHolderName ? "Card Holder" : "Nickname"}
        </div>
        <div className={styles.tag}>
          {showCardHolderName ? cardData.cardHolderName : cardData.name}
          </div>
      </div>
      <div className={styles.col} onClick={() => onClick()}>
        <div className={styles.mobileTag}>Type</div>
        <div className={styles.tag}>{toTitleCase(cardData.type)}</div>
      </div>
      <div className={styles.col} onClick={() => onClick()}>
        <div className={styles.mobileTag}>Status</div>
        <div className={cn(styles.tag, { [styles.active]: cardData.status === "active" }, { [styles.frozen]: cardData.status === "inactive" }, { [styles.cancelled]: cardData.status === "canceled" })}>{toTitleCase(cardData.status === "inactive" ? "Frozen" : cardData.status)}</div>
      </div>
    </div>
  );
};

export default Row;
