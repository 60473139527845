import React, { useReducer, createRef, useState } from "react";
import styles from "./NewQuestionnaireForm.module.sass";
import Modal from "../../../../components/Modal";
import uuid from "react-uuid";
import TextInput from "../../../../components/TextInput";
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import $ from "jquery";
import { useEffect } from "react";
import { addQuestionnaire } from "../../../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import Spinner from "../../../../utils/spinner";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const userInputArray = ["checkbox-group", "number", "radio-group", "select", "textarea", "text"];

const NewQuestionnaireForm = ({
  visible,
  setVisible,
  CreateQuestionnaire,
  formBuilderForm,
  setQuestionnaires,
  getQuestionnaireFunction,
}) => {
  const [addQuestionnaireLoading, setAddQuestionnairLoading] = useState(false);

  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
    questionnaireName: "",
    formBuilderData: "",
  });
  const handleChange = (e) => {
    setUserInput({ [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userInput?.questionnaireName.trim() === "") {
      toast.error("Name is required", toastConfiguration);
      window.screenTo(0, 0);
      return;
    }

    const getDataBtn = document.querySelector(".get-data");
    if (getDataBtn !== null) {
      getDataBtn.click();
    }

    const formData = document.querySelector(".formData-json").innerText;

    const getDataOverlay = document.querySelector(".form-builder-overlay");
    if (getDataOverlay !== null) {
      getDataOverlay.click();
    }

    const formValues = JSON.parse(formData);
    let hasButton = false;
    let hasSubmitType = false;
    let hasUserInputType = false;

    for (let i = 0; i < formValues?.length; i++) {
      if (formValues[i]?.type === "button") {
        if (formValues[i]?.subtype === "submit") {
          hasSubmitType = true;
        }
        hasButton = true;
      }
      if (userInputArray?.includes(formValues[i]?.type)) {
        hasUserInputType = true;
      }
    }

   
      if (!hasButton) {
        toast.error("Please add sumbit button", toastConfiguration);
        return;
      }
      if (!hasSubmitType) {
        toast.error("Atleast one button should have type submit.", toastConfiguration);
        return;
      }
    
    if (addQuestionnaireLoading) {
      return;
    }

    setAddQuestionnairLoading(true);

    const { data } = await addQuestionnaire({
      name: userInput.questionnaireName,
      data: formData,
    });

    if (data) {
      setQuestionnaires([]);
      toast.success("Successfully added questionnaire", toastConfiguration);
      await getQuestionnaireFunction(1);
    } else {
      toast.error("Error in adding questionnaire", toastConfiguration);
    }

    setUserInput({ questionnaireName: "" });
    setVisible(false);
    setAddQuestionnairLoading(false);
  };

  const fb = createRef();

  var options = {
    dataType: "json",
    disableFields: ["autocomplete", "file", "hidden", "date"],
    inputSets: [
      {
        label: "Event Questionnaires",
        fields: [
          {
            type: "header",
            subtype: "h3",
            label: "Session Questionnaire",
            className: "header",
          },
          {
            type: "paragraph",
            label:
              "Thank you for hiring me as your photographer; I'm beyond excited to get to know you and create a session you'll absolutely love. I'd love to hear a few details about what you're looking for to ensure that I create the perfect session. Please take a moment to fill out the questions below.",
          },
          {
            type: "paragraph",
            label: "If you have any questions at all, please email me at hello@yourstudioname.com",
          },
          {
            type: "checkbox",
            label: "Do you agree to the terms and conditions?",
          },
          {
            type: "header",
            subtype: "h3",
            label: "Clients Information",
            className: "header",
          },
          {
            type: "text",
            label: "First Name",
            className: "form-control",
            required: true,
          },
          {
            type: "text",
            label: "Last Name",
            className: "form-control",
            required: true,
          },
          {
            type: "text",
            label: "Email",
            className: "form-control",
            required: true,
          },
          {
            type: "text",
            label: "Phone Number",
            className: "form-control",
            required: true,
          },
          {
            type: "header",
            subtype: "h3",
            label: "Session Information",
            className: "header",
          },
          {
            type: "text",
            label: "Session Date",
            className: "form-control",
            required: true,
          },
          {
            type: "text",
            label: "Package Booked",
            className: "form-control",
          },
          {
            type: "radio-group",
            required: false,
            label: "Where will the session take place?",
            inline: false,
            access: false,
            other: false,
            values: [
              {
                label: "Option 1",
                value: "option-1",
                selected: false,
              },
              {
                label: "Option 2",
                value: "option-2",
                selected: false,
              },
              {
                label: "Option 3",
                value: "option-3",
                selected: false,
              },
            ],
          },
          {
            type: "textarea",
            required: false,
            label: "Who will be attending this session? ",
            className: "form-control",
            name: "textarea-1672312391324-0",
            access: false,
            subtype: "textarea",
          },
          {
            type: "textarea",
            required: false,
            label: "Do you have a specific location in mind for your session? ",
            className: "form-control",
            name: "textarea-1672312391324-0",
            access: false,
            subtype: "textarea",
          },
          {
            type: "textarea",
            required: false,
            label: "Are there any unique props you'd like to incorporate into your session? ",
            className: "form-control",
            name: "textarea-1672312391324-0",
            access: false,
            subtype: "textarea",
          },
          {
            type: "textarea",
            required: false,
            label:
              "Are there any photos within my portfolio you love most as far as style and location? ",
            className: "form-control",
            name: "textarea-1672312391324-0",
            access: false,
            subtype: "textarea",
          },
          {
            type: "button",
            subtype: "submit",
            label: "Save & Continue",
            className: "form-control",
          },
        ],
      },
    ],
  };

  useEffect(() => {
    $(fb.current).formBuilder(options);
  }, [visible]);

  return (
    <Modal
      visible={visible}
      outerClassName={styles.outerWrap}
      onClose={() => {
        setUserInput({ questionnaireName: "" });
        setVisible(false);
      }}
      children={
        <Card
          /* classTitle="title-blue" */
          title="Questionnaire"
          className={styles.card}
        >
          <form onSubmit={handleSubmit} noValidate>
            <TextInput
              className={styles.input}
              label="Name"
              onChange={handleChange}
              name="questionnaireName"
              id="questionnaireName"
              type="text"
              value={userInput.questionnaireName}
              required
            />
            <div className={styles.questionnaireForm}>
              <div id="fbEditor" ref={fb} />
            </div>
            <div className={styles.newQuestionnaireBtn}>
              {/*  <button
                onClick={() => {
                  setUserInput({ questionnaireName: "" });
                  setVisible(false);
                }}
                className="button-stroke"
              >
                Cancel
              </button> */}
              <button className="button">
                {addQuestionnaireLoading ? <Spinner size="24" color="white" /> : <>Save</>}
              </button>
            </div>
          </form>
        </Card>
      }
    />
  );
};

export default NewQuestionnaireForm;
