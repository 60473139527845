import { useEffect, useState } from 'react'
import {
    addDeal,
    convertDealToCustomer,
    getPipelineList,
    removeDeal,
    updateColumnCardsOrderAcrossLane,
    updateColumnCardsOrderInSameLane,
    updateDeal,
    getSingleDeal,
    getAllTeam_TodoTask
} from '../../../../utils/apiCallHanlder';
import Spinner from '../../../../utils/spinner';
import Styles from './Pipeline.module.sass'
import AdjustPipeline from './AdjustPipeline';
import TextInput from '../../../../components/TextInput';
import Card from '../../../../components/Card';
import Modal from '../../../../components/Modal';
import TeamsDropdown from "../../../../components/Dropdown_c";
import ReactPhoneInput from "react-phone-input-2";
import Dropdown from '../../../../components/Dropdown';
import { toastConfiguration } from '../../../../utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import DeleteModal from '../../../../components/DeleteModal';
import Icon from '../../../../components/Icon';
import { format } from "react-phone-input-auto-format";
import { Link } from 'react-router-dom';

import Board from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'

const defaultChooseTeams = {
    userid: 'choose',
    name: 'Choose'
}

let pipelinesData = [];
const inputStyle = {
    position: "relative",
    fontSize: "14px",
    letterSpacing: ".01rem",
    marginTop: "0px !important",
    marginBottom: " 0px !important",
    paddingLeft: "48px",
    marginLeft: "0px",
    background: "#F4F4F4",
    border: "none",
    borderRadius: "10px",
    lineHeight: "25px",
    height: "45px",
    width: "100%",
    outline: "none",
};

const CardComp = (data) => {
    return <div className={Styles.cardContainer}
        onClick={data?.onClick}
        onChange={data?.onChange}
    >
        {/* <div className={Styles.cardColumnContainer}> */}
        <div className={Styles.cardRow}>
            <div className={Styles.title}>Name</div>
            <div className={Styles.value}>{data?.title}</div>
        </div>
        <div className={Styles.cardRow}>
            <div className={Styles.title}>Email</div>
            <div
                className={data?.description ? Styles.valueHyperlink : Styles.value}
                onClick={(e) => {
                    if (data?.description) {
                        e.stopPropagation()
                        window.location.href = `mailto:${data?.description}`;
                    }
                }}
            >
                {data?.description || "-"}
            </div>
        </div>
        <div className={Styles.cardRow}>
            <div className={Styles.title}>Mobile</div>
            <div
                className={data?.mobile?.length > 4 ? Styles.valueHyperlink : Styles.value}
                onClick={(e) => {
                    if (data?.mobile?.length > 4) {
                        e.stopPropagation()
                        window.location.href = `tel:${data?.mobile}`;
                    }
                }}
            >
                {data?.mobile?.length > 4 ? format(data?.mobile || '') : '-'}
            </div>
        </div>
        <div className={Styles.cardRow}>
            <div className={Styles.title}>LTV</div>
            <div className={Styles.value}>{data?.label}</div>
        </div>
        {/* </div> */}
    </div >
}

const LaneHeader = (data) => {
    return (
        <div className={Styles.headerRow}>
            <div className={Styles.headerTitle}>{data?.title}</div>
            <div className={Styles.headerValue}>{data?.label}</div>
        </div>
    )
}

const Pipeline = ({
    setShowAddDeal,
    showAddDeal,
    setShowAdjustPipeline,
    showAdjustPipeline
}) => {
    const [loading, setLoading] = useState(true);
    const [addLoading, setAddLoading] = useState(false);
    const [stages, setStages] = useState([]);

    const [dealName, setDealName] = useState("");
    const [email, setEamil] = useState("");
    const [mobile, setMobile] = useState("");
    const [ltv, setLtv] = useState("");
    const [stage, setStage] = useState("");
    const [details, setDetails] = useState("");
    const [responsible, setResponsible] = useState('');

    const [dealId, setDealId] = useState(undefined);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [convertLoading, setConvertLoading] = useState(false);
    const [teams, setTeams] = useState([]);

    useEffect(async () => {
        await getListData();
        await getTeamMembers();
    }, [])

    const getTeamMembers = async () => {
        const { data } = await getAllTeam_TodoTask();
        if (data?.team) {
            setResponsible(defaultChooseTeams?.userid)
            setTeams([...(data?.team || [])]);
        }
    }

    const getListData = async () => {
        pipelinesData = { columns: [] }
        setLoading(true);
        const { data } = await getPipelineList({});
        if (data) {
            const calculatedData = [];
            const refinedStages = data?.columns?.map(item => item?.title);
            setStages(refinedStages);
            setStage(refinedStages[0])

            data?.columns?.map(column => {
                const columnCards = [];
                data?.list?.map(list => {
                    if (list?.pipelineColumnId === column?.pipelineColumnId) {
                        columnCards.push({
                            id: list?.pipelineDealId,
                            title: list?.name,
                            description: list?.email,
                            label: `$${list?.ltv}`,
                            hideCardDeleteIcon: true,
                            pipelineColumnType: list?.pipelineColumnType,
                            mobile: list?.phone,
                            ltv: list?.ltv,
                            onClick: async () => cardClickHandler(list?.pipelineDealId)
                        });
                    }
                })
                calculatedData.push({
                    id: column?.pipelineColumnId,
                    title: `${column?.title} ${column?.probability}%`,
                    label: `$${column?.totalPercentageLTV}`,
                    name: column?.title,
                    pipelineColumnType: column?.columnType,
                    cards: [...columnCards]
                })
            })

            pipelinesData = { columns: calculatedData };
        }
        setLoading(false);
    }

    const handleDragEnd = (board, cardDetails, source, destination) => {
        const sourceLaneId = source?.fromColumnId;
        const targetLaneId = destination?.toColumnId;
        const position = destination?.toPosition;
        const cardId = cardDetails?.id;

        if (sourceLaneId === targetLaneId) {
            handleSameLineMovement(sourceLaneId, cardId, position);
        } else {
            handleAcrossLineMovement(cardId, targetLaneId, cardDetails, position, sourceLaneId);
        }
    }

    const handleSameLineMovement = async (laneId, cardId, currentPosition) => {
        setLoading(true);
        pipelinesData?.columns?.map?.(async (lane) => {
            if (lane?.id === laneId) {
                const card = lane?.cards?.filter(item => item?.id === cardId)
                const laneCardsWithoutTargetCard = lane?.cards?.filter(item => item?.id !== cardId)
                laneCardsWithoutTargetCard?.splice(currentPosition, 0, card[0]);
                lane.cards = laneCardsWithoutTargetCard;

                const laneOrder = laneCardsWithoutTargetCard?.map(item => item?.id);
                await updateColumnCardsOrderInSameLane({ ids: laneOrder });
            }
        })
        setLoading(false)
    }

    const handleAcrossLineMovement = async (cardId, targetLaneId, cardDetails, position, sourceLaneId) => {
        setLoading(true);
        pipelinesData?.columns?.map((lane) => {
            if (lane?.id === sourceLaneId) {
                lane?.cards?.filter(item => item?.id !== cardId);
            } else if (lane?.id === targetLaneId) {
                lane?.cards?.splice(position, 0, cardDetails);
            }
        })

        await updateColumnCardsOrderAcrossLane({
            pipelineDealId: cardId,
            pipelineColumnId: targetLaneId,
            pipelineColumnType: cardDetails?.pipelineColumnType,
            newOrder: position
        })

        await getListData();
        setLoading(false);
    }

    const addDealCloseHandler = () => {
        setShowAddDeal(false);
        setDealName("");
        setEamil("");
        setMobile("");
        setLtv("");
        setDealId(undefined);
        setStage(stages[0])
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const saveButtonClickHandler = async () => {
        //validations
        if (!dealName || dealName?.trim() === "") {
            toast.error("Deal name is required", toastConfiguration);
            return;
        }

        if (!email || email?.trim() === "") {
            toast.error("Email is required", toastConfiguration);
            return;
        }

        if (email && !isValidEmail(email)) {
            toast.error("Email is invalid", toastConfiguration);
            return;
        }

        setAddLoading(true);
        let pipeline = pipelinesData?.columns?.filter(item => item?.name === stage)
        pipeline = pipeline[0] ? pipeline[0] : []

        const payload = {
            name: dealName,
            email: email,
            Phone: mobile,
            LTV: ltv,
            pipelineColumnId: pipeline?.id,
            pipelineColumnType: pipeline?.pipelineColumnType,
            AssignedTo: responsible === 'choose' ? undefined : responsible,
            details: details
        }

        const { data } = dealId ? await updateDeal({
            ...payload,
            pipelineDealId: dealId
        }) :
            await addDeal(payload);

        if (data) {
            toast.success("Deal added successfully", toastConfiguration);
            addDealCloseHandler();
            await getListData();
        } else {
            toast.error("Error in adding deal", toastConfiguration);
        }

        setAddLoading(false);
    }

    const cardClickHandler = async (cardId) => {
        const { data } = await getSingleDeal(cardId);
        debugger;

        if (data?.deal) {
            setDealId(data?.deal?.pipelineDealId);
            setDealName(data?.deal?.name);
            setEamil(data?.deal?.email);
            setMobile(data?.deal?.phone);
            setLtv(data?.deal?.ltv);
            setStage(data?.deal?.pipelineColumnName);
            setDetails(data?.deal?.details);
            setResponsible(data?.deal?.assignedTo || defaultChooseTeams?.userid);
        }

        setShowAddDeal(true);
    }

    const convertToCustomerHandler = async () => {
        setConvertLoading(true);
        const { data } = await convertDealToCustomer({
            pipelineDealId: dealId
        });
        if (data) {
            toast.success("Deal converted to customer Successfully", toastConfiguration);
            addDealCloseHandler();
            await getListData();
        } else {
            toast.error("Error in converting deal to customer", toastConfiguration);
        }
        setConvertLoading(false);
    }

    const deleteDealHandler = async () => {
        const { data } = await removeDeal(dealId, {});
        if (data) {
            toast.success("Deal deleted Successfully", toastConfiguration);
            setVisibleDelete(false);
            addDealCloseHandler();
            await getListData();
        } else {
            toast.error("Error in deleting deal", toastConfiguration);
        }
    }

    console.log(pipelinesData)

    return (
        <>
            <ToastContainer />
            <Modal
                visible={showAddDeal}
                outerClassName={Styles.modalOuter}
                onClose={addDealCloseHandler}
                hideCloseButton={true}
                children={
                    <Card
                        title={"Deal"}
                        head={
                            <div style={{ display: 'flex', gap: 12 }}>
                                {dealId ?
                                    <>
                                        <DeleteModal
                                            id={dealId}
                                            onClickHandler={deleteDealHandler}
                                            visibleDelete={visibleDelete}
                                            setVisibleDelete={setVisibleDelete}
                                            icon={
                                                <Icon
                                                    className={Styles.btnIcon}
                                                    fill='#FF4900'
                                                    name='trash'
                                                    size='20'
                                                />
                                            }
                                            className={Styles.deleteBtn}
                                        />
                                        <button className='button-stroke-red' onClick={convertToCustomerHandler}>
                                            {convertLoading ?
                                                <Spinner size="24" color="gray" />
                                                :
                                                "Convert to Customer"
                                            }
                                        </button>
                                    </>
                                    :
                                    <button className='button-stroke-red' onClick={addDealCloseHandler}>Cancel</button>
                                }
                                <button className='button' onClick={saveButtonClickHandler}>
                                    {addLoading ?
                                        <Spinner size="24" color="white" />
                                        :
                                        dealId ? "Update" : "Save"
                                    }
                                </button>
                            </div>
                        }
                        className={Styles.cardHeader}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
                            <div style={{ display: 'flex', gap: 24, marginTop: 24 }}>
                                <TextInput
                                    onChange={(event) => setDealName(event.target.value)}
                                    classInput={Styles.input}
                                    label='Name'
                                    name='name'
                                    type='text'
                                    value={dealName}
                                    required
                                />
                                <TextInput
                                    onChange={(event) => setEamil(event.target.value)}
                                    classInput={Styles.input}
                                    label='Email'
                                    name='name'
                                    type='text'
                                    value={email}
                                    required
                                />
                                <div className={Styles.field}>
                                    <div className={Styles.fieldLabel} style={{ marginBottom: 5 }}>Mobile</div>
                                    <div className={Styles.mobileField}>
                                        <ReactPhoneInput
                                            inputExtraProps={{
                                                name: "phone",
                                                required: true,
                                                autoFocus: true,
                                            }}
                                            buttonStyle={{ border: "none" }}
                                            containerStyle={{
                                                border: '2px solid #C7C5BF',
                                                marginTop: 2
                                            }}
                                            inputStyle={inputStyle}
                                            country={"us"}
                                            countryCodeEditable={false}
                                            value={mobile?.indexOf('+') > -1 ? mobile : '+' + mobile}
                                            onChange={(value) => setMobile(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: 24 }}>
                                <TextInput
                                    onChange={(event) => setLtv(event.target.value)}
                                    classInput={Styles.input}
                                    label='Deal Value'
                                    name='ltv'
                                    type='text'
                                    value={ltv}
                                    required
                                />
                                <div className={Styles.field}>
                                    <Dropdown
                                        label={"Stage"}
                                        className={Styles.dropdown}
                                        classDropdownHead={Styles.dropdownHead}
                                        value={stage}
                                        setValue={setStage}
                                        options={stages || []}
                                        width={"100%"}
                                        bodyStyles={{ height: 150, overflow: 'auto' }}
                                    />
                                </div>
                                <div className={Styles.field}>
                                    <TeamsDropdown
                                        label="Responsible"
                                        className={Styles.dropdown}
                                        classDropdownHead={Styles.dropdownHead}
                                        value={responsible}
                                        setValue={setResponsible}
                                        bodyStyles={{ width: '100%' }}
                                        options={[defaultChooseTeams, ...(teams || [])]}
                                    />
                                </div>
                            </div>
                            <div style={{ marginRight: 24 }}>
                                <TextInput
                                    onChange={(event) => setDetails(event.target.value)}
                                    classInput={Styles.input}
                                    label='Description'
                                    name='description'
                                    type='text'
                                    value={details}
                                    required
                                />
                            </div>
                        </div>
                    </Card>
                }
            />
            <>
                {loading && (
                    <div className={Styles.spinnerContainer}>
                        <Spinner size="48" color="gray" />
                    </div>
                )}
                {pipelinesData?.columns?.length > 0 && !loading && (
                    <Card className={Styles.pipelineCard}>
                        <Board
                            initialBoard={pipelinesData}
                            onCardDragEnd={handleDragEnd}
                            style={{ backgroundColor: 'transparent', height: 'max-content' }}
                            laneStyle={{ backgroundColor: 'transparent' }}
                            renderCard={CardComp}
                            renderColumnHeader={LaneHeader}
                            disableColumnDrag={true}
                        />
                    </Card>
                )}
            </>
        </>
    )
}

export default Pipeline;