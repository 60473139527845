import React from "react";
import styles from "./AccountInfo.module.sass";
import Modal from "../../../../../../components/Modal";
import EditNewCustomer from "../../../../../Customers/EditNewCustomer";
import Icon from "../../../../../../components/Icon";

const CreateNewProduct = ({ showAccountInfo, setShowAccountInfo, nameClickHandler , searchString }) => {
  return (
    <>
      <Modal
        visible={showAccountInfo}
        onClose={() => setShowAccountInfo(false)}
        outerClassName={styles.outer}
        buttonMargin={33}
        dontCloseOnClickOutside={true}
      >
        <EditNewCustomer
          isAdd={true}
          searchString={searchString}
          comingFromModal={true}
          nameClickHandler={nameClickHandler}
          className={styles.card}
        />
      </Modal>
    </>
  );
};

export default CreateNewProduct;
