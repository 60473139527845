import React from "react";
import cn from "classnames";
import styles from "./TableInfo.module.sass";
import Row from "../Row";
import DeleteModal from "../../../../../../components/DeleteModal";
import Icon from "../../../../../../components/Icon";

import {
  Checkbox,
  TriStateCheckbox,
  TriStateContext,
} from "react-tri-state-checkbox";
import "react-tri-state-checkbox/styles/checkbox.css";

const TableInfo = ({
  className,
  subscriptionsId,
  handleSubscriptions,
  handleSubscriptionsSingle,
  subscriptions,
  isSubscriptionsType,
  editAction,
  editProduct,
  setSubscriptionId,
  setVisible,
  needNewData,
  setNeedNewData,
  setActiveType,
}) => {
  return (
    <>
      <TriStateContext controls={subscriptionsId}>
        <div className={cn(styles.wrapper, className)}>
          <div className={cn(styles.table)}>
            <div className={cn(styles.row)}>
              <div className={styles.editOverlayHead}></div>
              {/* <div className={styles.col}>
                <TriStateCheckbox
                  onClick={handleSubscriptions}
                  className={styles.triHead}
                ></TriStateCheckbox>
              </div> */}
              <div className={styles.col}>Customer</div>
              <div className={styles.col}>Email</div>
              <div className={styles.col}>Product</div>
              <div className={styles.col}>Status</div>
              <div className={styles.col}>Created</div>
              <div className={styles.col}></div>
            </div>
            {subscriptions.map((x, index) => (
              <Row
                item={x}
                key={index}
                setSubscriptionId={setSubscriptionId}
                setVisibleAdd={setVisible}
                setActiveType={setActiveType}
                needNewData={needNewData}
                setNeedNewData={setNeedNewData}
                singleCheck={
                  <Checkbox
                    onClick={handleSubscriptionsSingle}
                    className={cn(styles.triCheckbox)}
                    id={x.id}
                  />
                }
                editAction={editAction && editAction}
                deleteAction={
                  <DeleteModal
                    icon={
                      <Icon
                        className={styles.btnIcon}
                        fill='#FF4900'
                        name='trash'
                        size='20'
                      />
                    }
                    className={styles.deleteBtn}
                    btnClass={cn(styles.btnClass)}
                  />
                }
                editProduct={editProduct}
              />
            ))}
          </div>
        </div>
      </TriStateContext>
    </>
  );
};

export default TableInfo;
