import React, { useEffect, useState } from "react";
import {
  searchProductTaxRates_DefaultOption,
} from "../../../../../../utils/apiCallHanlder";
import TaxEntry from "./taxEntry";
import Spinner from "../../../../../../utils/spinner";

const TaxRates = ({
  dbTaxRates,
  taxRate,
  taxRatesList,
  setTaxRatesList,
  saveTaxRateHandler,
}) => {
  const [loadingTaxRates, setLoadingTaxRates] = useState(false);
  const [taxvalue, settaxvalue] = useState(0);
  const [taxList, settaxList] = useState([{ id: "", index: "" }]);

  useEffect(() => {
    if (dbTaxRates?.length > 0) {
      settaxList([...dbTaxRates]);
    }
  }, [dbTaxRates])

  const [taxIds, settaxIds] = useState([]);
  useEffect(async () => {
    if (!taxRatesList || taxRatesList.length == 0) {
      setLoadingTaxRates(true);
      const { data } = await searchProductTaxRates_DefaultOption("");
      if (data) {
        const firstIndex = { name: "+ Add new tax", id: "1" };
        const lastIndex = { name: "Add an additional tax to invoice", id: "-1" };

        for (let i = 0; i < data?.taxRateList?.length; i++) {
          for (let j = 0; j < dbTaxRates?.length; j++) {
            if (!taxIds.includes(dbTaxRates[j]?.id)) {
              taxIds.push(dbTaxRates[j]?.id)
            }
            if (data.taxRateList[i]?.id === dbTaxRates[j].id) {
              data.taxRateList[i].isSelected = true;
            }
          }
        }
        setTaxRatesList([firstIndex, ...data?.taxRateList, lastIndex]);
      }
    }

    setLoadingTaxRates(false);
  }, [taxRate]);

  return (
    <div
      style={{
        display: " flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginTop: 10
      }}
    >
      {loadingTaxRates ? <Spinner size="24" color="gray" /> :
        taxList.map((item, index) => (
          <TaxEntry
            item={item}
            index={index}
            taxRatesList={taxRatesList}
            taxList={taxList}
            settaxvalue={settaxvalue}
            taxvalue={taxvalue}
            saveTaxRateHandler={saveTaxRateHandler}
            taxIds={taxIds}
            settaxIds={settaxIds}
          />
        ))}
    </div>
  );
};

export default TaxRates;
