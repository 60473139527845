import React, { useState } from "react";
import cn from "classnames";
import styles from "./TextInput.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import Spinner from "../../utils/spinner";
import { getNumberWithSpaces } from "../../utils/utils";

const TextInput = ({
  className,
  classLabel,
  classInput,
  label,
  req,
  descriptionLoading,
  updateQuantityLoading,
  icon,
  copy,
  currency,
  currencyBoxWidth,
  tooltip,
  place,
  onKeyDown,
  onKeyUp,
  onClickClose,
  close,
  percentage,
  text,
  priorText,
  crossOnLabel,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const isNotANumber = (currency || icon || priorText) ? true : isNaN(props?.value);

  return (
    <div
      className={cn(
        styles.field,
        { [styles.fieldIcon]: icon },
        { [styles.fieldCopy]: copy },
        { [styles.fieldCurrency]: currency },
        className
      )}
    >
      {label && (
        <div className={cn(classLabel, styles.label, { [styles.crossOnLabel]: crossOnLabel })}>
          {label}{" "}
          {crossOnLabel ? (
            <button onClick={onClickClose}>
              <Icon name="close" size="24" fill="#C7C5BF" />{" "}
            </button>
          ) :
            <b className={styles.required}>{req}</b>
          }
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        {priorText && (
          <span className={styles.priorText}>
            {priorText}{" "}
          </span>
        )}
        <input
          className={cn(classInput, styles.input, {
            [styles.transparentColor]: ((props?.value || props?.value === 0) && !isNotANumber && !isFocus),
          },)}
          {...props}
          onFocus={() => {
            setIsFocus(true);
          }}
          onBlur={() => {
            setIsFocus(false);
          }}
          autoComplete="off"
          onKeyUp={onKeyUp && onKeyUp}
          onKeyDown={onKeyDown && onKeyDown}
        />
        {
          ((props?.value || props?.value === 0) && !isNotANumber && !isFocus) &&
          <span className={styles.formattedNumber}>{getNumberWithSpaces(parseInt(props?.value || "0"))}
          </span>
        }
        {descriptionLoading || updateQuantityLoading ?
          <div className={styles.spinner}>
            <Spinner size={"20"} loading={descriptionLoading || updateQuantityLoading} color={"gray"} />
          </div> : null}

        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} size="24" />{" "}
          </div>
        )}

        {percentage && (
          <div className={styles.percentage}>
            <Icon name={percentage} size="24" />{" "}
          </div>
        )}
        {close && (
          <button className={styles.copy} onClick={onClickClose}>
            <Icon name="close" size="24" />{" "}
          </button>
        )}
        {copy && (
          <button className={styles.copy}>
            <Icon name="copy" size="24" />{" "}
          </button>
        )}
        {text && (
          <span className={styles.text}>
            {text}{" "}
          </span>
        )}
        {currency && <div className={styles.currency} style={currencyBoxWidth ? { width: currencyBoxWidth } : {}}>{currency}</div>}
      </div>
    </div>
  );
};

export default TextInput;
