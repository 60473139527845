import React from "react";
import Card from "../../../components/Card";
import styles from "./NoProjects.module.sass";
import Image from "../../../components/Image";

const NoProjects = ({ }) => {

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Card className={styles.card}>
                        <Image
                            className={styles.pic}
                            src="/images/icons/empty-black.png"
                            srcDark="/images/icons/empty-white.png"
                            alt="Core"
                        />
                    </Card>
                </div>
            </div>
        </>
    );
};

export default NoProjects;
