import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Image from "../../../../components/Image";
import { deleteContactContract, deleteContract, getContactsContracts } from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { dateFormatterWithTime, toastConfiguration, toTitleCase } from "../../../../utils/utils";
import styles from "./Contracts.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import { useHistory } from "react-router";
import getContactContract from '../../../../mocks/contract.json';
import { dateFormatter } from "../../../../utils/utils";
import AlternativeAvatar from "../../../../components/AlternativeAvatar";

let page = 0;

const Contracts = ({
  getUpdatedContract,
  id,
  name,
  email,
  mobileCode,
  mobile,
  imageURL,
  onClick,
  setTemplateName,
  setLink,
  setCreatedOn,
  setViewedOn,
  setSignedOn
}) => {

  let pageSize = 30;
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isNextPageExist, setIsNextPageExist] = useState(false);
  const [newDataLoading, setNewDataLoading] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const history = useHistory();

  useEffect(async () => {
    page = 0;
    await getContactPaymentHandler();
  }, [getUpdatedContract]);

  const getContactPaymentHandler = async (loader) => {
    if (loader)
      loader(true)
    else
      setLoading(true);
    page = page + 1;
    const { data, error } = await getContactsContracts({
      page: page || 1,
      pageSize,
      contactId: id,
      email: email,
    });
    if (data?.contracts) {
      setContracts(data?.contracts);
    } else {
      toast.error(error, toastConfiguration);
    }

    setIsNextPageExist(data?.isNextPageExist || false);


    if (loader)
      loader(false)
    else
      setLoading(false);
  };

  const editClickHandler = (item) => {
    if (item.paymentType == "Invoice")
      history.push(`/payments/${item.id}`);
    if (item.paymentType == "Subscription")
      history.push(`/subscriptions/${item.id}`);

  };

  const handleSingleContractDetails = (x) => {
    setTemplateName(x.templateName)
    setCreatedOn(x.createdOn)
    setLink(x.link)
    setViewedOn(x.viewedOn)
    setSignedOn(x.signedOn)
  }

  return (
    <>
      <ToastContainer></ToastContainer>
      <div className={styles.infoWrapper}>
        {imageURL ?
          <Image
            src={imageURL || "/images/content/avatar.jpg"}
            srcDark={imageURL || "/images/content/avatar.jpg"}
            className={styles.pic}
          />
          :
          <AlternativeAvatar name={name} className={'small-icon'} />
        }
        <div className={styles.infoWrap}>
          <div className={styles.info}>
            <p className={styles.label}>Name</p>
            <p className={styles.txt}>{name}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.label}>Email</p>
            <p className={styles.txt}>{email}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.label}>Mobile</p>
            <p className={styles.txt}>
              {mobileCode} {mobile}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.table}>
        {loading ? (
          <div className={styles.spinner}>
            <Spinner loading={loading} size={50} color={"gray"} />
          </div>
        ) : (<>
          <div className={styles.head}>
            <div className={styles.col}>Template</div>
            <div className={styles.col}>Link for customer</div>
            <div className={styles.col}>Sent</div>
            <div className={styles.col}>Viewed</div>
            <div className={styles.col}>Signed</div>
            <div className={styles.col}></div>
          </div>

          <>
            {contracts.map((x, index) => (
              <div className={styles.row} key={index} onClick={() => { history.push(`/contacts/template/${id}/${x.id}`) }}>
                <div className={styles.col}>
                  <p className={styles.mblLabel}>Template</p>
                  <span>{x.contractName}</span>
                </div>
                <div className={styles.col}>
                  <p className={styles.mblLabel}>Link for customer</p>
                  <div className={styles.linkWrap}>
                    <span style={{ wordBreak: 'break-all', paddingRight: 16 }}>{x.link}</span>
                    <button onClick={(e) => {
                      e.stopPropagation();
                      navigator?.clipboard?.writeText(x.link);
                      toast.success("Link copied successfully", toastConfiguration);
                    }}>
                      <Icon name="multiselect" size="24" />
                    </button>
                  </div>
                </div>
                <div className={styles.col} onClick={() => onClick(id)} style={{ width: 150 }}>
                  <p className={styles.mblLabel}>Sent</p>
                  <span>{dateFormatter(x.createdOn)}</span>
                </div>
                <div className={styles.col} onClick={() => onClick(id)}>
                  <p className={styles.mblLabel}>Viewed</p>
                  {x.viewedOn ? (
                    <span className={styles.success}>Yes</span>
                  ) : (
                    <span className={styles.danger}>No</span>
                  )}
                </div>
                <div className={styles.col} onClick={() => onClick(id)} style={{ width: 150 }}>
                  <p className={styles.mblLabel}>Signed</p>
                  {/* <span>{dateFormatterWithTime(x.createdOn)}</span> */}
                  {x.signedOn ? (
                    <span className={styles.success}>{dateFormatter(x.signedOn)}</span>
                  ) : (
                    <span className={styles.danger}>No</span>
                  )}
                </div>
                <div className={styles.col}>
                  <p className={styles.mblLabel}></p>
                  {/* className={cn(styles[x.status] || styles.draft)} */}
                  {/* <span >{toTitleCase(x.status)}</span> */}
                  <button className={styles.deleteBtn} onClick={async (e) => {
                    e.stopPropagation();
                    setDeleteLoading(true);
                    setDeleteId(x?.id);
                    const { data } = await deleteContract(x.id, {});
                    if (data) {
                      page = 0;
                      await getContactPaymentHandler();
                      toast.success("Successfully deleted contract", toastConfiguration);
                    } else {
                      toast.error("Error in deleting contract", toastConfiguration);
                    }
                    setDeleteLoading(false);
                  }}>
                    {deleteLoading && deleteId === x.id ? <Spinner size="24" color="gray" /> :
                      <Icon name="trash" size="24" />
                    }
                  </button>
                </div>
              </div>
            ))}
          </>
        </>
        )}
        {isNextPageExist ?
          <div className={styles.foot}>
            <button className={cn("button-stroke-red button-small", styles.button)}
              onClick={() => getContactPaymentHandler(setNewDataLoading)}
              style={{ width: "250px" }}
            >
              {newDataLoading ?
                <Spinner size="24px" color="gray" loading={newDataLoading} />
                :
                (
                  <>
                    <span>See More Payments</span>
                    <Icon name="arrow-next" size="20"></Icon>
                  </>
                )

              }
            </button>
          </div>
          : null}

      </div>
    </>
  );
};

export default Contracts;
