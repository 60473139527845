import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "../../Header/Messages/Messages.module.sass";
import notificationStyle from "./Notifications.module.sass";
import ReadNotifications from "./assets/read";
import Modal from "../../Modal";
import Card from "../../Card";
import UnReadNotifications from "./assets/unread";
import { getAlerts, markAlertAsRead, deleteAlert } from "../../../utils/apiCallHanlder";
import { useHistory } from 'react-router';
import Spinner from "../../../utils/spinner";
import { dateFormatter, transactionDateFormatter } from "../../../utils/utils";
import Icon from "../../Icon";

const Notifications = ({ hasUnreadNotifications, setHasUnreadNotification, onClick }) => {
    const history = useHistory();
    const [color, setColor] = useState("#464542");
    const [showNotifications, setShowNotifications] = useState(false);
    const [loading, setLoading] = useState(false);

    const [hasNextPage, setHasNextPage] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [mainLoading, setMainLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

    useEffect(async () => {
        if (showNotifications) {
            setMainLoading(true);
            setAlerts([]);
            await getNotificationAlerts(1);
            setMainLoading(false);
        }
    }, [showNotifications])

    useEffect(() => {
        if (alerts.length > 0) {
            let count = 0;
            let shouldSetState = false;
            for (let i = 0; i < alerts?.length; i++) {
                if (!alerts[i]?.isRead) {
                    count = count + 1;
                    shouldSetState = true;
                }
            }

            setUnreadNotificationCount(count);
            setHasUnreadNotification(shouldSetState);
        }
    }, [alerts])

    const alertActionHandler = (action) => {
        switch (action) {
            case 'Redirect_StudioDocument':
                history.push("/studio/4")
                return;
            default:
                history.push("/")
        }
    }

    const getNotificationAlerts = async (pageNo = 1) => {
        const alertsPreview = await getAlerts({
            OnlyUnread: false,
            PageSize: 10,
            page: pageNo
        })
        if (alertsPreview?.data) {
            setHasNextPage(alertsPreview?.data?.isNextPageExist);
            if (pageNo === 1) {
                setAlerts(alertsPreview?.data?.alertEvents)
            } else {
                setAlerts([...(alertsPreview?.data?.alertEvents || []), ...alerts])
            }
        }
    }

    return (
        <div
            className={cn(styles.messages)}
        >
            <span
                onMouseEnter={() => {
                    setColor("#FF4900")
                }}
                onMouseLeave={() => {
                    setColor("#464542")
                }}
                style={{ cursor: "pointer" }}
                className={styles.linkNotification}
                onClick={() => {
                    setShowNotifications(true)
                    onClick()
                    }}
            >
                {hasUnreadNotifications ?
                    <ReadNotifications size={24} />
                    :
                    <UnReadNotifications size={24} />
                }
                <span>Notifications</span>
            </span>

            <Modal
                outerClassName={styles.outerModal}
                visible={showNotifications}
                onClose={() => setShowNotifications(false)}
                children={
                    <Card
                        title="Notifications"
                        className={styles.card}
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: mainLoading || alerts?.length < 1 ? 'center' : 'unset',
                            alignItems: mainLoading || alerts?.length < 1 ? 'center' : 'unset',
                            height: 250,
                            overflowY: 'auto'
                        }}
                        >
                            {mainLoading ? <Spinner size="48" color="gray" /> :
                            alerts?.length < 1 ? <span>There are no notifications</span> :
                                alerts?.map((item, index) => {
                                    return <div key={index} style={{
                                        display: 'flex',
                                        borderBottom: alerts[index + 1] ? '1px solid gray' : 'unset',
                                        padding: 8,
                                        cursor: 'pointer',
                                    }}>
                                        <span style={{
                                        display: 'flex',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                            if (unreadNotificationCount === 1) {
                                                setHasUnreadNotification(false);
                                            }

                                            setShowNotifications(false)
                                            if (!item?.isRead) {
                                                markAlertAsRead(item?.alertEventId)
                                            }
                                            if (item?.hasAction) {
                                                alertActionHandler(item?.actionType)
                                            }
                                        }}
                                        >
                                            {item?.isRead ?
                                                <div className={notificationStyle.unReadIcon}><UnReadNotifications size={20} /></div> : <div className={notificationStyle.readIcon}><ReadNotifications size={20} /></div>
                                            }
                                            <span style={{
                                                fontSize: 14,
                                                marginLeft: 8
                                            }}> {item?.message}
                                            </span>
                                            <span style={{
                                                fontSize: 14,
                                                marginLeft: 8,
                                                width: 130
                                            }}>
                                                {dateFormatter(item?.createdOn)}
                                            </span>
                                    </span>
                                    <span style={{
                                            fontSize: 14,
                                            marginLeft: 20,
                                            width: 50
                                        }}>
                                            <button
                                                className={styles.removeBtn}
                                                onClick={async () => {
                                                    const { data } = await deleteAlert(item?.alertEventId);
                                                    if (data) {
                                                        setAlerts(alerts.filter(r => r.alertEventId !== item?.alertEventId))
                                                        setShowNotifications(true);
                                                    }
                                                }}
                                                >
                                                <Icon name='trash' size='20' />
                                            </button>
                                        </span>
                                    </div>
                                })
                            }
                        </div>
                        {hasNextPage && (
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'end', marginTop: 24 }}>
                                <button
                                    className={"button"}
                                    onClick={async () => {
                                        setLoading(true);
                                        await getNotificationAlerts(pageNumber + 1);
                                        setPageNumber(pageNumber + 1)
                                        setLoading(false);
                                    }}
                                >
                                    {loading ?
                                        <Spinner size="24" color="white" />
                                        :
                                        "Load More"
                                    }
                                </button>
                            </div>
                        )}
                    </Card>
                }
            />
        </div>
    );
};

export default Notifications;
