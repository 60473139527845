import React, { useEffect, useState } from "react";
import Icon from "../../../../components/Icon";
import styles from "./AdjustRecipient.module.sass";
import Card from "../../../../components/Card";
import TextInput from "../../../../components/TextInput";
import Dropdown_c from "../../../../components/Dropdown_c";
import Dropdown from "../../../../components/Dropdown";
import ReactPhoneInput from "react-phone-input-2";
import Spinner from "../../../../utils/spinner";
import Image from "../../../../components/Image";
import cn from "classnames";
import Modal from "../../../../components/Modal";
import Emails from "../../../Customers/EditNewCustomer/Emails";
import Events from "../../../Customers/EditNewCustomer/Events";
import Payments from "../../../Customers/EditNewCustomer/Questioners";
import Documents from "../../../Customers/EditNewCustomer/Documents";
import Contracts from "../../../Customers/EditNewCustomer/Contract";
import Questioners from "../../../Customers/EditNewCustomer/Questioners"
import Checkbox from "../../../../components/Checkbox";
import DeleteModal from "../../../../components/DeleteModal";
import OutsideClickHandler from "react-outside-click-handler";
import AlternativeAvatar from "../../../../components/AlternativeAvatar";
import {
  addInvoice,
  addRecipient,
  connectWithGoogleExternalInt,
  deleteCustomer,
  deleteRecipient,
  disconnectGoogleEmails,
  getRecipient,
  updateRecipient,
  uploadProductImage,
} from "../../../../utils/apiCallHanlder";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  getReturnUrl,
  toastConfiguration,
  trackIntercomEvent,
} from "../../../../utils/utils";
import SendNewEmail from "../../../Studio/Email/SendNewEmail";
import AddEditEvent from "../../../Customers/EditNewCustomer/Events/AddEditEvent/index";
import DocumentOverviewPopup from "../../../Accounting/Documents/DocumentOverviewPopup";
import NewContract from "../../../Customers/EditNewCustomer/Contract/NewContract";
import NewQuestioners from "../../../Customers/EditNewCustomer/Questioners/NewQuestioners";
import LargeNav from "../../../../components/LargeNav";

let haveDropdownsData = false;

let randomNumber = 1;

let countries = [""];

let accountHolderType = [
  {
    text: "Individual",
    value: "individual",
    display: "Individual",
  },
  {
    text: "Company",
    value: "company",
    display: "Company",
  },
];

let states = [""];

const inputStyle = {
  position: "relative",
  fontSize: "14px",
  letterSpacing: ".01rem",
  marginTop: "0px !important",
  marginBottom: " 0px !important",
  paddingLeft: "48px",
  marginLeft: "0px",
  background: "#F4F4F4",
  border: "none",
  borderRadius: "10px",
  lineHeight: "25px",
  height: "45px",
  width: "100%",
  outline: "none",
};

const recipientInfos = ["Details", "Emails", "Events", "Payments", "Documents", "Contract", "Questionnaire"];

const avatarTypes = ["Human", "Jungle", "Modern", "Monster", "Superhero"];

const currencies = ["USD", "Currency 1", "Currency 2"];

const AdjustRecipient = ({
  setShowAdjustRecipient,
  setShowTransactionSend,
  setShowRecipient,
  setSelectedRecipient,
  setShowRecipientOverview,
  id,
  isComingFromCustomer,
}) => {
  if (!haveDropdownsData) {
    const customerLookupdata = JSON.parse(localStorage.getItem("lookupData"));
    let data = null;
    if (customerLookupdata) {
      data = customerLookupdata?.data;

      countries = data.Countries; //keeping only US
      states = data.US_States;
      haveDropdownsData = true;
    }
  }
  const [showPaymentBtn, setShowPaymentBtn] = useState(false);
  const [selectCountry, setSelectCountry] = useState(countries[0].value);
  const [selectAccountHolderType, setSelectAccountHolderType] = useState(
    accountHolderType[0].value
  );
  const [selectState, setSelectState] = useState(states[0].value);
  const [syncWithGoogleVisible, setSyncWithGoogleVisible] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [recipientInfo, setRecipientInfo] = useState(recipientInfos[0])

  const [showNewQuestioners, setShowNewQuestioners] = useState(false)

  const [updateDocumentObject, setUpdateDocumentObject] = useState(false)
  const [getUpdatedContract, setUpdatedContract] = useState(false)

  const [showNewContract, setShowNewContract] = useState(false)
  const [editMode, setEditMode] = useState(true)

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");

  const [emails, setEmails] = useState([]);
  const [isNextPageExists, setIsNextPageExists] = useState(false);
  const [nextPageInfo, setNextPageInfo] = useState(null);

  const [startLoadingEmails, setStartLoadingEmails] = useState(true);
  const [unsyncLoading, setUnSyncLoading] = useState(false);

  const [imageUrl, setImageUrl] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [startLoading, setStartLoading] = useState(false);

  const [imageActiveIndex, setImageActiveIndex] = useState(1);

  //Recipient nav state
  const [activeIndex, setActiveIndex] = useState(1);
  const [activeAvatarIndex, setActiveAvatarIndex] = useState(1);
  const [activeRecipientInfo, setActiveRecipientInfo] = useState(
    recipientInfos[0]
  );
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [validEmail, setValidEmail] = useState(null);
  const [currency, setCurrency] = useState(currencies[0]);
  const [shippingTick, setShippingTick] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [text, setText] = useState("Upload or drag here");

  const [uploadLoading, setUploadLoading] = useState(false);

  const [showNewDocModal, setShowNewDocModal] = useState(false);
  const [showSendNewEmail, setShowSendNewEmail] = useState(false);
  const [showNewEventModal, setShowNewEventModal] = useState(false);
  const [editEvent, setEditEvent] = useState(null);

  const [getUpdatedQuestionnaire, setGetUpdatedQuestionnaire] = useState(false);

  //new contract data
  const [templateName, setTemplateName] = useState("")
  const [createdOn, setCreatedOn] = useState(null)
  const [link, setLink] = useState(null)
  const [viewedOn, setViewedOn] = useState(null)
  const [signedOn, setSignedOn] = useState(null)

  const [isBoth, setIsBoth] = useState(false);

  const history = useHistory();

  const handleReload = () => {
    setActiveIndex(3);
  };

  useEffect(async () => {
    if (id !== "add") {
      setStartLoading(true);

      const { data } = await getRecipient({
        id,
      });

      if (data) {
        const recipient = data?.recipient;
        setSelectCountry(recipient?.addressCountry);
        setSelectAccountHolderType(recipient?.accountHolderType);
        setSelectState(recipient?.addressState);

        setIsBoth(recipient?.isBoth);

        setName(recipient?.name);
        setEmail(recipient?.email);
        setValidEmail(isValidEmail(recipient?.email));
        setMobile(recipient?.mobile);
        setRoutingNumber(recipient?.accountRouting);
        recipient?.imageUrl && setImageUrl(recipient?.imageUrl);
        setAccountNumber(recipient?.accountNumber);
        setAddressLine1(recipient?.addressLine1);
        setAddressLine2(recipient?.addressLine2);
        setCity(recipient?.addressCity);
        setZip(recipient?.addressPostalCode);
      }

      setStartLoading(false);
    }
  }, [id]);

  const saveClickHandler = async () => {
    // if (isNaN(routingNumber) || routingNumber.length !== 9) {
    //   setErrorMessage("Routing Number should be numerice of 9 digits");
    // } else {
    if (name !== "") {
      setLoading(true);

      const payload = {
        name: name,
        email: email,
        mobile: "+" + mobile,
        imageUrl: imageUrl,
        accountType: selectAccountHolderType,
        accountHolderType: selectAccountHolderType,
        accountNumber: accountNumber,
        accountRouting: routingNumber,
        addressCountry: selectCountry,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        addressCity: city,
        addressState: selectState,
        addressPostalCode: zip,
      };

      if (id !== "add") {
        payload["id"] = id;
      }

      const { data, error } =
        id === "add"
          ? await addRecipient(payload)
          : await updateRecipient(payload);

      if (data) {
        if (id === "add") {
          trackIntercomEvent("recipient-created");
          if (isComingFromCustomer) {
            history.push("/contacts/tabs/1");
          } else {
            setSelectedRecipient && setSelectedRecipient(data?.recipient);
            setShowAdjustRecipient && setShowAdjustRecipient(false);
            setShowRecipient && setShowRecipient(true);
            setShowRecipientOverview && setShowRecipientOverview(false);
          }
        } else {
          isComingFromCustomer
            ? history.push("/contacts/tabs/1")
            : history.push("/transactions/all");
        }
      } else {
        setErrorMessage(error);
      }
      setLoading(false);
    } else {
      toast.error("Please provide contact name", toastConfiguration);
    }
    //}
  };

  const onChange = (setValue, valueToSet) => {
    setValue(valueToSet);
    setErrorMessage("");
  };

  useEffect(() => {
    setActiveIndex(recipientInfos.indexOf(activeRecipientInfo) + 1);
  }, [activeRecipientInfo]);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const onDropHandler = (ev) => {
    ev.preventDefault();

    let file = "";
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file = [...ev.dataTransfer.items]
        .find((item) => item.kind === "file")
        .getAsFile();
    } else {
      // Use DataTransfer interface to access the file(s)
      file = ev.dataTransfer.files[0];
    }
    imageUploadHandler(file);
    setText(file?.name);
  };

  const onDragOver = (ev) => ev.preventDefault();

  const imageUploadHandler = async (imageFile) => {
    setUploadLoading(true);
    const formData = new FormData();
    formData.append("", imageFile);
    const { data } = await uploadProductImage(formData);
    if (data) {
      setImageUrl(data.image.url);
    } else {
      toast.error("Error in uploading Image", toastConfiguration);
    }

    setText("Upload or drag here");
    setShowAvatarModal(false);
    setUploadLoading(false);
  };

  const ConnectWithGmailHandler = async () => {
    setSyncLoading(true);
    const ReturnUrl = getReturnUrl("contacts/res/" + id);

    const signInResponse = await connectWithGoogleExternalInt({ ReturnUrl });
    const { data } = signInResponse;
    if (data) {
      window.location = data.redirectURL;
    }
    setSyncLoading(true);
  };

  return (
    !showNewContract && !showNewQuestioners ? (
      <>
        {startLoading ? (
          <div style={{ position: "absolute", left: "60%", top: "50%" }}>
            <Spinner size="48" color="gray" />
          </div>
        ) : (
          <>
            <ToastContainer />
            <div className={styles.title}>
              {isComingFromCustomer ? (
                <div
                  className={styles.backBtn}
                  onClick={() => {
                    history.push("/contacts/tabs/1");
                  }}
                >
                  <Icon name="arrow_backward" size="18" />
                  Back to contacts
                </div>
              ) : (
                <div
                  className={styles.backBtn}
                  onClick={() => {
                    history.push("/transactions");
                  }}
                >
                  <Icon name="arrow_backward" size="18" />
                  Back to transactions
                </div>
              )}
            </div>

            <Card className={styles.card}>
              <Card
                className={styles.singleCard}
                title="Contact info2"
                border
              /* classTitle="title-blue" */
              >
                <LargeNav
                  tabOptions={recipientInfos}
                  activeTab={activeIndex - 1}
                  onClick={(x, index) => {
                    setActiveIndex(index + 1);
                  }}
                />
                <Dropdown
                  options={recipientInfos}
                  handleChangeIndex={(index) => {
                    setActiveIndex(index + 1);
                  }}
                  value={recipientInfo}
                  setValue={setRecipientInfo}
                  className={styles.navDropdown}
                />
                {/* <Dropdown_c
                  className={styles.navDropdown}
                  value={activeRecipientInfo}
                  setValue={setActiveRecipientInfo}
                  options={recipientInfos}
                  small
                /> */}
                {activeIndex === 1 ? (
                  <>
                    <div className={styles.row}>
                      <div className={styles.avatarWrap}>
                        <button onClick={() => setShowAvatarModal(true)}>
                          <Icon name="close" size="16" />
                        </button>
                        {imageUrl
                          ? (<Image
                            className={styles.avatar}
                            src={imageUrl}
                            srcDark={imageUrl}
                          />)
                          : (
                            <AlternativeAvatar name={name} className={'big-icon'} />
                          )
                        }

                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <TextInput
                            label="Name"
                            value={name}
                            onChange={(e) => onChange(setName, e.target.value)}
                            name="name"
                            type="text"
                            style={
                              name === ""
                                ? {
                                  backgroundColor: "#FFBC9925",
                                }
                                : null
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <TextInput
                            label="Email"
                            value={email}
                            onChange={(e) => {
                              onChange(setEmail, e.target.value);
                              if (isValidEmail(e.target.value)) {
                                setValidEmail(true);
                              } else {
                                setValidEmail(false);
                              }
                            }}
                            name="email"
                            type="email"
                            style={
                              validEmail
                                ? {
                                  backgroundColor: "#F4F4F4",
                                }
                                : {
                                  backgroundColor: "#FFBC9925",
                                }
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <div className={styles.fieldLabel}>Mobile</div>
                          <div className={styles.mobileField}>
                            <ReactPhoneInput
                              inputExtraProps={{
                                name: "phone",
                                required: true,
                                autoFocus: true,
                              }}
                              buttonStyle={{ border: "none" }}
                              inputStyle={inputStyle}
                              country={"us"}
                              countryCodeEditable={false}
                              value={mobile}
                              onChange={(value) => onChange(setMobile, value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <TextInput
                            label="Description"
                            name="name"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <Card
                      className={styles.card}
                      title="Billing information"
                    /* classTitle="title-yellow" */
                    >
                      <div className={styles.row}>
                        <div className={styles.col}>
                          <div className={styles.field}>
                            <div className={styles.fieldLabel}>Country</div>
                            <Dropdown_c
                              className={styles.dropdown}
                              classDropdownHead={styles.dropdownHead}
                              value={selectCountry}
                              setValue={setSelectCountry}
                              options={countries}
                            />
                          </div>
                        </div>
                        <div className={styles.col}>
                          <div className={styles.field}>
                            <TextInput
                              label="Routing number"
                              style={
                                isNaN(routingNumber) ||
                                  routingNumber?.length !== 9
                                  ? routingNumber?.length === 0
                                    ? {
                                      backgroundColor: "#F4F4F4",
                                    }
                                    : {
                                      backgroundColor: "#FFBC9925",
                                    }
                                  : null
                              }
                              value={routingNumber}
                              onChange={(e) =>
                                onChange(setRoutingNumber, e.target.value)
                              }
                              name="routing"
                              type="text"
                              required
                            />
                          </div>
                        </div>
                        <div className={styles.col}>
                          <div className={styles.field}>
                            <TextInput
                              label="Account number"
                              value={accountNumber}
                              onChange={(e) =>
                                onChange(setAccountNumber, e.target.value)
                              }
                              name="account"
                              type="text"
                              required
                            />
                          </div>
                        </div>
                        {/* <div className={styles.col}>
                        <div className={styles.field}>
                          <div className={styles.fieldLabel}>
                            Account Holder Type{" "}
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <Dropdown_c
                            className={styles.dropdown}
                            classDropdownHead={styles.dropdownHead}
                            value={selectAccountHolderType}
                            setValue={setSelectAccountHolderType}
                            options={accountHolderType}
                          />
                        </div>
                      </div> */}
                        <div className={styles.col}>
                          <div className={styles.field}>
                            <TextInput
                              label="Address"
                              name="addressLine1"
                              type="text"
                              value={addressLine1}
                              onChange={(e) =>
                                onChange(setAddressLine1, e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        {/* <div className={styles.col}>
                        <div className={styles.field}>
                          <TextInput
                            label="Address Line 2"
                            name="addressLine2"
                            type="text"
                            value={addressLine2}
                            onChange={(e) =>
                              onChange(setAddressLine2, e.target.value)
                            }
                            required
                          />
                        </div>
                      </div> */}
                        <div className={styles.col}>
                          <div className={styles.field}>
                            <TextInput
                              label="City"
                              name="city"
                              type="text"
                              value={city}
                              onChange={(e) => onChange(setCity, e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className={styles.col}>
                          <div className={styles.field}>
                            <TextInput
                              label="ZIP"
                              name="zip"
                              type="text"
                              value={zip}
                              onChange={(e) => onChange(setZip, e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className={styles.col}>
                          <div className={styles.field}>
                            <div className={styles.fieldLabel}>State</div>
                            <Dropdown_c
                              className={styles.dropdown}
                              classDropdownHead={styles.dropdownHead}
                              value={selectState}
                              setValue={setSelectState}
                              options={states}
                            />
                          </div>
                        </div>
                        <div className={styles.col}>
                          <div className={styles.field}>
                            <TextInput
                              label="Tax Id"
                              name="tax id"
                              type="text"
                              required
                            />
                          </div>
                        </div>
                        {/* <div className={styles.col}>
                        <div className={styles.field}>
                          <div className={styles.fieldLabel}>Currency</div>
                          <Dropdown
                            className={styles.dropdown}
                            value={currency}
                            setValue={setCurrency}
                            options={currencies}
                          />
                        </div>
                      </div> */}
                      </div>
                      <div className={styles.row}>
                        <div className={styles.shippingWrap}>
                          <div className={styles.fieldLabel}>
                            Shipping Details
                          </div>
                          <div className={styles.checkStatus}>
                            <Checkbox
                              className={styles.checkbox}
                              classCheckboxTick={styles.checkboxTick}
                              value={shippingTick}
                              onChange={() => setShippingTick(!shippingTick)}
                            />
                            <span>Same as billing details</span>
                          </div>
                        </div>
                      </div>
                      {!shippingTick ? (
                        <>
                          <div className={styles.row}>
                            <div className={styles.col}>
                              <div className={styles.field}>
                                <div className={styles.fieldLabel}>Country</div>
                                <Dropdown_c
                                  className={styles.dropdown}
                                  classDropdownHead={styles.dropdownHead}
                                  value={selectCountry}
                                  setValue={setSelectCountry}
                                  options={countries}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.col}>
                              <div className={styles.field}>
                                <TextInput
                                  label="Address"
                                  name="address"
                                  type="text"
                                  required
                                />
                              </div>
                            </div>
                            <div className={styles.col}>
                              <div className={styles.field}>
                                <TextInput
                                  label="City"
                                  name="city"
                                  type="text"
                                  required
                                />
                              </div>
                            </div>
                            <div className={styles.col}>
                              <div className={styles.field}>
                                <TextInput
                                  label="ZIP"
                                  name="zip"
                                  type="text"
                                  required
                                />
                              </div>
                            </div>
                            <div className={styles.col}>
                              <div className={styles.field}>
                                <div className={styles.fieldLabel}>State</div>
                                <Dropdown_c
                                  className={styles.dropdown}
                                  classDropdownHead={styles.dropdownHead}
                                  value={selectState}
                                  setValue={setSelectState}
                                  options={states}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </Card>
                    {/* Avatar Modal */}
                    <Modal
                      visible={showAvatarModal}
                      onClose={() => setShowAvatarModal(false)}
                      outerClassName={styles.outerClass}
                      children={
                        <>
                          <Card
                            /* classTitle="title-blue" */
                            title="Image"
                            className={styles.card}
                          >
                            <div
                              className={styles.uploadImage}
                              id="drop_zone"
                              onDrop={onDropHandler}
                              onDragOver={onDragOver}
                              onClick={() => {
                                document?.getElementById("imgupload").click();
                              }}
                            >
                              {uploadLoading ? (
                                <div style={{ marginTop: 5 }}>
                                  <Spinner size="24" color="gray" />
                                </div>
                              ) : (
                                <Icon name="upload" size="24" />
                              )}
                              {text}
                            </div>
                            <input
                              type="file"
                              id="imgupload"
                              accept="image/png, image/gif, image/jpeg"
                              style={{
                                display: "none",
                              }}
                              onChange={(event) =>
                                imageUploadHandler(event.target.files[0])
                              }
                            />
                            <p className={styles.orChoose}>Or choose an avatar</p>
                            <div className={styles.nav}>
                              <div className={styles.typeNav}>
                                {avatarTypes.map((x, index) => (
                                  <div
                                    className={cn(styles.item, {
                                      [styles.active]:
                                        index === imageActiveIndex - 1,
                                    })}
                                    key={index}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setImageActiveIndex(index + 1);
                                    }}
                                  >
                                    {x}
                                  </div>
                                ))}
                              </div>
                            </div>
                            {imageActiveIndex === 1 ? (
                              <div className={styles.avatarFlex}>
                                {[...Array(36).keys()].map((x, index) => (
                                  <Image
                                    key={index}
                                    className={styles.avatar}
                                    src={`/avatars/HUMANS/GREATWEEK.COM AVATAR ${x + 1
                                      }.png`}
                                    srcDark={`/avatars/HUMANS/GREATWEEK.COM AVATAR ${x + 1
                                      }.png`}
                                    onClick={() => {
                                      setShowAvatarModal(false);
                                      setImageUrl(
                                        `/avatars/HUMANS/GREATWEEK.COM AVATAR ${x + 1
                                        }.png`
                                      );
                                    }}
                                  />
                                ))}
                              </div>
                            ) : imageActiveIndex === 2 ? (
                              <div className={styles.avatarFlex}>
                                {[...Array(18).keys()].map((x, index) => (
                                  <Image
                                    key={index}
                                    className={styles.avatar}
                                    src={`/avatars/JUNGLE/GREATWEEK.COM JUNGLE ${x + 1
                                      }.png`}
                                    srcDark={`/avatars/JUNGLE/GREATWEEK.COM JUNGLE ${x + 1
                                      }.png`}
                                    onClick={() => {
                                      setShowAvatarModal(false);
                                      setImageUrl(
                                        `/avatars/JUNGLE/GREATWEEK.COM JUNGLE ${x + 1
                                        }.png`
                                      );
                                    }}
                                  />
                                ))}
                              </div>
                            ) : imageActiveIndex === 3 ? (
                              <div className={styles.avatarFlex}>
                                {[...Array(75).keys()].map((x, index) => (
                                  <Image
                                    key={index}
                                    className={styles.avatar}
                                    src={`/avatars/MODERN/GREATWEEK.COM ANIMATED ${x + 1
                                      }.png`}
                                    srcDark={`/avatars/MODERN/GREATWEEK.COM ANIMATED ${x + 1
                                      }.png`}
                                    onClick={() => {
                                      setImageUrl(
                                        `/avatars/MODERN/GREATWEEK.COM ANIMATED ${x + 1
                                        }.png`
                                      );
                                      setShowAvatarModal(false);
                                    }}
                                  />
                                ))}
                              </div>
                            ) : imageActiveIndex === 4 ? (
                              <div className={styles.avatarFlex}>
                                {[...Array(17).keys()].map((x, index) => (
                                  <Image
                                    key={index}
                                    className={styles.avatar}
                                    src={`/avatars/MONSTER/GREATWEEK.COM MONSTER ${x + 1
                                      }.png`}
                                    srcDark={`/avatars/MONSTER/GREATWEEK.COM MONSTER ${x + 1
                                      }.png`}
                                    onClick={() => {
                                      setImageUrl(
                                        `/avatars/MONSTER/GREATWEEK.COM MONSTER ${x + 1
                                        }.png`
                                      );
                                      setShowAvatarModal(false);
                                    }}
                                  />
                                ))}
                              </div>
                            ) : imageActiveIndex === 5 ? (
                              <div className={styles.avatarFlex}>
                                {[...Array(17).keys()].map((x, index) => (
                                  <Image
                                    key={index}
                                    className={styles.avatar}
                                    src={`/avatars/SUPERHERO/GREATWEEK.COM SUPERHERO ${x + 1
                                      }.png`}
                                    srcDark={`/avatars/SUPERHERO/GREATWEEK.COM SUPERHERO ${x + 1
                                      }.png`}
                                    onClick={() => {
                                      setImageUrl(
                                        `/avatars/SUPERHERO/GREATWEEK.COM SUPERHERO ${x + 1
                                        }.png`
                                      );
                                      setShowAvatarModal(false);
                                    }}
                                  />
                                ))}
                              </div>
                            ) : null}
                          </Card>
                        </>
                      }
                    />
                  </>
                ) : activeIndex === 2 ? (
                  <>
                    {id ? (
                      <Emails
                        name={name}
                        id={id}
                        imageUrl={imageUrl}
                        email={email}
                        mobile={mobile}
                        setSyncWithGoogleVisible={setSyncWithGoogleVisible}
                        emails={emails}
                        setEmails={setEmails}
                        nextPageInfo={nextPageInfo}
                        setIsNextPageExists={setIsNextPageExists}
                        setNextPageInfo={setNextPageInfo}
                        isNextPageExists={isNextPageExists}
                        startLoading={startLoadingEmails}
                        setStartLoading={setStartLoadingEmails}
                      />
                    ) : null}
                  </>
                ) : activeIndex === 3 ? (
                  <>
                    {id ? (
                      <Events
                        id={id}
                        name={name}
                        email={email}
                        mobile={mobile}
                        imageUrl={imageUrl}
                      />
                    ) : null}
                  </>
                ) : activeIndex === 4 ? (
                  <>
                    {id ? (
                      <Payments
                        id={id}
                        imageURL={imageUrl}
                        name={name}
                        email={email}
                        mobile={mobile}
                      />
                    ) : null}
                  </>
                ) : activeIndex === 5 ? (
                  <>
                    {id ? (
                      <Documents
                        name={name}
                        imageURL={imageUrl}
                        email={email}
                        mobile={mobile}
                        customerId={id}
                        updateDocumentObject={updateDocumentObject}
                      />
                    ) : null}
                  </>
                ) : activeIndex === 6 ? (
                  <>
                    {id ? (
                      <Contracts
                        name={name}
                        imageURL={imageUrl}
                        email={email}
                        mobile={mobile}
                        customerId={id}
                        id={id}
                        getUpdatedContract={getUpdatedContract}
                        setTemplateName={setTemplateName}
                        setLink={setLink}
                        setCreatedOn={setCreatedOn}
                        setViewedOn={setViewedOn}
                        setSignedOn={setSignedOn}
                        onClick={() => {
                          setShowNewContract(true)
                          setEditMode(false)
                        }}
                      />
                    ) : null}
                  </>
                ) : activeIndex === 7 ? (
                  <>
                    {id ? (
                      <Questioners
                        name={name}
                        imageURL={imageUrl}
                        getUpdatedQuestionnaire={getUpdatedQuestionnaire}
                        email={email}
                        mobile={mobile}
                        id={id}
                        customerId={id}
                      />
                    ) : null}
                  </>
                ) : null}
              </Card>
              {activeIndex === 1 ? (
                <div className={styles.actionCard}>
                  {id === "add" ? (
                    <button
                      onClick={() => {
                        isComingFromCustomer
                          ? history.push("/contacts/tabs/1")
                          : history.push(
                            id === "add"
                              ? "/transactions/send"
                              : "/transactions/all"
                          );
                      }}
                      className={cn("button-stroke", styles.button)}
                    >
                      <Icon name="arrow-left" size="20" />
                      <span>Cancel</span>
                    </button>
                  ) : (
                    <DeleteModal
                      className={styles.deleteBtn}
                      onConfirm={() => {
                        isComingFromCustomer
                          ? history.push("/contacts/tabs/1")
                          : history.push("/transactions/all");
                      }}
                      id={id}
                      deleteFunction={deleteRecipient}
                      setValue={false}
                    />
                  )}

                  <button
                    onClick={saveClickHandler}
                    className={"button"}
                  >
                    {loading ? (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Spinner size="24" color="white" />
                      </div>
                    ) : (
                      <>
                        <Icon name="save" size="20" />
                        <span>{id === "add" ? "Save" : "Save"}</span>
                      </>
                    )}
                  </button>

                  {errorMessage !== "" ? (
                    <div className={styles.errorContainer}>
                      <span className={styles.errorMessage}>{errorMessage}</span>
                    </div>
                  ) : null}
                </div>
              ) : activeIndex === 2 ? (
                <div className={styles.actionCard}>

                  {!startLoadingEmails &&
                    (syncWithGoogleVisible ? (
                      <button
                        className="button"
                        onClick={ConnectWithGmailHandler}
                        style={{ width: 200 }}
                      >

                        {syncLoading ? <Spinner size="24" color='white' /> :
                          <>
                            <Image
                              src="/images/icons/google.svg"
                              srcDark="/images/icons/google.svg"
                            />
                            Sync Google mail
                          </>
                        }
                      </button>
                    ) : (
                      <>
                        <button
                          className="button"
                          onClick={() => setShowSendNewEmail(true)}
                        >
                          <Icon name="mail" size="24" />
                          Send new email
                        </button>
                        <button
                          style={{ width: 150 }}
                          onClick={async () => {
                            setUnSyncLoading(true);
                            const { data } = await disconnectGoogleEmails({});
                            if (data) {
                              toast.success(
                                "Successfully unsynced google mail",
                                toastConfiguration
                              );
                              setEmails([]);
                              setNextPageInfo(null);
                              setIsNextPageExists(false);
                              setSyncWithGoogleVisible(true);
                            } else {
                              toast.error(
                                "Error in unsyncing google mail",
                                toastConfiguration
                              );
                            }
                            setUnSyncLoading(false);
                          }}
                          className={cn("button button-stroke-red", styles.unlink)}
                        >
                          {unsyncLoading ? (
                            <Spinner size="24" color="white" />
                          ) : (
                            "Unlink"
                          )}
                        </button>
                      </>
                    ))}
                </div>
              ) : activeIndex === 3 ? (
                <div className={styles.actionCard}>
                  <button
                    onClick={() => setShowNewEventModal(true)}
                    className="button"
                  >
                    Add new event
                  </button>
                </div>
              ) : activeIndex === 4 ? (
                <div className={styles.actionCard}>
                  <OutsideClickHandler
                    onOutsideClick={() => setShowPaymentBtn(false)}
                  >
                    <div
                      className={cn(styles.dotsBtn, {
                        [styles.active]: showPaymentBtn,
                      })}
                    >
                      <div className={styles.head}>
                        <button
                          className={cn("button", styles.btn)}
                          onClick={() => setShowPaymentBtn(!showPaymentBtn)}
                        >
                          Add new payment
                          {showPaymentBtn ? (
                            <Icon name="arrow-up" size="24" />
                          ) : (
                            <Icon name="arrow-down" size="24" />
                          )}
                        </button>
                      </div>
                      <div className={styles.actionBody}>
                        {isBoth && (
                          <>
                            <button
                              className={styles.actionItem}
                              onClick={async () => {
                                setInvoiceLoading(true);
                                const { data, error } = await addInvoice({
                                  customerId: id,
                                  customerImage: imageUrl,
                                });
                                if (data) {
                                  history.push(`/payments/${data?.invoice?.id}`);
                                }
                                else {
                                  toast.error(error, toastConfiguration);
                                }
                                setInvoiceLoading(false);
                              }}
                            >
                              <Icon name="document" size="24" />
                              Invoice
                              {invoiceLoading && (
                                <label style={{ marginLeft: 14, marginTop: 4 }}>
                                  <Spinner size="16" color="gray" />
                                </label>
                              )}
                            </button>
                            <button
                              className={styles.actionItem}
                              onClick={() => {
                                history.push({
                                  pathname: "/subscriptions/add",
                                  state: { customerId: id },
                                });
                              }}
                            >
                              <Icon name="sync" size="24" />
                              Subscription
                            </button>
                            <button
                              className={styles.actionItem}
                              onClick={() => {
                                history.push({
                                  pathname: "/paymentLinks",
                                  state: { customerId: id },
                                });
                              }}
                            >
                              <Icon name="link" size="24" />
                              Payment Link
                            </button>
                          </>
                        )}
                        <button
                          className={styles.actionItem}
                          onClick={() => {
                            history.push({
                              pathname: "/transactions/send",
                              state: { recipientId: id },
                            });
                          }}
                        >
                          <Icon name="pay" size="20" viewBox="0 0 20 20" />
                          Pay to someone
                        </button>
                      </div>
                    </div>
                  </OutsideClickHandler>
                </div>
              ) : activeIndex === 5 ? (
                <div
                  className={styles.actionCard}
                  onClick={() => setShowNewDocModal(true)}
                >
                  <button className="button">
                    Add new document
                  </button>
                </div>
              ) : activeIndex === 6 ? (
                <div className={styles.actionCard}>
                  <button
                    onClick={() => {
                      setShowNewContract(true)
                    }}
                    className="button"
                  >
                    Start new contract
                  </button>
                </div>
              ) : activeIndex === 7 ? (
                <div className={styles.actionCard}>
                  <button
                    onClick={() => {
                      setShowNewQuestioners(true)
                    }}
                    className="button"
                  >
                    Create new questioners
                  </button>
                </div>
              ) : null
              }
            </Card>
            {showNewDocModal ? (
              <DocumentOverviewPopup
                showNewDocModal={showNewDocModal}
                setShowNewDocModal={setShowNewDocModal}
                customerId={id}
                customerName={name}
                setUpdateDocumentObject={setUpdateDocumentObject}
                updateDocumentObject={updateDocumentObject}
              />
            ) : null}
            <AddEditEvent
              forceRefreshEvents={handleReload}
              editEvent={editEvent}
              showNewEventModal={showNewEventModal}
              setShowNewEventModal={setShowNewEventModal}
              isSearch={true}
              id={id}
              customerName={name}
              customerImageUrl={imageUrl}
            />
            <SendNewEmail
              visible={showSendNewEmail}
              setVisible={setShowSendNewEmail}
              customerId={id}
              toMail={email || ""}
            />
          </>
        )}
      </>
    )
      : showNewContract && !showNewQuestioners ? (
        <NewContract
          customerId={id}
          imageURL={imageUrl}
          name={name}
          email={email}
          mobile={mobile}
          setShowNewContract={setShowNewContract}
          editMode={editMode}
          setEditMode={setEditMode}
          templateName={templateName}
          link={link}
          getUpdatedContract={getUpdatedContract}
          setUpdatedContract={setUpdatedContract}
          createdOn={createdOn}
          viewedOn={viewedOn}
          signedOn={signedOn}
        />
      ) :
        showNewQuestioners && !showNewContract ? (
          <NewQuestioners
            customerId={id}
            imageURL={imageUrl}
            name={name}
            email={email}
            mobile={mobile}
            setShowNewQuestioners={setShowNewQuestioners}
            editMode={editMode}
            setEditMode={setEditMode}
            templateName={templateName}
            link={link}
            createdOn={createdOn}
          />
        ) : null
  );

};

export default AdjustRecipient;
