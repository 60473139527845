import React, { useEffect, useState } from "react";
import EditNewCustomer from "./EditNewCustomer";
import NoCustomer from "./NoCustomer";
import CustomerOverview from "./CustomersOverview";
import cn from "classnames";
import withUserAuth from "../../utils/withUserAuth";
import Spinner from "../../utils/spinner";
import ErrorModal from "../../components/ErrorModal";
import styles from "./Customers.module.sass";
import { useParams, withRouter } from "react-router";
import { stripeConnectionStatus } from "../../utils/utils";

const Customers = (props) => {
  const { id, templateId, questionnaireId } = useParams();
  const { activeTab, activeCustomerTab, customerTab } = useParams();

  //const [visible, setVisible] = useState(stripeConnectionStatus());
  const [visible, setVisible] = useState(true);
  const [isCustomer, setIsCustomer] = useState(props?.isAddRecipient ? false : true);

  const isNewHeader = props?.location?.state?.isNewHeader;
  const isComingFromRecipient = props.location?.state?.comingFromRecipient;

  return (
    <>
      {!visible ? (
        <ErrorModal visibility={true} />
      ) : id && id !== "add" ? (
        <EditNewCustomer
          id={id}
          isAdd={false}
          templateId={templateId}
          activeCustomerTab={customerTab}
          questionnaireId={questionnaireId}
          setIsCustomer={setIsCustomer}
        />) :
        isNewHeader || id === "add" ?
          <EditNewCustomer
            setIsCustomer={setIsCustomer}
            activeCustomerTab={customerTab}
            isAdd={true}
            isComingFromRecipient={isComingFromRecipient}
          />
          :
          <CustomerOverview
            visibility={visible}
            activeTab={activeTab}
            isCustomer={isCustomer}
            setIsCustomer={setIsCustomer}
          />
      }
    </>
  );
};

export default withRouter(Customers);
