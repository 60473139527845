import React, { useEffect, useState } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Row from "./Row";
import DeleteModal from "../../../../../components/DeleteModal";
import Icon from "../../../../../components/Icon";
import Spinner from "../../../../../utils/spinner";
import {
  Checkbox,
  TriStateCheckbox,
  TriStateContext,
} from "react-tri-state-checkbox";
import "react-tri-state-checkbox/styles/checkbox.css";

// data
import { customers } from "../../../../../mocks/customers";
import { deleteRecipient, searchRecipients } from "../../../../../utils/apiCallHanlder";
import { useHistory } from "react-router";
import Image from "../../../../../components/Image";

let lastId = "";

const Table = ({
  className,
  editAction,
  changeCustomer,
  changeCustomerAll,
  editCustomer,
  comingFromCustomer,
  recipientData,
  setRecipientData,
  isLoading,
  setIsLoading,
  getMoreLoading,
  setGetMoreLoading,
  hasMore,
  setHasMore
}) => {
  const customerId = customers.map((x) => {
    return x.id;
  });



  const history = useHistory();

  if (recipientData?.length === 0) {
    lastId = "";
  }

  useEffect(async () => {
    setIsLoading(true);
    await getRecipients(lastId);
    setIsLoading(false);
  }, [])

  const getRecipients = async (previousId) => {
    const { data } = await searchRecipients("30", previousId, "");
    if (data) {

      if (previousId === "") {
        setRecipientData(data.list);
      } else {
        setRecipientData(recipientData.concat(data.list));
      }

      lastId = data?.list?.at(-1)?.id;
      setHasMore(data.isNextPageExist);
    }
  }

  return (
    recipientData.length < 1 ? (
      <div className={styles.noDataImg}>
        <Image 
          src={"/images/icons/empty-black.png"}
          srcDark={"/images/icons/empty-white.png"}
        />
      </div>
    ) : (
      <>
        <TriStateContext controls={customerId}>
          <div className={cn(styles.wrapper, className)}>
            <div className={cn(styles.table)} style={{ position: 'relative' }}>
              <div className={cn(styles.row)}>
                <div className={styles.editOverlayHead}></div>
                {/* <div className={styles.col}>
                  <TriStateCheckbox
                    onClick={changeCustomerAll}
                    className={styles.triHead}
                  ></TriStateCheckbox>
                </div> */}
                <div className={styles.col}>Name</div>
                <div className={styles.col}>Email</div>
                <div className={styles.col}>Account Routing</div>
                <div className={styles.col}>Account Type</div>
                <div className={styles.col}></div>
              </div>
              {isLoading ?
                <div className={styles.spinner}>
                  <Spinner loading={isLoading} size={50} color={'gray'} />
                </div>
                : recipientData?.map((x, index) => (
                    <Row
                      item={x}
                      key={index}
                      // singleCheck={
                      //   <Checkbox
                      //     onClick={changeCustomer}
                      //     className={styles.triCheckbox}
                      //     id={x.id}
                      //   />
                      // }
                      editAction={
                        <button
                          className={styles.actionItem}
                          onClick={() => {
                            history.push({
                              pathname: `/transactions/${x.id}`,
                              state: { isComingFromCustomer: comingFromCustomer }
                            })
                          }}
                        >
                          <Icon name="edit" size="24" />
                          Edit
                        </button>
                      }
                      deleteAction={
                        <DeleteModal
                          icon={
                            <Icon
                              className={styles.btnIcon}
                              fill="#FF4900"
                              name="trash"
                              size="20"
                            />
                          }
                          className={styles.deleteBtn}
                          deleteFunction={deleteRecipient}
                          getRecipients={getRecipients}
                          setIsLoading={setIsLoading}
                          setRecipientData={setRecipientData}
                          id={x.id}
                          comingFromCustomer={comingFromCustomer}
                          btnClass={cn(styles.btnClass)}
                        />
                      }
                      editCustomer={() => {
                        history.push({
                          pathname: `/transactions/${x.id}`,
                          state: { isComingFromCustomer: comingFromCustomer }
                        })
                      }}
                    />
                  ))}
            </div>

            {hasMore ?
              <div className={styles.foot}>
                <button className={cn("button-stroke-red button-small", styles.button)} onClick={async () => {
                  setGetMoreLoading(true);
                  await getRecipients(lastId)
                  setGetMoreLoading(false);
                }} style={{ width: 200 }}>
                  {getMoreLoading ?
                    <Spinner loading={getMoreLoading} size={20} color={'gray'} />
                    :
                    <>
                      <span>See More Recipients</span>
                      <Icon name="arrow-next" size="20"></Icon>
                    </>
                  }

                </button>
              </div>
              : null
            }

          </div>
        </TriStateContext>
      </>
    )
  );
};

export default Table;
