import React, { useCallback } from "react";
import { createPortal } from "react-dom";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import Card from "../Card";
import { customers, stripeBalance, bankAccount, cash, linkedAccounts, cards, accounting } from "../../utils/descriptions";
import styles from "./Modal.module.sass";

import Icon from "../Icon";
import HoverComp from "../hover";

const Modal = ({ outerClassName, visible, onClose, children, className }) => {
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  return createPortal(
    visible && (
      <div id="modal" className={styles.modal}>
        <div className={cn(styles.outer, outerClassName)} >
          <Card
            className={cn(styles.card, className)}
            classCardHead={styles.head}
            title="Money flows"
            /* classTitle={cn("title-red", styles.title)} */
          >
            <OutsideClickHandler onOutsideClick={onClose}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginTop: 80, position: 'absolute' }}>
                  <HoverComp
                    content={customers}
                    hoverClass={"explainerHover1"}
                    imgEle={<img src="/images/moneyflows/customers.svg" alt="customers" />}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: "column", marginLeft: 10 }}>
                  <img src="/images/moneyflows/paymentTypes.svg" alt="paymentTypes"
                    style={{ position: 'absolute', left: 100, top: 120 }} />


                  <img src="/images/moneyflows/arrow1.svg" alt="arrow1" style={{ position: 'absolute', left: 100, top: 120 }} />
                  <img src="/images/moneyflows/arrow2.svg" alt="arrow2" style={{ position: 'absolute', left: 100, top: 195 }} />
                  <img src="/images/moneyflows/arrow3.svg" alt="arrow3" style={{ position: 'absolute', left: 100, top: 202 }} />
                  <img src="/images/moneyflows/arrow4.svg" alt="arrow4" style={{ position: 'absolute', left: 100, top: 202 }} />
                </div>

                <div style={{ display: 'flex', flexDirection: "column", marginLeft: 10 }}>

                  <HoverComp
                    content={stripeBalance}
                    hoverClass={"explainerHover2"}
                    imgEle={<img src="/images/moneyflows/stripe.svg" alt="stripe"
                      style={{ position: 'absolute', left: 230, top: 90 }} />}
                  />
                  <img src="/images/moneyflows/arrow5.svg" alt="arrow"
                    style={{ position: 'absolute', left: 257, top: 145 }}
                  />
                  <HoverComp
                    content={bankAccount}
                    hoverClass={"explainerHover3"}
                    imgEle={<img src="/images/moneyflows/bank.svg" alt="bank"
                      style={{ position: 'absolute', left: 230, top: 180 }}
                    />}
                  />
                  <HoverComp
                    content={cash}
                    hoverClass={"explainerHover4"}
                    imgEle={<img src="/images/moneyflows/cash.svg" alt="cash"
                      style={{ position: 'absolute', left: 235, top: 260 }}
                    />}
                  />
                  <HoverComp
                    content={linkedAccounts}
                    hoverClass={"explainerHover5"}
                    imgEle={<img src="/images/moneyflows/linked.svg" alt="linked"
                      style={{ position: 'absolute', left: 230, top: 320 }}
                    />}
                  />

                </div>

                <div style={{ display: 'flex', flexDirection: "column", marginLeft: 10 }}>

                  <img src="/images/moneyflows/arrow6.svg" alt="arrow6" style={{ position: 'absolute', left: 300, top: 140 }} />
                  <img src="/images/moneyflows/arrow2.svg" alt="arrow2" style={{ position: 'absolute', left: 300, top: 180, width: 100 }} />
                  <img src="/images/moneyflows/arrow1.svg" alt="arrow1" style={{ position: 'absolute', left: 300, top: 200, width: 100 }} />
                  <img src="/images/moneyflows/arrow7.svg" alt="arrow7" style={{ position: 'absolute', left: 284, top: 227, width: 180 }} />
                </div>

                <div style={{ display: 'flex', flexDirection: "column", marginLeft: 10 }}>
                  <HoverComp
                    content={cards}
                    hoverClass={"explainerHover6"}
                    imgEle={<img src="/images/moneyflows/cards.svg" alt="cards"
                      style={{ position: 'absolute', left: 360, top: 50, width: 180 }} />
                    }

                  />
                  <HoverComp
                    content={accounting}
                    hoverClass={"explainerHover7"}
                    imgEle={<img src="/images/moneyflows/accounts.svg" alt="accounts"
                      style={{ position: 'absolute', left: 400, top: 180, width: 120 }} />}
                  />
                </div>
              </div>
              <button className={styles.close} onClick={onClose}>
                <Icon name="close" size="20" />
              </button>
            </OutsideClickHandler>
          </Card>
        </div>

      </div>
    ),
    document.body
  );
};

export default Modal;
