export const NAME_FIELD_ERROR = "Please Fill out Name Field";
export const STRIPE_COUNTRIES = [
    "united states",
    "Australia",
    "Austria",
    "Belgium",
    "Brazil",
    "Bulgaria",
    "Canada",
    "croatia",
    "cyprus",
    "czechia",
    "denmark",
    "estonia",
    "finland",
    "france",
    "germany",
    "gibraltar",
    "greece",
    "hong kong",
    "hungary",
    "india",
    "ireland",
    "italy",
    "japan",
    "latvia",
    "liechtenstein",
    "lithuania",
    "luxembourg",
    "malaysia",
    "malta",
    "mexico",
    "netherlands",
    "new zealand",
    "norway",
    "poland",
    "portugal",
    "romania",
    "singapore",
    "slovakia",
    "slovenia",
    "spain",
    "sweden",
    "switzerland",
    "thailand",
    "united arab emirates",
    "united kingdom"
]

export const STRIPE_COUNTRIES_WITH_CURRENCY = {
    'GBP': ["united kingdom", "gibraltar"],
    'EUR': ["Austria", "Belgium", "Bulgaria", "Switzerland", "Cyprus", "czechia",
        "Germany", "Denmark", "Estonia", "Spain", "Finland", 'France', "United Kingdom", "Gibraltar",
        "Greece", "Croatia", "Hungary", "Ireland", "Italy", "Liechtenstein", "Lithuania",
        "Luxembourg", "Latvia", "Malta", "Netherlands", "Norway", "Poland", "Portugal", "Romania", "Sweden", "Slovenia", "Slovakia"],
    'USD': ["United States"],
    'SEK': ["Sweden"],
    "NOK": ["Norway"],
    "DKK": ["Denmark"],
    "CHF": ["Switzerland", "Liechtenstein"]
}

export const defaultOrderValues = {
    sCode: "",
    product: "",
    qty: "",
    costPrice: "",
    total: "",
    discPercent: "",
    discount: "",
    vat: "",
    subTotal: "",
    sellPrice: ""
}


export const inventoryOrder = {
    sCode: "1",
    product: "protection programs",
    qty: "10",
    costPrice: "$100",
    total: "$1000",
    discPercent: "0%",
    discount: "0",
    vat: "0.12",
    subTotal: "$1000",
    sellPrice: "$1000"
}

export const icons = [
    {
        name: 'Single Family',
        icon: 'single'
    },
    {
        name: 'Multi Family',
        icon: 'multifamily'
    },
    {
        name: 'Commercial',
        icon: 'commercial'
    },
    {
        name: 'Apartment',
        icon: 'apartment'
    },
    {
        name: 'Land',
        icon: 'land'
    }
]

export const iconsLoans = [
    {
        name: 'Fix & Flip',
        icon: 'rehab'
    },
    {
        name: 'Rentals',
        icon: 'rentals'
    },
    {
        name: 'New Construction',
        icon: 'newconstruction'
    },
    {
        name: 'Refinance',
        icon: 'refinance'
    },
    // {
    //     name: 'Not defined',
    //     icon: 'notdefined'
    // }
]