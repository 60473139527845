import Card from "../../../../../components/Card";
import cn from 'classnames';
import styles from './CompanyDetails.module.sass';

const CompanyDetails = ({ companyDetails }) => {
    return <Card
        title="Company Details"
        className={styles.card}
        id="printAbleDiv"
    >
        <div className={styles.imageWrapper}>
            {companyDetails?.headerImageUrl && <img src={companyDetails?.headerImageUrl} alt="cover" className={styles.coverImage} />}
            {companyDetails?.imageUrl && <img src={companyDetails?.imageUrl} alt="profile" className={styles.profileImage} />}
        </div>
        <div className={cn(styles.description, styles.flex)}>
            <span>{companyDetails?.companyName}</span>
            {/* <span>CEO</span> */}
            <span>{companyDetails?.businessName}</span>
            <span>{companyDetails?.addressLine1}</span>
            {/* <span>Willimington, DE 19808</span> */}
            <span>{companyDetails?.phone}</span>
            <span>{companyDetails?.email}</span>
            {/* <span>www.greatweek.com</span> */}
        </div>
        <div className={styles.description}>
            <span>{companyDetails?.description}</span>
        </div>
    </Card>
}

export default CompanyDetails;