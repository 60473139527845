export const displayNameDesc = "This name will be shown all over in application."
export const emailDesc = "This email will be attached with every invoice."
export const businessNameDesc = "Your business name can be changed and will appear on for instance your Invoices.<br /> Your legal business name may be different from your actual business name (What the customers see)."
export const teamEmailDesc = "Invitation link will be sent over this link."
export const customers = "Customers can pay Great Week Users through various ways. Invoice, Subscription or Payment Link to one of your accounts or even cash."
export const stripeBalance = "Stripe Balance is where funds are settled if you chose stripe when you send a payment request to customer.  Through stripe your customers can pay with cards, installments and many other payment types. You can set up automatic payouts from your stripe balance to go to your bank account. By default we have set this to 2 business days, which means that all funds in your stripe balance will be transferred to your Bank account every 2nd business day. Please note that there are Stripe fees, which will be deducted from the final payment amount https://stripe.com/pricing."
export const bankAccount = "Bank Account is your main operational account. Most Customers that have lower than 15% Profit Margins send Invoices without Stripe Acceptance. Through this process, Customers pay directly to your bank account which has 0% acceptance cost."
export const cash = "Cash can be used for business income and expenses. You will have an Account labeled Cash in Accounting ledger but we have to note that most successful businesses have started eliminating cash from their business operations."
export const linkedAccounts = "Linked Account is an external bank account linked to the Great Week Platform. It can be an existing business bank account or a personal bank account. You can identify specific transactions from your Linked Account that you wish to book to your accounting. For instance if you used your personal bank account (through a card purchase) to pay for a business expense, you can book that transaction to your business accounting.  customers may choose to link external bank accounts to their Great Week Platform. That way you can automatically see all transactions from multiple Bank accounts and choose a specific transaction from an external account to report on your business accounting."
export const cards = "Cards are linked to your Bank Account balance. You can have an unlimited amount of cards, both physical and virtual linked to your bank account. Use your Cards to pay for all your business expenses, from Fuel to office supplies, to Social Media Advertising and even take out cash from ATM’s. Cards work both Online and in retail environments and virtual cards can be added to Apple Pay and Google pay."
export const accounting = "Accounting ledger is where we book ALL transactions related to your business."