import React, { useState } from "react";
import styles from "./Details.module.sass";
import TextInput from "../../../../components/TextInput";
import cn from "classnames";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Card from "../../../../components/Card";
import Tooltip from "../../../../components/Tooltip";
import Image from "../../../../components/Image";
import Icon from "../../../../components/Icon";
import Dropdown from "../../../../components/Dropdown_c";
import { uploadProductImage, } from "../../../../utils/apiCallHanlder";
import { CouponRedeemDateFormatter } from "../../../../utils/utils";
import { useEffect } from "react";
import DateInput from "../../../../components/DateInput";

const Details = ({
  className,
  name,
  setName,
  percentOff,
  setPercentOff,
  maxRedemptions,
  setMaxRedemptions,
  redeemBy,
  setRedeemBy,
  selectDuration,
  setSelectDuration,
  durationInMonths,
  setDurationInMonths,
  duration,
  setDuration,
  setValueHandler,
  nameError,
  setNameError,
  archived,
  isAdd,
  comingFromInvoice
}) => {

  const durationOption = [
    { value: 'forever', label: 'Forever' },
    { value: 'once', label: 'Once' },
    { value: 'repeating', label: 'Repeating' }
  ]

  useEffect(() => {
  }, [selectDuration])



  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={isAdd ? "Create new Discount" : "Details"}
        /* classTitle="title-blue" */
        classCardHead={styles.cardHead}
      >
        {!isAdd ?
          <p style={{ color: "red" }}>You can only Update Coupon Name</p>
          : ''}
        <div className={styles.row}>
          <div className={styles.col} style={comingFromInvoice && { marginTop: 'unset' }}>
            <div className={styles.field} style={archived ? { pointerEvents: 'none' } : null}>
              <TextInput
                style={name === "" ? { backgroundColor: '#FFBC9925', borderColor: "#FFBC9925" } : null}
                label="Name"
                name="Name"
                onChange={(event) => setValueHandler(event?.target?.value, setName)}
                value={name}
                type="text"
                //tooltip='Products are the goods or services you sell. If you sell subscriptions, you might call them "plans." Product names are visible to customers at checkout, in receipts, invoices, and the customer portal.'
                required
              />
              {nameError !== "" ?
                <div className={styles.errorContainer}><span className={styles.errorMessage}>{nameError}</span></div>
                : null
              }
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col} style={comingFromInvoice && { marginTop: 'unset' }}>
            <div className={styles.field} style={archived ? { pointerEvents: 'none' } : null}>
              <TextInput
                style={name === "" ? { backgroundColor: '#FFBC9925', borderColor: "#FFBC9925" } : null}
                label="Max Redemptions"
                name="maxRedemptions"
                onChange={(event) => setValueHandler(event?.target?.value, setMaxRedemptions)}
                value={maxRedemptions}
                type="number"
                //tooltip='Products are the goods or services you sell. If you sell subscriptions, you might call them "plans." Product names are visible to customers at checkout, in receipts, invoices, and the customer portal.'
                required
              />
              {/* {nameError !== "" ?
                <div className={styles.errorContainer}><span className={styles.errorMessage}>{nameError}</span></div>
                : null
              } */}
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col} style={comingFromInvoice && { marginTop: 'unset' }}>
            <div className={styles.field} style={archived ? { pointerEvents: 'none' } : null}>
              <DateInput
                className={styles.dateInput}
                isWithPortal={true}
                hideText={true}
                redeemText={true}
                allowFuture={true}
                label="Redeem By"
                portalClassInput={styles.input}
                classInputValue={styles.inputValue}
                dateInputId="dateInputId"
                markAsPaidHandler={(event) => setRedeemBy(CouponRedeemDateFormatter(event))}
              />
              {/* <TextInput
                style={name === "" ? { backgroundColor: '#FFBC9925', borderColor: "#FFBC9925" } : null}
                label="Redeem By"
                name="redeemBy"
                onChange={(event) => setValueHandler(event?.target?.value, setRedeemBy)}
                value={redeemBy}
                type="date"
                //tooltip='Products are the goods or services you sell. If you sell subscriptions, you might call them "plans." Product names are visible to customers at checkout, in receipts, invoices, and the customer portal.'
                required
              /> */}
              {/* {nameError !== "" ?
                <div className={styles.errorContainer}><span className={styles.errorMessage}>{nameError}</span></div>
                : null
              } */}
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col} style={comingFromInvoice && { marginTop: 'unset' }}>
            <div className={styles.field} style={archived ? { pointerEvents: 'none' } : null}>
              <div className={styles.label}>Duration</div>
              <select
                label='Select Duration'
                name='durationSelect'
                style={selectDuration === "" || selectDuration === null ?
                  {
                    backgroundColor: '#FFBC9925', borderColor: "#FFBC9925", width: 280, height: 48, padding: 10,
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: 1.71429,
                    color: "#1A1D1F"
                  }
                  : {
                    height: 50, width: 280, padding: 10,
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: 1.71429,
                    color: "#1A1D1F",
                  }}
                placeholder="Please select duration"
                className={styles.dropdown}
                value={selectDuration}
                onChange={(event) => setValueHandler(event?.target?.value, setSelectDuration)}
              >
                <option style={{
                  padding: 10,
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: 1.71429,
                  // color: "#6F767E",
                  color: "#1A1D1F"
                }} value={''}>{"Select Duration"}</option>
                {durationOption.map((duration, index) =>
                  <option style={{
                    padding: 10,
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: 1.71429,
                    // color: "#6F767E",
                    color: "#1A1D1F"
                  }} key={index} value={duration.value}>{duration.label}</option>
                )}
              </select>
              {
                selectDuration === "repeating" ?
                  <div style={{ marginTop: 25 }}>
                    <TextInput
                      type="number"
                      style={durationInMonths === "" || durationInMonths === null ?
                        { backgroundColor: '#FFBC9925', borderColor: "#FFBC9925", width: 280 }
                        : { width: 280 }}
                      label="Duration In Months"
                      name="durationInMonths"
                      value={durationInMonths}
                      onChange={(event) => setValueHandler(event?.target?.value, setDurationInMonths)}
                      required
                    />
                  </div>
                  :
                  ''

              }
            </div>
          </div>
        </div>
      </Card>
      {/* <TooltipGlodal /> */}
    </>
  );
};

export default Details;
